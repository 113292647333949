import { first } from "lodash";
import { ProfileItem } from "PFApp/components/profile_item";
import { Divider } from "PFComponents/divider";
import { Icon } from "PFComponents/icon";
import MultiToggle from "PFComponents/multi_toggle/multi_toggle";
import AutoSelect from "PFComponents/select/autoselect";
import { useActivityEconomicsScenarioAssign } from "PFCore/hooks/queries/activity";
import {
  fetchScenarioMatchesOptions,
  fetchScenarioProfilesOptions
} from "PFCore/services/activity/activity_economics_profiles";
import { EconomicsVacancy, Id, Profile } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../../../../../../use_growl";
import css from "./assignments_form.module.scss";
import { DisplayOption, OptionsTableHeader } from "./display_option";

enum ProfilesSelector {
  Matches = "matches",
  NamedResource = "named-resource"
}

type AssignmentsFormProps = {
  emptyVacancies: EconomicsVacancy[];
  scenarioId: Id;
};

export const AssignmentsForm = ({ emptyVacancies, scenarioId }: AssignmentsFormProps) => {
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.economics" });
  const { mutate: assignVacancy } = useActivityEconomicsScenarioAssign(scenarioId);

  const [profilesSelector, setProfilesSelector] = useState<ProfilesSelector>(ProfilesSelector.NamedResource);

  const handleAssign = (profiles: Profile[], vacancyId: Id) => {
    const profileId = first(profiles)?.id;
    if (!profileId) {
      return;
    }

    assignVacancy(
      { profileId, vacancyId },
      {
        onSuccess: () => {
          growl({
            kind: "success",
            message: t("scenario.assignSuccess")
          });
        },
        onError: () => {
          growl({
            kind: "error",
            message: t("scenario.assignError")
          });
        }
      }
    );
  };

  const renderFormRow = (vacancy: EconomicsVacancy, index) => (
    <>
      <div className={css.formRow}>
        <Icon name="warning" size="md" className={css.icon} />
        {profilesSelector === ProfilesSelector.NamedResource && (
          <AutoSelect
            rootClassName={css.select}
            query={(term) =>
              fetchScenarioProfilesOptions({ activityId: vacancy.activity.id, scenarioId, term })
            }
            formatOption={(option) => ({
              ...option,
              displayElement: <ProfileItem profile={option} />,
              item: option
            })}
            values={[]}
            handleChange={(profiles) => handleAssign(profiles, vacancy.id)}
            closeOnChange
            cache={false}
          />
        )}
        {profilesSelector === ProfilesSelector.Matches && (
          <AutoSelect
            rootClassName={css.select}
            query={(term) =>
              fetchScenarioMatchesOptions({ activityId: vacancy.activity.id, scenarioId, term })
            }
            formatOption={(option) => ({
              ...option,
              displayElement: <DisplayOption option={option} />,
              item: option
            })}
            preOptions={(options) => options.length > 0 && <OptionsTableHeader />}
            noOptionsText={t("assignmentForm.options.noMatches")}
            values={[]}
            disabled={!vacancy.activity.matchedAt}
            handleChange={(profiles) => handleAssign(profiles, vacancy.id)}
            closeOnChange
            cache={false}
            fitDropdownContent
          />
        )}
        <MultiToggle<ProfilesSelector>
          options={[
            {
              id: ProfilesSelector.Matches,
              value: t("scenario.profilesSelector.matches"),
              disabled: !vacancy.activity.matchedAt,
              tooltipContent: !vacancy.activity.matchedAt && t("matchesNotReady")
            },
            { id: ProfilesSelector.NamedResource, value: t("scenario.profilesSelector.namedResource") }
          ]}
          controlledValue={profilesSelector}
          onChange={setProfilesSelector}
          fluid
        />
      </div>
      {index !== emptyVacancies.length - 1 && <Divider />}
    </>
  );

  return <div className={css.form}>{emptyVacancies.map(renderFormRow)}</div>;
};
