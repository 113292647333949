import ContactInfoEditForm from "PFApp/profiles/common/contact_info_edit_form";
import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import { RouterPrompt } from "PFComponents/router_prompt/router_prompt";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { AccessLevel } from "PFTypes";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const ContactInformationSection = ({ profile, currentProfile, adminPage, handleProfileUpdate }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "edit.sections" });
  const [onLoading, setOnLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const onChanged = (...args) => {
    setHasChanged(...args);
    handleProfileUpdate();
  };

  const isPermitted = isProfileFieldPermitted(
    currentProfile?.permissions_group,
    profile?.permissions_group?.id,
    "contact_info",
    AccessLevel.ReadWrite
  );

  const contactInfoRef = useRef();

  const buttonDisabled = !isPermitted ? !isPermitted : adminPage ? false : onLoading;

  return (
    <ProfileEditPanel
      title={getProfileSectionText("contact_information")}
      headingButtonLabel={t("contactInfo.addNew")}
      handleHeadingButtonClick={() => contactInfoRef?.current?.onAddNew()}
      headingButtonDisabled={buttonDisabled}
    >
      <RouterPrompt when={hasChanged} />
      <ContactInfoEditForm
        profile={profile}
        adminPage={adminPage}
        ref={contactInfoRef}
        onLoading={setOnLoading}
        onChanged={onChanged}
      />
    </ProfileEditPanel>
  );
};

ContactInformationSection.propTypes = {
  profile: PropTypes.any.isRequired,
  currentProfile: PropTypes.any.isRequired,
  adminPage: PropTypes.bool,
  handleProfileUpdate: PropTypes.func.isRequired
};

ContactInformationSection.defaultProps = {
  adminPage: false
};

export default ContactInformationSection;
