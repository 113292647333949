import { merge, omit } from "lodash";
import { checkFiltersAreDefault } from "PFApp/use_filtered_collection";
import { removeChildrenFiltersIfNeeded } from "PFApp/use_filtered_collection/use_filtered_collection.utils";
import useStorageFilters from "PFApp/use_filtered_collection/use_storage_filters";
import { Filters, Value } from "PFTypes";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

import { UseFiltersReturn } from "../../../use_filtered_collection/use_filters";

type UseFiltersWithPostAction = {
  storagePrefix: string;
  postChangeAction: (...args: any) => void;
  defaultValue?: Filters<Value>;
};
export type UseFiltersWithPostActionReturn = UseFiltersReturn & {
  setFiltersRaw: UseFiltersReturn["setFilters"];
  childrenFiltersEnabled: boolean;
  setChildrenFiltersEnabled: Dispatch<SetStateAction<boolean>>;
};

export const useFiltersWithPostAction = ({
  storagePrefix,
  postChangeAction,
  defaultValue = {}
}: UseFiltersWithPostAction): UseFiltersWithPostActionReturn => {
  const { setFilters, updateFilter, resetFilters, selectedFilters, ...rest } = useStorageFilters({
    storagePrefix,
    defaultValue
  });
  const [childrenFiltersEnabled, setChildrenFiltersEnabled] = useState<boolean>(
    !checkFiltersAreDefault(selectedFilters.children || {}, defaultValue?.children || {})
  );

  const handleResetFilters = useCallback<UseFiltersReturn["resetFilters"]>(
    (...args) => {
      resetFilters(...args);
      postChangeAction(...args);
      setChildrenFiltersEnabled(false);
    },
    [postChangeAction, resetFilters]
  );

  const handleUpdateFilter = useCallback<UseFiltersReturn["updateFilter"]>(
    (...args) => {
      updateFilter(...args);
      postChangeAction(...args);
    },
    [postChangeAction, updateFilter]
  );

  const handleSetFilters = useCallback<UseFiltersReturn["setFilters"]>(
    (filters, ...args) => {
      const areChildrenFiltersDefault = checkFiltersAreDefault(
        filters.children || {},
        defaultValue?.children || {}
      );
      setChildrenFiltersEnabled(!areChildrenFiltersDefault);
      const resultFilters = removeChildrenFiltersIfNeeded(filters, areChildrenFiltersDefault);
      setFilters(resultFilters, ...args);
      postChangeAction(resultFilters, ...args);
    },
    [postChangeAction, setFilters]
  );

  useEffect(() => {
    if (childrenFiltersEnabled) {
      setFilters(merge(selectedFilters, { children: defaultValue?.children }));
    } else {
      setFilters(omit(selectedFilters, "children"));
    }
  }, [childrenFiltersEnabled]);

  return {
    ...rest,
    selectedFilters: removeChildrenFiltersIfNeeded(selectedFilters, !childrenFiltersEnabled),
    resetFilters: handleResetFilters,
    updateFilter: handleUpdateFilter,
    setFilters: handleSetFilters,
    setFiltersRaw: setFilters,
    childrenFiltersEnabled,
    setChildrenFiltersEnabled
  };
};
