import classNames from "classnames";
import useWindowSize from "PFCore/helpers/use_window_size";
import { EventKey } from "PFTypes/event_key";
import { useEffect, useRef } from "react";

import { isNavItem } from "../../../helpers";
import { NavItemProps } from "../nav_item";
import { AnyNavDropdownItem } from "./any_nav_dropdown_item";
import css from "./nav_dropdown.module.scss";

type NavDropdownProps = {
  id: string;
  shown?: boolean;
  items: NavItemProps["items"];
  closeSubMenu: NavItemProps["closeSubMenu"];
  labelledBy?: string;
};

export const NavDropdown = ({ id, shown, items, closeSubMenu, labelledBy }: NavDropdownProps) => {
  const listRef = useRef<HTMLUListElement>(null);

  const { windowHeight } = useWindowSize();
  const getStyle = () => {
    if (!listRef.current) {
      return;
    }

    const newStyle = { top: -20 };

    const height = listRef.current?.offsetHeight || 0; // offsetHeight ignores scaling
    const parentRect = (listRef.current.parentNode as HTMLElement)?.getBoundingClientRect();

    if (parentRect.top + newStyle.top + height > windowHeight) {
      newStyle.top = windowHeight - height - parentRect.top - 10;
    }

    if (parentRect.top + newStyle.top < 0) {
      newStyle.top = -parentRect.top;
    }

    return newStyle;
  };

  useEffect(() => {
    const onClosePress = (e) => {
      if (e.key === EventKey.Escape) {
        closeSubMenu();
      }
    };

    const onUpdatedFocus = (e) => {
      if (e.relatedTarget && !listRef.current?.contains(e.relatedTarget)) {
        closeSubMenu();
      }
    };

    if (shown) {
      document.addEventListener("keydown", onClosePress);
      listRef.current?.addEventListener("focusout", onUpdatedFocus);
    } else {
      document.removeEventListener("keydown", onClosePress);
      listRef.current?.removeEventListener("focusout", onUpdatedFocus);
    }
    return () => document.removeEventListener("keydown", onClosePress);
  }, [shown]);

  return (
    <ul
      className={classNames(css.root, { [css.shown]: shown, [css.hidden]: !shown })}
      ref={listRef}
      style={getStyle()}
      data-qa-id="NavDropdown"
      id={id}
      aria-labelledby={labelledBy}
      aria-hidden={!shown}
    >
      {items?.map((item, index) => (
        <AnyNavDropdownItem
          item={item}
          key={`${index}:${isNavItem(item) ? item.name : "sep"}`}
          closeSubMenu={closeSubMenu}
        />
      ))}
    </ul>
  );
};
