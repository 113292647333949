/**
 * @param {string} eventCategory - category of the event e.g. searchRecomendations
 * @param {string} eventAction - Name of the action and the page that calls the
 * action e.g. search_recomendations
 * @param {object} eventPayload - payload sent to analytics tracker. e.g.
 * {
 *  searchText,
    recomendations,
    selectedRecomendation,
    index,
    timestamp
 * }
 */

const AnalyticsPush = (eventCategory, eventAction, eventPayload) => {
  if (window._paq) {
    _paq.push([
      "trackEvent",
      eventCategory, // Event Category
      eventAction, // Event Action
      JSON.stringify({ ...eventPayload, timestamp: new Date().getTime() })
    ]);
  }
};

export default AnalyticsPush;
