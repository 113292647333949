import { decamelizeKeys } from "humps";
import api from "PFCore/api";
import { Id } from "PFTypes";
import { ChangeRequest } from "PFTypes/change_request";

export const applyChangeRequest = (id: Id, appliedData: Partial<ChangeRequest["applied_data"]>) =>
  api<ChangeRequest>({
    url: `change_requests/${id}/apply`,
    method: "PUT",
    data: {
      appliedData
    }
  }).then((resp) => decamelizeKeys(resp) as ChangeRequest); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized
