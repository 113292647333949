export const HEADER_SPACING_OFFSET = 16;
/*
  value equal to .root vertical padding from
  assets/javascripts/core/components/side_panel/side_panel.module.scss
*/
const DEFAULT_OFFSET = 20;

const TABS_PADDING = 10;

export const getHeaderOffset = (hasTabsConfig: boolean) => {
  if (hasTabsConfig) {
    return 2 * HEADER_SPACING_OFFSET + TABS_PADDING + DEFAULT_OFFSET;
  } else {
    return DEFAULT_OFFSET;
  }
};
