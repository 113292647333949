import classNames from "classnames";
import { Typography } from "PFComponents/typography";
import { getBlendedColorOnWhite } from "PFCore/utilities/color";
import { BookingCategory } from "PFTypes";

import BookingPill from "../../../../components/pills/booking_pill";
import css from "./activity_details_category_section.module.scss";

const SMALL_WIDTH = 60;
const LARGE_WIDTH = 120;

type ActivityCategoryProps = {
  category: BookingCategory;
  shouldSliceText?: boolean;
  bookingPillSize?: "large" | "small";
};

export const ActivityCategory = ({
  category,
  bookingPillSize = "large",
  shouldSliceText = false
}: ActivityCategoryProps): JSX.Element => {
  const categoryColor = getBlendedColorOnWhite([category.color].reverse(), 1).string();
  return (
    <div className={css.categoryContainer}>
      <Typography
        variant="bodyRegular"
        className={classNames(css.categoryName, { [css.textSliced]: shouldSliceText })}
      >
        {category.display_as}
      </Typography>
      <BookingPill
        style={{
          borderWidth: 1,
          width: bookingPillSize === "large" ? LARGE_WIDTH : SMALL_WIDTH
        }}
        className={css.bookingPill}
        category={{ color: categoryColor }}
      />
    </div>
  );
};
