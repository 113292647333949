import { CalendarMetric, CalendarMode } from "PFApp/booking/types";
import useStorage from "PFCore/helpers/use_storage";
import { Dispatch, SetStateAction, useEffect } from "react";

export const useCalendarMetric = (
  mode: CalendarMode,
  showUtilizationMetrics?: boolean
): [CalendarMetric, Dispatch<SetStateAction<CalendarMetric>>] => {
  const DEFAULT_METRIC = showUtilizationMetrics ? CalendarMetric.Utilization : CalendarMetric.TotalHours;
  const [metric, setMetric] = useStorage<CalendarMetric>(`${mode}-metric-mode`, DEFAULT_METRIC);

  useEffect(() => {
    if (metric === CalendarMetric.Utilization && !showUtilizationMetrics) {
      setMetric(DEFAULT_METRIC);
    }
  }, [showUtilizationMetrics]);

  return [metric, setMetric];
};
