import classNames from "classnames";
import { Alert } from "PFComponents/alert";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { ActivityProfilesAvatarStack } from "../../../../components/activity_profiles_avatar_stack";
import ButtonLabel from "../../../parts/button_label";
import { useActivityPageContext } from "../../activity_page_context";
import { useActivityChat } from "../hooks/use_activity_chat";
import { useReviewers } from "../hooks/use_reviewers";
import AddReviewersModal from "./add_reviewers_modal";
import { DeadlineModal } from "./deadline_modal";
import { ReviewDeadlineLabel } from "./review_deadline_label";
import css from "./review_section.module.scss";
import { ReviewerLabel } from "./reviewer_label";

export const OwnerFlow = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.review" });
  const { t: trans } = useTranslation("translation");

  const { task, allShortlists } = useActivityPageContext();
  const { formatDate, isDatePassed } = useDateFormatter();
  const { reviewers, canWrite: canWriteReviewers } = useReviewers(task);
  const { startChat } = useActivityChat();

  const [showDeadlineModal, setShowDeadlineModal] = useToggle(false);

  const reviewer = task.review?.completed_by;
  const isDraft = task.state === "draft";
  const deadlineDate = task.review?.deadline;
  const deadlinePassed = isDatePassed(deadlineDate);
  const completedDate = task.review?.completed_at;
  const canReview =
    !!allShortlists.length && ["pending", "shortlisting", "reviewing"].includes(task.workflow_state);

  const [showRequestReview, setShowRequestReview] = useToggle(false);

  return (
    <>
      <ActivityProfilesAvatarStack
        className={css.profilesStack}
        label={t("review")}
        profiles={reviewers}
        actionLabel={t("addReviewer")}
        qaId="ActivityPageAddReviewerButton"
        handleActionClick={canWriteReviewers ? () => setShowRequestReview(true) : undefined}
        displayChatIcon={!isDraft}
        handleIconClick={startChat}
      />
      {reviewers.length === 0 && (
        <Typography variant="labelRegular" className={css.noReviewersLabel}>
          <Icon name="info" color="paletteBlue2" />
          {t("noReviewers")}
        </Typography>
      )}
      {completedDate && <ReviewerLabel name={getProfileName(reviewer)} date={formatDate(completedDate)} />}
      {!completedDate && deadlineDate && (
        <div className={classNames(css.label, css.inReviewLabel)}>
          {reviewers.length > 0 && (
            <ButtonLabel className={css.nowrap}>
              <Icon name="cross" size="sm" color="paletteRed0" />
              {t("inReview")}
            </ButtonLabel>
          )}
          <ReviewDeadlineLabel date={deadlineDate} onClick={setShowDeadlineModal} />
        </div>
      )}
      {!completedDate && !deadlineDate && reviewers.length > 0 && canReview && (
        <Button className={css.button} onClick={setShowDeadlineModal} text={t("setDeadline")} />
      )}
      {!completedDate && deadlinePassed && (
        <Alert
          className={css.label}
          kind="warning"
          action={{ onClick: setShowDeadlineModal, text: trans("edit") }}
          title={t("deadlinePassedOwner")}
          multiLineTitle
        />
      )}
      {showDeadlineModal && <DeadlineModal onClose={setShowDeadlineModal} />}
      {showRequestReview && <AddReviewersModal handleClose={setShowRequestReview} />}
    </>
  );
};
