import { useGrowl, useHideGrowl } from "PFApp/use_growl";
import { useSession } from "PFApp/use_session";
import { fetchCurrentProfile } from "PFCore/services/current_profile";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";

const NOTICE_WINDOW = 15 * 1000;
const UNDEFINED_TTL = 999999;
const DEBOUNCE_ON_ACTION_WINDOW = 500;

export const useLiveSessionCheck = (ttl: number) => {
  const [active, setActive] = useState(true);
  const timeoutId = useRef<number | null>(null);
  const growl = useGrowl();
  const hideGrowl = useHideGrowl();
  const { t } = useTranslation();

  const { expireSession } = useSession();

  useEffect(() => {
    const id = "live-session-check";
    if (!active) {
      timeoutId.current = window.setTimeout(() => {
        if (!active) {
          expireSession("sign_in.session_inactive");
        }
      }, NOTICE_WINDOW);
      growl({
        id,
        kind: "alert",
        message: t("signUpSignIn.inactivityWarning"),
        ttl: UNDEFINED_TTL
      });
    }
    return () => {
      hideGrowl(id);
      timeoutId.current && window.clearTimeout(timeoutId.current);
    };
  }, [active]);

  const handleOnIdle = () => setActive(false);

  const handleOnActive = () => {
    setActive(true);
    fetchCurrentProfile(); // Make request to API to stay alive
  };

  const handleOnAction = () => setActive(true);

  useIdleTimer({
    disabled: !ttl,
    timeout: ttl ? ttl - NOTICE_WINDOW : undefined,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: DEBOUNCE_ON_ACTION_WINDOW
  });
};
