import { bind, clone, extend, isNil } from "lodash";

const getHeaders = (options, session, currentAccount) => {
  const ref = options.url;

  if ((ref !== undefined ? ref.indexOf("options/filter") : undefined) > 0) {
    options.api_version = 2;
  }

  const accessToken = session.getAccessToken();
  const headers = clone(options.headers) || {};
  if (currentAccount) {
    headers.ACCOUNT = currentAccount.full_domain;
  }
  headers.ACCEPT || (headers.ACCEPT = `application/vnd.profinda+json;version=${options.api_version || 1}`);
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  headers["Ui-Version"] || (headers["Ui-Version"] = PF.config.ui_version);
  !isNil(PF.config.locale) && (headers["Accept-Language"] = PF.config.locale);
  return headers;
};

// eslint-disable-next-line id-length
export const setupWrapAjax = ($, session, currentAccount, onServerError) => {
  const originalAjax = $.ajax;

  // eslint-disable-next-line id-length
  $.ajax = (url, options) => {
    var deferred, promise;
    if (options === undefined) {
      options = {};
    }

    deferred = $.Deferred();

    if (typeof url === "object") {
      options = url;
      url = undefined;
    }

    let ajaxOptions = extend({}, options, {
      headers: getHeaders(options, session, currentAccount)
    });

    const ajaxPromise = originalAjax
      .call($, url, ajaxOptions)
      .done((data, textStatus, jqXHR) => deferred.resolve(data, textStatus, jqXHR))
      .fail((jqXHR, textStatus, errorThrow) => {
        const ref = url || options.url;
        const isServerErrror = (jqXHR.status || 0) >= 500;

        if (isServerErrror) {
          onServerError?.();
        }

        if (jqXHR.status === 401 && !(ref !== null ? ref.match("/api/oauth/token") : undefined)) {
          session
            .refreshToken()
            .then(() => {
              ajaxOptions = extend({}, options, {
                headers: getHeaders(options, session, currentAccount)
              });
              originalAjax
                .call($, url, ajaxOptions)
                .done((data, textStatus, jqXHR) => deferred.resolve(data, textStatus, jqXHR))
                .fail((jqXHR, textStatus, errorThrow) => {
                  session.expireSession();
                  deferred.reject(jqXHR, textStatus, errorThrow);
                });
            })
            .catch(() => deferred.reject(jqXHR, textStatus, errorThrow));
        } else {
          return deferred.reject(jqXHR, textStatus, errorThrow);
        }
      });

    promise = deferred.promise();
    promise.success = promise.done;
    promise.error = promise.fail;
    promise.complete = promise.always;
    promise.abort = bind(ajaxPromise.abort, ajaxPromise);
    return promise;
  };
};
