import { Channel, PresenceChannel } from "pusher-js";
import { useEffect, useState } from "react";

export const usePusherEvent = (
  channelName: string,
  eventName: string,
  callback: Function,
  enabled = true
) => {
  const [channel, setChannel] = useState<Channel & PresenceChannel>();

  // @ts-ignore
  const client = window.newPusher;

  useEffect(() => {
    if (!channelName || !client || !client.subscribe || !client.unsubscribe || !enabled) {
      return;
    }

    const _channel = client.subscribe(channelName);

    setChannel(_channel);

    return () => {
      client.unsubscribe(channelName);
    };
  }, [channelName, client, client?.subscribe, client?.unsubscribe, enabled]);

  useEffect(() => {
    if (channel && callback) {
      channel.bind(eventName, callback);

      return () => {
        channel.unbind(eventName, callback);
      };
    }
  }, [channel, eventName, callback]);
};
