import useBookingCategories from "PFCore/hooks/use_booking_categories";

export const useBookingCategoryFilter = () => {
  const { bookingCategories } = useBookingCategories();

  const query = ({ value, term }) => {
    const categories = bookingCategories
      .filter((item) => !item.archived)
      .map((category) => ({
        id: category.id,
        text: category.display_as
      }));

    if (term) {
      return Promise.resolve({
        entries: categories.filter((category) => category.text.toLowerCase().includes(term.toLowerCase()))
      });
    }

    return Promise.resolve({
      entries: value ? categories.filter((category) => value.includes(category.id)) : categories
    });
  };

  return {
    query
  };
};
