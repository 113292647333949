export const rowChildrenKeys = {
  all: () => ["rowChildren"],
  ofMode: (mode) => [...rowChildrenKeys.all(), mode],
  ofRow: (mode, type, id) => [...rowChildrenKeys.ofMode(mode), type, id],
  ofRowPage: (mode, type, id, childrenType, filters, dateRange, page) => [
    ...rowChildrenKeys.ofRow(mode, type, id),
    childrenType,
    filters,
    dateRange,
    page
  ],
  ofRowPageCount: (mode, type, id, childrenType, filters, dateRange) => [
    ...rowChildrenKeys.ofRow(mode, type, id),
    "pastBookingsCount",
    childrenType,
    filters,
    dateRange
  ],
  isRowChildrenQuery: (query) => query.queryKey[0] === "rowChildren"
};
