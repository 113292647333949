import { engagementsDispatch } from "PFReducers/engagements_reducer";
import { notificationSettingsDispatch } from "PFReducers/notification_settings_reducer";
import { notificationsDispatch } from "PFReducers/notifications_reducer";
import { profilesDispatch } from "PFReducers/profiles_reducer";
import { searchDispatch } from "PFReducers/search_reducer/search_reducer";

export const mainDispatch = (dispatch, store) => (action) => {
  const dispatchers = [
    engagementsDispatch,
    notificationsDispatch,
    notificationSettingsDispatch,
    profilesDispatch,
    searchDispatch
  ];

  for (let i = 0; i < dispatchers.length; i++) {
    const result = dispatchers[i](dispatch, action, store);
    if (result) {
      return result;
    }
  }

  return dispatch(action);
};
