import { EconomicsScenario, Id } from "PFTypes";

import api from "../../api";

export const createActivityEconomicsScenario = (activityId: Id, name: string) =>
  api<EconomicsScenario>({
    url: `engagements/${activityId}/scenarios`,
    method: "POST",
    data: {
      name
    }
  });
