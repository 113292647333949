import Footer from "PFApp/layout/footer/footer";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import landingBackgroundImageUrl from "PFImages/landing-new-bg.jpg";

import css from "../landing_layout.module.scss";
type ContentProps = {
  children: React.ReactNode;
  right?: number;
  alignTop?: boolean;
  maxWidth?: number;
};

export const Content = ({ children, right, alignTop, maxWidth }: ContentProps) => {
  const { data: currentAccount } = useCurrentAccount();
  const cover = (currentAccount.customization || {}).landing_cover_url || landingBackgroundImageUrl;
  const width = `calc(100% - ${right || 0}px)`;

  return (
    <div className={css.content} style={{ width }} data-qa-id="LandingLayout.content">
      <div
        className={css.bg}
        style={{ right, backgroundImage: `url(${cover})` }}
        data-qa-id="LandingLayout.bg"
      />
      <div className={css.contentWrap}>
        <div
          className={css.contentInner}
          style={alignTop ? { verticalAlign: "top", maxWidth } : { maxWidth }}
        >
          {children}
        </div>
      </div>
      <div className={css.contentFooter}>
        <Footer style={{ transform: "none", paddingTop: 0 }} />
      </div>
    </div>
  );
};
