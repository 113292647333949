import classNames from "classnames";
import { uniqueId } from "lodash";
import React, { ChangeEvent, CSSProperties } from "react";

import css from "./checkbox.module.scss";

type CheckboxProps = {
  checked: boolean;
  handleChange: (value: boolean, ev?: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  waggle?: boolean;
  small?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  id?: string;
  classes?: {
    inputFake?: string;
  };
};

const Checkbox = ({
  className,
  label = "",
  checked,
  small = true,
  waggle = false,
  disabled = false,
  style,
  handleChange,
  id,
  classes
}: CheckboxProps) => {
  const checkboxId = id || uniqueId("checkbox");

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={classNames(css.component, className, { [css.small]: small })}
      style={style}
      htmlFor={checkboxId}
    >
      <div className={css.wrap}>
        <input
          className={css.input}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={(ev) => handleChange(ev.target.checked, ev)}
          id={checkboxId}
        />
        <div className={classNames(css.inputFake, { [css.waggleAnimation]: waggle }, classes?.inputFake)} />
        <span className={classNames(css.label, { [css.disabled]: disabled })}> {label} </span>
      </div>
    </label>
  );
};

export default Checkbox;
