import { InputFieldSet } from "PFComponents/text/input_field_set";
import { getWeekdays } from "PFCore/helpers/date";

import css from "./availability_with_hours.module.scss";

type HoursPerDayProps = {
  onHoursChange: (value: string, index: number) => void;
  dayOfWeek: number[];
};

export const HoursPerDay = ({ onHoursChange, dayOfWeek }: HoursPerDayProps) => (
  <div className={css.hoursPerDayWrapper}>
    {getWeekdays().map((day, index) => (
      <InputFieldSet
        key={`${day}+${index}`}
        label={day}
        inputType="number"
        min={0}
        max={24}
        value={dayOfWeek[index] / 60}
        onChange={(value: string) => {
          onHoursChange(value, index);
        }}
        className={css.hourPerDay}
      />
    ))}
  </div>
);
