import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";
import { FetchProfileQueryParams, FetchProfilesResponse, fetchProfilesV2 } from "PFCore/services/profile";
import { InfiniteQueryOptions, QueryOptions } from "PFTypes/request_options";

import { profilesKeys } from "./query_keys";

export const useProfiles = (
  params: FetchProfileQueryParams,
  options: QueryOptions<FetchProfilesResponse> = {}
) => useQuery<FetchProfilesResponse>(profilesKeys.all(params), () => fetchProfilesV2(params), options);

export const useProfilesInfinite = (
  params: FetchProfileQueryParams,
  options: InfiniteQueryOptions<FetchProfilesResponse> = {}
) =>
  useInfiniteQuery({
    queryKey: profilesKeys.all(params),
    queryFn: ({ pageParam = 1 }) => fetchProfilesV2({ ...params, page: pageParam }),
    getNextPageParam,
    ...options
  });
