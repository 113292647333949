const cache = {};

function getExpire(url) {
  const params = new URLSearchParams(url.replace(/[^?]*\?/, ""));
  return params.get("Expires");
}

function isExpired(url) {
  return getExpire(url) * 1000 < +new Date() + 3600 * 1000;
}

export function fromCache(id, mode, url) {
  const key = `${mode}:${id}:${url.replace(/\?.*/, "")}`;
  const prevUrl = cache[key];

  if (!prevUrl || isExpired(prevUrl)) {
    cache[key] = url;
    return url;
  } else {
    return prevUrl;
  }
}
