import { isEmpty } from "lodash";
import { Typography } from "PFComponents/typography";
import { Variant } from "PFComponents/typography/typography.types";

import css from "./emphasized_text.module.scss";

type EmphasizedTextProps = {
  text: string;
  tag?: string;
  variant?: Variant;
  emphasizedVariant?: Variant;
  className?: string;
};

export const EmphasizedText = ({
  text,
  tag = "em",
  variant = "bodyRegular",
  emphasizedVariant = "bodyBold",
  className
}: EmphasizedTextProps) => {
  const startTag = `<${tag}>`;
  const endTag = `</${tag}>`;

  const parts = text.replaceAll(endTag, startTag).split(startTag);

  return (
    <span className={className}>
      {parts.map((part, index) =>
        !isEmpty(part) ? (
          <Typography
            key={index}
            variant={index % 2 === 0 ? variant : emphasizedVariant}
            className={css.textPart}
            noMargin
          >
            {part}
          </Typography>
        ) : null
      )}
    </span>
  );
};
