import { Icon } from "PFComponents/icon";

import ButtonLabel from "../../../parts/button_label";
import css from "./review_section.module.scss";

type ReviewerLabelProps = { name: string; date: string };

export const ReviewerLabel = ({ name, date }: ReviewerLabelProps) => (
  <ButtonLabel className={css.label}>
    <Icon name="check" size="sm" color="paletteGreen0" />
    <span className={css.center}>{name}</span> <span className={css.nowrap}>- {date}</span>
  </ButtonLabel>
);
