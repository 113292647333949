import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const useMatchesStatusText = (status?: string): ReactNode => {
  const { t } = useTranslation("activities");

  switch (status) {
    case "matches_outdated":
      return t("matchesStatus.matchesOutdated");
    case "matching":
      return t("matchesStatus.matching");
    case "prefiltering":
    case "filters_outdated":
      return t("matchesStatus.filtersOutdated");
    case "matching_availabilities":
    case "availability_outdated":
      return t("matchesStatus.availabilityOutdated");
    case "activity_desynchronized":
    case "activity_not_synchronized":
    default:
      return (
        <>
          <p>{t("matchesStatus.tooManyRequests")}</p>
          <p>{t("matchesStatus.waitingForAvailableSlot")}</p>
        </>
      );
  }
};
