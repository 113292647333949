import classNames from "classnames";

import { Typography } from "../typography";
import css from "./skip_link.module.scss";

type SkipLinkProps = {
  targetId: string;
  text: string;
  className?: string;
};

export const SkipLink = ({ targetId, text, className }: SkipLinkProps) => (
  <div className={classNames(css.skipLinkContainer, className)}>
    <Typography variant="bodyButtons" tag="a" href={`#${targetId}`} className={css.skipLink}>
      {text}
    </Typography>
  </div>
);
