import classNames from "classnames";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

import commonCss from "../../questions_modal.module.scss";
import { FormValues, QuestionData } from "../../questions_modal.types";
import Question from "./question";
import css from "./questions_section.module.scss";

interface Props {
  questionFields: QuestionData[];
  isEditMode: boolean;
  control: Control<FormValues>;
  className?: string;
}

const QuestionsSection = ({ questionFields, control, isEditMode, className }: Props): JSX.Element => {
  const { t } = useTranslation("activities");

  return (
    <section className={className}>
      <h5 className={classNames(commonCss.noMargin, commonCss.formTitle)}>
        {t("questionsModal.pleaseAnswerQuestions")}
      </h5>
      <ol className={css.list}>
        {questionFields.map(({ id, label, name }) => (
          <Question key={id} name={name} label={label} isEditMode={isEditMode} control={control} />
        ))}
      </ol>
    </section>
  );
};

export default QuestionsSection;
