import classNames from "classnames";
import { isUndefined } from "lodash";
import { TableColumn } from "PFApp/components/table/table_types";
import { ActionIcon } from "PFComponents/action_icon";
import { Tile } from "PFComponents/tile";
import Toggle from "PFComponents/toggle/toggle";
import { Typography } from "PFComponents/typography";
import { useOptionalTranslation } from "PFCore/hooks/use_optional_translation";
import { Draggable } from "react-beautiful-dnd";

import css from "./table_properties.module.scss";

type TableColumnPillProps = {
  index?: number;
  isDraggable?: boolean;
  columnData: TableColumn;
  onVisibilityChange: (columnData: TableColumn, selected: boolean) => void;
};

export const TableColumnPill = ({
  isDraggable,
  index,
  columnData,
  onVisibilityChange
}: TableColumnPillProps) => {
  const { optionalT } = useOptionalTranslation("workflow");

  const renderTableColumnPill = (dragHandleProps?) => (
    <Tile
      className={classNames(css.columnPill, { [css.inactive]: !columnData.selected })}
      light={!isDraggable}
    >
      <span {...(isDraggable ? dragHandleProps : {})}>
        <ActionIcon name="move" size="sm" disabled={!isDraggable} />
      </span>

      <Typography className={css.columnName} variant="labelRegular" tag="span" noMargin clipOverflow>
        {optionalT(columnData.title)}
      </Typography>

      <Toggle
        checked={columnData.selected}
        onChange={() => onVisibilityChange(columnData, !columnData.selected)}
        disabled={columnData.locked}
        className={css.visibilityIcon}
        compact
      />
    </Tile>
  );

  if (!isDraggable || isUndefined(index)) {
    return renderTableColumnPill();
  }

  return (
    <Draggable index={index} draggableId={columnData.name}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps?.style,
            position: "static",
            height: snapshot.isDragging ? "0px" : "48px"
          }}
        >
          {renderTableColumnPill(provided.dragHandleProps)}
        </div>
      )}
    </Draggable>
  );
};
