import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { CurrentAccount } from "PFTypes";

export const isFeatureEnabled =
  (account: CurrentAccount): ((string) => boolean) =>
  (name) => {
    const features = account && account.active_features;
    return !!(features && features.filter((feature) => feature.name === name).length > 0);
  };

export default function useIsFeatureEnabled(): (string) => boolean {
  const { data: currentAccount } = useCurrentAccount();

  return isFeatureEnabled(currentAccount);
}
