
/*
*
* Profile show page
*   - Header
*     - Avatar
*     - Bio
*     - ...
*     - Custom Fields with section profile_basic_info
*   - Experience
*     - Current position
*       - Custom fields with section profile_experience_highlights
*     - Past positions
*       - Custom fields from experience (positions endpoint)
*     - ...
*     - Custom Fields from section profile_expertise
*       - We split this in current and past based on section profile_past_experience
*
*/

const PROFILE_SECTIONS = {
  // profile_basic_info: Custom fields displayed in the Profile Header
  profile_basic_info: "profile_basic_info",

  // current_experience_title_1 & 2: Custom fields displayed in the Profile Current Position
  current_experience_title_1: "current_experience_title_1",
  current_experience_title_2: "current_experience_title_2",

  // past_experience_title_1 & 2: Custom fields displayed in the Profile Past Positions
  past_experience_title_1: "past_experience_title_1",
  past_experience_title_2: "past_experience_title_2",

  // profile_private_fields: Custom fields never displayed to other users (hidden skills...)
  profile_private_fields: "profile_private_fields",

  // profile_expertise: Custom fields displayed in the Profile Experience
  profile_expertise: "profile_expertise",

  // profile_experience_highlights: Custom types filter for current experience custom fields
  profile_experience_highlights: "profile_experience_highlights",

  // profile_past_experience: Custom types filter to split Custom Type Section in Current and Past
  profile_past_experience: "profile_past_experience",

  // editable_only_by_admins: Custom field not displayed if no data. Edit button not displayed
  editable_only_by_admins: "editable_only_by_admins"
};

export default PROFILE_SECTIONS;
