import ColumnsHeaders from "PFApp/components/table/columns_headers";

import { useInterestedContext } from "./interested_context_provider";
import css from "./interested_table.module.scss";

const InterestedColumnsHeaders = () => {
  const {
    selectedColumns: columns,
    watchersMetadata,
    watchers,
    setSelectedProfiles,
    selectedProfiles,
    orderStorage
  } = useInterestedContext();
  const { setSelectedOrder } = orderStorage;

  return (
    <ColumnsHeaders
      className={css.headers}
      columns={columns}
      //@ts-ignore
      order={watchersMetadata?.order}
      onOrderChange={setSelectedOrder}
      bulkSelectProps={{
        selectedItems: selectedProfiles,
        setSelectedItems: setSelectedProfiles,
        allItems: watchers,
        showDropdown: false
      }}
    />
  );
};

export default InterestedColumnsHeaders;
