import classnames from "classnames";
import { HTMLAttributes } from "react";

import { AutoSelectValue, AutoSelectValueProps, SelectValue } from "./auto_select_value";
import css from "./autoselect_values.module.scss";

type AutoSelectValuesProps = Omit<AutoSelectValueProps, "value"> & {
  values: SelectValue[];
  disabled?: boolean;
  style: HTMLAttributes<HTMLDivElement>["style"];
};

export const AutoSelectValues = ({ values, disabled, style, ...props }: AutoSelectValuesProps) => {
  const { locked, multi } = props;
  const shownValues = (multi ? values : values.slice(0, 1)) || [];

  if (shownValues.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames(css.multiValues, {
        disabled: disabled
      })}
      style={style}
    >
      {shownValues.map((value) => (
        <AutoSelectValue key={value.id} {...props} value={value} locked={locked || value.item.locked} />
      ))}
    </div>
  );
};
