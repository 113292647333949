import { useMutation } from "@tanstack/react-query";
import { useBookingsOverviewContext } from "PFApp/booking/parts/overview/context/bookings_overview_context";
import useRowChildrenInvalidate from "PFApp/booking/parts/overview/side_panel_row/queries/use_row_children_invalidate";
import { CalendarMode } from "PFApp/booking/types";
import { reassignBookings, ReassignBookingsPayload } from "PFCore/services/bookings_reassign";
import { MutationOptions } from "PFTypes";

import { useProfileInvalidate } from "../../profile";
import { useBookingsForUpdateInvalidate } from "../bulk_operation/use_bookings_for_update_invalidate";

type UseBookingsReassign = {
  options?: MutationOptions<ReassignBookingsPayload>;
};

export const useBookingsReassign = ({ options }: UseBookingsReassign) => {
  const { invalidateProfile } = useProfileInvalidate();
  const { invalidateByActivityIds } = useRowChildrenInvalidate();
  const { invalidate: invalidateSelectMode } = useBookingsForUpdateInvalidate();
  const { calendarMode } = useBookingsOverviewContext();
  return useMutation(async (payload: ReassignBookingsPayload) => reassignBookings(payload), {
    ...options,
    onSuccess: (...args) => {
      const { activityId, newProfileId, oldProfileId } = args[1] as ReassignBookingsPayload;
      invalidateSelectMode("total_selection");
      oldProfileId && invalidateProfile(oldProfileId);
      newProfileId && invalidateProfile(newProfileId);
      calendarMode === CalendarMode.Projects && activityId && invalidateByActivityIds([activityId]);
      options?.onSuccess?.(...args);
    }
  });
};
