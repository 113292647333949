import { ProfileInfoEntry, useProfileCustomValues } from "PFApp/hooks/use_profile_custom_values";
import { Profile } from "PFTypes";

type UseReassignCustomInfo = {
  profile: Partial<Profile> & Pick<Profile, "positions">;
};

type UseReassignCustomInfoReturn = {
  reassignCustomInfo: ProfileInfoEntry | undefined;
};

export const useReassignCustomInfo = ({ profile }: UseReassignCustomInfo): UseReassignCustomInfoReturn => {
  const { getCustomValue } = useProfileCustomValues();

  return {
    reassignCustomInfo: getCustomValue(profile, "match_info_1")
  };
};
