import { ProfilesHeader } from "./parts/profiles_header";
import { ProfilesList } from "./parts/profiles_list";
import css from "./profiles_page.module.scss";

export const ProfilesPage = () => (
  <div className={css.root}>
    <ProfilesHeader />
    <ProfilesList />
  </div>
);
