import { ActionDiv } from "PFComponents/divs";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { Experience } from "PFTypes";
import React from "react";

import { ExperienceRadial } from "./parts/experience_radial";
import { ExperienceTooltip } from "./parts/experience_tooltip";
import css from "./skill_radial.module.scss";
import { useExperienceRadial } from "./use_experience_radial";

type SkillRadialProps = {
  name: string;
  experience?: Experience | null;
  expectedExperience?: Experience | null;
  isRankable?: boolean;
  isMissing?: boolean;
  isCore?: boolean;
  truncateLength?: number;
  onClick?: VoidFunction;
};

export const SkillRadial = ({
  name,
  experience = null,
  expectedExperience = experience,
  isRankable = true,
  isMissing = false,
  isCore = false,
  truncateLength = 40,
  onClick
}: SkillRadialProps): JSX.Element => {
  const { colorVariants, icon } = useExperienceRadial({ experience, expectedExperience, isMissing });

  const Wrapper = onClick
    ? ({ children }) => (
        <ActionDiv ariaLabel={name} onClick={onClick} className={css.actionDiv}>
          {children}
        </ActionDiv>
      )
    : React.Fragment;

  const isNameTooLong = name.length > truncateLength;

  return (
    <Wrapper>
      <span className={css.skillRadial}>
        {isRankable ? (
          <Tooltip
            content={<ExperienceTooltip experience={experience} expectedExperience={expectedExperience!} />}
            disabled={!expectedExperience}
          >
            <div>
              <ExperienceRadial colorVariants={colorVariants} icon={icon} />
            </div>
          </Tooltip>
        ) : (
          <Icon
            name={isMissing ? "cross" : "check"}
            color={isMissing ? "paletteRed0" : "paletteBlue0"}
            size="sm"
          />
        )}
        <Tooltip content={<Typography variant="labelButton">{name}</Typography>} disabled={!isNameTooLong}>
          <div className={css.nameWrapper}>
            <Typography variant="labelButton" className={css.name}>
              {name.slice(0, truncateLength)}
              {isNameTooLong && "..."}
            </Typography>
          </div>
        </Tooltip>
        {isCore && <Icon name="core" />}
      </span>
    </Wrapper>
  );
};
