import { Divider } from "PFComponents/divider";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { getActivityCustomFieldValues } from "PFCore/helpers/activity_custom_fields";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../role_information.module.scss";

type RoleSkillsProps = {
  role: Activity;
};

export const RoleSkills = ({ role }: RoleSkillsProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.roleInfo" });

  const skills = getActivityCustomFieldValues(role, "skills");

  if (skills.length === 0) {
    return null;
  }

  return (
    <div className={css.skills}>
      <div className={css.skillsTitle}>
        <Icon name="core" color="paletteBlue1" />
        <Typography variant="bodyBold" noMargin>
          {t("skills")}
        </Typography>
      </div>
      <Divider color="palettePrimary5" />
      {skills.map((skill) => (
        <Typography variant="labelRegular" key={`skill-${skill.id}`}>
          {skill.text}
        </Typography>
      ))}
    </div>
  );
};
