import { TableColumn } from "PFApp/components/table/table_types";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";

const useCustomInterestColumns = (): {
  customColumns: TableColumn[];
} => {
  const { customTypes } = useCustomTypes();

  const matchingCustomTypes = (customTypes || []).filter(
    (customType) => !!customType.sections.find((section) => section.key === "filters_interest")
  );

  const customColumns: TableColumn[] = matchingCustomTypes.map((customType) => ({
    name: customType.name || "",
    title: customType.display_as || "",
    order: customType.position,
    kind: customType.kind,
    type: "custom_field",
    attribute: ["profile", customType.name || ""]
  }));

  return { customColumns };
};

export default useCustomInterestColumns;
