import { Legend } from "PFApp/booking/parts/overview/legend";
import { AvailabilityContent, AvailabilityContext } from "PFApp/profiles/common/availability";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Profile } from "PFTypes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PageSection } from "../page_section";
import css from "./availability_section.module.scss";
import { AvailabilitySectionToolbar } from "./parts/availability_section_toolbar";

const AvailabilityLegend = () => (
  <Legend theme={css} display={{ warnings: false, engagements: false, roles: false }} />
);

type AvailabilitySectionProps = {
  profile: Profile;
  isLoading: boolean;
  className: string;
  isAdmin: boolean;
  isMe: boolean;
  onExpand: () => void;
  isExpanded: boolean;
  shouldHideExpandAction: boolean;
  myRef: React.RefObject<HTMLDivElement>;
};

export const AvailabilitySection = ({
  profile,
  isLoading,
  className,
  isAdmin,
  isMe,
  onExpand,
  isExpanded,
  shouldHideExpandAction,
  myRef
}: AvailabilitySectionProps): JSX.Element | null => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation(["profiles", "availability"]);

  /* @ts-ignore */
  const [activeTab, setActiveTab] = useState<HeaderToggleType>("calendar");

  const getProfileFieldsAccessLevel = (profile, key) => {
    try {
      const pgId = profile.permissions_group.id;
      const pg = currentProfile.permissions_group.cross_group_permissions[pgId];
      return pg.profile_fields_access_levels[key];
    } catch {
      return null;
    }
  };

  if (!getProfileFieldsAccessLevel(profile, "availability")) {
    return null;
  }

  const isCalendarDisplayed = activeTab === "calendar";

  return (
    <AvailabilityContext isAdmin={isAdmin} profile={profile} isSearchEnabled>
      <PageSection
        id="availability"
        title={t("profiles:show.parts.availability.title")}
        hType="h3"
        action={
          <AvailabilitySectionToolbar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isMe={isMe}
            profile={profile}
            isExpanded={isExpanded}
            onExpand={onExpand}
            shouldHideExpandAction={shouldHideExpandAction}
            isLoading={isLoading}
          />
        }
        footer={<AvailabilityLegend />}
        className={className}
        contentStyle={{ overflow: isCalendarDisplayed ? "auto" : "hidden", marginTop: 0 }}
      >
        <div ref={myRef} />
        <AvailabilityContent
          profile={profile}
          isLoading={isLoading}
          activeViews={[activeTab]}
          isAdmin={isAdmin}
          readOnly={!isMe}
          isExpanded={isExpanded}
        />
      </PageSection>
    </AvailabilityContext>
  );
};
