import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { PostUntilChangeReason } from "PFTypes";
import { useTranslation } from "react-i18next";

type ExpiryDateEditModeProps = {
  postUntil: string;
  reason: PostUntilChangeReason | null;
};

export const ExpiryDateEditMode = ({ postUntil, reason }: ExpiryDateEditModeProps): JSX.Element => {
  const { t } = useTranslation("activities", { keyPrefix: "edit.sections.expiryDateSection" });
  const { formatDate } = useDateFormatter();
  return (
    <div>
      <Typography variant="labelRegular">{t("replyByDate")}</Typography>
      <Typography variant="bodyBold">{formatDate(postUntil)}</Typography>
      {reason && (
        <>
          <Typography variant="labelRegular">{t("reasonToChange")}</Typography>
          <Typography variant="bodyBold">{reason.text ?? ""}</Typography>
        </>
      )}
    </div>
  );
};
