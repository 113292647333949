import { DateMinMax, IsoDate } from "./date_time";

export type BytesNumber = number;
import { Id } from "./id";

export type Percentage = number; // percentage as a fraction min 0

export enum PageTarget {
  ReportsProfiles = "reports_profiles",
  ReportsEngagements = "reports_engagements",
  ReportsAuditEngagements = "reports_audit_engagements",
  ReportsRoles = "reports_roles",
  ReportsAuditRoles = "reports_audit_roles"
}

export type AccountReportUsage = {
  dateRange: DateMinMax;
  used: BytesNumber;
};

export type AccountReportLimits = {
  warningLevel: Percentage;
  criticalLevel: Percentage;
  limit: BytesNumber;
  createdAt: IsoDate;
};

export type AccountReportingStatus = {
  used: Percentage;
  warning: Percentage;
  critical: Percentage;
};

export type RoleReportData = {
  profileId: Id;
  profileIds: Id[];
  timeToShortlist?: number;
  timeToAccept?: string;
  timeToFill?: number;
  taskId: number;
  taskName: string;
  name: string;
  customValue: string[];
};
