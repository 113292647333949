import AppContext from "PFApp/app_context";
import CardTx from "PFApp/components/card_tx/card_tx";
import ProfileCardConnected from "PFApp/components/profile_card_connected";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./results_all.module.scss";

const Header = ({ name, meta, numberOfItems }) => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation("search");

  const redirectTab = name === "profiles" ? "matches" : name;

  return (
    <div className={css.header}>
      <Typography variant="h2">{t(`tabs.${name}`)}</Typography>
      <div className={css.actions}>
        {meta.total ? t("tabs.show", { number: numberOfItems, of: meta.total }) : t("tabs.noResults")}
        {meta.total > 0 && (
          <Button
            kind="blank"
            className={css.button}
            onClick={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TAB, payload: redirectTab })}
          >
            {t("tabs.seeAll", { template: t(`tabs.${name}`) })}
          </Button>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  name: PropTypes.string,
  meta: PropTypes.shape({
    total: PropTypes.number
  }),
  numberOfItems: PropTypes.number
};

const ResultsAll = () => {
  const {
    store: {
      search: { matches, matchesMeta, allActivities, availableTemplates }
    }
  } = useContext(AppContext);
  const { data: currentProfile } = useCurrentProfile();
  const isChatEnabled = useIsChatEnabled();

  const listOfActivitiesKeys = useMemo(
    () => availableTemplates?.map((activity) => activity.key) || [],
    [availableTemplates]
  );

  const data = useMemo(() => {
    const activities = listOfActivitiesKeys.reduce(
      (acc, item) => ({
        ...acc,
        [item]: {
          ...allActivities?.[item],
          entries: allActivities?.[item].entries.slice(0, 3)
        }
      }),
      {}
    );
    return {
      profiles: { entries: matches.slice(0, 3), meta: matchesMeta },
      ...activities
    };
  }, [listOfActivitiesKeys, allActivities, matches, matchesMeta]);

  return (
    <>
      <div>
        <Header name="profiles" meta={matchesMeta} numberOfItems={data["profiles"].entries.length} />
        <div className={css.results}>
          {data["profiles"].entries.map((profile) => (
            <ProfileCardConnected
              key={profile.id}
              profileResponse={profile}
              withChat={isChatEnabled}
              currentProfile={currentProfile}
            />
          ))}
        </div>
      </div>
      {listOfActivitiesKeys.map((activity) => {
        const activitiesData = data[activity];

        if (!activitiesData.entries || activitiesData.entries.length === 0) {
          return null;
        }
        return (
          <div key={activity}>
            <Header
              name={activity}
              meta={data[activity].meta}
              numberOfItems={data[activity].entries.length}
            />
            <div className={css.results}>
              {data[activity].entries.map((item) => (
                <CardTx key={item.id} task={item.activity} />
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ResultsAll;
