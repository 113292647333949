import BrowserStorage from "PFCore/base/storage/browser_storage";
import MemoryStorage from "PFCore/base/storage/memory_storage";
import SessionStorage from "PFCore/base/storage/session_storage";

export const resetStorage = () => {
  try {
    window.pfSessionStorage = new SessionStorage();
  } catch {}
  try {
    "localStorage" in window && window.localStorage !== null;
    try {
      window.localStorage.setItem("ping", "pong");
      window.storage = new BrowserStorage();
    } catch (e) {
      window.storage = new MemoryStorage();
    }
  } catch (e) {
    window.storage = new MemoryStorage();
  }
};

resetStorage();
