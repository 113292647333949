import { Collection, Id } from "PFTypes";
import { Phase } from "PFTypes/phase";

import api from "../../api/api";

export const fetchPhases = (activityId?: Id | null): Promise<Collection<Phase[]>> =>
  api({
    url: `/phases`,
    params: { activityId }
  });
