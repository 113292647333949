import AppContext from "PFApp/app_context";
import ShareBar, { ShareWrapper } from "PFApp/components/activity_share/share_bar";
import CardTx from "PFApp/components/card_tx/card_tx";
import CreatePost from "PFApp/feed/parts/post/create_post";
import FreemasonryLayout from "PFComponents/freemasonry/freemasonry_layout";
import { LoadingDots } from "PFComponents/loading_dots";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { useContext, useState } from "react";

import MoreButton from "../parts/more_button";
import EmptyActicities from "./empty_activities";

const ResultsActivities = () => {
  const {
    dispatch,
    store: {
      search: { term, tab, allActivities, allActivitiesMeta, activitiesPending, activitiesPagination }
    }
  } = useContext(AppContext);

  const activities = allActivities?.[tab]?.entries || [];
  const activitiesMeta = allActivitiesMeta?.[tab] || {};

  const [postId, setPostId] = useState(null);
  const [activitiesToShare, setActivitiesToShare] = useState([]);

  const handleEditPost = (taskId) => setPostId(taskId);

  const handleShare = (id) =>
    activitiesToShare.includes(id)
      ? setActivitiesToShare(activitiesToShare.filter((activityId) => activityId !== id))
      : setActivitiesToShare(activitiesToShare.concat(id));

  if (activities.length === 0) {
    if (term.trim() !== "" && activitiesPending) {
      // first loading
      return <LoadingDots circlesEnabled pageViewEnabled />;
    }
    return <EmptyActicities />;
  }

  return (
    <>
      <ShareBar activityIds={activitiesToShare} onCancel={() => setActivitiesToShare([])} />
      {postId && (
        <CreatePost
          handleClose={() => setPostId(null)}
          postId={postId}
          fetchActivities={(_page, _perPage, _tab, resp) => {
            dispatch({ type: SEARCH_ACTIONS.SEARCH_UPDATE_ACTIVITY, payload: resp });
          }}
          tab={null}
          isEdit
        />
      )}
      <FreemasonryLayout minColumnWidth={380} key={activities.length}>
        {activities.map(({ activity }) => (
          <ShareWrapper
            key={activity.id}
            activitiesToShare={activitiesToShare}
            onShare={handleShare}
            activityId={activity.id}
            disabled={activity.state === "draft"}
          >
            <CardTx task={activity} handleEditPost={handleEditPost} fromSearch={true} onShare={handleShare} />
          </ShareWrapper>
        ))}
      </FreemasonryLayout>
      <MoreButton
        isLoading={activitiesPending}
        renderedItemsCount={activities.length}
        total={activitiesMeta.total || 0}
        handleClick={() =>
          dispatch({
            type: SEARCH_ACTIONS.SEARCH_UPDATE_PAGINATION
          })
        }
        perPage={10}
        page={activitiesPagination?.[tab] || 1}
      />
    </>
  );
};
export default ResultsActivities;
