import { TableProperties } from "PFApp/workflow/parts/table_properties";

import { useInterestedContext } from "./interested_context_provider";

const PropertiesPanel = () => {
  const { columns, showTableProperties, toggleShowTableProperties, selectedColumns, setSelectedColumns } =
    useInterestedContext();

  return (
    <TableProperties
      onCloseClick={toggleShowTableProperties}
      onColumnsChange={setSelectedColumns}
      isVisible={showTableProperties}
      defaultColumns={columns}
      selectedColumns={selectedColumns}
    />
  );
};

export default PropertiesPanel;
