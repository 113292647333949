import moment, { Moment } from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking, BookingCategory, CalendarRange, CurrentProfile, Profile } from "PFTypes";
import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

import { getRoleJobCodeDisplayAs } from "../../use_role_job_code";
import { filterBookings } from "./profile_bookings_provider.utils";
import { BookingWithProfileId, useProfileBookings } from "./use_profile_bookings";

export type CalendarSearch = {
  isSearchEnabled: boolean;
  search: string;
  setSearch: (search: string) => void;
};

type ProfileBookingsConfig = { tooltip?: { hideActivityLink?: boolean } };
type ProfileBookingsContextType = {
  selectedBooking: Booking | null;
  bookings: BookingWithProfileId[];
  bookingCategories: BookingCategory[];
  config?: ProfileBookingsConfig;
  jobCodeDisplayAs: string;
  refreshBookings: () => void;
  selectBooking: (booking: Booking) => void;
  handleDateRangeChange: (min: Moment, max: Moment, currentMonth: Moment) => void;
  fetchAllBookings: () => void;
  calendarSearch: CalendarSearch;
};

type ProfileBookingsProviderProps = {
  currentProfile: CurrentProfile;
  profile: Profile;
  config?: ProfileBookingsConfig;
  bookingCategories: BookingCategory[];
  isSearchEnabled?: boolean;
};

const ProfileBookingsContext = createContext({} as ProfileBookingsContextType);

export const ProfileBookingsProvider = ({
  children,
  currentProfile,
  config,
  profile,
  bookingCategories,
  isSearchEnabled
}: PropsWithChildren<ProfileBookingsProviderProps>) => {
  const [selectedBooking, selectBooking] = useState<Booking | null>(null);
  const [search, setSearch] = useState("");
  const { formatISODate } = useDateFormatter();
  const [dateRange, setDateRange] = useState<CalendarRange>();
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [fetchAllEnabled, setFetchAllEnabled] = useState<boolean>(false);

  const { bookings, refreshBookings } = useProfileBookings({
    profile,
    dateRange,
    fetchAllEnabled
  });

  const jobCodeDisplayAs = useMemo(() => getRoleJobCodeDisplayAs(currentProfile), [currentProfile]);

  const handleDateRangeChange = (min: Moment, max: Moment, month: Moment) => {
    setDateRange({
      min: formatISODate(min),
      max: formatISODate(max)
    });
    setCurrentMonth(month);
  };

  return (
    <ProfileBookingsContext.Provider
      value={{
        selectedBooking,
        bookings: isSearchEnabled ? filterBookings(bookings, search, formatISODate(currentMonth)) : bookings,
        bookingCategories,
        config,
        jobCodeDisplayAs,
        refreshBookings,
        selectBooking,
        handleDateRangeChange,
        fetchAllBookings: () => setFetchAllEnabled(true),
        calendarSearch: {
          search,
          setSearch,
          isSearchEnabled: !!isSearchEnabled
        }
      }}
    >
      {children}
    </ProfileBookingsContext.Provider>
  );
};

export const useProfileBookingsContext = () => useContext(ProfileBookingsContext);
