import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { CalendarMode } from "PFApp/booking/types";
import { RowType } from "PFApp/booking/types/row";

import { rowChildrenKeys } from "./query_keys";
import { RowChildrenItem } from "./use_row_children";

const useRowChildrenInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (mode: string, type: string, id: number) =>
      queryClient.invalidateQueries({ queryKey: rowChildrenKeys.ofRow(mode, type, id) }),
    invalidateAll: () => queryClient.invalidateQueries({ queryKey: rowChildrenKeys.all() }),
    removeAllCache: () => queryClient.removeQueries({ queryKey: rowChildrenKeys.all() }),
    invalidateByActivityIds: (activityIds: number[]) => {
      const cacheEntries: [QueryKey, RowChildrenItem?][] = queryClient
        .getQueriesData<RowChildrenItem>({ predicate: rowChildrenKeys.isRowChildrenQuery })
        .filter(
          //eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([[all, mode, type, id], rowChildrenData]) =>
            ([RowType.Engagement, RowType.Role].includes(type as RowType) &&
              activityIds.includes(id as number)) ||
            !!rowChildrenData?.entries.find((i) => activityIds.includes(i.activity?.id))
        );
      if (!cacheEntries || !cacheEntries.length) {
        return;
      }
      return Promise.all(cacheEntries.map(([queryKey]) => queryClient.invalidateQueries({ queryKey })));
    },
    invalidateByProfileIds: (profileIds: number[]) =>
      Promise.all(
        profileIds.map((profileId) =>
          queryClient.invalidateQueries({
            queryKey: rowChildrenKeys.ofRow(CalendarMode.Workforce, RowType.Profile, profileId)
          })
        )
      )
  };
};

export default useRowChildrenInvalidate;
