import {
  CamelizedBooking,
  OverbookingsManagementMode,
  SuggestedChangeAction,
  SuggestedChangesPayload
} from "PFCore/services/bookings_suggested_changes";

import { BookingFormValues } from "../booking_form/booking_form_provider";
import { isGroupOption } from "../booking_form/workforce_member_select/workforce_member_select_dropdown";
import { UpdatedBookingChange } from "./overbookings_management_modal";

export const bookingFormValuesToSuggestedChangesData = (
  bookingFormValues: BookingFormValues,
  overbookedDates: string[],
  mode: OverbookingsManagementMode | null
): SuggestedChangesPayload | null => {
  if (!bookingFormValues?.workforceMember || isGroupOption(bookingFormValues.workforceMember) || !mode) {
    return null;
  }

  const profileId = bookingFormValues.workforceMember.id;
  const bookings = bookingFormValues.bookings.map((booking) => ({
    id: booking?.id,
    startDate: booking.startDate,
    endDate: booking.endDate,
    requirementValue: booking.requirement?.value,
    requirementType: booking.requirement.type,
    category: booking.category ?? undefined
  }));

  return {
    profileId,
    overbookedDates,
    mode,
    bookings
  };
};

type ApplySuggestedChangesReturn = {
  bookingFormValues: BookingFormValues;
  bookingsToUpdate: CamelizedBooking[];
  bookingsToCreate: CamelizedBooking[];
  bookingsToDelete: CamelizedBooking[];
};

export const applySuggestedChanges = (
  mode: OverbookingsManagementMode,
  bookingFormValues: BookingFormValues,
  suggestedChanges: UpdatedBookingChange[]
): ApplySuggestedChangesReturn => {
  const { bookings } = bookingFormValues;

  const bookingsToUpdate = suggestedChanges.flatMap(({ booking, changes }) =>
    changes
      .filter(({ action, data }) => action === SuggestedChangeAction.Update && !!data)
      .map(({ data }) => {
        if (!data) {
          return booking;
        }
        return {
          ...booking,
          description: (booking as CamelizedBooking).description ?? "",
          startDate: data.startDate,
          endDate: data.endDate,
          requirementValue: data.requirementValue
        };
      })
  ) as CamelizedBooking[];

  const bookingsToDelete = suggestedChanges
    .filter(({ shouldBeRemoved }) => shouldBeRemoved)
    .flatMap(({ booking }) => booking) as CamelizedBooking[];

  if (mode === OverbookingsManagementMode.AdjustOther) {
    const bookingsToCreate = suggestedChanges
      .flatMap(({ booking, changes }) =>
        changes
          .filter((change) => change.action === SuggestedChangeAction.Create && change?.data)
          .flatMap(({ data }) => {
            if (!data) {
              return [];
            }

            return [
              {
                ...booking,
                startDate: data.startDate,
                endDate: data.endDate,
                requirementValue: data.requirementValue
              }
            ];
          })
      )
      .filter((data) => !!data) as CamelizedBooking[];

    return {
      bookingFormValues,
      bookingsToUpdate,
      bookingsToCreate,
      bookingsToDelete
    };
  }

  const updatedBookings = bookings.flatMap((booking, index) => {
    const suggestedChange = suggestedChanges[index];

    const isDeleteAction = suggestedChange.changes.find(
      (change) => change.action === SuggestedChangeAction.Delete
    );
    if (isDeleteAction) {
      return suggestedChange.shouldBeRemoved ? [] : [booking];
    }

    if (suggestedChange.changes.length === 0) {
      return [booking];
    }

    return suggestedChange.changes.map((change) => {
      if (change.data) {
        return {
          ...booking,
          id: change.action === SuggestedChangeAction.Create ? undefined : booking.id,
          startDate: change.data.startDate,
          endDate: change.data.endDate,
          requirement: {
            value: change.data.requirementValue,
            type: booking.requirement.type
          }
        };
      }
      return booking;
    });
  });

  return {
    bookingFormValues: {
      ...bookingFormValues,
      bookings: updatedBookings
    },
    bookingsToUpdate,
    bookingsToCreate: [],
    bookingsToDelete
  };
};
