import { omit } from "lodash";

export const removeChildrenFiltersIfNeeded = <T extends { children?: any }>(
  filters: T,
  removeChildren: boolean
): T | Omit<T, "children"> => {
  if (!removeChildren) {
    return filters;
  }
  return omit(filters ?? {}, "children");
};
