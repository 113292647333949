import { ProfileItem } from "PFApp/components/profile_item";
import { useGrowl } from "PFApp/use_growl";
import AutoSelect from "PFComponents/select/autoselect";
import { getActiveActivityProfiles } from "PFCore/helpers/get_active_activity_profiles";
import { getProfileName } from "PFCore/helpers/profile";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useOwnerCreate, useOwnerUpdate } from "PFCore/hooks/queries/owners";
import { ActivityProfileType } from "PFTypes";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AddActivityProfiles from "./add_activity_profiles";
import { useOwnerRemove } from "./hooks/use_owner_remove";

export const AddOwnersModal = ({ handleClose, activity }) => {
  const { owners } = activity;
  const { getFirstError } = useResponseErrors();
  const taskOwners = getActiveActivityProfiles(activity.owners);
  const currentPrincipalOwner = owners.find(({ principal }) => principal)?.profile;

  const [newProfiles, setNewProfiles] = useState([]);
  const [principalOwner, setPrincipalOwner] = useState(currentPrincipalOwner);
  const { t } = useTranslation("activities");
  const growl = useGrowl();

  const { mutate: createOwner } = useOwnerCreate();
  const { mutate: updateOwner } = useOwnerUpdate(activity.id);

  const { handleRemove, getRemoveModalContent } = useOwnerRemove(activity, handleClose);

  const principalOwnerOptions = useMemo(
    () =>
      owners
        .filter(({ profile }) => profile.active !== false)
        .map(({ profile }) => profile)
        .concat(newProfiles),
    [owners, newProfiles]
  );

  useEffect(() => {
    if (principalOwner && !principalOwnerOptions.find(({ id }) => principalOwner.id === id)) {
      setPrincipalOwner(null);
    }
  }, [principalOwnerOptions, principalOwner]);

  const handleSubmit = (addedProfiles) => {
    const removedPrincipalOwner = !principalOwner && owners.find((owner) => owner.principal);
    const existingPrincipalOwner =
      principalOwner && owners.find((owner) => owner.profile_id === principalOwner.id && !owner.principal);

    if (removedPrincipalOwner || existingPrincipalOwner) {
      updateOwner(
        {
          id: (removedPrincipalOwner || existingPrincipalOwner).id,
          principal: !!principalOwner
        },
        {
          onSuccess: () => {
            growl({
              message: t("show.parts.principalOwnerUpdated"),
              kind: "success"
            });
          }
        }
      );
    }
    addedProfiles.forEach((profile) =>
      createOwner(
        {
          profileId: profile.id,
          activityId: activity.id,
          ...(principalOwner?.id === profile.id ? { principal: true } : {})
        },
        {
          onSuccess: () =>
            growl({
              message: t("show.parts.profileIsCoOwner", {
                profileName: getProfileName(profile),
                taskName: activity.name
              }),
              kind: "success"
            }),
          onError: ({ response }) => {
            const error = getFirstError(response);
            growl({
              message: error || t("show.parts.taskNotShared", { taskName: activity.name }),
              kind: "error"
            });
          }
        }
      )
    );
  };

  return (
    <AddActivityProfiles
      task={activity}
      profiles={taskOwners}
      onClose={handleClose}
      onRemove={handleRemove}
      onSubmit={handleSubmit}
      submitButtonLabel={t("show.parts.shareAddCoOwner")}
      title={t("show.parts.addCoOwner")}
      description={t("show.parts.ownersModalDescription")}
      newProfiles={newProfiles}
      onNewProfilesChange={setNewProfiles}
      getRemoveModalContent={getRemoveModalContent}
      activityProfileType={ActivityProfileType.Owner}
      enableSubmit={principalOwner !== currentPrincipalOwner}
    >
      <AutoSelect
        key={JSON.stringify(principalOwnerOptions.map((id) => id))}
        values={principalOwner ? [principalOwner] : null}
        label={t("show.parts.ownersSelectLabel")}
        closeOnChange
        query={(term) =>
          Promise.resolve(
            principalOwnerOptions.filter((item) =>
              getProfileName(item).toLowerCase().includes(term.toLowerCase())
            )
          )
        }
        displayValues={principalOwner ? <ProfileItem profile={principalOwner} /> : null}
        handleChange={(option) => setPrincipalOwner(option[0] ? option[0] : null)}
        formatOption={(item) => ({
          id: item.id,
          displayElement: <ProfileItem profile={item} />,
          item
        })}
        letClear
      />
    </AddActivityProfiles>
  );
};

AddOwnersModal.propTypes = {
  handleClose: PropTypes.func,
  activity: PropTypes.object
};

export default AddOwnersModal;
