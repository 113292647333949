import { shouldShowTerms } from "PFApp/routes/route_helpers";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { Markdown } from "PFComponents/markdown";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile, useCurrentProfileUpdate } from "PFCore/hooks/queries/profile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { DefaultTermsOfService } from "./default_terms_of_service";
import css from "./terms_of_service.less";

const TermsOfService = () => {
  const { t } = useTranslation("onboarding");
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { mutateAsync: updateCurrentProfile } = useCurrentProfileUpdate();

  const growl = useGrowl();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const shouldAcceptTerms = shouldShowTerms(currentAccount, currentProfile);

  useEffect(() => {
    if (shouldAcceptTerms) {
      growl({ message: t("termsOfService.acceptPrompt") });
    }
  }, [shouldAcceptTerms]);

  const rawCopy = currentAccount?.customization?.terms_and_conditions?.trim();

  const handleAcceptTerms = () => {
    setLoading(true);
    updateCurrentProfile({ agree_to_terms_and_conditions: true, id: currentProfile.id })
      .then(() => {
        setLoading(false);
        growl({ message: t("termsOfService.accepted") });
        history.push("/");
      })
      .catch(() => {
        setLoading(false);
        growl({ kind: "error", message: t("termsOfService.error") });
      });
  };

  return (
    <div className={css.container}>
      {rawCopy ? <Markdown raw={rawCopy} crop={1e10} emojify={false} /> : <DefaultTermsOfService />}
      {shouldAcceptTerms && (
        <Button
          text={t("translation:accept")}
          disabled={loading}
          onClick={handleAcceptTerms}
          style={{ margin: "20px 0px" }}
        />
      )}
    </div>
  );
};

export default TermsOfService;
