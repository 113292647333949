import { useEffect, useState } from "react";

const useOnceWhen = (check, fn, cleanup) => {
  const [used, setUsed] = useState(false);

  useEffect(() => {
    if (check && !used) {
      setUsed(true);
      fn();
    }
    if (check && used) {
      return () => cleanup();
    }
  }, [check, used]);
};

export default useOnceWhen;
