import { ProfileItem } from "PFApp/components/profile_item";
import Panel from "PFComponents/panel/panel";
import AutoSelect from "PFComponents/select/autoselect";
import { fetchProfilesOptions } from "PFCore/services/profile";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { Note } from "../components/note";
import css from "./sections.module.scss";

const Assignee = ({ assignee, isRequired, errors, onChange }) => {
  const { t } = useTranslation("activities");

  return (
    <Panel className={css.wrapper} id="assignee">
      <div className={css.header}>
        {t("edit.sections.assignee")} {isRequired && "*"}
      </div>
      <AutoSelect
        values={assignee ? [assignee] : null}
        label={t("selectEllipsis")}
        displayValues={assignee ? <ProfileItem profile={assignee} /> : null}
        closeOnChange
        multi={false}
        query={(term) => fetchProfilesOptions({ term, policyName: "resourcer" })}
        error={errors.assignee}
        parseResponse={(response) => response.entries.filter((item) => !assignee || assignee.id !== item.id)}
        formatOption={(item) => ({
          id: item.id,
          displayElement: <ProfileItem profile={item} />,
          item: item
        })}
        handleChange={(profiles) => {
          onChange(profiles[0]);
        }}
        letClear={!isRequired}
      />
      <Note>
        <Trans i18nKey="edit.sections.addAssigneeDescription" t={t} components={[<strong key="0" />]} />
      </Note>
    </Panel>
  );
};

Assignee.defaultProps = {
  isRequired: false
};

Assignee.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.object,
  isRequired: PropTypes.bool,
  assignee: PropTypes.shape({
    id: PropTypes.number
  })
};

export default Assignee;
