import moment, { Moment, MomentInput } from "moment";
import { ActionIcon } from "PFComponents/action_icon";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type ChangeMonthButtonProps = {
  dir: number;
  dateLow: MomentInput;
  dateHigh: MomentInput;
  displayMonth: MomentInput;
  setDisplayMonth: (value: Moment) => void;
};

export const ChangeMonthButton = ({
  dir,
  dateLow,
  dateHigh,
  displayMonth,
  setDisplayMonth
}: ChangeMonthButtonProps): JSX.Element => {
  const { t } = useTranslation("core", { keyPrefix: "components.calendar" });

  const handleClick = useCallback(
    //@ts-ignore-line
    () => setDisplayMonth(displayMonth.clone().add(dir, "month")),
    [setDisplayMonth, displayMonth, dir]
  );

  const disabled =
    !dateLow || !dateHigh || moment(dateLow).format("YYYY-MM") <= moment(dateHigh).format("YYYY-MM");

  return (
    <ActionIcon
      name={dir === 1 ? "chevron-right" : "chevron-left"}
      size="sm"
      title={t(dir === 1 ? "nextMonth" : "prevMonth")}
      onClick={handleClick}
      disabled={disabled}
    />
  );
};
