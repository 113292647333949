import { useGrowl } from "PFApp/use_growl";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useEffect } from "react";

export const useAccountSwitched = () => {
  const growl = useGrowl();
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile, isSignedIn } = useCurrentProfile();

  useEffect(() => {
    if (currentAccount.id && isSignedIn) {
      const isAccountSwitched = currentProfile.account_id && currentProfile.account_id !== currentAccount.id;

      if (isAccountSwitched) {
        growl({
          message: `You switched account, be careful`,
          kind: "alert"
        });
      }
    }
  }, [currentAccount?.id, isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps
};
