import { useQuery } from "@tanstack/react-query";
import { fetchInvites, FetchInvitesResponse } from "PFCore/services/invites/fetch_invites";

import { invitesKeys } from "./query_keys";

export const useInvites = (targetId: number | null = null) => {
  const queryKey = invitesKeys.list("Task", targetId ? [targetId] : null);

  return useQuery<FetchInvitesResponse["entries"]>(
    queryKey,
    () => fetchInvites({ targetType: "Task", targetId: targetId! }).then(({ entries }) => entries),
    {
      placeholderData: [],
      enabled: targetId !== null
    } as any
  );
};
