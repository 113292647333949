import { CalendarMode, CalendarView } from "PFApp/booking/types";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import useStorage from "PFCore/helpers/use_storage";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { PermissionRule } from "PFTypes";
import { Dispatch, SetStateAction, useEffect } from "react";

const DEFAULT_VIEW_BY_MODE = {
  [CalendarMode.Workforce]: CalendarView.Compressed,
  [CalendarMode.Projects]: CalendarView.EngagementsAndAuditEngagements
};

export const useCalendarView = (
  mode: CalendarMode
): [CalendarView, Dispatch<SetStateAction<CalendarView>>] => {
  const { data: currentProfile } = useCurrentProfile();
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const isWallPlannerAvailable = isPermittedTo(PermissionRule.WallPlannerAccess);
  const [view, setView] = useStorage<CalendarView>(`${mode}-view-mode-v2`, DEFAULT_VIEW_BY_MODE[mode]);

  useEffect(() => {
    if (!Object.values(CalendarView).includes(view)) {
      setView(DEFAULT_VIEW_BY_MODE[mode]);
    }
  }, [view]);

  useEffect(() => {
    if (view === CalendarView.WallPlanner && !isWallPlannerAvailable) {
      setView(DEFAULT_VIEW_BY_MODE[CalendarMode.Workforce]);
    }
  }, [isWallPlannerAvailable]);

  return [view, setView];
};
