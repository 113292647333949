import { useQuery } from "@tanstack/react-query";
import { fetchActivity } from "PFCore/services/activities";
import { Activity } from "PFTypes/activity";
import { Id } from "PFTypes/id";
import { QueryOptions } from "PFTypes/request_options";

import activityKeys from "./query_keys";

export const useActivity = (activityId: Id, options: QueryOptions<Activity> = {}) =>
  useQuery<Activity>(activityKeys.activity(activityId), () => fetchActivity(activityId), {
    retry: false,
    staleTime: 0,
    ...options
  });
