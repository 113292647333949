import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { Link } from "react-router-dom";

import css from "../landing_layout.module.scss";
import { Logo } from "./logo";

export const Header = ({ style }: { style?: React.CSSProperties }) => {
  const { data: currentAccount } = useCurrentAccount();
  const customization = currentAccount.customization || {};

  return (
    <div className={css.header} data-qa-id="LandingLayout.header" style={style}>
      <Link className={css.logo} to="/" data-qa-id="LandingLayout.headerLogoLink">
        <Logo height={22} width={96} />
      </Link>
      <span data-qa-id="LandingLayout.contactInfo">{customization.contact_info}</span>
    </div>
  );
};
