import { Button } from "PFComponents/button";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { sendContactMessage } from "PFCore/services/contact";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../use_growl";
import css from "./contact_view.module.scss";

type FormValues = {
  name: string;
  email: string;
  message: string;
};
const DEFAULT_FORM_VALUES: FormValues = { name: "", email: "", message: "" };

const ContactView = (): JSX.Element => {
  const {
    data: {
      customization: { contact_info }
    }
  } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState(DEFAULT_FORM_VALUES);
  const [loading, setLoading] = useState(false);
  const dispatchGrowl = useGrowl();

  useEffect(() => {
    setForm({
      ...form,
      name: currentProfile?.first_name || "",
      email: currentProfile?.email || ""
    });
  }, [currentProfile]);

  const isValidMessage = () => {
    if (form.message === "") {
      setErrors({ message: t("contact.errors.emptyMessage") });
      return false;
    } else {
      return true;
    }
  };

  const onSubmitSuccess = useCallback(() => {
    dispatchGrowl({
      title: t("contact.success.title"),
      message: t("contact.success.message")
    });

    setForm({ name: currentProfile?.first_name || "", email: currentProfile?.email || "", message: "" });
    setErrors({});
    setLoading(false);
  }, []);

  const onSubmitError = useCallback((errs) => {
    dispatchGrowl({
      kind: "error",
      message: t("contact.errors.submit")
    });
    setErrors(errs);
    setLoading(false);
  }, []);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (isValidMessage()) {
        setLoading(true);
        sendContactMessage(form.message).then(onSubmitSuccess).catch(onSubmitError);
      }
    },
    [form.message, onSubmitSuccess, onSubmitError]
  );

  const makeInput = (inputType, label, field, disabled = false): JSX.Element => (
    <InputFieldSet
      inputType={inputType}
      label={label}
      error={errors[field]}
      value={form[field]}
      onChange={(val) => setForm({ ...form, [field]: val })}
      disabled={disabled}
    />
  );

  return (
    <div className={css.contactViewContainer}>
      {/* eslint-disable-next-line camelcase */}
      <h1>{t("contact.generalEnquiries", { contactInfo: contact_info })}</h1>

      <h3>{t("contact.pleaseShareFeedback")}</h3>

      <form className={css.contactViewForm} onSubmit={onSubmit}>
        {makeInput("input", t("contact.name"), "name", true)}
        {makeInput("input", t("contact.email"), "email", true)}
        {makeInput("textarea", t("contact.message"), "message")}

        <Button tag="button" disabled={loading}>
          {t("send")}
        </Button>
      </form>
    </div>
  );
};

export default ContactView;
