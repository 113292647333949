import classNames from "classnames";
import { useCategorizedSkills } from "PFCore/helpers/use_categorized_skills";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import PropTypes from "prop-types";
import { useState } from "react";

import css from "./skills_categories_list.module.scss";
import SkillsList from "./skills_list";

const SkillsCategory = ({ name, skills, profile }) => {
  const [isCategoryCollapsed, setIsCategoryCollapsed] = useState(false);

  return (
    <div>
      <div className={css.categoryHeader}>
        <button
          className={classNames(css.iconButton, { [css.arrowClosed]: isCategoryCollapsed })}
          onClick={() => setIsCategoryCollapsed((prev) => !prev)}
        >
          <DownArrowIcon height={24} width={24} />
        </button>
        {`${name} (${skills.length})`}
      </div>
      {!isCategoryCollapsed && (
        <div className={css.skillListChildren}>
          <SkillsList collection={skills} profileId={profile.id} renderProficiency />
        </div>
      )}
    </div>
  );
};

SkillsCategory.propTypes = {
  name: PropTypes.string.isRequired,
  skills: PropTypes.array.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

const SkillsCategoriesList = ({ skills, profile }) => {
  const [skillCategories, otherSkills] = useCategorizedSkills(skills, profile.skill_categories);

  const skillCategoryNames = Object.keys(skillCategories);

  return (
    <div className={css.skillList}>
      <div>
        {skillCategoryNames.map((skillCategory, index) => (
          <SkillsCategory
            key={`${skillCategory}-${index}`}
            name={skillCategory}
            skills={skillCategories[skillCategory]}
            profile={profile}
          />
        ))}
      </div>
      {otherSkills.length > 0 && <SkillsCategory name="Other" skills={otherSkills} profile={profile} />}
    </div>
  );
};

SkillsCategoriesList.propTypes = {
  skills: PropTypes.array.isRequired,
  profile: PropTypes.shape({
    skill_categories: PropTypes.array,
    id: PropTypes.number.isRequired
  }).isRequired
};

export default SkillsCategoriesList;
