import { Id } from "PFTypes";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";

type UseSidePanelScroll<TAB_ID extends Id> = {
  selectedTabId: TAB_ID | undefined;
};

type UseSidePanelScrollReturn = {
  bodyRef: RefObject<HTMLDivElement>;
  footerContentRef: RefObject<HTMLDivElement>;
  handleScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  hasBorderShadow: boolean;
  hasScroll: boolean;
};

export const useSidePanelScroll = <TAB_ID extends Id>({
  selectedTabId
}: UseSidePanelScroll<TAB_ID>): UseSidePanelScrollReturn => {
  const footerContentRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const initialBodyHeight = bodyRef.current?.clientHeight ?? 0;
  const initialScrollHeight = bodyRef.current?.scrollHeight ?? 0;

  const [hasBorderShadow, setHasBorderShadow] = useState(initialScrollHeight > initialBodyHeight);
  const [hasScroll, setHasScroll] = useState(initialScrollHeight > initialBodyHeight);

  useEffect(() => {
    if (bodyRef.current) {
      const bodyHeight = bodyRef.current?.clientHeight ?? 0;
      const scrollHeight = bodyRef.current?.scrollHeight ?? 0;
      const hasScroll = scrollHeight > bodyHeight;
      setHasScroll(hasScroll);
      setHasBorderShadow(hasScroll);
    }
  }, [selectedTabId, initialBodyHeight, initialScrollHeight]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const isBottom =
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop === event.currentTarget.clientHeight;
      if (isBottom) {
        setHasBorderShadow(false);
      } else if (!hasBorderShadow) {
        setHasBorderShadow(true);
      }
    },
    [hasBorderShadow, selectedTabId]
  );

  return {
    bodyRef,
    footerContentRef,
    handleScroll,
    hasBorderShadow,
    hasScroll
  };
};
