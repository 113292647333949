import { ApiRoute } from "PFCore/utilities/routes";

export const fetchRareSkills = (profileId: number) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute("/api/profile_rare_skills"),
    api_version: 2, // eslint-disable-line camelcase
    data: {
      profile_id: profileId, // eslint-disable-line camelcase
      max_number_of_skills: 5 // eslint-disable-line camelcase
    }
  });
