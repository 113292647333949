import CompareController from "PFApp/activities/compare/compare_controller";
import ActivityEditController from "PFApp/activities/edit/activity_edit_controller";
import ActivityShowController from "PFApp/activities/show/activity_show_controller";
import NotFoundRoute from "PFApp/routes/not_found_route";
import { EnsureLoggedInAndRegistered, WithLayout } from "PFApp/routes/route_helpers";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

const ActivitiesRoutes = () => {
  const location = useLocation();
  const { path } = useRouteMatch();

  return (
    <EnsureLoggedInAndRegistered>
      <WithLayout>
        <Switch>
          <Route path={`${path}/:id/edit`}>
            <ActivityEditController pageType="edit" key={location.key} />
          </Route>
          <Route path={`${path}/:templateType/new/parent_activity/:parentId`}>
            <ActivityEditController pageType="new" key={location.key} />
          </Route>
          <Route path={`${path}/:templateType/new/:id`}>
            <ActivityEditController pageType="clone" key={location.key} />
          </Route>
          <Route path={`${path}/:templateType/new`}>
            <ActivityEditController pageType="new" key={location.key} />
          </Route>
          <Route path={`${path}/:id/compare/:tab/:ids/:type?`}>
            <CompareController />
          </Route>
          <Route path={`${path}/:id/compare/:tab/:ids?`}>
            <CompareController />
          </Route>
          <Route path={`${path}/:id/compare/:tab`}>
            <CompareController />
          </Route>
          <Route path={`${path}/:id/:section/:params?`}>
            <ActivityShowController />
          </Route>
          <Route path={`${path}/:templateType/conversation/:conversationId`}>
            <ActivityShowController withConversation={true} />
          </Route>
          <Route path={`${path}/:id`}>
            <ActivityShowController />
          </Route>
          <Route path="*">
            <NotFoundRoute />
          </Route>
        </Switch>
      </WithLayout>
    </EnsureLoggedInAndRegistered>
  );
};

export default ActivitiesRoutes;
