import AutoSelect from "PFComponents/select/autoselect";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { fetchAutocompleteCustomValues } from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import PropTypes from "prop-types";
import { useState } from "react";

const ReviewCustomField = ({ customField, handleChange, error }) => {
  const { customTypes } = useCustomTypes();
  const { name } = customField.type;
  const customType = customTypes.find((customType) => customType.name === name);

  const [values, setValues] = useState(
    customField.values.map(({ value, global_id }) => ({ id: global_id || value, text: value }))
  );
  const multi = customType.kind === "multiple";
  const suggestion = customType.suggestion_description;

  return (
    <div style={{ paddingBottom: 30 }} data-qa-id={`Onboarding.ReviewCustomField.${customType.name}`}>
      <AutoSelect
        multi={multi}
        values={values}
        letCreate={customType.new_values === "allowed"}
        cache={false}
        closeOnChange={true}
        query={(term) => fetchAutocompleteCustomValues({ term, type: customType.name })}
        label={customType.display_as}
        error={error}
        handleChange={(data) => {
          setValues(data);
          handleChange(data.map(({ text }) => ({ value: text })));
        }}
        qaId={`ReviewCustomField.${customType.name}`}
        placeholder={multi || values.length === 0 ? suggestion : false}
      />
    </div>
  );
};

ReviewCustomField.propTypes = {
  customField: PropTypes.object,
  handleChange: PropTypes.func,
  error: PropTypes.object
};

export default ReviewCustomField;
