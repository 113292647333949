import { includes } from "lodash";
import marked from "marked";
import { emojify } from "PFCore/helpers/emojify";
import { gidToPath } from "PFCore/helpers/gid";
import { softBreakableLink } from "PFCore/helpers/soft_breakable";
import { startsWith } from "underscore.string";
import filterXSS from "xss";

const MENTION_RE = /@\[([^\]]+)\]\((gid:\/\/profinda-api\/Profile\/[0-9]+)\)/g;

// Transforms mentions from the given text to plain format
// @[your name](gid://profinda-api/Profile/123) => @your name
//
// @param text [String] text to process
// @return [String] text without mentions
export const mentionsToPlain = (text) => {
  if (!text) {
    return "";
  }
  return text.replace(MENTION_RE, (_, name) => `@${name.replace(/\s/g, "_")}`);
};

const renderer = new marked.Renderer();

// Overwrite link renderer to detect
// - $[<text>](<href>) as mentions (handled by mentions renderer)
//
// @param href [String] link to be handled
// @param title [String] title
// @param text [String] text extracted from [<text>]
renderer.link = (href, title, text) => {
  if (href.match(/^gid:\/\/profinda-api\/Profile\/[0-9]+/)) {
    return renderer.mentions(href, title, text);
  }
  const link = marked.Renderer.prototype.link
    .call(renderer, href, title, softBreakableLink(text))
    .replace(/href="([^"]+)"/, (match, url) => {
      // filterXXS will filter anything without http or https protocol so here we
      // this add it so it supports things like "www.example.com" and "example.com"
      if (
        !(
          startsWith(url, "http://") ||
          startsWith(url, "https://") ||
          startsWith(url, "/") ||
          startsWith(url, "mailto:")
        )
      ) {
        url = `http://${url}`;
      }

      // adds target=_blank to 3rd party links
      if (!startsWith(url, "#/")) {
        return `href='${url}' target='_blank'`;
      } else {
        return `href='${url}'`;
      }
    });
  return link;
};

// Mentions renderer to detect
// - $[<text>](<href>) as mentions
//
// @param href [String] link to be handled
// @param title [String] title
// @param text [String] text extracted from [<text>]
renderer.mentions = (href, title, text) => {
  const data = href.replace(/^gid:\/\/profinda-api\//, "").split("/");
  const softBreakLink = softBreakableLink(`@${text}`);
  const path = gidToPath(href);
  const tag = marked.Renderer.prototype.link.call(renderer, path, title, softBreakLink);
  return tag.replace(
    /^<a/,
    `<a data-js-mention='true'
      data-js-mention-type='${data[0]}'
      data-js-mention-id='${data[1]}'`
  );
};

// Hack to change link inline lexer as the lib does not give you a way to
// overwrite inline lexers
//
// made to detect:
// - $[]() as mentions
//
// Adding @ and $ as possible non text chars
const allow = (tag, attr) => {
  const tagWhiteList = filterXSS.whiteList[tag];
  if (!includes(tagWhiteList, attr)) {
    tagWhiteList.push(attr);
  }
  return allow;
};

const LINK_MENTION_RE =
  /^[!@]{0,1}?\[((?:\[[^\]]*\]|[^[\]]|\](?=[^[]*\]))*)\]\(\s*<?([\s\S]*?)>?(?:\s+['"]([\s\S]*?)['"])?\s*\)/;
const LINK_TEXT_RE = /^[\s\S]+?(?=[\\<![_*`~@$]|https?:\/\/| *\n|$)/;

export const fullMarkdown = (text, options = { emojify: true }) => {
  marked.setOptions({
    gfm: true,
    tables: true,
    breaks: true,
    pedantic: false,
    smartLists: true,
    smartypants: true
  });

  marked.InlineLexer.rules.breaks.link = LINK_MENTION_RE;
  marked.InlineLexer.rules.breaks.text = LINK_TEXT_RE;

  text = text ? `${text}` : "";
  let html = options.emojify ? emojify(text, { careful: true }) : text;

  // escape ` for non code blocks for cases like "ProFinda`s team"
  html = html.replace(/([^ `\n])`([^ `\n])/g, "$1&#96;$2");
  html = marked(html, { renderer });
  options.emojify && (html = emojify(html));

  allow("img", "class");
  allow("span", "class");
  allow("code", "class");
  allow("a", "data-js-mention");
  allow("a", "data-js-mention-type");
  allow("a", "data-js-mention-id");

  return filterXSS(html).replace(/<a/g, " <a rel='noopener noreferrer'").replace(/&amp;/g, "&");
};
