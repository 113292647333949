import { usePusherEvent } from "PFCore/base/pusher";
import { gidToType } from "PFCore/helpers/gid";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { ApiRoute, UiRoute } from "PFCore/utilities/routes";
import logoURL from "PFImages/native_notification_icon.png";
import { CHAT_ACTIONS } from "PFReducers/chat_reducer";
import { NOTIFICATIONS_ACTIONS, NOTIFICATIONS_ASYNC_ACTIONS } from "PFReducers/notifications_reducer";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const useNativeNotifications = (dispatch, store) => {
  const { notificationSettings } = store;
  const { data: currentProfile } = useCurrentProfile();
  const history = useHistory();

  const [notificationsAllowed, setNotificationsAllowed] = useState(false);

  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch {
      return false;
    }
    return true;
  };

  const setupNotificationsInBrowser = () => {
    if (!("Notification" in window)) {
      setNotificationsAllowed(false);
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          setNotificationsAllowed(permission === "granted");
        });
      } else {
        Notification.requestPermission((permission) => {
          setNotificationsAllowed(permission === "granted");
        });
      }
    }
  };

  useEffect(() => {
    if (String(notificationSettings.allow_native_notifications) === "true") {
      setupNotificationsInBrowser();
    } else {
      setNotificationsAllowed(false);
    }
  }, [notificationSettings.allow_native_notifications]);

  const handleCreateNotificationEvent = useCallback((data) => {
    if (!notificationsAllowed) {
      return;
    }

    const type = pluralize(gidToType(data.resource_gid).toLowerCase());
    if (type === "notifications") {
      $.ajax(ApiRoute(`/api/notifications/${data.id}`), {
        api_version: 2
      }).then((data) => {
        const notification = new Notification(data.title, {
          body: data.body,
          icon: data.picture_url,
          tag: data.category_type,
          image: UiRoute(logoURL)
        });
        notification.onclick = () => {
          if (data.category_type === "message") {
            dispatch({ type: CHAT_ACTIONS.CHAT_RENDER, payload: { id: data.target_id } });
          } else {
            history.push(data.url_address);
          }
          window.focus();
          dispatch({
            type: NOTIFICATIONS_ASYNC_ACTIONS.MARK_AS_READ,
            payload: data.id
          }).then(() => {
            dispatch({
              type: NOTIFICATIONS_ACTIONS.NOTIFICATION_SET_MARK_AS_READ,
              payload: data.id
            });
          });
          notification.close();
        };
      });
    }
  }, []);

  usePusherEvent(
    `private-profile-${currentProfile.id}`,
    "create",
    handleCreateNotificationEvent,
    !!currentProfile.id
  );
};
