import { Button } from "PFComponents/button";
import BackIcon from "PFIcons/back_icon.svg";
import NextIcon from "PFIcons/next.svg";
import { useTranslation } from "react-i18next";

import css from "./rules_carousel_footer.module.scss";

type RulesCarouselFooterProps = {
  onNext: () => void;
  onPrev: () => void;
  onCreate: () => void;
  onRemove: (index: number) => void;
  carouselIndex: number;
  rulesLength: number;
};

export const RulesCarouselFooter = ({
  onNext,
  onPrev,
  onCreate,
  onRemove,
  carouselIndex,
  rulesLength
}: RulesCarouselFooterProps) => {
  const { t } = useTranslation();

  return (
    <div className={css.rulesSliderFooter}>
      {rulesLength > 1 && (
        <div className={css.rulesNavigationButtons}>
          <Button kind="blank" onClick={onPrev} disabled={carouselIndex === 0}>
            <BackIcon width={20} height={20} />
          </Button>
          <div className={css.rulesCounter}>
            {t("availabilityRequirement.ruleXofY", {
              index: carouselIndex + 1,
              total: rulesLength
            })}
          </div>
          <Button kind="blank" onClick={onNext} disabled={carouselIndex === rulesLength - 1}>
            <NextIcon width={20} height={20} />
          </Button>
        </div>
      )}
      <div className={css.rulesPopulateButtons}>
        <Button onClick={onCreate}>
          {t(
            rulesLength === 0 ? "availabilityRequirement.addRule" : "availabilityRequirement.addAnotherRule"
          )}
        </Button>
        <Button kind="danger" onClick={() => onRemove(carouselIndex)} disabled={rulesLength === 0}>
          {t("availabilityRequirement.removeCurrent")}
        </Button>
      </div>
    </div>
  );
};
