import { Table } from "@tanstack/react-table";

import { TableData } from "./table";
import { PaginationOptions } from "./table.types";

export const calculateGridStyle = (count: number): React.CSSProperties => ({
  gridTemplateColumns: `${new Array(count).fill("2fr").join(" ")}`
});

export const getPaginationOptions = <TABLE_DATA extends TableData>(
  table: Table<TABLE_DATA>,
  paginationOptions?: PaginationOptions
): PaginationOptions => {
  if (paginationOptions) {
    return paginationOptions;
  }
  return {
    currentPage: table.getState().pagination.pageIndex + 1,
    totalPages: table.getPageCount(),
    onClick: (page: number) => table.setPageIndex(page - 1)
  };
};

export const sortingDateFn = (dateA: Date | undefined, dateB: Date | undefined): number =>
  (dateA?.getTime() ?? 0) - (dateB?.getTime() ?? 0);
