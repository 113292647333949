import moment from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useTranslation } from "react-i18next";

const useFiltersRange = () => {
  const { formatISODate } = useDateFormatter();
  const { t } = useTranslation();

  const availabilityUntilToday = (number, string) => ({
    start: formatISODate(moment().subtract(number, string)),
    end: formatISODate()
  });

  const availabilityFromDate = (numberStart, numberEnd, string) => ({
    start: formatISODate(moment().add(numberStart, string)),
    end: formatISODate(moment().add(numberEnd, string))
  });

  const pastOptions = [
    { id: "week", item: availabilityUntilToday(1, "weeks"), displayElement: t("filters.ranges.pastWeek") },
    {
      id: "two_weeks",
      item: availabilityUntilToday(2, "weeks"),
      displayElement: t("filters.ranges.pastTwoWeeks")
    },
    {
      id: "one_month",
      item: availabilityUntilToday(1, "months"),
      displayElement: t("filters.ranges.pastMonth")
    },
    {
      id: "two_months",
      item: availabilityUntilToday(2, "months"),
      displayElement: t("filters.ranges.pastMonth", { count: 2 })
    },
    {
      id: "six_months",
      item: availabilityUntilToday(6, "months"),
      displayElement: t("filters.ranges.pastMonth", { count: 6 })
    },
    {
      id: "one_year",
      item: availabilityUntilToday(1, "years"),
      displayElement: t("filters.ranges.pastYear")
    },
    {
      id: "two_years",
      item: availabilityUntilToday(2, "years"),
      displayElement: t("filters.ranges.pastTwoYears")
    }
  ];

  const futureOptions = [
    {
      id: "this_week",
      item: availabilityFromDate(0, 1, "weeks"),
      displayElement: t("filters.ranges.thisWeek")
    },
    {
      id: "next_week",
      item: availabilityFromDate(1, 2, "weeks"),
      displayElement: t("filters.ranges.nextWeek")
    },
    {
      id: "this_month",
      item: availabilityFromDate(0, 1, "months"),
      displayElement: t("filters.ranges.thisMonth")
    },
    {
      id: "next_month",
      item: availabilityFromDate(1, 2, "months"),
      displayElement: t("filters.ranges.nextMonth")
    },
    {
      id: "this_quarter",
      item: availabilityFromDate(0, 3, "months"),
      displayElement: t("filters.ranges.thisQuarter")
    },
    {
      id: "next_quarter",
      item: availabilityFromDate(3, 6, "months"),
      displayElement: t("filters.ranges.nextQuarter")
    },
    {
      id: "this_year",
      item: availabilityFromDate(0, 1, "years"),
      displayElement: t("filters.ranges.thisYear")
    },
    {
      id: "next_year",
      item: availabilityFromDate(1, 2, "years"),
      displayElement: t("filters.ranges.nextYear")
    }
  ];

  const allOptions = [...pastOptions, ...futureOptions];

  return {
    pastOptions,
    futureOptions,
    allOptions
  };
};

export default useFiltersRange;
