import { decamelizeKeys } from "humps";
import api from "PFCore/api";
import { CurrentProfile } from "PFTypes";

export const editCurrentProfile = (formData: Partial<CurrentProfile>) =>
  api({
    method: "PUT",
    url: "me",
    data: formData
  }).then(
    (response) => decamelizeKeys(response),
    (error) => {
      throw error.response;
    }
  );
