import { ActionIcon } from "PFComponents/action_icon";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./details_header.module.scss";

type DetailsHeaderProps = {
  subtitle: string | number | null;
  title?: string;
  linkUrl?: string;
};

export const DetailsHeader = ({
  children,
  title,
  subtitle,
  linkUrl
}: PropsWithChildren<DetailsHeaderProps>) => {
  const { t } = useTranslation("translation");

  return (
    <section className={css.header}>
      <div className={css.headerWithChildren}>
        <div>{children}</div>
        <div className={css.headerContainer}>
          <div className={css.title}>{title || t("untitled")}</div>
          <div className={css.titleLabel}>{subtitle}</div>
        </div>
      </div>
      <div className={css.actionIcons}>
        {linkUrl && (
          <Link to={linkUrl}>
            <ActionIcon name="open" size="sm" />
          </Link>
        )}
      </div>
    </section>
  );
};
