import { useQuery } from "@tanstack/react-query";
import { fetchProfilesOptions, ProfilesOptionsParams } from "PFCore/services/profile";
import { Collection, ProfileBasicInformation } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { profilesKeys } from "./query_keys";

export const useProfilesOptions = (
  params: ProfilesOptionsParams,
  options: QueryOptions<Collection<ProfileBasicInformation[]>> = {}
) =>
  useQuery<Collection<ProfileBasicInformation[]>>(
    profilesKeys.options(params),
    () => fetchProfilesOptions(params),
    options
  );
