import { Pages } from "PFTypes";

export type Page<PAGE_BEAN extends object = object> = PAGE_BEAN;

export type PageState = {
  pages: Record<string, Page>;
};

type PageSetActionType = "PAGE_SET";
type PageMergeActionType = "PAGE_MERGE";
type PageResetActionType = "PAGE_RESET";

type PageActionTypes = PageSetActionType | PageMergeActionType | PageResetActionType;

export type PageAction<PAGE_BEAN extends object = object> = {
  type: PageActionTypes;
  payload: {
    key: string;
    payload?: Partial<PAGE_BEAN>;
  };
};

export const pageReducer = (state: Pages, { type, payload }: PageAction): Pages => {
  if (!payload) {
    return state;
  }

  const pages = state.pages || {};
  const { payload: actualPayload, key } = payload;

  switch (type) {
    case "PAGE_SET":
      pages[payload.key] = actualPayload ?? {};
      break;

    case "PAGE_MERGE":
      pages[key] = { ...pages[key], ...actualPayload };
      break;

    case "PAGE_RESET":
      delete pages[payload.key];
      break;
  }

  return { ...state, pages };
};
