import css from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { ActivityReviewSection } from "../../../../../../parts/review_section";
import { RoleInformation } from "../../../components/role_information";
import { ShortlistSideFilters } from "../shortlist_side_filters";

export const ShortlistSidePanel = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist" });
  const { task } = useActivityPageContext();

  return (
    <div className={css.pageCardSidePanel}>
      <Typography variant="h3" noMargin>
        {t("header")}
      </Typography>
      <RoleInformation role={task} qaId="ActivityShortlistTabRoleInformation" />
      <Divider color="paletteNeutral0" />
      <ShortlistSideFilters />
      <Divider color="paletteNeutral0" />
      <ActivityReviewSection />
    </div>
  );
};
