import { Note } from "PFComponents/notes/notes.types";

// TODO: [PROF-1218] Add notes - integration with the API part
export const MOCK_NOTES: Note[] = [
  {
    id: 1,
    author: "Sancho Pansa",
    authorId: 91,
    content: "I think Jeff Smith would be a nice fit for this role",
    createdAt: "2021-08-10T12:36:12Z"
  },
  {
    id: 2,
    author: "Don Quixote",
    authorId: 92,
    content: "I totally agree with Sancho",
    createdAt: "2021-08-11T15:56:12Z"
  }
];
