import { find, includes, pickBy } from "lodash";

import { RANGES_ERROR_KEY } from "./constants";

export const getErrorsForRuleIndex = (
  errors: Record<string, string>,
  index: number
): Record<string, string> => pickBy(errors, (_, key) => includes(key, `${RANGES_ERROR_KEY}[${index}]`));

export const getFieldError = (errors: Record<string, string>, field: string): string | undefined =>
  find(errors, (_, key) => includes(key, field));
