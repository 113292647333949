import api from "PFCore/api";
import { PureCustomValueCamelCased } from "PFTypes";

export type AutocompleteCustomValuesParams = {
  term?: string;
  type?: string | string[];
  global?: boolean;
  value?: any;
};

export type AutocompleteCustomValuesResponse = PureCustomValueCamelCased[];

export const fetchAutocompleteCustomValues = (
  params: AutocompleteCustomValuesParams,
  { apiVersion }: { apiVersion: 1 | 2 } = { apiVersion: 2 }
): Promise<AutocompleteCustomValuesResponse> =>
  api({
    url: "autocomplete/custom_values",
    headers: {
      apiVersion
    },
    params
  });

export const fetchAutocompleteSkills = (
  params: Omit<AutocompleteCustomValuesParams, "type">,
  options?: { apiVersion: 1 | 2 }
) => fetchAutocompleteCustomValues({ type: "skills", ...params }, options);
