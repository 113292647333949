import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { GhostPage } from "PFComponents/ghost_page";
import { LoadingDots } from "PFComponents/loading_dots";
import Panel from "PFComponents/panel/panel";
import { Select } from "PFComponents/select/select";
import { Subtemplate } from "PFTypes";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActivityEditFormContext } from "../activity_edit_form_context";
import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import { isSubtemplatesLogicActive } from "../utils/activity_edit_form.utils";
import FieldFactory from "./field_factory";
import { useFilteredMetadataProperties } from "./hooks/use_filtered_metadata_properties";
import css from "./sections.module.scss";

type AttributesSectionProps = {
  onSubtemplateChange?: (subtemplateKey: string) => void;
  qaIdPrefix: string;
  usedOn?: string;
  title: string;
  withMatch?: boolean;
  subtemplateOptions?: DropdownOption[];
  hasSubtemplates?: boolean;
  subtemplate?: Subtemplate;
  subtemplateKey?: string;
  isLoading?: boolean;
  buttons?: React.ReactElement | null;
};

export const AttributesSection = ({
  title,
  buttons,
  qaIdPrefix,
  withMatch,
  subtemplateOptions = [],
  hasSubtemplates = false,
  onSubtemplateChange,
  subtemplate,
  subtemplateKey,
  usedOn,
  isLoading = false
}: AttributesSectionProps): JSX.Element | null => {
  const { errors } = useActivityEditFormContext();
  const { template } = useActivityEditPageContext();
  const { isEdit, isClone } = useActivityEditPageFlags();

  const { t } = useTranslation("activities", { keyPrefix: "edit.sections" });
  const [previousHeight, setPreviousHeight] = useState(0);
  const fieldsWrapperRef = useRef<HTMLDivElement>(null);

  const filteredMetadataProperties = useFilteredMetadataProperties(subtemplate, withMatch);

  const anyProperties = filteredMetadataProperties.length > 0;

  const isSubtemplateSelected = !!subtemplate;
  const showSubtemplateSelector = isSubtemplatesLogicActive(
    hasSubtemplates,
    !!subtemplateKey,
    isEdit,
    isClone
  );
  const isGhostPageVisible = showSubtemplateSelector && !isSubtemplateSelected;

  if (!anyProperties) {
    return null;
  }

  return (
    <Panel className={css.wrapper}>
      {title && (
        <div className={css.header}>
          {title}
          {buttons}
        </div>
      )}

      {showSubtemplateSelector && (
        <div>
          <Select
            placeholder={t("pleaseSelect")}
            label={t(`subtemplateSelectLabel.${template.key}`)}
            qaId="ActivityFormSubtemplateSelect"
            value={subtemplateOptions.find(({ item }) => item === subtemplateKey)?.displayElement}
            error={errors?.roleForm}
            options={subtemplateOptions}
            onChange={(newItem: string) => {
              setPreviousHeight(fieldsWrapperRef.current?.clientHeight || 0);
              onSubtemplateChange && onSubtemplateChange(newItem);
            }}
            disabled={isEdit || isClone}
            controlledValue
            required
          />

          {isGhostPageVisible && <GhostPage className={css.ghostPage} ref={fieldsWrapperRef} />}
        </div>
      )}
      {isLoading && (
        <div className={css.loaderWrapper} style={{ height: previousHeight }}>
          <LoadingDots circlesEnabled circleSize="xs" />
        </div>
      )}
      {!isGhostPageVisible && !isLoading && (
        <div className={css.inlines} ref={fieldsWrapperRef}>
          {(filteredMetadataProperties || []).map((property) => (
            <FieldFactory
              key={property.name}
              property={property}
              subtemplate={subtemplate}
              qaIdPrefix={qaIdPrefix}
              usedOn={usedOn}
            />
          ))}
        </div>
      )}
    </Panel>
  );
};
