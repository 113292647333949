import classNames from "classnames";
import CloseIcon from "PFIcons/close.svg";
import { useTranslation } from "react-i18next";

import css from "./header.module.scss";

interface Props {
  onClose: () => void;
  role: string;
}

const Header = ({ onClose, role }: Props): JSX.Element => {
  const { t } = useTranslation("activities");

  return (
    <header className={css.header}>
      <CloseIcon width={18} height={18} onClick={onClose} className={css.closeIcon} />
      <h5 className={classNames(css.noMargin, css.title)}>{t("questionsModal.showInterestInRole")}</h5>
      <h3 className={css.noMargin}>{role}</h3>
    </header>
  );
};

export default Header;
