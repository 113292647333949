import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

import css from "./chat_message.scss";
import { Separator } from "./separator";

type Props = {
  owned: boolean;
  createdAt: Moment;
  profileName?: string;
  profileViewable?: boolean;
  handleDeleteClicked: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleProfileClicked: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const MessageInfo = ({
  owned,
  createdAt,
  profileName = "",
  profileViewable = false,
  handleDeleteClicked,
  handleProfileClicked
}: Props): JSX.Element => {
  const { t } = useTranslation("core");
  const createdAtInWords =
    createdAt.diff(moment(), "days") < -1 ? createdAt.fromNow() : createdAt.format("MMM D, h:mm a");

  return (
    <small className={css.info} data-qa-id="message-info">
      {!owned && profileViewable && (
        <button className={css.profileLink} onClick={handleProfileClicked}>
          {profileName}
        </button>
      )}
      {!owned && !profileViewable && <span>{profileName}</span>}
      {!owned && <Separator />}
      <span>{createdAtInWords}</span>
      {owned && <Separator />}
      {owned && (
        <button className={css.link} onClick={handleDeleteClicked} data-qa-id="chat-message-delete">
          {t("chat.deleteMessage")}
        </button>
      )}
    </small>
  );
};
