export const invitesKeys = {
  all: () => ["invites"],
  list: (targetType: string, targetIds: number[] | null) => [
    ...invitesKeys.all(),
    "list",
    targetType,
    String(targetIds)
  ],
  single: (targetIds: number[], profileIds: number[]) => [
    "invite",
    targetIds.toString(),
    profileIds.toString()
  ]
};
