import { GrowlEntry } from "PFApp/components/growls";
import { Search } from "PFCore/initial_state/search";
import {
  ActivityPreview,
  AppLayout,
  Chats,
  CurrentAccount,
  CurrentProfile,
  CustomType,
  Notifications,
  NotificationSettings,
  ProfilePreview,
  TemplateConfiguration
} from "PFTypes";
import { createContext } from "react";

import { PlannerPageContext } from "./workflow/parts/planner/use_planner_tab";

export type Dispatch = <ACTION extends object>(action: ACTION) => void | Promise<any>;

type AppContextType = {
  store: {
    activityPreview: ActivityPreview;
    appLayout: AppLayout;
    chats: Chats;
    currentAccount: CurrentAccount;
    currentProfile: CurrentProfile;
    customTypes: CustomType[];
    growls: GrowlEntry[];
    modalSearch: unknown;
    notificationSettings: NotificationSettings;
    notifications: Notifications;
    pages: Record<string, any> & { workflowPage: Record<string, any> & { planner: PlannerPageContext } };
    profilePreview: ProfilePreview;
    search: Search;
    templatesConfiguration: TemplateConfiguration[];
    themeVars: Record<string, string>;
  };
  dispatch: Dispatch;
};

const AppContext = createContext<AppContextType>({ store: {} } as AppContextType);

export default AppContext;
