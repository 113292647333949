// eslint-disable-next-line max-len
// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const spacing = {
  spacingXs: "0.125rem",
  spacingSm: "0.25rem",
  spacingMd2: "0.75rem",
  spacingMd: "0.5rem",
  spacingLg: "1rem",
  spacingXl: "1.5rem",
  spacing2xl: "2rem",
  spacing3xl: "2.5rem"
} as const;

export default spacing;
