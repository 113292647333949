import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { Profile } from "PFTypes";

import { NavSection } from "../../profile_edit_sections.types";
import ProfileEditNavItem from "./profile_edit_nav_item";
import css from "./profile_edit_nav_links.scss";

type ProfileEditNavLinksProps = {
  sections: NavSection[];
  profile: Profile;
  currentSection: string;
  adminPage: boolean;
  placement: string;
};

export const ProfileEditNavLinks = ({
  sections,
  profile,
  currentSection,
  adminPage,
  placement
}: ProfileEditNavLinksProps): JSX.Element | null => {
  const isEnabled = useIsFeatureEnabled();

  sections = sections.filter((section) => section.placement === placement);
  if (sections.length === 0) {
    return null;
  }

  return (
    <ul className={css.links}>
      {sections.map((nav) => {
        if (nav.requireFeature && isEnabled(nav.requireFeature) === false) {
          return false;
        }

        if (nav.if && !nav.if()) {
          return false;
        }

        const href = adminPage
          ? `/admin/users/${profile.id}/edit/${nav.section}`
          : `/profile/edit/${nav.section}`;

        return (
          <ProfileEditNavItem
            {...nav}
            href={href}
            key={nav.section}
            current={currentSection === nav.section}
          />
        );
      })}
    </ul>
  );
};
