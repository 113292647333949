export const activitiesTab = "activities";
export const activitiesListTab = [
  "engagement",
  "role",
  "audit_engagement",
  "audit_role",
  "position",
  "lesson",
  "question",
  "suggestion",
  "project",
  "event"
];
export const allTab = "all";
export const matchesTab = "matches";
export const profilesTab = "profiles";
export const modalFakeTab = "modalFakeTab";

export const allTabs = [matchesTab, profilesTab, allTab, ...activitiesListTab];
