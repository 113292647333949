import { decamelizeKeys } from "humps";
import api from "PFCore/api";
import { Match, PaginatedCollection } from "PFTypes";
import { QueryParams } from "PFTypes/query_params";

type MatchesPayload = {
  refresh?: boolean;
} & QueryParams;

export const fetchMatches = (
  activityId,
  payload: MatchesPayload = { page: 1, perPage: 10 }
): Promise<PaginatedCollection<Match[]>> =>
  api({
    url: "matches/index",
    method: "POST",
    data: { activityId: activityId, ...payload }
  }).then((response) => {
    const { perPage, totalPages } = response.meta;
    const decamelizeResponse = decamelizeKeys(response);
    return {
      ...decamelizeResponse,
      meta: { ...decamelizeResponse.meta, perPage, totalPages }
    } as PaginatedCollection<Match[]>;
  });
