import classNames from "classnames";
import DownArrowSvg from "PFIcons/down_arrow.svg";
import RightArrowSvg from "PFIcons/right_arrow.svg";

import css from "./warning_tooltip_content.module.scss";

interface IProps {
  label: string;
  counter: number;
  classes?: Record<string, string>;
  expanded: boolean;
  onToggleExpanded: () => void;
  renderExpanded?: () => React.ReactNode;
  counterColor?: string;
}

const ExpandedIcon = (props) => <DownArrowSvg {...props} />;
const NotExpandedIcon = (props) => <RightArrowSvg {...props} />;

const WarningTooltipContent = ({
  label,
  counter,
  classes = {},
  expanded,
  onToggleExpanded,
  renderExpanded,
  counterColor
}: IProps) => {
  const counterValue = counter <= 9 ? counter : "9+";
  const ArrowIconComponent = expanded ? ExpandedIcon : NotExpandedIcon;
  return (
    <div className={css.root}>
      <div className={css.header}>
        <div>
          {!!renderExpanded && (
            <ArrowIconComponent
              className={css.arrowIcon}
              onClick={onToggleExpanded}
              viewBox="6.5 6 11 11"
              width={10}
              height={10}
            />
          )}
          <span>{label}</span>
        </div>
        <div
          style={{ ...(counterColor ? { background: counterColor } : {}) }}
          className={classNames(css.counter, classes.counter)}
        >
          {counterValue}
        </div>
      </div>
      {!!renderExpanded && expanded && renderExpanded()}
    </div>
  );
};

export default WarningTooltipContent;
