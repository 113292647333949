import api from "PFCore/api";
import { AccessRequest } from "PFTypes/access_request";

export type RequestAccessPayload = {
  accountId: number;
  name: string;
  company: string;
  email: string;
};

export const requestAccess = (data: RequestAccessPayload): Promise<AccessRequest> =>
  api({
    url: "access_requests",
    method: "POST",
    data,
    headers: {
      apiVersion: 1
    }
  });
