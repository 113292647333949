import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { isNil, startsWith } from "lodash";
import { ApiRoute } from "PFCore/utilities/routes";
import QueryString from "qs";

import { Session } from "./types";

const DEFAULT_API_VERSION = 2;

const setAuthorizationHeader = (config: InternalAxiosRequestConfig, token: string): void => {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
};

export const clearInterceptors = (instance: AxiosInstance): void => {
  instance.interceptors.request.clear();
  instance.interceptors.response.clear();
};

export const prefixRequestURL = (config: InternalAxiosRequestConfig): void => {
  config.url = ApiRoute(`/api/${config.url}`);
};

export const setHeaders = (config: InternalAxiosRequestConfig, token: string, fullDomain?: string): void => {
  const apiVersion = config.headers.apiVersion || DEFAULT_API_VERSION;

  config.headers.api_version = apiVersion; // eslint-disable-line camelcase
  delete config.headers.apiVersion;

  if (fullDomain) {
    config.headers.ACCOUNT = fullDomain;
  }
  setAuthorizationHeader(config, token);

  if (!config.headers.Accept) {
    config.headers.Accept = `application/vnd.profinda+json;version=${apiVersion}`;
  }

  if (!isNil(PF.config.locale)) {
    config.headers["Accept-Language"] = PF.config.locale;
  }
};

export const parseRequestToSnakeCase = (config: InternalAxiosRequestConfig): void => {
  if (config.params) {
    config.params = decamelizeKeys(config.params);
  }

  if (config.data) {
    config.data = decamelizeKeys(config.data);
  }
};

export const parseResponseToCamelCase = (response: AxiosResponse): void => {
  const contentType: string = response.headers["content-type"];

  if (response.data && contentType.includes("application/json")) {
    response.data = camelizeKeys(response.data, (key, convert) => {
      const camelizedKey = convert(key);
      return startsWith(key, "_") ? `_${camelizedKey}` : camelizedKey;
    });
  }
};

export const addPaginationParamsToResponse = (response: AxiosResponse) => {
  const {
    config: { params, data: requestDataString },
    data
  } = response;
  const isFormData = requestDataString instanceof FormData;
  const requestData = requestDataString && !isFormData ? JSON.parse(requestDataString) : {};
  const perPage = params?.per_page ?? requestData.per_page;
  const page = params?.page ?? requestData.page;

  if (!!data.meta && perPage !== undefined && page !== undefined && !Number.isNaN(data.meta?.total)) {
    const totalPages = Math.max(Math.ceil(data.meta.total / perPage), 1);
    response.data = {
      ...data,
      meta: {
        ...data?.meta,
        perPage,
        page,
        totalPages
      }
    };
  }
};

export const refreshTokenAndRetry = (
  session: Session,
  originalRequest?: InternalAxiosRequestConfig
): Promise<AxiosResponse> =>
  new Promise((resolve, reject) => {
    session
      .refreshToken()
      .then(() => {
        if (originalRequest) {
          setAuthorizationHeader(originalRequest, session.getAccessToken());

          axios(originalRequest)
            .then((res) => {
              parseResponseToCamelCase(res);
              addPaginationParamsToResponse(res);
              resolve(res);
            })
            .catch(() => {
              session.expireSession();
            });
        }
      })
      .catch(reject);
  });

export const setParamsSerializer = (config: InternalAxiosRequestConfig) => {
  config.paramsSerializer = {
    serialize: (params) =>
      QueryString.stringify(params, {
        arrayFormat: "brackets",
        skipNulls: true
      })
  };
};
