import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Profile } from "PFTypes/profile";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import css from "./calendars_section.module.scss";

type CalendarSectionProps = {
  profile: Profile;
};

const CalendarsSection = ({ profile }: CalendarSectionProps) => {
  const { t } = useTranslation("profiles");
  const history = useHistory();

  const { isEnabled, isAdmin } = useBookingModulePermissions();
  const { formatDate } = useDateFormatter();

  const canEditCalendars = isEnabled && isAdmin;

  const calendars = useMemo(() => {
    const rawCalendars = profile.availability?.calendars || {};

    return Object.values(rawCalendars).map((calendar, index) => ({
      ...calendar,
      startDate: Object.keys(rawCalendars)[index]
    }));
  }, [profile]);

  return (
    <ProfileEditPanel
      headingButtonLabel={t("edit.sections.calendars.edit")}
      headingButtonDisabled={!canEditCalendars}
      handleHeadingButtonClick={() => history.push("/admin/booking/calendars")}
    >
      {calendars.map((calendar) => (
        <div key={calendar.startDate} className={css.item}>
          <strong>{calendar.calendar_name || t("edit.sections.calendars.defaultName")} </strong>
          <div className={css.calendarStart}>
            <span>{t("edit.sections.calendars.starts")}</span>
            <strong>{formatDate(calendar.startDate)}</strong>
          </div>
        </div>
      ))}
    </ProfileEditPanel>
  );
};

export default CalendarsSection;
