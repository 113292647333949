import classNames from "classnames";
import React, { Fragment, JSX, useCallback, useEffect, useState } from "react";

import Tooltip from "../tooltip/tooltip";
import { Typography } from "../typography";
import css from "./multi_toggle.module.scss";

export type Option<ID_TYPE extends string = string> = {
  id: ID_TYPE;
  value: string | number | JSX.Element;
  disabled?: boolean;
  tooltipContent?: string | false;
  title?: string;
};

export type MultiToggleProps<ID_TYPE extends string> = React.AriaAttributes & {
  label?: string;
  defaultSelected?: ID_TYPE;
  options: Option<ID_TYPE>[];
  controlledValue?: ID_TYPE;
  onChange: (id: ID_TYPE) => void;
  rounded?: boolean;
  fluid?: boolean;
  classes?: Partial<{
    root: string;
    container: string;
    button: string;
  }>;
};

const MultiToggle = <ID_TYPE extends string = string>({
  label,
  defaultSelected,
  options,
  controlledValue,
  onChange,
  rounded = false,
  fluid = false,
  classes = {}
}: MultiToggleProps<ID_TYPE>): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<ID_TYPE | undefined>(defaultSelected);

  const handleOnClick = useCallback(
    (id: ID_TYPE) => {
      setSelectedOption(id);
      onChange(id);
    },
    [onChange]
  );

  useEffect(() => {
    setSelectedOption(defaultSelected);
  }, [defaultSelected]);

  return (
    <div className={classNames(css.root, classes.root)}>
      {label && <Typography variant="labelRegular">{label}</Typography>}
      <div
        role="group"
        className={classNames(
          css.multiToggleContainer,
          { [css.rounded]: rounded, [css.fluid]: fluid },
          classes.container
        )}
      >
        {options.map(({ id, disabled, tooltipContent, value, title }) => {
          const isSelectedOption = (controlledValue || selectedOption) === id;
          const content = (
            <button
              aria-pressed={isSelectedOption}
              key={id}
              type="button"
              className={classNames(
                css.button,
                {
                  [css.selected]: isSelectedOption,
                  [css.disabled]: disabled
                },
                classes.button
              )}
              onClick={() => !disabled && handleOnClick(id)}
              title={title || (typeof value === "string" ? value : "")}
            >
              <Typography
                variant={isSelectedOption ? "bodyBold" : "bodyRegular"}
                tag="span"
                className={css.buttonLabel}
              >
                {value}
              </Typography>
            </button>
          );
          return (
            <Fragment key={id}>
              {tooltipContent ? <Tooltip content={tooltipContent}>{content}</Tooltip> : content}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default MultiToggle;
