import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

export const useFormatBookingDates = () => {
  const { formatISODate, utc } = useDateFormatter();

  const formatBookingDates = (startDate: string, endDate: string) =>
    `${formatISODate(utc(startDate))} - ${formatISODate(utc(endDate))}`;

  return {
    formatBookingDates
  };
};
