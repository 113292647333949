const forEachDay = (low, high, fn) => {
  const dNow = new Date(low);
  const dHighTime = new Date(high).getTime();

  const MAX_DAYS = 10000; // just to not get into an infinite loop;

  for (let i = 0; i < MAX_DAYS; i++) {
    const iso = dNow.toISOString().replace(/T.*/, "");

    const code = fn(iso);
    if (code === "break") {
      break;
    }

    if (dHighTime < dNow.getTime()) {
      break;
    }

    dNow.setUTCDate(dNow.getUTCDate() + 1);
  }
};

const isSpace = (days, row, low, high) => {
  let answer = true;
  forEachDay(low, high, (iso) => {
    if (!(!days[iso] || days[iso][row] === undefined)) {
      // 0 is falsy
      answer = false;
      return "break";
    }
  });
  return answer;
};

const allocateRow = (days, low, high) => {
  const MAX_ROWS = 100; // ??

  for (let row = 0; row < MAX_ROWS; row++) {
    if (isSpace(days, row, low, high)) {
      forEachDay(low, high, (iso) => {
        days[iso] = days[iso] || {};
        days[iso][row] = true;
      });
      return row;
    }
  }

  return 0;
};

const markRows = (periods) => {
  const days = {};
  let max = 0;

  periods.forEach((bp) => {
    const row = allocateRow(days, bp.start, bp.end);
    bp.row = row;
    if (row > max) {
      max = row;
    }
  });

  return max; // we'll need it to calculate heights
};

export default markRows;
