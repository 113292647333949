import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { Activity, Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

type ShortlistButtonProps = {
  activity: Activity;
  profile: Profile;
  isLoadingShortlists: boolean;
  onShortlistCreated: VoidFunction;
};

export const ShortlistButton = ({
  activity,
  profile,
  isLoadingShortlists,
  onShortlistCreated
}: ShortlistButtonProps) => {
  const { t } = useTranslation("profiles");
  const growl = useGrowl();
  const { isLoading: isCreating, mutate: createShortlist } = useShortlistCreate();

  const isButtonDisabled = isCreating || isLoadingShortlists;

  return (
    <Button
      kind="secondary"
      onClick={() => {
        createShortlist(
          { activityId: activity.id, profileId: profile.id, named: true },
          {
            onSuccess: () => onShortlistCreated(),
            onError: ({ responseJSON }) => {
              growl({ message: responseJSON.errors[0].detail, kind: "error" });
            }
          }
        );
      }}
      disabled={isButtonDisabled}
    >
      {isButtonDisabled ? "..." : t("shortlistForProfile.shortlist")}
    </Button>
  );
};
