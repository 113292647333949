import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { AvailabilityDuration } from "PFTypes/booking";
import { useTranslation } from "react-i18next";

type DurationModeOptionsReturn = {
  currentDurationMode: DropdownOption;
  durationModeOptions: DropdownOption[];
};

export const useDurationModeOptions = (
  durationMode: AvailabilityDuration["mode"]
): DurationModeOptionsReturn => {
  const { t } = useTranslation();

  const durationModeOptions: DropdownOption[] = [
    { displayElement: t("availabilityRequirement.days"), id: "days", item: "days" },
    { displayElement: t("availabilityRequirement.hours"), id: "minutes", item: "minutes" }
  ];

  const currentDurationMode = durationMode === "days" ? durationModeOptions[0] : durationModeOptions[1];

  return { currentDurationMode, durationModeOptions };
};
