import { cloneDeep, keys, pick } from "lodash";
import { isInSections } from "PFCore/helpers/custom_type";

export const sanitizePosition = (_position, whitelist, customTypes, adminPage) => {
  const whitelistedKeys = keys(whitelist);
  let position = cloneDeep(_position);

  position.current = position.main || !!position.current;

  if (position.current) {
    delete position.end_date; // "current" positions can't have end_date (enforced by API)
  }

  // We whitelist after the current and end_date to ensure we don't send them if not whitelisted
  if (whitelist) {
    position = pick(position, ...whitelistedKeys);
  }

  whitelistedKeys.forEach((key) => {
    const type = customTypes.find((customType) => customType.name === key);
    if (type && !adminPage) {
      if (isInSections("editable_only_by_admins", type)) {
        delete position[key];
      }
    }
  });

  return { positions: [position] };
};
