import { useMemo } from "react";

import { useTemplate } from "../../../hooks";
import { useActivityEditPageFlags } from "../activity_edit_page_context";

export const useEngagementSchema = () => {
  const { isAudit } = useActivityEditPageFlags();

  const template = useTemplate(isAudit ? "audit_engagement" : "engagement");

  return useMemo(() => template?.schema, [template?.schema]);
};
