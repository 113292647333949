import classNames from "classnames";
import PinIcon from "PFIcons/pin_alt.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./ranking_indicator.module.scss";

const RankingIndicator = ({ values, myRanking }) => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });
  const width = {
    basic: 33,
    intermediate: 33,
    advanced: 33
  };

  const leftBorder = width.basic > 0 ? "basic" : width.intermediate > 0 ? "intermediate" : "advanced";
  const rightBorder = width.advanced > 0 ? "advanced" : width.intermediate > 0 ? "intermediate" : "basic";

  // myRanking cames from the API as 1, 3 or 5, meaning basic, intermediate, advanced
  const myRankingPostion = {
    1: `${width.basic / 2}%`,
    3: `${width.basic + width.intermediate / 2}%`,
    5: `${width.basic + width.intermediate + width.advanced / 2}%`
  };

  const tierRenderer = (level) => (
    <div
      className={classNames(css?.[level], {
        [css.borderLeft]: leftBorder === level,
        [css.borderRight]: rightBorder === level
      })}
    >
      {values?.[level] || 0}
    </div>
  );

  return (
    <div className={css.wrapper}>
      <div className={css.rankingBar}>
        {myRanking ? (
          <div className={css.rankingIndicator} style={{ left: myRankingPostion[myRanking] }}>
            <PinIcon height={32} width={32} />
          </div>
        ) : null}
        {tierRenderer("basic")}
        {tierRenderer("intermediate")}
        {tierRenderer("advanced")}
      </div>
      <div className={css.legend}>
        <div className={classNames({ [css.disabled]: values?.basic === 0 })}>{t("basic")}</div>
        <div className={classNames({ [css.disabled]: values?.intermediate === 0 })}>{t("intermediate")}</div>
        <div className={classNames({ [css.disabled]: values?.advanced === 0 })}>{t("advanced")}</div>
      </div>
    </div>
  );
};

RankingIndicator.propTypes = {
  values: PropTypes.shape({
    basic: PropTypes.number,
    intermediate: PropTypes.number,
    advanced: PropTypes.number
  }),
  myRanking: PropTypes.number
};

export default RankingIndicator;
