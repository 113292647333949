import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import { useActivityEditPageFlags } from "../activity_edit_page_context";
import { useActivityEditFormSubmit } from "../hooks";
import css from "./header_section.module.scss";

const ButtonSection = (): JSX.Element => {
  const { t } = useTranslation("activities");
  const { isNew, isDraft } = useActivityEditPageFlags();
  const onSubmit = useActivityEditFormSubmit();

  const isNewOrDraft = isNew || isDraft;
  const createButtonLabel = isNewOrDraft ? "create" : "update";

  return (
    <div className={css.headerContainer}>
      <div className={css.buttonSection}>
        {isNewOrDraft && (
          <Button
            text={t("edit.sections.saveDraft")}
            kind="secondary"
            qaId="draft-button"
            onClick={() => onSubmit({ draft: true })}
            className={css.submitButton}
          />
        )}
        <Button
          text={t(`edit.sections.${createButtonLabel}`)}
          kind="primary"
          qaId="creation-button"
          onClick={() => onSubmit()}
          className={css.submitButton}
        />
      </div>
    </div>
  );
};

export default ButtonSection;
