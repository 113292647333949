import moment from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useProfileBookingsContext } from "../common/profile_bookings_provider";
import css from "./booking_list.less";

const Bookings = () => {
  const { formatDate, utc } = useDateFormatter();
  const { t } = useTranslation("profiles");
  const { bookings, fetchAllBookings } = useProfileBookingsContext();

  useEffect(() => {
    fetchAllBookings();
  }, []);

  const sortedBookings = useMemo(
    () =>
      (bookings || [])
        .filter(
          ({ end_date }) =>
            moment(end_date).valueOf() >= moment().set({ hour: 0, minute: 0, second: 0 }).valueOf()
        )
        .sort((b1, b2) => moment(b2.start_date).valueOf() - moment(b1.start_date).valueOf()),
    [bookings]
  );

  if (!sortedBookings.length) {
    return <span>{t("matchingRoles.noBookings")}</span>;
  }

  return (
    <table className={css.table}>
      <thead>
        <tr>
          <th>{t("matchingRoles.bookings")}</th>
        </tr>
      </thead>
      <tbody>
        {sortedBookings.map((booking, i) => (
          <tr key={i}>
            <td>{`${formatDate(utc(booking.start_date))} - ${formatDate(utc(booking.end_date))}`}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Bookings.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.object)
};

export default Bookings;
