import { WorkforceMetricsMode } from "PFApp/booking/types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface UseWorkforceMetricsMode {
  showUtilizationMetrics: boolean;
}

export const useWorkforceMetricsMode = ({
  showUtilizationMetrics
}: UseWorkforceMetricsMode): [WorkforceMetricsMode, Dispatch<SetStateAction<WorkforceMetricsMode>>] => {
  const [value, setValue] = useState(
    showUtilizationMetrics ? WorkforceMetricsMode.BillableUtilization : WorkforceMetricsMode.TotalAvailability
  );

  useEffect(() => {
    setValue(
      showUtilizationMetrics
        ? WorkforceMetricsMode.BillableUtilization
        : WorkforceMetricsMode.TotalAvailability
    );
  }, [showUtilizationMetrics]);

  return [value, setValue];
};
