import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { NOTIFICATION_SETTINGS_ASYNC_ACTIONS } from "PFReducers/notification_settings_reducer";
import { useEffect } from "react";

export const useNotificationSettings = (dispatch) => {
  const { isSignedIn } = useCurrentProfile();

  useEffect(() => {
    if (isSignedIn) {
      dispatch({ type: NOTIFICATION_SETTINGS_ASYNC_ACTIONS.NOTIFICATION_SETTINGS_FETCH });
    }
  }, [isSignedIn]);
};
