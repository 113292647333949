import { Button } from "PFComponents/button";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./search_like_a_pro.module.scss";

const SearchLikeAPro = () => {
  const { t } = useTranslation("search");
  const [showPanel, setShowPanel] = useState(false);

  return (
    <>
      <Button
        icon="info"
        text={t("searchLikeAPro.searchLikeAPro")}
        kind="blank"
        className={css.root}
        onClick={() => setShowPanel(true)}
      />
      <SidePanel show={showPanel} onClose={() => setShowPanel(false)}>
        <div className={css.panelWrapper}>
          <div className={css.panelHeader}>{t("searchLikeAPro.panelHeader")}</div>
          <div>
            <div className={css.panelContent}>{t("searchLikeAPro.descriptionFirst")}</div>
            <div className={css.panelContent}>{t("searchLikeAPro.descriptionSecond")}</div>
            <div className={css.panelContent}>{t("searchLikeAPro.descriptionThird")}</div>
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default SearchLikeAPro;
