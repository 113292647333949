import { ApiRoute } from "PFCore/utilities/routes";

export const uploadAttachment = (file: File) => {
  if (!file) {
    return $.Deferred().reject();
  }

  const formData = new FormData();

  // Saving activity attachment as "Chat::Attachment" "message" is required by API
  formData.append("type", "Chat::Attachment");
  formData.append("target_type", "message");
  formData.append("attachment", file);

  // @ts-ignore
  return $.ajax({
    method: "POST",
    url: ApiRoute("/api/attachments"),
    api_version: 2,
    data: formData,
    contentType: false,
    processData: false
  });
};
