import IBookingHistoryItem, { AttributeKey } from "PFApp/booking/types/booking_history_item";
import { Typography } from "PFComponents/typography";
import { camelCase } from "PFCore/helpers/camel_case";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { forwardRef, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AttributeData } from "./attribute_items/attribute";
import { AttributesSection } from "./attributes_section";
import css from "./booking_history_item.module.scss";
import { useBookingHistory } from "./use_booking_history";

type BookingHistoryItemProps = {
  item: IBookingHistoryItem;
};

const BookingHistoryItem = forwardRef<HTMLDivElement, BookingHistoryItemProps>(
  (
    {
      item: {
        requester_name: requesterName,
        requester_id: requesterId,
        updated_attributes: updatedAttributes,
        created_at: createdAt,
        action
      }
    },
    ref
  ) => {
    const { t } = useTranslation(["bookingModule", "translation"]);
    const { formatDateTime, utc } = useDateFormatter();
    const { getUserFriendlyAttributeName } = useBookingHistory();

    const propertyNames = updatedAttributes
      ? Object.keys(updatedAttributes)
          .map((attribute) => getUserFriendlyAttributeName(attribute))
          .join(", ")
      : t("translation:property");

    const attributes: AttributeData[] = useMemo(
      () =>
        updatedAttributes
          ? Object.entries(updatedAttributes).map(([key, value]) => ({
              attributeId: key as AttributeKey,
              valueFrom: value[0],
              valueTo: value[1]
            }))
          : [],
      [updatedAttributes]
    );

    const hasManyAttributes = attributes.length > 1;
    const shouldDisplayAttributes = attributes.length > 0 && action === "booking_updated";

    return (
      <div ref={ref} className={css.root}>
        <Typography variant="bodyRegular" tag="span">
          <Trans
            i18nKey={`bookingModule:details.history.${camelCase(action)}`}
            t={t}
            components={[<Link key="0" className={css.profileName} to={`/profiles/${requesterId}`} />]}
            values={{
              properties: hasManyAttributes ? t("bookingModule:details.history.someFields") : propertyNames,
              profileName: requesterName
            }}
          />
        </Typography>
        <div>
          <Typography variant="bodyRegular" tag="span">
            {formatDateTime(utc(createdAt))}
          </Typography>
        </div>
        <div>{shouldDisplayAttributes && <AttributesSection attributes={attributes} />}</div>
      </div>
    );
  }
);

BookingHistoryItem.displayName = "BookingHistoryItem";

export default BookingHistoryItem;
