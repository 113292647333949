import i18n from "../i18n";

const useConfirm = (displayConfirm) => {
  const allowAction = () => {
    if (displayConfirm) {
      const timeBefore = new Date();
      const confirm = window.confirm(i18n.t("core:form.editDialog"));
      const timeAfter = new Date();

      if (timeAfter - timeBefore < 100) {
        return true; // Dialogs are prevented, so pass the confirm
      }
      // Return whether the user confirmed or declined the popup
      return confirm;
    }
    // We're not displaying the confirm, so allow action
    return true;
  };

  return allowAction;
};

export default useConfirm;
