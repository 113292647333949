import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { Button } from "PFComponents/button";
import { Card } from "PFComponents/card";
import { Modal } from "PFComponents/modal";
import { useModalContext } from "PFComponents/modal/modal_context";
import Pill from "PFComponents/pill/pill";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { SkillRatingIcon } from "PFCore/components/icons/skill_rating_icon";
import canonicalId from "PFCore/helpers/canonicalId";
import { convertActivityToProfileCustomValues } from "PFCore/helpers/custom_value";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { ActivityCustomValue, Profile, ProfileCustomValue } from "PFTypes";
import { Id } from "PFTypes/id";
import { useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import SkillsExplorer from "../charts/skills_explorer";
import { BlockedSkillsAlert } from "./blocked_skills_alert";
import { Description } from "./description";
import css from "./section_skills.module.scss";
import SkillsGrid from "./skills_grid";
import { SkillsSuggestions } from "./skills_suggestions";
import { useSectionSkills } from "./use_section_skills";

export type ChangesLogItem = {
  id: Id;
  type: "new" | "suggested" | "discovered" | "delete" | "framework" | "ranked";
  data: ProfileCustomValue;
  oldData?: ProfileCustomValue;
  developmental?: boolean;
  top?: boolean;
};

type SkillsSectionProps = {
  profile: Profile;
  handleProfileUpdate: () => Profile;
};

export const SkillsSection = ({ profile, handleProfileUpdate }: SkillsSectionProps) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { closeWithAnimation } = useModalContext();
  const skillsCustomType = useSkillsType();

  const { t: defaultT } = useTranslation("translation");
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.sectionSkills" });

  const {
    addNewSkill,
    addToLog,
    changesLog,
    discoveredSkills,
    discoveredSkillsLoading,
    suggestedSkills,
    suggestedFrameworksSkills,
    isLoadingSuggestedFrameworksSkills,
    skills,
    basicSkills,
    intermediateSkills,
    advancedSkills,
    skillsValues,
    newSkills,
    saveChanges,
    onDragEnd,
    newSkillRating,
    setNewSkillRating,
    addedValues,
    deletedValues,
    coreSkillsCount,
    developmentalSkillsCount,
    skillsSortingSelected,
    setSkillsSortingSelected,
    setSkills,
    removeFromLog,
    handleSkillChange,
    blockedSkills
  } = useSectionSkills(profile, handleProfileUpdate, closeWithAnimation);

  const [isConfirmModalDisplayed, setIsConfirmModalDisplayed] = useState(false);

  const tabs = useMemo(
    () =>
      [
        {
          id: "suggested",
          title: t("suggestedSkills"),
          infoText: t("suggestions.suggested.info"),
          skills: suggestedSkills as unknown as ProfileCustomValue[]
        },
        {
          id: "framework",
          title: t("suggestions.framework.title"),
          infoText: t("suggestions.framework.info", { name: currentAccount.name }),
          skills: convertActivityToProfileCustomValues(
            suggestedFrameworksSkills as unknown as ActivityCustomValue[]
          ),
          disabled: isLoadingSuggestedFrameworksSkills
        },
        {
          id: "discovered",
          title: t("discoveredSkills"),
          infoText: t("suggestions.discovered.title"),
          skills: discoveredSkills as unknown as ProfileCustomValue[],
          disabled: discoveredSkillsLoading
        }
      ].filter(({ skills }) => skills.length),
    [
      currentAccount.name,
      discoveredSkills,
      discoveredSkillsLoading,
      suggestedSkills,
      suggestedFrameworksSkills,
      isLoadingSuggestedFrameworksSkills,
      t
    ]
  );

  return (
    <>
      <div className={css.outerGrid}>
        <div className={css.sidebar}>
          <Card className={css.newSkill}>
            <Typography variant="h1" className={css.title}>
              {t("mySkills")}
            </Typography>
            <Typography variant="h4">{t("addNewSkill")}</Typography>
            <div className={css.skillSelectorContainer}>
              <CustomValuesEditField
                key={newSkills.length}
                kind="bordered"
                label={t("skillLabel")}
                placeholder={newSkills.length === 0 ? t("skillPlaceholder") : undefined}
                tip=""
                customType={skillsCustomType ?? null}
                values={newSkills as any}
                qaIdPrefix="profile-skills-new-input"
                adminPage={false}
                letClear
                useProfilesEndpoint
                profile={currentProfile}
                handleChange={(changedSkills) => handleSkillChange(changedSkills as any[])}
                hasValuesWithExpiryDate={false}
                disabledValues={skillsValues}
                parseResponse={(options) =>
                  options.map((option) => {
                    const canonicalOptionText = canonicalId(option.text);
                    const isAlreadyAdded =
                      (option.added && !deletedValues.includes(canonicalOptionText)) ||
                      addedValues.includes(canonicalOptionText);
                    const isBlocked = option.state === "blocked";
                    const isDisabled = isBlocked || isAlreadyAdded;

                    return {
                      ...option,
                      disabled: isDisabled,
                      displayElement: (
                        <Tooltip
                          content={option.description && <Description text={option.description} />}
                          placement="right"
                          interactive
                          className={css.descTooltip}
                        >
                          <span className={css.selectValueWrapper}>
                            {option.text}
                            {isDisabled && (
                              <Pill className={css.pill} small dark>
                                {t(isAlreadyAdded ? "added" : option.state)}
                              </Pill>
                            )}
                          </span>
                        </Tooltip>
                      )
                    };
                  })
                }
              />
              {blockedSkills.length > 0 && <BlockedSkillsAlert blockedSkills={blockedSkills} />}
            </div>
            <div>
              <Typography variant="labelRegular" tag="span">
                {t("ratingLabel")}
              </Typography>
              <SkillRatingIcon
                className={css.skillRating}
                value={newSkillRating}
                isEditMode
                size={25}
                updateExperience={setNewSkillRating}
              />
            </div>
            <Button
              onClick={addNewSkill}
              disabled={blockedSkills.length > 0 || newSkills.length === 0 || !newSkillRating}
            >
              {t("addSkill")}
            </Button>
          </Card>
          <Card className={css.insights}>
            <Typography variant="h4">{t("skillInsights")}</Typography>
            <SkillsExplorer profile={profile} skills={skills} selectedChart="bubble" showTooltip={false} />
          </Card>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={css.content}>
            <div className={css.controlsButtons}>
              <Button
                kind="secondary"
                onClick={() => {
                  if (changesLog.length > 0) {
                    setIsConfirmModalDisplayed(true);
                  } else {
                    closeWithAnimation();
                  }
                }}
              >
                {defaultT("cancel")}
              </Button>
              <Button onClick={saveChanges} disabled={changesLog.length === 0}>
                {t("saveAndClose")}
              </Button>
            </div>
            <SkillsGrid
              basicSkills={basicSkills}
              intermediateSkills={intermediateSkills}
              advancedSkills={advancedSkills}
              skillsSortingSelected={skillsSortingSelected}
              setSkillsSortingSelected={setSkillsSortingSelected}
              addToLog={addToLog}
              coreSkillsCount={coreSkillsCount}
              developmentalSkillsCount={developmentalSkillsCount}
            />
            <div>
              {tabs.length > 0 && (
                <SkillsSuggestions
                  addToLog={addToLog}
                  tabs={tabs}
                  profile={profile}
                  onSkillRanked={(customValue) => {
                    setSkills((prevSkills) => [customValue, ...prevSkills]);

                    // remove the log describing suggestion removal to avoid skill unlink
                    // after its added to profile via SkillsFrameworksSuggestedSkillsModal
                    removeFromLog(
                      ({ id, type, oldData }) => customValue.id === id && type === "delete" && !oldData
                    );
                  }}
                />
              )}
            </div>
          </div>
        </DragDropContext>
      </div>
      {isConfirmModalDisplayed && (
        <Modal
          title={t("closeSkillsModal")}
          onOK={() => {
            closeWithAnimation();
            setIsConfirmModalDisplayed(false);
          }}
          onClose={() => {
            setIsConfirmModalDisplayed(false);
          }}
          labelOK={defaultT("ok")}
        >
          {t("leavingPageWillDiscardChanges")}
        </Modal>
      )}
    </>
  );
};
