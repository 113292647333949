import { InterestQuestionnaire } from "PFTypes";

export enum TemplatesConfigurationActionType {
  TemplatesConfigurationSet = "TEMPLATES_CONFIGURATION_SET"
}

interface Action {
  type: TemplatesConfigurationActionType;
  payload: InterestQuestionnaire[];
}

export const templatesConfigurationReducer = (state: any, { type, payload }: Action) => {
  switch (type) {
    case TemplatesConfigurationActionType.TemplatesConfigurationSet:
      return {
        ...state,
        templatesConfiguration: payload
      };
  }
};
