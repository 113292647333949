import classNames from "classnames";
import { forIn } from "lodash";
import { EventKey } from "PFTypes/event_key";
import { DragEvent, useRef, useState } from "react";

import { DraggedOverlay } from "./dragged_overlay";
import css from "./file_upload.module.scss";
import { useUploadFile } from "./use_upload_file";

type FileUploadProps<RESPONSE_TYPE extends object> = React.PropsWithChildren<{
  name: string;
  url?: string;
  data?: object;
  handleChange?: (file: File) => void;
  handleLoad?: (response: RESPONSE_TYPE) => void;
  handleError?: (response: RESPONSE_TYPE) => void;
  handleTypeError?: () => void;
  allowedFileTypes?: string[];
  acceptMediaTypes?: string[];
  qaId?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  classes?: {
    root?: string;
    label?: string;
    content?: string;
  };
  disabled?: boolean;
}>;

export const FileUpload = <RESPONSE_TYPE extends object>({
  name,
  url,
  data,
  handleChange,
  handleLoad,
  handleError,
  allowedFileTypes = [],
  acceptMediaTypes,
  handleTypeError,
  qaId,
  inputRef,
  classes,
  disabled,
  children
}: FileUploadProps<RESPONSE_TYPE>) => {
  const uploadRef = useRef<HTMLInputElement>(null);

  const { mutate: uploadFile } = useUploadFile<RESPONSE_TYPE>({
    options: {
      onSuccess: (data) => {
        handleLoad && handleLoad(data);
      },
      onError: (error) => {
        handleError && handleError(error);
      }
    }
  });

  const [dragging, setDragging] = useState(false);

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDragging(false);

    const file = event.dataTransfer ? event.dataTransfer.files[0] : null;

    if (file) {
      validateFileType(file);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      event.target.value = ""; // reset so you can trigger change multiple times
      validateFileType(file);
    }
  };

  const validateFileType = (file: File) => {
    const isValidType = allowedFileTypes.some((type) => file.name.endsWith(type));
    if (!allowedFileTypes.length || isValidType) {
      handleChangeFunction(file);
    } else {
      handleTypeError && handleTypeError();
    }
  };

  const handleChangeFunction = (file: File) => {
    handleChange && handleChange(file);

    if (!url) {
      return;
    }

    const formData = new FormData();
    forIn(data, (value, key) => {
      formData.append(key, value);
    });

    formData.append(name, new Blob([file]), file.name);

    uploadFile({
      url,
      data: formData
    });
  };

  return (
    <label className={classNames(css.label, classes?.label)} htmlFor="file-upload-input">
      <div
        role="button"
        tabIndex={0}
        className={classNames(css.container, { [css.dragging]: dragging }, classes?.root)}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onKeyDown={(event) => event.key === EventKey.Enter && (inputRef || uploadRef)?.current?.click()}
      >
        <div className={classNames(css.wrapper, classes?.content)}>{children}</div>
        <input
          id="file-upload-input"
          ref={inputRef || uploadRef}
          onChange={handleInputChange}
          name={name}
          type="file"
          accept={acceptMediaTypes?.join(",")}
          disabled={disabled}
          data-qa-id={qaId}
          className={css.input}
        />
        {dragging && <DraggedOverlay onDragLeave={handleDragLeave} />}
      </div>
    </label>
  );
};
