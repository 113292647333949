import classNames from "classnames";
import useLinkedinConnect from "PFApp/linkedin_connect/use_linkedin_connect";
import css from "PFApp/profiles/profile_linkedin_connect_button.less";
import { LoadingDots } from "PFComponents/loading_dots";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import linkedInLogoUrl from "PFImages/premium/linkedin_logo.png";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const ProfileLinkedInConnectButton = ({ style, profile }) => {
  const { t } = useTranslation("profiles");

  const isEnabled = useIsFeatureEnabled();
  const { connect, disconnect, loading } = useLinkedinConnect();

  if (!isEnabled(FeatureFlag.LinkedinSignin)) {
    return null;
  }

  const connected = profile.connected_to_linkedin;
  const qaId = "linked-in-button";
  const text = connected ? t("disconnectFrom") : t("connectTo");
  const handleClick = connected ? disconnect : connect;

  return (
    <button
      className={classNames(css.root, { [css.disconnect]: connected }, { [css.loading]: loading })}
      disabled={loading}
      data-qa-id={qaId}
      style={style}
      onClick={handleClick}
    >
      {loading && <LoadingDots className={css.loading_dots} />}
      <div className={classNames({ [css.loading]: loading })}>
        {`${text} `}
        <img src={linkedInLogoUrl} alt="LinkedIn" />
      </div>
    </button>
  );
};

ProfileLinkedInConnectButton.propTypes = {
  profile: PropTypes.any,
  style: PropTypes.object
};

ProfileLinkedInConnectButton.defaultProps = {
  style: null
};

export default ProfileLinkedInConnectButton;
