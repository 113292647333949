import classNames from "classnames";
import { RELEVANT_TO_ME_ID } from "PFApp/constants/relevant_to_me";
import { checkFiltersAreClear, getAPIFilters, getValueFilters } from "PFApp/use_filtered_collection";
import { Button } from "PFComponents/button";
import { SavedFilterCreateData } from "PFCore/services/saved_filters";
import { Id } from "PFTypes";
import { PageTarget } from "PFTypes/saved_filters";
import { useTranslation } from "react-i18next";

import { FiltersBaseProps } from "../filters_panel/filters_element";
import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import css from "./save_as_view.module.scss";
import { SaveAsViewModal } from "./save_as_view_modal";
import { SaveAsViewModalMode } from "./save_as_view_modal/save_as_view_modal";
import { useSaveAsViewModal } from "./save_as_view_modal/use_save_as_view_modal";
import { SavedViewsBadges } from "./saved_views_badges";

type SaveAsViewProps = {
  className?: string;
  meta: FiltersBaseProps["meta"];
  clearFilters?: (filters: any) => void;
  viewsKey: PageTarget | null;
  isRelevantToMeDisabled?: boolean;
};

export const SaveAsView = ({
  className,
  meta,
  viewsKey,
  isRelevantToMeDisabled = false,
  clearFilters
}: SaveAsViewProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    views,
    saveFiltersAsView,
    deleteFiltersView,
    selectedView,
    setSelectedViewId,
    isLoading,
    shareFilters
  } = useSavedViewsContext(viewsKey);

  const { open, close, isOpen, data } = useSaveAsViewModal();

  const valueFilters = getValueFilters(meta?.filters);

  const handleSave = (viewName: string, profilesIds: number[], mode: SaveAsViewModalMode, id?: Id) => {
    if (mode === SaveAsViewModalMode.Share && id) {
      shareFilters({
        id,
        data: {
          profilesIds,
          name: viewName
        }
      });
    } else {
      const apiFilters = getAPIFilters(valueFilters);
      // TODO: [PROF-2876] added typing indicate that something is wrong here!
      // @ts-ignore
      const newSavedFilter: SavedFilterCreateData = {
        name: viewName,
        target: viewsKey!,
        ...apiFilters
      };

      saveFiltersAsView(newSavedFilter).then(({ id }) => {
        setSelectedViewId(id);
      });
    }

    clearFilters && clearFilters(valueFilters);
  };

  const isSaveAsViewDisabled = !!selectedView || checkFiltersAreClear(meta?.filters);

  const viewsWithAdditionalParams = views.map((savedFilter) => {
    const isRelevantToMeSavedFilter = savedFilter.id === RELEVANT_TO_ME_ID;
    const isRelevantToMeAndDisabled = isRelevantToMeSavedFilter && isRelevantToMeDisabled;

    return {
      ...savedFilter,
      disabled: isRelevantToMeAndDisabled,
      allowRemove: !isRelevantToMeSavedFilter,
      tooltipContent: isRelevantToMeAndDisabled ? t("turnOffRequirementsToUseSavedFilter") : null
    };
  });

  return (
    <div className={classNames(css.savedFilters, className)}>
      <SavedViewsBadges
        valueFilters={valueFilters}
        savedViewSelected={selectedView}
        views={viewsWithAdditionalParams}
        isRelevantToMeDisabled={isRelevantToMeDisabled}
        isLoading={isLoading}
        clearFilters={clearFilters}
        deleteFiltersView={deleteFiltersView}
        onChangeSelectedView={(id) => setSelectedViewId(id)}
        open={open}
      />
      <div>
        <Button kind="secondary" onClick={() => open()} disabled={isSaveAsViewDisabled}>
          {t("filters.save.saveAsView")}
        </Button>
      </div>

      {isOpen && <SaveAsViewModal views={views} onSave={handleSave} onClose={close} initialData={data} />}
    </div>
  );
};
