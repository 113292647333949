import classNames from "classnames";
import { MatchGauge } from "PFComponents/resource_metrics/match_gauge";
import { profileResponseShapePropType } from "PFCore/base/prop_types/profile_response_prop_types";
import PropTypes from "prop-types";
import { useCallback } from "react";

import { Card } from "../card";
import ProfileCardSkills from "./parts/profile_card_skills";
import ProfileCardTitle from "./parts/profile_card_title";
import css from "./profile_card.module.scss";

/**
 * @deprecated Use application\components\profile_card
 */
const ProfileCard = ({
  className,
  currentAccount,
  currentProfile,
  profileResponse,
  actions,
  headerActions,
  moreInfoTrackEvent,
  embeded,
  topRightCornerNode,
  children,
  footer,
  Icon,
  isColumn,
  handleClick,
  handleIconClick,
  isBlindModeOn,
  outlook,
  gaugeProps
}) => {
  const { profile, scores } = profileResponse;
  const skillsStyle = embeded ? { borderTop: 0 } : {};

  const href = `/profiles/${profile.id}`;

  const onAvatarClick = useCallback(
    (e) => {
      e.preventDefault();
      outlook ? window.open(`${location.origin}${href}`, "_blank") : handleClick();
    },
    [href, outlook, handleClick]
  );

  const guts = (
    <>
      <div className={css.header}>
        <ProfileCardTitle
          currentProfile={currentProfile}
          handleClick={handleClick}
          handleIconClick={handleIconClick}
          href={href}
          Icon={Icon}
          isBlindModeOn={isBlindModeOn}
          profile={profile}
          onAvatarClick={onAvatarClick}
          outlook={outlook}
        />

        <div className={css.topRightCorner}>
          {scores && <MatchGauge match={{ scores }} className={css.match} {...gaugeProps} />}
          {headerActions && <div className={css.headerActions}>{headerActions}</div>}
          {topRightCornerNode}
        </div>
      </div>

      <ProfileCardSkills
        sort={false}
        currentAccount={currentAccount}
        profileResponse={profileResponse}
        style={skillsStyle}
        currentProfile={currentProfile}
        outlook={outlook}
        moreInfoTrackEvent={moreInfoTrackEvent}
        isBlindModeOn={isBlindModeOn}
      />

      {children}

      {actions && (
        <>
          <hr className={css.separator} />
          <div className={classNames(css.skillsAndActions, { [css.skillsAndActionsColumn]: isColumn })}>
            {!isColumn && <small> &nbsp;</small>}
            {actions && <div className={classNames({ [css.actions]: isColumn })}>{actions}</div>}
          </div>
        </>
      )}

      {footer}
    </>
  );

  return embeded ? (
    <div className={classNames(css.embededWrap, className)}>{guts}</div>
  ) : (
    <Card className={className} paddingVariant="lg">
      {guts}
    </Card>
  );
};

ProfileCard.propTypes = {
  profileResponse: profileResponseShapePropType.isRequired,
  currentProfile: PropTypes.object.isRequired,
  currentAccount: PropTypes.object,
  actions: PropTypes.node,
  headerActions: PropTypes.node,
  embeded: PropTypes.bool,
  outlook: PropTypes.bool,
  className: PropTypes.string,
  moreInfoTrackEvent: PropTypes.string,
  children: PropTypes.element,
  footer: PropTypes.element,
  topRightCornerNode: PropTypes.node,
  onlyRelated: PropTypes.bool,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isColumn: PropTypes.bool,
  handleClick: PropTypes.func,
  handleIconClick: PropTypes.func,
  /*! makes profiles names hashed and avatar blank for fair-recruitement reasons */
  isBlindModeOn: PropTypes.bool,
  gaugeProps: PropTypes.shape(MatchGauge.propTypes)
};

ProfileCard.defaultProps = {
  outlook: false,
  gaugeProps: {}
};

export default ProfileCard;
