import { Button } from "PFComponents/button";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Activity, Profile } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import EditInternalExperienceForm from "../../../../common/experience/edit_internal_experience_form";
import css from "../experience_section.modules.scss";

type InternalExperienceEditButtonProps = {
  experience: Activity;
  fetchInternalExperience: () => void;
  profile: Profile;
};

export const InternalExperienceEditButton = ({
  experience,
  fetchInternalExperience,
  profile
}: InternalExperienceEditButtonProps) => {
  const { t } = useTranslation("profiles");
  const { data: currentProfile } = useCurrentProfile();

  const isMe = profile?.id && profile?.id === currentProfile?.id;

  const [editMode, setEditMode] = useState(false);

  const completeEdition = () => {
    fetchInternalExperience();
    setEditMode(false);
  };

  if (!isMe) {
    return null;
  }

  return (
    <>
      <Button
        aria-label={t("common.experience.internalExperience.editInternalExperience")}
        aria-expanded={editMode}
        aria-haspopup="dialog"
        className={css.editIcon}
        kind="secondary"
        icon="edit"
        onClick={() => setEditMode(true)}
      />
      {editMode && (
        <EditInternalExperienceForm
          experience={experience}
          profile={profile}
          adminPage={false}
          handleSuccess={completeEdition}
          handleRemove={completeEdition}
          onClose={() => setEditMode(false)}
        />
      )}
    </>
  );
};
