import { ActionDiv } from "PFComponents/divs";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./role_information_header.module.scss";

type RoleInformationHeaderProps = {
  role: Activity;
  isOpen: boolean;
  onClick: () => void;
  qaId?: string;
};

export const RoleInformationHeader = ({ isOpen, onClick, qaId }: RoleInformationHeaderProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.roleInfo" });

  return (
    <div className={css.header}>
      <ActionDiv className={css.title} onClick={onClick} ariaLabel={t("title")} data-qa-id={qaId}>
        <Icon name={isOpen ? "chevron-down" : "chevron-right"} color="paletteWhite0" size="sm" />
        <Typography variant="bodyBold" noMargin>
          {t("title")}
        </Typography>
      </ActionDiv>
    </div>
  );
};
