import ChangeAssigneeModal from "PFApp/activities/show/parts/change_assignee_modal";
import { Button } from "PFComponents/button";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ChangeAssigneeButton = ({ buttonKind, buttonStyle }) => {
  const [showAssingee, setShowAssignee] = useState(false);
  const { t } = useTranslation("activities");

  return (
    <>
      <Button
        text={t("show.parts.setAssignee")}
        kind={buttonKind || "secondary"}
        style={buttonStyle}
        qaId="activity-change-assignee"
        onClick={() => setShowAssignee(true)}
      />
      {showAssingee && <ChangeAssigneeModal onClose={() => setShowAssignee(false)} />}
    </>
  );
};

ChangeAssigneeButton.propTypes = {
  buttonKind: PropTypes.string,
  buttonStyle: PropTypes.object
};

export default ChangeAssigneeButton;
