import { Button } from "PFComponents/button";
import { Activity, BookingCategory, Id } from "PFTypes";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import WarningIcons from "../../warning_icons/warning_icons";
import { BookingFormProps } from "../booking_form";
import { BookingFormValues } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";
import { PotentialWarnings } from "../use_potential_warnings";
import { CreateButton } from "./create_button";
import { CreateWithOverbookingButtonGroup } from "./create_with_overbooking_button_group";
import css from "./footer.module.scss";

type FooterProps = {
  profileId: Id | undefined;
  activity: Activity | undefined;
  onClose: () => void;
  isSubmitDisabled: boolean;
  isLoading: boolean;
  mode: BookingFormMode;
  overbookingIntersectionsDates: string[] | undefined;
} & Pick<PotentialWarnings, "overbookings" | "misalignments"> &
  Pick<BookingFormProps, "onOpenOverbookingsManagementModal">;

export const Footer = ({
  profileId,
  activity,
  overbookings,
  misalignments,
  overbookingIntersectionsDates,
  onClose,
  isSubmitDisabled,
  isLoading,
  mode,
  onOpenOverbookingsManagementModal
}: FooterProps) => {
  const { t } = useTranslation();
  const [bookings, globalCategory, globalTitle] = useWatch<BookingFormValues>({
    name: ["bookings", "globalCategory", "globalTitle"]
  });

  const potentialBookings =
    mode !== BookingFormMode.Edit
      ? (bookings ?? []).map(({ key, title, category, startDate, endDate }) => ({
          key,
          title: title ?? globalTitle,
          booking_category_id: category?.id ?? globalCategory?.id,
          start_date: startDate,
          end_date: endDate
        }))
      : [];

  const misalignmentsWithProfile = profileId
    ? [
        {
          profileId,
          misalignments
        }
      ]
    : [];

  const getConfirmButtonLabel = () => {
    if (mode === BookingFormMode.Edit) {
      return t("update");
    } else {
      return t("create");
    }
  };

  const hasOverbookingsCausedByOtherBookings = (overbookingIntersectionsDates ?? []).length > 0;
  const isCreateMode = mode === BookingFormMode.Create;

  const shouldDisplayOverbookingButtonGroup =
    mode !== BookingFormMode.Edit &&
    hasOverbookingsCausedByOtherBookings &&
    (isCreateMode
      ? (globalCategory as BookingCategory)?.affects_availability
      : (bookings ?? [])
          .map((booking) =>
            booking?.category ? (booking?.category as BookingCategory)?.affects_availability : false
          )
          .reduce((acc, curr) => acc || curr, false));

  return (
    <div className={css.root}>
      <div className={css.buttonsSection}>
        <Button
          icon={shouldDisplayOverbookingButtonGroup ? "cross" : undefined}
          type="button"
          kind="secondary"
          text={!shouldDisplayOverbookingButtonGroup ? t("cancel") : undefined}
          onClick={onClose}
        />
        <div className={css.content}>
          <WarningIcons
            profileId={profileId}
            roleId={activity?.id}
            roleName={activity?.name ?? ""}
            overbookings={overbookings}
            misalignments={misalignmentsWithProfile}
            potentialBookings={potentialBookings}
          />
        </div>
        {shouldDisplayOverbookingButtonGroup ? (
          <CreateWithOverbookingButtonGroup
            isSubmitDisabled={isSubmitDisabled}
            isLoading={isLoading}
            onOpenOverbookingsManagementModal={onOpenOverbookingsManagementModal}
            overbookingIntersectionsDates={overbookingIntersectionsDates ?? []}
          />
        ) : (
          <CreateButton label={getConfirmButtonLabel()} isLoading={isLoading} disabled={isSubmitDisabled} />
        )}
      </div>
    </div>
  );
};
