import { flexRender, Header } from "@tanstack/react-table";
import { SortDirection } from "@tanstack/react-table";
import classNames from "classnames";
import { clone, findIndex, reverse } from "lodash";
import { useTranslation } from "react-i18next";

import { TableData } from "./table";
import css from "./table.module.scss";
import { TableSorting } from "./table_sorting";

type ColumnHeaderProps<T extends TableData> = {
  header: Header<T, unknown>;
  compact?: boolean;
};

type IsSorted = false | SortDirection;

const SORT_ORDER: IsSorted[] = ["asc", "desc", false];

export const ColumnHeader = <TABLE_DATA extends TableData>({
  header,
  compact
}: ColumnHeaderProps<TABLE_DATA>) => {
  const { t } = useTranslation("core", { keyPrefix: "components.table" });
  const canSort = header.column.getCanSort();
  const isSorted = header.column.getIsSorted();

  const Component = canSort ? "button" : "div";

  const getAriaLabel = () => {
    const invertSorting = !!header.column.columnDef.invertSorting;
    const columnName = header.column.columnDef.meta?.ariaLabel || t("column");
    const isDefaultOrder = !!header.column.columnDef.meta?.isDefaultOrder;

    const sortOrder = invertSorting ? reverse(clone(SORT_ORDER)) : SORT_ORDER;
    const nextOrderIndex = (findIndex(sortOrder, (item) => item === isSorted) + 1) % sortOrder.length;
    const nextOrder = sortOrder[nextOrderIndex] as IsSorted;

    if (!nextOrder && isDefaultOrder && isSorted) {
      return {
        asc: t("orderByDescending", { columnName }),
        desc: t("orderByAscending", { columnName })
      }[isSorted];
    }

    if (!nextOrder) {
      return t("resetOrderFor", { columnName });
    }

    return {
      asc: t("orderByAscending", { columnName }),
      desc: t("orderByDescending", { columnName })
    }[nextOrder];
  };

  return (
    <th
      className={classNames({ [css.compactHeader]: compact })}
      style={{
        width: header.getSize() !== 0 ? header.getSize() : undefined
      }}
    >
      <Component
        className={classNames(css.headerButton, { [css.sortableColumn]: canSort })}
        onClick={header.column.getToggleSortingHandler()}
        {...(canSort
          ? {
              "aria-label": getAriaLabel()
            }
          : {})}
      >
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
        {canSort && <TableSorting isSorted={isSorted} />}
      </Component>
    </th>
  );
};
