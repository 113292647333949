import classNames from "classnames";
import { SkipLink } from "PFComponents/skip_link/skip_link";
import { createPortal } from "react-dom";

import css from "./skip_to_content.module.scss";

type SkipToContentProps = {
  targetId: string;
  text: string;
  isRoot?: boolean;
  className?: string;
};

export const SkipToContent = ({ className, targetId, text, isRoot }: SkipToContentProps) => {
  const skipLinksRegion = document.getElementById("skip_links_region");
  const targetElement = document.getElementById(targetId);

  if (!targetElement) {
    return null;
  }

  const skipLink = (
    <SkipLink className={classNames(css.skipToContent, className)} targetId={targetId} text={text} />
  );
  return isRoot && skipLinksRegion ? createPortal(skipLink, skipLinksRegion) : skipLink;
};
