import { Camelized } from "humps";
import api from "PFCore/api";
import { Booking, BookingCategory, RequirementType } from "PFTypes";

export type BookingData = {
  id: number | undefined;
  startDate: string;
  endDate: string;
  requirementValue: number | undefined;
  requirementType: RequirementType;
  category: BookingCategory | undefined;
  bookingCategoryId?: number;
};

export enum OverbookingsManagementMode {
  AdjustOther = "adjust_other",
  AdjustCurrent = "adjust_current"
}

export type OverbookedDates = string[];

export type SuggestedChangesPayload = {
  profileId: number;
  overbookedDates: OverbookedDates;
  mode: OverbookingsManagementMode;
  bookings: BookingData[];
};

export enum SuggestedChangeAction {
  Create = "create",
  Update = "update",
  Delete = "delete"
}

export type SuggestedChange = {
  action: SuggestedChangeAction;
  data?: {
    startDate: string;
    endDate: string;
    requirementValue: number;
  };
};

export type CamelizedBooking = Camelized<Booking>;

export type BookingChange = {
  booking: BookingData | CamelizedBooking;
  changes: SuggestedChange[];
};

export type SuggestedChangesResponse = BookingChange[];

export const getSuggestedChanges = (
  data: SuggestedChangesPayload | null
): Promise<SuggestedChangesResponse> =>
  api({
    url: `booking_module/bookings/suggested_changes`,
    method: "POST",
    data
  });
