import { map } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import AddActivityProfiles from "PFApp/activities/show/parts/add_activity_profiles";
import { useGrowl } from "PFApp/use_growl";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { Profile } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type AddShortlistModalProps = {
  onClose: VoidFunction;
};

export const AddShortlistModal = ({ onClose }: AddShortlistModalProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.addShortlistModal" });
  const growl = useGrowl();

  const { task: activity, allShortlists } = useActivityPageContext();
  const { mutateAsync: createShortlist } = useShortlistCreate();

  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const shortlistCreators = map(profiles, ({ id: profileId }) =>
      createShortlist({ activityId: activity.id, profileId, named: true })
    );

    await Promise.all(shortlistCreators)
      .then(() => {
        growl({ message: t("successMessage") });
      })
      .catch(() => {
        growl({ message: t("failedMessage"), kind: "error" });
      });

    setIsSaving(false);
  };

  return (
    <AddActivityProfiles
      title={t("title")}
      submitButtonLabel={t("addToShortlist")}
      newProfiles={profiles}
      onNewProfilesChange={setProfiles}
      profiles={[]}
      disabledProfileIds={allShortlists.map(({ profile }) => profile.id)}
      task={activity}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
