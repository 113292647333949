import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { Booking } from "PFTypes";
import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";

import { BookingFormValues, BookingType } from "../booking_form_provider";

type UseInitialBookingType = {
  initialBooking?: Booking;
  setValue: UseFormSetValue<BookingFormValues>;
};

export const useInitialBookingType = ({ initialBooking, setValue }: UseInitialBookingType) => {
  const { isEnabled: isBMEnabled, isBookingResourcer } = useBookingModulePermissions();

  useEffect(() => {
    if (isBMEnabled && isBookingResourcer && initialBooking?.booking_template_id) {
      setValue("bookingType", BookingType.Repeated, { shouldValidate: true });
    } else {
      setValue("bookingType", BookingType.Single, { shouldValidate: true });
    }
  }, [initialBooking]);
};
