import { MatchGauge } from "PFComponents/resource_metrics/match_gauge";
import Tooltip from "PFComponents/tooltip/tooltip";
import { getMatchDisplayValue } from "PFCore/helpers/match_value";
import { IMatchScores } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./match_value.module.scss";

type MatchValueTooltippedProps = {
  scores: IMatchScores | null | undefined;
  scoreName: string;
};

const MatchValueTooltipped = ({ scores, scoreName }: MatchValueTooltippedProps) => {
  const { t } = useTranslation();
  const scoreValue = scores?.[scoreName];
  const isGaugeVisible = !!scores && !!scoreValue;
  const displayValue = getMatchDisplayValue(scoreValue);
  const translatedScoreName = t(`table.tooltips.matchScore.${scoreName}` as unknown as TemplateStringsArray);

  const tooltipContent = useMemo(() => {
    if (isGaugeVisible) {
      return <MatchGauge match={{ scores }} />;
    }

    return t("table.tooltips.matchScore.noScore", { scoreName: translatedScoreName });
  }, [isGaugeVisible, scores, t, translatedScoreName]);

  return (
    <Tooltip theme="pf-white" content={tooltipContent}>
      <div className={css.matchContainer}>
        <span className={css.matchValue}>{displayValue}</span>
        {scoreValue && <span className={css.percentageSign}>%</span>}
      </div>
    </Tooltip>
  );
};

export default MatchValueTooltipped;
