import { compact } from "lodash";
import { useSavedFilters, useSavedFiltersCreate } from "PFCore/hooks/queries/filters";
import { useRelevantToMe } from "PFCore/hooks/queries/filters/use_relevant_to_me";
import { useSavedFiltersDelete } from "PFCore/hooks/queries/filters/use_saved_filters_delete";
import { useShareSavedFilters } from "PFCore/hooks/queries/filters/use_share_saved_filters";
import { Id } from "PFTypes/id";
import { PageTarget } from "PFTypes/saved_filters";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../../../use_growl";
import { SavedViewsType } from "./saved_views_context";

export const useSavedViews = (viewsKey: PageTarget, withRelevantToMe: boolean): SavedViewsType => {
  const growl = useGrowl();
  const { t } = useTranslation();
  const [selectedViewId, setSelectedViewId] = useState<Id | null>(null);

  const { data: savedViews, isFetching: isLoadingSavedFilters } = useSavedFilters(viewsKey);
  const { data: relevantToMe, isFetching: isLoadingRelevantToMe } = useRelevantToMe(viewsKey, {
    enabled: withRelevantToMe
  });
  const { mutateAsync: createSavedFilter, isLoading: isCreating } = useSavedFiltersCreate(viewsKey);
  const { mutateAsync: deleteSavedFilter, isLoading: isDeleting } = useSavedFiltersDelete(viewsKey);

  const { mutate: shareSavedFilters } = useShareSavedFilters({
    onSuccess: () => {
      growl({
        kind: "success",
        message: t("filters.share.onSuccess")
      });
    },
    onError: () => {
      growl({
        kind: "error",
        message: t("filters.share.onError")
      });
    }
  });

  const views = useMemo(
    () => [...compact([relevantToMe]), ...(savedViews || [])],
    [relevantToMe, savedViews]
  );

  const selectedView = useMemo(
    () => views.find(({ id }) => id === selectedViewId) ?? null,
    [views, selectedViewId]
  );

  return {
    views,
    saveFiltersAsView: createSavedFilter,
    deleteFiltersView: deleteSavedFilter,
    shareFilters: shareSavedFilters,
    selectedView,
    setSelectedViewId,
    isLoading: isLoadingSavedFilters || isLoadingRelevantToMe || isCreating || isDeleting
  };
};
