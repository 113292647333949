import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import colors from "PFTheme/tokens/colors";
import { Experience } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useExperienceRadial } from "../../use_experience_radial";
import { CustomColors, ExperienceColorVariant, ExperienceRadial } from "../experience_radial";
import css from "./experience_tooltip.module.scss";

type ExperienceTooltipProps = {
  experience: Experience | null;
  expectedExperience: Experience;
};

const CUSTOM_COLORS: CustomColors = {
  [ExperienceColorVariant.Active]: colors.paletteWhite0,
  [ExperienceColorVariant.Neutral]: colors.paletteBlue2
};

export const ExperienceTooltip = ({
  experience,
  expectedExperience
}: ExperienceTooltipProps): JSX.Element => {
  const { t: tApp } = useTranslation();
  const { t: tCore } = useTranslation("core");
  const { colorVariants: expectedColorVariants } = useExperienceRadial({ experience: expectedExperience });
  const { colorVariants: workforceMemberColorVariants } = useExperienceRadial({ experience });

  const experienceToTranslationMap = {
    [Experience.Basic]: tCore("skillRatings.basic"),
    [Experience.Intermediate]: tCore("skillRatings.intermediate"),
    [Experience.Advanced]: tCore("skillRatings.advanced")
  };

  return (
    <div className={css.tooltipContent}>
      <span className={css.column}>
        <Typography variant="labelBold" noMargin>
          {tApp("radialSkill.required")}
        </Typography>
        <ExperienceRadial colorVariants={expectedColorVariants} customColors={CUSTOM_COLORS} />
        <Typography variant="labelBold" noMargin>
          {experienceToTranslationMap[expectedExperience]}
        </Typography>
      </span>
      <Divider type="vertical" color="paletteBlue2" theme={{ divider: css.divider }} />
      <span className={css.column}>
        {experience ? (
          <>
            <Typography variant="labelBold" noMargin>
              {tApp("radialSkill.wmHas")}
            </Typography>
            <ExperienceRadial colorVariants={workforceMemberColorVariants} customColors={CUSTOM_COLORS} />
            <Typography variant="labelBold" noMargin>
              {experienceToTranslationMap[experience]}
            </Typography>
          </>
        ) : (
          <Typography variant="labelBold" className={css.noSkillText} noMargin>
            {tApp("radialSkill.wmDoesNotHaveThatSkill")}
          </Typography>
        )}
      </span>
    </div>
  );
};
