import classNames from "classnames";
import { Card } from "PFComponents/card";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

import { SkipToContent } from "../../../components/skip_to_content";
import css from "./page_section.module.scss";

type PageSectionProps = {
  title?: string | React.ReactNode;
  scrollKey?: string;
  hType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children?: React.ReactNode;
  action?: React.ReactNode;
  id?: string;
  linkTo?: string;
  tags?: React.ReactElement;
  actionWrapStyle?: React.CSSProperties;
  className?: string;
  contentStyle?: React.CSSProperties;
  titleAction?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  skipToSection?: {
    targetId: string;
    text: string;
  };
};

export const PageSection = forwardRef<HTMLDivElement, PageSectionProps>(
  (
    {
      title,
      scrollKey,
      hType = "h3",
      children,
      action,
      actionWrapStyle,
      id,
      linkTo,
      tags,
      contentStyle,
      className,
      titleAction,
      header,
      footer,
      skipToSection
    },
    ref
  ) => {
    const HTag = hType;

    const renderHeaderContent = () => {
      if (header) {
        return header;
      }

      return (
        <>
          {(title || action || titleAction) && title && (
            <HTag className={css.title}>
              {tags}
              {linkTo ? <Link to={linkTo}>{title}</Link> : title}
            </HTag>
          )}
          {titleAction}
          {action && (
            <div style={actionWrapStyle} className={css.actionWrap}>
              {action}
            </div>
          )}
        </>
      );
    };

    return (
      <Card id={id} className={classNames(css.sectionBox, className)}>
        {skipToSection && (
          <SkipToContent
            className={css.skipToSection}
            targetId={skipToSection.targetId}
            text={skipToSection.text}
          />
        )}
        <div ref={ref} data-scroll-key={scrollKey} className={css.root}>
          <div className={css.header}>{renderHeaderContent()}</div>
          <div className={css.content} style={contentStyle}>
            <div className={css.contentInner}>{children}</div>
          </div>
          {footer && <div className={css.footer}>{footer}</div>}
        </div>
      </Card>
    );
  }
);

PageSection.displayName = "PageSection";
