import moment from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UseReadableDuration {
  duration?: Partial<{
    start_date: string | null;
    end_date: string | null;
    current: boolean;
  }>;
}

export const useReadableDuration = ({ duration }: UseReadableDuration): string => {
  const { t } = useTranslation("core");
  const { formatDate, utc } = useDateFormatter();

  return useMemo(() => {
    if (!duration || !duration.start_date || !duration.end_date) {
      return "";
    }
    const startDate = utc(duration.start_date);
    const endDate = duration.current ? utc() : utc(duration.end_date);
    const endDateReadable = duration.current ? t("time.inWords.now") : formatDate(endDate);
    const momentDuration = moment.duration(Number(endDate) - Number(startDate));

    let humanizedDuration;
    if (momentDuration.asDays() < 60) {
      humanizedDuration = t("time.inWords.days", { count: Math.round(momentDuration.asDays()) || 1 });
    } else {
      humanizedDuration = t("time.inWords.months", { count: Math.round(momentDuration.asMonths()) });
    }

    return t("time.inWords.fromToDuration.base", {
      from: formatDate(startDate),
      to: endDateReadable,
      duration: humanizedDuration
    });
  }, [t, duration, formatDate, utc]);
};
