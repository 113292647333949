import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import {
  useActivityEditFormErrors,
  UseActivityEditFormErrorsReturn,
  useActivityEditFormPostUntil,
  UseActivityEditFormPostUntilReturn,
  useActivityEditFormQuestions,
  UseActivityEditFormQuestionsReturn,
  useActivityEditFormSimpleField,
  UseActivityEditFormSimpleFieldsReturn,
  useConfirmModalsManager,
  UseConfirmModalsManagerReturn,
  useSkillsFrameworkLink,
  UseSkillsFrameworkLinkReturn,
  useSubtemplates,
  UseSubtemplatesReturn
} from "./hooks";
import { useCachedValues, UseCachedValuesReturn } from "./hooks/use_cached_values";

export type ActivityEditFormContextType = {
  submitOptions: { draft?: boolean };
  setSubmitOptions: React.Dispatch<React.SetStateAction<{ draft?: boolean }>>;
  locked: boolean;
  setLocked: React.Dispatch<React.SetStateAction<boolean>>;
} & UseActivityEditFormErrorsReturn &
  UseActivityEditFormSimpleFieldsReturn &
  UseActivityEditFormPostUntilReturn &
  UseConfirmModalsManagerReturn &
  UseSubtemplatesReturn &
  UseActivityEditFormQuestionsReturn &
  UseSkillsFrameworkLinkReturn &
  UseCachedValuesReturn;

export const ActivityEditFormContext = createContext<ActivityEditFormContextType>(
  {} as ActivityEditFormContextType
);

export const ActivityEditFormProvider = ({ children }: PropsWithChildren) => {
  const { isClone, subtemplates } = useActivityEditPageContext();
  const { isNew } = useActivityEditPageFlags();

  const formErrors = useActivityEditFormErrors();

  const simpleFieldsState = useActivityEditFormSimpleField();
  const formQuestions = useActivityEditFormQuestions();

  const postUntilFields = useActivityEditFormPostUntil(formErrors);
  const cacheStates = useCachedValues();

  const subtemplatesState = useSubtemplates({
    subtemplates,
    ...simpleFieldsState,
    ...cacheStates,
    removeErrorIfPresent: formErrors.removeErrorIfPresent,
    populateDefaultsEnabled: isNew && !isClone
  });

  const formSkillsFramework = useSkillsFrameworkLink({
    ...subtemplatesState,
    ...simpleFieldsState,
    ...cacheStates
  });

  const [submitOptions, setSubmitOptions] = useState<{ draft?: boolean }>({});
  const [locked, setLocked] = useState<boolean>(false);
  const formModals = useConfirmModalsManager();

  const context = useMemo(
    () => ({
      ...simpleFieldsState,
      ...postUntilFields,
      ...subtemplatesState,
      ...formQuestions,
      ...formSkillsFramework,
      ...cacheStates,
      submitOptions,
      setSubmitOptions,
      locked,
      setLocked,
      ...formErrors,
      ...formModals
    }),
    [
      formErrors,
      formModals,
      formQuestions,
      formSkillsFramework,
      locked,
      postUntilFields,
      simpleFieldsState,
      submitOptions,
      subtemplatesState,
      cacheStates
    ]
  );

  return <ActivityEditFormContext.Provider value={context}>{children}</ActivityEditFormContext.Provider>;
};

export const useActivityEditFormContext = () => useContext(ActivityEditFormContext);
