import { useIsMutating, useMutation } from "@tanstack/react-query";
import { addBookingNote } from "PFApp/booking/services/booking_notes_api";
import { MutationOptions } from "PFTypes";

import { noteKeys } from "./query_keys";
import { useBookingNoteInvalidate } from "./use_booking_note_invalidate";

export const useBookingNoteCreate = (bookingId: number, options?: MutationOptions<string>) => {
  const mutationKey = noteKeys.create();

  const { invalidate } = useBookingNoteInvalidate();

  const createMutation = useMutation(
    async (body: string) => {
      if (!bookingId || !body) {
        return;
      }

      return addBookingNote({ bookingId, body });
    },
    {
      mutationKey,
      ...options,
      onSuccess: (...args) => {
        invalidate(bookingId);
        options?.onSuccess?.(...args);
      }
    }
  );

  const isMutating = useIsMutating({ mutationKey });

  return {
    mutation: createMutation,
    create: createMutation.mutateAsync,
    isMutating
  };
};
