import api from "PFCore/api";
import { Id, PaginatedCollection } from "PFTypes";
import { ActivityImportRow, Import } from "PFTypes";

export type ActivitiesImportsParams = {
  search?: string;
  order?: { direction: string } | null;
  page?: number;
  perPage?: number;
};

export type ActivitiesImportsResponse = PaginatedCollection<Import[]>;

export type ActivitiesImportRowsParams = {
  page: number;
  perPage: number;
  filter?: "created" | "updated" | "invalid";
};

export type ActivitiesImportRowsMeta = {
  total: number;
  created: number;
  updated: number;
  invalid: number;
};
export type ActivitiesImportRowResponse = PaginatedCollection<ActivityImportRow[], ActivitiesImportRowsMeta>;

export const fetchActivitiesImports = (templateKey: string, params?: ActivitiesImportsParams) =>
  api<ActivitiesImportsResponse>({
    url: "activities/imports",
    params: { ...params, templateKey }
  });

export const fetchActivitiesImportRows = (id: Id, params?: ActivitiesImportRowsParams) =>
  api<ActivitiesImportRowResponse>({
    url: `activities/imports/${id}/import_rows`,
    params
  });
