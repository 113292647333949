import { ONE_DAY_MS } from "PFCore/utilities/time_consts";

export const getDaysDifference = (date1: Date, date2: Date) => {
  const utcDate1 = new Date(
    Date.UTC(
      date1.getUTCFullYear(),
      date1.getUTCMonth(),
      date1.getUTCDate(),
      date1.getUTCHours(),
      date1.getUTCMinutes(),
      date1.getUTCSeconds(),
      date1.getUTCMilliseconds()
    )
  );
  const utcDate2 = new Date(
    Date.UTC(
      date2.getUTCFullYear(),
      date2.getUTCMonth(),
      date2.getUTCDate(),
      date2.getUTCHours(),
      date2.getUTCMinutes(),
      date2.getUTCSeconds(),
      date2.getUTCMilliseconds()
    )
  );
  const timeDifference = utcDate2.getTime() - utcDate1.getTime();
  return timeDifference / ONE_DAY_MS;
};

type Clash = {
  start: Date;
  end: Date;
  itemId: number;
  key: string;
};

type Bar = {
  start: string;
  end: string;
  itemId: number;
  key: string;
};

export const getClashesFromBars = (bars: Bar[]): Clash[] => {
  const clashes: Clash[] = [];
  const sortedBars = bars.sort((b1, b2) => new Date(b2.start).getTime() - new Date(b1.start).getTime());
  sortedBars.forEach((currentBar) => {
    const overlappingBarEndDates = getOverlappingBarEndDates(currentBar, sortedBars);
    if (overlappingBarEndDates.length > 0) {
      const start = new Date(currentBar.start);
      const end = new Date(Math.min(new Date(currentBar.end).getTime(), Math.max(...overlappingBarEndDates)));
      clashes.push({
        start,
        end,
        itemId: currentBar.itemId,
        key: `${currentBar.itemId} ${clashes.length}`
      });
    }
  });
  return clashes;
};

const getOverlappingBarEndDates = (currentBar: Bar, sortedBars: Bar[]): number[] => {
  const currentBarStartDate = new Date(currentBar.start).getTime();
  const currentBarEndDate = new Date(currentBar.end).getTime();
  const overlappingBarEndDates: number[] = [];
  for (let index = sortedBars.length - 1; index >= 0; index--) {
    const bar = sortedBars[index];
    if (bar.key === currentBar.key) {
      continue;
    }
    if (currentBarEndDate < new Date(bar.start).getTime()) {
      break;
    }
    const barStart = new Date(bar.start).getTime();
    const barEnd = new Date(bar.end).getTime();
    if (barStart <= currentBarStartDate && barEnd > currentBarStartDate) {
      overlappingBarEndDates.push(barEnd);
    }
  }
  return overlappingBarEndDates;
};
