import { useBookingsSuggestedChanges } from "PFCore/hooks/queries/bookings/suggested_changes/use_bookings_suggested_changes";
import {
  OverbookingsManagementMode,
  SuggestedChangeAction
} from "PFCore/services/bookings_suggested_changes";
import { useFormContext } from "react-hook-form";

import { bookingFormValuesToSuggestedChangesData } from "../../../overbookings_management_modal/overbookings_management_modal.utils";
import { BookingFormValues } from "../../booking_form_provider";

type UseCheckAdjustCurrentMode = {
  overbookingIntersectionsDates: string[];
};

export const useCheckAdjustCurrentMode = ({ overbookingIntersectionsDates }: UseCheckAdjustCurrentMode) => {
  const { getValues } = useFormContext<BookingFormValues>();

  const bookingsData = bookingFormValuesToSuggestedChangesData(
    getValues(),
    overbookingIntersectionsDates,
    OverbookingsManagementMode.AdjustCurrent
  );

  const { data: currentSuggestedChangesData, isLoading: isCurrentSuggestedDataLoading } =
    useBookingsSuggestedChanges({
      payload: bookingsData,
      options: {
        enabled: !!bookingsData,
        cacheTime: 0
      }
    });

  const currentBookingCanBeCreated =
    (currentSuggestedChangesData ?? [])?.filter((suggestedChange) => {
      if (suggestedChange.changes.length === 0) {
        return true;
      }
      return (
        suggestedChange.changes.filter(({ action }) => action !== SuggestedChangeAction.Delete).length > 0
      );
    }).length > 0;

  return {
    isAdjustCurrentModeDisabled: isCurrentSuggestedDataLoading || !currentBookingCanBeCreated
  };
};
