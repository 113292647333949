import { useSession } from "PFApp/use_session";
import popup from "PFCore/helpers/popup";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile/use_profile_invalidate";
import { editCurrentProfile } from "PFCore/services/current_profile";
import { ApiRoute } from "PFCore/utilities/routes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../use_growl";

const useLinkedinConnect = () => {
  const { t } = useTranslation();
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const growl = useGrowl();
  const { invalidateCurrentProfile } = useProfileInvalidate();

  const { getAccessToken } = useSession();
  const [loading, setLoading] = useState(false);
  const connect = () => {
    setLoading(true);
    const url = ApiRoute(
      `/api/linkedin_connect?access_token=${getAccessToken()}&account=${currentAccount.full_domain}`
    );
    const opts = {
      path: url,
      name: t("linkedinConnect.linkedinConnect"),
      loading: {
        title: t("linkedinConnect.loadingTitle"),
        message: t("linkedinConnect.loadingMessage")
      },
      callback: () => {
        setLoading(false);
        invalidateCurrentProfile();
      }
    };

    popup(opts, () => growl({ message: t("popupFailure"), kind: "error" }));
  };

  const disconnect = () => {
    setLoading(true);

    editCurrentProfile({ linkedin_uid: null }).then(() => invalidateCurrentProfile());
  };

  const connected = currentProfile?.connected_to_linkedin;
  useEffect(() => {
    setLoading(false);
  }, [connected]);

  return { connect, disconnect, loading };
};

export default useLinkedinConnect;
