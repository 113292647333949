import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";

export const usePastDaysLimit = (): number | undefined => {
  const {
    data: { availability }
  } = useCurrentAccount();

  const { past_days_limit: pastDaysLimit } = availability || {};

  return pastDaysLimit;
};
