import { useQueryClient } from "@tanstack/react-query";

import { bulkOperationKeys } from "./query_keys";
import { SelectionType } from "./use_bookings_for_update";

export const useBookingsForUpdateInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (selectionType: SelectionType) =>
      queryClient.invalidateQueries(bulkOperationKeys.forUpdateInvalidate(selectionType))
  };
};
