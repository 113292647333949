import { Icon } from "PFCore/components/icon";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Accordion } from "../accordion/accordion";
import { Button } from "../button";
import { NoteModal } from "./modals/note_modal";
import { Note } from "./notes.types";
import { SingleNote } from "./single_note/single_note";

const defaultCanUserEditNoteStrategy = () => false;

type NotesProps = {
  notes: Note[];
  onCreateNote: (content: string) => void;
  onEditNote: (id: number, content: string) => void;
  onDeleteNote: (id: number) => void;
  canUserEditNoteStrategy?: (note: Note) => boolean;
  canUserAddNote: boolean;
  bottomLineDisabled?: boolean;
};

export const Notes = ({
  notes,
  onCreateNote,
  onEditNote,
  onDeleteNote,
  canUserEditNoteStrategy = defaultCanUserEditNoteStrategy,
  canUserAddNote,
  bottomLineDisabled
}: NotesProps): JSX.Element => {
  const { t } = useTranslation("core");
  const [isDisplayedAddNoteModal, setIsDisplayedAddNoteModal] = useState(false);
  const notesCount = notes.length;
  return (
    <>
      <div>
        <Accordion
          defaultState={"closed"}
          header={t("notes.title", { count: notesCount })}
          iconSet="chevron"
          headerSize="small"
          actions={
            canUserAddNote && (
              <Button kind="blank" onClick={() => setIsDisplayedAddNoteModal(true)}>
                <Icon name="add" size="sm" />
              </Button>
            )
          }
        >
          {notes.map((note, index) => (
            <SingleNote
              key={index}
              note={note}
              onDelete={onDeleteNote}
              onEdit={(content) => onEditNote(note.id, content)}
              isEditableNote={canUserEditNoteStrategy(note)}
              bottomLineDisabled={bottomLineDisabled && index === notesCount - 1}
            />
          ))}
        </Accordion>
      </div>
      {isDisplayedAddNoteModal && (
        <NoteModal mode="add" onClose={() => setIsDisplayedAddNoteModal(false)} onSave={onCreateNote} />
      )}
    </>
  );
};
