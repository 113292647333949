import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendMessage, SendMessagePayload, SendMessageResponse } from "PFCore/services/chat/send_message";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";

import { chatKeys } from "./query_keys";

export const useSendChatMessage = (conversationId: Id, options?: MutationOptions<SendMessagePayload>) => {
  const queryClient = useQueryClient();

  return useMutation<SendMessageResponse, unknown, SendMessagePayload>({
    mutationFn: (payload: SendMessagePayload) => sendMessage(payload),
    onSuccess: (...args) => {
      queryClient.setQueryData(chatKeys.messages(conversationId), (data: any) => {
        const firstPage = {
          entries: [...data.pages[0].entries, args[0]],
          meta: data.pages[0].meta
        };
        const newPages = [firstPage, ...data.pages.slice(1)];

        return {
          ...data,
          pages: newPages
        };
      });

      options?.onSuccess?.(...args);
    },
    ...options
  });
};
