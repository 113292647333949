import { InputFieldSet } from "PFComponents/text/input_field_set";

import ComponentPropTypes from "./component_prop_types";

const InputFieldSetWrapper = ({ onChange, maxLength, label, error, value, qaId, required }) => (
  <InputFieldSet
    required={required}
    style={{ marginBottom: 30 }}
    onChange={onChange}
    maxLength={maxLength}
    error={error}
    label={label}
    value={value}
    qaId={qaId}
  />
);

InputFieldSetWrapper.propTypes = ComponentPropTypes;

export default InputFieldSetWrapper;
