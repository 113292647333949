import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useBookingActivity } from "PFCore/hooks/queries/activity/use_booking_activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking, PermissionRule } from "PFTypes";

export const useBookingNotesPermissions = (booking?: Booking) => {
  const { data: currentProfile } = useCurrentProfile();
  const { data: activity } = useBookingActivity(booking?.activity_id!, {
    enabled: !!booking?.activity_id,
    retry: false
  });
  const { getBookingCategory } = useBookingCategories();
  const isPermittedTo = useIsCurrentUserPermittedTo();

  const isOwnBooking = booking?.profile_id === currentProfile.id;
  const isBookingCategoryReadonly = getBookingCategory(booking?.booking_category_id)?.readonly;

  const isBookingResourcer = isPermittedTo(PermissionRule.BookingResourcer);
  const hasActivityWriteAccess =
    !activity ||
    activity.coowned ||
    !!activity.participants?.find(({ profile }) => profile.id === currentProfile.id);

  return {
    canCreate: !isBookingCategoryReadonly && (isOwnBooking || (isBookingResourcer && hasActivityWriteAccess))
  };
};
