import { getValueFilters } from "PFApp/use_filtered_collection";
import { UseFiltersReturn } from "PFApp/use_filtered_collection/use_filters";
import { useDeterministicStringify } from "PFCore/helpers/use_deterministic_stringity";
import { Meta } from "PFTypes";
import { useEffect, useMemo } from "react";

import { BOOKING_CATEGORY_FILTER } from "./booking_category_filter";

type UseClearBookingCategory = {
  meta: Meta;
  onFilterChange: UseFiltersReturn["updateFilter"];
};

export const useClearBookingCategory = ({ meta, onFilterChange }: UseClearBookingCategory) => {
  const stringify = useDeterministicStringify();
  const entryFilters = useMemo(
    () =>
      getValueFilters(meta?.filters, (name, filter) => !filter?.hidden && name !== BOOKING_CATEGORY_FILTER),
    [meta?.filters]
  );

  const bookingCategoryFilter = meta?.filters?.fields?.booking_category_id;

  useEffect(() => {
    if (bookingCategoryFilter) {
      onFilterChange?.(bookingCategoryFilter, null);
    }
  }, [stringify(entryFilters)]);
};
