import { useQueryClient } from "@tanstack/react-query";

import { accountKeys } from "./query_keys";

export const useAccountInvalidate = () => {
  const cache = useQueryClient();

  return {
    invalidateCurrentAccount: () => cache.invalidateQueries({ queryKey: accountKeys.current() })
  };
};
