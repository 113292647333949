import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { Booking, BookingCategory } from "PFTypes";
import { useCallback, useMemo } from "react";

const useBookingCategories = () => {
  const { data: currentAccount } = useCurrentAccount();

  const bookingCategories = useMemo<BookingCategory[]>(
    () => currentAccount.availability?.booking_categories || [],
    [currentAccount.availability?.booking_categories]
  );

  const getBookingCategory = useCallback(
    (categoryId): BookingCategory | undefined => bookingCategories.find(({ id }) => id === categoryId),
    [bookingCategories]
  );

  return {
    bookingCategories,
    getBookingCategory,
    getCategory: useCallback(
      ({ booking_category_id }: Booking) => getBookingCategory(booking_category_id),
      [getBookingCategory]
    ),
    mainCategory: useMemo<BookingCategory | null>(
      () => bookingCategories.find(({ main }) => main) || null,
      [bookingCategories]
    )
  };
};

export default useBookingCategories;
