import { useTranslation, UseTranslationOptions } from "react-i18next";

import { I18nNamespace } from "../i18n/i18n";
import {
  ErrorFormatOptions,
  getFirstError,
  getFormattedErrors,
  isUnauthorized
} from "./response_errors_utils";

type UseResponseErrors = {
  translationNs?: I18nNamespace;
  translationOptions?: UseTranslationOptions<any>;
};

type BoundErrorUtils = {
  getFormattedErrors: (errors: unknown, options?: ErrorFormatOptions) => Record<string, string>;
  getFirstError: (errors: unknown, options?: ErrorFormatOptions, key?: string) => string;
  isErrorUnauthorized: (errors: unknown, options?: ErrorFormatOptions) => boolean;
};

export const useResponseErrors = ({
  translationNs = "translation",
  translationOptions = {}
}: UseResponseErrors = {}): BoundErrorUtils => {
  const { t } = useTranslation(translationNs, translationOptions);

  return {
    getFormattedErrors: getFormattedErrors.bind(null, t),
    getFirstError: getFirstError.bind(null, t),
    isErrorUnauthorized: isUnauthorized.bind(null, t)
  };
};
