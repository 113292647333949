import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { fetchActivityWarnings } from "PFApp/booking/services/api";
import { ActivityMisalignments } from "PFApp/booking/types";
import { useMemo } from "react";

import activityKeys from "./query_keys";

type Warning = {
  misalignments: ActivityMisalignments;
};
export type ActivityWarningsIndex = Partial<Warning>;
type ActivityIdWithRowKey = { rowKey: string; id: number };

const groupLoadingByRowKey = (
  queries: UseQueryResult<ActivityWarningsIndex>[],
  activityRows: ActivityIdWithRowKey[]
): Record<string, boolean> =>
  queries.reduce((result, { isLoading, isFetching }, index) => {
    const { rowKey } = activityRows[index];
    return {
      ...result,
      [rowKey]: isLoading || isFetching
    };
  }, {});

export const useActivityWarnings = (activityRows: ActivityIdWithRowKey[]) => {
  const stringifiedActivityRows = JSON.stringify(activityRows);
  const queriesConfig = useMemo(
    () =>
      activityRows.map(({ id }) => ({
        queryKey: activityKeys.warnings(id),

        queryFn: async (): Promise<ActivityWarningsIndex> => ({
          [id]: await fetchActivityWarnings(id)
        })
      })),
    [stringifiedActivityRows]
  );
  const queries = useQueries({
    queries: queriesConfig
  });

  const isLoading = useMemo(
    () => !!queries.find(({ isLoading, isFetching }) => isLoading || isFetching),
    [queries]
  );

  const activityWarningsLoadingByRowKey = useMemo<Record<string, boolean>>(
    () => groupLoadingByRowKey(queries, activityRows),
    [isLoading, stringifiedActivityRows]
  );

  const warningsByActivity = useMemo<ActivityWarningsIndex>(
    () => Object.assign({}, ...queries.map(({ data }) => data)),
    [isLoading, stringifiedActivityRows]
  );

  return {
    warningsByActivity,
    activityWarningsLoadingByRowKey
  };
};
