import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import i18n from "PFCore/i18n";
import { useEffect } from "react";

export const useChangeLanguage = () => {
  const { data: currentProfile, isSignedIn } = useCurrentProfile();
  const { data: currentAccount } = useCurrentAccount();

  useEffect(() => {
    let displayLocale: string | undefined = currentAccount.customization?.locale;
    if (isSignedIn) {
      displayLocale = currentProfile.locale;
    }
    if (displayLocale) {
      i18n.changeLanguage(displayLocale);
    }
  }, [currentAccount.id, isSignedIn, currentProfile.locale]);
};
