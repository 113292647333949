import classNames from "classnames";
import { Typography } from "PFComponents/typography";

import css from "./profile_preview_header.module.scss";

type ProfilePreviewHeaderProps = React.PropsWithChildren<{
  actions?: React.ReactNode;
  headerClassName?: string;
}>;

export const ProfilePreviewHeader = ({
  headerClassName,
  actions,
  children
}: ProfilePreviewHeaderProps): JSX.Element => (
  <div className={css.root}>
    <Typography variant="h5" className={classNames(css.title, headerClassName)}>
      {children}
    </Typography>
    <span>{actions}</span>
  </div>
);
