import { Button } from "PFComponents/button";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AddOwnersModal from "../parts/add_owners_modal";

const AddCoOwner = ({ buttonKind, buttonStyle, activity }) => {
  const [showAddOwners, setShowAddOwners] = useState(false);
  const { t } = useTranslation("activities");

  return (
    <>
      <Button
        text={t("show.parts.addCoOwner")}
        kind={buttonKind || "secondary"}
        style={buttonStyle}
        qaId="activity-add-owner"
        onClick={() => setShowAddOwners(true)}
      />
      {showAddOwners && <AddOwnersModal handleClose={() => setShowAddOwners(false)} activity={activity} />}
    </>
  );
};

AddCoOwner.propTypes = {
  buttonKind: PropTypes.string,
  buttonStyle: PropTypes.object,
  activity: PropTypes.object
};

export default AddCoOwner;
