import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { useTemplate } from "PFApp/hooks";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { activityPermissions } from "PFCore/helpers/templates";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";

export const useShouldDisplayEngagementSelect = (isGroupBooking: boolean) => {
  const { data: currentProfile } = useCurrentProfile();
  const { isEnabled: isBMEnabled, isBookingResourcer } = useBookingModulePermissions();
  const { activity } = useBookingActivityContext();
  const engagementTemplate = useTemplate("engagement");
  const activityTemplate = useTemplate(activity?.template_id);

  const isActivityEngagement = activityTemplate?.key === "engagement";
  const isLinkingToEngagementEnabled = engagementTemplate && engagementTemplate.bookings_allowed;
  const engagementMatchesPermissions = ["ro", "rw"].includes(
    activityPermissions(currentProfile?.permissions_group, currentProfile?.templates, "engagement") ?? ""
  );

  return (
    isBMEnabled &&
    isBookingResourcer &&
    !!isLinkingToEngagementEnabled &&
    (!activity || isActivityEngagement) &&
    engagementMatchesPermissions &&
    !isGroupBooking
  );
};
