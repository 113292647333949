import Tooltip from "PFCore/components/tooltip/tooltip";
import { Typography } from "PFCore/components/typography";

import css from "./side_info.module.scss";

type SideInfoProps = {
  label: string;
  value: string;
  tooltipContent?: string;
};

export const SideInfo = ({ label, value, tooltipContent }: SideInfoProps) => (
  <aside className={css.aside}>
    <Typography variant="labelRegular" noMargin clipOverflow>
      {label}
    </Typography>
    <Tooltip content={tooltipContent}>
      <div className={css.fitContent}>
        <Typography variant="bodyBold" noMargin clipOverflow>
          {value}
        </Typography>
      </div>
    </Tooltip>
  </aside>
);
