import NotFoundComponent from "PFComponents/not_found/not_found";
import usePageWrapper from "PFCore/helpers/use_page_wrapper";
import { useTranslation } from "react-i18next";

import { ProfilesPage } from "./profiles_page";
import { ProfilesPageContextProvider, useProfilesPageContext } from "./profiles_page_context";

const ProfilesPageController = () => {
  const { t } = useTranslation(["translation", "profiles"]);
  const { error } = useProfilesPageContext();
  const WrappedProfilesPage = usePageWrapper(ProfilesPage);

  return (
    <WrappedProfilesPage
      errors={error ? [error] : []}
      errorComponent={
        <NotFoundComponent
          message={t("profiles:list.notFoundMessage")}
          buttonMessage={t("translation:comeBackLater")}
          buttonUrl="/profiles"
        />
      }
    />
  );
};

export const ProfilesPageControllerWrapper = () => (
  <ProfilesPageContextProvider>
    <ProfilesPageController />
  </ProfilesPageContextProvider>
);
