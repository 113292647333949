import classNames from "classnames";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import css from "./button.module.scss";

const ButtonLink = ({
  children,
  kind,
  size,
  small,
  style,
  qaId,
  href,
  target,
  rootClassName,
  onClick,
  theme,
  title,
  isExternalLink
}) => {
  const className = classNames(css.button, rootClassName, css[`button-${kind}`], theme.root, {
    [css[`button-${size}`]]: size,
    [css.buttonSmall]: small
  });
  return (
    <Link
      to={
        isExternalLink
          ? {
              pathname: href
            }
          : href
      }
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}
      className={className}
      style={style}
      data-qa-id={qaId}
      onClick={onClick}
      title={title || (isString(children) && children)}
    >
      {children}
    </Link>
  );
};

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  target: PropTypes.oneOf([null, "_blank"]),
  kind: PropTypes.oneOf([
    "primary",
    "secondary",
    "secondary",
    "autosuggest",

    "landing-blue", // special only used in landing
    "blank" // minimal styles, will depend on the parent for styling
  ]),
  size: PropTypes.oneOf([null, "lg", "sm", "xs"]),
  small: PropTypes.bool,
  style: PropTypes.object,
  qaId: PropTypes.string,
  rootClassName: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.shape({
    root: PropTypes.string,
    inner: PropTypes.string
  }),
  title: PropTypes.string,
  isExternalLink: PropTypes.bool
};

ButtonLink.defaultProps = {
  href: null,
  target: null,
  tag: "a",
  kind: "primary",
  size: null,
  small: false,
  style: null,
  qaId: "Button",
  rootClassName: null,
  theme: {}
};

export default ButtonLink;
