import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useCallback } from "react";

type UseStringValueParserReturn = {
  formatStringValue: (value: string, columnName: string) => string;
};

export const useStringValueParser = (): UseStringValueParserReturn => {
  const { formatDate } = useDateFormatter();
  const { customTypes } = useCustomTypes();

  const formatStringValue = useCallback(
    (value: string, columnName: string) => {
      const customType = customTypes.find(({ name }) => name === columnName);
      if (customType?.value_type === "date") {
        return formatDate(value);
      }
      return value;
    },
    [customTypes]
  );

  return {
    formatStringValue
  };
};
