import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";

export type ForgotPasswordPayload = {
  accountId: number;
  email: string;
};

type ForgotPasswordResponse = {
  message: string;
};

export const forgotPassword = ({ accountId, email }: ForgotPasswordPayload): jqXHR<ForgotPasswordResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute("/api/auth/forgot_password"),
    method: "POST",
    data: {
      user: {
        account_id: accountId,
        email
      }
    }
  });
