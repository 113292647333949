import { ApiRoute } from "PFCore/utilities/routes";

export type SendMessagePayload = {
  attachmentIds: number[];
  plain: string;
  conversationId: number;
  profileId: number;
  timeAgo: string;
  localTimestamp: number;
};

export type SendMessageResponse = {
  message: string;
};

export const sendMessage = ({
  attachmentIds,
  plain,
  conversationId,
  profileId,
  timeAgo,
  localTimestamp
}: SendMessagePayload): Promise<SendMessageResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute(`/api/chat/messages`),
    api_version: 2,
    method: "POST",
    processData: false,
    contentType: "application/json",
    data: JSON.stringify({
      attachment_ids: attachmentIds,
      plain,
      conversation_id: conversationId,
      profile_id: profileId,
      time_ago: timeAgo,
      local_timestamp: localTimestamp
    })
  });
