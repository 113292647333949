import { Divider } from "PFComponents/divider";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import { Typography } from "PFComponents/typography";
import { useDeterministicStringify } from "PFCore/helpers/use_deterministic_stringity";
import { useBookingsSuggestedChanges } from "PFCore/hooks/queries/bookings/suggested_changes/use_bookings_suggested_changes";
import {
  BookingChange,
  OverbookingsManagementMode,
  SuggestedChangesPayload
} from "PFCore/services/bookings_suggested_changes";
import { BookingCategory } from "PFTypes";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { NoPermissionInfo } from "./no_permission_info/no_permission_info";
import css from "./overbookings_management_modal.module.scss";
import { SuggestedChanges } from "./suggested_changes";

export type UpdatedBookingChange = {
  bookingChangeIndex: number;
  shouldBeRemoved: boolean;
} & BookingChange;

type OverbookingsManagementModalProps = {
  onClose: () => void;
  onConfirm: (bookingChanges: UpdatedBookingChange[]) => Promise<void>;
  bookingsData: SuggestedChangesPayload | null;
  category: BookingCategory | undefined;
};

export const OverbookingsManagementModal = ({
  onConfirm,
  onClose,
  bookingsData,
  category
}: OverbookingsManagementModalProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const {
    data: suggestedChangesData,
    isLoading,
    isError
  } = useBookingsSuggestedChanges({
    payload: bookingsData
  });
  const stringify = useDeterministicStringify();

  const [suggestedChanges, setSuggestedChanges] = useState<UpdatedBookingChange[]>([]);

  useEffect(() => {
    if (suggestedChangesData) {
      setSuggestedChanges(
        suggestedChangesData.map((suggestedChange, index) => ({
          ...suggestedChange,
          shouldBeRemoved: false,
          bookingChangeIndex: index
        }))
      );
    }
  }, [suggestedChangesData]);

  const handleToggleRemoveBooking = (bookingChangeIndex: number) => {
    const updatedSuggestedChanges = suggestedChanges.map((suggestedChange) => {
      if (suggestedChange.bookingChangeIndex === bookingChangeIndex) {
        return {
          ...suggestedChange,
          shouldBeRemoved: !suggestedChange.shouldBeRemoved
        };
      }
      return suggestedChange;
    });
    setSuggestedChanges(updatedSuggestedChanges);
  };

  const handleOk = !isLoading && !isError ? () => onConfirm(suggestedChanges) : undefined;

  const content = useMemo(() => {
    if (!category || !bookingsData) {
      return null;
    }

    if (isLoading) {
      return <LoadingDots />;
    }

    if (isError) {
      return <NoPermissionInfo category={category} currentBookings={bookingsData.bookings} />;
    }
    const modalInformation =
      bookingsData.mode === OverbookingsManagementMode.AdjustCurrent
        ? t("bookingModule:bookings.createWithOverbooking.modal.adjustCurrentInformation")
        : t("bookingModule:bookings.createWithOverbooking.modal.adjustOtherInformation");

    const changedBookings =
      suggestedChanges?.filter((bookingChange) => bookingChange.changes.length > 0) ?? [];

    return (
      <div className={css.root}>
        <Typography variant="bodyRegular" tag="span">
          {modalInformation}
        </Typography>
        <div>
          {changedBookings.map((bookingChange, index) => (
            <>
              <SuggestedChanges
                key={stringify(bookingChange)}
                mode={bookingsData.mode}
                category={category}
                bookingChange={bookingChange}
                onToggleRemoveBooking={handleToggleRemoveBooking}
              />
              {index !== changedBookings.length - 1 && <Divider className={css.divider} />}
            </>
          ))}
        </div>
      </div>
    );
  }, [isLoading, isError, suggestedChanges, bookingsData]);

  return (
    <Modal
      title={t("bookingModule:bookings.createWithOverbooking.modal.title")}
      labelOK={t("translation:confirm")}
      onOK={handleOk}
      onClose={onClose}
    >
      {content}
    </Modal>
  );
};
