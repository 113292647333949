import classNames from "classnames";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { fetchVacancies } from "PFCore/services/vacancies";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import css from "./activity_details_common.scss";

const ActivityDetailsVacancies = ({ task, vacancies }) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.details.vacancies" });
  const [vacanciesMeta, setVacanciesMeta] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = task;

  useEffect(() => {
    if (id) {
      if (vacancies) {
        setVacanciesMeta(vacancies.meta);
      } else {
        setIsLoading(true);

        fetchVacancies({ activityId: task.id, perPage: 0 }).then((resp) => {
          setVacanciesMeta(resp.meta);
          setIsLoading(false);
        });
      }
    }
  }, [id, vacancies]);

  return (
    <div className={classNames(css.infoItem, css.itemContainer)}>
      <Typography variant="h5" noMargin>
        {t("title")}
      </Typography>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <div>
          <div className={css.vacanciesSection}>
            <Typography variant="bodyRegular" tag="span">
              <Trans
                t={t}
                i18nKey={"open"}
                values={{ count: vacanciesMeta.totalOpened }}
                components={[<Typography key={0} variant="bodyBold" tag="span" />]}
              />
            </Typography>
          </div>
          <div className={css.vacanciesSection}>
            <Typography variant="bodyRegular" tag="span">
              <Trans
                t={t}
                i18nKey={"total"}
                values={{ count: vacanciesMeta.total }}
                components={[<Typography key={0} variant="bodyBold" tag="span" />]}
              />
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

ActivityDetailsVacancies.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number
  }),
  vacancies: PropTypes.shape({
    meta: PropTypes.shape({
      total: PropTypes.number.isRequired,
      total_opened: PropTypes.number
    })
  })
};

export default ActivityDetailsVacancies;
