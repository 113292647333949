import classNames from "classnames";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { NavItem as NavItemType } from "../../types";
import { NavDropdown } from "./nav_dropdown";
import css from "./nav_item.module.scss";

export type NavItemProps = NavItemType & {
  counter?: number;
  counterHref?: string;
  icon?: ReactElement;
  openKey?: string | null;
  forceOpen?: boolean;
  isActiveTest?: Function;
  revealed?: boolean;
  hoverEffect?: boolean;
  openSubMenu: (id: NavItemType["id"]) => void;
  closeSubMenu: (id?: NavItemType["id"]) => void;
  onClick?: VoidFunction;
} & React.AriaAttributes;

export type CommonNavItemProps = Pick<NavItemProps, "revealed" | "openKey" | "openSubMenu" | "closeSubMenu">;

export const NavItem = ({
  icon,
  items,
  name,
  counter,
  href,
  id,
  openKey,
  forceOpen,
  revealed,
  isActiveTest,
  hoverEffect = true,
  "aria-label": ariaLabel,
  closeSubMenu,
  openSubMenu,
  onClick
}: NavItemProps) => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const linkRef = useRef<any>(null);

  const handleClick = (e) => {
    const isMiddleClick = e.button === 1 || e.button === 4;

    if (isMiddleClick) {
      return; // just follow href, otherwise ignore
    }

    if (items || onClick) {
      e.preventDefault(); // avoid navigation with href (because dropdown/function)
    }

    if (items) {
      openKey === id ? closeSubMenu() : openSubMenu(id);
    } else {
      // no submenu nav items stills close submenus of other items
      closeSubMenu();
    }

    onClick?.();
  };

  const handleSubMenuClose: NavItemProps["closeSubMenu"] = (...args) => {
    linkRef.current?.focus();
    closeSubMenu(...args);
  };

  useEffect(() => {
    const onHashChange = () => isActiveTest && setActive(!!isActiveTest(location.pathname));
    isActiveTest && window.addEventListener("hashchange", onHashChange);
    onHashChange();
    return () => isActiveTest && window.removeEventListener("hashchange", onHashChange);
  }, [location.pathname]);

  const isOpen = openKey === id || forceOpen;
  const showsDropdown = items && isOpen;
  const showCounter = counter ? counter > 0 : false;
  const itemContent = (
    <div className={css.itemContent}>
      <div className={css.icon}>{icon}</div>
      {showCounter && <div className={css.counter}>{counter}</div>}
    </div>
  );

  const displayId = `${id}_nav_link`;
  const hasPopup = items && items.length > 0;
  const listId = `${displayId}_menuList`;

  const visualItems = (
    <>
      <svg className={css.blobTail} viewBox="0 0 100 90" width="100" height="90">
        <path d="M0,0V90H50C50,68 60,60 80,60 100,60 100,30 80,30 60,30 50,22 50,0Z" />
      </svg>
      <span className={css.blob} />

      <span className={css.openBlob} />
      <svg className={css.openBlobTail} viewBox="0 0 51 90" width="51" height="90">
        <path d="M50,90C50,70 45,60 20,60 0,60 0,30 20,30 45,30 50,20 50,0 h1 v90z" />
      </svg>
    </>
  );

  return (
    <li
      data-qa-id={`NavItem.${id}`}
      id={id !== undefined ? String(id) : undefined}
      className={classNames(css.item, {
        [css.isOpen]: isOpen,
        [css.isActive]: active,
        [css.isRevealed]: revealed
      })}
    >
      {!hasPopup && href ? (
        <Link
          to={href}
          className={classNames(css.link, { [css.withHoverEffect]: hoverEffect })}
          onClick={handleClick}
          id={displayId}
          aria-label={ariaLabel || name}
          aria-current={active && "page"}
          role="link"
        >
          {visualItems}
          {itemContent}
        </Link>
      ) : (
        <button
          ref={linkRef}
          className={classNames(css.link, { [css.withHoverEffect]: hoverEffect })}
          onClick={handleClick}
          id={displayId}
          aria-expanded={!onClick ? showsDropdown : undefined}
          aria-haspopup={!onClick ? hasPopup : undefined}
          aria-label={ariaLabel || name}
          aria-controls={!onClick ? listId : undefined}
          aria-current={active && "page"}
        >
          {visualItems}
          {itemContent}
        </button>
      )}

      <div className={css.linkName}>{name}</div>

      {hasPopup && (
        <NavDropdown
          id={listId}
          items={items}
          closeSubMenu={handleSubMenuClose}
          shown={showsDropdown}
          labelledBy={displayId}
        />
      )}
    </li>
  );
};
