import { EconomicsScenario, Id } from "PFTypes";

import api from "../../api";

export const updateActivityEconomicsScenario = (scenarioId: Id, name: string) =>
  api<EconomicsScenario>({
    url: `scenarios/${scenarioId}`,
    method: "PUT",
    data: {
      name
    }
  });
