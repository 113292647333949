import { EmptyState } from "PFComponents/empty_state";
import NoProfilesIcon from "PFIcons/no_profiles.svg";
import { Activity } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import { useTemplate } from "../../../hooks";
import css from "./matches_empty.module.scss";

type MatchesActivityClosedProps = { activity: Activity };

export const MatchesActivityClosed = ({ activity }: MatchesActivityClosedProps) => {
  const { t } = useTranslation("activities");
  const { name } = activity;

  const template = useTemplate(activity.template_id);
  const templateName = template?.name;

  return (
    <EmptyState
      illustration={<NoProfilesIcon className={css.noProfilesIcon} />}
      message={t("show.matches.activityClosed", { name, templateName })}
    />
  );
};
