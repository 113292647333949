const importExternalScript = (url) =>
  new Promise((resolve, reject) => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });

export default importExternalScript;
