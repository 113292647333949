import { SkillsFramework, SkillsFrameworkCustomType, SkillsFrameworkCustomValue } from "PFTypes";

export enum SkillsFrameworkKind {
  Secondary = "secondary",
  Faded = "faded",
  Selected = "selected"
}

export type SkillsFrameworkCustomValueDecorated = SkillsFrameworkCustomValue & {
  kind?: SkillsFrameworkKind;
  primary?: boolean;
  selected?: boolean;
};

export type SkillsFrameworkCustomField = {
  conflict?: boolean;
  type: SkillsFrameworkCustomType;
  values: SkillsFrameworkCustomValueDecorated[];
};

export type SkillsFrameworkDecorated = SkillsFramework<SkillsFrameworkCustomValueDecorated> & {
  kind?: SkillsFrameworkKind;
};
