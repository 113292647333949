import { useMemo } from "react";

import { OverbookedBooking, OverbookingsManagementApi } from "./overbookings_management_context";
import { useOverbookingsManagementReducer } from "./use_overbookings_management_reducer";

export const useOverbookingsManagement = () => {
  const [state, dispatch] = useOverbookingsManagementReducer();

  const apiValues = useMemo<OverbookingsManagementApi>(() => {
    const setOverbookedBookings = (
      profileId: number | undefined,
      activityId: number | undefined,
      overbookedBookings: OverbookedBooking[]
    ) => {
      dispatch({ type: "setOverbookedBookings", overbookedBookings, profileId, activityId });
    };

    const clearOverbookedBookings = () => {
      dispatch({ type: "clearOverbookedBookings" });
    };

    return {
      setOverbookedBookings,
      clearOverbookedBookings
    };
  }, []);

  const stateValue = useMemo(() => state, [state]);

  return {
    overbookingsManagementState: stateValue,
    overbookingsManagementApi: apiValues
  };
};
