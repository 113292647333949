import { upperFirst } from "lodash";
import { GId, GIdTargetId, GIdTargetType } from "PFTypes";

export const gidFor = (targetType: GIdTargetType, targetId: GIdTargetId): GId =>
  `gid://profinda-api/${targetType}/${targetId}`;

export const gidToType = function (gid: GId): GIdTargetType {
  const arrayGId = gid.replace("gid://profinda-api/", "").split("/");
  return upperFirst(arrayGId[0]) as GIdTargetType;
};

export const gidToId = function (gid: GId): GIdTargetId {
  const arrayGId = gid.replace("gid://profinda-api/", "").split("/");
  return arrayGId[1];
};

export const gidToPath = function (gid: GId, prefix?: string): string {
  if (!prefix) {
    prefix = "/";
  }
  const targetType = gidToType(gid);
  const targetId = gidToId(gid);

  if (targetType === GIdTargetType.Task) {
    return `${prefix}activities/${targetId}`;
  } else if (targetType === GIdTargetType.Profile) {
    return `${prefix}profiles/${targetId}`;
  } else if (targetType === GIdTargetType.ChangeRequest) {
    return `${prefix}change_requests/${targetId}`;
  } else {
    return "";
  }
};
