import { Icon } from "PFComponents/icon";

import { DetailsHeader } from "../details_header";

type MisalignmentsHeaderProps = {
  roleName: string;
};

export const MisalignmentsHeader = ({ roleName }: MisalignmentsHeaderProps) => (
  <DetailsHeader title={roleName} subtitle={"Misalignments"}>
    <Icon name="role" />
  </DetailsHeader>
);
