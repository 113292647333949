import { Card } from "PFComponents/card";
import AutoSelect from "PFComponents/select/autoselect";
import Toggle from "PFComponents/toggle/toggle";
import { Typography } from "PFComponents/typography";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { fetchAutocompleteCustomValues } from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import { ChangeRequest } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../change_request_page.module.scss";
import { DisabledItems, MergeData, SetDisabledItems } from "../change_request_page.types";
import { Input } from "./input";

type AboutSectionProps = {
  data: ChangeRequest["data"];
  mergeData: MergeData;
  disabledItems: DisabledItems;
  setDisabledItems: SetDisabledItems;
  errors: any;
};

export const AboutSection = ({
  data,
  mergeData,
  disabledItems,
  setDisabledItems,
  errors
}: AboutSectionProps) => {
  const { customTypes } = useCustomTypes();
  const { t } = useTranslation("changeRequests");

  if (
    !data ||
    (typeof data.first_name !== "string" &&
      typeof data.last_name !== "string" &&
      typeof data.summary !== "string" &&
      !data.custom_fields?.length)
  ) {
    return null;
  }

  const commonProps = {
    data,
    mergeData,
    disabledItems,
    setDisabledItems
  };

  return (
    <Card qaId="ChangeRequest.About">
      <Typography variant="h2" className={css.header}>
        {t("parts.about")}
      </Typography>
      <div className={css.form}>
        <Input
          {...commonProps}
          prop="first_name"
          label={t("parts.inputs.firstName")}
          error={errors.first_name}
        />
        <Input
          {...commonProps}
          prop="last_name"
          label={t("parts.inputs.lastName")}
          error={errors.last_name}
        />
        <Input
          {...commonProps}
          prop="summary"
          label={t("parts.inputs.shortBio")}
          inputType="textarea"
          error={errors.summary}
        />

        {data?.custom_fields?.map((customField) => {
          const type = customTypes.find(({ name }) => name === customField.type.name);

          if (!type) {
            return null;
          }

          return (
            <div key={type.name} className={css.inputWrapper} data-qa-id={`ChangeRequest.About.${type.name}`}>
              <AutoSelect
                cache={false}
                key={type.name}
                label={type.display_as}
                disabled={disabledItems.customFields[type.name]}
                tip={type.description}
                rootClassName={css.input}
                values={customField.values.map((it) => ({ ...it, text: it.value }))}
                multi={type.kind === "multiple"}
                error={errors?.["custom_fields"]?.find((item) => item[type.name])?.[type.name]}
                closeOnChange={type.kind !== "multiple"}
                query={(term) =>
                  fetchAutocompleteCustomValues({
                    term,
                    type: customField.type.name
                  })
                }
                handleChange={(items) => {
                  customField.values = items.map((it) => ({ ...it, value: it.text }));
                }}
              />
              <div>
                <Toggle
                  checked={!disabledItems.customFields[type.name]}
                  onChange={(val) => {
                    setDisabledItems((prev) => ({
                      ...prev,
                      customFields: { ...prev.customFields, [type.name]: !val }
                    }));
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};
