import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useProfiles } from "PFCore/hooks/queries/profiles/use_profiles";
import React from "react";

import { DetailsPanelComponentProps, DetailsSidePanelProps } from "../../details_panel";
import { MisalignmentsDetail } from "./misalignments_detail/misalignments_detail";
import { MisalignmentsHeader } from "./misalignments_header";

type MisalignmentsDetailSidePanelProps = DetailsSidePanelProps & DetailsPanelComponentProps;

export const MisalignmentsDetailSidePanel = React.memo(
  ({ isOnTop, show, data, onClose, zIndex, goToDate }: MisalignmentsDetailSidePanelProps) => {
    const profileIds = (data?.misalignmentDetails?.misalignments ?? []).map(({ profileId }) => profileId);

    const { data: profilesData, isLoading: isProfilesLoading } = useProfiles({
      filters: {
        fields: { id: profileIds }
      },
      perPage: PER_PAGE_UNPAGINATED
    });

    return (
      <SidePanel
        show={show}
        zIndex={zIndex}
        isSidePanelClosing={data.isClosing}
        onClose={onClose}
        title={<MisalignmentsHeader roleName={data.misalignmentDetails?.roleName ?? ""} />}
        isOnTop={isOnTop}
      >
        <MisalignmentsDetail
          profileId={data.misalignmentDetails?.profileId}
          profiles={profilesData?.entries.map(({ profile }) => profile) ?? []}
          areProfilesLoading={isProfilesLoading}
          misalignments={data.misalignmentDetails?.misalignments ?? []}
          goToDate={data.misalignmentDetails?.enableGoToDate ? goToDate : undefined}
        />
      </SidePanel>
    );
  }
);

MisalignmentsDetailSidePanel.displayName = "MisalignmentsDetailSidePanel";
