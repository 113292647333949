import { Calendar } from "../calendar";

export enum AvailabilityMode {
  TimeRule = "time_rule",
  Within = "within",
  PerWeek = "per_week",
  PerMonth = "per_month"
}

export type AvailabilityDuration = {
  mode: "days" | "minutes";
  value: number;
};

type BaseRange = {
  start: string | null;
  end: string | null;
  phase_source_id?: string;
};

export type TimeRuleRange = BaseRange & {
  day_of_week: number[];
};

export type StandardRange = BaseRange & {
  duration: AvailabilityDuration;
};

export type AvailabilityRange = TimeRuleRange | StandardRange;

export type AvailabilityRule = {
  mode: AvailabilityMode;
  availability_threshold: number;
  ranges: AvailabilityRange[];
};

export interface ProfileAvailability {
  calendars?: { [key: string]: Calendar };
}

export type Availability = AvailabilityRule | ProfileAvailability;

export type ProfileDayAvailability = {
  id: number;
  date: string;
  availableMinutes: number;
  nominalWorkingMinutes: number;
  billableUtilization: number;
  utilization: number;
};

export type DateRange = {
  min: string;
  max: string;
};
