import { ProfileCard } from "PFApp/components/profile_card";
import SortBy from "PFApp/components/sort_by";
import { Card } from "PFComponents/card";
import { LoadingDots } from "PFComponents/loading_dots";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { Typography } from "PFComponents/typography";
import { useInfiniteScroll } from "PFCore/hooks/use_infinite_scroll";
import NoProfilesIllustration from "PFTheme/graphics/illustrations/illustration-no-profiles.svg";
import { useTranslation } from "react-i18next";

import { useProfilesPageContext } from "../../profiles_page_context";
import css from "./profiles_list.module.scss";

export const ProfilesList = () => {
  const { t } = useTranslation(["translation", "profiles"]);
  const {
    profiles,
    isFetching,
    resetFilters,
    meta,
    hasNextPage,
    isFetchingNextPage,
    setOrder,
    fetchNextPage,
    areFiltersClear
  } = useProfilesPageContext();

  const lastRoleRef = useInfiniteScroll<HTMLDivElement>({
    fetchMore: fetchNextPage,
    loading: isFetching,
    hasMore: hasNextPage
  });

  const isAnyProfile = profiles.length > 0;
  const showEmptyState = !isFetching && !isAnyProfile;

  return (
    <Card className={css.card} paddingVariant="xl">
      {meta.order && (
        <span className={css.sortByBlock}>
          <SortBy
            sort={meta.order.value?.text}
            options={meta.order.options.map((option) => ({
              id: option.text,
              displayElement: option.text,
              item: option
            }))}
            onSortChange={setOrder}
            noLabel
          />
          {isAnyProfile && (
            <Typography variant="labelRegular" className={css.countMessage}>
              {t("profiles:list.profilesCount", {
                count: profiles.length,
                total: meta.counters?.filteredProfilesCount
              })}
            </Typography>
          )}
        </span>
      )}
      {isFetching && !isFetchingNextPage && (
        <LoadingDots circleSize="xs" circlesEnabled className={css.loading} />
      )}
      {profiles.map((profile, index) => (
        <div key={profile.id} ref={index === profiles.length - 1 ? lastRoleRef : undefined}>
          <ProfileCard profile={profile.profile} highlights={profile.optionalFields?.highlights} />
        </div>
      ))}
      {isFetchingNextPage && <LoadingDots circleSize="xs" circlesEnabled className={css.loading} />}
      {showEmptyState && (
        <NotFoundComponent
          message={t("profiles:list.noResultsMessage")}
          buttonMessage={t("profiles:list.resetFilters")}
          handleClick={areFiltersClear ? undefined : resetFilters}
        >
          <NoProfilesIllustration />
          <Typography variant="h2">{t("profiles:list.noProfilesMatch")}</Typography>
        </NotFoundComponent>
      )}
    </Card>
  );
};
