import { Id } from "PFTypes/id";

export const certificateKeys = {
  all: () => ["certificates"],
  list: (profileId: Id, page: number, perPage: number, customValueIds: number[]) => [
    ...certificateKeys.all(),
    profileId,
    page,
    perPage,
    customValueIds
  ],
  single: (id: Id) => ["certificate", id.toString()]
};
