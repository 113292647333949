import { FetchSkillsFrameworksOptionsParams } from "PFCore/services/skills_frameworks/fetch_skills_frameworks_options";
import { Id } from "PFTypes/id";

import { FetchProfileSkillsFrameworksParams } from "../../../services/skills_frameworks/fetch_profile_skills_frameworks";

export const skillsFrameworksKeys = {
  all: () => ["skillsFrameworks"],
  options: (params: FetchSkillsFrameworksOptionsParams = {}) => [
    ...skillsFrameworksKeys.all(),
    "options",
    params
  ],
  profileSkillsFrameworks: (profileId: Id, params: FetchProfileSkillsFrameworksParams = {}) => [
    ...skillsFrameworksKeys.all(),
    "profileSkillsFrameworks",
    profileId,
    params
  ]
};
