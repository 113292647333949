import { LeftArrow, RightArrow } from "PFComponents/carousel/carousel_actions";
import { useCertificates } from "PFCore/hooks/queries/certificates";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import CalendarChecked from "PFIcons/calendar_checked.svg";
import CalendarDelete from "PFIcons/calendar_delete.svg";
import type { Certificate } from "PFTypes/certificate";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Carousel } from "../../carousel/carousel";
import css from "./certificates.module.scss";
import CertificatesIcon from "./certificates_icon";

const CertificateInfo = ({ item, count }: { item: Certificate; count: number }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "common.certificates" });
  const { formatDate } = useDateFormatter();

  return (
    <div className={css.certificateItem}>
      <div className={css.title}>
        {item.title}
        <CertificatesIcon count={count} />
      </div>
      <div className={css.issuer}>
        <span>{t("issuer")}:</span>
        <span className={css.value}>{item.issuer}</span>
      </div>
      <div className={css.dates}>
        <div className={css.date}>
          <CalendarChecked height={20} width={20} />
          {t("issued")}:<strong>{formatDate(item.issueDate)}</strong>
        </div>
        {item.expiryDate && (
          <div className={css.date}>
            <CalendarDelete height={20} width={20} />
            {t("expires")}:<strong>{formatDate(item.expiryDate)}</strong>
          </div>
        )}
      </div>
    </div>
  );
};

const Certificates = ({ profileId, customValueIds }: { profileId: number; customValueIds: number[] }) => {
  const [currentCertificateIndex, setCurrentCertificateIndex] = useState(0);

  const certificatesQuery = useCertificates(profileId, 1, 15, customValueIds);
  const certificates = certificatesQuery.data?.entries;

  if (certificatesQuery.isLoading || certificates.isError || certificates?.length === 0) {
    return null;
  }

  const multipeCertificates = certificates.length > 1;

  return (
    <div className={css.container}>
      {multipeCertificates && (
        <LeftArrow
          classes={{ root: css.arrow }}
          onClick={() => setCurrentCertificateIndex((prev) => Math.max(prev - 1, 0))}
          index={currentCertificateIndex}
        />
      )}
      <div className={css.carouselWrapper}>
        <Carousel
          items={certificates.map((item, index) => ({
            id: index,
            content: <CertificateInfo item={item} count={certificates.length} />
          }))}
          activeIndex={currentCertificateIndex}
        />
      </div>
      {multipeCertificates && (
        <RightArrow
          classes={{ root: css.arrow }}
          onClick={() => setCurrentCertificateIndex((prev) => Math.min(prev + 1, certificates.length - 1))}
          index={currentCertificateIndex}
          length={certificates.length}
        />
      )}
    </div>
  );
};

export default Certificates;
