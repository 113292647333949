import { Profile } from "PFTypes";
import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";

export type ResetRegistrationStatusPayload = {
  id: string;
};

export const resetRegistrationStatus = (id: string): jqXHR<Profile> =>
  // @ts-ignore
  $.ajax({
    type: "PUT",
    url: ApiRoute(`/api/admin/profiles/${id}`),
    data: {
      registration_status: "new"
    },
    api_version: 2
  });
