import classNames from "classnames";
import { ProfileItem } from "PFApp/components/profile_item";
import { Profile, Vacancy } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ClosureReason } from "../../../activity_vacancies.types";
import css from "./closed_vacancy.module.scss";

type ClosureInformationProps = {
  closureDetails: string;
  filledRole?: Vacancy;
  profile?: Profile;
  closureReasons: ClosureReason[];
};

export const ClosureInformation = ({
  closureDetails,
  profile,
  filledRole,
  closureReasons
}: ClosureInformationProps): JSX.Element => {
  const { t } = useTranslation("activities", { keyPrefix: "show.vacancies" });

  const isProfileProvided = !!profile;
  const details = isProfileProvided ? (
    <ProfileItem style={{ marginLeft: 5 }} profile={profile} />
  ) : (
    closureDetails
  );
  const filledWith = closureReasons.find(({ key }) => key === filledRole?.closureReason)?.label;

  return (
    <div className={css.reasons}>
      {filledWith && <span>{t("filledWith", { filledWith })}</span>}
      {details && (
        <span className={classNames(css.filledBy, { [css.column]: isProfileProvided })}>
          <div>{t("filledBy")}</div>
          <div>{details}</div>
        </span>
      )}
    </div>
  );
};
