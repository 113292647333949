import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { ApiRoute } from "PFCore/utilities/routes";

export const PROFILES_ASYNC_ACTIONS = {
  FETCH_COLLECTION: "FETCH_PROFILES",
  FETCH_PLANNER_COLLECTION: "FETCH_PLANNER_COLLECTION"
};

export const profilesDispatch = (dispatch, action) => {
  switch (action.type) {
    case PROFILES_ASYNC_ACTIONS.FETCH_COLLECTION: {
      return fetchPaginatedCollection(ApiRoute("/api/profiles"), action.payload.params);
    }
    case PROFILES_ASYNC_ACTIONS.FETCH_PLANNER_COLLECTION: {
      return fetchPaginatedCollection(ApiRoute("/api/profiles/index"), action.payload.params, null, {
        method: "POST"
      });
    }
  }
};
