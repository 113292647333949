import { useActivityFeedback } from "PFCore/hooks/queries/activity/use_activity_feedbacks";
import { FeedbackUser } from "PFTypes/activity_feedback";
import { FeedbackRoundInfo, ParticipatedRole } from "PFTypes/activity_participant";
import { useMemo } from "react";

import { FeedbackFormData } from "../form/feedback_form_context_provider";

type FeedbackDefaultData = {
  formDefaultData: FeedbackFormData | undefined;
  isReadOnly: boolean;
  provider: FeedbackUser | undefined;
};

export const useFeedbackDefaultData = (
  activeFeedbackRound: FeedbackRoundInfo | null,
  selectedRole: ParticipatedRole
): FeedbackDefaultData => {
  const isReadOnly = activeFeedbackRound?.status === "sent";

  const { data: fetchedFeedbackData } = useActivityFeedback(activeFeedbackRound?.id, {
    enabled: !!activeFeedbackRound?.id && isReadOnly
  });

  const provider = fetchedFeedbackData?.provider;

  const formDefaultData: FeedbackFormData | undefined = useMemo(() => {
    const rawData = isReadOnly ? fetchedFeedbackData : selectedRole;
    return rawData
      ? {
          ...rawData,
          skills: (rawData?.skills || []).map((skill) => ({
            ...skill,
            experience: skill.experience || 1,
            isActive: true
          }))
        }
      : rawData;
  }, [fetchedFeedbackData, isReadOnly, selectedRole]);

  return { formDefaultData, isReadOnly, provider };
};
