import { ActionIcon } from "PFComponents/action_icon";

import css from "./booking_note_actions.module.scss";

interface IProps {
  editMode: boolean;
  disabled?: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

export const BookingNoteActions = ({ editMode, disabled, onEditClick, onDeleteClick }: IProps) => (
  <div className={css.root}>
    <ActionIcon name={editMode ? "arrow-left" : "edit"} size="sm" onClick={onEditClick} disabled={disabled} />
    <ActionIcon name="remove" size="sm" onClick={onDeleteClick} disabled={disabled} />
  </div>
);
