import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import { useGrowl } from "PFApp/use_growl";
import { useSession } from "PFApp/use_session";
import { Button } from "PFComponents/button";
import { suspendProfile } from "PFCore/services/profile";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

type DeleteUserSectionProps = {
  profile: Profile;
};

const DeleteUserSection = ({ profile }: DeleteUserSectionProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "edit.sections" });
  const { signOut } = useSession();
  const handleSignOut = () => signOut();
  const growl = useGrowl();
  const handleRemoveProfile = () => {
    if (window.confirm(t("deleteUser.sureDeleteAccount"))) {
      suspendProfile(profile.id).then(() => {
        growl({ message: t("deleteUser.accountDeleted") });
        setTimeout(handleSignOut, 3000);
      });
    }
  };

  return (
    <ProfileEditPanel title={getProfileSectionText("delete_user")}>
      <div style={{ textAlign: "center", margin: 48 }}>
        <Button kind="danger" onClick={handleRemoveProfile}>
          {t("deleteUser.deleteMyAccount")}
        </Button>
      </div>
    </ProfileEditPanel>
  );
};

export default DeleteUserSection;
