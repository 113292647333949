import { DimensionDefinitionLoose } from "echarts/types/src/util/types.js";
import { compact, uniq } from "lodash";
import { ECOption } from "PFCore/helpers/echarts_wrapper";
import { FeedbackForProfile } from "PFTypes/activity_feedback";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { createFeedbackChartConfig } from "./feedback_chart_config";

const getRatedSkillNames = (skills?: FeedbackForProfile["skills"]) =>
  (skills || []).filter((skill) => skill.experience > 0).map((skill) => skill.name);

const checkForRatedSkills = (feedback?: FeedbackForProfile) => {
  if (!feedback) {
    return false;
  }
  return feedback.skills.some((skill) => skill.experience > 0);
};

const formatExperience = (experience?: number) => {
  if (experience === 0 || !experience) {
    return 0;
  }
  return experience + 1;
};

type FeedbackChartData = {
  config: ECOption;
  seriesCount: number;
};

export const useFeedbackChartData = (
  managerFeedback?: FeedbackForProfile,
  selfAssessment?: FeedbackForProfile,
  peerFeedbacks?: FeedbackForProfile[]
): FeedbackChartData => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.experienceFeedback.chart" });
  const displayedPeerFeedbacks = peerFeedbacks?.filter((feedback) => checkForRatedSkills(feedback));
  const isManagerDisplayed = checkForRatedSkills(managerFeedback);
  const isSelfDisplayed = checkForRatedSkills(selfAssessment);

  const peerDimensions = (displayedPeerFeedbacks || []).map((_, index) => ({
    name: `peer${index + 1}`,
    displayName: `${t("peer")} ${index + 1}}`
  }));

  const displayedDimensions: DimensionDefinitionLoose[] = compact([
    isManagerDisplayed ? { name: "manager", displayName: t("manager") } : null,
    isSelfDisplayed ? { name: "self", displayName: t("self") } : null,
    ...peerDimensions
  ]);

  const skillNames = uniq([
    ...getRatedSkillNames(managerFeedback?.skills),
    ...getRatedSkillNames(selfAssessment?.skills),
    ...(displayedPeerFeedbacks || []).flatMap((peer) => getRatedSkillNames(peer.skills))
  ]);

  const datasetSource = useMemo(() => {
    if (
      !isManagerDisplayed &&
      !isSelfDisplayed &&
      (!displayedPeerFeedbacks || displayedPeerFeedbacks?.length === 0)
    ) {
      return null;
    }

    return skillNames.map((skillName) => {
      const managerSkill = managerFeedback?.skills.find((skill) => skill.name === skillName);
      const selfSkill = selfAssessment?.skills.find((skill) => skill.name === skillName);

      const seriesData = {
        skill: skillName || "",
        manager: formatExperience(managerSkill?.experience),
        self: formatExperience(selfSkill?.experience)
      };
      displayedPeerFeedbacks?.forEach((peer, index) => {
        seriesData[`peer${index + 1}`] = formatExperience(
          peer?.skills.find((skill) => skill.name === skillName)?.experience
        );
      });

      return seriesData;
    });
  }, [
    isManagerDisplayed,
    isSelfDisplayed,
    displayedPeerFeedbacks,
    skillNames,
    managerFeedback?.skills,
    selfAssessment?.skills
  ]);

  return {
    seriesCount: skillNames.length,
    config: datasetSource ? createFeedbackChartConfig(datasetSource, displayedDimensions) : {}
  };
};
