import { useEffect } from "react";

import { useGrowl } from "../use_growl";

const STORAGE_KEY = "profinda_custom_configuration";

export const useNotifyAboutCustomConfig = () => {
  const growl = useGrowl();

  useEffect(() => {
    const customConfig = storage.getItem(STORAGE_KEY);

    if (!customConfig) {
      return;
    }

    if (PF.config.environment === "puppetmaster") {
      growl({
        title: "Custom configuration content is enabled",
        message: "In order to disable it call `PF.Initializers.customizeConfig()`",
        kind: "alert"
      });
    } else if (customConfig.locale) {
      growl({
        title: "Custom locale configuration is enabled",
        message: "In order to disable it call `PF.Initializers.customizeConfig()`",
        kind: "alert"
      });
    }
  }, []);
};
