import api from "PFCore/api";
import { Id } from "PFTypes";

export type PostReviewDeadlineResponse = {
  activityId: number;
  completedAt: string | null;
  completedBy: { id: number; firstName: string; lastName: string } | null;
  deadline: string;
  id: number;
  state: string;
};

export const postReviewDeadline = (activityId: Id, date: string) =>
  api<PostReviewDeadlineResponse>({
    method: "POST",
    url: `activities/${activityId}/reviews`,
    data: { deadline: date }
  });

export const putReviewDeadline = (activityId: Id, date: string) =>
  api<PostReviewDeadlineResponse>({
    method: "PUT",
    url: `activities/${activityId}/reviews`,
    data: { deadline: date }
  });

export type CompleteReviewResponse = {
  activityId: number;
  completedAt: string;
  completedBy: { id: number; firstName: string; lastName: string };
  deadline: string;
  id: number;
  state: string;
};

export const completeReview = (activityId: Id) =>
  api<CompleteReviewResponse>({
    method: "PUT",
    url: `activities/${activityId}/reviews`,
    data: { state: "complete" }
  });
