import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { gidFor } from "PFCore/helpers/gid";
import { isMsTeams, openChatInMsTeams } from "PFCore/helpers/ms_teams";
import { ApiRoute } from "PFCore/utilities/routes";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const OpenNewChatButton = ({ item, task, participants, renderChat, currentProfile }) => {
  const { t } = useTranslation();

  const [isPending, setIsPending] = useState(false);

  if (!participants) {
    return <LoadingDots />;
  }

  const participant = participants.find(({ profile: { id } }) => id === item.id);

  const [kind, copy, qaIdSuffix] = participant
    ? ["secondary", t("conversations.open121"), ".open"]
    : isPending
    ? ["blank", <LoadingDots key="idem" />, ".pending"]
    : ["primary", t("conversations.start121"), ".start"];

  return (
    <Button
      qaId={`OpenNewChatButton${qaIdSuffix}`}
      small
      style={{ minWidth: 80 }}
      kind={kind}
      disabled={isPending}
      onClick={() => {
        if (isMsTeams()) {
          $.ajax({
            api_version: 2,
            url: ApiRoute(`/api/profiles/${item.id}`)
          }).always((profile) => openChatInMsTeams(profile?.email || ""));
          return;
        }
        if (participant) {
          renderChat(participant.conversation_id);
        } else {
          setIsPending(true);
          $.post({
            api_version: 2,
            url: ApiRoute("/api/chat/conversations"),
            data: { target: gidFor("Task", task.id), owner_id: currentProfile.id }
          }).then((resp) => {
            renderChat(resp.id);
            $.post({
              api_version: 2,
              url: ApiRoute("/api/chat/participants"),
              data: { conversation_id: resp.id, profile_id: item.id }
            });
          });
        }
      }}
    >
      {copy}
    </Button>
  );
};

OpenNewChatButton.propTypes = {
  item: PropTypes.shape({ id: PropTypes.number }).isRequired,
  task: PropTypes.shape({ id: PropTypes.number }).isRequired,
  renderChat: PropTypes.func.isRequired,

  participants: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  currentProfile: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default OpenNewChatButton;
