import React from "react";
import { useTranslation } from "react-i18next";

import { IRoleWatcher } from "../../../../../../types/watchers";
import css from "../interested_rows.module.scss";

const Questions = ({ watcher }: { watcher: IRoleWatcher }) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.interested.table" });

  return (
    <>
      {(watcher.activity?.questions || []).map(([question, answer], i) => (
        <React.Fragment key={i}>
          <div>{question}</div>
          <div className={css.answer}>{t(`answers.${answer}`)}</div>
        </React.Fragment>
      ))}
    </>
  );
};

export default Questions;
