import css from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { RoleInformation } from "../../components/role_information";
import { MatchesChart } from "../components/matches_chart";
import { MatchesConstraints } from "./matches_constraints";

export const MatchesSidePanel = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches" });
  const { task } = useActivityPageContext();

  return (
    <div className={css.pageCardSidePanel}>
      <Typography variant="h3" noMargin>
        {t("header")}
      </Typography>
      <RoleInformation role={task} qaId="ActivityMatchesTabRoleInformation" />
      <MatchesChart />
      <MatchesConstraints />
    </div>
  );
};
