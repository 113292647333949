import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";

import css from "./save_as_view.module.scss";

type SavedViewBadgeTextProps = {
  name: string;
  onShareClick?: VoidFunction;
};

export const SavedViewBadgeText = ({ name, onShareClick }: SavedViewBadgeTextProps) => (
  <div className={css.badgeText}>
    <Typography variant="bodyRegular" noMargin clipOverflow>
      {name}
    </Typography>
    {onShareClick && (
      <ActionIcon
        name="share"
        size="xs"
        onClick={(event) => {
          event.stopPropagation();
          onShareClick();
        }}
      />
    )}
  </div>
);
