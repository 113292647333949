export const profileHash = (seed = 0) => {
  const x = Math.sin(seed++) * 10000;
  const result = (x - Math.floor(x)).toString().slice(-3);

  // blacklisting numbers that could be inappropriate
  const blacklist = [666];
  if (blacklist.includes(result)) {
    return (x - Math.floor(x)).toString().slice(0, 3);
  } else {
    return result;
  }
};
