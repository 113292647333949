import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";

type DeleteUserResponse = {
  message: string;
};

export const deleteUser = (id: string): jqXHR<DeleteUserResponse> =>
  // @ts-ignore
  $.ajax({
    type: "DELETE",
    url: ApiRoute(`/api/saas_admin/users/${id}`)
  });
