import { useMutation } from "@tanstack/react-query";
import { decamelizeKeys } from "humps";
import { useActivityHistoryInvalidate } from "PFApp/activities/parts/history/hooks/use_activity_history_invalidate";
import { useMatchesShortlistReplace } from "PFCore/hooks/queries/matches/use_matches_shortlist_replace";
import { newShortlist } from "PFCore/services/shortlist";
import { ShortlistMinimized } from "PFTypes";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";

import { useShortlistsInvalidate } from "./use_shortlists_invalidate";

type ShortlistCreateParams = {
  activityId: Id;
  profileId: Id;
  named?: boolean;
};

export const useShortlistCreate = (
  options: MutationOptions<ShortlistCreateParams, ShortlistMinimized> = {},
  withInvalidate = true
) => {
  const { invalidateShortlists } = useShortlistsInvalidate();
  const invalidateHistory = useActivityHistoryInvalidate();
  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();

  return useMutation({
    mutationFn: async ({
      activityId,
      profileId,
      named
    }: ShortlistCreateParams): Promise<ShortlistMinimized> => newShortlist(activityId, profileId, named),
    ...options,
    onSuccess: (...args) => {
      const [shortlist, variables] = args;
      if (withInvalidate) {
        invalidateShortlists(variables.activityId);
        invalidateHistory(variables.activityId);
      }
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      replaceMatchesShortlistInCache(decamelizeKeys(shortlist) as ShortlistMinimized);

      options?.onSuccess?.(...args);
    }
  });
};
