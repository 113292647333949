import { EconomicsScenario, Id } from "PFTypes";

import api from "../../api";

export const shareActivityEconomicsScenario = (scenarioId: Id, profileIds: Id[]) =>
  api<EconomicsScenario>({
    url: `scenarios/${scenarioId}/scenario_shares`,
    method: "POST",
    data: {
      profileIds
    }
  });
