import { find, map } from "lodash";
import { MomentInput } from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { AvailabilityRule, FeatureFlag, StandardRange, TimeRuleRange } from "PFTypes";
import { Phase } from "PFTypes/phase";
import { useTranslation } from "react-i18next";

import { usePhases } from "../hooks/queries/phases/use_phases";
import { getWeekdaysMin } from "./date";
import useIsFeatureEnabled from "./use_is_feature_enabled";

type AvailabilityRangesFormat = (
  formatDate: (value: MomentInput) => string,
  phases: Phase[],
  duration: AvailabilityRule,
  multiline?: boolean,
  className?: string
) => string | JSX.Element;

type UseAvailabilityRangesFormatter = {
  availabilityRangesFormat: AvailabilityRangesFormat;
};

const useAvailabilityRangesFormatter = (): UseAvailabilityRangesFormatter => {
  const { t } = useTranslation();
  const availabilityRangesFormat: AvailabilityRangesFormat = (
    formatDate,
    phases,
    duration,
    multiline = false,
    className
  ) => {
    const rows = duration.ranges
      .sort(({ start: aStart }, { start: bStart }) => ((aStart as string) < (bStart as string) ? -1 : 1))
      .map((range: TimeRuleRange, index) => {
        if (!range.day_of_week) {
          range.day_of_week = [480, 480, 480, 480, 480, 480, 480, 480];
        }
        const startDate = formatDate(range.start);
        const endDate = formatDate(range.end);
        const daysOfWeek = getWeekdaysMin()
          .map((day, index) => `${day}: ${Math.round((range.day_of_week[index] / 60) * 100) / 100}`)
          .join(" ");
        const phase = find(phases, ["sourceId", range.phase_source_id])?.name;

        if (multiline || phase) {
          return (
            <div key={index} className={className}>
              {phase && <div>{t("withColon", { value: phase }) as string}</div>}
              {`${startDate} - ${endDate}`}
              <div>{`(${daysOfWeek})`}</div>
            </div>
          );
        } else {
          return `${startDate} - ${endDate} (${daysOfWeek})`;
        }
      });

    const matching = t("availabilityRequirement.matchingAtLeast", {
      percentage: duration.availability_threshold
    });

    return (
      <>
        {rows}
        {duration && <div>{matching}</div>}
      </>
    );
  };
  return {
    availabilityRangesFormat
  };
};

type AvailabilityIntervalFormat = (
  formatDate: (value: MomentInput) => string,
  phases: Phase[],
  availability: AvailabilityRule
) => string;

type UseAvailabilityIntervalFormatter = {
  availabilityIntervalFormat: AvailabilityIntervalFormat;
};

const useAvailabilityIntervalFormatter = (): UseAvailabilityIntervalFormatter => {
  const { t } = useTranslation(["core", "translation"]);
  const getDuration = (mode: string, value: number): string => {
    if (mode === "days") {
      return t("core:time.inWords.days", { count: value });
    }

    const durationValue = mode === "minutes" ? Math.round(value / 60) : value;
    return t("core:time.inWords.hours", { count: durationValue });
  };

  const getStandardAvailabilityModes = () => [
    {
      displayElement: t("translation:availabilityRequirement.within"),
      id: "within",
      value: "within",
      item: "within"
    },
    {
      displayElement: t("translation:availabilityRequirement.perWeek"),
      id: "per_week",
      value: "per_week",
      item: "per_week"
    },
    {
      displayElement: t("translation:availabilityRequirement.perMonth"),
      id: "per_month",
      value: "per_month",
      item: "per_month"
    }
  ];

  const availabilityIntervalFormat: AvailabilityIntervalFormat = (formatDate, phases, availability) => {
    const rows = map(availability.ranges, (range: StandardRange) => {
      const startDate = formatDate(range.start);
      const endDate = formatDate(range.end);
      const mode = find(getStandardAvailabilityModes(), ({ id }) => id === availability.mode)?.displayElement;
      const phase = find(phases, ["sourceId", range.phase_source_id])?.name;

      const duration = getDuration(range.duration.mode, range.duration.value);

      const description = `${duration} ${mode} ${startDate} - ${endDate}`;

      if (phase) {
        return t("translation:withColon", { value: phase }) + description;
      }

      return description;
    });

    rows.push(
      t("translation:availabilityRequirement.matchingAtLeast", {
        percentage: availability.availability_threshold
      })
    );

    return rows.join("\n");
  };
  return { availabilityIntervalFormat };
};

type UseAvailabilityFormatterReturn = {
  availabilityRangesFormat: (
    duration: AvailabilityRule,
    multiline?: boolean,
    className?: string
  ) => string | JSX.Element;
  availabilityIntervalFormat: (availability: AvailabilityRule) => string;
};

export const useAvailabilityFormatter = (): UseAvailabilityFormatterReturn => {
  const { availabilityIntervalFormat } = useAvailabilityIntervalFormatter();
  const { availabilityRangesFormat } = useAvailabilityRangesFormatter();
  const hasActivityPhases = useIsFeatureEnabled()(FeatureFlag.ActivityPhases);
  const { data: phases } = usePhases(null, {
    enabled: hasActivityPhases,
    placeholderData: { entries: [], meta: {} } as any
  });

  const { formatDate } = useDateFormatter();

  return {
    availabilityRangesFormat: (duration, multiline, className) =>
      availabilityRangesFormat(formatDate, phases!.entries as Phase[], duration, multiline, className),
    availabilityIntervalFormat: (availability) =>
      availabilityIntervalFormat(formatDate, phases!.entries as Phase[], availability)
  };
};
