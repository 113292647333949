import { RowType } from "PFApp/booking/types/row";
import { Icon } from "PFComponents/icon";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";

const activityIcons = {
  role: () => <Icon name="role" size="md" />,
  engagement: () => <Icon name="engagement" size="md" />,
  // eslint-disable-next-line camelcase
  audit_role: () => <Icon name="role-audit" size="md" />,
  // eslint-disable-next-line camelcase
  audit_engagement: () => <Icon name="engagement-audit" size="md" />,
  profile: () => <Icon name="profile" size="md" />
};

interface UseRowIconComponent {
  templateId: number | null;
  type: string;
}

export const useRowIconComponent = ({ templateId, type }: UseRowIconComponent) => {
  const { data: currentProfile } = useCurrentProfile();
  const templateKey =
    type !== RowType.Profile ? currentProfile.templates?.find(({ id }) => id === templateId)?.key : null;
  return activityIcons[templateKey || type];
};
