import { isEmpty, isEqual, isNil } from "lodash";
import { isAvailabilityRule } from "PFCore/helpers/filters/type_checks";
import { AvailabilityMode, AvailabilityRule, FilterWithDefaults } from "PFTypes";
import { useCallback, useEffect, useMemo, useState } from "react";

type UseAvailabilityStateReturn = {
  availability: Partial<AvailabilityRule>;
  defaultAvailability: AvailabilityRule | null | undefined;
  updateAvailability: (newValue: Partial<AvailabilityRule> | null, timeRuleModeChecked?: boolean) => void;
  clearAvailability: VoidFunction;
  restoreAvailability: VoidFunction;
};

export const useAvailabilityState = (
  filter: FilterWithDefaults | null,
  onChange: (updated: Partial<AvailabilityRule> | null) => void
): UseAvailabilityStateReturn => {
  const filterValue = useMemo(() => filter?.value || null, [JSON.stringify(filter?.value || {})]);
  const filterDefaultValue = useMemo(
    () => (isAvailabilityRule(filter?.defaultValue) ? filter?.defaultValue : null),
    [filter?.defaultValue]
  );

  const [availability, setAvailability] = useState<Partial<AvailabilityRule>>(
    isAvailabilityRule(filterValue) ? filterValue : {}
  );

  useEffect(() => {
    setAvailability(isAvailabilityRule(filterValue) ? filterValue : {});
  }, [filterValue]);

  const updateAvailability = useCallback<UseAvailabilityStateReturn["updateAvailability"]>(
    (newValue, timeRuleModeChecked) => {
      if ((isEmpty(availability) && !newValue) || isEqual(newValue, availability)) {
        return;
      }

      if (!newValue) {
        const mode = !isNil(timeRuleModeChecked)
          ? { mode: timeRuleModeChecked ? AvailabilityMode.TimeRule : AvailabilityMode.Within }
          : {};

        onChange(newValue);
        setAvailability({ ranges: [], ...mode });
        return;
      }

      const rangesToSave = newValue.ranges?.filter((range) => range.start && range.end) || [];
      const valueToSave = { ...newValue, ranges: rangesToSave };

      setAvailability(newValue);
      onChange(valueToSave);
    },
    [availability, onChange]
  );

  const clearAvailability = useCallback(() => {
    updateAvailability(null, undefined);
  }, [updateAvailability]);

  const restoreAvailability = useCallback(() => {
    if (filterDefaultValue) {
      onChange(filterDefaultValue);
    }
  }, [filterDefaultValue, onChange]);

  return {
    availability,
    updateAvailability,
    clearAvailability,
    defaultAvailability: filterDefaultValue,
    restoreAvailability
  };
};
