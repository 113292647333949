import moment from "moment";
import { UNDETERMINED_FUTURE } from "PFApp/constants/dates";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

const useAvailabilityLimits = () => {
  const { data: currentAccount } = useCurrentAccount();
  const { formatISODate } = useDateFormatter();

  const monthsLimit = currentAccount.availability?.calculated_months_limit
    ? currentAccount.availability.calculated_months_limit - 1
    : 6;

  const maxDate = formatISODate(UNDETERMINED_FUTURE.endOf("month"));
  const minDate = formatISODate(moment().subtract(monthsLimit, "months").startOf("month"));

  return {
    maxDate,
    minDate
  };
};

export default useAvailabilityLimits;
