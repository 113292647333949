import { TEMPLATE_KEYS } from "PFApp/constants/templates";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag, Template } from "PFTypes";
import { useCallback } from "react";

export const useIsAssigneeRequired = () => {
  const isEnabled = useIsFeatureEnabled();

  return useCallback(
    (template: Template): boolean =>
      isEnabled(FeatureFlag.RequireAssignee) &&
      [
        TEMPLATE_KEYS.ROLE,
        TEMPLATE_KEYS.AUDIT_ROLE,
        TEMPLATE_KEYS.ENGAGEMENT,
        TEMPLATE_KEYS.AUDIT_ENGAGEMENT
      ].includes(template.key),
    [isEnabled]
  );
};
