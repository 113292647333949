import { useAuditRoles } from "PFCore/hooks/queries/roles/use_audit_roles";
import { useRoles } from "PFCore/hooks/queries/roles/use_roles";
import { FetchRolesParams, FetchRolesResponse } from "PFCore/services/roles";
import { useMemo, useState } from "react";

import { activityContextPlaceholder } from "../activity_page_context";

type UseActivityPageRolesReturn = {
  roles: FetchRolesResponse;
  fetchRolesPage: (params: FetchRolesParams) => void;
};

const useActivityPageRoles = (task, isEngagement, kind = "role"): UseActivityPageRolesReturn => {
  const [params, setParams] = useState<FetchRolesParams>({
    page: 1,
    perPage: 25,
    filters: {
      fields: {
        parentActivityId: task.id,
        state: ["draft", "new", "complete"]
      }
    }
  });
  const payload = useMemo(
    () => ({
      ...params,
      filters: {
        ...params.filters,
        fields: {
          ...params.filters?.fields,
          parentActivityId: task.id,
          state: ["draft", "new", "complete"]
        }
      }
    }),
    [params, task.id]
  );

  const isKindRole = kind === "role";

  const { data: roles, refetch: refetchRoles } = useRoles(payload, {
    keepPreviousData: true,
    enabled: isEngagement && task.coowned && isKindRole
  });

  const { data: auditRoles, refetch: refetchAuditRoles } = useAuditRoles(payload, {
    keepPreviousData: true,
    enabled: isEngagement && task.coowned && !isKindRole
  });

  const fetchRoles = (params?: FetchRolesParams) => {
    if (params) {
      setParams(params);
    } else {
      isKindRole ? refetchRoles() : refetchAuditRoles();
    }
  };

  return {
    roles: (isKindRole ? roles : auditRoles) || activityContextPlaceholder.roles,
    fetchRolesPage: fetchRoles
  };
};

export default useActivityPageRoles;
