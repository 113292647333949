import { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { isNil } from "lodash";

import { configureResponses } from "../configuration";
import { Session } from "../types";
import { clearInterceptors, parseRequestToSnakeCase, setParamsSerializer } from "../utils";
import { getInsightsInstance } from "./instance";

const setAuthorizationHeader = (config: InternalAxiosRequestConfig, token: string): void => {
  if (token) {
    config.headers.profindaapitoken = token;
  }
};

const setHeaders = (config: InternalAxiosRequestConfig, token: string, fullDomain?: string): void => {
  const apiVersion = 1;

  config.headers.api_version = apiVersion; // eslint-disable-line camelcase
  delete config.headers.apiVersion;

  if (fullDomain) {
    config.headers.ACCOUNT = fullDomain;
  }
  setAuthorizationHeader(config, token);

  if (!config.headers.Accept) {
    config.headers.Accept = `application/vnd.profinda+json;version=${apiVersion}`;
  }

  if (!isNil(PF.config.locale)) {
    config.headers["Accept-Language"] = PF.config.locale;
  }
};

export const configureInsightsRequests = (
  instance: AxiosInstance,
  session: Session,
  fullDomain?: string
): void => {
  instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    setHeaders(newConfig, session.getAccessToken(), fullDomain);

    if (newConfig.headers["Content-Type"] === "multipart/form-data") {
      return newConfig;
    }

    parseRequestToSnakeCase(newConfig);

    setParamsSerializer(newConfig);

    return newConfig;
  });
};

export const setupInsightsAxiosInstance = (
  session: Session,
  fullDomain?: string,
  onServerError?: VoidFunction
): void => {
  const instance = getInsightsInstance();
  clearInterceptors(instance);
  configureInsightsRequests(instance, session, fullDomain);
  configureResponses(instance, session, onServerError);
};
