import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";

import css from "./profile_item_display.module.scss";

type ProfileItemDisplayProps = {
  profile: Profile;
};

export const ProfileItemDisplay = ({ profile }: ProfileItemDisplayProps): JSX.Element => (
  <div className={css.personFrom}>
    <Typography variant="h5" noMargin className={css.wrappedContainer}>
      {getProfileName(profile)}
    </Typography>
    <Typography variant="bodyRegular" tag="span" className={css.wrappedContainer}>
      {profile.email}
    </Typography>
  </div>
);
