import { isEmpty } from "lodash";
import { Button } from "PFComponents/button/button";
import { forwardRef, JSX, useEffect, useRef, useState } from "react";

import css from "./dynamic_items_container.module.scss";

const MORE_BUTTON_WIDTH = 30;
const ITEMS_GAP = 5;

interface DynamicItemsContainerProps {
  items: JSX.Element[];
  onMoreClick: VoidFunction;
  disabled?: boolean;
}

// adds 1px to each element to avoid unnecessary ellipsis
const getActualContainerWidth = (elementWidths: number[], elementWidthsSum: number, moreCount: number) =>
  elementWidthsSum +
  (elementWidths.length - 1) * (ITEMS_GAP + 1) +
  (moreCount ? MORE_BUTTON_WIDTH + (ITEMS_GAP + 1) : 0);

const calculateMoreCount = (elementWidths: number[], elementWidthsSum: number, containerWidth: number) => {
  let moreCount = 0;
  while (
    elementWidths.length > 0 &&
    getActualContainerWidth(elementWidths, elementWidthsSum, moreCount) > containerWidth
  ) {
    const lastWidth = elementWidths.pop() || 0;
    elementWidthsSum -= lastWidth;
    moreCount++;
  }
  return moreCount;
};

const InvisibleContainerForMeasurements = forwardRef<HTMLDivElement, { items: JSX.Element[] }>(
  ({ items }, ref) => (
    <div ref={ref} className={css.invisibleContainer}>
      {items}
    </div>
  )
);
InvisibleContainerForMeasurements.displayName = "InvisibleContainerForMeasurements";

export const DynamicItemsContainer = ({ items, onMoreClick, disabled }: DynamicItemsContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const invisibleContainerRef = useRef<HTMLDivElement | null>(null);
  const [visibleItems, setVisibleItems] = useState<JSX.Element[]>(items);
  const moreCount = items.length - visibleItems.length;

  useEffect(() => {
    const calculateVisibleItems = () => {
      const containerWidth = containerRef.current?.clientWidth;
      const itemElements = Array.from(invisibleContainerRef.current?.children || []);
      const elementWidths = (itemElements as HTMLDivElement[]).map(({ offsetWidth }) => offsetWidth);
      const elementWidthsSum = elementWidths.reduce((sum, current) => sum + current, 0);
      if (!containerWidth || isEmpty(itemElements) || elementWidthsSum === 0) {
        return;
      }
      const moreCount = calculateMoreCount(elementWidths, elementWidthsSum, containerWidth);
      setVisibleItems(items.slice(0, items.length - moreCount));
    };
    calculateVisibleItems();
    window.addEventListener("resize", calculateVisibleItems);
    return () => {
      window.removeEventListener("resize", calculateVisibleItems);
    };
  }, [items]);

  return (
    <div className={css.root}>
      <InvisibleContainerForMeasurements ref={invisibleContainerRef} items={items} />
      <div
        ref={containerRef}
        className={css.container}
        style={{
          gap: ITEMS_GAP
        }}
      >
        {visibleItems}
        {moreCount > 0 && (
          <Button
            kind="text"
            className={css.more}
            onClick={onMoreClick}
            style={{ width: MORE_BUTTON_WIDTH }}
            disabled={disabled}
          >
            <div>+{moreCount}</div>
          </Button>
        )}
      </div>
    </div>
  );
};
