import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import { useLinkAllCustomFields } from "../hooks";
import { useEngagementLinkStatus } from "../hooks/engagement_link/use_engagement_link_status";

export const RestoreEngagementLinkButton = () => {
  const restoreEngagementValues = useLinkAllCustomFields();
  const { hasAnyLinkedValue, hasAnyValueChanged } = useEngagementLinkStatus();
  const { t } = useTranslation("activities");

  if (!hasAnyLinkedValue || !hasAnyValueChanged) {
    return null;
  }

  return (
    <Button
      kind="secondary"
      icon="history"
      small
      onClick={() => restoreEngagementValues({ skipOtherSources: true })}
      text={t("edit.engagementLink.restoreAll")}
    />
  );
};
