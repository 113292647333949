import { Button } from "PFComponents/button";
import { Select } from "PFComponents/select/select";
import { getProfileName } from "PFCore/helpers/profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { FeedbackRoundInfo } from "PFTypes/activity_participant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFeedbackContext } from "./feedback_context_provider";
import css from "./form/feedback_form.module.scss";
import { useSavedFeedbackForms } from "./hooks/use_saved_feedback_forms";
import { ManagerFeedbackForm } from "./manager_feedback_form";
import { NoFeedbackPlaceholder } from "./no_feedback_placeholder";

export const FormSection = () => {
  const { setActiveFeedbackRound, activeFeedbackRound, selectedParticipant, selectedRole, selectedEntry } =
    useFeedbackContext();
  const { resetSavedForm } = useSavedFeedbackForms();
  const { t } = useTranslation("activities", { keyPrefix: "show.feedback" });
  const { t: commonT } = useTranslation();
  const [isCreatingNewFeedback, setIsCreatingNewFeedback] = useState(false);
  const { formatDateTime } = useDateFormatter();
  const submissionDate = formatDateTime(activeFeedbackRound?.submittedAt);

  useEffect(() => {
    setIsCreatingNewFeedback(false);
  }, [selectedEntry]);

  const selectHandler = (selectedRound: FeedbackRoundInfo) => {
    if (selectedRound.id === activeFeedbackRound?.id) {
      return;
    }
    setActiveFeedbackRound(selectedRound);
    resetSavedForm();
  };

  const fullName = getProfileName(selectedParticipant);
  const isFeedbackListEmpty = selectedRole?.feedback?.length === 0;

  const feedbackRoundOptions = selectedRole?.feedback?.map((feedbackInfo) => ({
    id: feedbackInfo.id,
    displayElement: formatDateTime(feedbackInfo.submittedAt) || String(feedbackInfo.round),
    item: String(feedbackInfo.id)
  }));

  if (isFeedbackListEmpty && !isCreatingNewFeedback) {
    return <NoFeedbackPlaceholder onClick={() => setIsCreatingNewFeedback(true)} />;
  }

  return (
    <>
      <div className={css.formHeader}>
        <h2>{fullName}</h2>
        <div className={css.headerRightSection}>
          {isCreatingNewFeedback ? (
            <>
              <span>{t("addingNewFeedback")}</span>
              <Button
                text={commonT("cancel")}
                kind="secondary"
                onClick={() => setIsCreatingNewFeedback(false)}
              />
            </>
          ) : (
            <>
              <Select
                options={feedbackRoundOptions || []}
                // @ts-ignore
                onChange={selectHandler}
                value={submissionDate || String(activeFeedbackRound?.round) || "1"}
                controlledValue
                readOnly
              />
              <Button text={t("addFeedback")} onClick={() => setIsCreatingNewFeedback(true)} />
            </>
          )}
        </div>
      </div>
      {selectedParticipant && selectedRole && (
        <ManagerFeedbackForm
          activeFeedbackRound={isCreatingNewFeedback ? null : activeFeedbackRound}
          selectedParticipant={selectedParticipant}
          selectedRole={selectedRole}
          onSubmit={() => isCreatingNewFeedback && setIsCreatingNewFeedback(false)}
        />
      )}
    </>
  );
};
