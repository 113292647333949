import classNames from "classnames";
import { capitalize } from "lodash";
import { AddOwnersModal } from "PFApp/activities/show/parts/add_owners_modal";
import { useMetadata } from "PFApp/components/activity_commons/use_metadata";
import { Button } from "PFComponents/button";
import Hr from "PFComponents/hr";
import { Icon } from "PFComponents/icon";
import ToggleableText from "PFComponents/toggleable_text/toggleable_text";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useBookingEngagementsEconomics } from "PFCore/hooks/queries/bookings/engagements/use_booking_engagements_economics";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { BudgetVsCostChart } from "../../../../activities/show/economics/scenario_view/charts/budget_vs_cost_chart";
import css from "../../../parts/overview/detail_view/detail_view.module.scss";
import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { EngagementDetailChildrenRoles } from "./engagement_detail_children_roles";

export const EngagementDetailContent = () => {
  const { formatDate, utc } = useDateFormatter();
  const { t } = useTranslation(["translation", "bookingModule"]);

  const [showAddOwners, setShowAddOwners] = useState(false);
  const { activity, id } = useBookingActivityContext();
  const { data: economicsData } = useBookingEngagementsEconomics(id ? [id] : []);

  const budgetChartData = economicsData?.entries[0];
  const metadata = useMetadata({ activity });
  const filteredMetadata = metadata.filter(
    ({ type, title }) => "availability" !== type && !["Skills", "Duration"].includes(title)
  );

  if (!activity) {
    return null;
  }

  const {
    profile,
    post_until, // eslint-disable-line camelcase
    duration,
    owners,
    description,
    created_at, // eslint-disable-line camelcase
    coowned,
    children_workflow_states_tally: childrenWorkflowStatesTally
  } = activity;

  const daysLeft = -Math.trunc(utc().diff(utc(post_until), "days", true));

  return (
    <div className={classNames(css.root)}>
      <div className={css.item}>
        <Typography variant="labelBold"> {capitalize(t("translation:owner"))}</Typography>
        <span className={css.spaceBetween}>
          <div>
            {owners.map(({ profile }, index) => (
              <React.Fragment key={profile.id}>
                <Link to={`/profiles/${profile.id}`}>{getProfileName(profile)}</Link>
                {index !== owners.length - 1 && ", "}
              </React.Fragment>
            ))}
          </div>

          {coowned && <Button icon="add" kind="secondary" onClick={() => setShowAddOwners(true)} />}
        </span>
      </div>
      <div className={css.item}>
        <Typography variant="labelBold"> {t("translation:description")}</Typography>
        <ToggleableText text={description} />
      </div>
      <span className={classNames(css.item, css.duration, css.spaceBetween)}>
        <span className={classNames(css.alignCenter, css.gap5)}>
          <Icon name="calendar" size="sm" />
          <Typography variant="labelBold">{t("translation:duration")} </Typography>
          {duration ? (
            <>
              <span className={css.strong}>
                {duration.start_date ? formatDate(utc(duration.start_date)) : "-"}
              </span>
              <span className={css.text}> {t("bookingModule:details.durationTo")}</span>
              <span className={css.strong}>
                {duration.end_date ? formatDate(utc(duration.end_date)) : "-"}
              </span>
            </>
          ) : (
            "-"
          )}
        </span>
      </span>
      <span className={css.details}>
        <span className={css.item}>
          <Typography variant="labelBold">{t("translation:postUntil")}</Typography>
          <span className={classNames(css.alignCenter, css.gap5)}>
            <Icon name="hourglass-half" size="sm" />
            {t("translation:day", { count: daysLeft })}
          </span>
        </span>
        <span className={css.item}>
          <Typography variant="labelBold">{t("translation:createdAt")}</Typography>
          <span className={classNames(css.alignCenter, css.gap5)}>
            <Icon name="calendar" size="sm" />
            {formatDate(utc(created_at))}
          </span>
        </span>
        {!!childrenWorkflowStatesTally && id && (
          <EngagementDetailChildrenRoles
            activityId={id}
            childrenWorkflowStatesTally={childrenWorkflowStatesTally}
          />
        )}
      </span>
      {budgetChartData?.budget && (
        <>
          <Hr />
          <div className={css.item}>
            <BudgetVsCostChart
              headerVariant="labelBold"
              budget={budgetChartData?.budget}
              cost={budgetChartData?.cost}
            />
          </div>
        </>
      )}
      <Hr />
      <div className={css.item}>
        <Typography variant="labelBold">{t("translation:privacy")}</Typography>
        <span className={classNames(css.alignCenter, css.gap5)}>
          {t(`translation:${activity.private ? "private" : "public"}`)}
          {activity.private && (
            <Tooltip content={t("bookingModule:details.privacyTooltipText")} appendTo="parent">
              <span className={css.alignCenter}>
                <Icon name="info" size="sm" />
              </span>
            </Tooltip>
          )}
        </span>
      </div>
      <span className={css.details}>
        {filteredMetadata.map(
          ({ title, text }) =>
            text !== undefined &&
            text !== "" && (
              <div key={title} className={css.item}>
                <Typography variant="labelBold">{title}</Typography>
                <span>{text}</span>
              </div>
            )
        )}
      </span>
      <Hr />
      <div className={css.item}>
        <Typography variant="labelBold">{t("translation:creator")}</Typography>
        <div>
          <Link to={`/profiles/${profile.id}`}>{getProfileName(profile)}</Link>
        </div>
      </div>
      {showAddOwners && <AddOwnersModal handleClose={() => setShowAddOwners(false)} activity={activity} />}
    </div>
  );
};
