import { useInterestQuestionnaires } from "PFCore/hooks/queries/common/use_interest_questionnaires";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { TemplatesConfigurationActionType } from "PFReducers/templates_configuration_reducer";
import { Dispatch, ReducerAction, useState } from "react";

export const useTemplatesConfiguration = (dispatch: Dispatch<ReducerAction<any>>): { isReady: boolean } => {
  const { isSignedIn } = useCurrentProfile();

  const [isDataSetToContext, setIsDataSetToContext] = useState(false);

  useInterestQuestionnaires({
    enabled: isSignedIn,
    onSettled: (res) => {
      dispatch({
        type: TemplatesConfigurationActionType.TemplatesConfigurationSet,
        payload: res?.entries || []
      });
      setIsDataSetToContext(true);
    }
  });

  return {
    isReady: isDataSetToContext || !isSignedIn
  };
};
