import { useIsMutating, useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteBooking, DeleteBookingProps } from "PFApp/booking/services/api";
import { MutationOptions } from "PFTypes/request_options";

import { useProfileInvalidate } from "../profile";
import { useBookingsForUpdateInvalidate } from "./bulk_operation/use_bookings_for_update_invalidate";
import { bookingMutationsKeys } from "./mutation_keys";
import { bookingKeys } from "./query_keys";

export const getBookingDeleteMutationKey = (id?: number) =>
  id ? bookingMutationsKeys.delete(id) : ["disabled-booking-delete-mutation"];

export const useBookingDelete = (id?: number, options?: MutationOptions<DeleteBookingProps>) => {
  const cache = useQueryClient();
  const { invalidateProfileWithBooking } = useProfileInvalidate();
  const { invalidate: invalidateSelectMode } = useBookingsForUpdateInvalidate();

  const bookingCacheKey = bookingKeys.single(id || -1);
  const mutationKey = getBookingDeleteMutationKey(id);

  const deleteMutation = useMutation(
    async (payload: DeleteBookingProps) => {
      await cache.cancelQueries(bookingCacheKey);
      return deleteBooking({ id, ...payload });
    },
    {
      mutationKey,
      onSuccess: () => {
        invalidateSelectMode("total_selection");
        return Promise.all([
          cache.invalidateQueries(bookingCacheKey),
          id && invalidateProfileWithBooking(id)
        ]);
      },
      ...options
    }
  );

  const isDeleting = !!useIsMutating({
    mutationKey
  });

  return {
    mutation: deleteMutation,
    delete: deleteMutation.mutateAsync,
    isDeleting
  };
};
