import { defer } from "lodash";
import Analytics from "PFCore/base/analytics";

class Tracking {
  constructor(currentAccount, userId) {
    this.analytics = new Analytics(currentAccount, userId);
  }

  // Records the given event as user action in Segment.io going later to GA
  // @param event_name [String] uniq event name to be recorded
  // @param extra_data [Object] any extra data as action, button, group ...
  recordEvent(event_name, extra_data) {
    return defer(() => {
      if (this.analytics.isEnabled()) {
        return;
      }

      return this.analytics.track(event_name, {
        category: "UserAction",
        extra_data
      });
    });
  }
}

export default Tracking;
