// eslint-disable-next-line max-len
// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const lineHeights = {
  lineHeightBig: "1.50",
  lineHeightRegular: "1.25",
  lineHeightSmall: "1.15"
} as const;

export default lineHeights;
