import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCertificate } from "PFApp/profiles/services/api";
import { Id } from "PFTypes/id";

import { certificateKeys } from "./query_keys";

export const useCertificateDelete = ({ id, onSuccess, ...queryParams }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: Id): any => deleteCertificate(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.all() });
      queryClient.invalidateQueries({ queryKey: certificateKeys.single(id) });
      onSuccess?.();
    },
    ...queryParams
  });
};
