import { Typography } from "PFComponents/typography";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { getContrastingTextColor } from "PFCore/utilities/color";

import BookingPill from "../../../../../../../components/pills/booking_pill";
import { AttributeKey, AttributeValue } from "../../../../../../types/booking_history_item";
import css from "./attribute.module.scss";
import { BooleanAttribute } from "./boolean_attribute";
import { DateAttribute } from "./date_attribute";
import { DurationAttribute } from "./duration_attribute";
import { LoadAttribute } from "./load_attribute";
import { ProfileAttribute } from "./profile_attribute";
import { RequirementType, RequirementTypeAttribute } from "./requirement_type_attribute";

type UseAttribute = {
  attributeId: AttributeKey;
};

type UseAttributeReturn = {
  attributeRenderer: (value: AttributeValue) => JSX.Element | null;
};

export const useAttribute = ({ attributeId }: UseAttribute): UseAttributeReturn => {
  const { getBookingCategory } = useBookingCategories();

  const attributeRenderer = (value: AttributeValue) => {
    if (value === null) {
      return null;
    }

    switch (attributeId) {
      case "booking_category_id": {
        const category = getBookingCategory(value);
        const textColor = category?.color ? getContrastingTextColor(category?.color, 0.7) : undefined;
        return (
          <BookingPill
            category={category}
            className={css.bookingPill}
            style={{ color: textColor, borderWidth: 1 }}
          >
            <Typography variant="labelRegular" tag="span" clipOverflow>
              {category?.display_as}
            </Typography>
          </BookingPill>
        );
      }
      case "profile_id": {
        return <ProfileAttribute profileId={value as number} />;
      }
      case "requirement_value":
      case "duration": {
        return <DurationAttribute value={value as number} />;
      }
      case "calculated_load": {
        return <LoadAttribute value={value as number} />;
      }
      case "start_date":
      case "end_date": {
        return <DateAttribute value={value as string} />;
      }
      case "requirement_type": {
        return <RequirementTypeAttribute value={value as RequirementType} />;
      }
      default: {
        if (typeof value === "boolean") {
          return <BooleanAttribute value={value} />;
        }
        return (
          <Typography variant="bodyRegular" tag="span">
            {value}
          </Typography>
        );
      }
    }
  };

  return {
    attributeRenderer
  };
};
