/* eslint-disable max-len */
import PropTypes from "prop-types";

const ProfindaLogo = ({ colorMain, colorSecondary, width, height }) => (
  <svg
    width={width}
    height={height}
    id="ProFindaLogo"
    data-name="ProFindaLogo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 329.86 69.51"
  >
    <defs>
      <style>
        {`.cls-1 {
            fill: ${colorMain};
          }

          .cls-2 {
            fill: ${colorSecondary};
          }`}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        className="cls-1"
        d="m102.11,42.6h-9.59v12.75h-8.35V14.63h17.76c13.26,0,19.06,4.96,19.06,14.04s-6.45,13.93-18.88,13.93Zm-.41-20.77h-9.18v13.93h9.12c7.46,0,10.77-2.36,10.77-7.02s-3.25-6.9-10.71-6.9Z"
      />
      <path
        className="cls-1"
        d="m143.7,32.57c-.06,0-.95-.06-1.78-.06-5.45,0-8.46,2.36-9.77,4.66v18.18h-8.11v-29.09h7.7v5.19h.12c1.3-2.77,4.38-5.78,11.84-5.78v6.9Z"
      />
      <path
        className="cls-1"
        d="m160.69,56.06c-9.18,0-16.99-5.01-16.99-15.22s7.81-15.28,16.99-15.28,16.99,5.01,16.99,15.28-7.81,15.22-16.99,15.22Zm0-23.78c-5.09,0-8.64,2.95-8.64,8.5s3.55,8.44,8.64,8.44,8.64-2.89,8.64-8.44-3.61-8.5-8.64-8.5Z"
      />
      <path className="cls-2" d="m209.05,21.83h-18.74v12.27h17.03v7.14h-17.03v14.1h-8.35V14.63h27.09v7.2Z" />
      <path className="cls-2" d="m220.94,22.18h-8.35v-7.26h8.35v7.26Zm-.12,33.17h-8.11v-29.09h8.11v29.09Z" />
      <path
        className="cls-2"
        d="m233.31,29.8h.12c1.42-1.48,4.79-4.25,11.01-4.25,7.4,0,12.79,4.01,12.79,13.04v16.76h-8.11v-14.93c0-5.84-2.78-8.03-7.46-8.03-3.97,0-6.75,2.24-7.93,3.48v19.47h-8.11v-29.09h7.7v3.54Z"
      />
      <path
        className="cls-2"
        d="m293.42,55.35h-7.7v-3.54h-.12c-.95,1.06-3.79,4.25-10.77,4.25-8.4,0-14.44-5.31-14.44-15.28s6.93-15.22,15.1-15.22c6.1,0,8.76,2.6,9.71,3.54h.12v-15.64h8.11v41.9Zm-16.1-6.14c5.03,0,7.1-2.3,7.99-3.54v-9.74c-1.01-1.3-3.14-3.66-7.93-3.66s-8.7,2.89-8.7,8.32,3.67,8.62,8.64,8.62Z"
      />
      <path
        className="cls-2"
        d="m329.86,55.35h-7.7v-3.54h-.12c-.95,1.06-3.49,4.25-10.54,4.25-8.76,0-14.68-5.31-14.68-15.34s6.69-15.17,15.1-15.17c6.69,0,9.23,3.24,10.12,4.25h.12v-3.54h7.7v29.09Zm-16.1-6.14c4.79,0,6.93-2.24,7.99-3.54v-9.85c-1.06-1.36-3.2-3.54-7.81-3.54s-8.82,2.72-8.82,8.44,3.67,8.5,8.64,8.5Z"
      />
      <path
        className="cls-2"
        d="m52.5,0H17.01C7.61,0,0,7.61,0,17.01v35.5c0,9.39,7.61,17.01,17.01,17.01h35.5c9.39,0,17.01-7.61,17.01-17.01V17.01c0-9.39-7.61-17.01-17.01-17.01Zm-12.95,45.44h-14.38c-.57,3.6-1.81,11.42-1.81,11.42h-6.45s3.81-23.38,4.72-29.14h17.13c1.7,0,3.09,1.38,3.09,3.09s-1.38,3.09-3.09,3.09c0,0-8.39,0-11.79,0-.29,1.82-.56,3.69-.79,5.19h13.38c4.05,0,7.42-2.88,8.18-6.7.11-.53.16-1.08.16-1.64,0-4.61-3.73-8.34-8.34-8.34h-17.19c.37-2.35,1.01-6.36,1.01-6.36h16.19c8.12,0,14.7,6.58,14.7,14.7s-6.58,14.7-14.7,14.7Z"
      />
    </g>
  </svg>
);

ProfindaLogo.propTypes = {
  colorMain: PropTypes.string,
  colorSecondary: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default ProfindaLogo;
