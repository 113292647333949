import { find } from "lodash";
import { usePreviousValue } from "PFCore/hooks/use_previous_value";
import { RefObject, useCallback, useEffect, useState } from "react";

import { DropdownOption } from "../../dropdown/dropdown";
import { ChatInputFieldSetProps } from "./chat_input_field_set";
import { AutocompleteDropdown } from "./use_autocomplete_data";

type UseChatInputFieldSetProps = {
  autoCompleteDropdowns: AutocompleteDropdown[];
  emojiIsOpen?: boolean;
  value: ChatInputFieldSetProps["value"];
  selection: { selectionStart: number; selectionEnd: number };
  inputRef: RefObject<HTMLTextAreaElement>;
};

export const useChatInputFieldSet = ({
  autoCompleteDropdowns,
  value,
  selection,
  inputRef
}: UseChatInputFieldSetProps) => {
  const [{ emojiIsOpen, dropdownOptions, dropdownTrigger }, setState] = useState<{
    emojiIsOpen: boolean;
    dropdownOptions: DropdownOption[];
    dropdownTrigger: RegExp | null;
  }>({
    emojiIsOpen: false,
    dropdownOptions: [],
    dropdownTrigger: null
  });

  const prevSelection = usePreviousValue(selection);
  const prevEmojiIsOpen = usePreviousValue(emojiIsOpen);
  const prevDropdownOptions = usePreviousValue(dropdownOptions);

  const closeDropdown = useCallback(() => {
    setState((state) => ({ ...state, dropdownOptions: [], emojiIsOpen: false, dropdownTrigger: null }));
  }, [setState]);

  const handleEffect = () => {
    const { selectionStart, selectionEnd } = selection;
    if (
      prevSelection?.selectionStart === selectionStart &&
      prevSelection.selectionEnd === selectionEnd &&
      prevEmojiIsOpen === emojiIsOpen
    ) {
      return;
    }

    const head = value?.substr(0, selectionStart);

    let autoCompleteDropdown, matchValue;

    if (!prevEmojiIsOpen && emojiIsOpen) {
      autoCompleteDropdown = find(autoCompleteDropdowns, (autoCompleteDropdown) =>
        autoCompleteDropdown.trigger.test(":")
      );

      matchValue = ":";
    }

    if (!autoCompleteDropdown && selectionStart === selectionEnd && head) {
      autoCompleteDropdown = find(autoCompleteDropdowns, (autoCompleteDropdown) =>
        autoCompleteDropdown.trigger.test(head)
      );

      if (autoCompleteDropdown) {
        matchValue = head.match(autoCompleteDropdown.trigger)?.[0];
      }
    }

    if (autoCompleteDropdown) {
      autoCompleteDropdown.data(matchValue, (options) => {
        setState((state) => ({
          ...state,
          dropdownOptions: options || [],
          dropdownTrigger: options?.length ? autoCompleteDropdown.trigger : null
        }));
      });
    } else {
      if (prevDropdownOptions) {
        closeDropdown();
      }
    }

    inputRef.current?.focus();

    if (inputRef.current && (selectionStart || selectionStart === 0)) {
      inputRef.current.selectionStart = selectionStart;
    }

    if (inputRef.current && (selectionEnd || selectionEnd === 0)) {
      inputRef.current.selectionEnd = selectionEnd;
    }
  };

  useEffect(() => {
    handleEffect();
  }, [value]);

  useEffect(() => {
    if (!prevEmojiIsOpen && emojiIsOpen) {
      handleEffect();
    }
  }, [emojiIsOpen]);

  const toggleEmojIsOpen = () => {
    if (dropdownOptions.length === 0) {
      setState((state) => ({
        ...state,
        emojiIsOpen: true
      }));
      inputRef.current?.focus();
    } else {
      closeDropdown();
    }
  };

  return {
    dropdownOptions,
    closeDropdown,
    dropdownTrigger,
    toggleEmojIsOpen,
    emojiIsOpen,
    setEmojIsOpen: (emojiIsOpen) => setState((state) => ({ ...state, emojiIsOpen }))
  };
};
