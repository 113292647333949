import PropTypes from "prop-types";
import { Component } from "react";

import ICON_SIZES from "./icon_sizes";

export default class PlusIcon extends Component {
  render() {
    /* eslint-disable max-len */
    const { size, className } = this.props;

    return (
      <svg className={className} width={size} height={size} viewBox="0 0 24 24">
        <path d="M10 10V3a2 2 0 014 0v7h7a2 2 0 010 4h-7v7a2 2 0 01-4 0v-7H3a2 2 0 010-4h7z" />
      </svg>
    );
  }
}

PlusIcon.propTypes = {
  /** 't-shirt' size. */
  size: PropTypes.number,
  className: PropTypes.string
};

PlusIcon.defaultProps = {
  size: ICON_SIZES.sm
};
