import { Conversation } from "PFTypes";

import { gidToPath, gidToType } from "./gid";

export const getTargetUrl = (conversation: Conversation) => gidToPath(conversation.target || "");
export const getTargetType = (conversation: Conversation) => gidToType(conversation.target || "");

const isOwnedBy = (conversation: Conversation, id: number) => conversation.owner?.id === id;
export const allowInvites = (conversation: Conversation, id: number) =>
  getTargetType(conversation) === "Task" && isOwnedBy(conversation, id) && conversation.kind !== "primary";
