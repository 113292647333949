import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking } from "PFTypes";

type UseSotTotalHoursReturn = {
  sortTotalHours: (bookingA: Booking, bookingB: Booking) => number;
};

export const useSortTotalHours = (): UseSotTotalHoursReturn => {
  const { getCategory } = useBookingCategories();

  const sortTotalHours = (bookingA: Booking, bookingB: Booking) => {
    const categoryA = getCategory(bookingA);
    const categoryB = getCategory(bookingB);

    const durationA = categoryA ? bookingA.duration : 0;
    const durationB = categoryB ? bookingB.duration : 0;

    return durationA - durationB;
  };

  return {
    sortTotalHours
  };
};
