import Tooltip from "PFComponents/tooltip/tooltip";
import { useAvailabilityFormatter } from "PFCore/helpers/availability";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { AvailabilityRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./availability_info_panel.module.scss";

type AvailabilityInfoPanelProps = {
  availability: AvailabilityRule;
};

export const AvailabilityInfoPanel = ({ availability }: AvailabilityInfoPanelProps) => {
  const { t } = useTranslation("activities");
  const { availabilityRangesFormat, availabilityIntervalFormat } = useAvailabilityFormatter();
  const { readableAvailability } = useReadableAvailability({ availability });

  if (!availability) {
    return null;
  }

  const tooltipContent =
    availability?.mode === "time_rule"
      ? availabilityRangesFormat(availability, true, css.rangesTooltipContent)
      : availabilityIntervalFormat(availability);

  return (
    <div className={css.root}>
      <b>{t("compare.availabilityRequirement")}</b>
      <Tooltip maxWidth={500} content={<div className={css.tooltipContent}>{tooltipContent}</div>}>
        <div>
          <div>{readableAvailability}</div>
          <div>{t("compare.matchingPercentage", { percentage: availability.availability_threshold })}</div>
        </div>
      </Tooltip>
    </div>
  );
};
