import { ParsedSchemaProperty } from "PFTypes";

import { FormFieldValue } from "../activity_edit_form.types";
import { useActivityEditFormContext } from "../activity_edit_form_context";
import { useFieldValueChange } from "../activity_edit_form_context/hooks";
import { useActivityEditPageContext } from "../activity_edit_page_context";

export const useChangeActivityField = (property: ParsedSchemaProperty) => {
  const { setCustomFields, setMetadata } = useActivityEditFormContext();
  const { template } = useActivityEditPageContext();

  const change = useFieldValueChange({
    templateKey: template.key,
    setCustomFields,
    setMetadata
  });

  return (value: FormFieldValue) => change(value, property);
};
