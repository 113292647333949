/*!
Emoji provided free by http://emojione.com
 */
const makeEmoji = (key, html, unicode, re, stringKey) => ({
  key,
  html,
  unicode,
  unicodeRe: new RegExp(unicode, "g"),
  re: new RegExp(re, "g"),
  stringKey,
});

const emojis = [
  makeEmoji("&gt;:-(", "&#x1f620;", "\ud83d\ude20", ">:-\\(", "angry"),
  makeEmoji("&gt;:(", "&#x1f620;", "\ud83d\ude20", ">:\\(", "angry"),
  makeEmoji(":angry:", "&#x1f620;", "\ud83d\ude20", ":angry:", "angry"),
  makeEmoji(":-)", "&#x1f603;", "\ud83d\ude03", ":-\\)", "smile"),
  makeEmoji(":)", "&#x1f603;", "\ud83d\ude03", ":\\)", "smile"),
  makeEmoji(":-D", "&#x1f603;", "\ud83d\ude03", ":-D", "smile"),
  makeEmoji(":D", "&#x1f603;", "\ud83d\ude03", ":D", "smile"),
  makeEmoji(":smile:", "&#x1f603;", "\ud83d\ude03", ":smile:", "smile"),
  makeEmoji(":-(", "&#x1f61e;", "\ud83d\ude1e", ":-\\(", "disappointed"),
  makeEmoji(":(", "&#x1f61e;", "\ud83d\ude1e", ":\\(", "disappointed"),
  makeEmoji(":disappointed:", "&#x1f61e;", "\ud83d\ude1e", ":disappointed:", "disappointed"),
  makeEmoji(":-P", "&#x1f61b;", "\ud83d\ude1b", ":-P", "tongue"),
  makeEmoji(":P", "&#x1f61b;", "\ud83d\ude1b", ":P", "tongue"),
  makeEmoji(":tongue:", "&#x1f61b;", "\ud83d\ude1b", ":tongue:", "tongue"),
  makeEmoji(":thumbsup:", "&#x1f44d;", "\ud83d\udc4d", ":thumbsup:", "thumbsup"),
  makeEmoji(":thumbsdown:", "&#x1f44e;", "\ud83d\udc4e", ":thumbsdown:", "thumbsdown")
];

const getUnicode = (emoji, options = {}) => {
  if (options.careful) {
    return ` ${ emoji.unicode } `;
  } else {
    return `${ emoji.unicode } `;
  }
};

export const sanitizeInputWithEmojis = (text) => {
  var emoji, i, len;
  for (i = 0, len = emojis.length; i < len; i++) {
    emoji = emojis[i];
    text = text.replace(emoji.unicodeRe, getUnicode(emoji));
    text = text.replace(emoji.re, getUnicode(emoji));
  }
  return text;
};

export const emojify = (text, options = {}) => {
  var emoji, i, len;
  for (i = 0, len = emojis.length; i < len; i++) {
    emoji = emojis[i];
    text = text.replace(emoji.re, getUnicode(emoji, options));
  }
  return text;
};

export const deemojify = (text) => {
  var emoji, i, len;
  for (i = 0, len = emojis.length; i < len; i++) {
    emoji = emojis[i];
    text = text.replace(emoji.unicodeRe, `:${ emoji.stringKey }:`);
    text = text.replace(emoji.key, `:${ emoji.stringKey }:`);
  }
  return text;
};
