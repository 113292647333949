import { omit } from "lodash";
import { PostUntilChangeReason } from "PFTypes";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import { useActivityEditPageContext } from "../../activity_edit_page_context";
import { getPostUntilChangeReason } from "../../sections/expiry_date_section/utils";
import { UseActivityEditFormErrorsReturn } from "./use_activity_edit_form_errors";

export type UseActivityEditFormPostUntilReturn = {
  postUntil: string | undefined;
  updatePostUntil: (postUntil: string) => void;
  postUntilChangeReason: PostUntilChangeReason | null;
  updatePostUntilChangeReason: Dispatch<SetStateAction<PostUntilChangeReason | null>>;
};

export const useActivityEditFormPostUntil = ({ errors, setErrors }: UseActivityEditFormErrorsReturn) => {
  const { activity, template } = useActivityEditPageContext();
  const [postUntil, setPostUntil] = useState<string | undefined>(activity.post_until);

  const reason = getPostUntilChangeReason(
    activity.post_until_change_reason ?? null,
    template.localized_post_until_change_reasons ?? []
  );
  const [postUntilChangeReason, updatePostUntilChangeReason] = useState(reason);

  const updatePostUntil = useCallback(
    (postUntil: string) => {
      const filteredErrors = omit(errors, "post_until");
      setErrors({ ...filteredErrors });
      setPostUntil(postUntil);
    },
    [errors, setErrors]
  );

  return useMemo(
    () => ({
      postUntil,
      updatePostUntil,
      postUntilChangeReason,
      updatePostUntilChangeReason
    }),
    [postUntil, postUntilChangeReason, updatePostUntil]
  );
};
