import moment from "moment";
import { NO_CATEGORY_COLOR } from "PFApp/components/pills/no_category_booking_pill";
import { Booking, BookingCategory } from "PFTypes";

import { CalendarPeriod } from "./bookings_calendar.types";

export const fromCalendarPeriod = (booking: CalendarPeriod): Booking => ({
  ...booking,
  start_date: booking.start_date.toISOString(),
  end_date: booking.end_date.toISOString()
});

export const toCalendarPeriod = (booking: Booking, bookingCategories: BookingCategory[]): CalendarPeriod => {
  const category = bookingCategories.find(({ id }) => id === booking.booking_category_id);
  return {
    ...booking,
    start_date: moment.utc(booking.start_date),
    end_date: moment.utc(booking.end_date),
    rgbHexColor: category?.color || NO_CATEGORY_COLOR
  };
};
