import { find, includes } from "lodash";
import { Dispatch } from "PFApp/app_context";
import AdminCredentialsSection from "PFApp/profiles/edit/sections/admin_credentials_section";
import { AvailabilitySectionWrapper } from "PFApp/profiles/edit/sections/availability_section";
import CalendarsSection from "PFApp/profiles/edit/sections/calendars_section";
import ContactInformationSection from "PFApp/profiles/edit/sections/contact_information_section";
import CredentialsSection from "PFApp/profiles/edit/sections/credentials_section";
import DeleteUserSection from "PFApp/profiles/edit/sections/delete_user_section";
import DetailsSection from "PFApp/profiles/edit/sections/details_section";
import NotificationsSection from "PFApp/profiles/edit/sections/notifications_section";
import PositionsSection from "PFApp/profiles/edit/sections/positions/positions_section";
import useIsAvailabilityPermitted from "PFApp/use_is_availability_permitted";
import i18n from "PFCore/i18n";
import CalendarIcon from "PFIcons/calendar_fixed.svg";
import { MODALS_ACTIONS } from "PFReducers/modals_reducer";
import { Profile } from "PFTypes";

import { NavSection } from "./profile_edit_sections.types";
import { LanguageSettingsSection } from "./sections/language_settings/language_settings_section";

export const profileEditSections = (dispatch?: Dispatch): NavSection[] => [
  {
    placement: "settings",
    section: "credentials",
    text: i18n.t("profiles:edit.emailAndPassword"),
    qaId: "credentials",
    clazz: CredentialsSection,
    requireFeature: "password_signin"
  },
  {
    placement: "settings",
    section: "notifications",
    text: i18n.t("profiles:edit.notificationSettings"),
    qaId: "notifications-settings",
    clazz: NotificationsSection
  },
  {
    placement: "settings",
    section: "language_settings",
    text: i18n.t("profiles:edit.languageSettings"),
    qaId: "delete-user",
    clazz: LanguageSettingsSection
  },
  {
    placement: "settings",
    section: "terms_and_conditions",
    text: i18n.t("profiles:edit.termsAndConditions"),
    qaId: "terms_and_conditions",
    id: "terms_and_conditions_item",
    onClick: () => dispatch && dispatch({ type: MODALS_ACTIONS.TERMS_SHOW })
  },
  {
    placement: "settings",
    section: "privacy_policy",
    text: i18n.t("profiles:edit.privacyPolicy"),
    qaId: "privacy_policy",
    id: "privacy_policy_item",
    onClick: () => window.open("https://www.profinda.com/privacy-policy/", "_blank")
  },
  {
    placement: "settings",
    section: "delete_user",
    text: i18n.t("profiles:edit.deleteAccount"),
    qaId: "delete-user",
    clazz: DeleteUserSection,
    danger: true,
    requireFeature: "self_inactivation"
  }
];

export const adminProfileEditSections = (profile?: Profile): NavSection[] => {
  /* @ts-ignore */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isAvailabilityPermitted = useIsAvailabilityPermitted(profile);

  return [
    {
      placement: "manage",
      section: "details",
      text: i18n.t("profiles:edit.basicInfoAndExpertise"),
      qaId: "basic-info",
      clazz: DetailsSection
    },
    {
      placement: "above",
      section: "availability",
      icon: <CalendarIcon width={16} height={16} />,
      text: i18n.t("profiles:edit.availability"),
      qaId: "availability",
      clazz: AvailabilitySectionWrapper,
      if: () => isAvailabilityPermitted
    },
    {
      placement: "manage",
      section: "positions",
      text: i18n.t("profiles:edit.positions"),
      qaId: "positions",
      clazz: PositionsSection
    },
    {
      placement: "displayed_info",
      section: "contact_information",
      text: i18n.t("profiles:edit.contactInformation"),
      qaId: "contact-info",
      clazz: ContactInformationSection
    },
    {
      placement: "settings",
      section: "admin_credentials",
      text: i18n.t("profiles:edit.emailAndPermissionGroup"),
      qaId: "admin_credentials",
      clazz: AdminCredentialsSection
    },
    {
      placement: "settings",
      section: "calendars",
      text: i18n.t("profiles:edit.calendars"),
      qaId: "calendars",
      clazz: CalendarsSection
    }
  ];
};

export const getProfileSectionText = (section: string, admin = false): string => {
  const sections = admin ? adminProfileEditSections() : profileEditSections();
  const item = find(sections, { section });
  return item ? item.text : "";
};

export const getSectionForTip = (tipName: string, admin = false) => {
  const sections = admin ? adminProfileEditSections() : profileEditSections();
  return find(sections, (section) => section.tipNames && includes(section.tipNames, tipName));
};
