import classNames from "classnames";
import { Typography } from "PFComponents/typography/typography";
import { fetchGeneratedDescription } from "PFCore/services/activities";
import { CustomField } from "PFTypes";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./description_generator.module.scss";
import { ClosedView, ErrorView, Header } from "./parts";

type DescriptionGeneratorProps = {
  title: string;
  description?: string;
  customFields: CustomField[];
  onDescriptionChange: (description: string) => void;
};

export const DescriptionGenerator = ({
  title,
  description,
  customFields,
  onDescriptionChange
}: DescriptionGeneratorProps) => {
  const { t } = useTranslation("activities");
  const ref = useRef<HTMLSpanElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [aiDescriptions, setAiDescriptions] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  const selectedDescription = aiDescriptions[selectedIndex];

  const handleNewDescription = useCallback(
    (props: { longer?: boolean; regenerate?: boolean } = {}) => {
      setIsLoading(true);
      setError(false);
      const skills = customFields.find(({ type }) => type.name === "skills")?.values || [];
      const roleDescription = description || ref.current?.innerText;
      const payload = {
        roleTitle: title,
        selectedSkills: skills.map(({ text }) => text),
        ...((props.regenerate || props.longer) && roleDescription ? { roleDescription } : {}),
        ...props
      };
      fetchGeneratedDescription(payload)
        .then(({ roleDescription }) => {
          const newAiDescriptions = [...aiDescriptions, roleDescription];
          setAiDescriptions(newAiDescriptions);
          setSelectedIndex(newAiDescriptions.length - 1);
          setIsLoading(false);
        })
        .catch(() => {
          setError(true);
          setIsLoading(false);
        });
    },
    [customFields, title, description, aiDescriptions, setAiDescriptions, setSelectedIndex]
  );

  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleDescriptionChange = () => ref.current?.innerText && onDescriptionChange(ref.current?.innerText);

  useEffect(() => {
    setError(false);
    if (isOpen && aiDescriptions.length === 0) {
      handleNewDescription();
    }
  }, [isOpen]);

  if (!isOpen) {
    return <ClosedView title={title} onOpen={onOpen} />;
  }

  return (
    <div className={classNames(css.root, { [css.open]: isOpen, [css.loading]: isLoading })}>
      <Header
        onClose={onClose}
        isLoading={isLoading}
        aiDescriptions={aiDescriptions}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        selectedDescription={selectedDescription}
        handleNewDescription={handleNewDescription}
        handleDescriptionChange={handleDescriptionChange}
        description={description}
      />

      {error && <ErrorView />}
      {!error && selectedDescription && (
        <>
          <Typography className={css.description} variant="bodyRegular">
            <span ref={ref} dangerouslySetInnerHTML={{ __html: selectedDescription }} />
          </Typography>{" "}
          <div className={css.infoMessage}>
            <Typography variant="labelRegular" tag="span">
              {t("edit.sections.descriptionGenerate.infoMessage")}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
