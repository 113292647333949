import { Typography } from "PFComponents/typography";
import { HTMLAttributes } from "react";

import css from "./history_entry_row.scss";

type HistoryEntryRowProps = React.PropsWithChildren<{
  badge?: string;
}> &
  HTMLAttributes<HTMLDivElement>;

export const HistoryEntryRow = ({ children, badge, ...divProps }: HistoryEntryRowProps) => (
  <div className={css.entry}>
    {badge && (
      <Typography noMargin variant="labelRegular">
        {badge}
      </Typography>
    )}
    <div {...divProps}>{children}</div>
  </div>
);
