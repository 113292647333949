import classNames from "classnames";
import Tooltip from "PFComponents/tooltip/tooltip";
import { ReactNode } from "react";

import css from "./progress_bar.module.scss";

type ProgressBarProps = {
  values: {
    value: number;
    color?: string;
    tooltipContent?: ReactNode;
  }[];
  totalValue?: number;
  className?: string;
};

const ProgressBar = ({ values, totalValue, className }: ProgressBarProps) => {
  const total = totalValue || values.reduce((acc, curr) => acc + curr.value, 0);

  return (
    <div className={classNames(css.progressBar, className)}>
      {values.map(({ value, color, tooltipContent }, index) => (
        <Tooltip key={index} content={tooltipContent}>
          <div
            className={css.valueLine}
            style={{ width: `${(value / total) * 100}%`, background: color }}
          ></div>
        </Tooltip>
      ))}
    </div>
  );
};

export default ProgressBar;
