import AppContext from "PFApp/app_context";
import ProfileCardConnected from "PFApp/components/profile_card_connected";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { isProfileActive } from "PFCore/helpers/profile";
import scrollTo from "PFCore/helpers/scroll_to";
import { useCanCreateActivities } from "PFCore/helpers/use_can_create_activities";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import PropTypes from "prop-types";
import { useCallback, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import MoreButton from "../parts/more_button";
import EmptyMatches from "./empty_matches";
import css from "./results.less";
import TaskFromMatchesCard from "./task_from_matches_card";

const InviteButton = ({ match, scrollTargetRef }) => {
  const { dispatch } = useContext(AppContext);
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation();
  const [canCreateActivities] = useCanCreateActivities();

  const canInvite =
    currentProfile.id !== match.profile.id && isProfileActive(match.profile) && canCreateActivities;

  const handleInviteClicked = useCallback(() => {
    const type = SEARCH_ACTIONS.SEARCH_MERGE_STATE;
    dispatch({ type, payload: { matchToInvite: match } });
    const top = scrollTargetRef.current.getBoundingClientRect().top + (window ? window.scrollY : 0);
    // header offset to accomodate the global <Header> - can be removed when it's no longer position:fixed
    const headerOffset = window.matchMedia("(min-width: 992px)").matches ? -90 : 0;
    scrollTo(window, 0, top - 10 + headerOffset);
  }, [match.profile]);

  if (!canInvite) {
    return false;
  }

  return (
    <Button kind="secondary" onClick={handleInviteClicked} qaId="InviteButton">
      {t("invite")}
    </Button>
  );
};

InviteButton.propTypes = {
  match: PropTypes.shape({
    profile: PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.oneOf(["active", "inactive"])
    })
  }),
  scrollTargetRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

const ResultsMatches = () => {
  const {
    dispatch,
    store: {
      search: {
        term,
        matches,
        matchesMeta,
        searchTask,
        matchesPending,
        matchToInvite,
        matchesPage,
        profilesMeta: { total: totalProfiles }
      }
    }
  } = useContext(AppContext);
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("search");

  const isChatEnabled = useIsChatEnabled();
  const scrollTargetRef = useRef();

  if (!matchesMeta.search_id && matches.length === 0 && matchesPending) {
    // only when before any fetch happened
    return <LoadingDots circlesEnabled pageViewEnabled />;
  }

  return (
    <div className={css.root} style={{ opacity: matchesPending ? 0.6 : 1 }} ref={scrollTargetRef}>
      {matches.length === 0 ? (
        <EmptyMatches />
      ) : (
        <>
          {totalProfiles > 0 && (
            <>
              <strong>{t("results.searchingByName")}</strong>
              <p>
                <Button
                  kind="blank"
                  onClick={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TAB, payload: "profiles" })}
                >
                  {t("results.countFoundProfiles", { count: totalProfiles, term })}
                </Button>
              </p>
            </>
          )}
          {matchToInvite && <TaskFromMatchesCard matchToInvite={matchToInvite} searchTask={searchTask} />}
          <ul className={css.results}>
            {matches.map((match) => (
              <ProfileCardConnected
                key={match.id}
                currentProfile={currentProfile}
                tagName={"li"}
                profileResponse={match}
                withChat={isChatEnabled}
              />
            ))}
          </ul>
          <MoreButton
            isLoading={matchesPending}
            renderedItemsCount={matches.length}
            total={matchesMeta.total || 0}
            handleClick={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_MORE_MATCHES })}
            perPage={10}
            page={matchesPage}
          />
        </>
      )}
    </div>
  );
};

export default ResultsMatches;
