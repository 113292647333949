import Tooltip from "PFComponents/tooltip/tooltip";
import ThumbtackIcon from "PFIcons/thumbtack.svg";
import { ReactNode } from "react";

import css from "./pin_button.module.scss";

type Props = {
  onChange: () => void;
  isSelected: boolean;
  renderContent?: (value: boolean) => ReactNode;
};

const PinButton = ({ onChange, isSelected, renderContent }: Props) => (
  <Tooltip theme="pf-white" content={renderContent && renderContent(isSelected)}>
    <div>
      <ThumbtackIcon
        width={10}
        height={20}
        fill={isSelected ? "var(--Palette-base-main)" : "var(--Color-gray-4)"}
        onClick={onChange}
        className={css.pinButton}
      />
    </div>
  </Tooltip>
);

export default PinButton;
