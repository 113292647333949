import { isEmpty } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import EchartsWrapper from "PFCore/helpers/echarts_wrapper";
import { DateWithRangesValue } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./matches_chart.module.scss";
import { matchesChartConfig } from "./matches_chart_config";

export const MatchesChart = () => {
  const {
    matchesMeta: { total, distribution, filters },
    matchesFetchState
  } = useActivityPageContext();
  const { t } = useTranslation("activities", { keyPrefix: "show.matches" });

  const isLoading = !matchesFetchState || matchesFetchState === "pending";
  const config = useMemo(
    () =>
      matchesChartConfig(
        !isEmpty((filters?.availability?.value as unknown as DateWithRangesValue)?.ranges),
        distribution
      ),
    [filters?.availability, distribution]
  );

  return (
    <>
      <div className={css.chartHeader}>
        <Typography variant="bodyBold">{t("chart.title")}</Typography>
        <div className={css.iconWithText}>
          <Icon name="profile" />
          <Typography variant="bodyRegular">
            {t("chart.countMatches", { count: distribution?.counters.matched.total || total || 0 })}
          </Typography>
        </div>
      </div>
      {isEmpty(distribution) ? (
        <div>
          <Typography variant="bodyRegular">{t("chart.noDataWarning")}</Typography>
        </div>
      ) : (
        <EchartsWrapper loading={isLoading} options={config} height={164} width={296} />
      )}
      <div className={css.lowHighMatch}>
        <Typography variant="labelSemibold" className={css.score}>
          {t("chart.low")}
        </Typography>
        <Typography variant="labelSemibold" className={css.score}>
          {t("chart.matchPercentage")}
        </Typography>
        <Typography variant="labelSemibold" className={css.score}>
          {t("chart.high")}
        </Typography>
      </div>
    </>
  );
};
