import initalSearch from "./search";

const initialState = () => ({
  internalExperience: {
    loaded: false,
    entries: [],
    meta: {}
  },
  profilePreview: { show: false, isClosing: false, ready: false },
  activityPreview: {},
  appLayout: {
    navBarRevealed: false, // only affects mobile!
    leftActionPanel: { content: null, title: "...", collapsed: true },
    rightActionPanel: { content: null, title: "...", collapsed: true },
    mainRight: 0,
    mainLeft: 0,
    mainWidth: 320,
    backdropOnClicks: []
  },
  notifications: {
    notifications: { entries: [], meta: { total: 0, total_unread: 0 } },
    page: 1,
    isLoading: true
  },
  notificationSettings: {},
  growls: [],
  chats: {
    ids: [],
    minimizedIds: ["list"]
  },
  pages: {},
  modalSearch: null,
  search: initalSearch({}),
  themeVars: {}
});

export default initialState;
