import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import css from "./empty.module.scss";

const EmptyProfiles = ({ t }) => (
  <div className={css.root}>
    <strong>{t("results.noProfilesFound")}</strong>
    <p>{t("results.tryChangingSearch")}</p>
  </div>
);

export default withTranslation("search")(EmptyProfiles);

EmptyProfiles.propTypes = {
  t: PropTypes.func
};
