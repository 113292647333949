import api from "PFCore/api";
import { Id, MarketplaceActivityCounter, MarketplaceActivityStatistics } from "PFTypes";

export type FetchActivitiesStatisticsParams = {
  id: Id[];
  counters?: MarketplaceActivityCounter[];
};

export type FetchActivitiesStatisticsResponse = Record<Id, MarketplaceActivityStatistics>;

export const fetchActivitiesStatistics = (
  params: FetchActivitiesStatisticsParams
): Promise<FetchActivitiesStatisticsResponse> =>
  api({
    url: "activities/statistics",
    params,
    method: "GET"
  });
