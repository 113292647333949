import { maxBy } from "lodash";
import moment, { Moment } from "moment";
import { Calendar } from "PFTypes";
import { useCallback } from "react";

import { ISO_DATE_FORMAT } from "../../../helpers/date";

type UseWorkingDayCheck = {
  calendars: Record<string, Calendar>;
};

type UseWorkingDayCheckReturn = {
  isWorkingDayCheck: (date: Moment) => boolean;
};

export const useWorkingDayCheck = ({ calendars }: UseWorkingDayCheck): UseWorkingDayCheckReturn => {
  const getCalendarForDate = useCallback(
    (date: Moment) => {
      const calendarKey = maxBy(
        Object.keys(calendars || []).filter(
          (startDate) => moment.utc(startDate).unix() <= moment.utc(date.format(ISO_DATE_FORMAT)).unix()
        ),
        (startDate) => moment(startDate).unix()
      );
      return calendarKey && calendars[calendarKey];
    },
    [calendars]
  );

  const isWorkingDayCheck = useCallback(
    (date: Moment) => {
      const calendar = getCalendarForDate(date);

      if (!calendar) {
        return true;
      }

      const exception = calendar.exception_days.find((day) => date.format(ISO_DATE_FORMAT) === day.date);

      if (exception) {
        return exception.working;
      }

      // Use isoWeekday instead of weekday from moment
      // in case the localization makes Monday the first day of the week
      return calendar.nominal_days.find((day) => day.wday === date.isoWeekday())?.working || false;
    },
    [getCalendarForDate]
  );
  return {
    isWorkingDayCheck
  };
};
