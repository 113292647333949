import classNames from "classnames";
import { isEqual } from "lodash";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { useOptionalTranslation } from "PFCore/hooks/use_optional_translation";
import { OrderMeta } from "PFTypes";

import BulkSelect, { BulkSelectProps } from "./bulk_select";
import css from "./columns_headers.module.scss";
import { columnSizeToClass } from "./helpers";
import { TableColumn } from "./table_types";

type ColumnsHeadersProps = {
  actionsHeader?: string;
  columns: TableColumn[];
  order?: OrderMeta;
  onOrderChange: Function;
  bulkSelectProps?: BulkSelectProps;
  className?: string;
  classes?: {
    selectModePadding?: string;
    mediumColumn?: string;
  };
};

/**
 * @deprecated
 */
const ColumnsHeaders = ({
  actionsHeader,
  columns,
  order,
  onOrderChange,
  bulkSelectProps,
  className,
  classes
}: ColumnsHeadersProps) => {
  const { optionalT } = useOptionalTranslation();
  const showBulkSelect = !!bulkSelectProps;

  const getHeader = (column: TableColumn) => {
    const isSelected = isEqual(order?.value?.name, [column.orderKey]);
    const isAsc = isSelected && order?.value?.direction === "asc";
    const title = optionalT(column.title);
    const availableOptions = (order?.options || []).filter((option) =>
      isEqual(option.name, [column.orderKey])
    );
    // For some columns we are just able to sort in one direction,
    // In that case we do not toggle on click
    const newOrder =
      availableOptions.length === 2
        ? availableOptions.find((option) => option.direction === (isAsc ? "desc" : "asc"))
        : availableOptions[0];

    if (!newOrder) {
      return (
        <Typography variant="bodyBold" tag="span" clipOverflow>
          {title}
        </Typography>
      );
    }

    const iconName = isSelected ? (isAsc ? "caret-down" : "caret-up") : "sort";

    return (
      <Button
        kind="blank"
        style={{ border: 0, borderRadius: 0 }}
        onClick={() => onOrderChange(newOrder)}
        qaId={`WorkflowPageColumnHeaderButton.${column.name}`}
        className={css.sortHeader}
      >
        <Typography variant="bodyBold" tag="span" clipOverflow>
          {title}
        </Typography>
        <Icon name={iconName} size="xs" />
      </Button>
    );
  };

  return (
    <div
      className={classNames(css.container, className, {
        [css.withBulkSelect]: showBulkSelect,
        ...(classes?.selectModePadding ? { [classes?.selectModePadding]: !showBulkSelect } : {})
      })}
    >
      {showBulkSelect && <BulkSelect style={{ bottom: "3px" }} {...bulkSelectProps} />}
      {columns.map((column) => (
        <div
          title={optionalT(column.title)}
          key={column.name}
          className={classNames(css.column, css[columnSizeToClass(column.size)], {
            [classes?.mediumColumn || ""]:
              classes?.mediumColumn && columnSizeToClass(column.size) === "medium"
          })}
        >
          {getHeader(column)}
          {column.childrenComponent}
        </div>
      ))}
      {actionsHeader && (
        <div className={classNames(css.column, css.actionsHeader, css[columnSizeToClass(1)])}>
          {actionsHeader}
        </div>
      )}
    </div>
  );
};

export default ColumnsHeaders;
