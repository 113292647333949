import { InputFieldSet } from "PFComponents/text/input_field_set";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValues } from "../../questions_modal.types";
import RequiredText from "../required_text";
import commonCss from "./../../questions_modal.module.scss";
import css from "./statement_section.module.scss";

const STATEMENT_MAX_LENGTH = 400;

interface Props {
  isEditMode: boolean;
  control: Control<FormValues>;
}

const StatementSection = ({ control, isEditMode }: Props): JSX.Element => {
  const { t } = useTranslation("activities");

  return (
    <section>
      <h5 className={commonCss.formTitle}>
        <RequiredText label={t("questionsModal.enterStatementOfQualification")} />
      </h5>
      <Controller
        name="statementOfQualification"
        control={control}
        rules={{ validate: (value) => !!value?.trim() }}
        render={({ field }) => (
          <InputFieldSet
            {...field}
            inputType="textarea"
            maxLength={STATEMENT_MAX_LENGTH}
            minHeight={105}
            disabled={!isEditMode}
            onChange={field.onChange}
            onBlur={field.onBlur}
            className={css.textarea}
          />
        )}
      />
    </section>
  );
};

export default StatementSection;
