import { isEmpty, isNumber } from "lodash";
import { ActionIcon } from "PFComponents/action_icon";
import { Slider } from "PFComponents/slider";
import { IntegerValue, NumberFilter } from "PFTypes";
import { useTranslation } from "react-i18next";

type IntegerItemProps = {
  label: string;
  filter: NumberFilter;
  handleChange: (value: IntegerValue | null) => void;
  disabled?: boolean;
};

export const IntegerItem = ({ label, filter, handleChange, disabled }: IntegerItemProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "filters.number" });
  const { min, max } = filter.statistics;

  if (!isNumber(min) || !isNumber(max)) {
    // this could only happen if api sends something wrong
    return null;
  }

  const showClearLink = !isEmpty(filter.value);

  const value = {
    min: Math.max((filter.value as IntegerValue)?.gte ?? min, min),
    max: Math.min((filter.value as IntegerValue)?.lte ?? max, max)
  };

  return (
    <Slider
      multiRange
      title={label}
      value={value}
      min={min}
      max={max}
      onChange={({ min, max }) => handleChange({ gte: min, lte: max })}
      disabled={disabled}
      showSteps={false}
      showMinMax={false}
      tip={t("minMax", { min, max })}
      actions={
        showClearLink && (
          <ActionIcon
            name="filter-clean"
            size="sm"
            onClick={() => handleChange(null)}
            color="palettePrimary0"
          />
        )
      }
    />
  );
};
