import { noop } from "lodash";
import { UseShareSavedFiltersData } from "PFCore/hooks/queries/filters/use_share_saved_filters";
import { SavedFilterCreateData } from "PFCore/services/saved_filters";
import { Id } from "PFTypes/id";
import { PageTarget, SavedFilter } from "PFTypes/saved_filters";
import { createContext, Dispatch, SetStateAction, useContext, useMemo } from "react";

import { useSavedViews } from "./use_saved_views";

const INITIAL_SAVED_VIEWS_CONTEXT: SavedViewsType = {
  views: [],
  saveFiltersAsView: () => new Promise<SavedFilter>(noop),
  deleteFiltersView: () => new Promise<null>(noop),
  shareFilters: () => new Promise<SavedFilter[]>(noop),
  selectedView: null,
  setSelectedViewId: noop,
  isLoading: false
};

export type SavedViewsType = {
  views: SavedFilter[];
  saveFiltersAsView: (payload: SavedFilterCreateData) => Promise<SavedFilter>;
  deleteFiltersView: (id: Id) => Promise<null>;
  shareFilters: (data: UseShareSavedFiltersData) => void;
  selectedView: SavedFilter | null;
  setSelectedViewId: Dispatch<SetStateAction<Id | null>>;
  isLoading: boolean;
};

type SavedViewsContextType = SavedViewsType | null;

const SavedViewsContext = createContext<SavedViewsContextType>(null);

type SavedViewsContextProviderType = {
  viewsKey: PageTarget;
  withRelevantToMe?: boolean;
};

export const SavedViewsContextProvider = ({
  viewsKey,
  withRelevantToMe = false,
  children
}: React.PropsWithChildren<SavedViewsContextProviderType>): JSX.Element => {
  const savedViewsParams = useSavedViews(viewsKey, withRelevantToMe);

  const contextValue = useMemo(() => savedViewsParams, [savedViewsParams]);

  return <SavedViewsContext.Provider value={contextValue}>{children}</SavedViewsContext.Provider>;
};

export const useSavedViewsContext = (viewsKey?: PageTarget | null): SavedViewsType => {
  const savedViewsContext = useContext<SavedViewsContextType>(SavedViewsContext);

  if (!savedViewsContext && viewsKey) {
    throw new Error("useSavedViewsContext must be used within a SavedViewsContextProvider");
  }
  return savedViewsContext || INITIAL_SAVED_VIEWS_CONTEXT;
};
