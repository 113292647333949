import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useTemplate } from "PFApp/hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export const Breadcrumbs = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const { isAudit, isRole, isEngagement, parentActivity, task } = useActivityPageContext();
  const template = useTemplate(task.template_id);

  const rootLinkData = useMemo(() => {
    if (isAudit) {
      return { path: "/audit_planner", label: t("auditPlanner") };
    }
    if (isRole || isEngagement) {
      return { path: `/workflow/${isRole ? "roles" : "engagements"}`, label: t("workflow") };
    }
  }, [isAudit, isRole, isEngagement, t]);

  if (!rootLinkData) {
    return null;
  }

  return (
    <div>
      <Link to={rootLinkData.path}>{rootLinkData.label}</Link>
      {parentActivity && (
        <>
          <span> / </span>
          <Link
            to={{
              pathname: `/activities/${parentActivity.id}`,
              search: `origin_id=${task.id}&origin_type=${template?.name}`,
              state: { utmSource: (location?.state as any)?.utmSource }
            }}
          >
            {parentActivity.name}
          </Link>
        </>
      )}
    </div>
  );
};
