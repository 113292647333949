import useStorage from "PFCore/helpers/use_storage";

import { useFilters, UseFiltersReturn } from "./use_filters";

const STORAGE_OPTIONS = { expiresIn: 0 };

export type FiltersStorage = UseFiltersReturn;

const useStorageFilters = ({
  storagePrefix,
  defaultValue
}: {
  storagePrefix: string;
  defaultValue: Record<string, unknown>;
}): UseFiltersReturn => {
  const [selectedFilters, setSelectedFilters] = useStorage<any>(
    `${storagePrefix}_selectedValueFilters`,
    defaultValue,
    STORAGE_OPTIONS
  );

  return useFilters({ selectedFilters, setSelectedFilters, defaultValue });
};

export default useStorageFilters;
