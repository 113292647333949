import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { fetchVacancies } from "../../services/vacancies";

const RoleVacanciesBadge = ({ role }) => {
  const [vacancies, setVacancies] = useState({ entries: [] });

  useEffect(() => {
    if (role.coowned) {
      fetchVacancies({
        activityId: role.id,
        page: 1,
        perPage: 1000
      }).then((resp) => {
        setVacancies(resp);
      });
    }
  }, [role.id, role.coowned]);

  if (!role.coowned) {
    return null;
  }

  const isLoading = !vacancies.meta;
  const openCount = vacancies.entries.filter(({ state }) => state === "opened").length;
  const message = `${openCount} / ${vacancies.entries.length}`;

  return !isLoading && <SimpleBadge small text={message} />;
};

RoleVacanciesBadge.propTypes = {
  role: PropTypes.shape({
    id: PropTypes.number.isRequired,
    coowned: PropTypes.bool.isRequired
  }).isRequired
};

export default RoleVacanciesBadge;
