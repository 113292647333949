import classNames from "classnames";
import { range } from "lodash";
import { forwardRef } from "react";

import css from "./ghost_page.module.scss";

type GhostPageProps = {
  tiles?: number;
  className?: string;
};

export const GhostPage = forwardRef<HTMLDivElement | null, GhostPageProps>(
  ({ tiles = 5, className }, ref): JSX.Element => (
    <div className={classNames(css.ghostPage, className)} ref={ref}>
      {range(0, tiles).map((id) => (
        <div key={id} className={css.contentPlaceholder} />
      ))}
    </div>
  )
);

GhostPage.displayName = "GhostPage";
