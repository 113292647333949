import { useCallback, useMemo, useState } from "react";

import { AccordionProps } from "./accordion";
import { useAccordionState } from "./use_accordion_state";
import { useIsContentOverflowVisible } from "./use_is_content_overflow_visible";

type UseAccordionProps = Pick<AccordionProps, "defaultState" | "state" | "onStateChange">;

const TRANSITION_DURATION = 200;
const contentStyle = {
  transitionProperty: "max-height",
  transitionTimingFunction: "ease-in",
  overflow: "hidden",
  height: "auto",
  transitionDuration: `${TRANSITION_DURATION}ms`
};

export const useAccordion = ({ defaultState = "open", state, onStateChange }: UseAccordionProps) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const { isOpen, toggle } = useAccordionState({ defaultState, state, onStateChange });

  const handleHeight = useCallback((node: HTMLDivElement) => {
    setMaxHeight(node?.scrollHeight);
  }, []);

  const isOverflowVisible = useIsContentOverflowVisible({ isOpen, delay: TRANSITION_DURATION });

  const contentProps = useMemo(
    () => ({
      ref: handleHeight,
      style: {
        maxHeight: isOpen ? maxHeight : 0,
        ...contentStyle,
        ...(isOverflowVisible ? { overflow: "visible" } : {})
      }
    }),
    [handleHeight, isOpen, isOverflowVisible, maxHeight]
  );

  return {
    handleStateChange: toggle,
    isOpen,
    contentProps,
    isOverflowVisible
  };
};
