import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { PermissionRule } from "PFTypes";

type UseButtonsPermission = {
  bookingEndDate: string | undefined;
  isEditPermitted: boolean | undefined;
};

export const useButtonsPermission = ({ bookingEndDate, isEditPermitted = true }: UseButtonsPermission) => {
  const { utc } = useDateFormatter();
  const isBookingResourcer = useIsCurrentUserPermittedTo(PermissionRule.BookingResourcer);
  const isPastBooking = utc(bookingEndDate).isBefore(new Date());
  const canSeeActionButtons = isBookingResourcer ? isEditPermitted : isEditPermitted && !isPastBooking;
  return {
    canSeeActionButtons
  };
};
