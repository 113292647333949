import { useTemplate } from "PFApp/hooks";
import { AvailabilityRule, FilterWithDefaults, Template } from "PFTypes";

import { useAvailabilityState } from "./hooks/use_availability_state";
import { AvailabilityLimitWrapper } from "./parts/availability_limit_wrapper";
import { AvailabilityThresholdSlider } from "./parts/availability_threshold_slider";

type AvailabilityFilterItemCompressedProps = {
  filter: FilterWithDefaults;
  onChange: (value: AvailabilityRule | null) => void;
  scope: number;
  templateKey: Template["key"];
};

export const AvailabilityFilterItemCompressed = ({
  filter,
  onChange,
  scope = 0,
  templateKey = "task"
}: AvailabilityFilterItemCompressedProps) => {
  const { availability, updateAvailability: handleAvailabilityChange } = useAvailabilityState(
    filter,
    onChange
  );
  const template = useTemplate(templateKey);

  if (!template || !availability) {
    return null;
  }

  return (
    <AvailabilityLimitWrapper scope={scope}>
      <AvailabilityThresholdSlider
        availability={availability}
        onChange={handleAvailabilityChange}
        template={template}
      />
    </AvailabilityLimitWrapper>
  );
};
