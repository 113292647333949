import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";

export type DownloadAttachmentPayload = {
  id: number;
};

type DownloadAttachmentResponse = {
  file_url: string;
};

export const downloadAttachment = ({ id }: DownloadAttachmentPayload): jqXHR<DownloadAttachmentResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute(`/api/attachments/${id}/download`),
    api_version: 2
  });
