import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";

const noop = () => {};

const useDebounce = (fn, wait = 100) => {
  const fnRef = useRef();

  useEffect(() => {
    fnRef.current = fn;
    return () => {
      fnRef.current = noop;
    };
  }, [fn, wait]);

  return useMemo(() => debounce((...attrs) => fnRef.current(...attrs), wait), []);
};

export default useDebounce;
