import { Icon } from "PFCore/components/icon";
import { Typography } from "PFCore/components/typography";
import { DragEvent } from "react";
import { useTranslation } from "react-i18next";

import css from "./file_upload.module.scss";

type DraggedOverlayProps = {
  onDragLeave: (event: DragEvent<HTMLDivElement>) => void;
};

export const DraggedOverlay = ({ onDragLeave }: DraggedOverlayProps) => {
  const { t } = useTranslation("core");
  return (
    <div className={css.overlay} onDragLeave={onDragLeave} aria-hidden>
      <div className={css.uploadIcon}>
        <Icon name="import" size="xxl" fill="var(--palettePrimary2)" />
        <Typography variant="bodyBold" tag="span">
          {t("dropTheFilesHere")}
        </Typography>
      </div>
    </div>
  );
};
