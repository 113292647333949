import classNames from "classnames";
import { forwardRef } from "react";

import css from "./pill.module.scss";

type PillProps = {
  className?: string;
  style?: React.CSSProperties;
  small?: boolean;
  dark?: boolean;
};

const Pill = forwardRef<HTMLDivElement, React.PropsWithChildren<PillProps>>(
  ({ className, style, small = false, dark = false, children }, ref): JSX.Element => (
    <div
      ref={ref}
      className={classNames(css.root, { [css.small]: small, [css.dark]: dark }, className)}
      style={style}
    >
      {children}
    </div>
  )
);

Pill.displayName = "Pill";

export default Pill;
