import { MomentInput } from "moment";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { DEFAULT_DATE_FORMAT } from "PFCore/helpers/date";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import colors from "PFTheme/tokens/colors";

type MisalignmentHeaderProps = {
  start: string;
  end: string;
};

export const MisalignmentHeader = ({ start, end }: MisalignmentHeaderProps) => {
  const { utc } = useDateFormatter();
  const isOneDay = start === end;
  const formatDate = (date: MomentInput) => utc(date).format(DEFAULT_DATE_FORMAT);

  return (
    <>
      <Icon name="calendar-misaligned" fill={colors.paletteOrange0} />
      <Typography variant="labelBold" noMargin>
        {isOneDay ? formatDate(start) : `${formatDate(start)} - ${formatDate(end)}`}
      </Typography>
    </>
  );
};
