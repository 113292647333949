import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useBookingActivity } from "PFCore/hooks/queries/activity/use_booking_activity";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { useShortlistReset } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { PermissionRule } from "PFTypes";
import { useCallback } from "react";

import useRowChildrenInvalidate from "../../parts/overview/side_panel_row/queries/use_row_children_invalidate";
import { BookingSelectOption } from "../booking_questions/booking_questions";
import { BookingDeleteAllOption } from "./delete_all_bookings_question";
import { DeleteMode } from "./delete_booking.types";

const SHORTLISTED_STATE = ["confirmed", "booked"];

type UseResetShortlist = {
  activityId: number | undefined;
  profileId: number;
  deleteMode: DeleteMode;
};

type UseResetShortlistReturn = {
  isPermittedToResetShortlist: boolean;
  handleResetShortlist: (
    deleteOptionSelected: BookingSelectOption,
    deleteAllOption: BookingDeleteAllOption
  ) => void;
};

export const useResetShortlist = ({
  deleteMode,
  activityId,
  profileId
}: UseResetShortlist): UseResetShortlistReturn => {
  const { data: activityData } = useBookingActivity(activityId ?? -1, {
    enabled: !!activityId
  });

  const { invalidateByActivityIds } = useRowChildrenInvalidate();
  const { mutate: resetShortlist } = useShortlistReset({
    onSuccess: () => {
      activityId && invalidateByActivityIds([activityId]);
    }
  });

  const isBookingResourcer = useIsCurrentUserPermittedTo(PermissionRule.BookingResourcer);

  const isCoOwnerOrReviewer = !!activityData?.coowned || !!activityData?.reviewer;
  const isPermittedToGetShortlistData = isCoOwnerOrReviewer && isBookingResourcer;

  const { data: shortlistData } = useActivityShortlists(
    activityId ?? -1,
    {},
    {
      enabled:
        isPermittedToGetShortlistData &&
        [DeleteMode.MultipleBookings, DeleteMode.LastBooking].includes(deleteMode) &&
        !!activityId
    }
  );

  const shortlistedProfile = shortlistData?.entries.find(({ profile }) => profile.id === profileId);

  const isPermittedToResetShortlist =
    isPermittedToGetShortlistData &&
    !!shortlistedProfile &&
    SHORTLISTED_STATE.includes(shortlistedProfile.state);

  const handleResetShortlist = useCallback(
    (deleteOptionSelected: BookingSelectOption, deleteAllOption: BookingDeleteAllOption) => {
      const isAllBookingsOptionOrLastBooking =
        deleteOptionSelected === BookingSelectOption.All || deleteMode === DeleteMode.LastBooking;
      if (
        isPermittedToResetShortlist &&
        isAllBookingsOptionOrLastBooking &&
        deleteAllOption === BookingDeleteAllOption.ReopenVacancy
      ) {
        const shortlist = shortlistData?.entries.find(
          (shortlistItem) => shortlistItem.profile.id === profileId
        );
        shortlist && resetShortlist(shortlist.id);
      }
    },
    [isPermittedToResetShortlist, profileId, shortlistData, resetShortlist, deleteMode]
  );

  return {
    isPermittedToResetShortlist,
    handleResetShortlist
  };
};
