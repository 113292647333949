import { useMutation } from "@tanstack/react-query";
import { bulkCreateOwner } from "PFCore/services/owners";
import { MutationOptions } from "PFTypes/request_options";

import { useActivityInvalidate } from "../activity/use_activity_invalidate";

export const useBulkOwnerCreate = (activityIds: number[], options: MutationOptions<number[]> = {}) => {
  const { invalidate: invalidateActivity } = useActivityInvalidate();

  return useMutation({
    mutationFn: async (profileIds: number[]) =>
      bulkCreateOwner({
        activityIds,
        profileIds
      }),
    onSuccess: (...args) => {
      invalidateActivity(activityIds);
      options.onSuccess?.(...args);
    },
    ...options
  });
};
