import api from "PFCore/api";
import { CalendarRange, Collection, Id } from "PFTypes";

export type BulkOperationFilter = {
  includeChildren?: boolean;
} & ConditionalBulkOperationFilter;

type ConditionalBulkOperationFilter =
  | {
      activityId?: Id;
      profileId?: Id;
      id?: never;
    }
  | { activityId?: Id[]; profileId?: Id[]; id?: never }
  | {
      activityId?: never;
      profileId?: never;
      id?: Id[];
    }
  | {
      activityId?: Id[];
      profileId?: never;
      id?: never;
    };

export type Exclude = {
  id?: Id[];
  activityId?: Id[];
  profileId?: Id[];
};

type BulkMoveUpdates = {
  updates: {
    offsetInMinutes: number;
  };
};

type BulkUpdateUpdates = {
  updates: {
    startDate?: string;
    endDate?: string;
    bookingCategoryId?: Id;
    title?: string;
    description?: string;
  };
};

export type BulkOperationBasePayload = {
  filters: BulkOperationFilter[];
  exclude?: Exclude;
  dateRange?: CalendarRange;
};

export type BookingsBulkMovePayload = BulkOperationBasePayload & BulkMoveUpdates;
export type BookingsBulkUpdatePayload = BulkOperationBasePayload & BulkUpdateUpdates;

export type BulkOperationResponse = {
  bookingIds: number[];
};

export const bookingsBulkMove = (data: BookingsBulkMovePayload): Promise<BulkOperationResponse> =>
  api({
    url: `booking_module/bookings/bulk/move`,
    method: "POST",
    data
  });

export const bookingsBulkUpdate = (data: BookingsBulkUpdatePayload): Promise<BulkOperationResponse> =>
  api({
    url: `booking_module/bookings/bulk/update`,
    method: "POST",
    data
  });

export type BookingsForUpdatePayload = {
  page: number;
  perPage: number;
} & BulkOperationBasePayload;

export type BookingForUpdate = {
  id: Id;
  bookingCategoryId: Id;
};

type CustomMeta = {
  dateRange: {
    min: string;
    max: string;
  };
};

export type BookingsForUpdateResponse = Collection<BookingForUpdate[], CustomMeta>;

export const bookingsForUpdate = (data: BookingsForUpdatePayload): Promise<BookingsForUpdateResponse> =>
  api({
    url: `booking_module/bookings/for_update`,
    method: "POST",
    data
  });
