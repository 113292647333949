import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityShortlistsFilters } from "PFApp/activities/show/hooks/use_activity_shortlists_filters";

import { SideFilters } from "../../../components/side_panel";

const FILTERS_RENDERED_OUTSIDE = ["term"];

export const ShortlistSideFilters = () => {
  const { shortlistsMeta, isLoadingShortlists } = useActivityPageContext();
  const activityShortlistFilters = useActivityShortlistsFilters();

  return (
    <SideFilters
      isPending={isLoadingShortlists}
      meta={shortlistsMeta}
      total={shortlistsMeta.total}
      omittedFilters={FILTERS_RENDERED_OUTSIDE}
      {...activityShortlistFilters}
      qaId="ActivityShortlistTabSideFiltersToggle"
    />
  );
};
