import { decamelizeKeys } from "humps";
import { Id, Profile, QueryParams } from "PFTypes";

import api from "../api/api";

type ExportProfilesQueryParams = QueryParams & { profileIds?: Id[]; activityId?: Id };
type ExportProfilesResponse = Partial<Profile & { score: number }>[];

// TODO: [PROF-5746] Remove decamelizeKeys when app is camelized
export const getExportProfiles = (data: ExportProfilesQueryParams) =>
  api<ExportProfilesResponse>({ url: "export/profiles/index", method: "POST", data }).then(
    (resp) => decamelizeKeys(resp) as ExportProfilesResponse
  );
