import { Draggable } from "react-beautiful-dnd";

import { Skill, SkillProps } from "./skill";

const getDraggableId = (skill: SkillProps["skill"], parentSection?: string): string => {
  if (parentSection) {
    return `${parentSection}-${skill.id || skill.value}`;
  }
  return String(skill.id || skill.value);
};

type DraggableSkillProps = {
  index: number;
  parentSection?: string;
} & SkillProps;

export const DraggableSkill = (props: DraggableSkillProps): JSX.Element => {
  const { skill, parentSection, index, ...rest } = props;
  return (
    <Draggable draggableId={getDraggableId(skill, parentSection)} index={index}>
      {(provided, snapshot) => <Skill skill={skill} draggableProps={{ provided, snapshot }} {...rest} />}
    </Draggable>
  );
};
