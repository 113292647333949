import { Button } from "PFComponents/button/button";
import { TableSorting } from "PFComponents/table/table_sorting";
import { HistorySortBy } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useActivityHistoryContext } from "../../activity_history_context";
import { useActivityHistory } from "../../hooks";

export const HistorySort = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history.sort" });
  const { sortBy, setSortBy } = useActivityHistoryContext();
  const { data } = useActivityHistory();
  const historyEntries = data?.entries || [];

  if (historyEntries.length === 0) {
    return null;
  }

  const isFromLatest = sortBy === HistorySortBy.Latest;

  const isSorted = isFromLatest ? "desc" : "asc";

  return (
    <Button
      kind="blank"
      onClick={() => setSortBy(isFromLatest ? HistorySortBy.Oldest : HistorySortBy.Latest)}
    >
      {t(isFromLatest ? "fromLatest" : "fromOldest")}
      <TableSorting isSorted={isSorted} />
    </Button>
  );
};
