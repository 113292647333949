import { StandardLonghandProperties } from "csstype";
import { LoadingDots } from "PFComponents/loading_dots";

import css from "./history_entry_data_placeholder.module.scss";

type HistoryEntryDataPlaceholderProps = {
  height?: StandardLonghandProperties["height"];
};

export const HistoryEntryDataPlaceholder = ({ height = "100%" }: HistoryEntryDataPlaceholderProps) => (
  <div className={css.placeholder} style={{ height }}>
    <LoadingDots />
  </div>
);
