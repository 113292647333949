import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityMatchesFilters } from "PFApp/activities/show/hooks/use_activity_matches_filters";

import { SideFilters } from "../../../../components/side_panel";
import { useAvailabilityFilter } from "../hooks/use_availability_filter";

const FILTERS_RENDERED_OUTSIDE = ["availability", "term"];

export const MatchesFilters = () => {
  const { matchesFetchState, matchesMeta } = useActivityPageContext();
  const { isAvailabilityFilterOn } = useAvailabilityFilter();

  const activityMatchesFilters = useActivityMatchesFilters();

  return (
    <SideFilters
      isPending={matchesFetchState === "pending"}
      meta={matchesMeta}
      total={matchesMeta.distribution?.counters.prefiltered?.total || 0}
      omittedFilters={FILTERS_RENDERED_OUTSIDE}
      extraToggleCondition={isAvailabilityFilterOn}
      {...activityMatchesFilters}
      qaId="ActivityMatchesTabSideFiltersToggle"
    />
  );
};
