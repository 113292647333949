import Tooltip from "PFComponents/tooltip/tooltip";
import WarningIcon from "PFIcons/warning.svg";
import { useTranslation } from "react-i18next";

import css from "./availability_warning_icon.module.scss";

const AvailabilityWarningIcon = () => {
  const { t } = useTranslation("activities");

  return (
    <Tooltip content={t("show.shortlist.candidateNoLongerMatch")}>
      <div className={css.warningIcon}>
        <WarningIcon width={20} height={20} />
      </div>
    </Tooltip>
  );
};

export default AvailabilityWarningIcon;
