import isEmpty from "lodash/isEmpty";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { Typography } from "PFComponents/typography";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

const SKILLS_LIMIT = 5;

type SkillsProps = {
  activity: Activity;
};

export const Skills = ({ activity }: SkillsProps) => {
  const { t } = useTranslation();
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();

  const { custom_fields: customFields } = activity;
  const skills = (customFields || []).find(({ type }) => type.name === "skills");
  const skillsType = useSkillsType();

  if (isEmpty(skills?.values)) {
    return null;
  }

  return (
    <span className={css.item}>
      <Typography variant="bodyBold" noMargin>
        {t("skills")}
      </Typography>
      <CustomValuesList
        type={skillsType}
        customValues={skills!.values}
        showTips={true}
        displayAttributeLevel={true}
        profileId={currentProfile.id}
        moreLimit={SKILLS_LIMIT}
        currentProfile={currentProfile}
        currentAccount={currentAccount}
        showModal
      />
    </span>
  );
};
