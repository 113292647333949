import { reduce } from "lodash";
import { GenericActivityEvent } from "PFTypes";
import { useTranslation } from "react-i18next";

export const useSanitizedAttributes = (
  updatedAttributes: GenericActivityEvent["updatedAttributes"]
): GenericActivityEvent["updatedAttributes"] => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history.entry.updatedAttributes" });

  return reduce(
    updatedAttributes,
    (acc, value: object, key) => {
      // Hide metadata key from the UI
      if (key === "metadata") {
        return { ...acc, ...value! };
      }

      // Hide id key from the UI
      if (key === "id") {
        return acc;
      }

      // Flatten the Availability Ranges object with Index
      if (key === "ranges") {
        const flattenedRanges = reduce(
          value,
          (acc, value, key) => ({
            ...acc,
            [t("range", { index: key })]: value
          }),
          {}
        );

        return { ...acc, ...flattenedRanges };
      }

      return { ...acc, [key]: value };
    },
    {}
  );
};
