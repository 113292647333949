import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityTemplate } from "PFApp/activities/show/hooks/use_activity_template";
import { getFilterDefault, getFilterQuery } from "PFApp/use_filtered_collection";
import { AvailabilityRule, FilterWithDefaults } from "PFTypes";
import { useMemo } from "react";

import { useActivityPropertyFilters } from "./use_activity_property_filters";

export const useAvailabilityFilter = () => {
  const template = useActivityTemplate();
  const { matchesMeta } = useActivityPageContext();
  const { includedPropertyFilters } = useActivityPropertyFilters();

  const availabilityFilter = useMemo<FilterWithDefaults | null>(() => {
    const rawFilter = includedPropertyFilters.find(({ name }) => name === "availability");

    if (!rawFilter) {
      return null;
    }

    return {
      ...(rawFilter || {}),
      query: getFilterQuery(rawFilter, matchesMeta),
      defaultValue: getFilterDefault(rawFilter, matchesMeta) as AvailabilityRule
    };
  }, [includedPropertyFilters, matchesMeta]);

  const isAvailabilityFilterOn = !!(availabilityFilter?.value && template?.key);

  return { availabilityFilter, isAvailabilityFilterOn };
};
