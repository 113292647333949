export type PlainSearchHighlight = string[];

export enum ProfilePositionHighlightAttribute {
  Title = "jobTitle",
  Summary = "summary",
  Company = "company"
}

export type ProfilePositionHighlightAttributeKey = `positionJobTitle` | "positionSummary" | "positionCompany";

export enum ProfileSearchHighlightAttribute {
  Skills = "skills",
  MatchInfo1 = "matchInfo1",
  MatchInfo2 = "matchInfo2",
  MatchInfo3 = "matchInfo3",
  Certificates = "certificates",
  MainPosition = "mainPosition",
  Positions = "positions",
  Summary = "summary",
  Name = "name"
}

export type ProfilePositionHighlight = Partial<Record<ProfilePositionHighlightAttribute, string[]>>;

export type ProfileSearchHighlights = Partial<
  Omit<Record<ProfileSearchHighlightAttribute, PlainSearchHighlight>, "mainPosition" | "positions">
> & {
  [ProfileSearchHighlightAttribute.MainPosition]?: ProfilePositionHighlight;
  [ProfileSearchHighlightAttribute.Positions]?: ProfilePositionHighlight;
};

export enum ActivitySearchHighlightAttribute {
  Description = "description",
  Skills = "skills",
  MetadataTextValues = "metadataTextValues"
}

export type ActivitySearchHighlights = Partial<
  Record<ActivitySearchHighlightAttribute, PlainSearchHighlight>
>;

export type SearchHighlightAttribute = ActivitySearchHighlightAttribute | ProfileSearchHighlightAttribute;

export type SearchHighlight = PlainSearchHighlight | ProfilePositionHighlight;

export type SearchHighlights = Partial<ProfileSearchHighlights & ActivitySearchHighlights>;
