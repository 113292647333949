// eslint-disable-next-line max-len
// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontWeights = {
  fontWeightBold: 700,
  fontWeightRegular: 400,
  fontWeightSemibold: 600,
  fontWeightLight: 300
} as const;

export default fontWeights;
