import NotFoundRoute from "PFApp/routes/not_found_route";
import { EnsureLoggedInAndRegistered, WithLayout } from "PFApp/routes/route_helpers";
import RecentSearchesPage from "PFApp/search/recent_searches_page";
import SearchComponent from "PFApp/search/search_component";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const SearchingRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <EnsureLoggedInAndRegistered>
      <WithLayout>
        <Switch>
          <Route exact path={path}>
            <RecentSearchesPage />
          </Route>
          <Route path={`${path}/:id/:tab/:term?`}>
            <SearchComponent />
          </Route>
          <Route path="*">
            <NotFoundRoute />
          </Route>
        </Switch>
      </WithLayout>
    </EnsureLoggedInAndRegistered>
  );
};

export default SearchingRoutes;
