import { pickBy } from "lodash";
import { Activity, Template } from "PFTypes";
import { useCallback, useMemo } from "react";

import { useEngagementSchema } from "./use_engagement_schema";

export const useGetCustomFieldsToLink = () => {
  const { properties } = useEngagementSchema() || { properties: {} };

  const linkableCustomFieldKeys = useMemo(
    () =>
      Object.keys(
        pickBy<Template["schema"]["properties"]>(properties, (property) => property.linked_from_engagement)
      ),
    [properties]
  );

  return useCallback(
    (engagement: Activity) =>
      engagement.custom_fields.filter((customField) =>
        linkableCustomFieldKeys.includes(customField.type.name)
      ),
    [linkableCustomFieldKeys]
  );
};
