import classNames from "classnames";
import { EmphasizedText } from "PFComponents/emphasized_text";
import { Typography } from "PFComponents/typography";
import { PlainSearchHighlight } from "PFTypes";
import React, { Fragment } from "react";

import css from "./search_highlight_plain_row.module.scss";

type SearchHighlightPlainRowProps = {
  attributeDisplayName: string;
  values: PlainSearchHighlight;
  indented?: boolean;
  noMargin?: boolean;
};

export const SearchHighlightPlainRow = ({
  attributeDisplayName,
  values,
  indented,
  noMargin
}: SearchHighlightPlainRowProps) => (
  <Fragment>
    <Typography
      variant={indented ? "labelRegular" : "labelBold"}
      noMargin={noMargin}
      className={classNames({ [css.indented]: indented })}
    >
      {attributeDisplayName}
    </Typography>
    <EmphasizedText
      text={values.join(", ")}
      variant="labelRegular"
      emphasizedVariant="labelBold"
      className={css.values}
    />
  </Fragment>
);
