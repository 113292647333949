import { FiltersPanelProvider } from "PFApp/components/filters/context/filters_panel_context";

import css from "../../../activity_page.module.scss";
import { ShortlistMainPanel } from "./parts/shortlist_main_panel";
import { ShortlistSidePanel } from "./parts/shortlist_side_panel";

export const ActivityShortlist = () => (
  <div className={css.pageCardContainer}>
    <FiltersPanelProvider>
      <ShortlistSidePanel />
      <ShortlistMainPanel />
    </FiltersPanelProvider>
  </div>
);
