import { useQuery } from "@tanstack/react-query";
import { Id } from "PFTypes";

import {
  fetchActivityEconomicsScenario,
  FetchActivityEconomicsScenarioResponse
} from "../../../services/activity";
import activityKeys from "./query_keys";

export const useActivityEconomicsScenario = (
  scenarioId: Id,
  options?: FetchActivityEconomicsScenarioResponse
) =>
  useQuery(activityKeys.economicsScenario(scenarioId), () => fetchActivityEconomicsScenario(scenarioId), {
    retry: false,
    staleTime: 0,
    ...options
  });
