import BookingPill from "PFApp/components/pills/booking_pill";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import {
  OverbookingsManagementMode,
  SuggestedChangeAction
} from "PFCore/services/bookings_suggested_changes";
import { BookingCategory } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import { UpdatedBookingChange } from "../overbookings_management_modal";
import { BookingPillContent } from "./booking_pill_content";
import { ChangedBooking } from "./changed_booking";
import { DeleteBookingAction } from "./delete_booking_action";
import css from "./suggested_changes.module.scss";
import { getTextColor } from "./suggested_changes.utils";
import { useFormatBookingDates } from "./use_format_booking_dates";

type SuggestedChangeProps = {
  bookingChange: UpdatedBookingChange;
  category: BookingCategory;
  mode: OverbookingsManagementMode;
  onToggleRemoveBooking: (index: number) => void;
};

export const SuggestedChanges = ({
  bookingChange,
  category,
  mode,
  onToggleRemoveBooking
}: SuggestedChangeProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking.modal" });
  const { booking } = bookingChange;

  const { formatBookingDates } = useFormatBookingDates();

  const originalBookingDates = formatBookingDates(booking.startDate, booking.endDate);

  const isSingleChange =
    bookingChange.changes.length === 1 && bookingChange.changes[0].action !== SuggestedChangeAction.Delete;

  const isSingleDeleteChange =
    bookingChange.changes.length === 1 && bookingChange.changes[0].action === SuggestedChangeAction.Delete;
  const { getBookingCategory } = useBookingCategories();

  const bookingCategory =
    mode === OverbookingsManagementMode.AdjustCurrent
      ? category
      : getBookingCategory(booking.bookingCategoryId);

  if (!bookingCategory) {
    return null;
  }

  const textColor = getTextColor(bookingCategory);

  return (
    <div className={css.flexColumnContainer}>
      <div className={css.categoryContainer}>
        {isSingleDeleteChange && <Icon name="warning" color="paletteOrange0" />}
        <Typography variant="bodyBold" tag="span">
          {bookingCategory.display_as}
        </Typography>
      </div>

      <div
        className={css.changesContainer}
        style={{
          flexDirection: isSingleChange ? "row" : "column",
          justifyContent: isSingleChange ? "space-between" : "flex-start"
        }}
      >
        <div
          className={css.flexColumnContainer}
          style={{
            width: isSingleChange ? "50%" : "100%"
          }}
        >
          {isSingleDeleteChange ? (
            <DeleteBookingAction
              shouldBeRemoved={bookingChange.shouldBeRemoved}
              onToggle={() => onToggleRemoveBooking(bookingChange.bookingChangeIndex)}
            />
          ) : (
            <Typography variant="bodyRegular" tag="span">
              {t("originalBooking")}
            </Typography>
          )}
          <BookingPill
            title={originalBookingDates}
            category={bookingCategory}
            className={css.bookingPill}
            style={{
              color: textColor,
              borderWidth: 1
            }}
          >
            <BookingPillContent startDate={booking.startDate} endDate={booking.endDate} />
          </BookingPill>
        </div>

        {!isSingleDeleteChange && (
          <div
            className={css.flexColumnContainer}
            style={{
              width: isSingleChange ? "50%" : "100%"
            }}
          >
            <Typography variant="bodyRegular" tag="span">
              {t("newBooking")}
            </Typography>
            {bookingChange.changes.map((change, index) => (
              <ChangedBooking key={index} suggestedChange={change} category={bookingCategory} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
