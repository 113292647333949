import { Template } from "PFTypes";
import { useMemo } from "react";

import { useTemplateFind } from "./use_template_find";

/**
 * @param attributesToCheckOrKeyOrId the param accepts plain key or id
 * partially to simplify calls but mainly to be backward compatible
 * with useTemplate hook previously defined in /core/hooks/templates
 */
export const useTemplate = (
  attributesToCheckOrKeyOrId: Partial<Template> | Template["key"] | Template["id"] | undefined
) => {
  const findTemplate = useTemplateFind();

  return useMemo(() => {
    if (typeof attributesToCheckOrKeyOrId === "object" && attributesToCheckOrKeyOrId !== null) {
      return findTemplate(attributesToCheckOrKeyOrId);
    }

    if (typeof attributesToCheckOrKeyOrId === "number") {
      return findTemplate({ id: attributesToCheckOrKeyOrId });
    }

    return findTemplate({ key: attributesToCheckOrKeyOrId });
  }, [attributesToCheckOrKeyOrId, findTemplate]);
};
