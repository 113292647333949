import { useMutation } from "@tanstack/react-query";
import { decamelizeKeys } from "humps";
import { createOwner, OwnerCreatePayload } from "PFCore/services/owners";
import { Owner } from "PFTypes";
import { MutationOptions } from "PFTypes/request_options";

import { useActivityInvalidate } from "../activity/use_activity_invalidate";

export const useOwnerCreate = ({ onSuccess, ...options }: MutationOptions<OwnerCreatePayload> = {}) => {
  const { invalidate: invalidateActivity } = useActivityInvalidate();

  return useMutation({
    mutationFn: (payload: OwnerCreatePayload) => createOwner(payload),
    onSuccess: (data, ...params) => {
      const owner = decamelizeKeys(data) as Owner;
      owner.activity_id && invalidateActivity([owner.activity_id]);
      onSuccess?.(owner, ...params);
    },
    ...options
  });
};
