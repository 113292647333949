import { get } from "lodash";

import { TableColumn, TableItem } from "./table_types";

export const columnSizeToClass = (size?: 1 | 2 | 3) => {
  const SIZES = {
    1: "small",
    2: "medium",
    3: "big"
  };

  return size ? SIZES[size] : "medium";
};

export const getRawValue = (item: TableItem, column: TableColumn) =>
  column.attribute ? get(item, column.attribute) : item[column.name];
