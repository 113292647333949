import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import ButtonLink from "PFComponents/button/button_link";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useTranslation } from "react-i18next";

type ViewInBookingModuleButtonType = {
  openAllocateManually?: boolean;
  workforceMemberId?: number;
  className?: string;
  classes?: {
    root?: string;
    inner?: string;
    hover?: string;
  };
};

const ViewInBookingModuleButton = ({
  openAllocateManually,
  className,
  workforceMemberId
}: ViewInBookingModuleButtonType) => {
  const { data: currentProfile } = useCurrentProfile();
  const { task } = useActivityPageContext();
  const { t } = useTranslation("activities");
  const templateKey = currentProfile.templates.find(({ id }) => id === task?.template_id)?.key;

  const searchParams = new URLSearchParams({
    activityId: task?.id?.toString() || "",
    openAllocateManually: openAllocateManually?.toString() || "",
    templateKey: templateKey || "",
    workforceMemberId: workforceMemberId?.toString() || ""
  });

  return (
    <Tooltip content={t("show.shortlist.viewInBookingModule")}>
      <div>
        <ButtonLink
          href={`/booking?${searchParams.toString()}`}
          kind="secondary"
          rootClassName={className}
          title={t("show.shortlist.viewInBookingModule")}
        >
          <Icon name="booking" size="sm" />
        </ButtonLink>
      </div>
    </Tooltip>
  );
};

export default ViewInBookingModuleButton;
