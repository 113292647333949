import { useTemplate } from "PFApp/hooks";
import { AVAILABILITY_MODES } from "PFComponents/availability";
import { isAvailabilityRule } from "PFCore/helpers/filters/type_checks";
import { AvailabilityRule, FilterWithDefaults, Template } from "PFTypes";
import { useMemo } from "react";

export const useAvailabilityDefaults = (filter: FilterWithDefaults, templateKey: Template["key"]) => {
  const template = useTemplate(templateKey);

  const defaultAvailability = useMemo(
    () =>
      isAvailabilityRule(filter.defaultValue)
        ? filter.defaultValue
        : ({
            availability_threshold: template?.schema.properties?.availability?.default_threshold || 70,
            mode: template?.schema.properties?.availability?.default_mode,
            ranges: []
          } as AvailabilityRule),
    [
      filter.defaultValue,
      template?.schema.properties?.availability?.default_mode,
      template?.schema.properties?.availability?.default_threshold
    ]
  );

  return {
    defaultAvailability,
    allowedModes: template?.schema.properties?.availability?.allowed_modes || AVAILABILITY_MODES
  };
};
