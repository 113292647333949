import Panel from "PFComponents/panel/panel";
import { BookingCategory, Template } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BookingCategorySelect } from "../../../booking/components/booking_category_select";
import { Note } from "../components/note";
import css from "./sections.module.scss";

type BookingCategorySectionProps = {
  qaIdPrefix: string;
  template: Template;
  setBookingCategory: (category: BookingCategory) => void;
  bookingCategory?: BookingCategory | null;
  errors: Record<string, string>;
};

export const BookingCategorySection = ({
  template,
  bookingCategory,
  setBookingCategory,
  qaIdPrefix,
  errors
}: BookingCategorySectionProps) => {
  const { t } = useTranslation(["activities", "translation"]);
  const { title } = template.schema.properties.booking_category_id;

  return (
    <Panel className={css.wrapper} qaId={`${qaIdPrefix}-bookingCategory`}>
      <div className={css.header}>{title}</div>
      <BookingCategorySelect
        onCategoryChange={setBookingCategory}
        category={bookingCategory ?? null}
        label={t("translation:selectEllipsis")}
        error={errors["booking_category_id"]}
        demand
        letClear
        restrictedTextWidth
      />
      <Note>{t("activities:edit.sections.addBookingCategoryDescription")}</Note>
    </Panel>
  );
};
