import { useTranslation } from "react-i18next";

import { useActivityTemplate } from "./use_activity_template";

export const useTranslatedActivityType = () => {
  const template = useActivityTemplate();

  const { t } = useTranslation("activities", { keyPrefix: "templates" });
  return template ? t(template.key) : null;
};
