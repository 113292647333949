// eslint-disable-next-line max-len
// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontSizes = {
  fontSizeH1: "2rem",
  fontSizeH2: "1.75rem",
  fontSizeH3: "1.5rem",
  fontSizeH4: "1.25rem",
  fontSizeH6: "0.9375rem",
  fontSizeH5: "1rem",
  fontSizeRegular: "0.875rem",
  fontSizeLabel: "0.75rem",
  fontSizeLabelSmall: "0.625rem"
} as const;

export default fontSizes;
