import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import ProfileCell from "PFApp/components/table/profile_cell";
import { TableColumn } from "PFApp/components/table/table_types";
import { useProfilePreview } from "PFApp/use_profile_preview";
import { Markdown } from "PFComponents/markdown";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { Activity, Profile } from "PFTypes";
import { useCallback } from "react";

import { IRoleWatcher } from "../../../../../../types/watchers";
import AddShortlistButton from "../../../../../parts/add_shortlist_button";
import css from "../interested_rows.module.scss";
import Questions from "../interested_table_cells/questions";

const useInterestedCellRenderer = () => {
  const { task } = useActivityPageContext() as { task: Activity };
  const { showPreview } = useProfilePreview();

  const { invalidateWatchers } = useActivityInvalidate();

  return useCallback(
    (watcher: IRoleWatcher, column: TableColumn) => {
      const handleProfileClick = (watcher: IRoleWatcher) => {
        showPreview({
          id: watcher.profile.id,
          profile: watcher.profile as unknown as Profile,
          showBookingButtons: true
        });
      };

      if (column.name === "questions") {
        return <Questions watcher={watcher} />;
      } else if (column.name === "shortlist") {
        return (
          <AddShortlistButton
            profile={watcher.profile}
            task={task}
            onClick={() => invalidateWatchers(task.id)}
          />
        );
      } else if (column.name === "name") {
        return <ProfileCell profile={watcher.profile as any} onClick={() => handleProfileClick(watcher)} />;
      } else if (column.name === "statement") {
        return (
          <Markdown raw={watcher.statement || ""} crop={150} className={css.statement} emojify={false} />
        );
      }
    },
    [task, showPreview, invalidateWatchers]
  );
};

export default useInterestedCellRenderer;
