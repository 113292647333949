export const THEME_VARS_ACTIONS = {
  INJECT_STYLES: "INJECT_STYLES"
};

export const themeVarsReducer = (state, { type, payload }) => {
  switch (type) {
    case THEME_VARS_ACTIONS.INJECT_STYLES: {
      return {
        ...state,
        themeVars: payload
      };
    }
    default:
      return state;
  }
};
