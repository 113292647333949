import classNames from "classnames";
import { SearchInput } from "PFApp/components/search_input";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { DropdownButton } from "PFComponents/dropdown_button";
import { Icon } from "PFComponents/icon";
import BubbleIcon from "PFIcons/bubble.svg";
import TreeMapIcon from "PFIcons/tree_map.svg";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { SelectedChart } from "./skills_chart_section";
import css from "./skills_chart_section.modules.scss";

type SkillsChartActionsProps = {
  isAllowedToEdit: boolean;
  searchTerm: string | null;
  isListVisible: boolean;
  skillsExplorerAvailable: boolean;
  selectedChart: SelectedChart;
  setSelectedChart: React.Dispatch<React.SetStateAction<SelectedChart>>;
  onListClick: () => void;
  onChartChange: () => void;
  onTermChange: (newTerm: string) => void;
};

export const SkillsChartActions = ({
  isAllowedToEdit,
  searchTerm,
  skillsExplorerAvailable,
  isListVisible,
  selectedChart,
  setSelectedChart,
  onListClick,
  onChartChange,
  onTermChange
}: SkillsChartActionsProps) => {
  const history = useHistory();
  const chartOptions = [
    {
      id: "drilldown",
      displayElement: (
        <div className={css.dropdownIcon}>
          <TreeMapIcon height={20} width={20} />
        </div>
      ),
      item: () => setSelectedChart("drilldown")
    },
    {
      id: "bubble",
      displayElement: (
        <div className={css.dropdownIcon}>
          <BubbleIcon height={20} width={20} />
        </div>
      ),
      item: () => setSelectedChart("bubble")
    }
  ];
  const chartIcon = chartOptions.find(({ id }) => id === selectedChart)?.displayElement;

  return (
    <div className={css.actionsWrapper}>
      {isAllowedToEdit && (
        <Link to={`/profiles/me/summary/skills`}>
          <Button icon="add" onClick={(fn) => fn} />
        </Link>
      )}
      <SearchInput
        term={searchTerm ?? ""}
        pageKey={RecentSearchesPageKey.ProfileSkills}
        onTermChange={onTermChange}
        classes={{ root: css.searchInput }}
      />
      <div className={css.buttons}>
        {skillsExplorerAvailable && (
          <>
            <DropdownButton
              buttonStyle={{ opacity: isListVisible ? 0.3 : 1 }}
              dropDownStyle={{ minWidth: 70 }}
              buttonKind="blank"
              options={chartOptions}
              popperOptions={{
                placement: "bottom",
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [-2, 0]
                    }
                  }
                ]
              }}
              handleChange={(fn) => {
                fn && fn();
                onChartChange();
              }}
            >
              <div className={css.dropdownIcons}>
                {chartIcon}
                <Icon name="caret-down" size="sm" />
              </div>
            </DropdownButton>
          </>
        )}
        <ActionIcon
          name="list"
          onClick={onListClick}
          classes={{
            icon: classNames({ [css.iconInactive]: !isListVisible })
          }}
        />
        {isAllowedToEdit && (
          <ActionIcon name="edit" size="sm" onClick={() => history.push("/profiles/me/summary/skills")} />
        )}
      </div>
    </div>
  );
};
