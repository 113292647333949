import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

export const MsTeamsRootPage = () => {
  const { isSignedIn } = useCurrentProfile();

  if (isSignedIn) {
    return <MsTeamsRedirect />;
  } else {
    return <Redirect to="/ms_teams/signin" />;
  }
};

const MsTeamsRedirect = () => {
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    if (window.microsoftTeams) {
      window.microsoftTeams.getContext((context) => {
        const lastVisitedPath = storage.getItem("ms_teams_last_visited_path");
        storage.removeItem("ms_teams_last_visited_path");
        setRedirectPath(context.subEntityId || lastVisitedPath || "/");
      });
    } else {
      setRedirectPath("/");
    }
  }, []);

  return redirectPath ? <Redirect to={redirectPath} /> : null;
};
