import { useTemplateConfiguration } from "PFApp/hooks";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { ActivityProfile, FeatureFlag } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useActivityEditFormContext } from "../activity_edit_form_context";
import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import { useGrowlFormError } from "../hooks";
import { useActivityEditForm } from "../hooks/use_activity_edit_form";
import Assignee from "./assignee";
import { BookingCategorySection } from "./booking_category_section";
import CommonSideSection from "./common_side_section";
import { ExpiryDateSection } from "./expiry_date_section/expiry_date_section";
import ShowInterestQuestions from "./show_interest_questions";

type Assignee = ActivityProfile["profile"] | null;

type ActivityEditFormSidePanelProps = {
  qaIdPrefix: string;
};

export const ActivityEditFormSidePanel = ({ qaIdPrefix }: ActivityEditFormSidePanelProps) => {
  const isEnabled = useIsFeatureEnabled();
  const { questionsToggleValue, questions, showQuestionsTile } = useActivityEditFormContext();
  const { handleUploadRemove, handleQuestionChange, handleQuestionsToggleChange, handlePrivateChange } =
    useActivityEditForm();
  const renderGrowlError = useGrowlFormError();

  const isBookingModuleEnabled = isEnabled(FeatureFlag.BookingModule);
  const { t } = useTranslation("activities");

  const { activity, template } = useActivityEditPageContext();
  const templateConfig = useTemplateConfiguration(template);
  const {
    isNew,
    isEdit,
    isDraft,
    isRole,
    isEngagement,
    isForceVisibilitySet,
    isForcePrivate,
    isAssigneeRequired
  } = useActivityEditPageFlags();

  const {
    errors,
    audiences,
    setAudiences,
    assignee,
    setAssignee,
    attachments,
    setAttachments,
    isPrivate,
    setIsPrivate,
    communicationType,
    setCommunicationType,
    bookingCategory,
    setBookingCategory
  } = useActivityEditFormContext();

  const showBookingCategorySection =
    isBookingModuleEnabled && !!template.schema.properties.booking_category_id;

  return (
    <div>
      <ExpiryDateSection isEditMode={isEdit} qaIdPrefix={qaIdPrefix} />
      {(isEngagement || isRole) && (
        <Assignee
          assignee={assignee}
          isRequired={isAssigneeRequired}
          errors={errors}
          onChange={setAssignee}
        />
      )}
      {showBookingCategorySection && (
        <BookingCategorySection
          template={template}
          qaIdPrefix={qaIdPrefix}
          setBookingCategory={setBookingCategory}
          bookingCategory={bookingCategory}
          errors={errors}
        />
      )}
      <CommonSideSection
        template={template}
        activity={activity}
        attachments={attachments}
        audiences={audiences}
        qaIdPrefix={qaIdPrefix}
        handleUploadAdded={(attachment) => setAttachments([...attachments, attachment])}
        handleUploadSuccessful={(attachment) => setAttachments([...attachments, attachment])}
        handleUploadFailed={() => renderGrowlError(t("edit.failedUploadAttachment"))}
        handleUploadRemove={handleUploadRemove}
        isPrivate={isPrivate}
        setIsPrivate={handlePrivateChange}
        handleAudiencesChange={setAudiences}
        onPrivateChange={setIsPrivate}
        communicationType={communicationType}
        handleCommunicationChange={setCommunicationType}
        isForceVisibilitySet={isForceVisibilitySet}
        isForcePrivate={isForcePrivate}
        isDraft={isDraft}
        isNew={isNew}
        errors={errors}
      />
      {showQuestionsTile && (
        <ShowInterestQuestions
          isToggleVisible={isNew && !!templateConfig?.allow_rm_to_enable}
          toggleValue={questionsToggleValue}
          questions={questions}
          areQuestionsEditable={isNew && !!templateConfig?.allow_rm_to_edit}
          errors={errors}
          onQuestionChange={handleQuestionChange}
          onToggleChange={handleQuestionsToggleChange}
        />
      )}
    </div>
  );
};
