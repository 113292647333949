import { PROFILE_PREVIEW_ACTIONS } from "PFReducers/profile_preview_reducer";

export default function showProfile(handleMinimize, e, profileId, dispatch) {
  // this media query must match css one (#chat_region and .chat_root)
  if (handleMinimize && window.matchMedia("(max-width: 560px)").matches) {
    handleMinimize(e);
  }

  dispatch({
    type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_SHOW,
    payload: { id: profileId }
  });
}
