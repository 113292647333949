import { isInSections } from "PFCore/helpers/custom_type";
import {
  activityCustomTypesAccessLevels,
  customTypesAccessLevelsAgainstProfile
} from "PFCore/helpers/custom_types";
import { isAdmin } from "PFCore/helpers/profile";
import { AccessLevel, CurrentProfile } from "PFTypes";
import { TFunction } from "react-i18next";

import { CustomValuesEditFieldProps } from "./custom_values_edit_field";

type GetCanEditCustomFieldData = Pick<
  CustomValuesEditFieldProps,
  "adminPage" | "customType" | "profile" | "position" | "isActivity" | "forceUnlocked"
> & { currentProfile: CurrentProfile; t: TFunction<"translation"> };

type GetCanEditCustomFieldDataReturn = {
  locked: boolean;
  lockedTip: string;
};

export const getCanEditCustomFieldData = ({
  adminPage,
  customType,
  profile,
  position,
  isActivity = false,
  forceUnlocked = false,
  currentProfile,
  t
}: GetCanEditCustomFieldData): GetCanEditCustomFieldDataReturn => {
  if (forceUnlocked || !customType) {
    return { locked: false, lockedTip: "" };
  }

  let level: AccessLevel | undefined = undefined;
  if (position && profile) {
    const profilePGId = profile.permissions_group.id;
    const field = `${position.main ? "main_position" : "other_positions"}/${customType.name}`;
    level =
      currentProfile.permissions_group.cross_group_permissions[profilePGId].profile_fields_access_levels[
        field
      ];
  } else if (isActivity) {
    level = activityCustomTypesAccessLevels(currentProfile)[customType.id];
  } else if (profile) {
    // we lock in Profile Page/Admin Page following the cross group rules (or your own if it's your profile)
    level = customTypesAccessLevelsAgainstProfile(profile, currentProfile)[customType.id];
  }

  const lockedBecausePermissions = level !== AccessLevel.ReadWrite; // ReadOnly are locked, as is anything else (foo, undefined, '')
  const lockedBecauseNotAdmin = !adminPage && !!isInSections("editable_only_by_admins", customType);

  let lockedTip = t("customValues.tips.lockedTip");
  if (lockedBecausePermissions) {
    lockedTip = t("customValues.tips.noPermissionsTip");
  } else if (isAdmin(currentProfile)) {
    lockedTip = t("customValues.tips.lockedAdminTip");
  }

  return {
    locked: lockedBecausePermissions || lockedBecauseNotAdmin,
    lockedTip
  };
};
