import { Alert } from "PFComponents/alert";
import { Typography } from "PFComponents/typography";
import { BookingData } from "PFCore/services/bookings_suggested_changes";
import { BookingCategory } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import BookingPill from "../../../../components/pills/booking_pill";
import { BookingPillContent } from "../suggested_changes/booking_pill_content";
import { getTextColor } from "../suggested_changes/suggested_changes.utils";
import css from "./no_permission_info.module.scss";

type NoPermissionInfoProps = {
  category: BookingCategory;
  currentBookings: BookingData[];
};

export const NoPermissionInfo = ({ category, currentBookings }: NoPermissionInfoProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking.modal" });
  const textColor = getTextColor(category);

  return (
    <div className={css.root}>
      <Alert small kind="warning">
        {t("noPermission")}
      </Alert>
      <div className={css.bookingContainer}>
        <Typography variant="bodyRegular" tag="span">
          {t("originalBooking")}
        </Typography>
        {currentBookings.map((booking, index) => (
          <BookingPill
            key={index}
            title={t("originalBooking")}
            category={category}
            className={css.bookingPill}
            style={{
              color: textColor
            }}
          >
            <BookingPillContent startDate={booking.startDate} endDate={booking.endDate} />
          </BookingPill>
        ))}
      </div>
    </div>
  );
};
