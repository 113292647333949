import css from "PFApp/profiles/edit/nav/profile_edit_nav.less";
import { Card } from "PFComponents/card";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ProfileEditNavBox } from "./parts/profile_edit_nav_box";
import { ProfileEditNavLinks } from "./parts/profile_edit_nav_links";

const ProfileEditNav = (props) => {
  const { adminPage } = props;
  const { t } = useTranslation("profiles");

  return (
    <Card>
      <div className={css.root} data-qa-id="ProfileEditNav">
        <h3>{t("edit.nav.inThisSection")}</h3>

        <ProfileEditNavLinks {...props} placement="above" />

        {adminPage && (
          <ProfileEditNavBox title={t("edit.nav.manageYourProfile")}>
            <ProfileEditNavLinks {...props} placement="manage" />
          </ProfileEditNavBox>
        )}

        {adminPage && (
          <ProfileEditNavBox title={t("edit.nav.displayedInformation")}>
            <ProfileEditNavLinks {...props} placement="displayed_info" />
          </ProfileEditNavBox>
        )}

        <ProfileEditNavBox title={t("edit.nav.settings")}>
          <ProfileEditNavLinks {...props} placement="settings" />
        </ProfileEditNavBox>
      </div>
    </Card>
  );
};

ProfileEditNav.propTypes = {
  currentSection: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
  adminPage: PropTypes.bool.isRequired
};

export default ProfileEditNav;
