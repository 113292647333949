export const TEMPLATE_KEYS = {
  COURSE: "course",
  EVENT: "event",
  POSITION: "position",
  PROJECT: "project",
  QUESTION: "question",
  ROLE: "role",
  SUGGESTION: "suggestion",
  POST: "post",
  LESSON: "lesson",
  ENGAGEMENT: "engagement",
  TASK: "task",
  WORK: "work",
  AUDIT_ROLE: "audit_role",
  AUDIT_ENGAGEMENT: "audit_engagement"
};
