import { useQuery } from "@tanstack/react-query";
import {
  fetchConversations,
  FetchConversationsPayload,
  FetchConversationsResponse
} from "PFCore/services/chat/fetch_conversations";
import { QueryOptions } from "PFTypes/request_options";

import { chatKeys } from "./query_keys";

export const useChatConversations = (
  payload: FetchConversationsPayload,
  options: QueryOptions<FetchConversationsResponse>
) => {
  const queryKey = payload.target
    ? chatKeys.conversationsWithTarget(payload.target, payload)
    : chatKeys.conversations(payload);

  return useQuery<FetchConversationsResponse>(queryKey, () => fetchConversations(payload), {
    ...options,
    placeholderData: {
      entries: [],
      meta: { total: 0 }
    }
  });
};
