import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";
import { ReactElement } from "react";

import css from "./history_entry_profile.module.scss";
import { HistoryEntryProfileAvatar } from "./history_entry_profile_avatar";

type HistoryEntryProfileProps = {
  profile: Profile;
  title?: ReactElement;
  subtitle?: string;
  showAvatar?: boolean;
};

export const HistoryEntryProfile = ({
  profile,
  title,
  subtitle,
  showAvatar = true
}: HistoryEntryProfileProps) => (
  <div className={css.profile}>
    {showAvatar && <HistoryEntryProfileAvatar profile={profile} />}
    <div className={css.profileDetails}>
      {title || (
        <Typography variant={"bodyBold"} noMargin>
          {getProfileName(profile)}
        </Typography>
      )}
      <Typography
        variant={"bodyRegular"}
        noMargin
        title={subtitle ? undefined : profile.email}
        clipOverflow={!!profile.email}
      >
        {subtitle || profile.email}
      </Typography>
    </div>
  </div>
);
