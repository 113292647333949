import { Template } from "PFTypes";
import { useContext, useMemo } from "react";

import AppContext from "../app_context";

export const useTemplateConfiguration = (template: Pick<Template, "key"> | undefined) => {
  const {
    store: { templatesConfiguration }
  } = useContext(AppContext);

  return useMemo(
    // eslint-disable-next-line camelcase
    () => templatesConfiguration.find(({ template_key }) => template_key === template?.key),
    [template?.key, templatesConfiguration]
  );
};
