import { compact, last, upperFirst } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useItemsEnumerate = () => {
  const { t } = useTranslation("core");

  return useCallback(
    (items: string[]): string => {
      const itemsWithoutLast = items.slice(0, -1).join(", ");
      const lastItem = last(items);

      return upperFirst(compact([itemsWithoutLast, lastItem]).join(` ${t("and")} `));
    },
    [t]
  );
};
