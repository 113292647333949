import SidePanel from "PFComponents/side_panel/side_panel";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import React from "react";
import { useTranslation } from "react-i18next";

import { DetailsPanelComponentProps, DetailsSidePanelProps } from "../../details_panel";
import { ProfileDetailHeader } from "../profile_detail/profile_detail_header";
import { OverbookingsDetail } from "./overbookings_detail/overbookings_detail";

type OverbookingsDetailSidePanelProps = DetailsSidePanelProps & DetailsPanelComponentProps;

export const OverbookingsDetailSidePanel = React.memo(
  ({
    isOnTop,
    show,
    data,
    onEdited,
    onDeleted,
    onClose,
    zIndex,
    goToDate
  }: OverbookingsDetailSidePanelProps) => {
    const { t } = useTranslation("bookingModule", { keyPrefix: "details.overbookings" });

    const { data: profile, isLoading: isProfileLoading } = useProfile(data?.id, {
      enabled: show && !!data?.id
    });

    return (
      <SidePanel
        show={show}
        zIndex={zIndex}
        isSidePanelClosing={data.isClosing}
        onClose={onClose}
        title={<ProfileDetailHeader profile={profile} subtitle={t("label")} />}
        isOnTop={isOnTop}
      >
        <OverbookingsDetail
          profile={profile}
          isProfileLoading={isProfileLoading}
          data={data}
          onEdited={onEdited}
          onDeleted={onDeleted}
          goToDate={goToDate}
        />
      </SidePanel>
    );
  }
);

OverbookingsDetailSidePanel.displayName = "OverbookingsDetailSidePanel";
