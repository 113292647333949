import { useTemplate } from "PFApp/hooks";
import { getActiveActivityProfiles } from "PFCore/helpers/get_active_activity_profiles";
import { Activity, Profile } from "PFTypes";
import { useMemo } from "react";

import { getOrderedActivityProfiles } from "../helpers/get_ordered_activity_profiles";

type ReviewersResult = {
  reviewers: Profile[];
  canView: boolean;
  canWrite: boolean;
};

export const useReviewers = (activity?: Activity): ReviewersResult => {
  const activityType = useTemplate(activity?.template_id);
  const reviewers = useMemo(
    () =>
      getOrderedActivityProfiles(
        getActiveActivityProfiles(activity?.reviewers || []).map((item) => item.profile)
      ),
    [activity?.reviewers]
  );

  const isRole = activityType?.key === "role";
  const canView = reviewers.length > 0;

  const canWrite = isRole && !!activity?.coowned;

  return { reviewers, canView, canWrite };
};
