import importExternalScript from "PFCore/helpers/import_external_script";
import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag } from "PFTypes";

const { ga, PF } = window;
class GoogleAnalytics {
  constructor(userId) {
    importExternalScript("https://www.google-analytics.com/analytics.js").then(() => {
      ga("create", {
        trackingId: PF?.config?.google_analytics_key,
        cookieDomain: "auto",
        userId
      });

      return ga("send", "pageview");
    });
  }

  page(title, path) {
    if (typeof ga === "function") {
      ga("set", "page", path);
    }
    return typeof ga === "function" ? ga("send", "pageview") : undefined;
  }

  track(ev_name, options) {
    return typeof ga === "function"
      ? ga("send", {
          hitType: "event",
          eventCategory: options.category,
          eventAction: options.action,
          eventLabel: ev_name
        })
      : undefined;
  }

  isEnabled() {
    return !(typeof ga === "undefined" || ga === null);
  }
}

export default class Analytics {
  constructor(currentAccount, userId) {
    const isEnabled = isFeatureEnabled(currentAccount);

    this.__implementation =
      PF?.config.google_analytics_key && isEnabled(FeatureFlag.GoogleAnalytics)
        ? new GoogleAnalytics(userId)
        : null;
  }

  page(title, path) {
    return this.__implementation !== null ? this.__implementation.page(title, path) : undefined;
  }

  track(ev_name, options = {}) {
    return this.__implementation !== null ? this.__implementation.track(ev_name, options) : undefined;
  }

  isEnabled() {
    return this.__implementation !== null ? this.__implementation.isEnabled() : undefined;
  }
}
