import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { FeatureFlag, PermissionRule } from "PFTypes";

export const useBookingModulePermissions = () => {
  const { data: currentProfile } = useCurrentProfile();

  const isPermittedTo = useIsCurrentUserPermittedTo();
  const isEnabled = useIsFeatureEnabled();
  const isBMEnabled = isEnabled(FeatureFlag.BookingModule);
  const isPermittedToEdit = isPermittedTo(PermissionRule.BookingResourcer);
  const isBookingResourcer = isPermittedTo(PermissionRule.BookingResourcer); // can manage demand, repeated, group bookings, as well as different users bookings
  const isPermittedToAccess = isPermittedTo(PermissionRule.BookingAccess);

  const canProfileCreateBooking = (profileId?: number) =>
    isBMEnabled && (isPermittedToEdit || (isPermittedToAccess && profileId === currentProfile.id));

  return {
    isEnabled: isBMEnabled,
    isPermittedToEdit,
    isPermittedToAccess,
    isBookingResourcer,
    isAdmin: isPermittedTo(PermissionRule.BookingAdmin),
    canProfileCreateBooking
  };
};

export default useBookingModulePermissions;
