import api from "PFCore/api";
import { Collection, Id, SkillsFramework, SkillsFrameworkRank } from "PFTypes";

export type SkillsFrameworksOptionsResponse = Collection<SkillsFramework[]>;

export type FetchSkillsFrameworksOptionsParams = {
  term?: string;
  rank?: SkillsFrameworkRank;
  ids?: Id[];
};

export const fetchSkillsFrameworksOptions = (
  params: FetchSkillsFrameworksOptionsParams = {}
): Promise<SkillsFrameworksOptionsResponse> =>
  api({
    url: "options/skills_frameworks",
    params
  });
