import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import ChampionsIcon from "PFIcons/champions.svg";
import { CSSProperties } from "react";

import { Carousel } from "../carousel";
import { useCarousel } from "../use_carousel";
import ChampionsCard from "./card_kinds/champions_card";
import CourseCard from "./card_kinds/course_card";
import FeedCard from "./card_kinds/feed_card";
import css from "./carousel_card.module.scss";

type CardItem = Partial<{
  title: string;
  description: string;
  imageURL: string;
  url: string;
  skills: any[];
  [key: string]: any;
}>;

type CarouselCardProps = {
  itemsCollection: CardItem[];
  title: string;
  kind: "feed" | "course" | "champion";
  subtext?: string;
  style?: CSSProperties;
  noItemsAction?: VoidFunction;
  noItemsLabel: JSX.Element | string;
  visibleItems?: number;
};

// TODO: [ENG-3090] Refactor CarouselCard component to be reusable
export const CarouselCard = ({
  itemsCollection,
  title,
  kind,
  subtext,
  style,
  noItemsAction,
  noItemsLabel,
  visibleItems = 1
}: CarouselCardProps) => {
  const { activeIndex, slideBack, slideForward } = useCarousel({ maxIndex: itemsCollection.length - 1 });

  const Card = {
    feed: FeedCard,
    course: CourseCard,
    champion: ChampionsCard
  }[kind];

  const items = itemsCollection.map((item, index) => ({
    id: index,
    content: (
      <div className={css.card}>
        <Card item={item} />
      </div>
    )
  }));

  return (
    <div className={classNames(css.wrapper, css[kind])} style={style}>
      <div className={css.header}>
        <div className={css.title}>
          {kind === "champion" && <ChampionsIcon />}
          {title}
        </div>
        <div className={css.arrows}>
          <ActionIcon onClick={slideBack} name="chevron-left" size="sm" />
          <ActionIcon onClick={slideForward} name="chevron-right" size="sm" />
        </div>
      </div>
      {subtext && <div className={css.subtext}>{subtext}</div>}
      <Carousel activeIndex={activeIndex} items={items} visibleItems={visibleItems} />
      {!itemsCollection.length && (
        <Button className={css.noItems} onClick={noItemsAction}>
          {noItemsLabel}
        </Button>
      )}
    </div>
  );
};
