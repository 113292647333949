import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";

type SendInvitationPayload = {
  targetIds: number[];
  profileIds: number[];
  targetType: string;
};

type SendInvitationResponse = {
  existing: any[];
  saved: any[];
  unauthorized: any[];
};

export const sendInvitation = ({
  targetIds,
  targetType,
  profileIds
}: SendInvitationPayload): jqXHR<SendInvitationResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute("/api/invites"),
    api_version: 2,
    method: "POST",
    data: {
      target_ids: targetIds,
      target_type: targetType,
      profile_ids: profileIds
    }
  });
