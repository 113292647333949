import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSavedFilter, SavedFiltersResponse } from "PFCore/services/saved_filters";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";
import { PageTarget } from "PFTypes/saved_filters";

import { savedFiltersKeys } from "./query_keys";

export const useSavedFiltersDelete = (
  target: PageTarget,
  { onSuccess, ...queryParams }: MutationOptions<Id, null> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: Id) => deleteSavedFilter(id),
    onSuccess: (...args) => {
      const removedId = args[1];
      queryClient.setQueryData(savedFiltersKeys.target(target), (savedFilters: SavedFiltersResponse) =>
        savedFilters.filter(({ id }) => id !== removedId)
      );

      onSuccess?.(...args);
    },
    ...queryParams
  });
};
