import classNames from "classnames";
import { Button } from "PFComponents/button";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./footer.module.scss";

const Footer = ({ startDate, endDate, onReset, isRegularText }) => {
  const { t } = useTranslation();
  const { formatDate } = useDateFormatter();

  if (!startDate) {
    return null;
  }

  return (
    <span
      className={classNames(css.footer, {
        [css.smallText]: !isRegularText
      })}
    >
      {!!endDate && (
        <>{startDate === endDate ? startDate : `${formatDate(startDate)} - ${formatDate(endDate)}`}</>
      )}
      {!endDate && (
        <>
          {t("selectEndDate", { date: formatDate(startDate) })}
          <Button kind="blank" title={t("resetDates")} rootClassName={css.footerCancel} onClick={onReset}>
            &times;
          </Button>
        </>
      )}
    </span>
  );
};

Footer.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onReset: PropTypes.func,
  isRegularText: PropTypes.bool
};

export default Footer;
