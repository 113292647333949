export type JsonValue = string | number | boolean | null | JsonObject | JsonArray | Function | undefined;

interface JsonObject {
  [key: string]: JsonValue;
}

interface JsonArray extends Array<JsonValue> {}

const sortJsonObject = (object: JsonObject): JsonObject => {
  const sorted: JsonObject = {};
  for (const key of Object.keys(object).sort()) {
    sorted[key] = serializeJsonValue(object[key]);
  }
  return sorted;
};

const serializeJsonValue = (value: JsonValue): JsonValue => {
  if (typeof value === "object" && value !== null) {
    if (Array.isArray(value)) {
      return value.map(serializeJsonValue);
    } else {
      return sortJsonObject(value);
    }
  } else {
    return value;
  }
};

export const stringify = (json: JsonValue): string => JSON.stringify(serializeJsonValue(json));

export const useDeterministicStringify = (): ((json: JsonValue) => string) => stringify;
