import classNames from "classnames";
import PropTypes from "prop-types";

import css from "./panel.less";

const Panel = ({ children, style, className, qaId, id }) => (
  <div className={classNames(css.panel)} id={id}>
    <div className={classNames(css.panelBody)}>
      <div className={className} style={style} data-qa-id={qaId}>
        {children}
      </div>
    </div>
  </div>
);

export default Panel;

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  qaId: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
};
