import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MoreButton = ({ renderedItemsCount, total, handleClick, isLoading, perPage, page }) => {
  const { t } = useTranslation();
  // the meta.total returned from api might not match the actual total ammount of entities
  // returned by the api - hence the addtional checks
  const isAll = renderedItemsCount >= total;
  const isALastPageOrGreater = page >= Math.max(total, renderedItemsCount) / perPage;

  if (isAll || isALastPageOrGreater) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: 30 }}>
      {isLoading ? (
        <LoadingDots circlesEnabled circleSize="xs" />
      ) : (
        <Button
          key={renderedItemsCount /*avoid hover state on mobiles*/}
          kind="secondary"
          small
          onClick={handleClick}
          disabled={isLoading}
        >
          {t("more")}
        </Button>
      )}
    </div>
  );
};

MoreButton.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  renderedItemsCount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default MoreButton;
