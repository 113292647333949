import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./internal_experiences_box.module.scss";

export const InternalExperiencesBox = ({ internalExperiences, profileId, closeOnClick }) => {
  const { t } = useTranslation("core", { keyPrefix: "skillBadge.expandable" });

  if (internalExperiences.length === 0) {
    return null;
  }

  return (
    <div>
      {internalExperiences?.map((item, index) => (
        <div key={item?.id}>
          <div className={css.wrapper}>
            <div>{t("roleName")}</div>
            <Link
              className={css.link}
              to={`/profiles/${profileId}/experience/${item.id}`}
              onClick={() => closeOnClick && closeOnClick()}
            >
              {item?.name}
            </Link>
          </div>
          {item?.metadata?.engagement_hours && (
            <div>{`${t("hours")} ${item?.metadata?.engagement_hours}`}</div>
          )}
          {index < internalExperiences?.length - 1 && <div className={css.line} />}
        </div>
      ))}
    </div>
  );
};

InternalExperiencesBox.propTypes = {
  internalExperiences: PropTypes.array,
  profileId: PropTypes.number,
  closeOnClick: PropTypes.func
};
