import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFTypes";

import activityKeys from "./query_keys";

export const useActivityEconomicsScenarioInvalidate = () => {
  const cache = useQueryClient();
  return {
    invalidate: (scenarioId: Id) =>
      cache.invalidateQueries({
        queryKey: activityKeys.economicsScenario(scenarioId)
      })
  };
};
