import classNames from "classnames";
import { Icon } from "PFCore/components/icon";
import { Typography } from "PFCore/components/typography/typography";

import css from "./note_counter.module.scss";

type NoteCounterProps = {
  counterValue: number;
  maxLength: number;
};

export const NoteCounter = ({ counterValue, maxLength }: NoteCounterProps): JSX.Element => {
  const limitExceeded = counterValue > maxLength;
  return (
    <div className={css.root}>
      {limitExceeded && <Icon name="warning" color="paletteRed1" />}
      <Typography
        variant="bodyRegular"
        tag="span"
        className={classNames({
          [css.error]: limitExceeded,
          [css.waggleAnimation]: limitExceeded
        })}
      >
        {counterValue}/{maxLength}
      </Typography>
    </div>
  );
};
