export const PROXIED_ENVS = ["staging", "integration", "uat", "production"];
const REQUIRES_DOMAIN_PATHS = /(linkedin_connect|oauth)/;
const uiRe = /^[/#]*/;

export const ApiRoute = (path) => {
  if (PROXIED_ENVS.indexOf(PF.config.environment) >= 0 && !REQUIRES_DOMAIN_PATHS.test(path)) {
    return path;
  }
  return `${PF.config.api_endpoint}${path}`;
};

export const UiRoute = (path, hostname = window.location.hostname, port = window.location.port) => {
  if (!path) {
    throw new Error("UiRoute called without path");
  }

  // If the URL does not contain an explicit port number, port will be set to '',
  // so we can skip it in the route
  return port
    ? `${location.protocol}//${hostname}:${port}${path}`
    : `${location.protocol}//${hostname}${path}`;
};

export const UiPath = (path) => path.replace(uiRe, "/");
