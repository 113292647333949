import { useQuery } from "@tanstack/react-query";
import {
  getSuggestedChanges,
  SuggestedChangesPayload,
  SuggestedChangesResponse
} from "PFCore/services/bookings_suggested_changes";
import { QueryOptions } from "PFTypes";

import { suggestedChangesKeys } from "./query_keys";

type UseBookingsSuggestedChanges = {
  payload: SuggestedChangesPayload | null;
  options?: QueryOptions<SuggestedChangesResponse>;
};

export const useBookingsSuggestedChanges = ({ payload }: UseBookingsSuggestedChanges) =>
  useQuery(suggestedChangesKeys.suggestedChanges(payload), () => getSuggestedChanges(payload), {
    enabled: !!payload,
    cacheTime: 0,
    retry: false
  });
