import api from "PFCore/api";

export const uploadFile = <RESPONSE_TYPE extends object>(
  url: string,
  data: FormData
): Promise<RESPONSE_TYPE> =>
  api({
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
