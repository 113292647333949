import css from "./skip_links_region.module.scss";

type SkipLinksRegionProps = {
  children?: React.ReactNode;
};

export const SkipLinksRegion = ({ children }: SkipLinksRegionProps) => (
  <div id="skip_links_region" className={css.skipLinksRegion}>
    {children}
  </div>
);
