import { PropsWithChildren, RefObject } from "react";

import css from "./table.module.scss";
import { useTableResize } from "./use_table_resize";

type TableResizerProps = {
  tableRef: RefObject<HTMLTableElement>;
  paginationRef: RefObject<HTMLDivElement>;
  bottomElementsSize: number | undefined;
  disableAutoResizing: boolean | undefined;
};

export const TableResizer = ({
  tableRef,
  paginationRef,
  bottomElementsSize,
  disableAutoResizing,
  children
}: PropsWithChildren<TableResizerProps>): JSX.Element => {
  const { maxHeight } = useTableResize({
    tableRef,
    paginationRef,
    bottomElementsSize,
    disableAutoResizing
  });
  return (
    <div className={css.tableResizer} style={{ maxHeight }}>
      {children}
    </div>
  );
};
