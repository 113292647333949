import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { useToggle } from "react-use";

type FiltersPanelProviderProps = {};

export type FiltersPanelContextType = FiltersPanelProviderProps & {
  isFiltersPanelOpen: boolean;
  openFiltersPanel: (open: boolean) => void;
  openedFilterTypes: string[];
  activeFiltersAccordionIndex: number | null;
  addOpenedFilterType: (filterType: string) => void;
  removeOpenedFilterType: (filterType: string) => void;
  setActiveFiltersAccordionIndex: Dispatch<SetStateAction<number | null>>;
  areFiltersDisabled: boolean;
  toggleFiltersDisabled: (on?: any) => void;
};

const FiltersPanelContext = createContext<FiltersPanelContextType>({} as FiltersPanelContextType);

export const FiltersPanelProvider = ({ children }: PropsWithChildren<FiltersPanelProviderProps>) => {
  const [areFiltersDisabled, toggleFiltersDisabled] = useToggle(false);
  const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState(false);
  const [openedFilterTypes, setOpenedFilterTypes] = useState<string[]>([]);
  const [activeFiltersAccordionIndex, setActiveFiltersAccordionIndex] = useState<number | null>(null);

  const addOpenedFilterType = useCallback((openedFilterType: string) => {
    setOpenedFilterTypes((openedTypes) => [...openedTypes, openedFilterType]);
  }, []);

  const removeOpenedFilterType = useCallback((closedFilterType: string) => {
    setOpenedFilterTypes((openedTypes) => openedTypes.filter((name) => name !== closedFilterType));
  }, []);

  const openFiltersPanel = useCallback((open: boolean) => {
    setIsFiltersPanelOpen(open);
    if (!open) {
      setActiveFiltersAccordionIndex(null);
      setOpenedFilterTypes([]);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      isFiltersPanelOpen,
      openFiltersPanel,
      openedFilterTypes,
      activeFiltersAccordionIndex,
      addOpenedFilterType,
      removeOpenedFilterType,
      setActiveFiltersAccordionIndex,
      areFiltersDisabled,
      toggleFiltersDisabled
    }),
    [
      activeFiltersAccordionIndex,
      addOpenedFilterType,
      areFiltersDisabled,
      isFiltersPanelOpen,
      openFiltersPanel,
      openedFilterTypes,
      removeOpenedFilterType,
      toggleFiltersDisabled
    ]
  );
  return <FiltersPanelContext.Provider value={contextValue}>{children}</FiltersPanelContext.Provider>;
};

export const useFiltersPanelContext = () => useContext(FiltersPanelContext);
