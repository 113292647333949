import { compact } from "lodash";
import { FetchConversationsPayload } from "PFCore/services/chat/fetch_conversations";
import { GId, Id } from "PFTypes";

export const chatKeys = {
  all: () => ["chat"],
  participants: (conversationId: Id) => [...chatKeys.all(), "participants", conversationId.toString()],
  conversation: (conversationId: Id) => [...chatKeys.all(), "conversation", conversationId.toString()],
  conversations: (options?: FetchConversationsPayload) => [
    ...chatKeys.all(),
    "conversations",
    ...(options ? [JSON.stringify(options)] : [])
  ],
  conversationsWithTarget: (target: GId, options?) => [
    ...chatKeys.all(),
    "conversations",
    target,
    ...(options ? [JSON.stringify(options)] : [])
  ],
  messages: (conversationId: Id) => compact([...chatKeys.all(), "messages", conversationId.toString()])
};
