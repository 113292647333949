import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchBookingTemplate } from "PFApp/booking/services/api";
import { BookingTemplate } from "PFTypes";

import { bookingTemplateKeys } from "../query_keys";

export const useBookingTemplate = (
  id?: number,
  options: Omit<UseQueryOptions<BookingTemplate>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  } = {}
) =>
  useQuery<BookingTemplate>(
    bookingTemplateKeys.single(id),
    () => fetchBookingTemplate(id) as unknown as Promise<BookingTemplate>,
    {
      retry: false,
      ...options
    }
  );
