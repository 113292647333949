import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export const Note = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <div>
      <strong>{t("pleaseNote")}</strong>
      <div>{children}</div>
    </div>
  );
};
