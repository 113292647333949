import { smartAgoFormat } from "PFCore/helpers/date";
import PropTypes from "prop-types";
import { Component } from "react";

export default class DisplayDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDate: this.getDisplayDate()
    };
  }

  componentDidMount() {
    this.refresh();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  getDisplayDate = () => {
    const { date } = this.props;

    return smartAgoFormat(date);
  };

  refresh = () => {
    const { date } = this.props;

    const diff = (new Date() - new Date(date)) / 1000;
    const delay = diff > 3600 ? 60000 : 5000;

    this.setState({ displayDate: this.getDisplayDate() });
    this.timeoutId = setTimeout(this.refresh, delay);
  };

  render() {
    const { displayDate } = this.state;

    return <>{displayDate}</>;
  }
}

DisplayDate.propTypes = {
  date: PropTypes.string.isRequired
};
