import { Id } from "PFTypes/id";

import { IsoDate } from "./date_time";
import { Snakify } from "./snakify";

export enum Experience {
  None = 0,
  Basic = 1,
  Intermediate = 3,
  Advanced = 5
}

export const DEFAULT_SKILL_EXPERIENCE = Experience.Intermediate;

export enum Importance {
  Supporting = 0,
  Essential = 1,
  Required = 2
}

type _CustomValue = {
  assessed: boolean;
  assessmentSource: string;
  certificateIds: Array<number>;
  createdAt: string;
  developmental: boolean;
  expiryDate: string | null;
  globalId: string | null;
  id: Id;
  interest: boolean | null;
  rare: boolean | null;
  seniority: number;
  source: string | null;
  suggestedExperience: number;
  text: string;
  top: boolean;
  value: string | number;
  emVerified: boolean;
  customTypeId?: Id;
  state: CustomValueState;
  importance?: Importance;
  description: string | null;
  globalDescription: string | null;
  updatedAt: string;
  creator?: {
    id: Id;
    name: string;
  };
  skillsFrameworks?: {
    id: Id;
    name: string;
  }[];
  activitiesCount?: number;
  profilesCount?: number;
  locked?: boolean;
  lastMergedInto?: { id: Id; value: string | number } | null;
  mergedCustomValues: { id: Id; value: string | number }[];
};

export type CustomValue = Snakify<_CustomValue>;

export type ProfileCustomValue = CustomValue & {
  experience: Experience;
};

export type MatchedCustomValue = {
  score: number;
  idf_boost: number;
  custom_value: ProfileCustomValue;
};

export type _ActivityCustomValue = _CustomValue & {
  required_experience: Experience;
};

export type ActivityCustomValue = CustomValue & {
  requiredExperience: Experience;
};

export type MatchCustomValue = ProfileCustomValue & {
  required_experience?: Experience;
};

export type PureCustomValueCamelCased = {
  id: Id;
  globalId?: Id | null;
  ordinal?: number;
  added?: boolean;
  text: string;
  type: string;
  curatedAt?: string | null;
  description?: string;
  importance?: Importance;
  state?: CustomValueState;
  value?: string | number;
};

export type CustomValueDateRangeCameCased = {
  startDate: IsoDate;
  endDate: IsoDate;
};

export type CustomValueDateRange = Snakify<CustomValueDateRangeCameCased>;

export type PureCustomValue = Snakify<PureCustomValueCamelCased>;

export type ActivityPureCustomValue = PureCustomValue & { required_experience: Experience };
export type ProfilePureCustomValue = PureCustomValue & { experience: Experience };

export type PureCustomValueData = Partial<
  Omit<PureCustomValue, "id" | "value"> & {
    required_experience: Experience;
  }
> &
  Pick<PureCustomValue, "id" | "value">;

export enum CustomValueState {
  Approved = "approved",
  Blocked = "blocked",
  Created = "created",
  Merged = "merged"
}

export enum CustomValueMetaAction {
  Merge = "merge",
  Block = "block",
  Approve = "approve",
  Edit = "edit",
  Export = "export",
  Reset = "reset"
}

export type CustomValueMetaActionName = `${CustomValueMetaAction}`;
