import { isEmpty } from "lodash";
import { CalendarView, PinnedData } from "PFApp/booking/types";
import useStorage from "PFCore/helpers/use_storage";
import { usePinnedProjects } from "PFCore/hooks/queries/bookings/projects/use_pinned_projects";
import { ProjectsResponse } from "PFCore/hooks/queries/bookings/projects/use_projects_entries";

type UseWorkforcePinnedData = {
  view: CalendarView;
  enabled: boolean;
};

export const useProjectsPinnedData = ({
  view,
  enabled
}: UseWorkforcePinnedData): PinnedData<ProjectsResponse> => {
  const [pinnedIdsForRolesView, setPinnedIdsForRolesView] = useStorage<number[]>(
    "booking_module_overview_projects_pinned_ids_roles_view",
    []
  );
  const [pinnedIdsForEngagementView, setPinnedIdsForEngagementView] = useStorage<number[]>(
    "booking_module_overview_projects_pinned_ids_engagements_view",
    []
  );

  const { data: pinnedDataForRolesView, isFetching: isFetchingPinnedDataForRolesView } = usePinnedProjects({
    view: CalendarView.RolesAndAuditRoles,
    pinnedIds: pinnedIdsForRolesView,
    options: { enabled: enabled && view === CalendarView.RolesAndAuditRoles }
  });

  const { data: pinnedDataForEngagementsView, isFetching: isFetchingPinnedDataForEngagementsView } =
    usePinnedProjects({
      view: CalendarView.EngagementsAndAuditEngagements,
      pinnedIds: pinnedIdsForEngagementView,
      options: { enabled: enabled && view === CalendarView.EngagementsAndAuditEngagements }
    });

  return view === CalendarView.RolesAndAuditRoles
    ? {
        data: isEmpty(pinnedIdsForRolesView) ? undefined : pinnedDataForRolesView,
        pinnedIds: pinnedIdsForRolesView,
        setPinnedIds: setPinnedIdsForRolesView,
        loading: isFetchingPinnedDataForRolesView
      }
    : {
        data: isEmpty(pinnedIdsForEngagementView) ? undefined : pinnedDataForEngagementsView,
        pinnedIds: pinnedIdsForEngagementView,
        setPinnedIds: setPinnedIdsForEngagementView,
        loading: isFetchingPinnedDataForEngagementsView
      };
};
