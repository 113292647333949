import { useQuery } from "@tanstack/react-query";
import { fetchShortlists, ShortlistCollection } from "PFCore/services/shortlist";
import { QueryOptions, QueryParams } from "PFTypes";

import { shortlistsKeys } from "./query_keys";

export const useActivityShortlists = (
  activityId: number,
  params: QueryParams = {},
  options: QueryOptions<ShortlistCollection> = {}
) =>
  useQuery<ShortlistCollection>(
    shortlistsKeys.activityShortlists(activityId, params),
    () => fetchShortlists(activityId, params.page, params.perPage, params.filters, params.order),
    {
      retry: false,
      staleTime: 0,
      ...options
    }
  );
