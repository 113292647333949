import { flatten, isEmpty, uniqBy } from "lodash";
import { Button } from "PFComponents/button";
import AutoSelect from "PFComponents/select/autoselect";
import api from "PFCore/api";
import { useMatchableTypes } from "PFCore/helpers/activities";
import { getId } from "PFCore/helpers/get_id";
import useDebounce from "PFCore/helpers/use_debounce";
import { useKeywords } from "PFCore/hooks/queries/bookings/keywords/use_keywords";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./tags_suggestions.module.scss";

const TagsSuggestions = ({ text, setSuggestedAttributes, attributes, activityTemplate }) => {
  const { t } = useTranslation("feed");

  const [skills, setSkills] = useState(attributes);
  const [relevantSuggestions, setRelevantSuggestion] = useState([]);

  const matchableTypeNames = useMatchableTypes(activityTemplate.id).map(({ name }) => name);

  const selectedSkills = useMemo(() => skills.map(getId), [skills]);

  const getParams = () => {
    const params = { text, matchTypes: matchableTypeNames };
    if (selectedSkills.length > 0) {
      params.selectedIds = selectedSkills;
    }

    return params;
  };

  const [params, setParams] = useState(getParams());

  const { data, isFetching: isLoading } = useKeywords(params, {
    enabled: Boolean(params.selectedIds?.length > 0 || params.text.trim())
  });

  const debounceSetRelevantAttributes = useDebounce(() => {
    setParams(getParams());
  }, 500);

  useEffect(() => {
    debounceSetRelevantAttributes();
  }, [text, skills]);

  useEffect(() => {
    if (data?.entries) {
      setRelevantSuggestion(data.entries.filter((item) => !selectedSkills.includes(getId(item))));
    }
  }, [data, skills]);

  const renderSuggestion = () =>
    relevantSuggestions.slice(0, 6).map((suggestion) => (
      <Button
        key={suggestion.id}
        kind="secondary"
        small
        onClick={() =>
          handleRelevantAttributeChange({
            ...suggestion,
            displayElement: <div>{suggestion.value}</div>,
            text: suggestion.value
          })
        }
        icon="add"
        text={suggestion.value}
      />
    ));

  const handleRelevantAttributeChange = (selectedAttribute) => {
    if (isEmpty(selectedAttribute)) {
      setRelevantSuggestion([]);
      setSuggestedAttributes([]);
      setSkills([]);
      return;
    }
    setRelevantSuggestion(relevantSuggestions.filter((item) => item.id !== selectedAttribute.id));
    const dedupedAttributes = uniqBy(flatten([...skills, selectedAttribute]), "id");
    setSkills(dedupedAttributes);
    setSuggestedAttributes(
      dedupedAttributes.map((attribute) => ({
        id: attribute.id,
        text: attribute.text,
        value: attribute.value
      }))
    );
  };

  return (
    <div className={css.selectArea}>
      <AutoSelect
        key={skills.length}
        label={t("post.tagYourPost")}
        values={skills}
        multi
        letClear
        closeOnChange
        selectStyles={{ margin: "0 -10px" }}
        query={(term) =>
          api({
            url: "autocomplete/suggestions",
            headers: {
              apiVersion: 1
            },
            params: {
              term: term || ""
            }
          }).then((data) => {
            const selectedSkillIds = skills.map((skill) => skill.id);
            return data.filter((item) => !selectedSkillIds.includes(item.id));
          })
        }
        parseResponse={(response) => (response || []).map((item) => ({ ...item, text: item.value }))}
        formatOption={(item) => ({
          id: item.id,
          text: item.text,
          displayElement: <div>{item.text}</div>,
          item: {
            ...item,
            displayElement: <div>{item.text}</div>,
            text: item.text
          }
        })}
        handleChange={handleRelevantAttributeChange}
        handleRemove={(skill) => {
          setSkills(skill);
          setSuggestedAttributes(
            skill.map((attribute) => ({
              id: attribute.id,
              text: attribute.text,
              value: attribute.value
            }))
          );
        }}
      />
      <div className={css.suggestions} style={{ opacity: isLoading ? 0.5 : 1 }}>
        {renderSuggestion()}
      </div>
    </div>
  );
};

TagsSuggestions.propTypes = {
  text: PropTypes.string,
  attributes: PropTypes.array,
  setSuggestedAttributes: PropTypes.func,
  activityTemplate: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
};

export default TagsSuggestions;
