import AppContext from "PFApp/app_context";
import { PROFILE_PREVIEW_ACTIONS } from "PFReducers/profile_preview_reducer";
import { Profile, ProfilePreview } from "PFTypes";
import { useCallback, useContext } from "react";

export const useProfilePreview = () => {
  const {
    dispatch,
    store: { profilePreview }
  } = useContext(AppContext);

  const showPreview = useCallback(
    (payload: Pick<ProfilePreview, "id" | "profile" | "showBookingButtons">) =>
      dispatch({
        type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_SHOW,
        payload
      }),
    [dispatch]
  );

  const hidePreview = useCallback(
    () => dispatch({ type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_HIDE }),
    [dispatch]
  );

  const unmountPreview = useCallback(
    () => dispatch({ type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_UNMOUNT }),
    [dispatch]
  );

  const completePreview = useCallback(
    (profile: Profile) =>
      dispatch({ type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_DONE, payload: { profile } }),
    [dispatch]
  );

  return { showPreview, hidePreview, unmountPreview, completePreview, previewState: profilePreview };
};
