import { useBookingNoteUpdate } from "PFCore/hooks/queries/bookings/notes/use_booking_note_update";
import { useTranslation } from "react-i18next";

import BookingNoteForm from "../booking_note_form/booking_note_form";
import css from "./booking_note_edit.module.scss";

interface IProps {
  bookingId: number;
  id: number;
  content: string;
  onSuccess: () => void;
}

const BookingNoteEdit = ({ bookingId, id, content, onSuccess }: IProps) => {
  const { t } = useTranslation();

  const { update, isMutating: loading } = useBookingNoteUpdate(bookingId, id);

  const onSubmit = ({ data: { content } }) => {
    update(content.trim()).then(() => onSuccess());
  };

  return (
    <BookingNoteForm
      defaultValues={{ content }}
      submitLabel={t("save")}
      onSubmit={onSubmit}
      disabled={!!loading}
      classes={{
        root: css.root
      }}
    />
  );
};

export default BookingNoteEdit;
