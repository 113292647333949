import { Divider } from "PFComponents/divider";

import css from "./matches_constraints.module.scss";
import { MatchesAvailability, MatchesFilters } from "./parts";

export const MatchesConstraints = () => (
  <div className={css.root}>
    <MatchesAvailability />
    <Divider />
    <MatchesFilters />
  </div>
);
