import ActivityContextProvider from "PFApp/activities/show/activity_context_provider";

import { useBookingActivityContext } from "../../../providers/booking_activity_context_provider";
import { ShortlistItems } from "./shortlist_items";

type ShortlistDetailProps = {
  showList?: boolean;
};

export const ShortlistDetail = ({ showList }: ShortlistDetailProps): JSX.Element => {
  const { id } = useBookingActivityContext();

  return (
    <ActivityContextProvider id={id ?? ""} onActivityUpdate={() => {}}>
      {showList && <ShortlistItems />}
    </ActivityContextProvider>
  );
};
