import classNames from "classnames";
import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { RouterPrompt } from "PFComponents/router_prompt/router_prompt";
import { ownProfileCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import useConfirm from "PFCore/helpers/use_confirm";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCustomValuesInvalidate } from "PFCore/hooks/queries/custom_fields/admin";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { CustomType, Profile, PureCustomValueData } from "PFTypes";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../../../use_growl";
import { EXPIRABLE_CUSTOM_TYPES } from "../../common/expirable_custom_types";
import css from "./expertise_field.module.scss";
import { PageSection } from "./page_section";

type ExpertiseFieldProps = {
  customType: CustomType;
  profile: Profile;
  recalcTargets: (revealed: boolean) => void;
  onEditModeChange: VoidFunction;
  onProfileUpdate: (fields: { [key: string]: any }) => Promise<Profile>;
  isMe?: boolean;
  isEditMode?: boolean;
  isLoading?: boolean;
  className?: string;
};

export const ExpertiseField = ({
  isEditMode,
  onEditModeChange,
  customType,
  profile,
  isMe,
  isLoading,
  recalcTargets,
  onProfileUpdate,
  className
}: ExpertiseFieldProps) => {
  const growl = useGrowl();
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation(["profiles", "translation"]);
  const [updatedValue, setUpdatedValue] = useState<PureCustomValueData[] | null>(null);
  const { invalidateForType: invalidateCustomValuesForType } = useCustomValuesInvalidate();

  const isEditPermitted = useMemo(() => {
    if (!currentProfile.permissions_group || !isMe) {
      return false;
    }
    return ownProfileCustomTypesAccessLevels(currentProfile)[customType.id] === "rw";
  }, [currentProfile, customType, isMe]);

  const handleProfileUpdate = () => {
    onProfileUpdate(updatedValue !== null ? { [customType?.name]: updatedValue } : {}).then(() => {
      setUpdatedValue(null);
      growl({
        message: t("profiles:show.parts.customTypeUpdated", { customType: customType.display_as }),
        kind: "success"
      });
      onEditModeChange();
      invalidateCustomValuesForType(customType.id);
    });
  };

  const isChanged = updatedValue !== null;
  const confirmNavigation = useConfirm(isChanged);

  const values = updatedValue || profile?.[customType?.name] || [];
  const title = customType.display_as || customType.name;

  const handleEditModeChange = () => {
    if (isEditMode && isChanged) {
      if (!confirmNavigation()) {
        return;
      }
    }
    setUpdatedValue(null);
    onEditModeChange();
  };

  return (
    <PageSection
      className={classNames(className, isEditMode ? css.visible : css.hidden)}
      key={customType.id}
      title={title || t("profiles:show.parts.unknown")}
      scrollKey={`cf_${customType.name}`}
      action={
        isEditPermitted && (
          <ActionIcon
            title={
              isEditMode
                ? t("profiles:show.parts.sectionAdditional.closeEditMode")
                : t("profiles:show.parts.sectionAdditional.openEditMode", { name: title })
            }
            onClick={handleEditModeChange}
            name={isEditMode ? "cross" : "edit"}
            size="sm"
          />
        )
      }
    >
      {isEditMode ? (
        <div>
          <RouterPrompt when={isEditMode && isChanged} />
          <CustomValuesEditField
            customType={customType}
            qaIdPrefix="profile-expertise"
            values={values}
            multi={customType.kind === "multiple"}
            adminPage={false}
            profile={profile}
            handleChange={(res) => setUpdatedValue(res)}
            classes={{ root: css.customValueField }}
            letClear
          />
          <div className={css.buttonWrapper}>
            <Button onClick={handleProfileUpdate} disabled={isLoading}>
              {t("translation:update")}
            </Button>
          </div>
        </div>
      ) : (
        <CustomValuesList
          type={customType}
          currentProfile={currentProfile}
          currentAccount={currentAccount}
          profileId={profile.id}
          moreLimit={6}
          isEditMode={isEditPermitted}
          showMoreButton
          customValues={values}
          handleMoreClicked={recalcTargets}
          hasValuesWithExpiryDate={EXPIRABLE_CUSTOM_TYPES.includes(customType?.name)}
        />
      )}
    </PageSection>
  );
};
