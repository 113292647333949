import api from "PFCore/api";

export type RemoveLogoPayload = {
  accountId: number;
};

type RemoveLogoResponse = {
  message: string;
};

export const removeLogo = ({ accountId }: RemoveLogoPayload): Promise<RemoveLogoResponse> =>
  api({
    url: `accounts/${accountId}/logo`,
    method: "DELETE"
  });
