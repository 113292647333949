import classNames from "classnames";
import { CSSProperties } from "react";

import css from "./page_card.module.scss";

type PageCardClasses = {
  wrap?: string;
  card?: string;
};

type PageCardVariants = "legacy" | "spaced";

type PageCardProps = {
  style?: CSSProperties;
  classes?: PageCardClasses;
  variant?: PageCardVariants;
};

const PageCard = ({
  children,
  style,
  classes,
  variant = "legacy"
}: React.PropsWithChildren<PageCardProps>) => (
  <div
    className={classNames(css.wrap, { [css.spacedWrapper]: variant === "spaced" }, classes?.wrap)}
    style={style}
  >
    <div className={classNames(css.card, { [css.spacedCard]: variant === "spaced" }, classes?.card)}>
      {children}
    </div>
  </div>
);

export default PageCard;
