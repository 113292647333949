import { Button } from "PFComponents/button";
import AutoSelect from "PFComponents/select/autoselect";
import { fetchAutocompleteCustomValues } from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import { FeedbackSkill } from "PFTypes/activity_participant";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useFeedbackFormMutations } from "../../hooks/use_feedback_form";
import css from "./skills_evaluation.module.scss";
import { SkillsTable } from "./skills_table";

type SkillsEvaluationProps = {
  readOnly: boolean;
};

export const SkillsEvaluation = ({ readOnly }: SkillsEvaluationProps): JSX.Element => {
  const { t } = useTranslation("activities", { keyPrefix: "show.feedback" });
  const { addSkill, isSkillInForm } = useFeedbackFormMutations();
  const [showNewSkillField, setShowNewSkillField] = useState(false);

  if (readOnly) {
    return <SkillsTable readOnly={readOnly} />;
  }

  const onAddSkill = (skillArray: FeedbackSkill[]) => {
    const skill = { ...skillArray[0], experience: 1 };
    addSkill(skill);
    setShowNewSkillField(false);
  };

  return (
    <div>
      <SkillsTable />
      {showNewSkillField ? (
        <AutoSelect
          query={(term) =>
            fetchAutocompleteCustomValues({ term, type: "skills" }).then((skills) =>
              skills.filter((skill) => !isSkillInForm(skill))
            )
          }
          handleChange={onAddSkill}
        />
      ) : (
        <Button
          icon="add"
          text={t("addSkill")}
          kind="text"
          onClick={() => setShowNewSkillField(true)}
          className={css.addSkillButton}
        />
      )}
    </div>
  );
};
