import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Id, MutationOptions } from "PFTypes";

import { completeReview, CompleteReviewResponse } from "../../../services/activity/reviews";
import activityKeys from "./query_keys";
import { useActivityInvalidate } from "./use_activity_invalidate";

export const useActivityReviewComplete = (
  options: MutationOptions<{ id: Id }, CompleteReviewResponse> = {}
) => {
  const cache = useQueryClient();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  return useMutation<CompleteReviewResponse, unknown, { id: Id }>({
    mutationFn: async ({ id }) => completeReview(id),
    ...options,
    onSuccess: (response, vars, context) => {
      const cacheKey = activityKeys.activity(vars.id);

      Promise.all([cache.invalidateQueries(cacheKey), invalidateActivities([Number(vars.id)])]);
      options.onSuccess?.(response, vars, context);
    }
  });
};
