import { EconomicsVacancy, Id } from "PFTypes";

import api from "../../api";

export const createActivityEconomicsScenarioAssignment = (scenarioId: Id, profileId: Id, vacancyId: Id) =>
  api<EconomicsVacancy["assignment"]>({
    url: `scenarios/${scenarioId}/scenario_assignments`,
    method: "POST",
    data: {
      profileId,
      vacancyId
    }
  });
