import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";

export type SendWelcomeEmailResponse = {
  message: string;
};

export const sendWelcomeEmail = (id: string): jqXHR<SendWelcomeEmailResponse> =>
  // @ts-ignore
  $.ajax({
    type: "post",
    url: ApiRoute("/api/admin/users/send_welcome_emails"),
    data: {
      profile_ids: [id]
    }
  });
