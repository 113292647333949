import { compact } from "lodash";
import { getCanEditCustomFieldData } from "PFApp/components/custom_values_edit_field/custom_values_edit_field.utils";
import { ActionIcon } from "PFComponents/action_icon";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { FeatureFlag, PermissionRule, Profile } from "PFTypes";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useUnmount } from "react-use";

import { ShortlistForProfileButton } from "../../../shortlist_for_profile";
import { PageSection } from "../page_section";
import css from "./organisation_data.module.scss";
import { OrganisationDataEdit } from "./parts/organisation_data_edit";
import { OrganisationDataPreview } from "./parts/organisation_data_preview";

type OrganisationDataProps = {
  profile: Profile;
  basicCustomTypesNames: string[];
  isEditMode: boolean;
  isLoading: boolean;
  isMe: boolean;
  onProfileUpdate: (profileFieldsToUpdate: any) => Promise<void>;
  onEditModeChange: () => void;
  errors: string | string[];
  className?: string;
};

export const OrganisationData = ({
  profile,
  basicCustomTypesNames,
  isEditMode,
  isLoading,
  isMe,
  onProfileUpdate,
  onEditModeChange,
  errors,
  className
}: OrganisationDataProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { t } = useTranslation("profiles");
  const isEnabled = useIsFeatureEnabled();
  const isPermittedTo = useIsPermittedTo(currentProfile);

  const pageSectionRef = useRef<HTMLDivElement>(null);

  useUnmount(() => {
    if (isEditMode) {
      onEditModeChange();
    }
  });

  const basicCustomTypes = compact(
    basicCustomTypesNames.map((name) => customTypes.find((customType) => customType.name === name))
  );

  const isAllowedToEdit = isMe || isPermittedTo(PermissionRule.ProfessionalProfileEdit);
  const hasEditableFields =
    isEnabled(FeatureFlag.ReportsTo) ||
    basicCustomTypes
      .map((customType) =>
        getCanEditCustomFieldData({
          adminPage: false,
          profile,
          customType,
          currentProfile,
          t
        })
      )
      .some(({ locked }) => !locked);

  return (
    <PageSection
      id="organisation-data"
      ref={pageSectionRef}
      className={className}
      hType="h3"
      title={t("show.parts.organisationData")}
      contentStyle={{ overflow: "auto" }}
      action={
        <div className={css.sectionActions}>
          {isAllowedToEdit && hasEditableFields && (
            <ActionIcon
              title={t("show.parts.openEditOrganisationData")}
              aria-haspopup="dialog"
              aria-expanded={isEditMode}
              onClick={onEditModeChange}
              name="edit"
              size="sm"
            />
          )}
          <ShortlistForProfileButton profile={profile} />
        </div>
      }
      skipToSection={{ targetId: "availability", text: t("show.parts.skipLinks.availability") }}
    >
      {isEditMode && (
        <OrganisationDataEdit
          profile={profile}
          basicCustomTypes={basicCustomTypes}
          isLoading={isLoading}
          errors={errors}
          onProfileUpdate={onProfileUpdate}
          onClose={onEditModeChange}
        />
      )}
      <OrganisationDataPreview profile={profile} basicCustomTypes={basicCustomTypes} />
    </PageSection>
  );
};
