import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Trans, useTranslation } from "react-i18next";

import css from "./review_section.module.scss";

type ReviewDeadlineLabelProps = { date: string; onClick?: () => void };

export const ReviewDeadlineLabel = ({ date, onClick }: ReviewDeadlineLabelProps) => {
  const { formatDate } = useDateFormatter();

  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.review" });

  return (
    <Typography variant="bodyRegular" noMargin className={css.nowrap}>
      <Trans
        i18nKey="reviewDeadlineDate"
        t={t}
        values={{ date: formatDate(date) }}
        components={[
          onClick ? (
            <Button
              key="0"
              kind="text"
              className={css.boldDate}
              onClick={onClick}
              qaId="ActivityPageReviewDeadlineButton"
            />
          ) : (
            <Typography key="0" variant="bodyBold" />
          )
        ]}
      />
    </Typography>
  );
};
