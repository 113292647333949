import api from "PFCore/api";

export type ReassignBookingsPayload = {
  activityId: number;
  oldProfileId: number;
  newProfileId: number;
  dateRange: {
    min: string;
    max: string;
  };
};

export const reassignBookings = (data: ReassignBookingsPayload): Promise<void> =>
  api({
    url: `booking_module/bookings/reassign`,
    method: "PUT",
    data
  });
