import FocusTrap from "focus-trap-react";
import { ActionIcon } from "PFComponents/action_icon";
import { Button, ButtonProps } from "PFComponents/button";
import Calendar from "PFComponents/calendar/calendar";
import { DoubleSectionPill } from "PFComponents/double_section_pill/double_section_pill";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { CustomValue } from "PFTypes";
import { EventKey } from "PFTypes/event_key";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

import css from "./calendar_badge.module.scss";

export type CalendarBadgeValue = Omit<DropdownOption, "item"> & { item: CustomValue };

export type CalendarBadgeProps = {
  displayValue: string;
  onRemove: (value: CalendarBadgeValue, event: SyntheticEvent) => void;
  value: CalendarBadgeValue;
  onDateChange: (item: CustomValue, event: SyntheticEvent) => void;
  isLocked: boolean;
  popperOptions?: object;
};

export const CalendarBadge = ({
  displayValue,
  onRemove,
  value,
  onDateChange,
  isLocked,
  popperOptions
}: CalendarBadgeProps) => {
  const { t } = useTranslation("core");
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState<string | null>(value.item.expiry_date!);

  const badgeRef = useRef<HTMLDivElement>(null);
  const modalSelector = document.getElementById("modal_region");

  const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { formatISODate, formatDate, utc } = useDateFormatter();

  const { styles, attributes } = usePopper(popperReferenceElement, popperElement, {
    placement: "top",
    strategy: "absolute",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-2, 15]
        }
      }
    ],
    ...popperOptions
  });

  const handleShowCalendar = () => {
    setShowCalendar(true);
  };

  const handleHideCalendar = () => {
    setShowCalendar(false);
  };

  const handleCalendarKeyDown = (event) => {
    if (event.key === EventKey.Escape) {
      handleHideCalendar();
    }
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (modalSelector && !modalSelector.contains(event.target)) {
        handleHideCalendar();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const CalendarRenderer = (
    <FocusTrap>
      <div
        role="dialog"
        className={css.calendarWrapper}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <Calendar
          style={{ width: 360 }}
          selectedDate={date ? formatISODate(utc(date)) : undefined}
          handleChange={(newDate, _options, event) => {
            setDate(newDate);
            setShowCalendar(false);
            onDateChange({ ...value.item, expiry_date: formatISODate(utc(newDate)) } as CustomValue, event);
          }}
          onKeyDown={handleCalendarKeyDown}
        />
      </div>
    </FocusTrap>
  );

  const handleDelete = isLocked ? undefined : (event) => onRemove(value, event);

  const leftContent = (
    <Tooltip content={<div>{displayValue}</div>}>
      <div className={css.displayInBadge} style={{ maxWidth: badgeRef?.current?.clientWidth }}>
        {displayValue}
      </div>
    </Tooltip>
  );

  const expirationDate = formatDate(utc(date));

  const dateButtonProps: Partial<ButtonProps> = date
    ? {
        text: `${t("time.inWords.expiresShort")} ${expirationDate}`,
        title: t("components.calendarBadge.changeExpirationDate", {
          date: expirationDate,
          label: displayValue
        })
      }
    : {
        icon: "calendar",
        iconPlacement: "right",
        text: t("time.inWords.expires"),
        title: t("components.calendarBadge.setExpirationDate", { label: displayValue })
      };

  const rightContent = (
    <div className={css.date}>
      <Button
        ref={setPopperReferenceElement}
        aria-expanded={showCalendar}
        aria-haspopup="dialog"
        kind="blank"
        disabled={isLocked}
        onClick={handleShowCalendar}
        className={css.calendarButton}
        {...dateButtonProps}
      />
      {date && !isLocked && (
        <ActionIcon
          size="xs"
          name="cross"
          title={t("components.calendarBadge.removeExpirationDate", {
            date: expirationDate,
            label: displayValue
          })}
          onClick={(event) => {
            setDate(null);
            onDateChange({ ...value.item, expiry_date: null } as CustomValue, event);
            popperReferenceElement?.focus();
          }}
        />
      )}
      {showCalendar &&
        ReactDOM.createPortal(CalendarRenderer, document.getElementById("modal_region") as HTMLElement)}
    </div>
  );

  return (
    <div className={css.root} ref={badgeRef}>
      <DoubleSectionPill
        leftContent={leftContent}
        onDelete={handleDelete}
        rightContent={rightContent}
        deleteTitle={t("components.calendarBadge.removeOption", { label: displayValue })}
      />
    </div>
  );
};
