import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { getAPIFilters, useMetaFilters } from "PFApp/use_filtered_collection";
import { Filters, Value } from "PFTypes";
import { useCallback } from "react";

export const useActivityMatchesFilters = () => {
  const { matchesMeta, updateMatchesParams } = useActivityPageContext();

  const updateFilterValues = useCallback(
    (updatedFilters: Filters<Value>) => {
      updateMatchesParams({ filters: getAPIFilters(updatedFilters), page: 1 });
    },
    [updateMatchesParams]
  );

  return useMetaFilters(matchesMeta, updateFilterValues);
};
