import { getConnectedProperties } from "PFCore/helpers/templates";
import { ParsedSchemaProperty, Subtemplate } from "PFTypes";

import { useActivityEditFormContext } from "../../activity_edit_form_context";
import { useActivityEditPageContext } from "../../activity_edit_page_context";

export const useConnectedFields = (subtemplate: Subtemplate | undefined, property?: ParsedSchemaProperty) => {
  const { customFields } = useActivityEditFormContext();
  const { template } = useActivityEditPageContext();

  if (property && !property.connected_options) {
    return null;
  }

  const connectedPropertiesNames = getConnectedProperties(subtemplate || template).map(({ name }) => name);

  return customFields.filter(
    ({ type, values }) => values.length > 0 && connectedPropertiesNames.includes(type.name)
  );
};
