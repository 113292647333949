import { activityCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { CustomType, ParsedSchemaProperty, Profile } from "PFTypes";

export const shouldDisplayProperty = (
  property: ParsedSchemaProperty,
  customTypes: CustomType[],
  currentProfile: Profile
): boolean => {
  const customType = customTypes.find((type) => type.name === property.name);

  return Boolean(
    customType && ["rw", "ro"].includes(activityCustomTypesAccessLevels(currentProfile)[customType.id])
  );
};
