import { Button, ButtonKind } from "PFComponents/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ShareModal } from "./share_modal";

type ShareButtonProps = {
  activityType: "role" | "engagement";
  activityIds: number[];
  invites?: any[];
  kind?: ButtonKind;
  withTitle?: boolean;
  onSuccess?: () => void;
};

export const ShareButton = ({
  activityIds,
  invites,
  onSuccess,
  activityType,
  kind,
  withTitle = true
}: ShareButtonProps) => {
  const { t } = useTranslation("core");

  const [shown, setShown] = useState(false);

  const noActivityIds = activityIds.length === 0;
  const handleClose = () => {
    setShown(false);
  };

  return (
    <>
      <Button
        disabled={noActivityIds}
        onClick={() => setShown(true)}
        kind={kind}
        text={withTitle ? t("activityShare.buttonText") : ""}
        icon="share"
        iconPlacement="right"
      />
      {shown && (
        <ShareModal
          activityIds={activityIds}
          onClose={handleClose}
          onSuccess={() => {
            onSuccess?.();
            handleClose();
          }}
          activityType={activityType}
          invites={invites}
        />
      )}
    </>
  );
};
