import AutoSelect from "PFComponents/select/autoselect";
import { useTranslation } from "react-i18next";

import { EngagementItem, EngagementOption } from "./engagement_item";
import { EngagementPlaceholder } from "./engagement_placeholder";
import { transform } from "./engagement_select.utils";

type EngagementSelectProps<RESPONSE extends object, ENGAGEMENT extends object> = {
  parseEngagementToOption: (engagement: ENGAGEMENT) => EngagementOption;
  letClear?: boolean;
  selectedEngagement: ENGAGEMENT | null;
  query: (term: string) => Promise<RESPONSE>;
  parseResponse: (response: RESPONSE) => ENGAGEMENT[];
  handleChange: (value: ENGAGEMENT) => void;
  error?: string;
  required?: boolean;
  label?: string;
  qaId?: string;
  id?: string;
  disabled?: boolean;
  isRepeatedBooking?: boolean;
};

export const EngagementSelect = <RESPONSE extends object, ENGAGEMENT extends object>({
  letClear,
  selectedEngagement,
  parseEngagementToOption,
  query,
  parseResponse,
  handleChange,
  error,
  required,
  label,
  qaId,
  id,
  disabled,
  isRepeatedBooking
}: EngagementSelectProps<RESPONSE, ENGAGEMENT>): JSX.Element => {
  const { t } = useTranslation();

  const selectedOption = selectedEngagement ? parseEngagementToOption(selectedEngagement) : null;

  const displayValues = selectedOption ? <EngagementItem item={selectedOption} /> : null;

  if (isRepeatedBooking) {
    return <EngagementPlaceholder />;
  }

  return (
    <AutoSelect
      label={label ?? t("engagement")}
      title={selectedOption?.name}
      displayValues={displayValues}
      closeOnChange
      letClear={letClear}
      values={transform.input(selectedOption)}
      query={query}
      formatOption={(item) => transform.formatOption(item, parseEngagementToOption)}
      handleChange={(engagement: ENGAGEMENT[]) => {
        const engagementOption = transform.output(engagement);
        handleChange(engagementOption);
      }}
      parseResponse={parseResponse}
      error={error}
      required={required}
      id={id}
      qaId={qaId}
      disabled={disabled}
    />
  );
};
