import { MomentInput } from "moment";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { DEFAULT_DATE_FORMAT } from "PFCore/helpers/date";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

import { useOverbookingColor } from "../../../../warning_icons/use_overbooking_color";
import { OverbookingsRange } from "../use_overbooking_ranges";

type OverbookingHeaderProps = {
  start: string;
  end: string;
  overbookings: OverbookingsRange["overbookings"];
};

export const OverbookingHeader = ({ start, end, overbookings }: OverbookingHeaderProps) => {
  const { utc } = useDateFormatter();
  const isOneDay = start === end;
  const formatDate = (date: MomentInput) => utc(date).format(DEFAULT_DATE_FORMAT);
  const { overbookingColor } = useOverbookingColor({ overbookings });

  return (
    <>
      <Icon name="warning" fill={overbookingColor?.hex()} />
      <Typography variant="labelBold" noMargin>
        {isOneDay ? formatDate(start) : `${formatDate(start)} - ${formatDate(end)}`}
      </Typography>
    </>
  );
};
