import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useParams } from "react-router-dom";

type Params = {
  id: string;
};

export const useProfileId = (): number => {
  const { data: currentProfile } = useCurrentProfile();
  const { id } = useParams<Params>();

  return id === "me" ? currentProfile.id : Number(id);
};
