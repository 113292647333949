import { ApiRoute } from "PFCore/utilities/routes";

export type DeleteMessageResponse = {
  message: string;
};

export const deleteMessage = (id: string): Promise<DeleteMessageResponse> =>
  // @ts-ignore
  $.ajax({
    type: "DELETE",
    url: ApiRoute(`/api/chat/messages/${id}`),
    api_version: 2
  });
