import { isNil, values } from "lodash";
import { MatchGauge } from "PFComponents/resource_metrics/match_gauge";
import { Typography } from "PFComponents/typography";
import { IMatchScores } from "PFTypes";
import { useTranslation } from "react-i18next";

type MatchScoreSectionProps = {
  scores: IMatchScores | undefined;
};

export const MatchScoreSection = ({ scores }: MatchScoreSectionProps): JSX.Element => {
  const { t } = useTranslation("bookingModule");
  const isScoresEmpty = !scores || values(scores).every(isNil);
  return (
    <>
      {isScoresEmpty ? (
        <Typography variant="labelRegular" tag="span">
          {t("bookings.reassign.noMatchScore")}
        </Typography>
      ) : (
        <MatchGauge match={{ scores }} />
      )}
    </>
  );
};
