import classNames from "classnames";
import { Button } from "PFComponents/button";
import { Card } from "PFComponents/card";
import { Typography } from "PFComponents/typography";
import scrollTo from "PFCore/helpers/scroll_to";
import { CustomType, Profile } from "PFTypes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import EditPositionForm from "../../../common/experience/edit_position_form";
import { Experience } from "./experience";
import css from "./experience_section.modules.scss";

type ExperienceSectionProps = {
  profile: Profile;
  currentProfile: Profile;
  internalExperiences: any;
  fetchInternalExperience: () => any;
  customTypes: CustomType[];
  handleProfileUpdate: (attributes: Partial<Profile>, options?: object) => Promise<void>;
  adminPage: boolean;
  className: string;
};

export const ExperienceSection = ({
  profile,
  currentProfile,
  internalExperiences,
  fetchInternalExperience,
  customTypes,
  handleProfileUpdate,
  adminPage,
  className
}: ExperienceSectionProps) => {
  const experiences = profile.positions.map((position) => ({
    ...position,
    internalPositions: internalExperiences.filter(
      (experience) => experience.metadata.position_id === position.id
    )
  }));

  const { t } = useTranslation("profiles");
  const { additionalSection } = useParams<{ additionalSection: string }>();

  const [addNewExperience, setAddNewExperience] = useState(false);

  const isMe = !!profile?.id && profile?.id === currentProfile?.id;

  useEffect(() => {
    const ieElement = document.querySelector(
      `[data-scroll-anchor="internal-experience-${additionalSection}"]`
    );
    scrollTo(window, 0, ieElement ? ieElement.getBoundingClientRect().top + window.pageYOffset - 50 : 0);
  }, [additionalSection]);

  return (
    <Card className={classNames(css.wrapper, className)} paddingVariant="xl">
      <header className={css.header}>
        <Typography variant="h2" noMargin>
          {t("show.parts.experience")}
        </Typography>
        {isMe && (
          <Button
            aria-label={t("edit.shared.addExperience")}
            aria-expanded={addNewExperience}
            aria-haspopup="dialog"
            text={t("edit.shared.addExperience")}
            onClick={() => setAddNewExperience(true)}
          />
        )}
      </header>
      <main>
        {addNewExperience && (
          <>
            <EditPositionForm
              adminPage={adminPage}
              position={{ current: true }}
              profile={profile}
              handleSuccess={() => {
                setAddNewExperience(false);
                handleProfileUpdate({}); // just refetch profile
              }}
              handleRemove={() => setAddNewExperience(false)}
              onClose={() => setAddNewExperience(false)}
            />
          </>
        )}
        {experiences.map((experience, index) => (
          <React.Fragment key={`${experience.id}+${index}`}>
            <Experience
              adminPage={adminPage}
              experience={experience}
              customTypes={customTypes}
              profile={profile}
              currentProfile={currentProfile}
              fetchInternalExperience={fetchInternalExperience}
              handleProfileUpdate={handleProfileUpdate}
              isMe={isMe}
            />
          </React.Fragment>
        ))}
      </main>
    </Card>
  );
};
