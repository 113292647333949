import { Button } from "PFCore/components/button";
import { profileHash } from "PFCore/helpers/profile_hash";
import { Profile } from "PFTypes";
import { SyntheticEvent } from "react";
import { Link } from "react-router-dom";

import css from "./profile_card_name.module.scss";

type ProfileCardNameProps = {
  href?: string;
  isBlindModeOn?: boolean;
  name: string;
  outlook?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  profile: Profile;
  qaId?: string;
};

const ProfileCardName = ({
  href,
  isBlindModeOn,
  profile,
  name,
  outlook,
  onClick,
  qaId = "ProfileCard.name"
}: ProfileCardNameProps) => {
  const nameNode = (
    <strong className={css.name} title={name.length > 70 ? name : undefined}>
      {name}
    </strong>
  );

  if (isBlindModeOn) {
    return <div className={css.name}>{`Candidate ${profileHash(profile.id)}`}</div>;
  }
  if (outlook) {
    return (
      <Button kind="blank" onClick={onClick} qaId={qaId}>
        {nameNode}
      </Button>
    );
  }
  if (href) {
    return (
      <Link to={href} data-qa-id={qaId} onClick={onClick}>
        {nameNode}
      </Link>
    );
  }

  return nameNode;
};

export default ProfileCardName;
