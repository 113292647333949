import { useQueryClient } from "@tanstack/react-query";

import { bookedProfilesKeys } from "./query_keys";

export const useBookedProfilesInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateByBookingGroupId: (bookingGroupId: number) =>
      queryClient.invalidateQueries({ queryKey: bookedProfilesKeys.byBookingGroupId(bookingGroupId) })
  };
};
