import ButtonLink from "PFComponents/button/button_link";
import WorkflowIcon from "PFIcons/workflow.svg";
import { useTranslation } from "react-i18next";

import css from "./shortlist_detail_action.modules.scss";

type ShortlistDetailActionProps = {
  id: number | undefined;
};

export const ShortlistDetailAction = ({ id }: ShortlistDetailActionProps) => {
  const { t } = useTranslation("bookingModule");
  return (
    <>
      {id && (
        <ButtonLink href={`/activities/${id}/shortlist`} kind="secondary" rootClassName={css.shortlistButton}>
          <WorkflowIcon height={24} width={24} />
          {t("details.viewInWorkflowShortlist")}
        </ButtonLink>
      )}
    </>
  );
};
