import classNames from "classnames";
import { isArray, noop, some } from "lodash";
import { Button, ButtonProps } from "PFComponents/button";
import DropDown, { DropdownOptions, DropDownProps } from "PFComponents/dropdown/dropdown";
import { getAriaProps } from "PFCore/helpers/get_aria_props";
import useClickOutside from "PFCore/helpers/use_click_outside";
import { IconName } from "PFTheme/graphics/icons";
import { AriaAttributes, CSSProperties, forwardRef, ReactNode, useCallback, useRef, useState } from "react";
import { usePopper } from "react-popper";

import { ActionIcon } from "../action_icon";
import css from "./dropdown_button.module.scss";

type DropdownButtonProps = AriaAttributes & {
  children?: ReactNode;
  actionIcon?: IconName;
  icon?: IconName;
  text?: string;
  small?: boolean;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  qaId?: string;
  options: DropdownOptions;
  buttonKind?: ButtonProps["kind"];
  /**
   * @deprecated Use one of the Button variants
   */
  buttonStyle?: CSSProperties;
  buttonClassName?: string;
  dropDownStyle?: CSSProperties;
  dropDownClassName?: string;
  dropdownProps?: Partial<DropDownProps>;
  handleChange: (item: any) => void;
  handleClose?: () => void;
  handleClick?: (event: any, menuShown: boolean) => void;
  popperOptions?: object;
};

export const DropdownButton = forwardRef<HTMLElement, DropdownButtonProps>(
  (
    {
      actionIcon,
      icon,
      text,
      small,
      style,
      className,
      disabled = false,
      qaId = "Button",
      options,
      buttonKind,
      buttonStyle,
      buttonClassName,
      dropDownStyle,
      dropDownClassName,
      dropdownProps = {},
      children,
      handleChange,
      handleClose,
      handleClick = noop,
      popperOptions,
      ...props
    }: DropdownButtonProps,
    ref
  ): JSX.Element => {
    const [menuShown, setMenuShown] = useState(false);
    const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const { styles, attributes } = usePopper(popperReferenceElement, popperElement, {
      placement: "auto-end",
      strategy: "fixed",
      ...popperOptions
    });

    const { ariaProps } = getAriaProps(props);
    const dropDownRef = useRef(null);
    const menuRef = useRef<HTMLDivElement>(null);

    useClickOutside(menuRef, () => {
      setMenuShown(false);
      handleClose && handleClose();
    });

    const defaultStyle: React.CSSProperties = {
      minWidth: 100,
      maxWidth: 300,
      textAlign: "left",
      position: "relative",
      display: "block",
      marginBottom: 0,
      ...dropDownStyle
    };

    // TODO: [PROF-7192] It should be refactored as a part of Dropdown refactor ticket
    // This is a hack to make the dropdown work with keyboard navigation
    // The same implementation is done in select.tsx
    // @ts-ignore
    const handleKeyDown = (event) => dropDownRef.current && dropDownRef.current.handleKeyDown(event);

    const handleBlur = ({ relatedTarget }) => {
      const clickedInDropdown =
        relatedTarget &&
        some(
          ["data-dropdown-root", "data-dropdown-item", "data-dropdown-list", "data-dropdown-button-menu"],
          (attribute) => relatedTarget.hasAttribute(attribute)
        );

      if (clickedInDropdown) {
        return;
      }

      setMenuShown(false);
      handleClose && handleClose();
    };

    // Ref callback that combines setPopperReferenceElement and the forwarded ref
    const combinedRef = useCallback(
      (node: HTMLElement | null) => {
        setPopperReferenceElement(node);
        if (typeof ref === "function") {
          ref(node);
        } else if (ref) {
          (ref as React.MutableRefObject<HTMLElement | null>).current = node;
        }
      },
      [ref]
    );

    const childContent = children ? { children } : { icon, text };

    const commonProps = {
      ...ariaProps,
      disabled,
      "ref": combinedRef,
      qaId,
      "onClick": (event) => {
        event.stopPropagation();
        setMenuShown(!menuShown);
        handleClick && handleClick(event, !menuShown);
      },
      "aria-expanded": menuShown,
      "aria-haspopup": "true" as "true"
    };

    const button = actionIcon ? (
      <ActionIcon
        {...childContent}
        title={ariaProps["aria-label"]}
        name={actionIcon}
        size={small ? "sm" : "md"}
        {...commonProps}
      />
    ) : (
      <Button
        {...childContent}
        small={small}
        kind={buttonKind}
        style={buttonStyle}
        className={buttonClassName}
        {...commonProps}
      />
    );

    return (
      <div
        tabIndex={-1}
        role="menu"
        ref={menuRef}
        className={classNames(css.dropdownButton, className)}
        style={style}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        data-dropdown-button-menu="true"
      >
        {button}
        {menuShown && (
          <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 20 }} {...attributes.popper}>
            <DropDown
              ref={dropDownRef}
              style={defaultStyle}
              rootClassName={dropDownClassName}
              options={options}
              handleChange={(item) => {
                if (!isArray(item)) {
                  !item.keepOpen && setMenuShown(false);
                }

                handleChange && handleChange(item);
              }}
              handleClose={() => {
                setMenuShown(false);
                handleClose && handleClose();
              }}
              {...dropdownProps}
            />
          </div>
        )}
      </div>
    );
  }
);

DropdownButton.displayName = "DropdownButton";
