import { Id } from "PFTypes";

export enum LocalisationKeys {
  CustomTypes = "customTypes",
  Filters = "filters",
  OrderOptions = "orderOptions",
  AccountTemplates = "accountTemplates"
}

export enum EntryType {
  String = "string",
  Text = "text",
  Collection = "collection"
}

export type LocalisationCollectionValue = {
  type: EntryType.Collection;
  value: LocalisationEntry[];
};

export type LocalisationSingleValue = {
  type: EntryType.String | EntryType.Text;
  value: string | null;
};

type LocalisationValue = LocalisationCollectionValue | LocalisationSingleValue;

export type LocalisationEntry = {
  key: string;
  locale: string;
} & LocalisationValue;

export type Localisation = {
  id: Id;
  name: string;
  localisation: LocalisationEntry[];
};
