import { useEffect, useState } from "react";

type UseIsContentOverflowVisible = {
  isOpen: boolean;
  delay: number;
};

export const useIsContentOverflowVisible = ({ isOpen, delay }: UseIsContentOverflowVisible): boolean => {
  const [isOverflowVisible, setIsOverflowVisible] = useState(isOpen);

  useEffect(() => {
    let timeoutId;
    if (isOpen) {
      timeoutId = setTimeout(() => {
        setIsOverflowVisible(true);
      }, delay);
    } else {
      setIsOverflowVisible(false);
    }
    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  return isOverflowVisible;
};
