import { DefaultNamespace, KeyPrefix, Namespace, useTranslation, UseTranslationOptions } from "react-i18next";

export const useOptionalTranslation = <
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined
>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions<TKPrefix>
) => {
  const { t, i18n, ...translation } = useTranslation(ns, options);

  return {
    t,
    i18n,
    optionalT: (textOrKey) => (i18n.exists(textOrKey) ? t(textOrKey) : textOrKey),
    ...translation
  };
};
