import { useMutation } from "@tanstack/react-query";
import { useCurrentProfileSet } from "PFCore/hooks/queries/profile";
import { editCurrentProfile } from "PFCore/services/current_profile";
import { CurrentProfile } from "PFTypes";
import { MutationOptions } from "PFTypes/request_options";

export const useCurrentProfileUpdate = ({
  onSuccess,
  ...queryParams
}: MutationOptions<Partial<CurrentProfile>> = {}) => {
  const setCurrentProfile = useCurrentProfileSet();

  return useMutation({
    mutationFn: (payload: Partial<CurrentProfile>): any => editCurrentProfile(payload),
    onSuccess: (resp: CurrentProfile, ...params) => {
      setCurrentProfile(resp);
      onSuccess?.(resp, ...params);
    },
    ...queryParams
  });
};
