import { useQueryClient } from "@tanstack/react-query";
import { has, isEmpty, isMatch, isPlainObject } from "lodash";

import { FetchRolesParams } from "../../../services/roles";
import { auditRolesKeys, rolesKeys } from "./query_keys";

const isFiltersQueryKey = (queryKeyPart: FetchRolesParams | unknown[]): queryKeyPart is FetchRolesParams =>
  isPlainObject(queryKeyPart) &&
  ["page", "perPage", "filters", "order"].some((fetchRoleParamsProperty) =>
    has(queryKeyPart, fetchRoleParamsProperty)
  );

export const useRolesInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateRoles: () => {
      queryClient.invalidateQueries(rolesKeys.all());
    },
    invalidateAuditRoles: () => {
      queryClient.invalidateQueries(auditRolesKeys.all());
    },
    invalidateFilteredRoles: (filters: FetchRolesParams | undefined) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (!["roles", "auditRoles"].includes(query.queryKey[0] as string)) {
            return false;
          }

          if (!filters || isEmpty(filters)) {
            return true;
          }

          const queriedFilters = query.queryKey.find(isFiltersQueryKey);

          return !!queriedFilters && isMatch(queriedFilters, filters);
        }
      });
    }
  };
};
