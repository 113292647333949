// TODO: deprecated: use helpers scrollTo

let supportsSmooth;
const testSupportsSmoothScroll = () => {
  try {
    var div = document.createElement("div");
    div.scrollTo({
      top: 0,
      get behavior() {
        supportsSmooth = true;
        return "smooth";
      }
    });
  } catch (err) {
    supportsSmooth = false;
  }
};

export default (elem, left) => {
  if (supportsSmooth === undefined) {
    testSupportsSmoothScroll();
  }

  if (!elem) {
    return;
  } // just in case;

  if (supportsSmooth) {
    elem.scrollTo({ behavior: "smooth", left, top: 0 });
  } else {
    elem.scrollTo(left, 0);
    // ie or safari most likely -> no animation
  }
};
