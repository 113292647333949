import classNames from "classnames";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import { agoFormat } from "PFCore/helpers/date";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./notification_item.module.scss";

export const getBadgeColour = (categoryType) => titleToBadgeColours[categoryType] ?? "highlight";

const titleToBadgeColours = {
  new_role_filled: "light", // eslint-disable-line camelcase
  new_role_rejected: "danger", // eslint-disable-line camelcase
  role_filled: "light", // eslint-disable-line camelcase
  role_closed: "danger", // eslint-disable-line camelcase
  shortlist_share: "highlight", // eslint-disable-line camelcase
  skill_suggestion: "highlight", // eslint-disable-line camelcase
  shortlist_assigned: "success", // eslint-disable-line camelcase
  custom_value_close_to_expire: "highlight", // eslint-disable-line camelcase
  interested_role_closed: "danger" // eslint-disable-line camelcase
};

const NotificationItem = ({
  notification: { id, title, created_at, category_type, body, status, url_address },
  onMarkAsRead
}) => {
  const { t } = useTranslation();
  const isUnread = status === "unread";
  const badgeColour = useMemo(() => getBadgeColour(category_type), [category_type]);

  return (
    <div className={classNames(css.notificationItem, { [css.read]: !isUnread })}>
      <div className={css.header}>
        <Typography variant="labelRegular" className={css.notificationLabel}>
          {agoFormat(created_at)}
        </Typography>
        {isUnread ? (
          <Button kind="text" small onClick={() => onMarkAsRead(id)} text={t("notifications.markRead")} />
        ) : (
          <Typography tag="span" variant="labelBold" className={css.notificationLabel}>
            {t("notifications.read")}
          </Typography>
        )}
      </div>
      <SimpleBadge
        text={title}
        kind={badgeColour}
        style={{
          margin: "0 0 10px 0",
          fontSize: 10,
          padding: "2px 10px",
          minHeight: 0,
          width: "fit-content"
        }}
      />
      <Link to={`${url_address}`} className={css.link} key={id} onClick={() => isUnread && onMarkAsRead(id)}>
        <div className={css.body}>{body}</div>
      </Link>
    </div>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    body: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category_type: PropTypes.string.isRequired,
    url_address: PropTypes.string
  }),
  onMarkAsRead: PropTypes.func.isRequired
};
