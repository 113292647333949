import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import Tooltip from "PFComponents/tooltip/tooltip";
import colors from "PFTheme/tokens/colors";
import { CustomValue, Experience } from "PFTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./skill_rating_icon.module.scss";

const Circle = ({
  customValue,
  isEditMode,
  experience,
  rating,
  cx,
  cy,
  color,
  strokeWidth,
  updateExperience
}: {
  experience: Experience;
  rating: number;
  cx: string;
  cy: string;
} & Pick<
  SkillRatingIconProps,
  "strokeWidth" | "color" | "customValue" | "updateExperience" | "isEditMode"
>) => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });
  const experienceLabelMap = useExperienceLabelMap();

  const generateHandleKeyDown = (rank: Experience) => (event) =>
    event.key === "Enter" && updateExperience?.(rank);

  return (
    <Tooltip
      theme="pf-dark-blue"
      disabled={!isEditMode}
      content={
        <span
          aria-label={
            customValue
              ? t("setCustomValueExperience", {
                  name: customValue.text,
                  experience: experienceLabelMap[experience]
                })
              : t("setExperience", { experience: experienceLabelMap[experience] })
          }
        >
          {experienceLabelMap[experience]}
        </span>
      }
    >
      <circle
        role={isEditMode ? "button" : "presentation"}
        tabIndex={!isEditMode ? -1 : undefined}
        cx={cx}
        cy={cy}
        r="8"
        stroke={color}
        strokeWidth={strokeWidth}
        data-level={experience}
        fill={rating >= experience ? color : "transparent"}
        onClick={isEditMode ? () => updateExperience?.(experience) : undefined}
        onKeyDown={isEditMode ? generateHandleKeyDown(experience) : undefined}
      />
    </Tooltip>
  );
};

const Line = ({
  experience,
  x1,
  x2,
  color,
  strokeWidth
}: {
  experience: Experience;
  x1: string;
  x2: string;
} & Pick<SkillRatingIconProps, "strokeWidth" | "color">) => (
  <line data-level={experience} x1={x1} y1="10" x2={x2} y2="10" stroke={color} strokeWidth={strokeWidth} />
);

type SkillRatingIconProps = {
  customValue?: CustomValue;
  value?: Experience | null;
  /** css size or number */
  size?: string | number;
  className?: string;
  isEditMode?: boolean;
  updateExperience?: (experience: Experience) => void;
  color?: string;
  strokeWidth?: number;
};

export const SkillRatingIcon = ({
  customValue,
  value = null,
  size = "1em",
  className,
  isEditMode,
  updateExperience,
  color = colors.paletteBlue1,
  strokeWidth = 2
}: SkillRatingIconProps) => {
  const [levelToShow, setLevelToShow] = useState<Experience | null>(value);
  useEffect(() => {
    setLevelToShow(value);
  }, [value]);

  const handleMouseOut = () => setLevelToShow(value);
  const handleMouseOver = (event) => {
    event.stopPropagation();
    setLevelToShow(event.target.getAttribute("data-level"));
  };

  const selectedIcon = (rating: number) => (
    <>
      <Circle
        cx="9"
        cy="10"
        experience={Experience.Basic}
        rating={rating}
        updateExperience={updateExperience}
        color={color}
        strokeWidth={strokeWidth}
        isEditMode={isEditMode}
        customValue={customValue}
      />
      <Line experience={Experience.Basic} x1="17" x2="22" color={color} strokeWidth={strokeWidth} />
      <Circle
        cx="30"
        cy="10"
        experience={Experience.Intermediate}
        rating={rating}
        updateExperience={updateExperience}
        color={color}
        strokeWidth={strokeWidth}
        isEditMode={isEditMode}
        customValue={customValue}
      />
      <Line experience={Experience.Intermediate} x1="38" x2="43" color={color} strokeWidth={strokeWidth} />
      <Circle
        cx="51"
        cy="10"
        experience={Experience.Advanced}
        rating={rating}
        updateExperience={updateExperience}
        color={color}
        strokeWidth={strokeWidth}
        isEditMode={isEditMode}
        customValue={customValue}
      />
    </>
  );

  return (
    <svg
      className={className}
      data-level={value}
      height={size}
      focusable="false"
      role="presentation"
      viewBox="0 0 60 19"
      onMouseOut={isEditMode ? handleMouseOut : undefined}
      onMouseOver={isEditMode ? handleMouseOver : undefined}
    >
      <g className={css.fill}>{selectedIcon(levelToShow || 0)}</g>
    </svg>
  );
};
