import { isFilter } from "PFApp/search/helpers/normalize_keywords";

const removeChoosenKeyword = (search, store, keywordToRemove) => {
  const foundKeyword = search.choosenKeywords.find((other) => other.id === keywordToRemove.id);

  if (foundKeyword) {
    const update = {
      choosenKeywords: search.choosenKeywords.filter((keyword) => keyword !== foundKeyword),
      deletedKeywords: search.deletedKeywords.concat(foundKeyword)
    };

    if (isFilter(foundKeyword)) {
      const { filterKey } = foundKeyword;
      const prevFilters = search.matchesRequestFilters || {};
      update.matchesRequestFilters = {
        ...prevFilters,
        strings: {
          ...prevFilters?.strings,
          [filterKey]: (prevFilters[filterKey] || []).filter((id) => id !== foundKeyword.id)
        }
      };
    }

    return update;
  } else {
    return {}; // noop
  }
};

export default removeChoosenKeyword;
