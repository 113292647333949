import classNames from "classnames";
import { isArray, map } from "lodash";
import { Typography } from "PFComponents/typography";
import { Variant } from "PFComponents/typography/typography.types";
import { GenericActivityEvent } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useSanitizedAttributes } from "../../sanitize_history_entry";
import css from "./history_updates_attributes.module.scss";
import { UpdatedAttribute } from "./updated_attribute";

const LabelLevelVariants: Variant[] = ["h5", "bodyBold", "bodyRegular"];

type HistoryUpdatedAttributesProps = {
  level?: number;
  label?: string;
  updatedAttributes: GenericActivityEvent["updatedAttributes"];
};

export const HistoryUpdatedAttributes = ({
  label,
  level = 0,
  updatedAttributes
}: HistoryUpdatedAttributesProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history.entry.updatedAttributes" });

  const isLowestLevel = isArray(updatedAttributes);
  const sanitizedAttributes = useSanitizedAttributes(updatedAttributes);

  const renderLabel = (label) => t(label as unknown as TemplateStringsArray, { defaultValue: label });
  const renderUpdatedAttributes = (attributes: GenericActivityEvent["updatedAttributes"]) =>
    map(attributes, (attribute: GenericActivityEvent["updatedAttributes"], key: string) => (
      <HistoryUpdatedAttributes key={key} label={key} updatedAttributes={attribute} level={level + 1} />
    ));

  return (
    <div className={classNames(css.container, { [css.indent]: level > 1 })}>
      {label && (
        <Typography
          className={css.updateAttributeTitle}
          variant={LabelLevelVariants[level - 1] || "labelBold"}
          noMargin
        >
          {renderLabel(label)}
        </Typography>
      )}
      {!isLowestLevel ? (
        renderUpdatedAttributes(sanitizedAttributes)
      ) : (
        <UpdatedAttribute from={sanitizedAttributes[0]} to={sanitizedAttributes[1]} />
      )}
    </div>
  );
};
