import { isEmpty, isEqual, isNil } from "lodash";
import useStorage from "PFCore/helpers/use_storage";
import { OrderOption } from "PFTypes";
import { useEffect } from "react";

const STORAGE_OPTIONS = { expiresIn: 0 };

export type OrderStorage = {
  selectedOrder: OrderOption | {};
  setSelectedOrder: (order: OrderOption) => void;
  noOrderSelected: boolean;
};

const useStorageOrder = ({
  storagePrefix,
  defaultValue,
  options
}: {
  storagePrefix: string;
  defaultValue: OrderOption | {};
  options?: OrderOption[];
}): OrderStorage => {
  const [selectedOrder, setSelectedOrder] = useStorage<OrderOption | { name?: string }>(
    `${storagePrefix}_selectedOrder`,
    defaultValue,
    STORAGE_OPTIONS
  );

  const noOrderSelected = isEqual(selectedOrder, {}) || isNil(selectedOrder);

  // TODO: [ENG-2596] Double check with API if defaulting order is not needed before making API call
  useEffect(() => {
    if (isEmpty(options)) {
      return;
    }
    const isSelectedOptionAvailable = options!.some(({ name }) =>
      isEqual(name, (selectedOrder?.name as string[]) || [])
    );
    if (!isSelectedOptionAvailable) {
      setSelectedOrder(defaultValue);
    }
  }, [options]);

  return {
    selectedOrder,
    setSelectedOrder,
    noOrderSelected
  };
};

export default useStorageOrder;
