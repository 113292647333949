import { InputFieldSet, InputFieldSetProps } from "PFComponents/text/input_field_set";
import { useCallback } from "react";

type IntegerWrapperProps = Omit<InputFieldSetProps, "onChange"> & { onChange: (value: number) => void };

export const IntegerWrapper = ({
  onChange,
  maxLength,
  label,
  error,
  value,
  qaId,
  required
}: IntegerWrapperProps) => {
  const handleChange = useCallback((value: string) => onChange?.(Number(value)), [onChange]);
  return (
    <InputFieldSet
      required={required}
      style={{ marginBottom: 30 }}
      inputType="number"
      onChange={handleChange}
      maxLength={maxLength}
      error={error}
      label={label}
      value={value}
      qaId={qaId}
    />
  );
};
