import { camelizeKeys } from "humps";
import { Divider } from "PFComponents/divider";

import { SearchHighlights } from "../../../search_highlight";
import { ProfileCardProps } from "../../profile_card";
import { ProfileCardProfileInfo } from "../profile_card_profile_info";
import css from "./profile_card_cotent.module.scss";

export type ProfileCardContentProps = ProfileCardProps & {
  onSizeChange: VoidFunction;
};

export const ProfileCardContent = ({ profile, highlights, onSizeChange }: ProfileCardContentProps) => (
  <div className={css.wrapper}>
    <ProfileCardProfileInfo profile={profile} onSizeChange={onSizeChange} />
    {highlights && (
      <>
        <Divider />
        <SearchHighlights highlights={camelizeKeys(highlights)} onToggle={onSizeChange} />
      </>
    )}
  </div>
);
