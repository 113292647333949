import classNames from "classnames";
import PropTypes from "prop-types";

import css from "./button_label.module.scss";

// label that imitates button
const ButtonLabel = ({ children, className, style }) => (
  <div className={classNames(css.root, className)} style={style}>
    {children}
  </div>
);

ButtonLabel.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};

export default ButtonLabel;
