import { camelCase, keys, orderBy, take } from "lodash";
import { Button } from "PFComponents/button";
import {
  ActivitySearchHighlightAttribute,
  ProfileSearchHighlightAttribute,
  SearchHighlightAttribute,
  SearchHighlights as SearchHighlightsType
} from "PFTypes/search_highlights";
import React from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { HighlightRow } from "./parts/highlight_row";
import css from "./search_highlights.module.scss";

type SearchHighlightsProps = {
  highlights: SearchHighlightsType;
  shortLabel?: boolean;
  onToggle?: VoidFunction;
};

const ORDER: Record<SearchHighlightAttribute, number> = {
  [ProfileSearchHighlightAttribute.Name]: 1,
  [ProfileSearchHighlightAttribute.Summary]: 2,
  [ProfileSearchHighlightAttribute.Skills]: 3,
  [ProfileSearchHighlightAttribute.Certificates]: 4,
  [ProfileSearchHighlightAttribute.MainPosition]: 5,
  [ProfileSearchHighlightAttribute.MatchInfo1]: 6,
  [ProfileSearchHighlightAttribute.MatchInfo2]: 7,
  [ProfileSearchHighlightAttribute.MatchInfo3]: 8,
  [ProfileSearchHighlightAttribute.Positions]: 9,
  [ActivitySearchHighlightAttribute.MetadataTextValues]: 10,
  [ActivitySearchHighlightAttribute.Description]: 11
};

export const SearchHighlights = ({ highlights, shortLabel = false, onToggle }: SearchHighlightsProps) => {
  const { t } = useTranslation(["core", "translation"]);
  const [showAll, toggleShowAll] = useToggle(false);

  const rows = orderBy<SearchHighlightAttribute>(
    keys(highlights) as unknown as SearchHighlightAttribute[],
    (attributeKey) => ORDER[camelCase(attributeKey)]
  );

  const displayedRows = showAll ? rows : take(rows, 1);
  const moreThanOneRowExists = rows.length > 1;
  const moreLabel = shortLabel ? t("core:more") : t("core:viewMore");
  const lessLabel = shortLabel ? t("core:less") : t("core:viewLess");

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    toggleShowAll();
    onToggle?.();
  };

  return (
    <section className={css.root}>
      <main className={css.highlightsLayout}>
        {displayedRows.map((attribute) => (
          <HighlightRow
            key={attribute}
            attribute={attribute as SearchHighlightAttribute}
            highlight={highlights[attribute]}
          />
        ))}
      </main>
      <div>
        {moreThanOneRowExists && (
          <Button
            onClick={handleClick}
            kind="text"
            icon={showAll ? "chevron-down" : "chevron-right"}
            text={showAll ? lessLabel : moreLabel}
            small
          />
        )}
      </div>
    </section>
  );
};
