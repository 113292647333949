import classNames from "classnames";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { useGetFormattedPostUntil } from "PFCore/helpers/use_get_formatted_post_until";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Activity } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type DetailsProps = { activity: Activity };

export const Details = ({ activity }: DetailsProps) => {
  const { t } = useTranslation();
  const { formatDate, utc } = useDateFormatter();

  const { created_at: createdAt, post_until: postUntil, vacancies } = activity;
  const { timeHeader, timeValue } = useGetFormattedPostUntil(postUntil);

  const vacanciesTotal = vacancies?.total || 0;
  const vacanciesBooked = (vacancies?.booked || 0) + (vacancies?.confirmed || 0);

  const detailsData = useMemo(
    () => [
      {
        title: timeHeader,
        icon: <Icon name="hourglass-half" size="sm" />,
        text: timeValue
      },
      {
        title: t("createdAt"),
        icon: <Icon name="calendar" size="sm" />,
        text: formatDate(utc(createdAt))
      },
      {
        title: t("vacancies"),
        icon: <Icon name="role" size="sm" />,
        text: vacanciesTotal
      },
      {
        title: t("booked"),
        icon: <Icon name="check" size="sm" />,
        text: vacanciesBooked
      }
    ],
    [t, createdAt, timeHeader, timeValue, vacanciesBooked, vacanciesTotal, formatDate, utc]
  );

  return (
    <span className={classNames(css.details, css.item)}>
      {detailsData.map(({ title, icon, text }) => (
        <span key={title} className={css.item}>
          <Typography variant="bodyBold" noMargin>
            {title}
          </Typography>
          <span className={css.detailItem}>
            {icon}
            <Typography variant="bodyRegular" noMargin tag="span">
              {text}
            </Typography>
          </span>
        </span>
      ))}
    </span>
  );
};
