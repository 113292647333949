import { Id } from "PFTypes";

export enum GIdTargetType {
  Task = "Task",
  Profile = "Profile",
  ChangeRequest = "Changerequest",
  Notification = "Notification",
  ChatConversation = "Chat::Conversation"
}

export type GIdTargetId = Id;
export type GId = `gid://profinda-api/${GIdTargetType}/${GIdTargetId}`;
