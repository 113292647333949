import api from "PFCore/api";
import { Collection } from "PFTypes";

import { Interest } from "./api.types";

export const fetchInterests = (
  profileId: number,
  currentPage: number,
  pageSize: number
): Promise<Collection<Interest[]>> =>
  api({
    url: `profiles/${profileId}/interests`,
    method: "get",
    params: {
      page: currentPage,
      perPage: pageSize
    }
  });
