import classNames from "classnames";
import { Button } from "PFComponents/button";
import PropTypes from "prop-types";

import css from "./options.module.scss";

const Options = ({ options, onSelect }) => (
  <div className={css.sidePanel}>
    {options.map((option) => (
      <div key={option.id}>
        <Button
          kind="blank"
          rootClassName={classNames(option.alternativeStyling ? css.alternativeStyling : css.option, {
            [css.selected]: option.selected,
            [css.noAction]: option.noAction
          })}
          onClick={() => onSelect(option)}
        >
          <div className={css.container}>
            {option.displayElement}
            {option.subtitle && <span className={css.secondary}>{option.subtitle}</span>}
          </div>
        </Button>
      </div>
    ))}
  </div>
);

Options.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired
      }),
      id: PropTypes.string.isRequired,
      displayElement: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      alternativeStyling: PropTypes.bool,
      noAction: PropTypes.bool
    })
  ),
  onSelect: PropTypes.func
};

export default Options;
