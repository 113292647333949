import classNames from "classnames";
import { useGrowl } from "PFApp/use_growl";
import DropDown from "PFComponents/dropdown/dropdown";
import { fetchAutocompleteCustomValues } from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import { ApiRoute } from "PFCore/utilities/routes";
import CheckIcon from "PFIcons/check.svg";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PriorityBadge from "./priority_badge";
import css from "./priority_dropdown.module.scss";

const PriorityDropdown = ({ activity, onSelected }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("workflow", { keyPrefix: "parts.threeDotsMenu" });
  const growl = useGrowl();

  const selectedPriorityId = activity.priority?.[0]?.id;

  useEffect(() => {
    fetchAutocompleteCustomValues({ type: "priority" }).then((response) => setOptions(response));
  }, []);

  const handleSetPriority = (priority) => {
    if (loading) {
      return;
    }
    setLoading(true);
    $.ajax({
      url: ApiRoute(`/api/activities/${activity.id}`),
      method: "PUT",
      data: JSON.stringify({
        custom_fields: [{ type: { name: "priority" }, values: priority ? [{ id: priority.id }] : [] }]
      }),
      api_version: 2,
      contentType: "application/json"
    }).then(
      () => {
        setLoading(false);
        growl({ message: t("priority.success") });
        onSelected && onSelected(priority);
        activity.priority = priority ? [{ id: priority.id, text: priority.text }] : null;

        // Hack to close parent dropdown
        const fakeButton = document.createElement("button");
        const parent = document.getElementById("react_app");
        parent.appendChild(fakeButton);
        fakeButton.click();
        fakeButton.remove();
      },
      () => {
        setLoading(false);
        growl({ message: t("priority.error"), kind: "error" });
      }
    );
  };

  const renderDisplayElement = (option = {}, className) => (
    <div className={classNames(css.priorityOption, className, { [css.loading]: loading })}>
      <PriorityBadge level={option.text?.toLowerCase()} />
      <span>{option.text || t("translation:none")}</span>
      {selectedPriorityId === option.id ? (
        <CheckIcon className={css.checkIcon} width={12} height={12} />
      ) : (
        <div className={css.noIcon} />
      )}
    </div>
  );

  const formattedOptions = useMemo(() => {
    const result = options.map((option) => ({
      id: option.id,
      displayElement: renderDisplayElement(option),
      item: option
    }));

    result.push({
      id: "none",
      displayElement: renderDisplayElement({}, css.none),
      item: null
    });

    return result;
  }, [options, loading]);

  return (
    <DropDown
      rootClassName={css.priorityDropdown}
      options={formattedOptions}
      handleChange={(item) => handleSetPriority(item)}
    />
  );
};

PriorityDropdown.propTypes = {
  onSelected: PropTypes.func,
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    priority: PropTypes.array
  })
};

export default PriorityDropdown;
