import { Id } from "PFTypes";
import { useState } from "react";

import { HeaderTabs } from "./header/side_panel_header";
import { SidePanelTabs } from "./side_panel";

type UseSidePanelTabs<TAB_ID extends Id> = {
  tabsConfig?: SidePanelTabs<TAB_ID>;
};

type UseSidePanelTabsReturn<TAB_ID> = {
  internalTabsConfig: HeaderTabs<TAB_ID> | undefined;
  selectedTabId: TAB_ID | undefined;
};

export const useSidePanelTabs = <TAB_ID extends Id>({
  tabsConfig
}: UseSidePanelTabs<TAB_ID>): UseSidePanelTabsReturn<TAB_ID> => {
  const defaultTabId = tabsConfig?.defaultSelectedTabId ?? tabsConfig?.tabs[0].id;
  const [selectedTabId, setSelectedTabId] = useState<TAB_ID | undefined>(defaultTabId);

  const internalTabsConfig = tabsConfig
    ? {
        tabs: tabsConfig.tabs,
        selectedTabId: selectedTabId ?? tabsConfig?.tabs[0].id,
        onTabClick: (id: TAB_ID) => setSelectedTabId(id)
      }
    : undefined;

  return {
    internalTabsConfig,
    selectedTabId
  };
};
