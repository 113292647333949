import { clone, findLastIndex, pullAt } from "lodash";
import { uniqueId } from "PFCore/helpers/unique_id";

export const GROWL_ACTIONS = {
  GROWL_RENDER: "GROWL_RENDER",
  GROWL_UNRENDER: "GROWL_UNRENDER",
  GROWL_UNRENDER_LAST_BY_TYPE_AND_ID: "GROWL_UNRENDER_LAST_BY_TYPE_AND_ID"
};

export const growlReducer = (state, action) => {
  const { growls } = state;
  const growl = action.payload || {};
  const prevGrowl = growls.filter(({ id }) => id && id === growl.id)[0];
  const shouldReplaceContentOfPrevById = !growl.hidePreviousByTypeAndId && prevGrowl;

  switch (action.type) {
    case GROWL_ACTIONS.GROWL_RENDER:
      if (shouldReplaceContentOfPrevById) {
        prevGrowl.title = growl.title;
        prevGrowl.message = growl.message;
      }

      return {
        ...state,
        growls: shouldReplaceContentOfPrevById
          ? growls
          : [...growls, { id: uniqueId("growl"), timestamp: new Date().getTime(), ...growl }]
      };

    case GROWL_ACTIONS.GROWL_UNRENDER:
      return {
        ...state,
        growls: state.growls.filter(({ id }) => id !== growl.id)
      };

    case GROWL_ACTIONS.GROWL_UNRENDER_LAST_BY_TYPE_AND_ID: {
      const indexToRemove = findLastIndex(growls, { kind: growl.kind, id: growl.id });
      if (indexToRemove < 0) {
        return state;
      }
      const growlsCopy = clone(growls);
      pullAt(growlsCopy, indexToRemove);
      return {
        ...state,
        growls: growlsCopy
      };
    }
  }
};
