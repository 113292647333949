import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { merge } from "lodash";
import {
  fetchActivityHistory,
  FetchActivityHistoryResponse
} from "PFCore/services/activity/activity_history";
import { QueryOptions, QueryParams } from "PFTypes";

import { useActivityHistoryContext } from "../activity_history_context";
import { activityHistoryKeys } from "./query_keys";

export const PER_PAGE = 10;

export const useActivityHistory = (
  params?: QueryParams,
  options: QueryOptions<FetchActivityHistoryResponse> = {}
) => {
  const { activity, page, sortBy } = useActivityHistoryContext();

  return useQuery<FetchActivityHistoryResponse, AxiosError>(
    activityHistoryKeys.list(activity?.id || "", merge({ page, perPage: PER_PAGE, sortBy }, params)),
    () => fetchActivityHistory(activity!.id, merge({ page, perPage: PER_PAGE, sortBy }, params)),
    {
      enabled: !!activity?.id,
      retry: false,
      ...options
    }
  );
};
