import { Button, ButtonProps } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";

type TooltipButtonIconProps = Pick<ButtonProps, "icon" | "onClick" | "disabled" | "qaId"> & {
  tooltipContent: string;
};

export const TooltipButtonIcon = ({ tooltipContent, ...buttonProps }: TooltipButtonIconProps) => (
  <Tooltip
    content={
      <Typography variant="bodyRegular" noMargin>
        {tooltipContent}
      </Typography>
    }
  >
    <div>
      <Button kind="secondary" {...buttonProps} />
    </div>
  </Tooltip>
);
