import { Query, useQueryClient } from "@tanstack/react-query";
import { FetchBookings } from "PFApp/booking/services/api";

import { profileKeys } from "../profile";
import { bookingKeys } from "./query_keys";

export const useBookingInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (id?: number) => {
      if (id === undefined) {
        return queryClient.invalidateQueries(bookingKeys.all());
      }

      return queryClient.invalidateQueries(bookingKeys.single(id));
    },
    invalidateBookingsForBookingGroup: (bookingGroupId: number | string) => {
      const allStoredBookingsCache = queryClient.getQueryCache().findAll(bookingKeys.all()) as Query<{
        booking_group_id?: number;
      }>[];
      const keys = allStoredBookingsCache
        .filter(({ state }) => state.data?.booking_group_id === bookingGroupId)
        .map(({ queryKey }) => queryKey);

      keys.forEach((key) =>
        queryClient.invalidateQueries({
          queryKey: key
        })
      );
    },
    invalidateProfileBookingsList: (profileId: number) => {
      // TODO: [SP-1742] Improve invalidating cache
      const queryKey = profileKeys.bookings(profileId);
      return queryClient.invalidateQueries({ queryKey });
    },
    invalidateList: (params?: FetchBookings) => {
      // TODO: [SP-1742] Improve invalidating cache
      if (!params) {
        return queryClient.invalidateQueries(profileKeys.all());
      }
      return queryClient.invalidateQueries(profileKeys.bookings(params.profileId));
    }
  };
};
