import { useQuery } from "@tanstack/react-query";
import { VacanciesCollection } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { fetchVacancies, FetchVacanciesParams } from "../../../services/vacancies";
import activityKeys from "./query_keys";

export const useActivityVacancies = (
  params: FetchVacanciesParams,
  options?: QueryOptions<VacanciesCollection>
) =>
  useQuery(activityKeys.vacancies(params.activityId, params), () => fetchVacancies(params), {
    retry: false,
    staleTime: 0,
    ...options
  });
