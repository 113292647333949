import { ParsedSchemaProperty, PureCustomValue } from "PFTypes";
import PropTypes from "prop-types";

export type ComponentProps = {
  property: ParsedSchemaProperty;
  onChange: (values: PureCustomValue | PureCustomValue[], property?: ParsedSchemaProperty) => any;
  value?: string;
  label?: string;
  qaId?: string;
  style?: Record<string, any>;
  required?: boolean;
  error?: string;
};

export const ComponentPropTypes = {
  property: PropTypes.shape({
    type: PropTypes.string,
    kind: PropTypes.oneOf(["multiple", "single", "nested"]),
    required: PropTypes.array,
    name: PropTypes.string,
    enum: PropTypes.array
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  qaId: PropTypes.string,
  style: PropTypes.object,
  required: PropTypes.bool,
  error: PropTypes.string
};

export default ComponentPropTypes;
