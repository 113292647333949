import classNames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";

import css from "./chats_section.module.scss";
import ChatsSectionMainChat from "./chats_section_main_chat";
import ChatsSectionOtherChat from "./chats_section_other_chats";

const ChatsSection = ({
  activity,
  allParticipants,
  primaryConversation,
  nonOwnerParticipants,
  otherConversations,
  participantsOfConversations,
  isEngagement,
  showMainChat,
  showOtherChats,
  className
}) => {
  const [collapsed, setCollapsed] = useState(true);

  if (!showMainChat && !showOtherChats) {
    return null;
  }

  const handleChatSectionClick = () => setCollapsed((prev) => !prev);

  return (
    <div className={classNames(css.chatsArea, className)}>
      <div className={css.header} data-qa-id="activities-chat-section-container">
        {showMainChat && (
          <ChatsSectionMainChat
            collapsed={collapsed}
            handleChatSectionClick={handleChatSectionClick}
            isEngagement={isEngagement}
            primaryConversation={primaryConversation}
            allParticipants={allParticipants}
            nonOwnerParticipants={nonOwnerParticipants}
            renderSeparator
            qaId="ActivitiesMainChatSectionToggle"
          />
        )}

        {showOtherChats && (
          <ChatsSectionOtherChat
            collapsed={collapsed}
            handleChatSectionClick={handleChatSectionClick}
            participantsOfConversations={participantsOfConversations}
            otherConversations={otherConversations}
            showMainChat={showMainChat}
            activity={activity}
            qaId="ActivitiesOtherChatSectionToggle"
          />
        )}
      </div>
    </div>
  );
};

ChatsSection.propTypes = {
  activity: PropTypes.object.isRequired,
  showMainChat: PropTypes.bool,
  showOtherChats: PropTypes.bool,
  allParticipants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      profile: PropTypes.object.isRequired
    })
  ),
  primaryConversation: PropTypes.object,
  nonOwnerParticipants: PropTypes.array,
  otherConversations: PropTypes.array,
  participantsOfConversations: PropTypes.object,
  isEngagement: PropTypes.bool,
  className: PropTypes.string
};

export default ChatsSection;
