import classNames from "classnames";

import css from "./modal_layout.module.scss";
import { ModalLayoutFooter, ModalLayoutFooterProps } from "./modal_layout_footer";
import { ModalLayoutHeader, ModalLayoutHeaderProps } from "./modal_layout_header";

export type ModalLayoutProps = {
  children?: React.ReactNode;
  classes?: {
    body?: string;
  };
} & ModalLayoutHeaderProps &
  ModalLayoutFooterProps;

export const ModalLayout = ({
  title,
  subtitle,
  children,
  classes = {},
  onOK,
  onCancel,
  labelOK,
  kindOK,
  showOKButton = true,
  disableOKButton = false,
  ariaLabelOK,
  labelCancel,
  footerLabel,
  showCancelButton = true,
  ariaLabelCancel
}: ModalLayoutProps) => (
  <div className={css.confirm}>
    <ModalLayoutHeader title={title} subtitle={subtitle} />
    <main className={classNames(css.body, classes.body)}>{children}</main>
    <ModalLayoutFooter
      labelOK={labelOK}
      kindOK={kindOK}
      showOKButton={showOKButton}
      disableOKButton={disableOKButton}
      ariaLabelOK={ariaLabelOK}
      labelCancel={labelCancel}
      footerLabel={footerLabel}
      showCancelButton={showCancelButton}
      ariaLabelCancel={ariaLabelCancel}
      onOK={onOK}
      onCancel={onCancel}
    />
  </div>
);
