import { useQueryClient } from "@tanstack/react-query";

import { projectsKeys } from "./query_keys";

export const useProjectsInvalidate = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => queryClient.invalidateQueries(projectsKeys.all()),
    removeCache: () => queryClient.removeQueries(projectsKeys.all())
  };
};
