import { useActivityEditFormContext } from "../../activity_edit_form_context";
import { useGetCustomFieldsToLink } from "../use_get_custom_fields_to_link";
import { areValuesEqual } from "./helpers";

export const useEngagementLinkStatus = () => {
  const { selectedParentActivity, customFields } = useActivityEditFormContext();
  const getLinkedCustomFields = useGetCustomFieldsToLink();

  if (!selectedParentActivity) {
    return { hasAnyLinkedValue: false, hasAnyValueChanged: false };
  }

  const linkedCustomFields = getLinkedCustomFields(selectedParentActivity);

  const hasAnyLinkedValue = linkedCustomFields.some(({ values }) => values.length > 0);

  const hasAnyValueChanged = linkedCustomFields.some((linkedCustomField) => {
    const customField = customFields.find(({ type }) => type.id === linkedCustomField.type.id);
    if (!customField) {
      return false;
    }

    return !areValuesEqual(customField?.values, linkedCustomField.values);
  });

  return { hasAnyLinkedValue, hasAnyValueChanged };
};
