import { useTranslation } from "react-i18next";

import css from "./ms_teams_sign_in.module.scss";

export const MsTeamsDeadEnd = () => {
  const { t } = useTranslation("msTeams");

  return (
    <div className={css.deadEnd}>
      <h2>{t("deadEnd")}</h2>
    </div>
  );
};
