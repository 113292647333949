import { Content, Header } from "./parts";

type LandingLayoutWithoutSidebarProps = {
  children?: React.ReactNode;
  alignTop?: boolean;
};

export const LandingLayoutWithoutSidebar = ({
  children,
  alignTop = false
}: LandingLayoutWithoutSidebarProps) => (
  <div data-qa-id="LandingLayout">
    <Header />
    <Content maxWidth={1000} alignTop={alignTop}>
      {children}
    </Content>
  </div>
);
