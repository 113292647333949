import { ApiRoute } from "PFCore/utilities/routes";

export const deleteAttachment = (id) => {
  // @ts-ignore
  $.ajax({
    url: ApiRoute(`/api/attachments/${id}`),
    method: "DELETE",
    api_version: 2
  });
};
