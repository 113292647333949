import { useReducer } from "react";

import {
  INITIAL_OVERBOOKINGS_MANAGEMENT_STATE,
  OverbookedBooking,
  OverbookingsManagementState
} from "./overbookings_management_context";

type OverbookingsManagementActions =
  | {
      type: "setOverbookedBookings";
      overbookedBookings: OverbookedBooking[];
      profileId: number | undefined;
      activityId: number | undefined;
    }
  | {
      type: "clearOverbookedBookings";
    };

const overbookingsManagementReducer = (
  state: OverbookingsManagementState,
  action: OverbookingsManagementActions
): OverbookingsManagementState => {
  switch (action.type) {
    case "setOverbookedBookings":
      return {
        ...state,
        overbookedBookings: action.overbookedBookings,
        profileId: action.profileId,
        activityId: action.activityId
      };
    case "clearOverbookedBookings":
      return INITIAL_OVERBOOKINGS_MANAGEMENT_STATE;
    default:
      return state;
  }
};

export const useOverbookingsManagementReducer = () =>
  useReducer(overbookingsManagementReducer, INITIAL_OVERBOOKINGS_MANAGEMENT_STATE);
