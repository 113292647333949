import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { OrderMeta, OrderOption } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";

import SortBy from "../../../../components/sort_by";
import css from "./matches_content.module.scss";

type MatchesActionsProps = {
  onRefresh: VoidFunction;
  isLoading: boolean;
  lastRefreshAt: string | null;
  orderMeta: OrderMeta | undefined;
  onOrderChange: (orderOption: OrderOption) => void;
};

export const MatchesActions = ({
  onRefresh,
  isLoading,
  lastRefreshAt,
  orderMeta,
  onOrderChange
}: MatchesActionsProps) => {
  const { t } = useTranslation("bookingModule");
  const { formatDateTime } = useDateFormatter();

  return (
    <div className={css.actions}>
      <div>
        {!!orderMeta && (
          <SortBy
            sort={orderMeta.value?.text}
            options={orderMeta.options.map((option) => ({
              id: option.text,
              displayElement: option.text,
              item: option
            }))}
            onSortChange={onOrderChange}
            kind="blank"
            noLabel
            disabled={isLoading}
          />
        )}
      </div>

      <div className={css.refreshAction}>
        <Typography variant="labelRegular" tag="span">
          {t("bookings.reassign.sidePanel.lastUpdated", {
            date: lastRefreshAt ? formatDateTime(lastRefreshAt) : "-"
          })}
        </Typography>
        <Button icon="refresh" kind="secondary" onClick={onRefresh} disabled={isLoading} />
      </div>
    </div>
  );
};
