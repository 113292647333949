import { FetchRolesParams } from "PFCore/services/roles";

export const rolesKeys = {
  all: () => ["roles"],
  list: (params: FetchRolesParams) => [...rolesKeys.all(), params]
};

export const auditRolesKeys = {
  all: () => ["auditRoles"],
  list: (params: FetchRolesParams) => [...auditRolesKeys.all(), params]
};
