import Color from "color";

export const getBlendedColor = (colorCodes: (string | undefined)[], ratio: number = 0.5) =>
  colorCodes
    .filter((color) => !!color)
    .map((color) => Color(color))
    .reduce((mergedColor, colorCode) => mergedColor.mix(colorCode, ratio));

export const getBlendedColorOnWhite = (colorCodes: (string | undefined)[], ratio: number = 0.5) =>
  getBlendedColor(["white", ...colorCodes], ratio);

/**
 * @param backgroundColor
 * @param contrast 0.0 - 1.0
 */
export const getContrastingTextColor = (backgroundColor: string, contrast = 0.7) => {
  const background = Color(backgroundColor);
  return background.isLight()
    ? background.mix(Color("black"), contrast)
    : background.mix(Color("white"), contrast);
};
