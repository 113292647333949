import classNames from "classnames";
import { Accordion } from "PFComponents/accordion";
import { Profile } from "PFTypes";

import { HistoryEntryRow } from "../history_entry_row";
import { HistoryEntrySubject } from "../history_event_subject";
import css from "./history_entry_profiles_accordion.module.scss";

type HistoryEntryProfilesAccordionProps = {
  profiles: Profile[];
  badge: string;
};

export const HistoryEntryProfilesAccordion = ({ profiles, badge }: HistoryEntryProfilesAccordionProps) => (
  <HistoryEntryRow className={css.firstProfileRow}>
    <Accordion header={badge} headerSize="small" defaultState="open">
      <div className={classNames(css.profiles)}>
        {profiles.map((targetProfile) => (
          <HistoryEntrySubject key={`history-entry-subject-${targetProfile.id}`} profile={targetProfile} />
        ))}
      </div>
    </Accordion>
  </HistoryEntryRow>
);
