import { RefObject, useEffect, useState } from "react";

export const useInputWidth = (inputRef: RefObject<HTMLInputElement>) => {
  const [inputWidth, setInputWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (inputRef.current) {
        setInputWidth(inputRef.current.clientWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return inputWidth;
};
