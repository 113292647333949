import { useQuery } from "@tanstack/react-query";
import { fetchActivityFeedbackParticipants } from "PFCore/services/activities";
import { Collection } from "PFTypes";
import { ActivityParticipant } from "PFTypes/activity_participant";
import { QueryOptions } from "PFTypes/request_options";

import activityKeys from "./query_keys";

export type FeedbackParticipantsResponseType = Collection<ActivityParticipant[]>;

export const useActivityFeedbackParticipants = (
  activityId?: number,
  options?: QueryOptions<FeedbackParticipantsResponseType>
) =>
  useQuery(
    activityKeys.feedbackParticipants(activityId),
    () => {
      if (!activityId) {
        return;
      }
      return fetchActivityFeedbackParticipants(activityId);
    },
    {
      retry: false,
      staleTime: 0,
      ...options
    }
  );
