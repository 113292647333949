import { AxiosRequestConfig } from "axios";
import api from "PFCore/api";
import { Collection, Keyword } from "PFTypes";
import { Id } from "PFTypes/id";

export type KeywordsParams = {
  page?: number;
  perPage?: number;
  text?: string;
  selectedIds?: Id[];
  matchTypes?: string[];
  filterTypes?: string[];
};

const defaultBody: KeywordsParams = {
  page: 1,
  perPage: 25,
  text: "",
  matchTypes: ["skills"]
};

export const fetchKeywords = (
  params: KeywordsParams,
  options: AxiosRequestConfig = {}
): Promise<Collection<Keyword[]>> =>
  api({
    method: "post",
    url: "keywords",
    data: { ...defaultBody, ...params },
    ...options
  });
