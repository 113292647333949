import { SortDirection } from "@tanstack/react-table";
import ArrowDropDown from "PFIcons/arrow_drop_down.svg";
import ArrowDropUp from "PFIcons/arrow_drop_up.svg";

import css from "./table.module.scss";

type TableSortingProps = {
  isSorted: false | SortDirection;
};

export const TableSorting = ({ isSorted }: TableSortingProps): JSX.Element => (
  <div className={css.sorting}>
    {(!isSorted || isSorted === "asc") && <ArrowDropUp />}
    {(!isSorted || isSorted === "desc") && <ArrowDropDown />}
  </div>
);
