import { find } from "lodash";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Template } from "PFTypes";
import { useCallback } from "react";

export const useTemplateFind = () => {
  const { data: currentProfile } = useCurrentProfile();

  return useCallback(
    (attributesToCheck: Partial<Template>) => find(currentProfile.templates, attributesToCheck),
    [currentProfile.templates]
  );
};
