import { last } from "lodash";
import { ActivityParticipant, FeedbackRoundInfo, ParticipatedRole } from "PFTypes/activity_participant";
import { createContext, SetStateAction, useContext, useState } from "react";

import { FeedbackFormData } from "./form/feedback_form_context_provider";
import { useManagerFeedbackParticipants } from "./hooks/use_manager_feedback_participants";

type FormSectionData = FeedbackFormData & { round?: number };

export type FeedbackEntry = {
  roleId: number;
  profileId: number;
};

type FeedbackContext = {
  selectedEntry: FeedbackEntry | undefined;
  setSelectedEntry: React.Dispatch<SetStateAction<FeedbackEntry | undefined>>;
  selectedRole: ParticipatedRole | undefined;
  selectedParticipant: ActivityParticipant | undefined;
  setCurrentFormData: React.Dispatch<SetStateAction<FeedbackFormData | null>>;
  currentFormData: FeedbackFormData | null;
  setActiveFeedbackRound: React.Dispatch<SetStateAction<FeedbackRoundInfo | null>>;
  activeFeedbackRound: FeedbackRoundInfo | null;
  setSavedForms: React.Dispatch<SetStateAction<Record<number, FeedbackFormData | null>>>;
  savedForms: Record<number, FormSectionData | null>;
};

const FeedbackContext = createContext({} as FeedbackContext);

export const FeedbackContextProvider = ({ children }): JSX.Element => {
  const [selectedEntry, setSelectedEntry] = useState<FeedbackEntry | undefined>(undefined);

  const onParticipantsFetch = (data) => {
    if (!selectedRole) {
      setSelectedEntry({ roleId: data.entries[0].roles[0].id, profileId: data.entries[0].id });
      setActiveFeedbackRound(last(data.entries[0].roles[0].feedback) || null);
    } else if (selectedRole.feedback.length > 0) {
      setActiveFeedbackRound(
        last(
          data?.entries
            ?.find((participant) => participant.id === selectedEntry?.profileId)
            ?.roles.find((role) => role.id === selectedEntry?.roleId)?.feedback
        ) || null
      );
    }
  };

  const { selectedParticipant, selectedRole } = useManagerFeedbackParticipants(
    selectedEntry?.profileId,
    selectedEntry?.roleId,
    onParticipantsFetch
  );

  const [activeFeedbackRound, setActiveFeedbackRound] = useState<FeedbackRoundInfo | null>(
    selectedRole?.feedback?.[0] || null
  );
  const [currentFormData, setCurrentFormData] = useState<FeedbackFormData | null>(null);
  const [savedForms, setSavedForms] = useState<Record<number, FormSectionData | null>>({});

  return (
    <FeedbackContext.Provider
      value={{
        selectedEntry,
        setSelectedEntry,
        selectedParticipant,
        selectedRole,
        setCurrentFormData,
        currentFormData,
        setActiveFeedbackRound,
        activeFeedbackRound,
        savedForms,
        setSavedForms
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedbackContext = () => useContext(FeedbackContext);
