import { useCustomValueToString } from "PFCore/helpers/custom_value";
import { getJobTitle } from "PFCore/helpers/profile";
import OfficeIcon from "PFIcons/office.svg";
import SectorIcon from "PFIcons/sector.svg";
import SubManagementIcon from "PFIcons/sub_management.svg";
import UserIcon from "PFIcons/user.svg";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

import css from "./headline.module.scss";

// headline relies on both: main position's job title as well as prosile's
// custom_fields (of section "match_info_2" and "match_info_3")

const Headline = ({ profile, customTypes }) => {
  const { getCustomValuesListString } = useCustomValueToString();

  const getMatchInfoValue = useCallback(
    (sectionKey, profile, customTypes) => {
      const type = customTypes.find(({ sections }) => (sections || []).find(({ key }) => key === sectionKey));
      const customValues = (type && profile[type.name]) || [];
      return customValues.length && getCustomValuesListString(customValues, type.value_type);
    },
    [getCustomValuesListString]
  );

  const data = useMemo(
    () =>
      [
        { Icon: UserIcon, text: getJobTitle(profile) },
        {
          Icon: SubManagementIcon,
          text: getMatchInfoValue("match_info_1", profile, customTypes)
        },
        { Icon: SectorIcon, text: getMatchInfoValue("match_info_2", profile, customTypes) },
        { Icon: OfficeIcon, text: getMatchInfoValue("match_info_3", profile, customTypes) }
      ].filter((item) => !!item.text),
    [profile, customTypes]
  );

  return (
    <div className={css.headline}>
      {data.map(({ Icon, text }, index) => (
        <div key={index} className={css.item}>
          <Icon
            className={css.headline__icon}
            width={15}
            height={18}
            viewBox={"7 0 10 20"}
            fill="var(--Palette-nav)"
          />
          <span>{text}</span>
        </div>
      ))}
    </div>
  );
};

Headline.propTypes = {
  profile: PropTypes.object.isRequired,
  customTypes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Headline;
