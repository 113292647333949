import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useProjectsInvalidate } from "PFCore/hooks/queries/bookings/projects/use_projects_invalidate";
import { useCallback } from "react";

import useRowChildrenInvalidate from "../parts/overview/side_panel_row/queries/use_row_children_invalidate";

type ActivityUpdatedEvent = {
  activity_id: number;
  parent_activity_id: number;
};

type UseActivityUpdatedHandlerReturn = (e: ActivityUpdatedEvent) => void;

export const useActivityUpdatedHandler = (): UseActivityUpdatedHandlerReturn => {
  const { invalidate: invalidateActivities } = useActivityInvalidate();
  const { invalidate: invalidateProjects } = useProjectsInvalidate();
  const { invalidateByActivityIds: invalidateRowChildrenByActivityIds } = useRowChildrenInvalidate();

  return useCallback(
    ({ activity_id: activityId, parent_activity_id: parentActivityId }) => {
      invalidateActivities([activityId, parentActivityId]);
      invalidateRowChildrenByActivityIds([activityId, parentActivityId]);
      invalidateProjects();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
