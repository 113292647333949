import { isEmpty, keys, omit } from "lodash";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Activity, CustomField, Metadata, Subtemplate } from "PFTypes";
import { useCallback, useMemo, useState } from "react";

import { FormFieldData } from "../../activity_edit_form.types";
import { useActivityEditPageContext } from "../../activity_edit_page_context";
import { useDynamicSubtemplateFields } from "./use_dynamic_subtemplate_fields";

type UseSubtemplates = {
  subtemplates: Subtemplate[];
  customFields: CustomField[];
  setCustomFields: React.Dispatch<React.SetStateAction<CustomField[]>>;
  metadata: Metadata;
  setMetadata: React.Dispatch<React.SetStateAction<Metadata>>;
  removeErrorIfPresent: (fieldName: string) => void;
  populateDefaultsEnabled: boolean;
  selectedParentActivity?: Activity;
  cachedSubtemplateValues: FormFieldData[];
  cachedFrameworksValues: FormFieldData[];
  saveSubtemplateValuesInCache: (values: FormFieldData[]) => void;
};

export type UseSubtemplatesReturn = {
  subtemplateOptions: DropdownOption[];
  hasSubtemplates: boolean;
  subtemplateKey: string | null;
  subtemplate: Subtemplate | null;
  handleSubtemplateChange: (value: string) => void;
  isLoading: boolean;
};

export const useSubtemplates = ({
  subtemplates,
  customFields,
  setCustomFields,
  metadata,
  setMetadata,
  removeErrorIfPresent,
  populateDefaultsEnabled,
  selectedParentActivity,
  cachedFrameworksValues,
  cachedSubtemplateValues,
  saveSubtemplateValuesInCache
}: UseSubtemplates): UseSubtemplatesReturn => {
  const { activity } = useActivityEditPageContext();

  const hasSubtemplates = !isEmpty(subtemplates);
  const [subtemplateKey, setSubtemplateKey] = useState<string | null>(
    hasSubtemplates ? activity?.subtemplate_key ?? null : null
  );
  const [isLoading, setIsLoading] = useState(false);

  const subtemplateOptions: DropdownOption[] = useMemo(
    () =>
      subtemplates.map((subtemplate) => ({
        id: subtemplate.subtemplate_key,
        displayElement: subtemplate.subtemplate_title,
        item: subtemplate.subtemplate_key
      })),
    [subtemplates]
  );

  const subtemplate = subtemplates.find(({ subtemplate_key: key }) => subtemplateKey === key) ?? null;

  useDynamicSubtemplateFields({
    subtemplate,
    enabled: populateDefaultsEnabled,
    selectedParentActivity,
    cachedFrameworksValues,
    cachedSubtemplateValues,
    saveSubtemplateValuesInCache,
    setCustomFields,
    setMetadata,
    setIsLoading
  });

  const clearAttributes = useCallback(
    (attributesToClear: string[]) => {
      const updatedCustomFields = customFields.filter(
        (customField) => !attributesToClear.includes(customField.type.name)
      );
      setCustomFields(updatedCustomFields);
      const newMetadata = omit(metadata, attributesToClear);
      setMetadata(newMetadata);
    },
    [customFields, metadata]
  );

  const handleSubtemplateChange = useCallback(
    (newSubtemplateKey: string) => {
      if (newSubtemplateKey === subtemplateKey) {
        return;
      }
      setIsLoading(true);

      if (subtemplate) {
        clearAttributes(keys(omit(subtemplate.schema.properties, "availability", "skills")));
      }
      setSubtemplateKey(newSubtemplateKey);
      removeErrorIfPresent("roleForm");

      if (!populateDefaultsEnabled) {
        setIsLoading(false);
      }
    },
    [subtemplateKey, subtemplate, removeErrorIfPresent, populateDefaultsEnabled, clearAttributes]
  );

  return {
    subtemplateOptions,
    hasSubtemplates,
    subtemplateKey,
    subtemplate,
    handleSubtemplateChange,
    isLoading
  };
};
