import { useMemo } from "react";

export const useCategorizedSkills = (skills, skillsCategories) => {
  const sortSkills = (collection) =>
    collection.sort((a, b) => {
      var skillA = a.text?.toUpperCase() || a.value?.toUpperCase();
      var skillB = b.text?.toUpperCase() || b.value?.toUpperCase();
      if (skillA < skillB) {
        return -1;
      }
      if (skillA > skillB) {
        return 1;
      }
      return 0;
    });

  const [skillCategories, otherSkills] = useMemo(() => {
    let otherSkills = [];

    const acc = (skills || []).reduce((acc, skill) => {
      const skillCategory = (skillsCategories || []).find(
        (item) => item[0].toLowerCase() === skill.value.toLowerCase()
      );
      if (skillCategory) {
        // the last one is experience, before the last one was the category name. Now is on the 3rd position
        const name = skillCategory[2];
        acc[name] = [skill, ...(acc[name] || [])];
      } else {
        otherSkills = [skill, ...otherSkills];
      }
      return acc;
    }, {});

    //acc sorting in alphabetical order
    const sortedSkillsCategories = Object.keys(acc).sort();
    const sortedSkills = sortedSkillsCategories.reduce(
      (sum, category) => ({
        ...sum,
        [category]: sortSkills(acc[category])
      }),
      {}
    );

    return [sortedSkills, sortSkills(otherSkills)];
  }, [skillsCategories, skills]);

  return [skillCategories, otherSkills];
};
