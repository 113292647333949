import { camelCase } from "lodash";
import { LandingLayoutWithLandingDescription } from "PFApp/components/landing_layout";
import { useSession } from "PFApp/use_session";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import i18n from "PFCore/i18n";
import { ApiRoute } from "PFCore/utilities/routes";
import { FeatureFlag } from "PFTypes";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory } from "react-router-dom";

import css from "./edit_forgotten_password_page.module.scss";

const EditForgottenPasswordPage = () => {
  const history = useHistory();
  const tokenRef = useRef(
    new URLSearchParams(window.location.search).get("reset_password_token") ||
      window.storage.getItem("reset_password_token")
  );

  const [message, setMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const { data: currentAccount } = useCurrentAccount();
  const isEnabled = useIsFeatureEnabled();

  const { t } = useTranslation();
  const { signIn } = useSession();

  useEffect(() => {
    if (window.storage.getItem("reset_password_token") === null) {
      window.storage.setItem("reset_password_token", tokenRef.current);
    }
  }, []);

  if (!tokenRef.current) {
    return <Redirect to="/" />;
  }

  const submit = (event) => {
    event.preventDefault(); // prevent form submit navigation
    setIsLoading(true);
    setErrors({});

    $.post({
      url: ApiRoute("/api/auth/edit_password"),
      data: {
        user: {
          account_id: currentAccount.id,
          password,
          password_confirmation: password,
          reset_password_token: tokenRef.current
        }
      }
    })
      .then(({ email }) => {
        signIn(email, password).then(() => history.push("/"));
      })
      .catch(({ responseJSON }) => {
        setIsLoading(false);
        if (responseJSON.errors.base && responseJSON.errors.base.length > 0) {
          const error = responseJSON.errors.base[0];
          setMessage(i18n.t(camelCase(error), { defaultValue: error }));
        } else if (responseJSON.message) {
          setMessage(responseJSON.message); // regular errors
        }
        setErrors(responseJSON.errors); // input errors
      });
  };

  const isPasswordSignInEnabled = isEnabled(FeatureFlag.PasswordSignin);
  const isRequestAccessEnabled = isEnabled(FeatureFlag.RequestAccess);

  return (
    <LandingLayoutWithLandingDescription>
      <strong data-qa-id="EditForgottenPasswordPage.header">{t("forgotPassword.editPassword")}</strong>

      {message && (
        <div className={css.marginBottom} data-qa-id="EditForgottenPasswordPage.successMessage">
          {message}
        </div>
      )}

      <form onSubmit={submit}>
        <InputFieldSet
          kind="white"
          showPasswordToggle
          qaId="EditForgottenPasswordPage.password"
          label={t("forgotPassword.newPassword")}
          inputType="password"
          className={css.marginBottom}
          onChange={setPassword}
          error={errors.password}
        />

        <Button
          qaId="EditForgottenPasswordPage.submit"
          className={css.button}
          disabled={isLoading || !password.trim()}
        >
          {isLoading ? <LoadingDots /> : t("forgotPassword.changePassword")}
        </Button>
      </form>

      <div className={css.marginBottom}>
        <Link to="/" data-qa-id={"EditForgottenPasswordPage.signInLink"}>
          {t("forgotPassword.signIn")}
        </Link>
      </div>

      {isPasswordSignInEnabled && (
        <>
          <div>
            <span>{t("forgotPassword.dontHaveAccount")}</span>
          </div>
          <div>
            <Link
              data-qa-id={
                isRequestAccessEnabled
                  ? "EditForgottenPasswordPage.requestAccess"
                  : "EditForgottenPasswordPage.signUp"
              }
              to={isRequestAccessEnabled ? "/request_access" : "/users/signup"}
            >
              {t("forgotPassword.signUp")}
            </Link>
          </div>
        </>
      )}
    </LandingLayoutWithLandingDescription>
  );
};

export default EditForgottenPasswordPage;
