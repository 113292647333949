import { useQuery } from "@tanstack/react-query";
import { fetchSavedFilters, SavedFiltersResponse } from "PFCore/services/saved_filters";
import { QueryOptions } from "PFTypes/request_options";
import { PageTarget } from "PFTypes/saved_filters";

import { savedFiltersKeys } from "./query_keys";

export const useSavedFilters = (target: PageTarget, options: QueryOptions<SavedFiltersResponse> = {}) =>
  useQuery<SavedFiltersResponse>({
    queryFn: () => fetchSavedFilters(target),
    queryKey: savedFiltersKeys.target(target),
    ...options
  });
