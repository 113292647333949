import classnames from "classnames";
import { useFiltersPanelContext } from "PFApp/components/filters/context/filters_panel_context";
import { PlainFilters } from "PFApp/components/filters/plain_filters";
import { filterElements } from "PFApp/use_filtered_collection";
import { UseMetaFiltersReturn } from "PFApp/use_filtered_collection/use_meta_filters";
import { Button } from "PFComponents/button";
import { Meta } from "PFTypes";
import { HTMLAttributes, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { SidePanelHeader } from "../side_panel_header";
import css from "./side_filters.module.scss";

type SideFiltersProps = {
  className?: HTMLAttributes<HTMLDivElement>["className"];
  isPending?: boolean;
  meta: Meta;
  total: number;
  omittedFilters: string[];
  extraToggleCondition?: boolean;
  qaId?: string;
} & UseMetaFiltersReturn;

export const SideFilters = ({
  className,
  isPending,
  meta,
  omittedFilters,
  total,
  areFiltersClear,
  updateFilter,
  clearFilters,
  resetFilters,
  extraToggleCondition,
  qaId
}: SideFiltersProps) => {
  const { openFiltersPanel, isFiltersPanelOpen, toggleFiltersDisabled } = useFiltersPanelContext();
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel" });

  const [filtersOpen, toggleFiltersOpen] = useToggle(false);

  const filters = filterElements(meta.filters, omittedFilters).filter(({ important }) => !!important);

  useEffect(() => {
    toggleFiltersDisabled(!filtersOpen);
  }, [filtersOpen, toggleFiltersDisabled]);

  useEffect(() => {
    if (extraToggleCondition || !areFiltersClear) {
      toggleFiltersOpen(true);
    }
  }, [extraToggleCondition, areFiltersClear, toggleFiltersOpen]);

  const handleFiltersToggle = () => {
    if (filtersOpen) {
      resetFilters();
    } else {
      clearFilters?.(meta?.default_filters);
    }

    toggleFiltersOpen();
  };

  return (
    <div className={classnames(css.root, className)}>
      <SidePanelHeader
        icon="filter"
        title={
          filtersOpen
            ? t("filters.titleWithCounter", {
                count: total || 0
              })
            : t("filters.title")
        }
        checked={filtersOpen}
        label={
          <Button
            kind="text"
            className={css.toggleFiltersButton}
            onClick={() => openFiltersPanel(true)}
            disabled={isFiltersPanelOpen}
          >
            {t("filters.toggleLabel")}
          </Button>
        }
        qaId={qaId}
        onToggle={handleFiltersToggle}
      />
      {filters.length > 0 && filtersOpen && (
        <PlainFilters
          narrow
          pending={isPending}
          meta={meta}
          onFilterChange={updateFilter}
          filters={filters}
        />
      )}
    </div>
  );
};
