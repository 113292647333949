import { useQueryClient } from "@tanstack/react-query";

import { workforceKeys } from "./query_keys";

export const useWorkforceInvalidate = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => queryClient.invalidateQueries(workforceKeys.all()),
    removeCache: () => queryClient.removeQueries(workforceKeys.all())
  };
};
