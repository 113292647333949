import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import {
  BookingFormDataEditOrCloneMode,
  BookingFormMode,
  BookingFormType
} from "PFApp/booking/components/booking_form/use_booking_form";
import CategoryBadge from "PFApp/booking/components/category_badge";
import BookingDeleteButton from "PFApp/booking/components/delete_booking_modal/booking_delete_button";
import {
  OverbookingPotentialBooking,
  useDetailsPanelApiContext
} from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context";
import { useBookingSiblings } from "PFApp/booking/parts/overview/detail_view/booking_detail/booking_detail_content/booking_siblings/use_booking_siblings";
import { getDeleteMode } from "PFApp/booking/parts/overview/detail_view/booking_detail/buttons/buttons";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { getProfileName } from "PFCore/helpers/profile";
import { useBooking } from "PFCore/hooks/queries";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking, Profile } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BookingSelectOption } from "../../../../booking_questions/booking_questions";
import css from "./booking_item.module.scss";

const checkIsBookingFormAlreadyOpenForThisBookingSiblings = (
  bookingSiblings: Booking[],
  modal: BookingFormType
) => {
  const isBookingFormOpenInEditMode = modal.state.props?.mode === BookingFormMode.Edit;
  const bookingFormInitialBookingId = isBookingFormOpenInEditMode
    ? (modal.state.data as BookingFormDataEditOrCloneMode)?.bookingId
    : undefined;
  return (
    !!bookingFormInitialBookingId && bookingSiblings.some(({ id }) => id === bookingFormInitialBookingId)
  );
};

type BookingItemProps = {
  bookingId?: number;
  profile: Profile;
  onDeleted?: (entity: Booking) => Promise<void>;
  onEdited?: () => void;
  potentialBooking?: OverbookingPotentialBooking;
};

export const BookingItem = ({
  bookingId,
  onDeleted,
  onEdited,
  profile,
  potentialBooking
}: BookingItemProps) => {
  const { modal } = useBookingFormContext();
  const { closeDetailsPanel, closeAllDetailsPanels, openDetailsPanel } = useDetailsPanelApiContext();
  const { utc } = useDateFormatter();
  const { t } = useTranslation("translation");

  const isBookingFormOpenInEditMode = modal.state.props?.mode === BookingFormMode.Edit;

  const { data: booking } = useBooking(bookingId, { enabled: !!bookingId });

  const { data: bookingSiblings, invalidate: invalidateBookingSiblings } = useBookingSiblings({
    bookingId,
    profileId: booking?.profile_id,
    activityId: booking?.activity?.id,
    enabled: isBookingFormOpenInEditMode
  });

  const {
    activity_id: activityId,
    activity_siblings_count: activitySiblingsCount,
    booking_category_id: bookingCategoryId,
    title,
    booking_template_id: bookingTemplateId,
    readonly
  } = booking || {};
  const { title: potentialBookingTitle, booking_category_id: potentialBookingCategoryId } =
    potentialBooking || {};

  const { data: bookingTemplate, isLoading: bookingTemplateLoading } = useBookingTemplate(bookingTemplateId, {
    enabled: Number.isInteger(bookingTemplateId)
  });

  const isSingleDayBookingTemplate = useMemo(
    () => bookingTemplate && utc(bookingTemplate.end_date).diff(bookingTemplate.start_date, "days") === 0,
    [bookingTemplate, utc]
  );

  const deleteMode = getDeleteMode(activityId || null, activitySiblingsCount || 0);
  const handleEdit = () => {
    const isBookingFormAlreadyOpenForThisBooking = checkIsBookingFormAlreadyOpenForThisBookingSiblings(
      bookingSiblings,
      modal
    );
    if (isBookingFormAlreadyOpenForThisBooking) {
      closeDetailsPanel();
    } else if (booking) {
      modal.open(
        { bookingId: booking.id, profileId: profile.id },
        { onSuccess: onEdited, mode: BookingFormMode.Edit }
      );
    }
  };

  const handleDelete = (entity: Booking, deleteOptionSelected: BookingSelectOption) => {
    const isBookingFormAlreadyOpenForThisBooking = checkIsBookingFormAlreadyOpenForThisBookingSiblings(
      bookingSiblings,
      modal
    );
    if (isBookingFormAlreadyOpenForThisBooking) {
      const areNoBookingsRemainingInForm =
        bookingSiblings.length === 1 || deleteOptionSelected === BookingSelectOption.All;
      if (areNoBookingsRemainingInForm) {
        modal.close();
        closeAllDetailsPanels();
      } else {
        closeDetailsPanel();
        invalidateBookingSiblings();
      }
    }

    return onDeleted ? onDeleted(entity) : Promise.resolve();
  };

  return (
    <div className={css.bookingItem}>
      <Button
        small
        kind="blank"
        onClick={() =>
          bookingId &&
          openDetailsPanel(
            {
              id: bookingId,
              type: "booking"
            },
            true
          )
        }
        disabled={!bookingId}
        text={title || potentialBookingTitle || t("booking")}
      />
      <div className={css.actionsContainer}>
        <CategoryBadge
          categoryId={bookingCategoryId ?? potentialBookingCategoryId}
          className={css.categoryBadge}
          titleClassName={css.categoryBadgeTitle}
        />
        <ActionIcon
          className={css.action}
          name="edit"
          size="sm"
          onClick={handleEdit}
          disabled={readonly || (Number.isInteger(bookingTemplateId) && bookingTemplateLoading) || !booking}
        />
        <BookingDeleteButton
          kind="icon"
          className={css.action}
          deleteMode={deleteMode}
          booking={booking}
          profileFullName={getProfileName(profile)}
          onDeleted={handleDelete}
          isSingleDayBookingTemplate={isSingleDayBookingTemplate}
          disabled={readonly || !booking}
        />
      </div>
    </div>
  );
};
