import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag } from "PFTypes";

/* eslint-disable id-length */
export const setupChameleon = (currentAccount) => {
  const isEnabled = isFeatureEnabled(currentAccount);

  if (PF.config.chameleon_token && isEnabled(FeatureFlag.Chameleon)) {
    !(function (t, n, o) {
      var a = "chmln",
        e = "adminPreview",
        c = "setup identify alias track set show on off custom help _data".split(" ");
      if ((n[a] || (n[a] = {}), n[a][e] && (n[a][e] = !1), !n[a].root)) {
        (n[a].accountToken = o), (n[a].location = n.location.href.toString()), (n[a].now = new Date());
        for (var s = 0; s < c.length; s++) {
          !(function () {
            var t = (n[a][`${c[s]}_a`] = []);
            n[a][c[s]] = function () {
              t.push(arguments);
            };
          })();
        }
        var i = t.createElement("script");
        (i.src = `https://fast.trychameleon.com/messo/${o}/messo.min.js`),
          (i.async = !0),
          t.head.appendChild(i);
      }
    })(document, window, PF.config.chameleon_token);
  }
};
