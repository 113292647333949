import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./skills_explorer.module.scss";

const Breadcrumb = ({ skills, path, setPath }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  const displayPath = useMemo(() => {
    const toReturn = [{ name: t("charts.allSkills"), path: [] }];

    path.forEach((dataIndex, index) => {
      if (skills[dataIndex]) {
        toReturn.push({ name: skills[dataIndex].name, path: path.slice(0, index + 1) });
        skills = skills[dataIndex]?.children;
      }
    });

    return toReturn;
  }, [path, t]);

  return (
    <ul className={css.breadcrumb}>
      {displayPath.map((item, index) => (
        <li key={`breadcrumb_${index}`}>
          <button onClick={() => setPath(item.path)}>{item.name}</button>
        </li>
      ))}
    </ul>
  );
};

Breadcrumb.propTypes = {
  skills: PropTypes.array,
  path: PropTypes.array,
  setPath: PropTypes.func
};

export default Breadcrumb;
