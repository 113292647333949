import { OrderOption } from "PFTypes";
import { useCallback } from "react";

import useStorageOrder, { OrderStorage } from "../../../use_filtered_collection/use_storage_order";

type UseWorkforceOrder = {
  storagePrefix: string;
  options?: OrderOption[];
  postChangeAction: VoidFunction;
  defaultValue: any;
};
type UseWorkforceOrderReturn = OrderStorage;

export const useOrderWithPostActions = ({
  storagePrefix,
  options,
  postChangeAction,
  defaultValue
}: UseWorkforceOrder): UseWorkforceOrderReturn => {
  const { setSelectedOrder, ...rest } = useStorageOrder({
    storagePrefix,
    defaultValue,
    options
  });

  const handleSetSelectedOrder = useCallback<OrderStorage["setSelectedOrder"]>(
    (...args) => {
      setSelectedOrder(...args);
      postChangeAction();
    },
    [postChangeAction, setSelectedOrder]
  );

  return {
    ...rest,
    setSelectedOrder: handleSetSelectedOrder
  };
};
