import { omit } from "lodash";

import { QueryParams } from "../types/query_params";
import AjaxSettings = JQuery.AjaxSettings;
import { AuditPlannerException, AuditPlannerExceptionKey, CalendarRange, Id, OrderOption } from "../types";

type CollectionParams = Omit<QueryParams, "order"> & {
  term?: string;
  date_range?: CalendarRange;
  apiVersion?: 1 | 2 | undefined;
  order?: Partial<OrderOption>;
  profile_ids?: Id[];
  state?: AuditPlannerException["state"][];
  exception_type?: AuditPlannerExceptionKey[];
  previous_search_id?: Id;
};

type RequestOptions = {
  method?: "GET" | "POST" | "PUT";
  contentType?: string;
  stringify?: boolean;
};

const _justFetch = <T = any>(
  url: string,
  params: CollectionParams = {},
  headers: Record<string, string> = {},
  options: RequestOptions = {}
): Promise<T> => {
  const perPage = params.perPage === undefined ? 25 : params.perPage;
  const apiVersion = params.apiVersion || 2;
  const page = params.page || 1;
  const queryParams = { ...omit(params, ["perPage", "apiVersion", "page"]), page, per_page: perPage };
  const method = options.method || "GET";
  const contentType = options.contentType || "application/x-www-form-urlencoded; charset=UTF-8";
  return $.ajax({
    url,
    headers,
    contentType,
    data: options.stringify ? JSON.stringify(queryParams) : queryParams,
    api_version: apiVersion,
    method
  } as AjaxSettings) as unknown as Promise<T>;
};

export const fetchPaginatedCollection = <T = any>(
  url: string,
  params: CollectionParams = {},
  headers: Record<string, string> | null = {},
  options: RequestOptions = {}
): Promise<T> => {
  const perPage = params.perPage === undefined ? 25 : params.perPage;
  const page = params.page || 1;
  const totalPages = (total) => Math.max(Math.ceil(total / perPage), 1);
  return _justFetch(url, params, headers || {}, options).then(
    (resp) => ({
      ...resp,
      meta: { ...resp.meta, page, perPage, totalPages: totalPages(resp.meta?.total || 0) }
    }),
    (resp) => resp
  );
};
