import Storage, { cacheBucket, cacheSuffix } from "PFCore/base/storage/storage";
import { endsWith, startsWith } from "underscore.string";

export default class MemoryStorage extends Storage {
  constructor() {
    super();
    this.internalStorage = {};
  }

  setItem = (key, value, options = {}) => {
    this.flushExpired();

    value = this.stringify(value);
    this.internalStorage[this.storageKey(key)] = value;

    if (options.expiresIn) {
      this.internalStorage[this.expirationKey(key)] = this.expirationTimeFor(options.expiresIn);
    } else {
      if (!options.preserveTime) {
        delete this.internalStorage[this.expirationKey(key)];
      }
    }
  };

  getItem = (key) => {
    this.flushExpiredItem(key);

    const value = this.internalStorage[this.storageKey(key)];
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  };

  removeItem = (key) => {
    delete this.internalStorage[this.storageKey(key)];
    delete this.internalStorage[this.expirationKey(key)];
  };

  clear = () => {
    this.internalStorage = {};
  };

  key = (index) => Object.keys(this.internalStorage)[index];

  eachKey = (callback) => {
    Object.keys(this.internalStorage).forEach((key) => {
      if (key && startsWith(key, cacheBucket) && !endsWith(key, cacheSuffix)) {
        callback(key.replace(cacheBucket, ""));
      }
    });
  };

  expirationTime = (key) => {
    const time = this.internalStorage[this.expirationKey(key)];
    if (time) {
      return parseInt(time, 10);
    } else {
      return null;
    }
  };
}
