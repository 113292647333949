import classNames from "classnames";
import { useTranslation } from "react-i18next";

import css from "./activity_details_common.scss";

interface Props {
  questions: string[];
  className?: string;
}

const ActivityDetailsQuestions = ({ questions, className }: Props) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.details" });

  return (
    <div className={classNames(css.infoItem, className)}>
      <strong>{t("showInterestQuestions")}</strong>
      {questions.map((question, index) => (
        <div key={index} className={classNames(css.interestQuestion, css.description)}>
          <div>{t("question", { id: index + 1 })}</div>
          <div className={css.content}>{question}</div>
        </div>
      ))}
    </div>
  );
};

export default ActivityDetailsQuestions;
