import { useQueryClient } from "@tanstack/react-query";

import { ActivitiesCollection, activitiesKeys } from "./query_keys";

export const useActivitiesInvalidate = () => {
  const cache = useQueryClient();

  return {
    invalidateByCollection: (collectionType?: ActivitiesCollection, params = {}) => {
      cache.invalidateQueries(activitiesKeys[collectionType || ActivitiesCollection.All](params as any));
    },
    invalidateImports: (templateKey: string) => cache.invalidateQueries(activitiesKeys.imports(templateKey))
  };
};
