import { Icon } from "PFComponents/icon";
import { Slider } from "PFComponents/slider";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import css from "./threshold_slider.module.scss";

enum ThresholdCap {
  Min = 50,
  Max = 80
}

type ThresholdSliderProps = {
  threshold: number;
  actions?: ReactNode;
  onChange: (threshold: number) => void;
};

export const ThresholdSlider = ({
  threshold = ThresholdCap.Min,
  actions,
  onChange
}: ThresholdSliderProps) => {
  const { t } = useTranslation();

  const showToLowValueWarning = threshold <= ThresholdCap.Min;
  const showToHighValueWarning = threshold >= ThresholdCap.Max;

  return (
    <>
      <Slider
        title={t("availabilityRequirement.description")}
        min={0}
        max={100}
        step={10}
        unit="%"
        onChange={onChange}
        value={threshold}
        actions={actions}
      />
      {(showToLowValueWarning || showToHighValueWarning) && (
        <div className={css.warning}>
          <Icon name="warning" color="paletteOrange0" size="sm" />
          {showToLowValueWarning && t("availabilityRequirement.lowThresholdWarning")}
          {showToHighValueWarning && t("availabilityRequirement.highThresholdWarning")}
        </div>
      )}
    </>
  );
};
