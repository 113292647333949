import { useQuery } from "@tanstack/react-query";
import {
  BookingEngagementsEconomicsResponse,
  fetchBookingEngagementsEconomics
} from "PFApp/booking/services/api";
import { Id, QueryOptions } from "PFTypes";

import { bookingEngagementsKeys } from "./query_keys";

export const useBookingEngagementsEconomics = (ids: Id[], options?: QueryOptions<any>) =>
  useQuery<BookingEngagementsEconomicsResponse>(
    bookingEngagementsKeys.economics(ids),
    () => fetchBookingEngagementsEconomics(ids),
    {
      enabled: ids.length > 0,
      ...options
    }
  );
