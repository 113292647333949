import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { PermissionRule } from "PFTypes";
import { useCallback } from "react";

export const useEconomicsPermissions = () => {
  const { data: currentProfile } = useCurrentProfile();
  const isPermittedTo = useIsPermittedTo(currentProfile);

  const canManageEconomics = isPermittedTo(PermissionRule.EngagementEconomicsManage);
  const canCreateScenarios =
    canManageEconomics || isPermittedTo(PermissionRule.EngagementEconomicsCreateScenarios);

  const canManageScenario = useCallback(
    (creatorId: number) => canManageEconomics || creatorId === currentProfile.id,
    [canManageEconomics, currentProfile]
  );

  return {
    canCreateScenarios,
    canManageScenario
  };
};
