import "moment/locale/fr-ca";

import { toLower } from "lodash";
import moment from "moment";
import { DEFAULT_LOCALE } from "PFCore/helpers/date";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useEffect } from "react";

export const useGlobalsForLocale = () => {
  const { data: currentProfile } = useCurrentProfile();
  const locale = currentProfile?.locale || DEFAULT_LOCALE;

  useEffect(() => {
    moment.locale(toLower(locale), {
      week: {
        dow: 1 // Monday is the first day of the week.
      }
    });
  }, [locale]);
};
