import { uniq } from "lodash";

export const CHAT_ACTIONS = {
  CHAT_RENDER: "CHAT_RENDER",
  CHAT_UNRENDER: "CHAT_UNRENDER",
  CHAT_MINIMIZE: "CHAT_MINIMIZE",
  CHAT_UNMINIMIZE: "CHAT_UNMINIMIZE",
  CHAT_EXPAND: "CHAT_EXPAND",
  CHAT_UNEXPAND: "CHAT_UNEXPAND",
  CHAT_SELECT: "CHAT_SELECT",
  CHAT_UNSELECT: "CHAT_UNSELECT"
};

export const chatReducer = (state, { type, payload }) => {
  const { chats } = state;
  const { ids, autoId, expandedId, minimizedIds } = chats;
  let newAutoId, newExpandedId;
  let newMinimizedIds = [...minimizedIds];

  switch (type) {
    case CHAT_ACTIONS.CHAT_RENDER: {
      if (payload.auto && ids.indexOf(payload.id) === -1) {
        newAutoId = payload.id;
      }

      if (payload.expanded) {
        newExpandedId = payload.id;
      }

      if (payload.minimized) {
        if (ids.indexOf(payload.id) === -1) {
          newMinimizedIds = [...minimizedIds, payload.id];
        }
      } else {
        if (ids.indexOf(payload.id) !== -1) {
          newMinimizedIds = minimizedIds.filter((candidate) => candidate !== payload.id);
        }
      }

      return {
        ...state,
        chats: {
          ids: uniq([...ids, payload.id]),
          selectedId: payload.id,
          autoId: newAutoId || autoId,
          expandedId: newExpandedId || expandedId,
          minimizedIds: newMinimizedIds
        }
      };
    }

    case CHAT_ACTIONS.CHAT_UNRENDER: {
      return {
        ...state,
        chats: {
          ids: ids.filter(
            (candidate) =>
              candidate !== payload.id || // Keep other chats
              (payload.auto && autoId !== candidate && candidate === payload.id) // Keep it if not auto chat
          ),
          selectedId: null,
          autoId: autoId === payload.id ? null : autoId,
          expandedId: expandedId === payload.id ? null : expandedId,
          minimizedIds: minimizedIds.filter((candidate) => candidate !== payload.id)
        }
      };
    }

    case CHAT_ACTIONS.CHAT_MINIMIZE: {
      return {
        ...state,
        chats: { ...state.chats, minimizedIds: [...minimizedIds, payload.id] }
      };
    }

    case CHAT_ACTIONS.CHAT_UNMINIMIZE: {
      return {
        ...state,
        chats: { ...state.chats, minimizedIds: minimizedIds.filter((candidate) => candidate !== payload.id) }
      };
    }

    case CHAT_ACTIONS.CHAT_EXPAND: {
      return {
        ...state,
        chats: { ...state.chats, expandedId: payload.id }
      };
    }

    case CHAT_ACTIONS.CHAT_UNEXPAND: {
      return {
        ...state,
        chats: { ...state.chats, expandedId: null }
      };
    }

    case CHAT_ACTIONS.CHAT_SELECT: {
      return {
        ...state,
        chats: { ...state.chats, selectedId: payload.id }
      };
    }

    case CHAT_ACTIONS.CHAT_UNSELECT: {
      return {
        ...state,
        chats: { ...state.chats, selectedId: null }
      };
    }
  }
};
