import { camelizeKeys } from "humps";
import { groupBy, isNumber, last, partition, split, uniqueId } from "lodash";
import { useGrowl } from "PFApp/use_growl";
import canonicalId from "PFCore/helpers/canonicalId";
import useStorage from "PFCore/helpers/use_storage";
import { useCustomValuesInvalidate } from "PFCore/hooks/queries/custom_fields/admin";
import { useCustomValuesCreate } from "PFCore/hooks/queries/custom_values/use_custom_values_create";
import { useDictionaryConnectionCreate } from "PFCore/hooks/queries/custom_values/use_dictionary_connection_create";
import { useDiscoveredSkills } from "PFCore/hooks/queries/skills/use_discovered_skills";
import { useSkillsInvalidate } from "PFCore/hooks/queries/skills/use_skills_invalidate";
import { useSkillsFrameworksInvalidate } from "PFCore/hooks/queries/skills_frameworks/use_skills_frameworks_invalidate";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { usePreviousValue } from "PFCore/hooks/use_previous_value";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { CustomValue, Experience, Id, Profile, ProfileCustomValue, SuggestionSkill } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { useProfileSuggestedSkills } from "../../hooks/use_profile_suggested_skills";
import { coreSkillsLimit, developmentalSkillsLimit } from "./constants";
import { ChangesLogItem } from "./section_skills";
import { skillsSortingOptions } from "./skills_grid";
import { useChangesLog } from "./use_changes_log";
import { useSkillChange } from "./use_skill_change";

const sanitizeDraggableId = (id: string): string => last(split(id, "-")) || "";

export type NewSkill = CustomValue & { created?: boolean };

export const useSectionSkills = (
  profile: Profile,
  handleProfileUpdate: () => Profile,
  handleClose: () => void
) => {
  const growl = useGrowl();
  const growlError = useErrorsGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });
  const skillsCustomType = useSkillsType();

  const { mutateAsync: createDictionaryConnection } = useDictionaryConnectionCreate({
    onError: (error) => {
      growlError(error.response);
    }
  });

  const { mutateAsync: createCustomValue } = useCustomValuesCreate({
    onSuccess: (item, { data }) => {
      createDictionaryConnection({
        customValueId: item.id,
        ...camelizeKeys(data)
      });
    },
    cacheTime: 0
  });

  const { suggestedFrameworksSkills, isLoading: isLoadingSuggestedFrameworksSkills } =
    useProfileSuggestedSkills({ profileId: profile.id });

  const [skills, setSkills] = useState<ProfileCustomValue[]>(profile?.skills || []);

  const [newSkills, setNewSkills] = useState<NewSkill[]>([]);
  const [newSkillRating, setNewSkillRating] = useState<Experience | null>(null);

  const [suggestedSkills, setSuggestedSkills] = useState<SuggestionSkill[]>(
    profile.suggestions?.skills || []
  );

  const { handleSkillChange, blockedSkills } = useSkillChange({ newSkills, setNewSkills });

  const { data: discoveredSkillsData, isLoading: discoveredSkillsLoading } = useDiscoveredSkills({
    onSuccess: ({ entries }) => setDiscoveredSkills(entries)
  });
  const { invalidateDiscoveredSkills } = useSkillsInvalidate();
  const { invalidateProfileSkillsFrameworks } = useSkillsFrameworksInvalidate();
  const { invalidateForType: invalidateCustomValuesForType } = useCustomValuesInvalidate();
  const [discoveredSkills, setDiscoveredSkills] = useState(discoveredSkillsData?.entries || []);

  const [hiddenFrameworksSkills, setHiddenFrameworksSkills] = useState<Id[]>([]);

  const { addToLog, removeFromLog, changesLog, setChangesLog, addedValues, deletedValues } = useChangesLog({
    skills,
    setSkills,
    discoveredSkills,
    setDiscoveredSkills,
    suggestedSkills,
    setSuggestedSkills,
    suggestedFrameworksSkills,
    setHiddenFrameworksSkills
  });

  const visibleSuggestedFrameworksSkills = suggestedFrameworksSkills.filter(
    ({ id }) => !hiddenFrameworksSkills.includes(id)
  );

  const [skillsSortingSelected, setSkillsSortingSelected] = useStorage("profile_skills_edit_basic_sort", {
    1: skillsSortingOptions[0],
    3: skillsSortingOptions[0],
    5: skillsSortingOptions[0]
  });

  const [basicSkills, intermediateSkills, advancedSkills] = useMemo(() => {
    const skillsByExperience = groupBy(skills, (skill) => skill.experience || Experience.Intermediate);
    return [
      (skillsByExperience[Experience.Basic] || []).sort(skillsSortingSelected[1].sortFunc),
      (skillsByExperience[Experience.Intermediate] || []).sort(skillsSortingSelected[3].sortFunc),
      (skillsByExperience[Experience.Advanced] || []).sort(skillsSortingSelected[5].sortFunc)
    ];
  }, [skills, skillsSortingSelected]);

  const skillsValues = useMemo(
    () => (skills || []).map(({ value, text }) => canonicalId(String(value || text))),
    [skills]
  );

  const addNewSkill = () => {
    if (newSkills.length === 0 || !newSkillRating) {
      return;
    }

    const skillsToAdd = newSkills.map((skill) => ({
      ...skill,
      experience: newSkillRating,
      id: skill.id || uniqueId("new")
    }));
    const isAdded = !!skillsToAdd.find((skill) => !!skills.find(({ id }) => id === skill.id));

    if (isAdded) {
      return growl({
        message: t("sectionSkills.errors.addDuplicateSkill"),
        kind: "error"
      });
    }

    addToLog(
      ...skillsToAdd.map((skill) => ({
        id: skill.id,
        data: {
          value: skill.text || skill.value,
          id: skill.id,
          created: skill.created,
          experience: newSkillRating,
          interest: true
        },
        type: "new"
      }))
    );

    setNewSkills([]);
    setNewSkillRating(null);
  };

  const executeSkillChanges = (changes: ChangesLogItem[]) =>
    Promise.all(
      changes
        .reduce((acc: ChangesLogItem[], curr) => {
          const changedAlready = acc.find(({ id }) => String(id) === String(curr.id));
          return changedAlready
            ? acc.map((change) =>
                String(change.id) === String(curr.id)
                  ? { ...change, data: { ...change.data, ...curr.data } }
                  : change
              )
            : [...acc, curr];
        }, [])
        .map((change) => {
          const { data } = change;
          const body = {
            ...change.data,
            ...(["suggested", "framework"].includes(change.type) ? { source: "skill_suggestion" } : null)
          };

          if (!isNumber(change.id)) {
            return createCustomValue({
              payload: {
                value: data.value || data.text,
                customTypeId: skillsCustomType?.id,
                globalId: data.globalId
              },
              data: body
            });
          }
          return createDictionaryConnection({
            customValueId: change.id,
            ...camelizeKeys(body)
          });
        })
    );

  const saveChanges = async () => {
    try {
      const [untaggingDevelopmentalSkillChanges, otherChanges] = partition(
        changesLog,
        ({ data, oldData }) =>
          (data?.developmental === false && oldData?.developmental === true) ||
          (data?.top === false && oldData?.top === true)
      );

      await executeSkillChanges(untaggingDevelopmentalSkillChanges);

      await executeSkillChanges(otherChanges);

      const attributes = await handleProfileUpdate();

      setChangesLog([]);
      setSkills(attributes?.skills || []);
      await invalidateProfileSkillsFrameworks(profile.id);
      await invalidateDiscoveredSkills();
      await invalidateCustomValuesForType(skillsCustomType!.id);

      growl({
        message: t("sectionSkills.skillsSaved"),
        kind: "success"
      });
      handleClose();
    } catch (e) {
      growl({
        message: t("sectionSkills.errors.saveSkills"),
        kind: "error"
      });
    }
  };

  const onDragEnd = (dragProps: DropResult) => {
    const { destination, source } = dragProps;
    const draggableId = sanitizeDraggableId(dragProps.draggableId);

    const newRating = destination?.droppableId;
    if (!destination || newRating === source?.droppableId) {
      return;
    }
    const skillId = draggableId?.includes("new") ? draggableId : Number(draggableId);
    const changeType =
      (["discovered", "suggested", "framework"].includes(source.droppableId) ? source.droppableId : null) ||
      "ranked";

    addToLog({ id: skillId, data: { experience: Number(newRating) }, type: changeType });
  };

  const developmentalSkillsCount = useMemo(
    () => skills?.filter((skill) => skill.developmental).length,
    [skills]
  );
  const previousDevelopmentalSkillsCount = usePreviousValue(developmentalSkillsCount);

  useEffect(() => {
    if (
      previousDevelopmentalSkillsCount &&
      developmentalSkillsCount === developmentalSkillsLimit &&
      previousDevelopmentalSkillsCount !== developmentalSkillsCount
    ) {
      growl({
        message: t("sectionSkills.developmentSkillsLimit", {
          limit: developmentalSkillsLimit
        }),
        kind: "alert",
        ttl: 20000
      });
    }
  }, [developmentalSkillsCount, t, growl, previousDevelopmentalSkillsCount]);

  const coreSkillsCount = useMemo(() => skills?.filter((skill) => skill.top).length, [skills]);
  const previousCoreSkillsCount = usePreviousValue(coreSkillsCount);

  useEffect(() => {
    if (
      previousCoreSkillsCount &&
      coreSkillsCount === coreSkillsLimit &&
      coreSkillsCount !== previousCoreSkillsCount
    ) {
      growl({
        message: t("sectionSkills.coreSkillsLimit", {
          limit: coreSkillsLimit
        }),
        kind: "alert",
        ttl: 20000
      });
    }
  }, [coreSkillsCount, growl, previousCoreSkillsCount, t]);

  return {
    addNewSkill,
    changesLog,
    addToLog,
    skills,
    skillsValues,
    newSkills,
    suggestedSkills,
    suggestedFrameworksSkills: visibleSuggestedFrameworksSkills,
    isLoadingSuggestedFrameworksSkills,
    discoveredSkills,
    basicSkills,
    intermediateSkills,
    advancedSkills,
    discoveredSkillsLoading,
    onDragEnd,
    saveChanges,
    newSkillRating,
    setNewSkillRating,
    addedValues,
    deletedValues,
    coreSkillsCount,
    developmentalSkillsCount,
    skillsSortingSelected,
    setSkillsSortingSelected,
    setSkills,
    removeFromLog,
    handleSkillChange,
    blockedSkills
  };
};
