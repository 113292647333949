import { EconomicsVacancy } from "PFTypes";

export const groupVacanciesByRole = (vacancies: EconomicsVacancy[]) => {
  const groupedVacancies = vacancies.reduce((acc, scenario) => {
    const { activity } = scenario;
    return {
      ...acc,
      [activity.id]: [...(acc[activity.id] || []), scenario]
    };
  }, {} as Record<string, EconomicsVacancy[]>);

  return groupedVacancies;
};

export const getPercentValue = (number?: number) => (number ? `${Math.round(number * 100)}%` : "-");
