// eslint-disable-next-line max-len
// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const letterSpacings = {
  letterSpacingRegular: "0em",
  letterSpacingBig: "0.05em",
  letterSpacingSmall: "-0.05em"
} as const;

export default letterSpacings;
