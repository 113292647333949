import AppContext from "PFApp/app_context";
import { useTemplate } from "PFApp/hooks";
import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import css from "PFApp/profiles/edit/sections/section.less";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import Toggle from "PFComponents/toggle/toggle";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useNotificationsSettings from "PFCore/helpers/use_notifications_settings";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { NOTIFICATION_SETTINGS_ASYNC_ACTIONS } from "PFReducers/notification_settings_reducer";
import { FeatureFlag } from "PFTypes";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import NotificationsSectionSelect from "./notifications_section_select";

const NotificationsSection = () => {
  const {
    dispatch,
    store: { notificationSettings }
  } = useContext(AppContext);
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles", { keyPrefix: "edit.sections" });
  const OPTIONS = useNotificationsSettings();

  const [FREQUENCY_OPTIONS, BINARY_OPTIONS, WEEKLY_OPTIONS] = useMemo(
    () => [
      [OPTIONS.weekly, OPTIONS.fortnightly, OPTIONS.monthly, OPTIONS.never],
      [OPTIONS.instantly, OPTIONS.dontReceive],
      [OPTIONS.weekly, OPTIONS.never]
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(notificationSettings);

  const isEnabled = useIsFeatureEnabled();
  const growl = useGrowl();

  const template = useTemplate("role");

  useEffect(() => {
    setSettings(notificationSettings);
  }, [notificationSettings]);

  useEffect(() => {
    dispatch({ type: NOTIFICATION_SETTINGS_ASYNC_ACTIONS.NOTIFICATION_SETTINGS_FETCH });
  }, []);

  const handleNotificationSettingChange = (name, value) => {
    setSettings((prev) => {
      const newSettings = { ...prev };
      newSettings[name] = value;
      return newSettings;
    });
  };

  const handleNotificationSettingsUpdate = () => {
    setLoading(true);
    dispatch({
      type: NOTIFICATION_SETTINGS_ASYNC_ACTIONS.NOTIFICATION_SETTINGS_UPDATE,
      payload: settings
    }).then(() => {
      setLoading(false);
      growl({ kind: "success", message: t("settingsUpdatedMessage") });
    });
  };

  const BOOKING_OPTIONS = useMemo(
    () => [OPTIONS.instantly, OPTIONS.daily, OPTIONS.weekly, OPTIONS.never],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const selects = useMemo(
    () => [
      { name: "booking_category_changed", options: BOOKING_OPTIONS },
      { name: "messages_status", options: BINARY_OPTIONS },
      { name: "regular_invites", options: BINARY_OPTIONS },
      {
        name: "role_invites",
        options: BINARY_OPTIONS,
        hidden: !template
      },
      { name: "role_filled", options: BINARY_OPTIONS },
      { name: "owner_role_filled", options: BINARY_OPTIONS },
      { name: "role_closed", options: BINARY_OPTIONS },
      { name: "digest_email", options: FREQUENCY_OPTIONS, hidden: !isEnabled(FeatureFlag.DigestEmail) },
      { name: "broadcast_invites", options: BINARY_OPTIONS },
      { name: "interest_expressed", options: BINARY_OPTIONS },
      {
        name: "interests_summary",
        options: WEEKLY_OPTIONS,
        hidden: !isEnabled(FeatureFlag.InterestsSummary)
      },
      { name: "marketplace_role_closed", options: BINARY_OPTIONS },
      { name: "booking_approval_requests", options: BOOKING_OPTIONS },
      {
        name: "feedback_request",
        options: BINARY_OPTIONS,
        hidden: !isEnabled(FeatureFlag.EngagementFeedback)
      },
      { name: "role_changed", options: BINARY_OPTIONS },
      { name: "audit_role_changed", options: BINARY_OPTIONS },
      { name: "audit_engagement_changed", options: BINARY_OPTIONS },
      { name: "engagement_changed", options: BINARY_OPTIONS },
      { name: "role_deleted", options: BINARY_OPTIONS },
      { name: "audit_role_deleted", options: BINARY_OPTIONS },
      { name: "audit_engagement_deleted", options: BINARY_OPTIONS },
      { name: "engagement_deleted", options: BINARY_OPTIONS },
      { name: "shortlist_created", options: BINARY_OPTIONS }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProfile, isEnabled]
  );

  return (
    <ProfileEditPanel title={getProfileSectionText("notifications")}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={css.wrap}>
          {selects
            .filter((i) => !i.hidden)
            .map((i) => (
              <NotificationsSectionSelect
                key={i.name}
                name={i.name}
                options={i.options}
                defaultValue={settings[i.name]}
                onChange={(newVal) => handleNotificationSettingChange(i.name, newVal)}
              />
            ))}
        </div>
        {isEnabled(FeatureFlag.NativeNotifications) && (
          <div style={{ marginTop: 30 }}>
            <h3>{t("notifications.settings.nativeNotifications")}</h3>
            <Toggle
              qaId="toggle-component-allow_native_notifications"
              checked={String(settings.allow_native_notifications) === "true"}
              onChange={(newVal) => handleNotificationSettingChange("allow_native_notifications", newVal)}
            />
            <small style={{ opacity: 0.7, display: "block" }}>
              {t("notifications.settingsHints.nativeNotifications")}
            </small>
          </div>
        )}
        <Button
          style={{ alignSelf: "center", margin: 20 }}
          kind="primary"
          disabled={loading}
          onClick={handleNotificationSettingsUpdate}
        >
          {t("translation:update")}
        </Button>
      </div>
    </ProfileEditPanel>
  );
};

export default NotificationsSection;
