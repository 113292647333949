export const PROFILE_PREVIEW_ACTIONS = {
  PROFILE_PREVIEW_SHOW: "PROFILE_PREVIEW_SHOW",
  PROFILE_PREVIEW_HIDE: "PROFILE_PREVIEW_HIDE",
  PROFILE_PREVIEW_UNMOUNT: "PROFILE_PREVIEW_UNMOUNT",
  PROFILE_PREVIEW_DONE: "PROFILE_PREVIEW_DONE"
};

export const profilePreviewReducer = (state, { type, payload }) => {
  switch (type) {
    case PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_SHOW:
      return {
        ...state,
        profilePreview: {
          id: payload.id,
          profile: payload.profile,
          showBookingButtons: !!payload.showBookingButtons,
          ready: false,
          show: true,
          isClosing: false
        }
      };

    case PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_HIDE:
      return {
        ...state,
        profilePreview: { ...state.profilePreview, isClosing: true }
      };

    case PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_UNMOUNT:
      // don't remove profile and id so we can have proper closing animation
      return {
        ...state,
        profilePreview: { ...state.profilePreview, isClosing: false, show: false }
      };

    case PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_DONE:
      if (payload.profile.id === state.profilePreview.id) {
        return {
          ...state,
          profilePreview: { ...state.profilePreview, profile: payload.profile, ready: true }
        };
      } else {
        return state; // another profile opened in the meantime - no need to update the state
      }
  }
};
