import { compact, isArray } from "lodash";
import Tooltip from "PFComponents/tooltip/tooltip";
import React from "react";

import css from "./assessment_checkmarks.module.scss";

type AssessmentCheckmarksProps = {
  title: string;
  tooltipContent?: JSX.Element | string | null;
  showTooltip?: boolean;
};

export const AssessmentCheckmarks = ({
  children,
  title,
  tooltipContent,
  showTooltip
}: React.PropsWithChildren<AssessmentCheckmarksProps>) => {
  const icons = compact(isArray(children) ? children : [children]);
  return (
    <Tooltip content={tooltipContent} disabled={!showTooltip} theme="pf-dark-blue">
      <div className={css.leftIconsContainer}>
        {icons.map((icon, i) => (
          <div key={`${title}-${i}`} className={css.leftIconWrapper} style={{ zIndex: 100 - i }}>
            {icon}
          </div>
        ))}
      </div>
    </Tooltip>
  );
};
