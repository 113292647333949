import classNames from "classnames";
import { Button } from "PFCore/components/button";
import { Icon } from "PFCore/components/icon";
import { Typography } from "PFCore/components/typography/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import React, { useState } from "react";

import { NoteModal } from "../modals/note_modal";
import { RemoveNoteModal } from "../modals/remove_note_modal";
import { Note } from "../notes.types";
import css from "./single_note.module.scss";

type SingleNoteProps = {
  note: Note;
  onEdit: (content: string) => void;
  onDelete: (noteId: number) => void;
  isEditableNote: boolean;
  bottomLineDisabled?: boolean;
};

export const SingleNote = ({
  note,
  onEdit,
  onDelete,
  isEditableNote,
  bottomLineDisabled
}: SingleNoteProps): JSX.Element => {
  const { formatDateTime } = useDateFormatter();
  const [isDisplayedDeleteModal, setIsDisplayedDeleteModal] = useState(false);
  const [isDisplayedEditNoteModal, setIsDisplayedEditNoteModal] = useState(false);
  const { author, content, createdAt } = note;
  const formattedCreatedAt = formatDateTime(createdAt);
  return (
    <>
      <div className={classNames(css.root, { [css.bottomLineDisabled]: bottomLineDisabled })}>
        <div className={css.headerContainer}>
          <div className={css.headerData}>
            <Typography variant="bodyBold" tag="span">
              {author}
            </Typography>
            <Typography variant="bodyRegular" tag="span" className={css.timeLabel}>
              {formattedCreatedAt}
            </Typography>
          </div>
          {isEditableNote && (
            <div className={css.headerActions}>
              <Button kind="blank" onClick={() => setIsDisplayedEditNoteModal(true)}>
                <Icon name="edit" size="sm" />
              </Button>
              <Button kind="blank" onClick={() => setIsDisplayedDeleteModal(true)}>
                <Icon name="remove" size="sm" />
              </Button>
            </div>
          )}
        </div>
        <div className={css.contentContainer}>
          <Typography variant="bodyRegular" tag="span">
            {content.split("\n").map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </div>
      </div>
      {isDisplayedDeleteModal && (
        <RemoveNoteModal
          onRemove={() => onDelete(note.id)}
          onClose={() => setIsDisplayedDeleteModal(false)}
        />
      )}
      {isDisplayedEditNoteModal && (
        <NoteModal
          mode="edit"
          content={note.content}
          onClose={() => setIsDisplayedEditNoteModal(false)}
          onSave={onEdit}
        />
      )}
    </>
  );
};
