import { useQuery } from "@tanstack/react-query";
import { fetchConversation } from "PFCore/services/chat/fetch_conversation";

import { chatKeys } from "./query_keys";

export const useChatConversation = (id: number) => {
  const queryKey = chatKeys.conversation(id);

  return useQuery(queryKey, () => fetchConversation(id));
};
