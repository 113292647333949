import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";
import { Mentionable } from "PFTypes/mentionable";

enum FollowState {
  Following = "following",
  Ignore = "ignore"
}

type FollowParticipantResponse = {
  entries: Mentionable[];
  meta: { total: number };
};

export const followParticipant = (id: number, follow: boolean): jqXHR<FollowParticipantResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute(`/api/chat/participants/${id}`),
    api_version: 2,
    method: "PUT",
    data: {
      notification_state: follow ? FollowState.Following : FollowState.Ignore
    }
  });
