import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteUser } from "../../../services/admin/users/delete_user";
import { adminKeys } from "./query_keys";

export const useDeleteUser = (id: string, options) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => deleteUser(id),
    onSuccess: (resp) => {
      queryClient.invalidateQueries({ queryKey: adminKeys.user(id) });
      options.onSuccess?.(resp);
    }
  });
};
