import { LoadingDots } from "PFComponents/loading_dots";
import NotFoundComponent from "PFComponents/not_found/not_found";
import usePageWrapper from "PFCore/helpers/use_page_wrapper";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfile } from "PFCore/services/profile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ProfileMatchingRolesPage from "./profile_matching_roles_page";

const ProfileMatchingRolesController = () => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles");

  const { id } = useParams();

  const [errors, setErrors] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (!currentProfile || !currentProfile.id) {
      return;
    }

    if (id === currentProfile.id) {
      setProfile(currentProfile);
    } else {
      fetchProfile(id)
        .then((resp) => setProfile(resp))
        .catch(() => setErrors([true]));
    }
  }, [currentProfile]);

  const WrappedProfileMatchingRolesPage = usePageWrapper(ProfileMatchingRolesPage);

  if (!profile && !errors) {
    return <LoadingDots circlesEnabled pageViewEnabled />;
  }

  return (
    <WrappedProfileMatchingRolesPage
      profile={profile || {}}
      errors={errors}
      loading={!errors && !profile}
      errorComponent={
        <NotFoundComponent
          message={t("profileDoesntExist")}
          buttonMessage={t("matchingRoles.tryOtherSearch")}
          buttonUrl="/profiles"
        />
      }
    />
  );
};

export default ProfileMatchingRolesController;
