import classNames from "classnames";

import css from "../match_gauge.module.scss";

type BarProps = {
  score: number | null | undefined;
  name: string;
  onHover: React.Dispatch<React.SetStateAction<string | null>>;
  isLoading?: boolean;
};

export const Bar = ({ score = 0, name, onHover, isLoading }: BarProps): JSX.Element => {
  const progressStyle = { width: `${Math.min(score || 0, 1) * 100}%` };

  return isLoading ? (
    <div className={css.bar}>
      <div className={css.line} />
      <div className={css.animatedItem} />
    </div>
  ) : (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={css.bar} onMouseEnter={() => onHover(name)} onMouseLeave={() => onHover(null)}>
      <div className={css.line}>
        <div className={classNames(css.progress, css[name])} style={progressStyle} />
      </div>
    </div>
  );
};
