import { fetchProfile as fetchUserProfile } from "PFCore/services/profile";
import { Profile } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from "react";

import { BookingFormWrapper } from "./booking_form_wrapper";
import {
  OverbookingsManagementApiContext,
  OverbookingsManagementStateContext
} from "./overbookings_management_context/overbookings_management_context";
import { useOverbookingsManagement } from "./overbookings_management_context/use_overbookings_management";
import { BookingFormType, useBookingForm } from "./use_booking_form";
import { OnSuccessData } from "./use_handle_submit/use_handle_submit";

type BookingFormContextType = {
  modal: BookingFormType;
  fetchProfile: (id: number) => Promise<Profile> | JQuery.jqXHR<Profile>;
  loadingState: Record<string, boolean>;
  setLoadingState: Dispatch<SetStateAction<Record<string, boolean>>>;
  profileId?: number;
  activeBookingIndex: number | undefined;
  setActiveBookingsIndex: Dispatch<SetStateAction<number | undefined>>;
};

const BookingFormContext = createContext({} as BookingFormContextType);

export type BookingFormConfig = {
  profileId?: number;
  fetchProfile?: (id: number) => Promise<Profile> | JQuery.jqXHR<Profile>;
  onSuccess?: (data: OnSuccessData) => Promise<unknown>;
  goToDate?: (date: Date) => void;
};

/**
 * @param profileId - Workforce Member selector is readonly in case the profileId is present
 */
export const BookingFormContextProvider = ({
  children,
  fetchProfile,
  profileId,
  onSuccess,
  goToDate
}: PropsWithChildren<BookingFormConfig>) => {
  const { overbookingsManagementApi, overbookingsManagementState } = useOverbookingsManagement();
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const [activeBookingIndex, setActiveBookingsIndex] = useState<number | undefined>(undefined);

  const modal = useBookingForm();
  const value = useMemo(
    () => ({
      modal,
      fetchProfile: fetchProfile || fetchUserProfile,
      loadingState,
      profileId,
      setLoadingState,
      activeBookingIndex,
      setActiveBookingsIndex
    }),
    [modal, fetchProfile, profileId, loadingState, activeBookingIndex]
  );

  return (
    <BookingFormContext.Provider value={value}>
      <OverbookingsManagementApiContext.Provider value={overbookingsManagementApi}>
        <OverbookingsManagementStateContext.Provider value={overbookingsManagementState}>
          {modal.state.isOpen && <BookingFormWrapper onSuccess={onSuccess} goToDate={goToDate} />}
          {children}
        </OverbookingsManagementStateContext.Provider>
      </OverbookingsManagementApiContext.Provider>
    </BookingFormContext.Provider>
  );
};

export const useBookingFormContext = () => useContext(BookingFormContext);
