import classNames from "classnames";
import { has, isNil } from "lodash";
import css from "PFComponents/dropdown/dropdown.module.scss";
import { DropDownItem, NO_ITEM } from "PFComponents/dropdown/dropdown_item";
import { Id } from "PFTypes";
import { EventKey } from "PFTypes/event_key";
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from "react";

import { DropdownGroup } from "./dropdown_group";

export type GroupDropdownOption = {
  id: Id;
  label?: string;
  options: DropdownOption[];
  disabled?: boolean;
};

export type DropdownOption = {
  id: string | number;
  global_id?: any;
  displayElement: JSX.Element | string;
  item: any;
  disabled?: boolean;
  tooltipMsg?: string;
  keepOpen?: boolean;
  separator?: boolean;
  ariaLabel?: string;
};

export type DropdownOptions = DropdownOption[] | GroupDropdownOption[];

export type DropDownProps = {
  options: DropdownOptions;
  preOptions?: JSX.Element | ((options: DropdownOptions) => JSX.Element);
  style?: React.CSSProperties;
  displayAbove?: boolean;
  handleChange: (selection: DropdownOption | DropdownOption[], item?: DropdownOption) => void;
  dropDownClassName?: string;
  rootClassName?: string;
  listClassName?: string;
  selectedIndex?: number;
  handleClose: () => void;
  isAccesible?: boolean;
  isMultiSelect?: boolean;
  initialSelectedItems?: DropdownOption[];
  selectedItems?: DropdownOption[];
  noOptionsText?: string;
  footer?: ReactNode;
};

type DropdownRef = {
  handleKeyUp?: (event: KeyboardEvent) => void;
  handleKeyDown?: (event: KeyboardEvent) => void;
};

const areOptionsGrouped = (options: DropdownOptions): options is GroupDropdownOption[] => {
  if (options.length === 0) {
    return false;
  }
  const firstElement = options[0];
  return has(firstElement, "options");
};

const findSelectedItem = (selection: DropdownOption[], value: DropdownOption) =>
  selection.find(({ id, global_id: globalId }) => (id ? id === value.id : globalId === value.global_id));

const getRawOptions = (options: DropdownOptions): DropdownOption[] => {
  if (areOptionsGrouped(options)) {
    return options.flatMap((groupOption) => groupOption.options);
  }
  return options;
};

const getGroupedOptionsIndexOffset = (options: GroupDropdownOption[], currentGroupIndex: number) => {
  const previousGroups = options.slice(0, currentGroupIndex);

  return getRawOptions(previousGroups).length;
};

export const DropDown = forwardRef<DropdownRef, DropDownProps>(
  (
    {
      options,
      preOptions,
      noOptionsText,
      style,
      displayAbove = false,
      handleChange,
      dropDownClassName,
      rootClassName,
      listClassName,
      selectedIndex,
      handleClose,
      isMultiSelect,
      initialSelectedItems,
      selectedItems,
      footer
    },
    ref
  ) => {
    const [cursorIndex, setCursorIndex] = useState(selectedIndex ?? -1);
    const [selection, setSelection] = useState<DropdownOption[]>(initialSelectedItems ?? []);

    useEffect(() => {
      if (selectedItems) {
        setSelection(selectedItems);
      }
    }, [selectedItems]);

    const handleMultiSelectChange = (value: DropdownOption) => {
      let changedValues: DropdownOption[] = [];
      const selectedItem = findSelectedItem(selection, value);

      if (selectedItem) {
        changedValues = selection.filter(({ id }) => id !== value.id);
      } else {
        changedValues = [...selection, value];
      }

      setSelection(selectedItems ?? changedValues);
      handleChange(changedValues, value);
    };

    const handleKeyDown = (event) => {
      const { length } = getRawOptions(options);

      if ([EventKey.ArrowUp, EventKey.ArrowDown, EventKey.Enter].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (event.key === EventKey.ArrowUp) {
        const nextIndex = (length + (isNil(cursorIndex) ? length : cursorIndex) - 1) % length;
        setCursorIndex(nextIndex);
      }

      if (event.key === EventKey.ArrowDown) {
        const nextIndex = (length + (isNil(cursorIndex) ? -1 : cursorIndex) + 1) % length;
        setCursorIndex(nextIndex);
      }

      if (event.key === EventKey.Enter) {
        const rawOptions = getRawOptions(options);
        const option = rawOptions[cursorIndex ?? -1];
        if (option) {
          const item = option && option.item;
          if (item && item !== NO_ITEM && !option.disabled) {
            if (isMultiSelect) {
              handleMultiSelectChange(option);
            } else {
              handleChange(item);
            }
          }
        }
      }

      if (event.key === EventKey.Escape) {
        handleClose();
      }
    };

    useImperativeHandle(ref, () => ({
      handleKeyDown
    }));

    const handleChangeFnc = (value, item) => {
      if (value.item === NO_ITEM || value.disabled) {
        return;
      }

      if (isMultiSelect) {
        handleMultiSelectChange(value);
        return;
      }

      handleChange(item);
    };

    const isSelected = (value, index) => {
      if (isMultiSelect) {
        return !!findSelectedItem(selection, value);
      }
      return selectedIndex === index;
    };

    const isHovered = (index: number) => index === cursorIndex;

    const checkIsSelectedByKey = (value: DropdownOption) => {
      if (selectedIndex && selectedIndex >= 0) {
        const rawOptions = getRawOptions(options);
        return rawOptions[selectedIndex].id === value.id;
      }
      return false;
    };

    return (
      <span
        className={classNames(css.dropdown, rootClassName, { [css.isUp]: displayAbove })}
        style={style}
        data-dropdown-root="true"
      >
        <ul tabIndex={-1} data-dropdown-list="true" className={classNames(css.list, listClassName)}>
          {preOptions && <li>{typeof preOptions === "function" ? preOptions(options) : preOptions}</li>}

          {areOptionsGrouped(options)
            ? options.map((group, groupIndex) => (
                <DropdownGroup
                  key={`group-${group.label}-${groupIndex}`}
                  {...group}
                  onChange={handleChangeFnc}
                  checkIsSelected={isSelected}
                  checkIsHovered={isHovered}
                  checkIsSelectedByKey={checkIsSelectedByKey}
                  dropDownClassName={dropDownClassName}
                  indexOffset={getGroupedOptionsIndexOffset(options, groupIndex)}
                />
              ))
            : options.map((value, index) => (
                <>
                  <DropDownItem
                    key={value.id}
                    value={value}
                    selected={isSelected(value, index)}
                    hovered={isHovered(index)}
                    handleChange={(item) => handleChangeFnc(value, item)}
                    dropDownItemClassName={dropDownClassName}
                    tooltipMsg={value.tooltipMsg}
                    onKeyDown={handleKeyDown}
                  />
                  {value.separator && <div className={css.separator} />}
                </>
              ))}
          {options.length === 0 && noOptionsText && <li>{noOptionsText}</li>}
        </ul>
        {footer}
      </span>
    );
  }
);

DropDown.displayName = "DropDown";

export default DropDown;
