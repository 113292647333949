import AppContext from "PFApp/app_context";
import { Button } from "PFComponents/button";
import { NOTIFICATIONS_ACTIONS, NOTIFICATIONS_ASYNC_ACTIONS } from "PFReducers/notifications_reducer";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import NotificationItem from "./notification_item";
import css from "./notifications_panel.module.scss";

const NotificationsPanel = () => {
  const {
    dispatch,
    store: {
      notifications: { showUnreadOnly, isLoading, notifications, page, target }
    }
  } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    const type = NOTIFICATIONS_ASYNC_ACTIONS.NOTIFICATIONS_FETCH_COLLECTION;
    dispatch({ type, payload: { page: 1, showUnreadOnly, target } });
  }, [showUnreadOnly]);

  const handleMarkAsRead = (id) =>
    dispatch({
      type: NOTIFICATIONS_ASYNC_ACTIONS.MARK_AS_READ,
      payload: id
    }).then(() => {
      dispatch({
        type: NOTIFICATIONS_ACTIONS.NOTIFICATION_SET_MARK_AS_READ,
        payload: id
      });
    });

  const nextPage = () => {
    if (!isLoading) {
      const type = NOTIFICATIONS_ASYNC_ACTIONS.NOTIFICATIONS_FETCH_COLLECTION;
      dispatch({ type, payload: { page: page + 1, showUnreadOnly, target } });
    }
  };

  const anyNotifications = notifications.entries.length > 0;

  return (
    <div className={isLoading ? css.isLoading : null}>
      {anyNotifications ? (
        notifications.entries.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onMarkAsRead={handleMarkAsRead}
          />
        ))
      ) : (
        <div className={css.noNotifications}>{isLoading ? "..." : t("notifications.upToDate")}</div>
      )}
      {notifications && notifications.meta.total > notifications.entries.length && (
        <div className={css.showMore}>
          <Button kind="text" onClick={nextPage} rootClassName={css.showMore}>
            {isLoading ? "..." : t("notifications.showOlder")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotificationsPanel;
