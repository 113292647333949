import { useCallback, useMemo, useState } from "react";

export enum ModalKey {
  NoRequirements = "NO_REQUIREMENTS",
  Misalignment = "MISALIGNMENT",
  Public = "PUBLIC",
  QuestionsChanged = "QUESTIONS_CHANGED"
}

const INITIAL_MODALS_STATE = {
  [ModalKey.NoRequirements]: false,
  [ModalKey.Misalignment]: false,
  [ModalKey.Public]: false,
  [ModalKey.QuestionsChanged]: false
};

type ModalState = Record<ModalKey, boolean>;

export type UseConfirmModalsManagerReturn = {
  isModalDisplayed: ModalState;
  isModalConfirmed: ModalState;
  setModalDisplayed: (key: ModalKey, value: boolean) => void;
  setModalConfirmed: (key: ModalKey, value: boolean) => void;
};

export const useConfirmModalsManager = (): UseConfirmModalsManagerReturn => {
  const [isModalDisplayed, setIsModalDisplayed] = useState<ModalState>(INITIAL_MODALS_STATE);
  const [isModalConfirmed, setIsModalConfirmed] = useState<ModalState>(INITIAL_MODALS_STATE);

  const setModalDisplayed = useCallback((key: ModalKey, value: boolean): void => {
    setIsModalDisplayed((prev) => ({
      ...prev,
      [key]: value
    }));
  }, []);

  const setModalConfirmed = useCallback((key: ModalKey, value: boolean): void => {
    setIsModalConfirmed((prev) => ({
      ...prev,
      [key]: value
    }));
  }, []);

  return useMemo(
    () => ({ isModalDisplayed, isModalConfirmed, setModalDisplayed, setModalConfirmed }),
    [isModalConfirmed, isModalDisplayed, setModalConfirmed, setModalDisplayed]
  );
};
