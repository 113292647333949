import { cloneDeep } from "lodash";
import { useState } from "react";

import { FormFieldData } from "../../activity_edit_form.types";

export type UseCachedValuesReturn = {
  cachedSubtemplateValues: FormFieldData[];
  saveSubtemplateValuesInCache: (values: FormFieldData[]) => void;
  cachedFrameworksValues: FormFieldData[];
  saveFrameworksValuesInCache: (values: FormFieldData[]) => void;
};

export const useCachedValues = () => {
  // Saving subtemplate values in state simplifies the logic when Engagement or SF is changed later,
  // because we don't have to query for Custom Values options
  const [cachedSubtemplateValues, setCachedSubtemplateValues] = useState<FormFieldData[]>([]);

  // Saving SF values in state is needed to avoid values conflicts solving once again
  // when Engagement or subtemplate is changed
  const [cachedFrameworksValues, setCachedFrameworksValues] = useState<FormFieldData[]>([]);

  const saveSubtemplateValuesInCache = (values: FormFieldData[]) => {
    setCachedSubtemplateValues(cloneDeep(values));
  };

  const saveFrameworksValuesInCache = (values: FormFieldData[]) => {
    setCachedFrameworksValues(cloneDeep(values));
  };

  return {
    cachedSubtemplateValues,
    saveSubtemplateValuesInCache,
    cachedFrameworksValues,
    saveFrameworksValuesInCache
  };
};
