import { Query, useQueryClient } from "@tanstack/react-query";
import { cloneDeep, isEqual, pick } from "lodash";
import { Id, Match, PaginatedCollection, ShortlistMinimized } from "PFTypes";
import { useCallback } from "react";

import { matchesKeys } from "./query_keys";

export const useMatchesShortlistReplace = () => {
  const queryClient = useQueryClient();

  const getMatchesQueries = useCallback(
    (activityId: Id) => {
      const matchesQueryKey = matchesKeys.activity(activityId);
      return queryClient.getQueryCache().findAll([], {
        predicate: (query) => isEqual(query.queryKey.slice(0, matchesQueryKey.length), matchesQueryKey)
      }) as Query<PaginatedCollection<Match[]>>[];
    },
    [queryClient]
  );

  const replaceMatchesShortlistInCache = useCallback(
    (shortlist: ShortlistMinimized) => {
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      const { profile_id: profileId, activity_id: activityId } = shortlist;
      const shortlistReplacement = pick(shortlist, "id", "state");

      const matchesQueries = getMatchesQueries(activityId);

      matchesQueries.forEach((query) => {
        const changedQueryData = cloneDeep(query.state.data);
        if (changedQueryData) {
          changedQueryData.entries = (changedQueryData.entries as Match[]).map<Match>((match) => {
            if (match.id === profileId) {
              return {
                ...match,
                shortlist: shortlistReplacement
              };
            }

            return match;
          });

          query.setData(changedQueryData);
        }
      });
    },
    [getMatchesQueries]
  );

  const deleteMatchesShortlistInCache = useCallback(
    (activityId: Id, shortlistId: Id) => {
      const matchesQueries = getMatchesQueries(activityId);

      matchesQueries.forEach((query) => {
        const changedQueryData = cloneDeep(query.state.data);
        if (changedQueryData) {
          changedQueryData.entries = (changedQueryData.entries as Match[]).map((match) => {
            if (match.shortlist?.id === shortlistId) {
              return {
                ...match,
                shortlist: null
              };
            }

            return match;
          });

          query.setData(changedQueryData);
        }
      });
    },
    [getMatchesQueries]
  );

  return {
    replaceMatchesShortlistInCache,
    deleteMatchesShortlistInCache
  };
};
