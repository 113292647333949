import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import keyBy from "lodash/keyBy";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
import uniqueId from "lodash/uniqueId";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import { useBookingInvalidate, useBookings } from "PFCore/hooks/queries";
import { useActivities } from "PFCore/hooks/queries/activities/use_activities";
import { useBookingsForUpdate } from "PFCore/hooks/queries/bookings/bulk_operation/use_bookings_for_update";
import { useBookingsForUpdateInvalidate } from "PFCore/hooks/queries/bookings/bulk_operation/use_bookings_for_update_invalidate";
import { Booking, CalendarRange, Profile } from "PFTypes";
import { useEffect, useState } from "react";

type UseProfileBookings = {
  profile: Profile;
  dateRange?: CalendarRange;
  fetchAllEnabled?: boolean;
};
type UseProfileBookingsReturn = {
  bookings: BookingWithProfileId[];
  refreshBookings: () => Promise<void>;
};

export type BookingWithProfileId = Booking & {
  _localId: string;
  profile_id: number;
};

export const useProfileBookings = ({
  profile,
  dateRange,
  fetchAllEnabled
}: UseProfileBookings): UseProfileBookingsReturn => {
  const [bookings, setBookings] = useState<BookingWithProfileId[]>([]);
  const { invalidateProfileBookingsList } = useBookingInvalidate();
  const { invalidate: invalidateForUpdate } = useBookingsForUpdateInvalidate();

  const isFetchingBookingsEnabled = !!dateRange || fetchAllEnabled;

  const { data } = useBookings(
    {
      profileId: profile.id,
      ...(!fetchAllEnabled ? { dateRange } : {})
    },
    {
      enabled: isFetchingBookingsEnabled
    }
  );

  const bookingActivityIds = uniq(
    compact((data?.entries ?? []).map(({ activity_id: activityId }) => activityId))
  );
  const { data: bookingActivities } = useActivities(
    { filters: { fields: { id: bookingActivityIds } }, perPage: PER_PAGE_UNPAGINATED },
    { enabled: isFetchingBookingsEnabled && !isEmpty(bookingActivityIds) }
  );

  const { data: bookingsForUpdateData } = useBookingsForUpdate(
    "profile_bookings",
    {
      filters: [{ profileId: profile.id }],
      dateRange,
      page: 1,
      perPage: PER_PAGE_UNPAGINATED
    },
    {
      enabled: isFetchingBookingsEnabled
    }
  );

  useEffect(() => {
    if (!data?.entries) {
      return;
    }
    const bookingForUpdateIds = new Set((bookingsForUpdateData?.entries ?? []).map(({ id }) => id));
    const activitiesById = keyBy(
      (bookingActivities?.entries ?? []).map(({ activity }) => activity),
      "id"
    );
    setBookings(
      (data?.entries ?? []).map((entry) => {
        const readonly = !bookingForUpdateIds.has(entry.id);
        const activity =
          !isNil(entry.activity_id) && activitiesById[entry.activity_id]
            ? pick(activitiesById[entry.activity_id], "id", "name", "template_id")
            : undefined;
        return {
          ...entry,
          _localId: entry._localId ?? uniqueId("local"),
          profile_id: profile.id, // eslint-disable-line camelcase
          readonly,
          activity
        };
      })
    );
  }, [data?.entries, bookingsForUpdateData, bookingActivities]);

  const refreshBookings = async () => {
    await invalidateProfileBookingsList(profile.id);
    await invalidateForUpdate("profile_bookings");
  };

  return {
    bookings,
    refreshBookings
  };
};
