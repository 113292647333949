import { isNil, omitBy } from "lodash";
import { ApiRoute } from "PFCore/utilities/routes";
import { Id } from "PFTypes/id";

export type FetchMessagesPayload = {
  conversationId?: Id;
  targetId?: string;
  targetType?: string;
  page?: number;
  perPage?: number;
};

export type FetchMessagesResponse = {
  entries: any[];
  meta: { total: number };
};

export const fetchMessages = ({
  conversationId,
  targetId,
  targetType,
  page = 1,
  perPage = 10
}: FetchMessagesPayload = {}): Promise<FetchMessagesResponse> => {
  const searchParams = new URLSearchParams(
    omitBy(
      {
        conversation_id: conversationId,
        target_id: targetId,
        target_type: targetType,
        page,
        per_page: perPage
      },
      isNil
    )
  );

  // @ts-ignore
  return $.ajax({
    url: ApiRoute(`/api/chat/messages?${searchParams.toString()}`),
    api_version: 2
  });
};
