import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Id } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Overbooking } from "../../../../../types";
import { DetailsPanelData } from "../../../details_panel_context/details_panel_context";

const ALL_OPTION_ID = "all";

type UseOverbookingsFilter = {
  overbookingsDetails: DetailsPanelData["overbookingsDetails"];
  initialOverbookings: Overbooking[] | undefined;
};

type UseOverbookingsFilterReturn = {
  options: DropdownOption[];
  selectedOption: Id;
  setSelectedOption: (id: Id) => void;
  currentInitialOverbookings: Overbooking[] | undefined;
  isSingleOption: boolean;
  isFilterEnabled: boolean;
};

export const useOverbookingsFilter = ({
  initialOverbookings,
  overbookingsDetails
}: UseOverbookingsFilter): UseOverbookingsFilterReturn => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "details.overbookings" });

  const allOption: DropdownOption = {
    id: ALL_OPTION_ID,
    displayElement: t("allOverbookings"),
    item: ALL_OPTION_ID
  };

  const activityOption: DropdownOption[] = overbookingsDetails?.filterConfig
    ? [
        {
          id: overbookingsDetails?.filterConfig.filteredActivity.id,
          displayElement: overbookingsDetails?.filterConfig.filteredActivity.name,
          item: overbookingsDetails?.filterConfig.filteredActivity.id
        }
      ]
    : [];

  const options = [allOption, ...activityOption];

  const showAllOverbookingsEnabled = !!overbookingsDetails?.filterConfig?.showAllOverbookingsEnabled;

  const defaultSelectedOption = showAllOverbookingsEnabled
    ? ALL_OPTION_ID
    : overbookingsDetails?.filterConfig?.filteredActivity.id ?? options[0].id;

  const [selectedOption, setSelectedOption] = useState<Id>(defaultSelectedOption);

  const filteredOverbookings = overbookingsDetails?.filterConfig?.filteredOverbookings;

  const isSingleOption = (initialOverbookings ?? []).length === (filteredOverbookings ?? []).length;

  const isFilterEnabled = !!overbookingsDetails?.filterConfig;

  const currentInitialOverbookings =
    selectedOption === ALL_OPTION_ID ? initialOverbookings : filteredOverbookings;

  return {
    options,
    selectedOption,
    setSelectedOption,
    currentInitialOverbookings,
    isSingleOption,
    isFilterEnabled
  };
};
