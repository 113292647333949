import { find } from "lodash";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { FeatureFlag, PermissionRule } from "PFTypes";
import { useMemo } from "react";

export const useIsAuditPlannerPermitted = () => {
  const { data: currentProfile } = useCurrentProfile();

  const isPermittedTo = useIsPermittedTo(currentProfile);
  const isEnabled = useIsFeatureEnabled();

  const auditPlannerAccess = useMemo(() => {
    if (!isEnabled(FeatureFlag.AuditPlanner)) {
      return false;
    }
    const engagementReadPermitted = find(currentProfile.templates, {
      key: "engagement"
    })?.read_permitted;
    const roleReadPermitted = find(currentProfile.templates, { key: "role" })?.read_permitted;
    const isAuditResourcer = isPermittedTo(PermissionRule.AuditResourcer);
    const isAuditApprover = isPermittedTo(PermissionRule.AuditApprover);
    return (engagementReadPermitted || roleReadPermitted) && (isAuditResourcer || isAuditApprover);
  }, [currentProfile, isPermittedTo, isEnabled]);

  return auditPlannerAccess;
};

export default useIsAuditPlannerPermitted;
