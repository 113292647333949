/*
 * We need to create the minimum structure that mirrors the values selected
 *
 * valueFilters: ValueFilters TO apiFilters: ValueFilters
 *
 * This structure needs to preserve all keys that have present values
 * It will clear nulls and empty arrays and empty objects
 *
 * returns ValueFilters
 * */
import { cloneDeep, isArray, isEmpty } from "lodash";
import { AvailabilityRule, Filters, Value } from "PFTypes";

export const getAPIFilters = (valueFilters: Filters<Value> | undefined, excludeList: string[] = []) => {
  if (isEmpty(valueFilters)) {
    return valueFilters;
  }

  const valueFiltersCopy = cloneDeep(valueFilters);

  Object.keys(valueFiltersCopy).forEach((key) => {
    if (["availability", "children"].includes(key)) {
      const isAvailabilityWithEmptyRanges =
        key === "availability" && isEmpty((valueFiltersCopy[key] as AvailabilityRule)?.ranges);

      if (isEmpty(valueFiltersCopy[key]) || excludeList.includes(key) || isAvailabilityWithEmptyRanges) {
        delete valueFiltersCopy[key];
      }

      return;
    }

    const innerFilters = valueFiltersCopy[key];
    Object.keys(innerFilters).forEach((filterName) => {
      const value = innerFilters[filterName];

      if (
        (isArray(value) && isEmpty(value)) ||
        value == null || // eslint-disable-line eqeqeq
        value === "" ||
        excludeList.includes(filterName)
      ) {
        delete innerFilters[filterName];
      }
    });

    if (isEmpty(valueFiltersCopy[key])) {
      delete valueFiltersCopy[key];
    }
  });

  return valueFiltersCopy;
};
