const EMOJIS = [
  { pattern: ":smile:", display: "😃" },
  { pattern: ":sad:", display: "😢" },
  { pattern: ":disappointed:", display: "🙁" },
  { pattern: ":tongue:", display: "😛" },
  { pattern: ":angry:", display: "😤" },
  { pattern: ":joy:", display: "😂" },
  { pattern: ":thumbsup:", display: "👍" },
  { pattern: ":thumbsdown:", display: "👎" },
  { pattern: ":star:", display: "⭐️" },
  { pattern: ":heart:", display: "❤️" },
];

export default EMOJIS;
