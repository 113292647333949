import classNames from "classnames";
import PropTypes from "prop-types";

import keyWordsCss from "../search_box/choosen_keyword.less";

const Keyword = ({ text, onAdd }) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <li className={classNames(keyWordsCss.item, keyWordsCss.addable)} onClick={onAdd}>
    <div className={keyWordsCss.wrap}>
      <span className={keyWordsCss.text}>
        <span className={keyWordsCss.plus}>{"+ "}</span>
        {text}
      </span>
    </div>
  </li>
);

Keyword.propTypes = {
  text: PropTypes.string,
  onAdd: PropTypes.func
};

export default Keyword;
