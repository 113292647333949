import { useMutation } from "@tanstack/react-query";
import { useMatchesShortlistReplace } from "PFCore/hooks/queries/matches/use_matches_shortlist_replace";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { deleteShortlist } from "PFCore/services/shortlist";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";

type UseShortlistDelete = {
  activityId: Id;
  options?: MutationOptions<Id, null>;
  withInvalidate?: boolean;
};

export const useShortlistDelete = ({
  options = {},
  withInvalidate = true,
  activityId
}: UseShortlistDelete) => {
  const { invalidateShortlists } = useShortlistsInvalidate();
  const { deleteMatchesShortlistInCache } = useMatchesShortlistReplace();

  return useMutation({
    mutationFn: async (id: Id): Promise<null> => deleteShortlist(id),
    ...options,
    onSuccess: (...args) => {
      const shortlistId = args[1];
      if (withInvalidate) {
        invalidateShortlists(activityId);
      }
      deleteMatchesShortlistInCache(activityId, shortlistId);

      options?.onSuccess?.(...args);
    }
  });
};
