import { Filter, Meta } from "PFTypes";
import { PageTarget } from "PFTypes/saved_filters";
import { RefObject } from "react";

import { FilterElement } from "./filter_element";
import css from "./plain_filters.module.scss";

type FiltersListProps = {
  filters: Filter[];
  onFocus: (filter: object) => void;
  onBlur: () => void;
  innerStyles: React.CSSProperties;
  lastFocusedFilterNameRef: RefObject<string | null>;
  viewsKey: PageTarget | null;
  classes?: {
    list?: string;
  };
  clearRefValue?: number | string;
  fetchOptions?: (filter: any, props?: Record<string, any>) => void;
  meta?: Partial<Meta>;
  narrow?: boolean;
  onFilterChange: (filter: any, valueId: any, filterValue: any) => void;
};

export type FiltersBaseProps = {
  clearRefValue?: number | string;
  fetchOptions?: (filter: any, props?: Record<string, any>) => void;
  meta?: Partial<Meta>;
  narrow?: boolean;
  onFilterChange: (filter: any, valueId: any, filterValue: any) => void;
};

export const FiltersList = (props: FiltersListProps) => {
  const { filters, clearRefValue, innerStyles } = props;

  return (
    <ul className={css.list} key={clearRefValue} style={innerStyles}>
      {filters.map((filter, index) => (
        <FilterElement filter={filter} key={`${index}:${filter.name}`} {...props} />
      ))}
    </ul>
  );
};
