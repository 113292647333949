import { Icon } from "PFComponents/icon";
import Toggle, { ToggleProps } from "PFComponents/toggle/toggle";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";

import css from "./side_panel_header.module.scss";

type SidePanelHeaderProps = {
  title: string;
  icon: IconName;
  onToggle: VoidFunction;
  tooltip?: string;
  label?: React.ReactNode;
} & Partial<Omit<ToggleProps, "onChange" | "compact">>;

export const SidePanelHeader = ({
  title,
  icon,
  tooltip,
  label,
  onToggle,
  ...toggleProps
}: SidePanelHeaderProps) => (
  <div className={css.root}>
    <div className={css.title}>
      <Icon name={icon} />
      <Typography variant="bodyBold" noMargin>
        {title}
      </Typography>
    </div>
    {onToggle && (
      <Tooltip content={tooltip}>
        <div className={css.toggleWrapper}>
          {label}
          <Toggle onChange={onToggle} {...toggleProps} compact={true} />
        </div>
      </Tooltip>
    )}
  </div>
);
