import { camelize } from "humps";
import { chain, isArray, isEmpty } from "lodash";

export type ErrorFormatOptions = {
  version?: string;
};

export const getFormattedErrors = (t, response, options: ErrorFormatOptions = {}) => {
  const errors = response?.responseJSON?.errors || response?.errors || response?.data?.errors || {};
  const errorsVersion =
    options.version ||
    (response?.getResponseHeader && response.getResponseHeader("X-Api-Version")) ||
    response?.headers?.["x-api-version"];

  if (isEmpty(errors)) {
    return;
  }

  if (errorsVersion === "v1") {
    return errors;
  }

  return Object.values(errors).reduce((errorObject: Record<string, string>, error) => {
    const errorName = getErrorName(error);
    if (!errorName) {
      return errorObject;
    }
    return { ...errorObject, [errorName]: getErrorDetail(t, error) };
  }, {});
};

const getErrorName = (error) =>
  error?.source?.pointer ? getErrorFieldName(error.source.pointer) : error.title;

const getErrorDetail = (t, error) => {
  if (!error.detail) {
    return;
  }
  if (!error.source?.pointer) {
    return t(error.detail, { defaultValue: error.detail });
  }
  const i18nKey = (error.meta ? error.meta.translation_key || error.meta.translationKey : undefined) || "";
  const camelizedI18Key = camelize(i18nKey);

  return t(camelizedI18Key, { defaultValue: t(i18nKey, { defaultValue: error.detail }) });
};

const getErrorFieldName = (pointer) => {
  const name = pointer.replace(/^\/data\/attributes\//, "");
  if (name.indexOf("/") >= 0) {
    return `${name.replace("/", "[").replace(/\//g, "][")}]`;
  }
  return name;
};

export const getFirstError = (t, errors, options = {}, key) => {
  const formattedErrors = getFormattedErrors(t, errors, options);

  let i18nKey;
  if (key) {
    i18nKey = isArray(formattedErrors[key])
      ? formattedErrors[key] !== null
        ? formattedErrors[key][0]
        : undefined
      : formattedErrors[key];
  } else {
    i18nKey = chain(formattedErrors).values().first().value();
    if (isArray(i18nKey)) {
      i18nKey = i18nKey[0];
    }
  }

  return t(i18nKey, { defaultValue: i18nKey });
};

export const isUnauthorized = (t, errors, options = {}) => {
  const formattedErrors = getFormattedErrors(t, errors, options);
  if (isArray(formattedErrors)) {
    return formattedErrors.some(({ status, detail }) => status === "422" && detail === "Unauthorized");
  } else {
    return Object.values(formattedErrors).flat().includes("Unauthorized");
  }
};
