import { FiltersPanelProvider } from "PFApp/components/filters/context/filters_panel_context";

import css from "../../../activity_page.module.scss";
import { MatchesPanel, MatchesSidePanel } from "./parts";

export const ActivityMatches = () => (
  <FiltersPanelProvider>
    <div className={css.pageCardContainer}>
      <MatchesSidePanel />
      <MatchesPanel />
    </div>
  </FiltersPanelProvider>
);
