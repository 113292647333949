import classNames from "classnames";
import useChat from "PFApp/use_chat";
import { Button } from "PFComponents/button";
import { getProfileName } from "PFCore/helpers/profile";
import useForceUpdate from "PFCore/helpers/use_force_update";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./activity_details_other_chats.module.scss";

const ActivityDetailsOtherChatsItem = ({ conversation, participants }) => {
  const { t } = useTranslation("activities");
  let chatUsers = "";

  const { data: currentProfile } = useCurrentProfile();
  const { renderChat } = useChat();

  if (!participants) {
    chatUsers = "...";
  } else {
    const names = participants
      .filter(({ profile }) => profile.id !== currentProfile.id)
      .map(({ profile }) => getProfileName(profile));

    if (names.length === 0) {
      chatUsers = conversation.owner?.first_name; // should never happen
    } else if (names.length === 1) {
      chatUsers = names[0];
    } else if (names.length <= 5) {
      chatUsers = t("show.details.chatUsers1", {
        users1: names.slice(0, names.length - 1).join(", "),
        users2: names[names.length - 1]
      });
    } else {
      chatUsers = t("show.details.chatUsers2", {
        users1: names.slice(0, 4).join(", "),
        userCount: names.length - 4
      });
    }
  }

  const isUnread = conversation.unread_count > 0;

  const forceUpdate = useForceUpdate();

  return (
    <div data-qa-id="ActivityDetailsOtherChatsItem" className={css.otherChatsList}>
      <Button
        kind="blank"
        icon="chat"
        text={chatUsers}
        className={classNames(css.name, { [css.unread]: isUnread })}
        onClick={() => {
          renderChat(conversation.id);
          conversation.unread_count = 0;
          forceUpdate();
        }}
      />
    </div>
  );
};

ActivityDetailsOtherChatsItem.propTypes = {
  conversation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    owner: PropTypes.object.isRequired,
    unread_count: PropTypes.number
  }).isRequired,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      profile: PropTypes.object.isRequired
    })
  )
};

export default ActivityDetailsOtherChatsItem;
