import moment, { MomentInput } from "moment/moment";

const isDateBetween = (date: MomentInput, start: MomentInput, end: MomentInput) => {
  const dateFormatted = (moment.isMoment(date) ? date : moment(date)).format("YYYY-MM-DD");
  const startMoment = moment.isMoment(start) ? start : moment(start);
  const endMoment = moment.isMoment(end) ? end : moment(end);
  return startMoment.format("YYYY-MM-DD") <= dateFormatted && dateFormatted <= endMoment.format("YYYY-MM-DD");
};

export default isDateBetween;
