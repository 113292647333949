import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button/";
import { Icon } from "PFComponents/icon/icon";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography/typography";
import { useTranslation } from "react-i18next";

import css from "../description_generator.module.scss";
import { Navigation } from "./navigation";

type HeaderProps = {
  onClose: () => void;
  isLoading?: boolean;
  aiDescriptions: string[];
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedDescription?: string;
  handleNewDescription: (props?: { longer?: boolean; regenerate?: boolean }) => void;
  handleDescriptionChange: () => void;
  description?: string;
};

export const Header = ({
  isLoading,
  onClose,
  aiDescriptions,
  selectedIndex,
  setSelectedIndex,
  selectedDescription,
  handleNewDescription,
  handleDescriptionChange,
  description
}: HeaderProps) => {
  const { t } = useTranslation("activities");

  return (
    <div className={classNames(css.alignCenter, css.headerWrapper)}>
      <div>
        <Icon name="ai" className={css.magicIcon} size="md" />
        <Typography variant="h5" className={css.header}>
          <div className={css.alignCenter}>{t("edit.sections.descriptionGenerate.aiDescription")}</div>
        </Typography>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <Navigation
            descriptions={aiDescriptions}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        )}
      </div>
      {selectedDescription && (
        <div className={css.buttons}>
          <Button
            kind="secondary"
            small
            onClick={() => handleNewDescription({ regenerate: true })}
            disabled={!description && !selectedDescription}
          >
            {t("edit.sections.descriptionGenerate.tryAgain")}
          </Button>
          <Button
            kind="secondary"
            small
            onClick={() => handleNewDescription({ longer: true })}
            disabled={!description && !selectedDescription}
          >
            {t("edit.sections.descriptionGenerate.makeLonger")}
          </Button>
          <Button small disabled={!selectedDescription} onClick={handleDescriptionChange}>
            {t("edit.sections.descriptionGenerate.insert")}
          </Button>
        </div>
      )}
      <ActionIcon onClick={onClose} name="cross" size="sm" />
    </div>
  );
};

Header.displayName = "DescriptionGeneratorHeader";
