export const variables = {
  // Onboarding
  "--Onboarding-bg": "rgba(0,0,0,0.5)",

  //Sidebar
  "--Sidebar-counter": "#86BC25",
  "--Sidebar-avatar-progress": "#86BC25",

  // Legacy:
  "--Color-primary": "#86BC25",
  "--Color-secondary": "#dfdfdf"
};
