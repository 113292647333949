import { useMutation } from "@tanstack/react-query";

import { signUp, SignUpPayload } from "../../../services/signup/sign_up";
import { authKeys } from "./query_keys";

export const useSignUp = () => {
  const mutationKey = authKeys.signUp();

  return useMutation(async (data: SignUpPayload) => signUp(data), { mutationKey });
};
