import moment, { Moment } from "moment";
import { ISO_DATE_FORMAT } from "PFCore/helpers/date";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { AvailabilityMode, AvailabilityRange, StandardRange, TimeRuleRange } from "PFTypes";

import { ISO_WEEKDAY_TO_DAY_NAME_MAP } from "./use_load_estimation.consts";

export type LoadEstimationData = {
  totalAvailableHours: number | null;
  percentageLoad: number | null;
};

export const useLoadEstimation = (isTimeRule: boolean, availabilityMode: AvailabilityMode) => {
  const {
    data: {
      availability: { default_calendar: defaultCalendar }
    }
  } = useCurrentAccount();

  const defaultNominalDayHours = (defaultCalendar?.default_nominal_day || 0) / 60;
  const nominalDays = defaultCalendar?.nominal_days || [];

  const getWorkingHoursForWeekday = (isoWeekday: number) => {
    const nominalDay = nominalDays.find(
      ({ day_name: dayName }) => dayName === ISO_WEEKDAY_TO_DAY_NAME_MAP[isoWeekday]
    );

    return (nominalDay?.working_minutes || 0) / 60;
  };

  const getTotalAvailableHoursEstimation = (startDate: Moment, endDate: Moment): number => {
    let totalHours = 0;

    for (
      let currentDate = startDate.clone();
      currentDate.isSameOrBefore(endDate);
      currentDate.add(1, "days")
    ) {
      const isoWeekday = currentDate.isoWeekday();
      const weekdayWorkingHours = getWorkingHoursForWeekday(isoWeekday);

      totalHours += weekdayWorkingHours;
    }

    return totalHours;
  };

  const getStandardRuleLoadHours = (startDate: Moment, endDate: Moment, range: StandardRange) => {
    let loadHours = 0;
    if (range.duration.mode === "days") {
      loadHours = range.duration.value * defaultNominalDayHours;
    } else if (range.duration.mode === "minutes") {
      loadHours = range.duration.value / 60;
    }

    if (availabilityMode === AvailabilityMode.PerWeek) {
      const numberOfWeeks = endDate.endOf("week").diff(startDate.startOf("week"), "weeks") + 1;

      loadHours *= numberOfWeeks;
    } else if (availabilityMode === AvailabilityMode.PerMonth) {
      const numberOfMonths = endDate.endOf("month").diff(startDate.startOf("month"), "months") + 1;

      loadHours *= numberOfMonths;
    }

    return loadHours;
  };

  const getTimeRuleLoadHours = (startDate: Moment, endDate: Moment, range: TimeRuleRange): number => {
    let loadHours = 0;

    for (
      let currentDate = startDate.clone();
      currentDate.isSameOrBefore(endDate);
      currentDate.add(1, "days")
    ) {
      const dayOfWeekIndex = currentDate.isoWeekday() - 1;
      loadHours += range.day_of_week[dayOfWeekIndex] / 60;
    }

    return loadHours;
  };

  const getLoadEstimationData = (range: AvailabilityRange): LoadEstimationData => {
    if (!range.start || !range.end) {
      return { totalAvailableHours: null, percentageLoad: null };
    }
    const startDate = moment(range.start, ISO_DATE_FORMAT);
    const endDate = moment(range.end, ISO_DATE_FORMAT);

    const totalAvailableHours = getTotalAvailableHoursEstimation(startDate, endDate);

    if (totalAvailableHours === 0) {
      return { totalAvailableHours, percentageLoad: null };
    }

    const loadHours = isTimeRule
      ? getTimeRuleLoadHours(startDate, endDate, range as TimeRuleRange)
      : getStandardRuleLoadHours(startDate, endDate, range as StandardRange);

    const percentageLoad = Math.round((loadHours / totalAvailableHours) * 100);

    return { totalAvailableHours, percentageLoad };
  };

  return {
    getLoadEstimationData
  };
};
