import DropupArrowIcon from "PFIcons/dropup_arrow.svg";
import PropTypes from "prop-types";

import css from "./priority_badge.module.scss";

const PriorityBadge = ({ level }) => {
  if (level === "high") {
    return <DropupArrowIcon className={css.high} width={20} height={20} />;
  } else if (level === "low") {
    return <DropupArrowIcon className={css.low} width={20} height={20} />;
  } else if (!level) {
    return <div className={css.none}>--</div>;
  } else {
    return <div className={css.other} />;
  }
};

PriorityBadge.propTypes = {
  level: PropTypes.string
};

export default PriorityBadge;
