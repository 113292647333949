import { useQuery } from "@tanstack/react-query";
import { fetchCertificates } from "PFApp/profiles/services/api";
import { Id } from "PFTypes/id";

import { certificateKeys } from "./query_keys";

export const useCertificates = (profileId: Id, page = 1, perPage = 10, customValueIds: number[] = []) =>
  useQuery({
    queryKey: certificateKeys.list(profileId, perPage, page, customValueIds),
    queryFn: () => fetchCertificates(profileId, page, perPage, customValueIds)
  });
