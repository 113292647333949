import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { ProjectsResponse } from "PFCore/hooks/queries/bookings/projects/use_projects_entries";
import { useUnmount } from "react-use";

import { BookingCalendarData } from "./context/booking_overview_context.types";
import useProjectsData from "./use_projects_data";
import { useProjectsPinnedData } from "./use_projects_pinned_data";

export const useProjects = (enabled: boolean): BookingCalendarData<{}, ProjectsResponse> => {
  const projects = useProjectsData({ enabled });
  const pinnedData = useProjectsPinnedData({
    view: projects.view,
    enabled
  });

  const { removeCache: removeActivitiesCache } = useActivityInvalidate();

  useUnmount(removeActivitiesCache);

  return {
    ...projects,
    pinnedData
  };
};
