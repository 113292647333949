import { ONE_DAY_MS } from "PFCore/utilities/time_consts";

import { Day } from "../booking_module_calendar/booking_calendar/booking_calendar_context_provider";

export const isDateBetween = (targetDate: Date, startDate: Date, endDate: Date) =>
  targetDate >= startDate && targetDate <= endDate;

export const getDaysDifference = (date1: Date, date2: Date) => {
  const timeDifference = date2.getTime() - date1.getTime();
  return timeDifference / ONE_DAY_MS;
};

export const getStartOfDay = (date: string) => {
  const dateObject = new Date(date);
  dateObject.setUTCHours(0, 0, 0);
  return dateObject;
};

export const getEndOfDay = (date: string) => {
  const dateObject = new Date(date);
  dateObject.setUTCHours(23, 59, 59);
  return dateObject;
};

export const addDays = (date: Date, days: number) => {
  date.setUTCDate(date.getUTCDate() + days);
  return date;
};

export const setTimeToMidnight = (date: Date) => {
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};

export const findDay = (days: Day[], date: string | number | Date): Day | undefined => {
  const dateToCheck = setTimeToMidnight(new Date(date)).getTime();
  return days.find(({ date: dayDate }) => dayDate.getTime() === dateToCheck);
};

export const findDayIndex = (days: Pick<Day, "date">[], date: string | number | Date): number | undefined => {
  const dateToCheck = setTimeToMidnight(new Date(date)).getTime();
  const dayIndex = days.findIndex(({ date: dayDate }) => dayDate.getTime() === dateToCheck);
  return dayIndex > -1 ? dayIndex : undefined;
};
