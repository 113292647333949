import moment from "moment";

export const UNDETERMINED_FUTURE = moment()
  .add(100, "years")
  .utcOffset(0)
  .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

export const UNDETERMINED_PAST = moment()
  .subtract(100, "years")
  .utcOffset(0)
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
