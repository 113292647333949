import { uniq, uniqBy } from "lodash";
import { isFilter, normalize } from "PFApp/search/helpers/normalize_keywords";

const pushKeyword = (search, store, payload) => {
  // we merge keywords from the task instead of overwriting them so user does not
  // loose the data they're working on (this will trigger another fetch if there
  // is any discrapency between the local store and the api)
  const normalizedNewItem = normalize(
    payload,
    store.search.customTypes,
    store.search.matchTypes,
    store.search.filterTypes
  );

  if (normalizedNewItem) {
    const foundItem = search.choosenKeywords.find((keyword) => keyword.id === payload.id);

    // update will not shuffle!
    const update = {};
    if (foundItem) {
      const index = search.choosenKeywords.indexOf(foundItem);
      search.choosenKeywords.splice(index, 1, normalizedNewItem);
      update.choosenKeywords = [...search.choosenKeywords];
    } else {
      update.choosenKeywords = uniqBy([...search.choosenKeywords, normalizedNewItem], ({ id }) => id);
    }

    update.trackKeywordsIds = uniq([...search.trackKeywordsIds, normalizedNewItem.id]);

    if (isFilter(normalizedNewItem)) {
      const { filterKey } = normalizedNewItem;
      const prevFilters = search.matchesRequestFilters || {};

      update.matchesRequestFilters = {
        ...prevFilters,
        strings: {
          ...prevFilters?.strings,
          [filterKey]: uniq([
            ...(prevFilters.strings ? [prevFilters.strings[filterKey]].flat() : []),
            normalizedNewItem.id
          ])
        }
      };
    }

    return update;
  } else {
    return {}; // noop
  }
};

export default pushKeyword;
