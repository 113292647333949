import PropTypes from "prop-types";

import css from "./brain_loading.less";

const BrainLoading = ({ text, style }) => (
  <div className={css.root} style={style}>
    <svg className={css.svg} viewBox="0 0 218 154">
      <defs>
        <filter
          x="-6.2%"
          y="-10.4%"
          width="113.3%"
          height="118.6%"
          filterUnits="objectBoundingBox"
          id="brain-filter-1"
        >
          <feOffset dx="1" dy="-1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.235772671   0 0 0 0 0.487451021   0 0 0 0 0.65796131  0 0 0 1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g className={css.content} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g filter="url(#brain-filter-1)" transform="translate(7.000000, 8.000000)">
          <g className={css.paths}>
            <path d="M106.943172,120.107395 L106.943172,111.5257" className={css.path89} />
            <path d="M35.3796929,131.414475 L57.1093536,138.710331" className={css.path88} />
            <path d="M146.446289,99.9541016 L130.206055,115.835938" className={css.path87} />
            <path d="M106.46582,111.849609 L82.4853516,111.415039" className={css.path86} />
            <path d="M35.4511719,132.150391 L10.9164866,118.089787" className={css.path85} />
            <path d="M149.833984,80.7890625 L132.182292,83.0598958" className={css.path84} />
            <path d="M176.5,72.5 L149.373047,80.6240234" className={css.path83} />
            <path d="M175.542791,72.8383508 L182.215928,57.3586248" className={css.path82} />
            <path d="M182.916358,58.1543506 L195.11431,71.8365463" className={css.path81} />
            <path d="M49.0581226,100.855523 L17.5030676,108.787766" className={css.path80} />
            <path d="M16.9312885,108.641248 L0.418786722,104.086075" className={css.path79} />
            <path d="M71.8006334,132.030583 L81.9711529,111.279769" className={css.path78} />
            <path d="M81.7043227,110.959335 L48.6781278,100.576781" className={css.path77} />
            <path d="M48.7555562,100.602987 L71.6148052,132.371268" className={css.path76} />
            <path d="M98.9672845,83.6926226 L126.048169,53.312097" className={css.path75} />
            <path d="M126.048169,53.312097 L99.1280973,51.7778233" className={css.path74} />
            <path d="M99.1280973,51.7778233 L75.2253516,84.5014517" className={css.path73} />
            <path d="M75.2253516,84.5014517 L81.3695937,110.815199" className={css.path72} />
            <path d="M157.724609,44.1738281 L166.410156,28.9746094" className={css.path71} />
            <path d="M166.388672,28.6679688 L195.201172,28.6679688" className={css.path70} />
            <path d="M194.675781,27.8535156 L201.743343,54.5886876" className={css.path69} />
            <path d="M163.492169,6.55703875 L146.50741,4.44345293" className={css.path68} />
            <path d="M117.948301,1.33475472 L146.77058,4.46812514" className={css.path67} />
            <path d="M117.607002,1.42933152 L82.614615,1.42933152" className={css.path66} />
            <path d="M30.9355914,54.7891493 L62.1099542,58.1599898" className={css.path65} />
            <path d="M30.772138,54.9412946 L9.19629212,59.0327691" className={css.path64} />
            <path d="M62.8244202,58.306995 L34.6662348,79.5641582" className={css.path63} />
            <path d="M131.934749,83.6954345 L157.499344,43.6003709" className={css.path62} />
            <path d="M157.754579,44.1159723 L180.768102,58.8157427" className={css.path61} />
            <path d="M180.823253,58.6297671 L165.925964,28.3580646" className={css.path60} />
            <path d="M166.551868,27.8604195 L132.899257,27.8604195" className={css.path59} />
            <path d="M125.841161,53.2993207 L133.570052,27.8912017" className={css.path58} />
            <path d="M102.317167,15.4859857 L125.930942,52.8016756" className={css.path57} />
            <path d="M69.4674578,26.9510628 L102.005498,15.5449849" className={css.path56} />
            <path d="M98.984997,51.9064274 L70.2690769,26.6393933" className={css.path55} />
            <path d="M63.4533906,58.6913314 L98.9401063,51.8474282" className={css.path54} />
            <path d="M74.6593844,84.4136852 L62.7825958,58.8952633" className={css.path53} />
            <path d="M75.3712221,85.0293286 L48.5804705,99.8432496" className={css.path52} />
            <path d="M165.44884,27.0280182 L163.423629,6.12435723" className={css.path51} />
            <path d="M163.53778,6.99138845 L194.818881,27.6359661" className={css.path50} />
            <path d="M195.3332,27.9065928 L180.833514,57.6485853" className={css.path49} />
            <path d="M181.161857,57.6485853 L201.993693,54.7422351" className={css.path48} />
            <path d="M201.993693,54.7422351 L195.190832,71.7545163" className={css.path47} />
            <path d="M195.190832,71.7545163 L175.344026,73.6655763" className={css.path46} />
            <path d="M175.344026,73.6655763 L157.678906,44.5353794" className={css.path45} />
            <path d="M157.678906,44.5353794 L150.79396,79.7386426" className={css.path44} />
            <path d="M150.79396,79.7386426 L146.231786,99.544406" className={css.path43} />
            <path d="M146.231786,99.544406 L131.175455,82.7078397" className={css.path42} />
            <path d="M131.175455,82.7078397 L129.467044,116.128302" className={css.path41} />
            <path d="M129.467044,116.128302 L106.897298,112.007339" className={css.path40} />
            <path d="M106.897298,112.007339 L98.6976967,83.7646944" className={css.path39} />
            <path d="M98.6976967,83.7646944 L82.2459075,110.32458" className={css.path38} />
            <path d="M82.2459075,110.32458 L86.1103529,135.860958" className={css.path37} />
            <path d="M86.1103529,135.860958 L72.0659863,132.894326" className={css.path36} />
            <path d="M72.0659863,132.894326 L56.5953789,138.885306" className={css.path35} />
            <path d="M56.5953789,138.885306 L48.4419507,101.814591" className={css.path34} />
            <path d="M48.4419507,101.814591 L36.0957339,131.570692" className={css.path33} />
            <path d="M36.0957339,131.570692 L15.4639821,107.504163" className={css.path32} />
            <path d="M15.4639821,107.504163 L10.5734643,117.496826" className={css.path31} />
            <path d="M10.5734643,117.496826 L0.134459725,104.092472" className={css.path30} />
            <path d="M0.134459725,104.092472 L9.16004951,58.4681607" className={css.path29} />
            <path d="M9.16004951,58.4681607 L33.9833073,80.5543702" className={css.path28} />
            <path d="M33.9833073,80.5543702 L30.2060781,54.9769492" className={css.path27} />
            <path d="M30.2060781,54.9769492 L37.0358728,24.8501794" className={css.path26} />
            <path d="M37.0358728,24.8501794 L62.8723771,58.8580682" className={css.path25} />
            <path d="M62.8723771,58.8580682 L69.6611289,25.9775766" className={css.path24} />
            <path d="M69.6611289,25.9775766 L48.4586244,11.5330416" className={css.path23} />
            <path d="M48.4586244,11.5330416 L83.0064838,1.13892771" className={css.path22} />
            <path d="M83.0064838,1.13892771 L102.299211,14.9126678" className={css.path21} />
            <path d="M102.299211,14.9126678 L117.839078,1.11199331" className={css.path20} />
            <path d="M117.839078,1.11199331 L132.685064,28.3452387" className={css.path19} />
            <path d="M132.685064,28.3452387 L147.042383,4.0491257" className={css.path18} />
            <path d="M147.042383,4.0491257 L166.077309,28.8903397" className={css.path17} />
            <path d="M33.3099472,80.367112 L48.5112106,100.914213" className={css.path16} />
            <path d="M33.4818144,80.8852785 L16.8568755,107.947939" className={css.path15} />
            <path d="M16.8568755,107.947939 L8.98689979,59.372387" className={css.path14} />
            <path d="M8.98689979,59.372387 L36.5933791,24.6795949" className={css.path13} />
            <path d="M36.5651621,24.6654864 L48.1854326,11.2483065" className={css.path12} />
            <path d="M132.079681,83.6056531 L98.8426294,83.6056531" className={css.path11} />
            <path d="M34.6662348,79.8273284 L74.6866122,84.9252234" className={css.path10} />
            <path d="M74.6866122,84.9252234 L98.6618303,84.9252234" className={css.path9} />
            <path d="M98.6618303,83.9773994 L98.6618303,51.0071055" className={css.path8} />
            <path d="M99.1192942,51.0071055 L101.551563,14.9178332" className={css.path7} />
            <path d="M101.551563,14.9178332 L132.989096,28.4731555" className={css.path6} />
            <path d="M132.989096,28.4731555 L157.18328,43.9128177" className={css.path5} />
            <path d="M157.18328,43.9128177 L125.800231,53.1463915" className={css.path4} />
            <path d="M125.964326,52.9688801 L131.528367,82.8845882" className={css.path3} />
            <path d="M131.528367,82.8845882 L106.51624,111.352675" className={css.path2} />
            <path d="M107.51624,120.033909 L86.9593,135.858898" className={css.path1} />
          </g>
        </g>
      </g>
    </svg>
    <div>{text}</div>
  </div>
);

BrainLoading.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object
};

BrainLoading.defaultProps = {
  text: ""
};

export default BrainLoading;
