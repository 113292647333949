import classNames from "classnames";

import css from "./hr.less";

type HrProps = {
  className?: string;
};

export default function Hr({ className }: HrProps) {
  return <hr className={classNames(css.hr, className)} />;
}
