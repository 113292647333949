import { CustomField, Metadata, Subtemplate } from "PFTypes";
import { Dispatch, SetStateAction, useCallback } from "react";

import { FormFieldValue } from "../../activity_edit_form.types";
import { useActivityEditPageContext } from "../../activity_edit_page_context";
import { useFieldValueChange } from "./use_field_value_change";
import { useVisiblePermittedProperties } from "./use_visible_permitted_properties";

type UseUpdateFieldValueProps = {
  setCustomFields: Dispatch<SetStateAction<CustomField[]>>;
  subtemplate: Subtemplate | null;
  setMetadata: Dispatch<SetStateAction<Metadata>>;
};

export const useUpdateCustomFieldValue = ({
  setMetadata,
  setCustomFields,
  subtemplate
}: UseUpdateFieldValueProps) => {
  const { template } = useActivityEditPageContext();

  const { getVisiblePermittedProperties } = useVisiblePermittedProperties();
  const visibleProperties = getVisiblePermittedProperties({ subtemplate, template });

  const changeFieldValue = useFieldValueChange({
    templateKey: template.key,
    setCustomFields,
    setMetadata
  });

  return useCallback(
    (fieldName: string, values: FormFieldValue) => {
      const property = visibleProperties.find(({ name }) => name === fieldName);

      if (!property) {
        return;
      }

      changeFieldValue(values, property);
    },
    [changeFieldValue, visibleProperties]
  );
};
