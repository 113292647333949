import { useQuery } from "@tanstack/react-query";
import {
  fetchActivitiesStatistics,
  FetchActivitiesStatisticsParams,
  FetchActivitiesStatisticsResponse
} from "PFCore/services/marketplace/fetch_activities_statistics";
import { QueryOptions } from "PFTypes/request_options";

import { ActivitiesCollection, activitiesKeys } from "../query_keys";

export const useActivitiesStatistics = (
  params: FetchActivitiesStatisticsParams,
  options?: QueryOptions<FetchActivitiesStatisticsResponse>
) =>
  useQuery<FetchActivitiesStatisticsResponse>(
    activitiesKeys[ActivitiesCollection.Statistics](params),
    () => fetchActivitiesStatistics(params),
    options
  );
