import { useEffect } from "react";

import { ModalKey, useActivityEditFormContext } from "../activity_edit_form_context";
import { useActivityEditFormSubmit } from "./use_activity_edit_form_submit";

export const useActivityEditFormAutoSubmit = () => {
  const { submitOptions, setSubmitOptions, isModalConfirmed } = useActivityEditFormContext();

  const submit = useActivityEditFormSubmit();

  useEffect(() => {
    if (
      isModalConfirmed[ModalKey.NoRequirements] ||
      isModalConfirmed[ModalKey.Misalignment] ||
      isModalConfirmed[ModalKey.QuestionsChanged]
    ) {
      submit(submitOptions);
      setSubmitOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalConfirmed.NO_REQUIREMENTS, isModalConfirmed.MISALIGNMENT, isModalConfirmed.QUESTIONS_CHANGED]);
};
