import axios, { AxiosInstance } from "axios";
import { ApiRoute } from "PFCore/utilities/routes";

let instance: AxiosInstance | null = null;

export const getInstance = () => {
  if (!instance) {
    instance = axios.create({
      baseURL: ApiRoute("/api/"),
      headers: {
        "Accept": null,
        "Ui-Version": PF.config.ui_version
      }
    });
  }

  return instance;
};
