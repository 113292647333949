import moment from "moment";
import { Note } from "PFComponents/notes/notes.types";
import { useState } from "react";

// TODO: [PROF-1218] Add notes - integration with the API part
export const useMockNotesData = (mockNotes: Note[]) => {
  const [notes, setNotes] = useState<Note[]>(mockNotes);

  const handleCreateNote = (authorName: string, authorId: number, content: string) => {
    const id = Math.round(Math.random() * 1000);
    const newNote: Note = {
      id,
      author: authorName,
      authorId,
      createdAt: moment().format(),
      content
    };
    setNotes([...notes, newNote]);
  };

  const handleDeleteNote = (id: number) => {
    const newNotes = notes.filter((note) => note.id !== id);
    setNotes(newNotes);
  };

  const handleEditNote = (id: number, content: string) => {
    const newNotes = notes.map((note) => {
      if (note.id === id) {
        return {
          ...note,
          content
        };
      }
      return note;
    });
    setNotes(newNotes);
  };

  return {
    notes,
    handleCreateNote,
    handleDeleteNote,
    handleEditNote
  };
};
