import { flatten, groupBy, sortBy, uniqBy, without } from "lodash";
import { getProfileName } from "PFCore/helpers/profile";
import { PermissionRule, Profile } from "PFTypes";

export const getOrderedActivityProfiles = (profiles: Profile[]): Profile[] => {
  const projectManagersPermissionsGroupsNames = uniqBy(
    profiles.map((profile) => profile.permissions_group),
    "name"
  )
    .filter(({ permissions }) =>
      permissions.find(({ name, permitted }) => name === PermissionRule.ProjectManager && permitted)
    )
    .map(({ display_as }) => display_as)
    .sort();

  const groupedByPermissionsGroup = groupBy(profiles, "permissions_group.display_as");
  const orderedGroupNames = projectManagersPermissionsGroupsNames.concat(
    without(Object.keys(groupedByPermissionsGroup), ...projectManagersPermissionsGroupsNames).sort()
  );

  return flatten(
    orderedGroupNames.map((groupName) =>
      sortBy(groupedByPermissionsGroup[groupName], (profile) => getProfileName(profile))
    )
  );
};
