import { useTranslation } from "react-i18next";

import { AttributeKey } from "../../../../../types/booking_history_item";

type UseBookingHistoryReturn = {
  getUserFriendlyAttributeName: (attributeKey: string) => string;
};

export const useBookingHistory = (): UseBookingHistoryReturn => {
  const { t } = useTranslation("bookingModule");

  const getUserFriendlyAttributeName = (attributeKey: AttributeKey) =>
    t(`details.history.attributes.${attributeKey}`, { defaultValue: attributeKey });

  return {
    getUserFriendlyAttributeName
  };
};
