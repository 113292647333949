import { decamelizeKeys } from "humps";
import api from "PFCore/api";
import { getRootDomain } from "PFCore/helpers/root_domain";
import { CurrentAccount, Id } from "PFTypes";

export const fetchAccount = (domain: Id) =>
  api({
    url: `accounts/${domain}`,
    headers: {
      Accept: "application/vnd.profinda+json;version=2"
    }
  });

export const fetchCurrentAccount = (): Promise<CurrentAccount> =>
  fetchAccount(getRootDomain()).then(
    (response) => decamelizeKeys(response) as CurrentAccount,
    ({ response }) => {
      throw response;
    }
  );

export const exportAccountConfig = (accountId: number) =>
  api({
    url: `accounts/${accountId}/export`
  });

export const importAccountConfig = (accountId: number, data): Promise<void> =>
  api({
    method: "POST",
    url: `accounts/${accountId}/import`,
    data
  });

export const fetchCvParserConfig = (accountId: Id) => api({ url: `accounts/${accountId}/cv_parser_config` });

export const updateCvParsingConfig = (accountId: Id, data) =>
  api({ url: `accounts/${accountId}/cv_parser_config`, method: "PUT", data });
