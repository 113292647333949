import ChatIcon from "PFIcons/chat.svg";

import css from "./chat_with_icon.module.scss";

const SVG_ICON_RATIO = 0.6;

const ChatWithIcon = ({ height = 11, width = 11 }) => (
  <div className={css.wrapper} style={{ height, width }}>
    <ChatIcon
      style={{
        fill: "#fff",
        height: height * SVG_ICON_RATIO,
        width: width * SVG_ICON_RATIO
      }}
    />
  </div>
);

export default ChatWithIcon;
