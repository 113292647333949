import { isNil } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityTemplate } from "PFApp/activities/show/hooks/use_activity_template";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { ParsedSchemaProperty } from "PFTypes";
import { useMemo } from "react";

export const useActivityPropertiesNames = (): string[] => {
  const { task } = useActivityPageContext();
  const template = useActivityTemplate();

  const { subtemplate_key: subtemplateKey } = task || {};

  const subtemplate = template?.schema?.subtemplates && template?.schema?.subtemplates[subtemplateKey!];
  const templateProperties = useMemo<ParsedSchemaProperty[]>(
    () => (template ? getVisibleProperties(template) : []),
    [template]
  );
  const hasAvailability = !!templateProperties?.find(({ name }) => name === "availability");

  const subtemplateProperties = useMemo(
    () =>
      subtemplate
        ? [...Object.keys(subtemplate.properties), ...(hasAvailability ? ["availability"] : [])]
        : [],
    [hasAvailability, subtemplate]
  );

  return useMemo<string[]>(() => {
    if (subtemplate) {
      return (
        templateProperties
          // If the prop is not in the subtemplate it is NOT activity filter
          .filter(({ name }) => subtemplateProperties.includes(name))
          // If the prop is marked as additional filter in the subtemplate is NOT an activity filter
          .filter(({ name, additional_filter: additionalFilter }) => {
            const subtemplateProperty = subtemplate.properties[name];
            return !isNil(subtemplateProperty?.additional_filter)
              ? !subtemplateProperty.additional_filter
              : !additionalFilter;
          })
          .map(({ name }) => name)
      );
    } else {
      return templateProperties
        .filter(({ additional_filter: additionalFilter }) => !additionalFilter)
        .map(({ name }) => name);
    }
  }, [subtemplate, subtemplateProperties, templateProperties]);
};
