import { cloneDeep, isEqual } from "lodash";
import { useTemplateConfiguration } from "PFApp/hooks";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { useActivityEditPageContext, useActivityEditPageFlags } from "../../activity_edit_page_context";
import {
  getInitialQuestionsToggleValue,
  getInitialQuestionValue,
  getQuestionsTileVisibility
} from "../../utils/activity_edit_form.utils";

export type Question = { id: number; value: string };

export type UseActivityEditFormQuestionsReturn = {
  areQuestionsModified: boolean;
  questionsToggleValue: boolean;
  setQuestionsToggleValue: Dispatch<SetStateAction<boolean>>;
  isAtLeastOneQuestion: boolean;
  showQuestionsTile: boolean;
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
};

export const useActivityEditFormQuestions = (): UseActivityEditFormQuestionsReturn => {
  const { isClone, template, activity } = useActivityEditPageContext();

  const { isEdit } = useActivityEditPageFlags();

  const templateConfig = useTemplateConfiguration(template);

  const initialQuestionsToggleValue = useMemo(
    () => getInitialQuestionsToggleValue(activity, templateConfig, isEdit, isClone),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [questionsToggleValue, setQuestionsToggleValue] = useState(initialQuestionsToggleValue);

  const initialQuestionsValues = useMemo(
    () =>
      [1, 2, 3].map((id) => ({
        id,
        value: getInitialQuestionValue(id, activity, templateConfig, isEdit, isClone)
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [questions, setQuestions] = useState<Question[]>(cloneDeep(initialQuestionsValues));

  const isAtLeastOneQuestion = useMemo(() => questions.some(({ value }) => value.trim() !== ""), [questions]);

  const showQuestionsTile = useMemo(
    () => getQuestionsTileVisibility(activity, templateConfig, isEdit, isClone, isAtLeastOneQuestion),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const areQuestionsModified = useMemo(
    () => questionsToggleValue !== initialQuestionsToggleValue || !isEqual(initialQuestionsValues, questions),
    [initialQuestionsToggleValue, initialQuestionsValues, questionsToggleValue, questions]
  );

  return useMemo(
    () => ({
      areQuestionsModified,
      questionsToggleValue,
      setQuestionsToggleValue,
      isAtLeastOneQuestion,
      showQuestionsTile,
      questions,
      setQuestions
    }),
    [areQuestionsModified, isAtLeastOneQuestion, questions, questionsToggleValue, showQuestionsTile]
  );
};
