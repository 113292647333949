import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  createCustomValue,
  CreateCustomValueData,
  CreateCustomValueResponse
} from "PFCore/services/custom_values";
import { MutationOptions } from "PFTypes/request_options";

import { ActivitiesCollection } from "../activities/query_keys";
import { useActivitiesInvalidate } from "../activities/use_activities_invalidate";
import { useProfileInvalidate } from "../profile/use_profile_invalidate";

export const useCustomValuesCreate = ({
  onSuccess,
  ...options
}: MutationOptions<CreateCustomValueData, CreateCustomValueResponse, AxiosError> = {}) => {
  const { invalidateCurrentProfile } = useProfileInvalidate();
  const { invalidateByCollection } = useActivitiesInvalidate();

  return useMutation<CreateCustomValueResponse, AxiosError, CreateCustomValueData>({
    mutationFn: ({ payload }) => createCustomValue(payload),
    onSuccess: (data, ...params) => {
      invalidateCurrentProfile();
      invalidateByCollection(ActivitiesCollection.MatchingActivityStatus);
      onSuccess?.(data, ...params);
    },
    ...options
  });
};
