import classNames from "classnames";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useBookingNoteRemove } from "PFCore/hooks/queries/bookings/notes/use_booking_note_remove";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { forwardRef, useState } from "react";

import type { BookingNote as BookingNoteType, IDeleteModalState } from "../types";
import css from "./booking_note.module.scss";
import { BookingNoteActions } from "./booking_note_actions";
import BookingNoteEdit from "./booking_note_edit";

type BookingNoteProps = {
  readonly: boolean;
  bookingId: number;
  note: BookingNoteType;
  openDeleteModal: (state: Omit<IDeleteModalState, "show">) => void;
};

export const BookingNote = forwardRef<HTMLDivElement, BookingNoteProps>(
  (
    {
      readonly,
      bookingId,
      note: { id, creator, body, updated_at: updatedAt, created_at: createdAt },
      openDeleteModal
    },
    ref
  ) => {
    const {
      data: { id: currentProfileId }
    } = useCurrentProfile();
    const { remove, isMutating: deleteLoading } = useBookingNoteRemove(bookingId, id);
    const [editMode, setEditMode] = useState<boolean>(false);
    const { formatDateTime, utc } = useDateFormatter();
    const dateFormatted = formatDateTime(utc(updatedAt || createdAt));
    const toggleEditMode = () => setEditMode((prev) => !prev);

    const onDeleteClick = () => {
      openDeleteModal({
        handleOK: remove
      });
    };

    const handleEditSuccess = () => {
      setEditMode(false);
    };

    const canPerformActions = !readonly && creator.id === currentProfileId;
    const authorName = getProfileName(creator);
    return (
      <div ref={ref} className={css.noteContainer}>
        <div className={css.header}>
          <div className={classNames(css.meta, { [css.metaMaxWidth]: canPerformActions })}>
            <Typography variant="bodyBold" tag="span">
              {authorName}
            </Typography>
            <Typography variant="bodyRegular" tag="span" className={css.date}>
              {dateFormatted}
            </Typography>
          </div>
          {canPerformActions && (
            <BookingNoteActions
              editMode={editMode}
              disabled={!!deleteLoading}
              onEditClick={toggleEditMode}
              onDeleteClick={onDeleteClick}
            />
          )}
        </div>
        {!editMode && <Typography variant="bodyRegular">{body}</Typography>}
        {editMode && (
          <BookingNoteEdit bookingId={bookingId} id={id} content={body} onSuccess={handleEditSuccess} />
        )}
      </div>
    );
  }
);

BookingNote.displayName = "BookingNote";
