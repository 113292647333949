import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

export type ActivityEditFormErrors = { interestQuestions?: string; roleForm?: string; post_until?: string };

export type UseActivityEditFormErrorsReturn = {
  errors: ActivityEditFormErrors;
  setErrors: Dispatch<SetStateAction<ActivityEditFormErrors>>;
  resetErrors: VoidFunction;
  removeErrorIfPresent: (fieldName: keyof ActivityEditFormErrors) => void;
};

export const useActivityEditFormErrors = () => {
  const [errors, setErrors] = useState<ActivityEditFormErrors>({});

  const resetErrors = useCallback(() => setErrors({}), []);

  const removeErrorIfPresent = useCallback(
    (fieldName) => {
      if (errors[fieldName]) {
        setErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[fieldName];
          return newErrors;
        });
      }
    },
    [errors]
  );

  return useMemo(
    () => ({
      errors,
      setErrors,
      resetErrors,
      removeErrorIfPresent
    }),
    [errors, removeErrorIfPresent, resetErrors]
  );
};
