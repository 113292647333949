import { pick } from "lodash";
import { ParsedSchemaProperty } from "PFTypes";

import { useActivityEditFormContext } from "../../activity_edit_form_context";

export const useFieldError = (property: ParsedSchemaProperty) => {
  const { errors } = useActivityEditFormContext();

  const keyBeginning = `metadata[${property.name}]`;
  const keys = Object.keys(errors).filter((key) => key.indexOf(keyBeginning) === 0);

  if (keys.length === 0) {
    return;
  }

  if (property.properties) {
    // nested e.g. availability
    return pick(errors, ...keys);
  }

  return errors[keys[0]];
};
