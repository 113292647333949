import { useParams } from "react-router-dom";

import { EconomicsList } from "./economics_list/economics_list";
import { ScenarioView } from "./scenario_view";

export const Economics = () => {
  const { params: scenarioId } = useParams<{ params: string }>();

  if (scenarioId) {
    return <ScenarioView scenarioId={scenarioId} />;
  }

  return <EconomicsList />;
};
