import { ApiRoute } from "PFCore/utilities/routes";

export const sendContactMessage = (message: string) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute("/api/contact_messages"),
    method: "POST",
    api_version: 2, // eslint-disable-line camelcase
    contentType: "application/json; charset=utf-8",
    data: JSON.stringify({
      message
    })
  });
