import useChat from "PFApp/use_chat";
import { usePusherEvent } from "PFCore/base/pusher";
import { gidFor, gidToType } from "PFCore/helpers/gid";
import useOnceWhen from "PFCore/helpers/use_once_when";
import { useChatConversations } from "PFCore/hooks/queries/chat/use_chat_conversations";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchParticipants } from "PFCore/services/chat/fetch_paricipants";
import { useCallback, useEffect, useMemo, useState } from "react";

const useActivityPageConversations = (taskId, taskOwnerId, isTaskPrivate) => {
  const { data: currentProfile } = useCurrentProfile();
  const { renderChat, unrenderChat } = useChat();

  const { data: { entries: conversations } = {}, refetch } = useChatConversations({
    target: gidFor("Task", taskId),
    perPage: 1000
  });

  const refetchChatConversations = useCallback(
    ({ resource_gid, target_id }) => {
      const type = gidToType(resource_gid);
      if (["Chat::Conversation", "Task"].includes(type) && parseInt(target_id) === parseInt(taskId)) {
        refetch();
      }
    },
    [refetch, taskId]
  );

  usePusherEvent(`private-profile-${currentProfile.id}`, "create", refetchChatConversations);
  usePusherEvent(`private-profile-${currentProfile.id}`, "change", refetchChatConversations);

  const primaryConversation = conversations?.find((conversation) => conversation.kind === "primary");
  const otherConversations = useMemo(
    () =>
      conversations?.filter(
        ({ id, created_at, last_activity_at }) =>
          id !== primaryConversation?.id && created_at !== last_activity_at // don't show the empty ones
      ),
    [conversations, primaryConversation]
  );

  useOnceWhen(
    !!primaryConversation,
    () => {
      if (taskOwnerId !== currentProfile.id) {
        const { id } = primaryConversation;
        renderChat(id, { minimized: true, expanded: true, auto: true });
      }
    },
    () => {
      primaryConversation && unrenderChat(primaryConversation.id, { auto: true });
    }
  );

  const [participantsOfConversations, setParticipantsOfConversations] = useState({});

  useEffect(() => {
    if (conversations) {
      const arrOfConversationParticipants = conversations.map((conversation) =>
        fetchParticipants({
          conversationId: conversation.id,
          withMessage: conversation.kind === "primary" && !isTaskPrivate // main is special (in ActivityDetailsMainChat). If is private we send the whole participant list
        })
      );

      Promise.all(arrOfConversationParticipants).then((conversationsParticipants) => {
        const participantsOfConversations = conversationsParticipants.reduce((acc, { entries }) => {
          const id = entries[0]?.conversation_id;
          return {
            ...acc,
            ...(id && { [id]: entries })
          };
        }, {});

        setParticipantsOfConversations(participantsOfConversations);
      });
    }
  }, [conversations, isTaskPrivate]);

  // all (most) of this file in a single useEffect?
  return {
    primaryConversation,
    otherConversations,
    participantsOfConversations
  };
};

export default useActivityPageConversations;
