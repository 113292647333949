import { useRef } from "react";

// From https://stackoverflow.com/a/56818036
const useComponentWillMount = (func) => {
  const willMount = useRef(true);
  if (willMount.current) {
    func();
  }
  willMount.current = false;
};

export default useComponentWillMount;
