import classNames from "classnames";
import colors from "PFTheme/tokens/colors";

import css from "./divider.module.scss";

type Color = keyof typeof colors;

type DividerProps = {
  className?: string;
  theme?: {
    divider?: string;
  };
  type?: "horizontal" | "vertical";
  color?: Color;
  size?: number;
};

export const Divider = ({
  className,
  theme,
  type = "horizontal",
  color = "paletteNeutral0",
  size
}: DividerProps) => {
  const colorStyle = color
    ? {
        borderColor: `var(--${color})`
      }
    : {};

  const sizeStyle = size
    ? { [type === "horizontal" ? "borderBottomWidth" : "borderRightWidth"]: `${size}px` }
    : {};
  const style = {
    ...colorStyle,
    ...sizeStyle
  };

  return (
    <div
      className={classNames(
        css.divider,
        theme?.divider,
        {
          [css.horizontal]: type === "horizontal",
          [css.vertical]: type === "vertical"
        },
        className
      )}
      style={style}
    />
  );
};
