export const MODALS_ACTIONS = {
  TERMS_SHOW: "TERMS_SHOW",
  TERMS_HIDE: "TERMS_HIDE"
};

export const modalReducer = (state, { type }) => {
  switch (type) {
    case MODALS_ACTIONS.TERMS_SHOW:
      return {
        ...state,
        termsShown: true
      };
    case MODALS_ACTIONS.TERMS_HIDE:
      return {
        ...state,
        termsShown: false
      };
    default:
      return state;
  }
};
