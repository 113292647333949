import { Typography } from "PFComponents/typography";
import {
  ProfilePositionHighlight,
  ProfilePositionHighlightAttribute,
  ProfileSearchHighlightAttribute
} from "PFTypes";
import React, { Fragment } from "react";

import { useAttributeName } from "../../hooks/use_attribute_name";
import { useGetPositionAttributeName } from "../../hooks/use_get_position_attribute_name";
import { SearchHighlightPlainRow } from "../search_highlight_plain_row";

type SearchHighlightProfilePositionsRowProps = {
  attribute: ProfileSearchHighlightAttribute;
  values: ProfilePositionHighlight;
};

export const SearchHighlightProfilePositionRow = ({
  attribute,
  values: positions
}: SearchHighlightProfilePositionsRowProps) => {
  const attributeName = useAttributeName(attribute);
  const getPositionAttributeName = useGetPositionAttributeName();

  return (
    <Fragment>
      <Typography variant="labelBold" noMargin>
        {attributeName}
      </Typography>
      <div /> {/* placeholder to keep grid consistent*/}
      {Object.entries(positions).map(([positionAttribute, positionValue]) => (
        <SearchHighlightPlainRow
          key={positionAttribute}
          attributeDisplayName={getPositionAttributeName(
            positionAttribute as ProfilePositionHighlightAttribute
          )}
          values={positionValue}
          indented
        />
      ))}
      <div />
      <div /> {/* additional separator */}
    </Fragment>
  );
};
