import { Button, ButtonProps } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import React from "react";

type CreateButtonProps = {
  label: string;
  isLoading: boolean;
  disabled: boolean;
} & Pick<ButtonProps, "kind">;

export const CreateButton = ({ label, isLoading, kind, disabled }: CreateButtonProps) => (
  <Button form="booking_form" type="submit" disabled={disabled} kind={kind}>
    {isLoading ? <LoadingDots /> : label}
  </Button>
);
