import css from "PFApp/profiles/edit/profile_edit_panel.less";
import { Button } from "PFComponents/button";
import { Card } from "PFComponents/card";
import CardHeader from "PFComponents/card/card_header";
import PropTypes from "prop-types";
import { Component } from "react";

export default class ProfileEditPanel extends Component {
  render() {
    const {
      title,
      children,
      headingButtonLabel,
      headingButtonDisabled,
      handleHeadingButtonClick,
      headingButtonQaId
    } = this.props;

    return (
      <Card>
        <CardHeader
          titleQaId="profile-edit-section-title"
          title={title}
          style={{ padding: 0, marginBottom: 16 }}
          action={
            headingButtonLabel && (
              <Button
                disabled={headingButtonDisabled}
                onClick={handleHeadingButtonClick}
                qaId={headingButtonQaId}
                small
              >
                {headingButtonLabel}
              </Button>
            )
          }
        />
        <div className={css.body}>{children}</div>
      </Card>
    );
  }
}

ProfileEditPanel.propTypes = {
  title: PropTypes.string.isRequired,
  headingButtonLabel: PropTypes.string,
  headingButtonDisabled: PropTypes.bool,
  headingButtonQaId: PropTypes.string,
  handleHeadingButtonClick: PropTypes.func,
  children: PropTypes.any,
  style: PropTypes.object
};

ProfileEditPanel.defaultProps = {
  headingButtonLabel: null,
  headingButtonDisabled: false,
  headingButtonQaId: null,
  handleHeadingButtonClick: null,
  children: null,
  style: null
};
