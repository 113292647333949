import { Cell, flexRender } from "@tanstack/react-table";
import classNames from "classnames";

import { TableData } from "./table";
import css from "./table.module.scss";

type TableCellProps<TABLE_DATA extends TableData> = {
  cell: Cell<TABLE_DATA, unknown>;
  compact?: boolean;
};

export const TableCell = <TABLE_DATA extends TableData>({
  cell,
  compact
}: TableCellProps<TABLE_DATA>): JSX.Element => (
  <td
    className={classNames({ [css.compactCell]: compact })}
    style={{
      width: cell.column.getSize() !== 0 ? cell.column.getSize() : undefined
    }}
  >
    {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </td>
);
