import { SimplePagination } from "PFComponents/pagination/simple_pagination";
import React from "react";

import { useActivityHistoryContext } from "./activity_history_context";
import { useActivityHistory } from "./hooks";

export const ActivityHistoryFooter = () => {
  const { page, setPage } = useActivityHistoryContext();
  const { data } = useActivityHistory();

  if (data && data?.meta.totalPages > 1) {
    return (
      <SimplePagination
        currentPage={page}
        handleClick={setPage}
        totalPages={data.meta.totalPages}
        scrollOnClick={false}
      />
    );
  }
  return null;
};
