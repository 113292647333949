import Tooltip from "PFComponents/tooltip/tooltip";
import { useTranslation } from "react-i18next";

import css from "./bulk_select_item.module.scss";
import { PROFILES_COUNT_LIMIT } from "./workforce_member_select.utils";

type BulkSelectItemProps = {
  count: number;
  disabled: boolean;
};

export const BulkSelectItem = ({ count, disabled }: BulkSelectItemProps) => {
  const { t } = useTranslation("bookingModule");

  const content = <div className={css.content}>{t("bookings.create.bulk.select", { count })}</div>;

  return disabled ? (
    <Tooltip content={t("bookings.create.bulk.tooManyProfiles", { count: PROFILES_COUNT_LIMIT })}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
