import { useMutation } from "@tanstack/react-query";
import { deleteOwner } from "PFCore/services/owners";
import { MutationOptions } from "PFTypes/request_options";

import { useActivityInvalidate } from "../activity/use_activity_invalidate";

export const useOwnerDelete = (
  activityId: number,
  { onSuccess, ...options }: MutationOptions<number> = {}
) => {
  const { invalidate: invalidateActivity } = useActivityInvalidate();

  return useMutation({
    mutationFn: (id: number) => deleteOwner(id),
    onSuccess: (...params) => {
      invalidateActivity([activityId]);
      onSuccess?.(...params);
    },
    ...options
  });
};
