import { decamelizeKeys } from "humps";
import api from "PFCore/api";
import { Collection } from "PFTypes";
import { ChangeRequest } from "PFTypes/change_request";

export type FetchChangeRequestsPayload = {
  profileId: number;
  diffOnly?: boolean;
  applied?: boolean;
  page?: number;
  perPage?: number;
};

type FetchChangeRequestsResponse = Collection<ChangeRequest[]>;

export const fetchChangeRequests = (params: FetchChangeRequestsPayload) =>
  api<FetchChangeRequestsResponse>({
    url: "change_requests",
    params
  }).then((resp) => decamelizeKeys(resp) as FetchChangeRequestsResponse); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized
