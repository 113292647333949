import { camelizeKeys } from "humps";
import { Divider } from "PFComponents/divider";
import React from "react";

import { SearchHighlights } from "../../../search_highlight";
import { useMatchCardContext } from "../../match_card_context";
import css from "./match_card_footer.module.scss";

export const MatchCardFooter = () => {
  const { optional_fields: optionalFields, animate } = useMatchCardContext();

  if (!optionalFields?.highlights) {
    return null;
  }

  return (
    <div className={css.footer}>
      <Divider />
      <SearchHighlights highlights={camelizeKeys(optionalFields.highlights)} onToggle={animate} />
    </div>
  );
};
