import { Id } from "PFTypes";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

export const useScenarioNavigate = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const openScenario = useCallback(
    (scenarioId: Id) => {
      history.replace(`/activities/${id}/economics/${scenarioId}`);
    },
    [history, id]
  );

  const closeScenario = useCallback(() => {
    history.replace(`/activities/${id}/economics`);
  }, [history, id]);

  return { openScenario, closeScenario };
};
