import classNames from "classnames";
import { Button } from "PFComponents/button/";
import { Icon } from "PFComponents/icon/icon";
import { useTranslation } from "react-i18next";

import css from "../description_generator.module.scss";

type NavigationProps = {
  descriptions: string[];
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const Navigation = ({ descriptions, selectedIndex, setSelectedIndex }: NavigationProps) => {
  const { t } = useTranslation("activities");

  if (!descriptions.length) {
    return null;
  }

  return (
    <div className={css.navigation}>
      <Button
        className={classNames({ [css.disabled]: selectedIndex === 0 })}
        kind="blank"
        disabled={selectedIndex === 0}
        onClick={() => setSelectedIndex((index) => Math.max(index - 1, 0))}
      >
        <Icon name="chevron-left" size="sm" />
      </Button>
      {t("edit.sections.descriptionGenerate.pagination", {
        current: selectedIndex + 1,
        total: descriptions.length
      })}
      <Button
        className={classNames({ [css.disabled]: selectedIndex === descriptions.length - 1 })}
        kind="blank"
        style={{ marginLeft: 0 }}
        disabled={selectedIndex === descriptions.length - 1}
        onClick={() => setSelectedIndex((index) => Math.min(index + 1, descriptions.length - 1))}
      >
        <Icon name="chevron-right" size="sm" />
      </Button>
    </div>
  );
};

Navigation.displayName = "DescriptionGeneratorNavigation";
