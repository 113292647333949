export enum CalendarView {
  WallPlanner = "wallPlanner",
  BookingsOnly = "bookingsOnly",
  Bookings = "bookings",
  Compressed = "compressed",
  EngagementsAndAuditEngagements = "engagementsAndAuditEngagements",
  RolesAndAuditRoles = "rolesAndAuditRoles"
}

export enum CalendarMetric {
  Utilization = "utilization",
  Availability = "availability",
  TotalHours = "totalHours"
}
