import { useMemo } from "react";
import { useTranslation } from "react-i18next";

enum Frequency {
  instantly = "instantly",
  daily = "daily",
  weekly = "weekly",
  fortnightly = "fortnightly",
  monthly = "monthly",
  never = "never",
  dontReceive = "dontReceive"
}

export type FrequencyData = {
  label: string;
  value: Frequency;
};

const useNotificationsSettings = (): Record<Frequency, FrequencyData> => {
  const { t } = useTranslation("profiles", { keyPrefix: "edit.sections.notifications.settingsOptions" });
  return useMemo(
    () => ({
      daily: {
        label: t("daily"),
        value: Frequency.daily
      },
      weekly: {
        label: t("weekly"),
        value: Frequency.weekly
      },
      never: {
        label: t("never"),
        value: Frequency.never
      },
      dontReceive: {
        label: t("dontReceive"),
        value: Frequency.never
      },
      fortnightly: {
        label: t("fortnightly"),
        value: Frequency.fortnightly
      },
      monthly: {
        label: t("monthly"),
        value: Frequency.monthly
      },
      instantly: {
        label: t("receiveImmediately"),
        value: Frequency.instantly
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export default useNotificationsSettings;
