import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cloneActivityEconomicsScenario } from "PFCore/services/activity/activity_economics_scenario_clone";
import { EconomicsScenario, Id, MutationOptions } from "PFTypes";

import activityKeys from "./query_keys";

export const useActivityEconomicsScenarioClone = (
  activityId: Id,
  options?: MutationOptions<Id, EconomicsScenario>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...queryOptions } = options || {};

  return useMutation<EconomicsScenario, unknown, Id>({
    mutationFn: cloneActivityEconomicsScenario,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(activityKeys.economics(activityId));
      onSuccess?.(...args);
    },
    ...queryOptions
  });
};
