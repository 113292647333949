import Pill from "PFComponents/pill/pill";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import css from "./item_wrapper.module.scss";

type ItemViewProps = {
  headLine?: string | ReactNode;
  details?: string;
  isCurrent?: boolean;
  isMain?: boolean;
  collapsible?: boolean;
  display?: boolean;
  onClick?: VoidFunction;
  children?: ReactNode;
};

export const ItemWrapper = ({
  headLine,
  details,
  isCurrent = false,
  isMain = false,
  collapsible = false,
  display = false,
  onClick,
  children
}: ItemViewProps): JSX.Element => {
  const { t } = useTranslation("profiles");

  return (
    <li className={css.item} data-qa-id={`profile-edit-positions-list-row`}>
      {collapsible && (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <a
          className={css.link}
          data-qa-id={`profile-edit-positions-edit`}
          onClick={() => onClick && onClick()}
        >
          <div className={css.detailsWrapper}>
            {headLine}
            {details && <small className={css.details}>{details}</small>}
          </div>
          {isMain && <Pill className={css.main}>{t("edit.shared.main")}</Pill>}
          {!isMain && isCurrent && <Pill className={css.current}>{t("edit.shared.current")}</Pill>}
        </a>
      )}

      {(display || !collapsible) && children}
    </li>
  );
};
