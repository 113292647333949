export type Booking = { id: number } & Record<string, unknown>;

const ROOT_KEY = "bookingsFromAvailability";

export const bookingsFromAvailabilityKeys = {
  all: () => [ROOT_KEY],
  byActivity: (activityId?: number) => [...bookingsFromAvailabilityKeys.all(), activityId],
  byActivityAndProfile: (activityId?: number, profileId?: number) => [
    ...bookingsFromAvailabilityKeys.byActivity(activityId),
    profileId
  ]
};
