import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Select } from "PFComponents/select/select";

import { DoubleSectionPill, DoubleSectionPillProps } from "../double_section_pill";
import css from "./double_section_select_pill.module.scss";

type DoubleSectionSelectPillProps = Omit<DoubleSectionPillProps, "rightContent"> & {
  attribute: JSX.Element | string;
  attributeOptions: DropdownOption[];
  onAttributeChange?: (newAttribute: DropdownOption["item"]) => void;
};

export const DoubleSectionSelectPill = ({
  attribute,
  attributeOptions,
  onAttributeChange,
  ...rest
}: DoubleSectionSelectPillProps) => (
  <DoubleSectionPill
    {...rest}
    rightContent={
      <span className={css.selectWrapper}>
        <Select
          readOnly
          controlledValue
          options={attributeOptions}
          value={attribute}
          onChange={onAttributeChange}
          className={css.customSelect}
        />
      </span>
    }
  />
);
