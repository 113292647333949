import { Id } from "PFTypes";
import { createContext, useContext } from "react";

export type OverbookedBooking = {
  activityId?: Id;
  profileId: number;
  startDate: string;
  endDate: string;
};

export type OverbookingsManagementState = {
  overbookedBookings: OverbookedBooking[];
  activityId: number | undefined;
  profileId: number | undefined;
};

export type OverbookingsManagementApi = {
  setOverbookedBookings: (
    profileId: number | undefined,
    activityId: number | undefined,
    overbookedBookings: OverbookedBooking[]
  ) => void;
  clearOverbookedBookings: () => void;
};

export const INITIAL_OVERBOOKINGS_MANAGEMENT_STATE: OverbookingsManagementState = {
  overbookedBookings: [],
  activityId: undefined,
  profileId: undefined
};

const INITIAL_OVERBOOKINGS_MANAGEMENT_API: OverbookingsManagementApi = {
  setOverbookedBookings: () => {},
  clearOverbookedBookings: () => {}
};

export const OverbookingsManagementStateContext = createContext(INITIAL_OVERBOOKINGS_MANAGEMENT_STATE);
export const OverbookingsManagementApiContext = createContext(INITIAL_OVERBOOKINGS_MANAGEMENT_API);

export const useOverbookingsManagementStateContext = () => useContext(OverbookingsManagementStateContext);
export const useOverbookingsManagementApiContext = () => useContext(OverbookingsManagementApiContext);
