import { useAccordion } from "PFComponents/accordion/use_accordion";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./warning_accordion.module.scss";

type WarningAccordionProps = {
  header: React.ReactNode;
  subHeader: React.ReactNode;
  onShowClick?: VoidFunction;
};

export const WarningAccordion = ({
  onShowClick,
  children,
  header,
  subHeader
}: React.PropsWithChildren<WarningAccordionProps>) => {
  const { handleStateChange, isOpen, contentProps } = useAccordion({
    defaultState: "closed"
  });
  const { t } = useTranslation("bookingModule", { keyPrefix: "details.warnings" });

  return (
    <div className={css.root}>
      <div className={css.header}>
        <span className={css.headerContent}>
          <span className={css.center}>
            <ActionIcon
              name={isOpen ? "chevron-down" : "chevron-right"}
              onClick={handleStateChange}
              title={t(isOpen ? "collapseTooltip" : "showTooltip")}
              size="sm"
            />
            {header}
          </span>
          {!!onShowClick && (
            <Button kind="text" small onClick={onShowClick}>
              {t("show")}
            </Button>
          )}
        </span>
        <div>{subHeader}</div>
      </div>
      <div {...contentProps}>{children}</div>
    </div>
  );
};
