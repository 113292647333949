import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from "react";

import css from "./animated_div.module.scss";

type AnimatedDivProps = {
  trigger?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const AnimatedDiv = ({
  children,
  trigger,
  className,
  ...divProps
}: PropsWithChildren<AnimatedDivProps>): JSX.Element => {
  const [currentHeight, setCurrentHeight] = useState(0);
  const ref = useRef<HTMLDivElement>();

  const handleHeight = (node: HTMLDivElement) => {
    setCurrentHeight(node?.clientHeight || 0);
    ref.current = node;
  };

  useEffect(() => {
    setCurrentHeight(ref?.current?.clientHeight || 0);
  }, [trigger]);

  return (
    <div className={css.container}>
      <div
        style={{
          maxHeight: currentHeight,
          minHeight: currentHeight
        }}
        className={classNames(css.root, className)}
        {...divProps}
      >
        <div ref={handleHeight}>{children}</div>
      </div>
    </div>
  );
};
