import { LoadingDots } from "PFComponents/loading_dots";
import { useBookingHistoryInfinite } from "PFCore/hooks/queries/bookings/history/use_booking_history";
import { useInfiniteScroll } from "PFCore/hooks/use_infinite_scroll";
import { useTranslation } from "react-i18next";

import BookingHistoryItem from "./booking_history_item";
import css from "./booking_history_item.module.scss";

interface BookingHistoryProps {
  bookingId: number;
}

export const BookingHistory = ({ bookingId }: BookingHistoryProps) => {
  const { t } = useTranslation("bookingModule");

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: loading
  } = useBookingHistoryInfinite(bookingId, 15, {
    enabled: !!bookingId
  });

  const totalCount = data?.pages?.[0]?.meta?.total;
  const bookingHistory = data?.pages?.reduce((acc, current) => [...acc, ...current.entries], []) || [];

  const lastItemRef = useInfiniteScroll<HTMLDivElement>({
    fetchMore: fetchNextPage,
    hasMore: hasNextPage,
    loading
  });

  return (
    <>
      {loading && <LoadingDots circlesEnabled circleSize="xs" centered />}
      {totalCount === 0 && !loading && <p>{t("details.history.noHistory")}</p>}
      <div className={css.historyContainer}>
        {bookingHistory.map((item, i) => (
          <BookingHistoryItem
            key={item.id}
            ref={i === bookingHistory.length - 1 ? lastItemRef : null}
            item={item}
          />
        ))}
      </div>
    </>
  );
};
