import CertificateSvg from "PFIcons/certificate.svg";

import css from "./certificates.module.scss";

const CertificatesIcon = ({ count }: { count: number }) => (
  <div className={css.icon}>
    <div className={css.count}>{count}</div>
    <CertificateSvg height={25} width={25} />
  </div>
);

export default CertificatesIcon;
