import { toLower } from "lodash";
import { getWeekdays } from "PFCore/helpers/date";
import { AccountAvailability } from "PFTypes";
import { AvailabilityMode, AvailabilityRule } from "PFTypes/booking";

const getDefaultStandardAvailability = (defaultAvailabilityThreshold: number): AvailabilityRule => ({
  ranges: [
    {
      start: null,
      end: null,
      duration: {
        mode: "days",
        value: 1
      }
    }
  ],
  availability_threshold: defaultAvailabilityThreshold,
  mode: AvailabilityMode.Within
});

const getDefaultTimeRuleAvailability = (
  availability: AccountAvailability,
  defaultAvailabilityThreshold: number
): AvailabilityRule => {
  const calendar = availability?.default_calendar;

  const range = {
    start: null,
    end: null,
    day_of_week: getWeekdays()
      .map(toLower)
      .map((day) => calendar?.nominal_days?.find(({ day_name }) => day_name === day)?.working_minutes || 0)
  };

  return {
    mode: AvailabilityMode.TimeRule,
    availability_threshold: defaultAvailabilityThreshold,
    ranges: [range]
  };
};

export const getDefaultAvailabilityRule = (
  isTimeRule: boolean,
  availability: AccountAvailability,
  defaultAvailabilityThreshold: number
): AvailabilityRule => {
  if (isTimeRule) {
    return getDefaultTimeRuleAvailability(availability, defaultAvailabilityThreshold);
  }

  return getDefaultStandardAvailability(defaultAvailabilityThreshold);
};
