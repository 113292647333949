import { Id } from "PFTypes";

export const adminKeys = {
  all: () => ["admin"],
  user: (id: string) => [...adminKeys.all(), "user", id],
  permissionsGroups: () => [...adminKeys.all(), "permissionsGroups"],
  permissionsGroup: (id) => [...adminKeys.permissionsGroups(), id],
  permissionsGroupsPage: (params) => [...adminKeys.permissionsGroups(), params],
  dataImport: (accountId: Id, id: Id) => [...adminKeys.all(), "dataImport", accountId, id]
};
