import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useTranslation } from "react-i18next";

import css from "./side_panel.module.scss";

type LastUpdatedSectionProps = {
  updatedAt: string | undefined;
};

export const LastUpdatedSection = ({ updatedAt }: LastUpdatedSectionProps): JSX.Element => {
  const { t } = useTranslation("profiles");
  const { formatDate } = useDateFormatter();

  const prepareLastUpdatedText = () => {
    if (!updatedAt) {
      return "";
    }
    const formattedUpdatedAtDate = formatDate(updatedAt);
    return formattedUpdatedAtDate === formatDate(new Date())
      ? t("show.parts.lastUpdatedToday")
      : t("show.parts.lastUpdatedDate", { date: formattedUpdatedAtDate });
  };

  return <div className={css.updateAt}>{prepareLastUpdatedText()}</div>;
};
