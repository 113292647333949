import { InputFieldSet } from "PFComponents/text/input_field_set";
import Toggle from "PFComponents/toggle/toggle";
import { ChangeRequest } from "PFTypes";

import css from "../change_request_page.module.scss";
import { DisabledItems, MergeData, SetDisabledItems } from "../change_request_page.types";

type InputProps = {
  data: ChangeRequest["data"];
  prop: "first_name" | "last_name" | "summary";
  mergeData: MergeData;
  disabledItems: DisabledItems;
  setDisabledItems: SetDisabledItems;
  label: string;
  inputType?: string;
  error: string;
};

export const Input = ({ data, prop, mergeData, disabledItems, setDisabledItems, ...rest }: InputProps) => {
  const isVisible = !!data[prop] || data[prop] === "";

  if (!isVisible) {
    return null;
  }

  return (
    <div className={css.inputWrapper}>
      <InputFieldSet
        disabled={disabledItems[prop]}
        onChange={(val) => mergeData({ [prop]: val })}
        value={data[prop] ?? ""}
        qaId={`ChangeRequest.About.${prop}`}
        className={css.input}
        {...rest}
      />
      <Toggle
        checked={!disabledItems[prop]}
        onChange={(val) => setDisabledItems((prev) => ({ ...prev, [prop]: !val }))}
      />
    </div>
  );
};
