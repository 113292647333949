import { Button } from "PFComponents/button";
import { SkillsFramework } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./skills_framework_button.module.scss";

type SkillsFrameworksButtonProps = {
  onAdd: () => void;
  onRemove: () => void;
  skillsFrameworks: SkillsFramework[];
  disabled?: boolean;
};

export const SkillsFrameworksButton = ({
  onAdd,
  onRemove,
  skillsFrameworks,
  disabled
}: SkillsFrameworksButtonProps) => {
  const { t } = useTranslation("skillsFrameworks");
  const hasSkillsFrameworks = skillsFrameworks.length > 0;

  return (
    <Button
      icon={hasSkillsFrameworks ? "cross" : "add"}
      className={css.button}
      text={
        hasSkillsFrameworks
          ? skillsFrameworks.map(({ name }) => name).join("\n")
          : t("selectModal.skillsFramework")
      }
      kind="secondary"
      small
      onClick={hasSkillsFrameworks ? onRemove : onAdd}
      disabled={disabled}
    />
  );
};
