import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./activity_details_common.scss";

const ActivityAudience = ({ activity }) => {
  const { t } = useTranslation("activities");
  const { customTypes } = useCustomTypes();

  const broadcastTypes = customTypes.filter((ct) =>
    ct.sections.find((section) => section.key === "broadcast")
  );
  return (
    <div data-qa-id="ActivityDetailsAudience" className={css.infoMore}>
      <div className={css.infoItem}>
        <strong>{t("translation:privacy")}</strong>
        <div>{activity.private ? t("translation:private") : t("translation:public")}</div>
        <div className={css.annotation}>
          {activity?.private ? t("show.details.privacyPrivateHint") : t("show.details.privacyPublicHint")}
        </div>
        {activity?.private &&
          activity?.audiences?.map((audience, index) => (
            <Audience key={index} audience={audience} broadcastTypes={broadcastTypes} />
          ))}
      </div>
    </div>
  );
};

ActivityAudience.propTypes = {
  activity: PropTypes.object
};

export default ActivityAudience;

const Audience = ({ audience, broadcastTypes }) => (
  <div className={css.broadcast}>
    {Object.keys(audience).map((typeName) => {
      const type = broadcastTypes.find((t) => t.name === typeName);

      return (
        <div key={typeName}>
          <b>{type?.display_as || typeName}: </b>
          <span>{audience[typeName].map(({ text }) => text).join(", ")}</span>
        </div>
      );
    })}
  </div>
);

Audience.propTypes = {
  audience: PropTypes.object,
  broadcastTypes: PropTypes.array
};
