import Checkbox from "PFComponents/checkbox/checkbox";
import PropTypes from "prop-types";

const ToggleCheckboxItem = ({ filter, handleChange, disabled }) => (
  <div style={{ marginLeft: -6, marginTop: 2 }}>
    <Checkbox
      style={{ paddingLeft: 5 }}
      label={filter.title}
      checked={filter.value?.toString() === "true"}
      handleChange={(checked) => handleChange(checked ? checked : null)}
      disabled={disabled}
    />
  </div>
);

ToggleCheckboxItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }).isRequired,
  disabled: PropTypes.bool
};

export default ToggleCheckboxItem;
