import { Button } from "PFComponents/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AddReviewersModal from "./add_reviewers_modal";

export const AddReviewersButton = () => {
  const [showAddReviewers, setShowAddReviewers] = useState(false);
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.review" });

  return (
    <>
      <Button
        text={t("addReviewer")}
        kind="primary"
        style={{ minWidth: 140 }}
        qaId="activity-add-reviewer"
        onClick={() => setShowAddReviewers(true)}
      />
      {showAddReviewers && <AddReviewersModal handleClose={() => setShowAddReviewers(false)} />}
    </>
  );
};
