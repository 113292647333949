import { Camelized } from "humps";
import { CreateBookingPayload, UpdateBookingsPayload } from "PFApp/booking/services/api";
import { useBookingsUpdateMany } from "PFCore/hooks/queries";
import { useBookingCreate } from "PFCore/hooks/queries/bookings/use_booking_create";
import { useBookingDelete } from "PFCore/hooks/queries/bookings/use_booking_delete";
import { OverbookingsManagementMode } from "PFCore/services/bookings_suggested_changes";
import { Booking } from "PFTypes";

import { UpdatedBookingChange } from "../overbookings_management_modal/overbookings_management_modal";
import { applySuggestedChanges } from "../overbookings_management_modal/overbookings_management_modal.utils";
import { BookingFormValues } from "./booking_form_provider";
import { UseHandleSubmit } from "./use_handle_submit/use_handle_submit";

type UseApplySuggestedChanges = {
  mode: OverbookingsManagementMode | null;
  handleSubmit: (values: BookingFormValues) => Promise<void>;
} & Pick<UseHandleSubmit, "formMethods">;

type UseApplySuggestedChangesReturn = {
  handleApplySuggestedChanges: (suggestedChanges: UpdatedBookingChange[]) => Promise<void>;
};

export const useApplySuggestedChanges = ({
  mode,
  handleSubmit,
  formMethods
}: UseApplySuggestedChanges): UseApplySuggestedChangesReturn => {
  const { update: updateBooking } = useBookingsUpdateMany();
  const { create: createBooking } = useBookingCreate();
  const { delete: deleteBooking } = useBookingDelete();

  const handleApplySuggestedChanges = async (suggestedChanges: UpdatedBookingChange[]) => {
    if (!mode) {
      return;
    }

    const { bookingFormValues, bookingsToUpdate, bookingsToCreate, bookingsToDelete } = applySuggestedChanges(
      mode,
      formMethods.getValues(),
      suggestedChanges
    );

    if (mode === OverbookingsManagementMode.AdjustOther) {
      bookingsToCreate.forEach((bookingToCreate: Camelized<Booking>) => {
        const payload: CreateBookingPayload = {
          profileId: bookingToCreate.profileId,
          activityId: bookingToCreate.activityId ?? undefined,
          sourceType: "ui",
          bookings: [
            {
              startDate: bookingToCreate.startDate,
              endDate: bookingToCreate.endDate,
              phaseSourceId: bookingToCreate.phaseSourceId,
              requirementType: bookingToCreate.requirementType,
              requirementValue: bookingToCreate.requirementValue,
              bookingCategoryId: bookingToCreate.bookingCategoryId,
              title: bookingToCreate.title,
              description: bookingToCreate.description,
              overridesCalendar: bookingToCreate.overridesCalendar,
              overridesNonWorkingDays: bookingToCreate.overridesNonWorkingDays
            }
          ]
        };
        createBooking(payload);
      });

      bookingsToUpdate.forEach((bookingToUpdate: Camelized<Booking>) => {
        const payload: UpdateBookingsPayload = {
          profileId: bookingToUpdate.profileId,
          activityId: bookingToUpdate.activityId ?? undefined,
          bookings: [
            {
              id: bookingToUpdate.id,
              startDate: bookingToUpdate.startDate,
              endDate: bookingToUpdate.endDate,
              requirementValue: bookingToUpdate.requirementValue
            }
          ]
        };
        updateBooking(payload);
      });

      bookingsToDelete.forEach((bookingToDelete: Camelized<Booking>) => {
        deleteBooking({
          id: bookingToDelete.id,
          profile_id: bookingToDelete.profileId,
          activity_id: bookingToDelete.activityId ?? undefined
        });
      });
    }

    await handleSubmit(bookingFormValues);
  };

  return {
    handleApplySuggestedChanges
  };
};
