import { useQuery } from "@tanstack/react-query";
import { fetchAuditRoles, FetchRolesParams, FetchRolesResponse } from "PFCore/services/roles";
import { QueryOptions } from "PFTypes/request_options";

import { auditRolesKeys } from "./query_keys";

export const useAuditRoles = (params: FetchRolesParams, options: QueryOptions<FetchRolesResponse> = {}) =>
  useQuery<FetchRolesResponse>({
    queryKey: auditRolesKeys.list(params),
    queryFn: () => fetchAuditRoles(params),
    ...options
  });
