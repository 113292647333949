import { useActivityUpdatedHandler } from "PFApp/booking/hooks/use_activity_updated_handler";
import { useProfileAvailabilityUpdatedHandler } from "PFApp/booking/hooks/use_profile_availability_updated_handler";
import { useGrowl } from "PFApp/use_growl";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile/use_profile_invalidate";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import useDebounce from "../../helpers/use_debounce";
import { usePusherEvent } from "./use_pusher_event";

export const useGlobalEvents = () => {
  const { t } = useTranslation();
  const { data: currentProfile, isSignedIn } = useCurrentProfile();
  const { invalidateCurrentProfile } = useProfileInvalidate();
  const { data: currentAccount } = useCurrentAccount();

  const growl = useGrowl();
  const handleBookingModuleProfileAvailabilityUpdated = useProfileAvailabilityUpdatedHandler();
  const handleBookingModuleActivityUpdated = useActivityUpdatedHandler();

  const refetchUser = useDebounce(() => invalidateCurrentProfile(), 1000);

  const updateCurrentProfile = useCallback((data) => {
    if (data["object_name"] === "profile") {
      refetchUser();
    }
  }, []);

  const growlPopup = useCallback(
    (data) => {
      growl({ message: t(data.message, data) });
    },
    [growl, t]
  );

  const currentProfileId = currentProfile?.id;
  usePusherEvent(`private-profile-${currentProfileId}`, "refresh", updateCurrentProfile, isSignedIn);
  usePusherEvent(`private-profile-${currentProfileId}`, "popup", growlPopup, isSignedIn);
  usePusherEvent(
    `private-account-${currentAccount.id}`,
    "profile_availability_updated",
    handleBookingModuleProfileAvailabilityUpdated
  );
  usePusherEvent(
    `private-account-${currentAccount.id}`,
    "activity_updated",
    handleBookingModuleActivityUpdated
  );
  usePusherEvent(
    `private-account-${currentAccount.id}`,
    "activity_bookings_updated",
    handleBookingModuleActivityUpdated
  );
};
