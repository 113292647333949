import { ActivityFeedbackCreateData } from "PFTypes/activity_feedback";

import { FeedbackFormData } from "../form/feedback_form_context_provider";

export const useFeedbackCreateBody =
  () =>
  (
    data: FeedbackFormData,
    roleId: number,
    activityId: number,
    participantId: number
  ): ActivityFeedbackCreateData => ({
    skills: data.skills.map((skill) => ({ ...skill, value: skill.name })),
    activityId,
    roleId,
    recipientId: participantId,
    feedbackTemplateId: null
  });
