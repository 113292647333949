import {
  ActivityEvent,
  ActivityMember,
  ActivityMemberEvent,
  ActivityMembers,
  ActivityShareEvent,
  EventAction,
  EventActions,
  EventShortlistAction,
  EventShortlistActions
} from "PFTypes";

export const isActivityMemberEvent = (entry: ActivityEvent): entry is ActivityMemberEvent => {
  const [prefix, suffix] = entry.event.split("_");

  return (
    (ActivityMembers.includes(prefix as ActivityMember) && EventActions.includes(suffix as EventAction)) ||
    (EventShortlistActions.includes(suffix as EventShortlistAction) && prefix === "shortlist")
  );
};

export const isActivityShareEvent = (entry: ActivityEvent): entry is ActivityShareEvent =>
  entry.event === "activity_shared" || entry.event === "help_requested";
