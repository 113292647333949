import { useQueryClient } from "@tanstack/react-query";

import { noteKeys } from "./query_keys";

export const useBookingNoteInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (bookingId) => queryClient.invalidateQueries(noteKeys.ofBooking(bookingId))
  };
};
