import { Activity } from "PFTypes";
import { useCallback } from "react";

import { FormFieldData } from "../../activity_edit_form.types";
import { useActivityEditFormContext } from "../../activity_edit_form_context";
import { useFieldsAutoPopulation } from "../../activity_edit_form_context/hooks/use_fields_auto_population";
import { useGetCustomFieldsToLink } from "../use_get_custom_fields_to_link";
import { useUpdateActivityCustomFieldValue } from "../use_update_activity_custom_field_value";

type LinkAllCustomFieldsAttributes = {
  engagement?: Activity;
  skipOtherSources: boolean;
};

export const useLinkAllCustomFields = () => {
  const getCustomFieldsToLink = useGetCustomFieldsToLink();
  const {
    setCustomFields,
    setMetadata,
    subtemplate,
    selectedParentActivity,
    cachedFrameworksValues,
    cachedSubtemplateValues
  } = useActivityEditFormContext();

  const updateField = useUpdateActivityCustomFieldValue();
  const { populateFromAllSources } = useFieldsAutoPopulation({
    setCustomFields,
    setMetadata,
    subtemplate,
    cachedFrameworksValues,
    cachedSubtemplateValues,
    selectedParentActivity
  });

  return useCallback(
    ({ engagement, skipOtherSources = false }: LinkAllCustomFieldsAttributes) => {
      const parentActivity = engagement || selectedParentActivity;

      if (!parentActivity) {
        return;
      }

      const customFieldsToFill = getCustomFieldsToLink(parentActivity);

      if (skipOtherSources) {
        customFieldsToFill.forEach((customField) => {
          updateField(customField.type.name, customField.values);
        });
      } else {
        const engagementDataToPopulate = customFieldsToFill.map<FormFieldData>(({ type, values }) => ({
          values,
          fieldName: type.name
        }));
        populateFromAllSources({
          sourceValues: engagementDataToPopulate,
          source: "engagement"
        });
      }
    },
    [selectedParentActivity, getCustomFieldsToLink, updateField, populateFromAllSources]
  );
};
