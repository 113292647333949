import classNames from "classnames";
import { customTypesAccessLevelsAgainstProfile } from "PFCore/helpers/custom_types";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { useTargets } from "../../common/use_targets";
import { ExpertiseField } from "./expertise_field";
import InterestsSection from "./interests_section";
import css from "./section_additional.module.scss";

const AdditionalSection = ({
  profile,
  me,
  currentProfile,
  handleProfileUpdate,
  isPendingUpdate,
  editMode,
  interestsCollection,
  setEditMode,
  experienceCustomTypes,
  initSection,
  className
}) => {
  const { recalcTargets, updateHighlight } = useTargets({
    me,
    profile,
    initSection
  });

  const allowedExperienceCustomTypes = useMemo(() => {
    const accessLevels = customTypesAccessLevelsAgainstProfile(profile, currentProfile);

    return experienceCustomTypes.filter((item) => ["rw", "ro"].includes(accessLevels[item.id]));
  }, [currentProfile, profile, experienceCustomTypes]);

  const showInterests = interestsCollection?.isLoading || interestsCollection?.entries.length > 0;

  return (
    <div className={classNames(css.additional, className)}>
      {allowedExperienceCustomTypes?.map((customType) => (
        <ExpertiseField
          className={css.expertiseField}
          key={customType?.name}
          customType={customType}
          profile={profile}
          isMe={me}
          recalcTargets={recalcTargets}
          onProfileUpdate={handleProfileUpdate}
          isEditMode={editMode[`cf_${customType?.name}`]}
          onEditModeChange={() => setEditMode(`cf_${customType?.name}`, !editMode[`cf_${customType?.name}`])}
          isLoading={isPendingUpdate}
        />
      ))}
      {showInterests && (
        <InterestsSection
          className={css.expertiseField}
          updateHighlight={updateHighlight}
          interestsCollection={interestsCollection}
        />
      )}
    </div>
  );
};

AdditionalSection.propTypes = {
  className: PropTypes.string,
  me: PropTypes.bool.isRequired,
  isPendingUpdate: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  currentProfile: PropTypes.object.isRequired,
  experienceCustomTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  interestsCollection: PropTypes.shape({
    entries: PropTypes.array,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    setCurrentPage: PropTypes.func,
    isLoading: PropTypes.bool
  }),
  initSection: PropTypes.string,
  handleProfileUpdate: PropTypes.func.isRequired,
  editMode: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired
};
export default AdditionalSection;
