import { useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import { fetchBookingHistory } from "PFApp/booking/services/api";
import { IBookingHistoryItem } from "PFApp/booking/types";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";

import { historyKeys } from "./query_keys";

export type IBookingHistoryResponse = {
  entries: IBookingHistoryItem[];
  meta: {
    total: number;
    totalPages: number;
    page: number;
  };
};

export const useBookingHistoryInfinite = (
  bookingId: number,
  perPage?: number,
  options: Omit<UseInfiniteQueryOptions<IBookingHistoryResponse>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  } = {}
) =>
  useInfiniteQuery({
    queryKey: historyKeys.ofBooking(bookingId),
    queryFn: ({ pageParam = 1 }) => {
      const params = {
        bookingId,
        page: pageParam,
        perPage
      };
      return fetchBookingHistory(params) as unknown as Promise<IBookingHistoryResponse>;
    },
    getNextPageParam,
    ...options
  });
