import { allTabs } from "PFApp/search/tabs_constants";
import { Activity, CustomType, Filters, Keyword, Match, Meta, OrderMeta, Profile, Template } from "PFTypes";

export type Search = {
  tab: string;
  filtersShown: boolean;
  lock: boolean;
  term: string;
  triggerCounter: number;

  searchTaskId?: number;
  searchTaskFetchCounter: number;

  pendingKeywordSearch: boolean;

  activities: Activity[];
  activitiesMeta: Meta;
  activitiesPage: number;
  activitiesPending: boolean;
  allActivitiesMeta: Meta;

  autoselectEnabled: boolean;
  matchToMerge: Match | null;
  collectionKind: "matches" | "profiles";
  matches: Match[];
  matchesMeta: Meta;
  matchesPage: number;
  matchesPending: boolean;

  profiles: Profile[];
  profilesMeta: Meta;
  profilesPage: number;
  profilesPending: boolean;

  choosenKeywords: Keyword[];
  deletedKeywords: Keyword[];
  suggestedKeywords: Keyword[];
  trackKeywordsIds: number[];
  suggestedKeywordsCyclePage: number;

  profilesRequestFilters: Filters;
  activitiesRequestFilters: Filters;
  matchesRequestFilters: Filters | null;

  profilesRequestOrder: Partial<OrderMeta>;
  activitiesRequestOrder: Partial<OrderMeta>;
  matchesRequestOrder: Partial<OrderMeta>;

  matchTypes: string[];
  filterTypes: string[];
  customTypes: CustomType[];

  availableTemplates: Template[];
};

const initialSearch = ({
  tab,
  id,
  term,
  autoselectEnabled = true
}: {
  tab?: string;
  id?: number;
  term?: string;
  autoselectEnabled?: boolean;
}): Search => ({
  tab: tab && allTabs.includes(tab) ? tab : "matches",
  filtersShown: false,
  lock: false,
  term: term || "",
  triggerCounter: 0,

  searchTaskId: (id && +id) || undefined,
  searchTaskFetchCounter: 0,

  pendingKeywordSearch: false,

  activities: [],
  activitiesMeta: { total: 0 },
  activitiesPage: 1,
  activitiesPending: true,
  allActivitiesMeta: { total: 0 },

  autoselectEnabled,
  matchToMerge: null,
  collectionKind: "matches",
  matches: [],
  matchesMeta: { total: 0 },
  matchesPage: 1,
  matchesPending: true,

  profiles: [],
  profilesMeta: { total: 0 },
  profilesPage: 1,
  profilesPending: true,

  choosenKeywords: [],
  deletedKeywords: [], // keep track of recently deleted keywords
  suggestedKeywords: [],
  trackKeywordsIds: [], // all keywords ever added to the search
  suggestedKeywordsCyclePage: 1,

  profilesRequestFilters: {},
  activitiesRequestFilters: {},
  matchesRequestFilters: null,

  profilesRequestOrder: {},
  activitiesRequestOrder: {},
  matchesRequestOrder: {},

  matchTypes: [],
  filterTypes: [],
  customTypes: [],
  availableTemplates: []
});

export default initialSearch;
