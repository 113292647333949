import { every, first, isArray, map } from "lodash";
import moment from "moment/moment";
import { useAvailabilityFormatter } from "PFCore/helpers/availability";
import { getProperties } from "PFCore/helpers/templates";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useItemsEnumerate } from "PFCore/hooks/use_items_enumerate";
import { Template } from "PFTypes";
import { JSX, useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useStringifyFunctions = () => {
  const { t } = useTranslation();
  const { formatDate, utc, formatDateTime } = useDateFormatter();
  const { availabilityRangesFormat, availabilityIntervalFormat } = useAvailabilityFormatter();
  const enumerateItems = useItemsEnumerate();
  const { getBookingCategory } = useBookingCategories();

  return {
    "custom_field": ({ value, separator = "\n" }) =>
      typeof value === "string"
        ? value
        : map(value, (data) =>
            data
              ? data.text || data.text === ""
                ? data.text
                : data.value || data.value === 0
                ? data.value
                : data
              : ""
          ).join(separator),
    "integer": ({ value }) => value.toString(),
    "toggle": ({ value, description }) => `${description}: ${value ? "Yes" : "No"}`,
    "date": ({ value }) => formatDate(value),
    "date-time": ({ value }) => formatDateTime(moment.utc(value)),
    "date-range": ({ value }) => `${formatDate(utc(value.start_date))} - ${formatDate(utc(value.end_date))}`,
    "date-range-with-current": ({ value }) =>
      `${formatDate(utc(value.start_date))} - ${formatDate(utc(value.end_date))}`,
    "availability": ({ value }) => {
      if (value.mode === "time_rule") {
        return availabilityRangesFormat(value, true);
      }

      return availabilityIntervalFormat(value);
    },
    "booking_category_id": ({ value }) => getBookingCategory(value)?.display_as || "",
    "checkboxes": ({ value }) => {
      const selectedValues = value.filter(({ value }) => value).map(({ id }) => t(id, { defaultValue: id }));

      return enumerateItems(selectedValues);
    }
  };
};

type UsePropertyToStringReturn = (
  template: Template,
  name: string,
  value: any,
  valueType: string | null,
  separator?: string
) => JSX.Element | string;

export const usePropertyToString = (): UsePropertyToStringReturn => {
  const stringifyFunctions = useStringifyFunctions();

  return useCallback(
    (template, name, value, valueType = null, separator = "\n") => {
      const property = getProperties(template).find((prop) => prop.name === name);
      if (!property) {
        return "";
      }
      let { type } = property;
      const { description } = property;

      // 'custom_field' of the valueType 'date' is a special case that our 'custom_field' stringify
      // function can't properly handle. It would just display an ugly date string, so we want to
      // format it using the 'date' serailizer.
      if (type === "custom_field" && valueType === "date") {
        type = "date";
        const firstValue: any = first(value);
        value = (firstValue ? firstValue.value : null) || "";
      }

      if (type === "checkboxes" && every(value, ({ value }) => !value)) {
        return "";
      }

      const stringify = type ? stringifyFunctions[type] : null;
      const out = (stringify && stringify({ value, description, separator })) || String(value) || "";

      if (type === "link") {
        const index = (out as string).indexOf("://");
        return 0 >= index || index > 8 ? `http://${out}` : out; // let's add http if no link protocol provided
      } else if (isArray(out)) {
        return out.join(separator);
      } else {
        return out;
      }
    },
    [stringifyFunctions]
  );
};
