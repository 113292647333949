import { Overbooking, Row } from "PFApp/booking/types";
import { Booking, CalendarRange, Id } from "PFTypes";
import { createContext, useContext } from "react";

import { ProfileMisalignments } from "../../../parts/hooks/use_get_misalignment_bars/get_misalignment_items_by_row";

export type DetailsPanelType =
  | "booking"
  | "role"
  | "engagement"
  | "profile"
  | "overbookings"
  | "misalignments";

type MisalignmentDetails = {
  profileId: Id | undefined;
  misalignments: ProfileMisalignments[];
  roleName: string | undefined;
  enableGoToDate: boolean;
};

export type DetailsPanelData = {
  id: number;
  type: DetailsPanelType;
  openAllocateManually?: boolean;
  isEditPermitted?: boolean;
  overbookingsDetails?: OverbookingsDetailsPanelData;
  misalignmentDetails?: MisalignmentDetails;
} & Partial<Pick<Row, "original">>;

export type OverbookingPotentialBooking = Pick<Booking, "start_date" | "end_date" | "title"> & {
  booking_category_id?: number;
  key: string;
};

export type OverbookingsFilterConfig = {
  showAllOverbookingsEnabled: boolean;
  filteredOverbookings?: Overbooking[];
  filteredActivity: {
    id: Id;
    name: string;
  };
};

type OverbookingsDetailsPanelData = {
  filterConfig?: OverbookingsFilterConfig;
  overbookings?: Overbooking[];
  calendarRange: CalendarRange;
  onShowClick?: (date: Date) => void;
  potentialBookings?: OverbookingPotentialBooking[];
};

export type StoredDetailsPanelData = {
  uniqueId: number;
  isClosing?: boolean;
} & DetailsPanelData;

export type StackOrderItemType = DetailsPanelType | "booking_form";

export type StackOrderItem = {
  type: StackOrderItemType;
  id: Id;
  uniqueId: number;
};

export type State = {
  detailsData: StoredDetailsPanelData | undefined;
  detailsDataStack: StoredDetailsPanelData[];
  itemsOrderStack: StackOrderItem[];
};

export const DETAILS_PANEL_INITIAL_STATE: State = {
  detailsData: undefined,
  detailsDataStack: [],
  itemsOrderStack: []
};

type DetailsPanelApi = {
  openDetailsPanel: (data: DetailsPanelData, stack?: boolean) => void;
  closeDetailsPanel: () => void;
  closeDetailsPanelExternal: () => void;
  closeAllDetailsPanels: () => void;
  pushToOrderStack: (id: Id, itemType: StackOrderItemType, uniqueId: number) => void;
  popFromOrderStack: () => void;
  canOpenDetailsPanelOfType: (type: DetailsPanelType) => boolean;
};

const DETAILS_PANEL_API_DEFAULT: DetailsPanelApi = {
  openDetailsPanel: () => {},
  closeDetailsPanel: () => {},
  closeDetailsPanelExternal: () => {},
  closeAllDetailsPanels: () => {},
  pushToOrderStack: () => {},
  popFromOrderStack: () => {},
  canOpenDetailsPanelOfType: () => false
};

type DetailsPanelState = Pick<State, "detailsData" | "detailsDataStack">;

type DetailsPanelModalState = {
  isThisSidePanelOpened: (id: number, type: DetailsPanelData["type"]) => boolean;
};

const DEFAULT_MODAL_STATE: DetailsPanelModalState = {
  isThisSidePanelOpened: () => false
};

type DetailsPanelRoleState = Pick<DetailsPanelData, "openAllocateManually">;

const DEFAULT_ROLE_STATE: DetailsPanelRoleState = {
  openAllocateManually: false
};

export type DetailsPanelOrderApi = {
  checkTopValue: (type: StackOrderItemType) => boolean;
  findIndexOfElement: (uniqueId: number) => number;
  findIndexOfBookingForm: () => number;
};

const DEFAULT_ORDER_API: DetailsPanelOrderApi = {
  checkTopValue: () => true,
  findIndexOfElement: () => -1,
  findIndexOfBookingForm: () => -1
};

export const DetailsPanelStateContext = createContext<DetailsPanelState>(DETAILS_PANEL_INITIAL_STATE);
export const DetailsSidePanelStateContext = createContext<DetailsPanelModalState>(
  DEFAULT_MODAL_STATE as DetailsPanelModalState
);
export const DetailsPanelRoleStateContext = createContext<DetailsPanelRoleState>(
  DEFAULT_ROLE_STATE as DetailsPanelRoleState
);
export const DetailsPanelOrderContext = createContext<DetailsPanelOrderApi>(DEFAULT_ORDER_API);

export const DetailsPanelApiContext = createContext<DetailsPanelApi>(DETAILS_PANEL_API_DEFAULT);

export const useDetailsPanelApiContext = () => useContext(DetailsPanelApiContext);
export const useDetailsPanelStateContext = () => useContext(DetailsPanelStateContext);
export const useDetailsPanelIsOpenedApiContext = () => useContext(DetailsSidePanelStateContext);
export const useDetailsPanelRoleStateContext = () => useContext(DetailsPanelRoleStateContext);
export const useDetailsPanelOrderContext = () => useContext(DetailsPanelOrderContext);
