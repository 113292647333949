import TooltipComponent from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

type OvercheduleDaysTooltipProps = PropsWithChildren<{
  isToggleDisabled: boolean;
}>;

export const OvercheduleDaysTooltip = ({ children, isToggleDisabled }: OvercheduleDaysTooltipProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings" });

  const content = isToggleDisabled ? (
    <Typography variant="bodyRegular" noMargin>
      {t("overscheduleDaysTooltip.toggleDisabled")}
    </Typography>
  ) : (
    <>
      <Typography variant="bodyBold" noMargin tag="p">
        {t("overscheduleDaysTooltip.header")}
      </Typography>
      <Typography variant="bodyRegular" noMargin tag="p">
        {t("overscheduleDaysTooltip.content")}
      </Typography>
    </>
  );

  return <TooltipComponent content={content}>{children}</TooltipComponent>;
};
