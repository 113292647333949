import { useMemo } from "react";
import { useCounter } from "react-use";

type UseCarousel = {
  maxIndex: number;
  initialIndex?: number;
};

type UseCarouselReturn = {
  activeIndex: number;
  canSlideLeft: boolean;
  canSlideRight: boolean;
  slideBack: VoidFunction;
  slideForward: VoidFunction;
  slideTo: (destinationIndex: number) => void;
};

export const useCarousel = ({ maxIndex, initialIndex = 0 }: UseCarousel): UseCarouselReturn => {
  const [activeIndex, { inc, dec, set }] = useCounter(initialIndex, maxIndex, 0);

  const canSlideLeft = useMemo(() => activeIndex > 0, [activeIndex]);
  const canSlideRight = useMemo(() => activeIndex < maxIndex, [activeIndex, maxIndex]);

  return {
    activeIndex,
    canSlideLeft,
    canSlideRight,
    slideBack: () => dec(),
    slideForward: () => inc(),
    slideTo: (destinationIndex: number) => set(destinationIndex)
  };
};
