import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Collection, Id } from "PFTypes";
import { Phase } from "PFTypes/phase";
import { QueryOptions } from "PFTypes/request_options";

import { fetchPhases } from "../../../services/phases";

type PhasesResponseType = Collection<Phase[]>;

const phasesKeys = {
  all: () => ["phases", "all"],
  byActivity: (activityId?: Id | null) => ["phases", "byActivity", activityId]
};

export const usePhases = (activityId?: Id | null, options?: QueryOptions<PhasesResponseType>) =>
  useQuery<PhasesResponseType>(
    phasesKeys.byActivity(activityId),
    async () => fetchPhases(activityId),
    options
  );

export const usePhasesInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (activityId?: number) => {
      if (!!activityId || activityId === 0) {
        return queryClient.invalidateQueries(phasesKeys.byActivity(activityId));
      }
      return queryClient.invalidateQueries(phasesKeys.all());
    }
  };
};
