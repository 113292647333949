import { decamelizeKeys } from "humps";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import ColumnsHeaders from "PFApp/components/table/columns_headers";
import RolesRows from "PFApp/workflow/parts/roles/roles_rows";
import PageTitle from "PFComponents/page/page_title";
import { SimplePagination } from "PFComponents/pagination/simple_pagination";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { NoLinkedRoles } from "../../../../parts/no_linked_roles";
import RolesActions from "./roles_actions";
import css from "./roles_header.module.scss";

export const ActivityRoles = () => {
  const { t } = useTranslation("activities", { keyPrefix: "roles" });
  const location = useLocation();

  const columns = useMemo(
    () => [
      { name: "name", title: t("roleTitle"), size: 2, type: "text" },
      { name: "id", title: t("id"), size: 1, type: "text" },
      { name: "status", title: t("status"), size: 1, type: "text" },
      { name: "workflow_state", title: t("workflowState"), size: 2, type: "text" },
      { name: "post_until", title: t("expiryDate"), size: 2, type: "date-time" },
      { name: "availability", title: t("availabilityRequirement"), size: 2, type: "availability" },
      { name: "assignee", title: t("assignee"), size: 2, type: "profile" }
    ],
    [t]
  );

  const {
    roles,
    roleKey,
    fetchRolesPage: fetchRoles,
    canCreateRoles,
    task: activity,
    isEngagement
  } = useActivityPageContext();

  const { meta, entries } = decamelizeKeys(roles);

  const canCreate = activity.coowned && isEngagement && canCreateRoles;

  return (
    <>
      <PageTitle actions={<RolesActions canCreateRoles={canCreate} roleKey={roleKey} activity={activity} />}>
        {t("tabTitle")}
      </PageTitle>
      <div className={css.scrollWrapper}>
        <div className={css.root}>
          {entries.length === 0 ? (
            <NoLinkedRoles
              activity={activity}
              showActions={false}
              canCreateRoles={canCreate}
              roleKey={roleKey}
            />
          ) : (
            <>
              <ColumnsHeaders columns={columns} onOrderChange={() => null} />
              <RolesRows
                items={entries.map((entry) => entry.activity)}
                columns={columns}
                onRoleDeleted={fetchRoles}
                utmSource={location?.state?.utmSource}
                roleKey={roleKey}
              />

              <SimplePagination
                currentPage={meta.page}
                handleClick={(page) => fetchRoles({ page, perPage: meta.per_page })}
                totalPages={meta.total_pages}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
