import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";
import { Conversation } from "PFTypes";

export const fetchConversation = (id: number): jqXHR<Conversation> =>
  //@ts-ignore
  $.ajax({
    url: ApiRoute(`/api/chat/conversations/${id}`),
    api_version: 2
  });
