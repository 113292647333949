import api from "PFCore/api";
import { Collection, Notification } from "PFTypes";

export type FetchNotificationsPayload = {
  targetTypes: string[];
  perPage?: number;
  page?: number;
  templateKey?: string;
  read?: boolean;
};

export type FetchNotificationsResponse = Collection<Notification[]>;

export const fetchNotifications = ({
  targetTypes,
  page = 1,
  perPage = 0,
  read = undefined,
  templateKey = undefined
}: FetchNotificationsPayload) =>
  api<FetchNotificationsResponse>({
    url: "notifications",
    params: {
      targetTypes,
      page,
      perPage,
      ...(read !== undefined ? { read } : {}),
      ...(templateKey ? { templateKey } : {})
    }
  });
