import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "PFTypes";

import {
  fetchProfileMatchingRoles,
  FetchProfileMatchingRolesParams,
  FetchProfileMatchingRolesResponse
} from "../../../services/profile";
import profileKeys from "./query_keys";

export const useProfileMatchingRoles = (
  params: FetchProfileMatchingRolesParams,
  options: QueryOptions<FetchProfileMatchingRolesResponse> = {}
) =>
  useQuery<FetchProfileMatchingRolesResponse>(
    profileKeys.matchingRoles(params),
    () => fetchProfileMatchingRoles(params),
    {
      retry: false,
      ...options
    }
  );
