import { customValuesShapePropType, scoresShapePropType } from "PFCore/base/prop_types/common_prop_types";
import PropTypes from "prop-types";

export const shortlistResponseShapePropType = PropTypes.shape({
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  scores: scoresShapePropType,
  custom_values: customValuesShapePropType,
  optional_fields: PropTypes.shape({
    available_minutes: PropTypes.number
  }),
  state: PropTypes.oneOf([
    "pending",
    "accepted",
    "declined",
    "assigned",
    "filled",
    "rejected",
    "booked",
    "confirmed"
  ]).isRequired
});
