import moment, { Moment, utc } from "moment";

import { ISO_DATE_FORMAT } from "../../../../helpers/date";
import { CalendarPeriod } from "../bookings_calendar.types";

const isDateBetween = (date: Moment, bars: CalendarPeriod[]): boolean =>
  bars.some((booking) =>
    utc(date).isBetween(
      utc(booking.start_date).startOf("day"),
      utc(booking.end_date).endOf("day"),
      undefined,
      "[]"
    )
  );

const isOverlapping = (start: Moment, end: Moment, bars: CalendarPeriod[]): boolean =>
  isDateBetween(start, bars) ||
  isDateBetween(end, bars) ||
  bars.some(
    (booking) =>
      utc(booking.start_date).startOf("day").isSameOrAfter(start) &&
      utc(booking.end_date).endOf("day").isSameOrBefore(end)
  );

const splitPeriodsIntoWeeks = (
  periods: CalendarPeriod[],
  startDate: string | undefined,
  endDate: string | undefined
): Record<string, CalendarPeriod[]> => {
  if (!startDate || !endDate) {
    return {};
  }
  const current = utc(moment(startDate)).startOf("isoWeek");
  const end = moment(endDate).endOf("isoWeek");
  const splittedBars = {};
  while (current < end) {
    const endOfWeek = current.clone().endOf("isoWeek");
    const weekKey = current.format(ISO_DATE_FORMAT);
    splittedBars[weekKey] = [];
    periods
      .filter((bar) => {
        const { start_date, end_date } = bar;
        return start_date.isBefore(endOfWeek) && end_date.isAfter(current);
      })
      .forEach((bar) => {
        splittedBars[weekKey] = [...splittedBars[weekKey], bar];
      });

    current.add(1, "week");
  }

  return splittedBars;
};

export const getPeriodsLevelsInWeek = (
  bookings: CalendarPeriod[][],
  startDate: string | undefined,
  endDate: string | undefined
): Record<string, CalendarPeriod[]>[] => {
  if (!startDate || !endDate) {
    return [];
  }
  return bookings.map((bookingsLevel) => splitPeriodsIntoWeeks(bookingsLevel, startDate, endDate));
};

export const separateOverlappingPeriods = (periods: CalendarPeriod[]): CalendarPeriod[][] => {
  const periodsSortedByDuration = periods.sort((bookingA, bookingB) => {
    const durationA = bookingA.end_date.endOf("day").diff(bookingA.start_date.startOf("day"), "days");
    const durationB = bookingB.end_date.endOf("day").diff(bookingB.start_date.startOf("day"), "days");
    return durationB - durationA;
  });

  const rows: CalendarPeriod[][] = [];

  periodsSortedByDuration.forEach((bar) => {
    if (!rows.length) {
      rows.push([bar]);
    } else {
      let shouldInsertNewRow = true;
      for (let i = 0; i < rows.length; i++) {
        if (isOverlapping(bar.start_date, bar.end_date, rows[i])) {
          continue;
        }
        rows[i].push(bar);
        shouldInsertNewRow = false;
        break;
      }
      if (shouldInsertNewRow) {
        rows.push([bar]);
      }
    }
  });

  return rows;
};
