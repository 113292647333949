import classNames from "classnames";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { ActivityProfilesAvatarStack } from "PFApp/components/activity_profiles_avatar_stack";
import { useTranslation } from "react-i18next";

import css from "./creator.module.scss";
import { useActivityChat } from "./hooks/use_activity_chat";

const Creator = () => {
  const { task } = useActivityPageContext();
  const { t } = useTranslation("activities");
  const { startChat } = useActivityChat();
  const creator = task.profile;

  return (
    <div className={classNames(css.root)} data-qa-id="Creator">
      <ActivityProfilesAvatarStack
        label={t("show.parts.creator", { count: 1 })}
        profiles={[creator]}
        displayChatIcon={task?.state !== "draft"}
        handleIconClick={startChat}
        isInline
      />
    </div>
  );
};

export default Creator;
