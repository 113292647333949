import { Button } from "PFComponents/button";
import ButtonLink from "PFComponents/button/button_link";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import css from "./not_found.module.scss";

const NotFoundComponent = ({
  message,
  buttonMessage,
  buttonDisabled,
  buttonHidden,
  buttonUrl,
  children,
  handleClick,
  style
}) => {
  const { t } = useTranslation("core");
  const history = useHistory();

  const handleDefaultClick = (event) => {
    if (!handleClick && !buttonUrl) {
      event.preventDefault();
      history.goBack();
    } else if (handleClick) {
      handleClick();
    }
  };

  return (
    <div className={css.emptyBlock} style={style}>
      {children}
      <p className={css.emptyText}>{message || t("notFound.message")}</p>
      {!buttonHidden && (
        <>
          {buttonUrl ? (
            <ButtonLink
              kind="primary"
              href={buttonUrl}
              disabled={buttonDisabled}
              onClick={handleDefaultClick}
            >
              {buttonMessage || t("notFound.buttonMessage")}
            </ButtonLink>
          ) : (
            <Button kind="primary" disabled={buttonDisabled} onClick={handleDefaultClick}>
              {buttonMessage || t("notFound.buttonMessage")}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

NotFoundComponent.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  buttonMessage: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonHidden: PropTypes.bool,
  buttonUrl: PropTypes.string,
  handleClick: PropTypes.func,
  style: PropTypes.object
};

NotFoundComponent.defaultProps = {
  message: null,
  buttonMessage: null,
  buttonDisabled: false,
  buttonHidden: false,
  buttonUrl: null
};

export default NotFoundComponent;
