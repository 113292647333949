import { LoadingDots } from "PFComponents/loading_dots";
import { RadioItem } from "PFComponents/radios";
import { useBookedProfiles } from "PFCore/hooks/queries/bookings/booked_profiles/use_booked_profiles";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BookingQuestions } from "./booking_questions";
import css from "./booking_questions.module.scss";

export enum GroupBookingsQuestionsMode {
  Update = "update",
  Delete = "delete"
}

export enum GroupBookingsQuestionsOption {
  GroupThis = "GroupThis",
  GroupAll = "GroupAll"
}

type GroupBookingsQuestionsProps = {
  mode: GroupBookingsQuestionsMode;
  value: GroupBookingsQuestionsOption;
  onChange: (selectedOption: GroupBookingsQuestionsOption) => void;
  userName: string;
  bookingGroupId: number;
};

export const GroupBookingsQuestions = ({
  mode,
  value,
  onChange,
  userName,
  bookingGroupId
}: GroupBookingsQuestionsProps): JSX.Element => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.group" });
  const { data: bookedProfiles, isLoading } = useBookedProfiles({ bookingGroupId });
  const membersCount = bookedProfiles?.meta.total;
  const label = t(`question.${mode}`, {
    membersCount
  });

  const GROUP_DELETE_OPTIONS: RadioItem<GroupBookingsQuestionsOption>[] = useMemo(
    () => [
      { id: GroupBookingsQuestionsOption.GroupThis, label: t("options.single", { name: userName }) },
      { id: GroupBookingsQuestionsOption.GroupAll, label: t("options.all", { membersCount }) }
    ],
    [userName, membersCount, t]
  );

  return isLoading ? (
    <LoadingDots className={css.loader} />
  ) : (
    <BookingQuestions
      name="groupBookingQuestions"
      text={label}
      className={css.specificTimeOptions}
      value={value}
      items={GROUP_DELETE_OPTIONS}
      onChange={onChange}
    />
  );
};
