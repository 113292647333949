import { Icon } from "PFComponents/icon";
import { getJobTitle, getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";

import { DetailsHeader } from "../details_header";

type ProfileDetailHeaderProps = {
  profile: Profile | undefined;
  subtitle?: string;
};

export const ProfileDetailHeader = ({ profile, subtitle }: ProfileDetailHeaderProps) =>
  profile ? (
    <DetailsHeader
      title={getProfileName(profile)}
      subtitle={subtitle || getJobTitle(profile)}
      linkUrl={`/profiles/${profile?.id}`}
    >
      <Icon name="profile" />
    </DetailsHeader>
  ) : null;
