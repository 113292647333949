import { getBadgeColour } from "PFApp/notifications/notification_item";
import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import { agoFormat } from "PFCore/helpers/date";
import PropTypes from "prop-types";
import { useMemo } from "react";

import css from "./notification_item.module.scss";

const NotificationItem = ({ notification: { title, created_at, category_type, body } }) => {
  const badgeColour = useMemo(() => getBadgeColour(category_type), [category_type]);

  return (
    <div className={css.item}>
      <div className={css.header}>
        <div className={css.date}>{agoFormat(created_at)}</div>
      </div>
      <SimpleBadge
        text={title}
        kind={badgeColour}
        style={{
          margin: "0 0 10px 0",
          fontSize: 10,
          padding: "2px 10px",
          minHeight: 0,
          width: "fit-content"
        }}
      />
      <div className={css.body}>{body}</div>
    </div>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    body: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category_type: PropTypes.string.isRequired
  })
};
