import { CalendarRange } from "PFCore/types/calendar_range";
import { Filters } from "PFTypes";

export const workforceMetricsKeys = {
  all: () => ["workforceMetrics"],
  single: (calendarRange: CalendarRange, filters: Filters = {}) => [
    ...workforceMetricsKeys.all(),
    JSON.stringify(calendarRange),
    JSON.stringify(filters)
  ]
};
