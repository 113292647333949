import { compact, isString, some } from "lodash";
import AutoSelect from "PFComponents/select/autoselect";
import PropTypes from "prop-types";
import { useCallback } from "react";

import ComponentPropTypes from "./component_prop_types";

const AutoSelectWrapper = ({ property, value, label, onChange, qaId, style, error }) => {
  const isMultiple = property.kind === "multiple";
  const handleChange = useCallback(
    (value) =>
      onChange(isMultiple ? value.map((val) => val.text) : value?.length > 0 ? value[0].text : undefined),
    [isMultiple, onChange]
  );
  const values =
    value !== undefined
      ? Array(value)
          .flat()
          .map((val) => (isString(val) ? { text: val } : val))
      : undefined;

  return (
    <AutoSelect
      multi={isMultiple}
      closeOnChange={isMultiple}
      values={values}
      letClear={some(property.enum, (item) => item === "")}
      query={() =>
        Promise.resolve(compact(property.items?.enum || property.enum).map((item) => ({ text: item })))
      }
      label={label}
      qaId={qaId}
      style={{ ...style, marginBottom: 30 }}
      error={error}
      handleChange={handleChange}
    />
  );
};

AutoSelectWrapper.propTypes = {
  ...ComponentPropTypes,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default AutoSelectWrapper;
