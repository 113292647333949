import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { CurrentProfile } from "PFTypes";

export const getRoleJobCodeDisplayAs = (currentProfile: CurrentProfile) =>
  currentProfile.templates?.find((template) => template.name === "Role")?.schema.properties.job_code?.title ||
  "ID";

export const useRoleJobCode = (): string => {
  const { data: currentProfile } = useCurrentProfile();

  return getRoleJobCodeDisplayAs(currentProfile);
};
