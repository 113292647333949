// deparam helper is opposite of $.param - i.e. turns url params string into a URLSearchParams object
// It supports arrays and top level properties
// It does not support nested properties

export const uriDeparam = function (options = {}) {
  const params = new URLSearchParams(window.location.search.substr(1));
  if (options.cleanup) {
    if (typeof window.history.pushState === "function") {
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }
  return params;
};
