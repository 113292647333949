import ShowNewChatModal from "PFApp/conversations/show_new_chat_modal/show_new_chat_modal";
import { Button } from "PFComponents/button";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import cssCommon from "./activity_details_common.module.scss";
import css from "./activity_details_other_chats.module.scss";
import ActivityDetailsOtherChatsItem from "./activity_details_other_chats_item";

const ActivityDetailsOtherChats = ({ activity, conversations, participantsOfConversations }) => {
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const { t } = useTranslation("activities");

  return (
    <div className={css.wrapper} data-qa-id="ActivityDetailsOtherChats">
      {showNewChatModal && (
        <ShowNewChatModal task={activity} handleClose={() => setShowNewChatModal(false)} />
      )}
      {conversations.map((conversation) => (
        <ActivityDetailsOtherChatsItem
          key={conversation.id}
          conversation={conversation}
          participants={participantsOfConversations[conversation.id]}
        />
      ))}
      <div className={!conversations || conversations.length === 0 ? cssCommon.headerEmpty : null}>
        {activity.coowned && (
          <div style={{ float: "right", marginTop: 5 }}>
            <Button
              text={t("show.details.startNew")}
              kind="secondary"
              onClick={() => setShowNewChatModal(true)}
              qaId={"ActivityDetailsChatStartButton"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ActivityDetailsOtherChats.propTypes = {
  activity: PropTypes.shape({ id: PropTypes.number.isRequired, coowned: PropTypes.bool }).isRequired,
  conversations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      kind: PropTypes.oneOf(["normal"]).isRequired
    })
  ).isRequired,
  participantsOfConversations: PropTypes.object.isRequired
};

export default ActivityDetailsOtherChats;
