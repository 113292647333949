import api from "PFCore/api";
import { Id } from "PFTypes";

export const deleteAssignee = (id: Id) =>
  api({
    url: `assignees/${id}`,
    method: "DELETE"
  });

export const updateAssignee = (data: { profileId: Id; activityId: Id }) =>
  api({
    url: "assignees",
    method: "POST",
    data
  });

export const bulkUpdateAssignee = (data: { profileId: Id; activityIds: Id[] }) =>
  api({
    url: "assignees/bulk_update",
    method: "POST",
    data
  });
