import { MatchingRelationType } from "PFApp/constants/matching_relation_type";
import { QueryParams } from "PFTypes";
import { useCallback, useState } from "react";

export const useProfileMatchingRolesQueryParams = () => {
  const [params, setParams] = useState<QueryParams & { relationType: MatchingRelationType }>({
    page: 1,
    relationType: MatchingRelationType.PrefilteredAndAvailable
  });

  const updateOrder = useCallback(
    (order: QueryParams["order"]) => setParams((params) => ({ ...params, order })),
    []
  );
  const updatePage = useCallback(
    (page: QueryParams["page"]) => setParams((params) => ({ ...params, page })),
    []
  );
  const updateFilters = useCallback(
    (filters: QueryParams["filters"]) => setParams((params) => ({ ...params, filters })),
    []
  );
  const updateRelation = useCallback(
    (relationType: MatchingRelationType) => setParams((params) => ({ ...params, relationType })),
    []
  );

  return {
    ...params,
    ...(params.order ? { order: params.order } : {}),
    updatePage,
    updateRelation,
    updateFilters,
    updateOrder
  };
};
