import { WithLayout } from "PFApp/routes/route_helpers";
import { useSession } from "PFApp/use_session";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { sidebar } from "PFCore/base/page_layouts";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Redirect } from "react-router-dom";

import { useDeeplink } from "../use_deeplink";

const NotFoundRoute = () => {
  const { saveDeeplink } = useDeeplink();

  const { isSignedIn } = useCurrentProfile();

  const { clearTokens } = useSession();

  if (isSignedIn) {
    return (
      <WithLayout layout={{ sidebar: sidebar }}>
        <NotFoundComponent
          message="The page was not found."
          buttonMessage="Click here to go to the homepage"
          buttonUrl="/"
        />
      </WithLayout>
    );
  } else {
    saveDeeplink();
    clearTokens();
    return <Redirect to="/users/signin" />;
  }
};

export default NotFoundRoute;
