import { useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import { fetchBookingNotes, IBookingNotesResponse } from "PFApp/booking/services/booking_notes_api";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";

import { noteKeys } from "./query_keys";

export const useBookingNotesInfinite = (
  bookingId: number,
  perPage?: number,
  options: Omit<UseInfiniteQueryOptions<IBookingNotesResponse>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  } = {}
) =>
  useInfiniteQuery({
    queryKey: noteKeys.ofBooking(bookingId, perPage),
    queryFn: ({ pageParam = 1 }) => {
      const params = {
        bookingId,
        page: pageParam,
        perPage
      };
      return fetchBookingNotes(params) as unknown as Promise<IBookingNotesResponse>;
    },
    getNextPageParam,
    ...options
  });
