import { orderBy } from "lodash";
import { setTimeToMidnight } from "PFApp/booking/parts/overview/calendar/calendar.utils";
import { Profile } from "PFTypes";
import { useMemo } from "react";

import { WarningAccordion } from "../../warnings_detail/warning_accordion";
import { OverbookingsRange } from "../use_overbooking_ranges";
import { BookingItem } from "./booking_item";
import { OverbookingHeader } from "./overbooking_header";
import { OverbookingSubHeader } from "./overbooking_sub_header";

type OverbookingsListProps = {
  rangesWithBookings: OverbookingsRange[];
  selectedSort: any;
  profile: Profile;
  onDeleted?: (entity: any) => Promise<void>;
  onEdited?: () => void;
  goToDate?: (date: Date) => void;
};

export const OverbookingsList = ({
  profile,
  rangesWithBookings,
  selectedSort,
  onDeleted,
  onEdited,
  goToDate
}: OverbookingsListProps) => {
  const sortedRanges = orderBy(
    rangesWithBookings,
    [({ start }) => new Date(start).getTime(), ({ end }) => new Date(end).getTime()],
    [selectedSort, selectedSort]
  );

  const sortedOverbookingElements = useMemo(
    () =>
      sortedRanges.map(({ start, end, bookings, overbookings }) => (
        <WarningAccordion
          key={`${profile.id}_${start}`}
          onShowClick={goToDate ? () => goToDate(setTimeToMidnight(new Date(start))) : undefined}
          header={<OverbookingHeader start={start} end={end} overbookings={overbookings} />}
          subHeader={<OverbookingSubHeader overbookings={overbookings} />}
        >
          {bookings.map((booking) => (
            <BookingItem
              key={"id" in booking ? booking.id : booking.key}
              bookingId={"id" in booking ? booking.id : undefined}
              profile={profile}
              onDeleted={onDeleted}
              onEdited={onEdited}
              potentialBooking={!("id" in booking) ? booking : undefined}
            />
          ))}
        </WarningAccordion>
      )),
    [profile, goToDate, onDeleted, onEdited, sortedRanges]
  );

  return <>{sortedOverbookingElements}</>;
};
