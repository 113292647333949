import { useQuery } from "@tanstack/react-query";
import {
  FetchSkillsFrameworksOptionsParams,
  SkillsFrameworksOptionsResponse
} from "PFCore/services/skills_frameworks/fetch_skills_frameworks_options";
import { fetchSkillsFrameworksOptions } from "PFCore/services/skills_frameworks/fetch_skills_frameworks_options";
import { QueryOptions } from "PFTypes/request_options";

import { skillsFrameworksKeys } from "./query_keys";

export const useSkillsFrameworksOptions = (
  params: FetchSkillsFrameworksOptionsParams = {},
  options: QueryOptions<SkillsFrameworksOptionsResponse> = {}
) => {
  const queryKey = skillsFrameworksKeys.options(params);

  return useQuery(queryKey, () => fetchSkillsFrameworksOptions(params), options);
};
