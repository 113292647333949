import { ActionIcon } from "PFComponents/action_icon";
import { SyntheticEvent } from "react";

type ExpandButtonProps = {
  isOpen: boolean;
  onClick: (event: SyntheticEvent) => void;
};

export const ExpandButton = ({ isOpen, onClick }: ExpandButtonProps) => (
  <ActionIcon onClick={onClick} name={isOpen ? "chevron-down" : "chevron-right"} size="sm" />
);
