export const workforceKeys = {
  all: () => ["workforce"],
  withParameters: (filters, order, dateRange, page: number, perPage: number) => [
    ...workforceKeys.all(),
    { filters, order, dateRange },
    page,
    perPage
  ],
  pinned: (filters, order, dateRange, page: number, perPage: number) => [
    ...workforceKeys.withParameters(filters, order, dateRange, page, perPage),
    "pinned"
  ]
};
