import { uniqBy } from "lodash";
import AppContext from "PFApp/app_context";
import { isFilter, isMatch } from "PFApp/search/helpers/normalize_keywords";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import SmartSuggestionsShell from "./smart_suggestions_shell";
import SuggestedFilters from "./suggested_filters";
import SuggestedKeywords from "./suggested_keywords";

const SmartSuggestions = () => {
  const { suggestedKeywords, choosenKeywords } = useContext(AppContext).store.search;
  const { t } = useTranslation("search");
  const suggestibles = getSuggestibles(suggestedKeywords, choosenKeywords);
  const filterSuggestions = getFilterSuggestions(suggestedKeywords, choosenKeywords);

  return (
    <SmartSuggestionsShell empty={suggestibles.length === 0 && filterSuggestions.length === 0}>
      <p>{t("smartSuggestions.description")}</p>

      <SuggestedKeywords items={suggestibles || []} />
      <SuggestedFilters items={filterSuggestions || []} />
    </SmartSuggestionsShell>
  );
};

const getSuggestibles = (suggestedKeywords, choosenKeywords) => {
  const isNotChoosen = (keyword) => !choosenKeywords.find((other) => other.id === keyword.id);
  const notChoosenKeywords = suggestedKeywords.filter(isNotChoosen);

  return notChoosenKeywords.filter(isMatch);
};

const getFilterSuggestions = (suggestedKeywords, choosenKeywords) => {
  // detectables are also suggested but are to be treated as filters
  const detectables = suggestedKeywords.filter(isFilter);
  const chosen = choosenKeywords.filter(isFilter);
  return uniqBy(detectables.concat(chosen), "id");
};

export default SmartSuggestions;
