import { ApiRoute } from "PFCore/utilities/routes";

export const NOTIFICATION_SETTINGS_ACTIONS = {
  NOTIFICATIONS_SET: "NOTIFICATION_SETTINGS_SET"
};

export const NOTIFICATION_SETTINGS_ASYNC_ACTIONS = {
  NOTIFICATION_SETTINGS_FETCH: "NOTIFICATION_SETTINGS_FETCH",
  NOTIFICATION_SETTINGS_UPDATE: "NOTIFICATION_SETTINGS_UPDATE"
};

export const notificationSettingsReducer = (state, { type, payload }) => {
  switch (type) {
    case NOTIFICATION_SETTINGS_ACTIONS.NOTIFICATION_SETTINGS_SET:
      return {
        ...state,
        notificationSettings: { ...state.notificationSettings, ...payload }
      };
  }
};

export const notificationSettingsDispatch = (dispatch, action) => {
  switch (action.type) {
    case NOTIFICATION_SETTINGS_ASYNC_ACTIONS.NOTIFICATION_SETTINGS_FETCH: {
      return $.ajax({ url: ApiRoute("/api/notification_settings") }).then((settings) =>
        dispatch({ type: NOTIFICATION_SETTINGS_ACTIONS.NOTIFICATION_SETTINGS_SET, payload: settings })
      );
    }
    case NOTIFICATION_SETTINGS_ASYNC_ACTIONS.NOTIFICATION_SETTINGS_UPDATE: {
      return $.ajax({
        method: "PUT",
        data: action.payload,
        url: ApiRoute(`/api/notification_settings/${action.payload.id}`)
      }).then(() =>
        dispatch({ type: NOTIFICATION_SETTINGS_ACTIONS.NOTIFICATION_SETTINGS_SET, payload: action.payload })
      );
    }
  }
};
