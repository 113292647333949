import { Typography } from "PFComponents/typography";

import css from "./save_as_view_modal.module.scss";

type ProfileItemProps = {
  profileName: string;
  email: string;
};

export const ProfileItem = ({ profileName, email }: ProfileItemProps) => (
  <div className={css.profileItem}>
    <Typography variant="bodyBold" tag="span">
      {profileName}
    </Typography>
    <Typography variant="labelRegular" tag="span">
      {email}
    </Typography>
  </div>
);
