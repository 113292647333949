import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import {
  FeedbackParticipantsResponseType,
  useActivityFeedbackParticipants
} from "PFCore/hooks/queries/activity/use_activity_feedback_participants";
import { ActivityParticipant, ParticipatedRole } from "PFTypes/activity_participant";
import { useMemo } from "react";

type FeedbackRecipientData = {
  selectedParticipant: ActivityParticipant | undefined;
  selectedRole: ParticipatedRole | undefined;
};

export const useManagerFeedbackParticipants = (
  profileId?: number,
  roleId?: number,
  onSuccess?: (res: FeedbackParticipantsResponseType) => void
): FeedbackRecipientData => {
  const { task } = useActivityPageContext();
  const { data } = useActivityFeedbackParticipants(task?.id, {
    onSuccess
  });

  const selectedParticipant = useMemo(
    () => data?.entries.find((participant) => participant.id === profileId),
    [data?.entries, profileId]
  );

  const selectedRole = useMemo(
    () => selectedParticipant?.roles.find((role) => role.id === roleId),
    [selectedParticipant?.roles, roleId]
  );

  return { selectedParticipant, selectedRole };
};
