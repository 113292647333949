import isEmpty from "lodash/isEmpty";
import { Filter, Filters, Value } from "PFTypes";

const isUpdatingSingleFilter = (args: any[]): args is [Filter, string, { children: boolean } | undefined] =>
  !isEmpty(args) && "name" in args[0] && args[1] !== undefined;
const isUpdatingAllFilters = (args: any[]): args is [Filters<Value>] =>
  !isEmpty(args) && typeof args[0] === "object" && !("name" in args[0]);

export const MOST_RELEVANT_ORDER = { name: ["scores.term_score"], text: "Most relevant", direction: "desc" };

export const checkIfShouldUpdateOrderToMostRelevant = (args: any): boolean => {
  if (isUpdatingSingleFilter(args)) {
    const [filter, filterValue, options] = args;
    const isRootTermFilterApplied = filter.name === "term" && filterValue && !options?.children;
    if (isRootTermFilterApplied) {
      return true;
    }
  }
  if (isUpdatingAllFilters(args)) {
    const [filters] = args;
    if (filters.fields?.term) {
      return true;
    }
  }
  return false;
};
