import { findIndex } from "lodash";
import { Select } from "PFComponents/select/select";
import Toggle from "PFComponents/toggle/toggle";
import { useOptionalTranslation } from "PFCore/hooks/use_optional_translation";
import { Filter, Meta } from "PFTypes";
import { useMemo } from "react";

import css from "./table_properties.module.scss";

type GroupBySelectProps = {
  meta?: Meta;
  selectedGroupBy?: string;
  onGroupByChange?: (group: string | null) => void;
};

const ALLOWED_FIELDS_GROUPS = ["state", "workflow_state"];

export const getAllowedGroups = (meta?: Meta): Filter[] => {
  const fieldsGroups = Object.values(meta?.filters?.fields || {}).filter(({ name }) =>
    ALLOWED_FIELDS_GROUPS.includes(name)
  );
  const stringsGroups = Object.values(meta?.filters?.strings || {});
  return [...fieldsGroups, ...stringsGroups] as Filter[];
};

export const GroupBy = ({ meta, selectedGroupBy, onGroupByChange }: GroupBySelectProps) => {
  const { t } = useOptionalTranslation("workflow");

  const showGrouping = !!selectedGroupBy;

  const groupByOptions = useMemo(
    () =>
      getAllowedGroups(meta).map(({ name, title }) => ({
        id: name || "",
        item: name || "",
        displayElement: title
      })),
    [meta]
  );

  const [selectedGroupByIndex, selectedGroupByName] = useMemo(() => {
    if (selectedGroupBy) {
      const index = findIndex(groupByOptions, ["item", selectedGroupBy]) || 0;
      const name = groupByOptions[index].displayElement;

      return [index, name];
    } else {
      return [];
    }
  }, [selectedGroupBy, groupByOptions]);

  return (
    <>
      {onGroupByChange && (
        <Toggle
          label={t("parts.enableTableProperties")}
          checked={showGrouping}
          inline
          compact
          reversed
          disabled={groupByOptions.length === 0}
          onChange={(value) => {
            onGroupByChange(value ? groupByOptions[0]?.item : null);
          }}
          qaId="TablePropertiesGroupToggle"
        />
      )}

      {showGrouping && (
        <Select
          className={css.groupBySelect}
          onChange={onGroupByChange}
          value={selectedGroupByName}
          options={groupByOptions}
          selectedIndex={selectedGroupByIndex}
          controlledValue
        />
      )}
    </>
  );
};
