import { last } from "lodash";
import { Id } from "PFTypes";
import { useReducer } from "react";

import {
  DETAILS_PANEL_INITIAL_STATE,
  StackOrderItem,
  StackOrderItemType,
  State,
  StoredDetailsPanelData
} from "./details_panel_context";

const REMEMBERED_DETAILS_COUNT = 2;

type Actions =
  | { type: "openDetailsPanel"; data: StoredDetailsPanelData; stack?: boolean }
  | { type: "closeDetailsPanel" }
  | { type: "closeAllDetailsPanels" }
  | { type: "pushToOrderStack"; id: Id; itemType: StackOrderItemType; uniqueId: number }
  | { type: "popFromOrderStack" }
  | { type: "clearOrderStack" }
  | { type: "setIsDetailsPanelClosing"; isClosing: boolean };

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "openDetailsPanel": {
      const { data, stack } = action;

      if (stack) {
        const lastNDetailsData = state.detailsDataStack.slice(-REMEMBERED_DETAILS_COUNT);
        const detailsStack = [...lastNDetailsData, data];
        return { ...state, detailsDataStack: detailsStack, detailsData: data };
      } else {
        return { ...state, detailsDataStack: [], detailsData: data };
      }
    }
    case "closeDetailsPanel": {
      const { detailsDataStack } = state;
      const stack = [...detailsDataStack.slice(0, detailsDataStack.length - 1)];
      const detailsPanelDataToSet = last(stack);

      return { ...state, detailsDataStack: stack, detailsData: detailsPanelDataToSet };
    }
    case "setIsDetailsPanelClosing": {
      const { isClosing } = action;
      const { detailsData } = state;
      return {
        ...state,
        detailsData: detailsData && isClosing ? { ...detailsData, isClosing } : detailsData
      };
    }
    case "closeAllDetailsPanels": {
      return { ...state, detailsDataStack: [], detailsData: undefined, itemsOrderStack: [] };
    }
    case "pushToOrderStack": {
      const { itemsOrderStack } = state;
      const { id, itemType, uniqueId } = action;
      const filteredStack =
        itemType === "booking_form"
          ? itemsOrderStack.filter((item) => item.type !== "booking_form")
          : itemsOrderStack;
      const changedItemsOrderStack: StackOrderItem[] = [...filteredStack, { id, type: itemType, uniqueId }];

      return { ...state, itemsOrderStack: changedItemsOrderStack };
    }
    case "popFromOrderStack": {
      const { itemsOrderStack } = state;
      return { ...state, itemsOrderStack: itemsOrderStack.slice(0, itemsOrderStack.length - 1) };
    }
    case "clearOrderStack": {
      return { ...state, itemsOrderStack: [] };
    }
  }
};

export const useDetailsPanelReducer = () => useReducer(reducer, DETAILS_PANEL_INITIAL_STATE);
