import { useActivitiesStatistics } from "PFCore/hooks/queries/activities/marketplace/use_activities_statistics";
import { MarketplaceActivity, MarketplaceActivityCounter, MatchedActivity } from "PFTypes";

export const useRolesStatistics = (
  roles: MarketplaceActivity[] | MatchedActivity[] | undefined,
  counters: MarketplaceActivityCounter[] = ["interests"]
) => {
  const allIds = (roles || []).map(({ id }) => id);

  const { data } = useActivitiesStatistics(
    { id: allIds, counters },
    {
      enabled: allIds.length > 0
    }
  );

  return data;
};
