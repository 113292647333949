import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import css from "./table_properties.module.scss";

type SortableColumnsProps = {
  children: React.ReactNode;
  onDragEnd: (result: DropResult) => void;
};

export const SortableColumns = ({ children, onDragEnd }: SortableColumnsProps) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="columns-droppable">
      {(provided) => (
        <div {...provided.droppableProps} className={css.tileList} ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);
