import AnyFilter from "PFApp/components/filters/any_filter";
import { useFiltersContext } from "PFApp/components/filters/context/filters_context";
import {
  AVAILABILITY_SCOPE_LIMIT,
  hasReachedLimit
} from "PFApp/components/filters/filter_items/availability_filter/helpers";
import { ExternalFiltersData } from "PFApp/components/filters/filters_panel/filters_element";
import { useSavedViewsContext } from "PFApp/components/filters/saved_views_context/saved_views_context";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Filter, Meta } from "PFTypes";
import { PageTarget } from "PFTypes/saved_filters";
import React from "react";
import { useTranslation } from "react-i18next";

import { useBookingCategoryFilter } from "./use_booking_category_filter";
import { useClearBookingCategory } from "./use_clear_booking_category";

export const BOOKING_CATEGORY_FILTER = "booking_category_id";

type BookingCategoryFilterProps = {
  viewsKey?: PageTarget | null;
} & ExternalFiltersData;

export const BookingCategoryFilter = ({
  viewsKey,
  lastFocusedFilterNameRef,
  onBlur,
  onFocus
}: BookingCategoryFilterProps) => {
  const { t } = useTranslation("bookingModule");

  const { meta, handleFilterChange: onFilterChange } = useFiltersContext();
  const { setSelectedViewId } = useSavedViewsContext(viewsKey);
  const { query } = useBookingCategoryFilter();

  const bookingCategoryFilter = meta?.filters?.fields?.booking_category_id;
  const scope = meta?.counters?.filtered_profiles_count;
  const hasReachedScopeLimit = hasReachedLimit(scope as number);

  useClearBookingCategory({ meta: meta as Meta, onFilterChange });

  const filterWithQuery = { ...bookingCategoryFilter, query } as Filter;

  const handleFilterChange = (valueId) => {
    setSelectedViewId(null);
    onFilterChange?.(filterWithQuery, valueId, { children: false });
  };

  return (
    <Tooltip
      content={t("bookings.tooltip.tooManyWorkforceMembers", { count: AVAILABILITY_SCOPE_LIMIT })}
      disabled={!hasReachedScopeLimit}
    >
      <div>
        <AnyFilter
          scope={scope}
          /* @ts-ignore */
          filter={filterWithQuery}
          handleChange={handleFilterChange}
          autofocus={lastFocusedFilterNameRef.current === filterWithQuery.name}
          handleFocus={() => onFocus(filterWithQuery)}
          handleBlur={onBlur}
          disabled={hasReachedScopeLimit}
        />
      </div>
    </Tooltip>
  );
};
