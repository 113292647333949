import ProfindaLogo from "PFComponents/profinda_logo";
import { useCurrentAccount } from "PFCore/hooks/queries/account";

type LogoProps = {
  width: number;
  height: number;
};

export const Logo = ({ width, height }: LogoProps) => {
  const { data: currentAccount } = useCurrentAccount();
  const { logo } = currentAccount;

  if (!logo) {
    return null;
  }

  if (logo.thumbnail_url) {
    return <img src={logo.thumbnail_url} alt="" height={40} data-qa-id="ProfindaLogo.custom" />;
  } else {
    return <ProfindaLogo width={width} height={height} colorMain="#fff" colorSecondary="#fff" />;
  }
};
