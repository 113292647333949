import { HeaderGroup } from "@tanstack/react-table";
import classNames from "classnames";

import { ColumnHeader } from "./column_header";
import { TableData } from "./table";
import css from "./table.module.scss";

type TableHeaderProps<T extends TableData> = {
  headerGroups: HeaderGroup<T>[];
  compact?: boolean;
};

export const TableHeader = <TABLE_DATA extends TableData>({
  headerGroups,
  compact
}: TableHeaderProps<TABLE_DATA>): JSX.Element => (
  <thead className={css.header}>
    {headerGroups.map((headerGroup) => (
      <tr key={headerGroup.id} className={classNames(css.row)}>
        {headerGroup.headers.map((header) => (
          <ColumnHeader key={header.id} header={header} compact={compact} />
        ))}
      </tr>
    ))}
  </thead>
);
