import { Icon } from "PFComponents/icon";
import { useTranslation } from "react-i18next";

import { DetailsHeader } from "../../../../components/details_panel/detail_view/details_header";

type BookingDetailHeaderProps = {
  title: string | undefined;
};

export const BookingDetailHeader = ({ title }: BookingDetailHeaderProps) => {
  const { t } = useTranslation();
  return (
    <DetailsHeader title={title} subtitle={t("booking")}>
      <Icon name="booking" />
    </DetailsHeader>
  );
};
