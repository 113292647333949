import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import Panel from "PFComponents/panel/panel";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import AttachmentsSection from "./attachments_section";
import ConversationSection from "./conversation_section";
import MakePrivateSection from "./make_private_section";
import css from "./sections.module.scss";

const CommonSideSection = ({
  template,
  activity,
  attachments,
  audiences,
  qaIdPrefix,
  handleUploadAdded,
  handleUploadFailed,
  handleUploadSuccessful,
  handleUploadRemove,
  isForceVisibilitySet,
  isPrivate,
  setIsPrivate,
  isForcePrivate,
  isDraft,
  isNew,
  communicationType,
  handleCommunicationChange,
  handleAudiencesChange,
  errors
}) => {
  const isChatEnabled = useIsChatEnabled();
  const { t } = useTranslation("activities");

  const isAttachmentVisible = template.schema?.rules?.hide_attachments !== true;

  return (
    <Panel className={css.wrapper}>
      {isAttachmentVisible && (
        <>
          <div className={css.header}>{t("edit.sections.attachments")}</div>
          <AttachmentsSection
            attachments={attachments}
            qaIdPrefix={qaIdPrefix}
            handleUploadAdded={handleUploadAdded}
            handleUploadSuccessful={handleUploadSuccessful}
            handleUploadFailed={handleUploadFailed}
            handleUploadRemove={handleUploadRemove}
          />
        </>
      )}
      {!isForceVisibilitySet && (
        <>
          <div className={css.header}>{t("edit.sections.privacy")}</div>
          <MakePrivateSection
            activity={activity}
            isEdit={!isNew}
            isDraft={isDraft}
            errors={errors}
            audiences={audiences}
            isPrivate={isPrivate}
            isForcePrivate={isForcePrivate}
            handlePrivateChange={setIsPrivate}
            handleAudiencesChange={handleAudiencesChange}
          />
        </>
      )}
      {isChatEnabled && !isForcePrivate && (isDraft || isNew) && (
        <>
          <div className={css.header}>{t("edit.sections.conversation")}</div>
          <ConversationSection
            communicationType={communicationType}
            handleCommunicationChange={handleCommunicationChange}
            qaIdPrefix={qaIdPrefix}
          />
        </>
      )}
    </Panel>
  );
};

export default CommonSideSection;

CommonSideSection.propTypes = {
  template: PropTypes.object,
  activity: PropTypes.object,
  attachments: PropTypes.array,
  audiences: PropTypes.array,
  qaIdPrefix: PropTypes.string,
  handleUploadAdded: PropTypes.func,
  handleUploadFailed: PropTypes.func,
  handleUploadSuccessful: PropTypes.func,
  handleUploadRemove: PropTypes.func,
  isForceVisibilitySet: PropTypes.bool,
  isPrivate: PropTypes.bool,
  setIsPrivate: PropTypes.func,
  isForcePrivate: PropTypes.bool,
  isDraft: PropTypes.bool,
  isNew: PropTypes.bool,
  communicationType: PropTypes.string,
  handleCommunicationChange: PropTypes.func,
  onPrivateChange: PropTypes.func,
  handleAudiencesChange: PropTypes.func,
  errors: PropTypes.object
};
