import TooltipContent from "PFApp/components/gantt/tooltip_content";
import Tooltip from "PFCore/components/tooltip/tooltip";
import { Booking, BookingCategory } from "PFTypes";
import { hideAll } from "tippy.js";

type PeriodTooltipProps = {
  booking: Booking;
  bookingCategories: BookingCategory[];
  jobCodeDisplayAs: string;
  date: string;
};

export const PeriodTooltip = ({
  booking,
  bookingCategories,
  jobCodeDisplayAs,
  date,
  children
}: React.PropsWithChildren<PeriodTooltipProps>) => (
  <Tooltip
    content={
      <TooltipContent
        booking={booking}
        bookingCategories={bookingCategories}
        jobCodeDisplayAs={jobCodeDisplayAs}
        date={date}
        disableAvailableTime
      />
    }
    interactive
    onShow={(instance) => {
      hideAll({ exclude: instance });
    }}
  >
    {children}
  </Tooltip>
);
