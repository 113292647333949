import { BookingsForUpdatePayload } from "PFCore/services/bookings_bulk_operation";

import { SelectionType } from "./use_bookings_for_update";

const ROOT_KEY = "bulk_operation";

export const bulkOperationKeys = {
  forUpdate: (selectionType: SelectionType, payload: BookingsForUpdatePayload) => [
    ROOT_KEY,
    "for_update",
    selectionType,
    payload
  ],
  forUpdateInvalidate: (selectionType: SelectionType) => [ROOT_KEY, "for_update", selectionType]
};
