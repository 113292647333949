import classNames from "classnames";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useGrowl } from "PFApp/use_growl";
import { getProfileName } from "PFCore/helpers/profile";
import { useActivityFeedbackCreate } from "PFCore/hooks/queries/activity/use_activity_feedback_create";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { ActivityParticipant, FeedbackRoundInfo, ParticipatedRole } from "PFTypes/activity_participant";
import { useTranslation } from "react-i18next";

import { useFeedbackContext } from "./feedback_context_provider";
import { FeedbackForm } from "./form/feedback_form";
import css from "./form/feedback_form.module.scss";
import { FeedbackFormData } from "./form/feedback_form_context_provider";
import { useFeedbackCreateBody } from "./hooks/use_feedback_create_body";
import { useFeedbackDefaultData } from "./hooks/use_feedback_default_data";
import { useSavedFeedbackForms } from "./hooks/use_saved_feedback_forms";

type ManagerFeedbackFormProps = {
  activeFeedbackRound: FeedbackRoundInfo | null;
  selectedParticipant: ActivityParticipant;
  selectedRole: ParticipatedRole;
  onSubmit: (data: FeedbackFormData) => void;
};

export const ManagerFeedbackForm = ({
  activeFeedbackRound,
  selectedParticipant,
  selectedRole,
  onSubmit
}: ManagerFeedbackFormProps): JSX.Element | null => {
  const { task } = useActivityPageContext();
  const { t } = useTranslation("activities", { keyPrefix: "show.feedback" });
  const growl = useGrowl();
  const growlError = useErrorsGrowl();

  const { setCurrentFormData } = useFeedbackContext();
  const generateCreateBody = useFeedbackCreateBody();
  const { formDefaultData, isReadOnly, provider } = useFeedbackDefaultData(activeFeedbackRound, selectedRole);
  const { participantSavedForm } = useSavedFeedbackForms();

  const { create: sendFeedback } = useActivityFeedbackCreate(task?.id, {
    onSuccess: () => {
      growl({
        kind: "success",
        message: t("feedbackSent")
      });
    },
    onError: ({ response }) => growlError(response)
  });

  if (!task) {
    return null;
  }

  const fullName = getProfileName(selectedParticipant);

  const handleSubmit = (data: FeedbackFormData) => {
    const payload = generateCreateBody(data, selectedRole.id, task?.id, selectedParticipant.id);
    sendFeedback(payload);
    onSubmit(data);
  };

  const handleUpdate = (data: FeedbackFormData) => {
    if (!activeFeedbackRound) {
      return;
    }
    setCurrentFormData(data);
  };

  return (
    <>
      <div>
        {t("feedbackDescription", {
          profileName: fullName,
          roleName: selectedRole.name,
          engagementName: task?.name
        })}
      </div>
      <FeedbackForm
        readOnly={isReadOnly}
        defaultData={participantSavedForm || formDefaultData}
        onSubmit={handleSubmit}
        onUpdate={handleUpdate}
        footerContent={
          <div className={classNames(css.shareInfoContainer, css.label)}>
            {isReadOnly ? t("feedbackSharedBy", { profileName: getProfileName(provider) }) : t("shareInfo")}
          </div>
        }
      />
    </>
  );
};
