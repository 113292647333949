import { Button } from "PFComponents/button";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "../reassign_side_panel.module.scss";

type ReassignSidePanelFooterProps = {
  submitDisabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const ReassignSidePanelFooter = ({
  submitDisabled,
  onClose,
  onSubmit
}: ReassignSidePanelFooterProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  return (
    <div className={css.footer}>
      <Button kind="secondary" onClick={onClose}>
        {t("translation:cancel")}
      </Button>
      <Button kind="landing-blue" onClick={onSubmit} disabled={submitDisabled}>
        {t("bookingModule:bookings.reassign.reassignAction")}
      </Button>
    </div>
  );
};
