import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useBookingActivity } from "PFCore/hooks/queries/activity/use_booking_activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity, FeatureFlag } from "PFTypes";
import { createContext, ReactNode, useContext, useMemo } from "react";

type BookingActivityContext = {
  id?: number;
  canEdit: boolean;
  isLoading: boolean;
  activity?: Activity;
  parentActivity?: Activity;
};

const BookingActivityContext = createContext({} as BookingActivityContext);

const BookingActivityContextProvider = ({ id, children }: { id?: number; children: ReactNode }) => {
  const { data: currentProfile } = useCurrentProfile();

  const { data: activity, isFetching: activityIsFetching } = useBookingActivity(id, { enabled: !!id }); // eslint-disable-line eqeqeq
  const { data: parentActivity, isFetching: parentActivityIsFetching } = useBookingActivity(
    activity?.parent_activity_id,
    { enabled: activity?.parent_activity_id != null } // eslint-disable-line eqeqeq
  );

  const isEnabled = useIsFeatureEnabled();

  const canCreateRoles = useMemo(() => {
    const roleTemplate = currentProfile?.templates?.find((item) => item.key === "role");
    return roleTemplate?.create_permitted && !roleTemplate?.hidden;
  }, [currentProfile?.templates]);

  const canEdit = (canCreateRoles ?? false) && isEnabled(FeatureFlag.BookingModule) && !!activity?.coowned;

  const value = {
    id,
    activity,
    parentActivity,
    canEdit,
    isLoading: activityIsFetching || parentActivityIsFetching
  };
  return <BookingActivityContext.Provider value={value}>{children}</BookingActivityContext.Provider>;
};

export const useBookingActivityContext = () => useContext(BookingActivityContext);

export default BookingActivityContextProvider;
