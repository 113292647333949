import { Profile } from "PFTypes";

import { HistoryEntryDataPlaceholder } from "./history_entry_data_placeholder";
import { HistoryEntryProfile } from "./history_entry_profile";
import { HistoryEntryRow } from "./history_entry_row";

export type HistoryEventSubjectProps = {
  profile: Profile | null | undefined;
  badge?: string;
  isLoading?: boolean;
};

export const HistoryEntrySubject = ({ profile, badge, isLoading }: HistoryEventSubjectProps) => (
  <HistoryEntryRow badge={badge}>
    {!isLoading && profile && <HistoryEntryProfile profile={profile} />}
    {isLoading && <HistoryEntryDataPlaceholder height="40px" />}
  </HistoryEntryRow>
);
