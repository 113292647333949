import css from "./side_panel_footer.module.scss";

type BorderShadowProps = {
  hasBorderShadow: boolean;
};

export const BorderShadow = ({ hasBorderShadow }: BorderShadowProps) => (
  <div
    style={{
      opacity: hasBorderShadow ? 1 : 0
    }}
    className={css.borderShadow}
  />
);
