import { isNumber, some } from "lodash";
import ActivityPageContext from "PFApp/activities/show/activity_page_context";
import ProfileCardConnected from "PFApp/components/profile_card_connected";
import { ProfileItem } from "PFApp/components/profile_item";
import { ActionIcon } from "PFComponents/action_icon";
import { LoadingDots } from "PFComponents/loading_dots";
import AutoSelect from "PFComponents/select/autoselect";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { useActivityRankedShortlists } from "PFCore/hooks/queries/shortlists/use_activity_ranked_shortlists";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { ProfileBasicInformation } from "PFTypes";
import { Id } from "PFTypes/id";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import css from "./candidate_select.module.scss";

type CandidateSelectProps = {
  candidateId: number;
  selectedProfileIds: Id[];
  onCandidateChange: (value: number | null) => void;
};

export const CandidateSelect = ({
  candidateId,
  selectedProfileIds,
  onCandidateChange
}: CandidateSelectProps) => {
  const { task } = useContext(ActivityPageContext);
  const { t } = useTranslation("activities", { keyPrefix: "show.vacancies" });
  const isCandidateSelected = isNumber(candidateId);

  const {
    data: rankedShortlists,
    isFetched: isRankedShortlistsFetched,
    isFetching: isFetchingRankedShortlists
  } = useActivityRankedShortlists({
    activityId: task!.id,
    profileIds: [candidateId],
    options: {
      enabled: isCandidateSelected
    }
  });
  const rankedProfile = rankedShortlists?.entries?.find(({ id }) => id === candidateId);

  const { data: profile, isFetching: isFetchingProfile } = useProfile(candidateId, {
    enabled: isRankedShortlistsFetched && !rankedProfile && isCandidateSelected
  });

  const isProfileAvailable = !!rankedProfile || !!profile;
  const isFetching = !isProfileAvailable && (isFetchingRankedShortlists || isFetchingProfile);

  return (
    <>
      {!isCandidateSelected && (
        <AutoSelect
          label={t("candidateNameOrEmail")}
          query={(term?: string) => fetchProfilesOptions({ term })}
          handleChange={(profiles: ProfileBasicInformation[]) => {
            onCandidateChange(profiles[0].id!);
          }}
          formatOption={(item: ProfileBasicInformation) => ({
            id: item.id,
            displayElement: <ProfileItem profile={item} />,
            item,
            disabled: some(selectedProfileIds, (id) => id === item.id)
          })}
          multi={false}
          closeOnChange
        />
      )}
      {isFetching && <LoadingDots circlesEnabled circleSize="xs" className={css.loader} />}
      {isCandidateSelected && isProfileAvailable && (
        <ProfileCardConnected
          className={css.profileCard}
          // @ts-ignore
          profileResponse={rankedProfile || (profile && { profile }) || {}}
          headerActions={<ActionIcon size="sm" name="cross" onClick={() => onCandidateChange(null)} />}
          embeded
        />
      )}
    </>
  );
};
