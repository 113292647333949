import { useActivityPageContext } from "../activity_page_context";

type UseActiveTabReturn = {
  isOnMatchesTab: boolean;
};

export const useActiveTab = (): UseActiveTabReturn => {
  const { tabActiveId } = useActivityPageContext();

  const isOnMatchesTab = tabActiveId === "matches";

  return {
    isOnMatchesTab
  };
};
