import isNil from "lodash/isNil";
import { BookingType } from "PFApp/booking/components/booking_form/booking_form_provider";
import { BookingFormMode } from "PFApp/booking/components/booking_form/use_booking_form";
import { OnSuccessData } from "PFApp/booking/components/booking_form/use_handle_submit/use_handle_submit";
import { LoadingDots } from "PFComponents/loading_dots";
import SidePanel from "PFComponents/side_panel/side_panel";
import { getProfileName } from "PFCore/helpers/profile";
import { useBookingInvalidate } from "PFCore/hooks/queries";
import { Booking } from "PFTypes";
import React from "react";

import { DetailsPanelComponentProps, DetailsSidePanelProps } from "../../../../components/details_panel";
import BookingActivityContextProvider from "../../../providers/booking_activity_context_provider";
import { BookingDetailContent } from "./booking_detail_content";
import { BookingDetailHeader } from "./booking_detail_header";
import {
  BookingDetailSidePanelContextProvider,
  useBookingDetailSidePanelContext
} from "./booking_detail_side_panel_context";
import Buttons from "./buttons";
import { useButtonsPermission } from "./buttons/use_buttons_permission";

type BookingDetailSidePanelProps = DetailsSidePanelProps & DetailsPanelComponentProps;

const BookingDetailSidePanelContent = ({
  isOnTop,
  data,
  onDeleted,
  onEdited,
  show,
  onClose,
  zIndex,
  goToDate,
  checkCanGoToDate
}: BookingDetailSidePanelProps) => {
  const {
    profile,
    isInitialBookingLoading,
    isSiblingBookingsFetching,
    siblingBookings,
    currentSiblingBookingIndex,
    invalidateBookingSiblings
  } = useBookingDetailSidePanelContext();
  const currentSiblingBooking = !isNil(currentSiblingBookingIndex)
    ? siblingBookings?.[currentSiblingBookingIndex]
    : undefined;
  const { invalidate: invalidateBooking } = useBookingInvalidate();

  const { canSeeActionButtons } = useButtonsPermission({
    bookingEndDate: currentSiblingBooking?.end_date,
    isEditPermitted: data?.isEditPermitted
  });

  const handleEdit = async ({ mode, bookingType }: OnSuccessData) => {
    if (mode === BookingFormMode.Edit && bookingType === BookingType.Repeated) {
      onClose();
    }
    await invalidateBooking(currentSiblingBooking?.id);
    invalidateBookingSiblings();
    onEdited && onEdited();
  };

  const handleDelete = async (booking: Booking) => {
    invalidateBookingSiblings();
    if (onDeleted) {
      await onDeleted(booking);
    }
  };

  const title =
    !currentSiblingBooking || isInitialBookingLoading || isSiblingBookingsFetching ? (
      <LoadingDots />
    ) : (
      <BookingDetailHeader title={currentSiblingBooking?.title} />
    );

  return (
    <BookingActivityContextProvider id={currentSiblingBooking?.activity_id ?? undefined}>
      <SidePanel
        show={show}
        zIndex={zIndex}
        isSidePanelClosing={data.isClosing}
        onClose={onClose}
        title={title}
        isOnTop={isOnTop}
        footerRenderer={() =>
          canSeeActionButtons ? (
            <Buttons profileFullName={getProfileName(profile)} onEdit={handleEdit} onDeleted={handleDelete} />
          ) : undefined
        }
      >
        <BookingDetailContent
          booking={currentSiblingBooking}
          profile={profile}
          loading={isInitialBookingLoading}
          goToDate={goToDate}
          checkCanGoToDate={checkCanGoToDate}
        />
      </SidePanel>
    </BookingActivityContextProvider>
  );
};

export const BookingDetailSidePanel = React.memo((props: BookingDetailSidePanelProps) => (
  <BookingDetailSidePanelContextProvider bookingId={props.data?.id} enabled={props.show}>
    <BookingDetailSidePanelContent {...props} />
  </BookingDetailSidePanelContextProvider>
));

BookingDetailSidePanel.displayName = "BookingDetailSidePanel";
