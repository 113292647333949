const displayShortlistReasons = (reasons = {}) => {
  const toReturn = reasons.list ? [...reasons.list] : [];

  if (reasons.other) {
    toReturn.push(reasons.other);
  }

  return toReturn.join(", ");
};

export default displayShortlistReasons;
