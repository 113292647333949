import { ReactNode } from "react";

export type FormatMinMaxFunction = (value: number) => ReactNode;

export type SliderAttributes = {
  min: number;
  max: number;
  step: number;
  title: string;
  multiRange: boolean;
  unit?: string;
  showSteps?: boolean;
  showMinMax?: boolean;
  disabled?: boolean;
  instantOnChange?: boolean;
  formatMinMax: FormatMinMaxFunction;
};

type GetLeftOffset = {
  value: number;
  min: number;
  max: number;
  inputWidth: number;
};

const THUMB_WIDTH = 24;

export const getLeftOffset = ({ value, min, max, inputWidth }: GetLeftOffset) =>
  // https://stackoverflow.com/a/61665977
  ((value - min) / (max - min)) * (inputWidth - THUMB_WIDTH) + THUMB_WIDTH / 2;
