import classNames from "classnames";
import { HTMLAttributes } from "react";

import css from "./divs.module.scss";

type FlexDivProps = React.PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    direction?: "row" | "column";
  }
>;

export const FlexDiv = ({ children, className, direction = "row", ...props }: FlexDivProps) => (
  <div
    className={classNames(className, css.flex, {
      [css.row]: direction === "row",
      [css.column]: direction === "column"
    })}
    {...props}
  >
    {children}
  </div>
);
