import { createContext, useContext } from "react";

type ProfileDetailContext = {
  onBookingClick?: (name: string) => void;
};

type ProviderProps = ProfileDetailContext;

const ProfileDetailContext = createContext({} as ProfileDetailContext);

const ProfileDetailContextProvider = ({
  onBookingClick,
  children
}: React.PropsWithChildren<ProviderProps>) => (
  <ProfileDetailContext.Provider value={{ onBookingClick }}>{children}</ProfileDetailContext.Provider>
);

export default ProfileDetailContextProvider;

export const useProfileDetailContext = () => useContext(ProfileDetailContext);
