import { Deferred } from "jquery";

const lifetime = 10; // 10.seconds
const simple_prefix = "simple_storage_locker";

const getSimpleKey = (key) => `${simple_prefix}:${key}`;
const isLocked = (key) => !!storage.getItem(key);
const lock = (key) => {
  window.storage.setItem(key, true, { expiresIn: lifetime });
};

const triggerUnlockEvent = (eventName) => {
  const event = new Event(eventName);
  window.dispatchEvent(event);
};

const listenToUnlockEvent = (eventName, callback) => {
  window.addEventListener(eventName, callback);
};

const removeUnlockEvent = (eventName, callback) => {
  window.removeEventListener(eventName, callback);
};

const getLockSimple = (simpleKey) => {
  const deferred = Deferred();

  const resolve = () => {
    deferred.resolve();
    return removeUnlockEvent(`${simpleKey}:unlock_failure`, reject);
  };
  const reject = () => {
    deferred.reject();
    return removeUnlockEvent(`${simpleKey}:unlock`, resolve);
  };

  if (isLocked(simpleKey)) {
    listenToUnlockEvent(`${simpleKey}:unlock`, resolve);
    listenToUnlockEvent(`${simpleKey}:unlock_failure`, reject);
  } else {
    deferred.resolve();
  }
  return deferred.promise();
};

export const lockSimple = (key, perform_callback) => {
  const simpleKey = getSimpleKey(key);

  if (!isLocked(simpleKey)) {
    lock(simpleKey);

    return Promise.resolve(perform_callback())
      .then(() => triggerUnlockEvent(`${simpleKey}:unlock`))
      .catch(() => triggerUnlockEvent(`${simpleKey}:unlock_failure`))
      .finally(() => window.storage.removeItem(key));
  }
  return getLockSimple(simpleKey);
};
