import { useQuery } from "@tanstack/react-query";

import { fetchUser } from "../../../services/admin/users/fetch_user";
import { adminKeys } from "./query_keys";

export const useUser = (id: string) => {
  const queryKey = adminKeys.user(id);

  return useQuery(queryKey, () => fetchUser(id));
};
