import { useSession } from "PFApp/use_session";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { ApiRoute } from "PFCore/utilities/routes";
import Pusher from "pusher-js";
import { useEffect } from "react";

import { useGlobalEvents } from "./use_global_events";

export const usePusher = () => {
  const { isSignedIn } = useCurrentProfile();
  const { data: currentAccount } = useCurrentAccount();
  const session = useSession();

  useEffect(() => {
    if (PF.config.environment !== "production") {
      Pusher.log = (msg) => console.log(msg);
    }
    // @ts-ignore
    Pusher.host = PF.config.pusher_host;

    const token = session.getAccessToken();

    if (!token || !currentAccount?.full_domain) {
      return;
    }

    const pusher = new Pusher(PF.config.pusher_key || "", {
      authEndpoint: ApiRoute("/api/pusher/auth"),
      cluster: PF.config.pusher_cluster || "eu",
      auth: {
        headers: {
          ACCOUNT: currentAccount.full_domain,
          PROFINDAAPITOKEN: token,
          ACCEPT: "application/vnd.profinda+json;version=1"
        }
      }
    });

    // @ts-ignore
    window.newPusher = pusher;
  }, [currentAccount.full_domain, isSignedIn]);

  useGlobalEvents();

  // @ts-ignore
  return window.newPusher;
};
