import isFirefox from "./is_firefox";
import isSafari from "./is_safari";

let supportsSmooth;
const testSupportsSmoothScroll = () => {
  try {
    const div = document.createElement("div");
    div.scrollTo({
      top: 0,
      get behavior() {
        supportsSmooth = true;
        return "smooth";
      }
    });
  } catch (err) {
    supportsSmooth = false;
  }
};

export default (element, left, top) => {
  if (supportsSmooth === undefined) {
    testSupportsSmoothScroll();
  }

  if (!element) {
    return;
  } // just in case;

  const elementStyleHasOverflowHidden = getComputedStyle(
    element.window === window ? element.document.body : element
  ).overflow.includes("hidden");

  /*
    there's a bug in WebKit which causes smooth scroll on elements which have overflow hidden to not work
    https://bugs.webkit.org/show_bug.cgi?id=238497#c18
    the same seems true for firefox
   */
  if (supportsSmooth && !((isSafari() || isFirefox()) && elementStyleHasOverflowHidden)) {
    element.scrollTo({ behavior: "smooth", left, top });
  } else {
    element.scrollTo(left, top);
    // ie or safari most likely -> no animation
  }
};
