import { QueryKey, useQuery } from "@tanstack/react-query";
import { fetchInterestQuestionnaires, InterestQuestionnairesResponse } from "PFCore/services/common";
import { QueryOptions } from "PFTypes/request_options";

export const INTEREST_QUESTIONNAIRES_KEY: QueryKey = ["interestQuestionnaires"];

export const useInterestQuestionnaires = (options: QueryOptions<InterestQuestionnairesResponse> = {}) =>
  useQuery<InterestQuestionnairesResponse>({
    queryFn: fetchInterestQuestionnaires,
    queryKey: INTEREST_QUESTIONNAIRES_KEY,
    ...options
  });
