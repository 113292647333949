import { toString } from "lodash";
import { Meta, StandardFilter } from "PFTypes";

type GetTermFilterDataReturn = {
  termFilter?: StandardFilter;
  termValue: string;
  isTermFilterAvailable: boolean;
};

export const getTermFilterData = (meta?: Meta): GetTermFilterDataReturn => {
  const termFilter = meta?.filters?.fields?.term;
  const termValue = toString(termFilter?.value) || "";
  const isTermFilterAvailable = !!termFilter;

  return { termFilter, termValue, isTermFilterAvailable };
};
