import { TimeRuleRange } from "PFTypes/booking";

import { LoadEstimationData } from "../../hooks/use_load_estimation";
import { AvailabilityWithHours } from "./availability_with_hours";
import css from "./time_rule.module.scss";

type TimeRuleProps = {
  range: TimeRuleRange;
  onChange: (range: TimeRuleRange) => void;
  isFilter: boolean;
  minDate?: { start: string; end?: string };
  errors?: Record<string, string>;
  portalRef?: React.RefObject<HTMLDivElement>;
  loadData: LoadEstimationData;
};

export const TimeRule = ({
  range,
  onChange,
  isFilter,
  minDate,
  errors,
  portalRef,
  loadData
}: TimeRuleProps) => {
  if (!range.day_of_week) {
    return null;
  }

  return (
    <div className={css.item}>
      <AvailabilityWithHours
        range={range}
        onChange={onChange}
        isFilter={isFilter}
        minDate={minDate}
        errors={errors}
        portalRef={portalRef}
        loadData={loadData}
      />
    </div>
  );
};
