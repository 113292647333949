import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createSavedFilter,
  SavedFilterCreateData,
  SavedFiltersResponse
} from "PFCore/services/saved_filters";
import { MutationOptions } from "PFTypes/request_options";
import { PageTarget, SavedFilter } from "PFTypes/saved_filters";

import { savedFiltersKeys } from "./query_keys";

export const useSavedFiltersCreate = (
  target: PageTarget,
  { onSuccess, ...queryParams }: MutationOptions<SavedFilterCreateData, SavedFilter> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: SavedFilterCreateData) => createSavedFilter(payload),
    onSuccess: (...args) => {
      const newSavedFilter = args[0];
      queryClient.setQueryData(
        savedFiltersKeys.target(target),
        (savedFilters: SavedFiltersResponse): SavedFiltersResponse => [...savedFilters, newSavedFilter]
      );

      onSuccess?.(...args);
    },
    ...queryParams
  });
};
