import ReviewCustomField from "PFApp/onboarding/parts/review_custom_field";
import PositionPeriodPicker from "PFApp/profiles/edit/fields/position_period_picker";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import Toggle from "PFComponents/toggle/toggle";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./review_position.module.scss";

const errorToString = (error) => (Array.isArray(error) ? error[0] : error) || "";

const ReviewPosition = ({ position, handleChange, errors, style }) => {
  const { formatDatabaseDateTime, formatISODate } = useDateFormatter();
  const { data: currentAccount } = useCurrentAccount();

  const { t } = useTranslation("onboarding");
  const [startDate, setStartDate] = useState(position.start_date ? formatISODate(position.start_date) : null);
  const [endDate, setEndDate] = useState(position.end_date ? formatISODate(position.end_date) : null);
  const [duration, setDuration] = useState(position.duration);

  return (
    <div className={css.form} style={style} data-qa-id="Onboarding.ReviewPosition">
      <InputFieldSet
        error={errorToString(position.internal ? errors?.name : errors.title)}
        qaId="ReviewPosition.title"
        label={t("positionInputs.title")}
        value={position.title}
        onChange={(title) => handleChange({ ...position, title })}
      />

      <InputFieldSet
        error={errorToString(errors?.subtitle)}
        qaId="ReviewPosition.company"
        label={t("positionInputs.company")}
        value={position.subtitle}
        onChange={(subtitle) => handleChange({ ...position, subtitle })}
      />

      <InputFieldSet
        error={errorToString(position.internal ? errors?.description : errors?.summary)}
        qaId="ReviewPosition.description"
        inputType="textarea"
        label={t("positionInputs.description")}
        value={position.summary}
        onChange={(summary) => handleChange({ ...position, summary })}
      />

      {(position.custom_fields || []).map((customField) => (
        <ReviewCustomField
          error={errorToString(errors.custom_fields?.[customField.type.name])}
          key={customField.type.name}
          customField={customField}
          handleChange={(values) => {
            customField.values = values;
            handleChange({
              ...position,
              custom_fields: [...position.custom_fields]
            });
          }}
        />
      ))}

      <Toggle
        label={t("positionInputs.internalPosition")}
        checked={!!position.internal}
        onChange={(internal) => handleChange({ ...position, internal, current: false, main: false })}
        inline
      />

      <PositionPeriodPicker
        currentPosition={position.current}
        error={errorToString(errors?.start_date || errors?.end_date || errors?.["date_range/start_date"])}
        startDate={startDate}
        endDate={endDate}
        duration={duration}
        handleChange={(start, end, duration, showDuration) => {
          setDuration(duration);
          setStartDate(start);
          setEndDate(end);
          handleChange({
            ...position,
            duration: showDuration ? duration : null,
            start_date: showDuration ? null : formatDatabaseDateTime(start),
            end_date: showDuration ? null : formatDatabaseDateTime(end || start)
          });
        }}
        handleToggleClick={(value) => {
          handleChange({
            ...position,
            current: value,
            main: position.current ? false : position.main // non-current cannot be main
          });
        }}
        letDuration
        letCurrentPosition
      />
      {currentAccount.cv_parser_config?.current_position && position.current && (
        <Toggle
          label={t("positionInputs.mainPosition")}
          checked={!!position.main}
          onChange={(main) => handleChange({ ...position, main })}
          inline
        />
      )}
    </div>
  );
};

ReviewPosition.propTypes = {
  position: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    summary: PropTypes.string,
    custom_fields: PropTypes.array,
    current: PropTypes.bool,
    main: PropTypes.bool,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    duration: PropTypes.number,
    internal: PropTypes.bool
  }),
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  style: PropTypes.object
};

export default ReviewPosition;
