import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { ApiRoute, UiRoute } from "PFCore/utilities/routes";

export const MsTeamsAuthSaml = () => {
  const { data: currentAccount } = useCurrentAccount();

  const parsedParams = useQueryParams();
  const accountName = parsedParams.get("account");
  const account = accountName || currentAccount.full_domain;
  const application = parsedParams.get("application") || "tab";
  const oauthRedirectMethod = parsedParams.get("oauthRedirectMethod");
  const authId = parsedParams.get("authId");

  const samlParams = `msTeams=true&account=${account}&application=${application}&`;
  const appDeepLinkParams = `oauthRedirectMethod=${oauthRedirectMethod}&authId=${authId}`;

  window.location = ApiRoute(
    `/api/oauth/authorize?${$.param({
      account,
      client_id: PF.config.client_id,
      grant_type: "saml",
      redirect_uri: UiRoute(`/?${samlParams}&${appDeepLinkParams}`)
    })}`
  );

  return null;
};
