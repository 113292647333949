import { PaginatedCollection } from "PFTypes";
import { InfiniteQueryOptions } from "PFTypes/request_options";

export const getNextPageParam: InfiniteQueryOptions<PaginatedCollection<any>>["getNextPageParam"] = (
  lastPageResponse
) => {
  const { meta } = lastPageResponse;
  if (meta.page < meta.totalPages) {
    return meta.page + 1;
  }
};
