import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { Button } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type ButtonsProps = {
  setAllocateManually: Dispatch<SetStateAction<boolean>>;
};

export const Buttons = ({ setAllocateManually }: ButtonsProps) => {
  const { t } = useTranslation();

  const { activity, parentActivity } = useBookingActivityContext();
  const vacanciesTotal = activity?.vacancies?.total ?? 0;
  const shortlistsTotal = activity?.shortlists?.total ?? 0;
  const isCompleted = activity?.state === "complete" || parentActivity?.state === "complete";

  const renderButton = () => (
    <div className={css.buttons}>
      <Button
        text={t("allocateManually")}
        className={css.wideButton}
        kind="secondary"
        disabled={!vacanciesTotal || shortlistsTotal === 0 || isCompleted}
        onClick={() => setAllocateManually((value) => !value)}
      />
    </div>
  );

  return !isCompleted ? (
    renderButton()
  ) : (
    <Tooltip content={t("bookingNotAvailable")}>{renderButton()}</Tooltip>
  );
};
