import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import css from "./header_section.module.scss";

const HeaderSection = () => {
  const { t } = useTranslation("activities");
  const { isNew } = useActivityEditPageFlags();
  const { activity, template } = useActivityEditPageContext();

  const title = t(`edit.sections.header.${isNew ? "create" : "edit"}`, {
    templateName: (template.name || "").toLowerCase()
  });
  const showLink = activity.id && `/activities/${activity.id}`;

  return (
    <div className={css.headerContainer}>
      <div className={css.mainHeader}>
        <div className={css.headerTitle}>{title}</div>
        {showLink && (
          <Link className={css.mainHeaderSmallLink} to={showLink}>
            <span>{t("edit.sections.show")}</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default HeaderSection;
