export enum BinaryAnswer {
  Yes = "YES",
  No = "NO"
}

export interface FormValues {
  question1?: BinaryAnswer;
  question2?: BinaryAnswer;
  question3?: BinaryAnswer;
  statementOfQualification?: string;
}

export interface QuestionData {
  id: number;
  name: keyof FormValues;
  label: string;
}
