import { LandingLayoutWithLandingDescription } from "PFApp/components/landing_layout";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useForgotPassword } from "PFCore/hooks/queries/auth/use_forgot_password";
import { FeatureFlag } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");

  const { data: currentAccount } = useCurrentAccount();
  const isEnabled = useIsFeatureEnabled();
  const { t } = useTranslation();

  const { mutate: forgotPassword, isLoading, data } = useForgotPassword();

  const submit = (event) => {
    event.preventDefault(); // prevent form submit navigation

    forgotPassword({
      email,
      accountId: currentAccount.id
    });
  };

  const isPasswordSignInEnabled = isEnabled(FeatureFlag.PasswordSignin);
  const isRequestAccessEnabled = isEnabled(FeatureFlag.RequestAccess);

  return (
    <LandingLayoutWithLandingDescription>
      <strong data-qa-id="ForgotPasswordPage.header">{t("forgotPassword.resetPassword")}</strong>

      {data?.message && (
        <div style={{ marginBottom: 20 }} data-qa-id="ForgotPasswordPage.successMessage">
          {data?.message}
        </div>
      )}

      <form onSubmit={submit}>
        <InputFieldSet
          kind="white"
          qaId="ForgotPasswordPage.email"
          label={t("forgotPassword.emailAddress")}
          style={{ marginBottom: 20 }}
          onChange={setEmail}
        />

        <Button
          qaId="ForgotPasswordPage.submit"
          style={{ width: "100%", marginBottom: 20 }}
          disabled={email.trim() === ""}
        >
          {isLoading ? <LoadingDots /> : t("forgotPassword.sendInstructions")}
        </Button>
      </form>

      <div style={{ marginBottom: 20 }}>
        <Link to="/users/signin" data-qa-id={"ForgotPasswordPage.signInLink"}>
          {t("forgotPassword.signIn")}
        </Link>
      </div>

      {isPasswordSignInEnabled && (
        <>
          <div>
            <span>{t("forgotPassword.dontHaveAccount")}</span>
          </div>
          <div>
            <Link
              data-qa-id={
                isRequestAccessEnabled ? "ForgotPasswordPage.requestAccess" : "ForgotPasswordPage.signUp"
              }
              to={isRequestAccessEnabled ? "/request_access" : "/users/signup"}
            >
              {t("forgotPassword.signUp")}
            </Link>
          </div>
        </>
      )}
    </LandingLayoutWithLandingDescription>
  );
};

export default ForgotPasswordPage;
