import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import css from "./footer.module.scss";

interface Props {
  isEditMode: boolean;
  isFormValid: boolean;
  onClose: () => void;
  onEdit: () => void;
  onReview: () => void;
}

const Footer = ({ isEditMode, isFormValid, onClose, onEdit, onReview }: Props): JSX.Element => {
  const { t } = useTranslation(["activities", "translation"]);

  return (
    <footer className={css.footer}>
      <Button kind="secondary" type="button" onClick={isEditMode ? onClose : onEdit}>
        {t(isEditMode ? "translation:cancel" : "translation:edit")}
      </Button>
      <Button
        key={`${isEditMode ? "review" : "submit"}-button`}
        type={isEditMode ? "button" : "submit"}
        disabled={!isFormValid}
        onClick={isEditMode ? onReview : undefined}
      >
        {t(isEditMode ? "activities:questionsModal.review" : "translation:submit")}
      </Button>
    </footer>
  );
};

export default Footer;
