import {
  PlainSearchHighlight,
  ProfilePositionHighlight,
  ProfileSearchHighlightAttribute,
  SearchHighlight,
  SearchHighlightAttribute
} from "PFTypes";
import React from "react";

import { useAttributeName } from "../hooks/use_attribute_name";
import { SearchHighlightPlainRow } from "./search_highlight_plain_row";
import { SearchHighlightProfilePositionRow } from "./search_highlight_profile_position_row";

type HighlightRowProps = {
  attribute: SearchHighlightAttribute;
  highlight: SearchHighlight | undefined;
};

export const HighlightRow = ({ attribute, highlight }: HighlightRowProps) => {
  const attributeName = useAttributeName(attribute);

  if (!highlight) {
    return null;
  }

  if (
    attribute === ProfileSearchHighlightAttribute.MainPosition ||
    attribute === ProfileSearchHighlightAttribute.Positions
  ) {
    return (
      <SearchHighlightProfilePositionRow
        attribute={attribute}
        values={highlight as ProfilePositionHighlight}
      />
    );
  }

  return (
    <SearchHighlightPlainRow
      attributeDisplayName={attributeName}
      values={highlight as PlainSearchHighlight}
    />
  );
};
