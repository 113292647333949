import { isNil } from "lodash";
import { useBookingModuleContext } from "PFApp/booking/parts/providers/booking_module_context_provider";
import { CalendarMode, CalendarModeInitialData, CalendarView } from "PFApp/booking/types";
import useStorage from "PFCore/helpers/use_storage";
import { useThresholds } from "PFCore/hooks/queries/bookings/thresholds/use_thresholds";
import { WorkforceResponse } from "PFCore/hooks/queries/bookings/workforce/use_workforce_entries";
import { useWorkforceInvalidate } from "PFCore/hooks/queries/bookings/workforce/use_workforce_invalidate";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile";
import { CalendarGranularity } from "PFCore/hooks/queries/profile/use_profiles_availabilities";
import { useCallback, useEffect, useState } from "react";
import { useUnmount } from "react-use";

import { BookingCalendarData, WorkforceCalendarData } from "./context/booking_overview_context.types";
import { useCalendarMetric } from "./context/use_calendar_metric";
import { useCalendarView } from "./context/use_calendar_view";
import useWorkforceData from "./use_workforce_data";
import { useWorkforcePinnedData } from "./use_workforce_pinned_data";

const GRANULARITY_STORAGE_KEY = "bm-workforce-granularity";
const PROFILE_CUSTOM_TYPES_STORAGE_KEY = "bm-workforce-custom-types";
const DEFAULT_PAGE = 1;

interface UseWorkforce {
  enabled: boolean;
  showUtilizationMetrics?: boolean;
}

export const useWorkforce = ({
  enabled,
  showUtilizationMetrics
}: UseWorkforce): BookingCalendarData<WorkforceCalendarData, WorkforceResponse> => {
  const { calendarRange } = useBookingModuleContext();

  const thresholds = useThresholds(["utilization", "overbooking"]);

  const [metric, setMetric] = useCalendarMetric(CalendarMode.Workforce, showUtilizationMetrics);
  const [view, setCalendarView] = useCalendarView(CalendarMode.Workforce);

  const [calendarGranularity, setCalendarGranularity] = useStorage<CalendarGranularity>(
    GRANULARITY_STORAGE_KEY,
    CalendarGranularity.Daily
  );
  const [profileCustomTypeIdsSelected, setProfileCustomTypeIdsSelected] = useStorage<number[]>(
    PROFILE_CUSTOM_TYPES_STORAGE_KEY,
    []
  );
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const resetPagination = useCallback(() => setPage(DEFAULT_PAGE), []);

  const { removeCache: removeWorkforceCache } = useWorkforceInvalidate();
  const { removeProfileBookingsCache } = useProfileInvalidate();
  useUnmount(() => {
    removeWorkforceCache();
    removeProfileBookingsCache();
  });

  const workforce = useWorkforceData({
    dateRange: calendarRange,
    enabled,
    page,
    resetPagination
  });
  const pinnedData = useWorkforcePinnedData({
    dateRange: calendarRange,
    enabled
  });

  const setView = useCallback(
    (view?: CalendarView, initialData?: Omit<CalendarModeInitialData, "view">) => {
      view && setCalendarView(view);
      if (!initialData) {
        return;
      }
      const { resetFilters } = workforce;
      const { filters } = initialData;
      resetFilters(filters);
    },
    [workforce, setCalendarView]
  );

  useEffect(() => {
    if (!isNil(workforce.data?.meta?.totalPages)) {
      resetPagination();
    }
  }, [workforce.data?.meta?.totalPages]);

  return {
    ...workforce,
    thresholds,
    view,
    setView,
    calendarGranularity,
    setCalendarGranularity,
    profileCustomTypeIdsSelected,
    setProfileCustomTypeIdsSelected,
    metric,
    setMetric,
    page,
    setPage,
    pinnedData
  };
};
