const uniqueHash = {};

export const uniqueId = (prefix = "") => {

  const prefixKey = encodeURI(prefix);

  uniqueHash[prefixKey] = (uniqueHash[prefixKey] || 0) + 1;

  return `${ prefixKey}__${ uniqueHash[prefixKey]}`;
};
