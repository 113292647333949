import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useGrowl } from "PFApp/use_growl";
import { getActiveActivityProfiles } from "PFCore/helpers/get_active_activity_profiles";
import { getProfileName } from "PFCore/helpers/profile";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useReviewerCreate, useReviewerDelete } from "PFCore/hooks/queries/reviewers";
import { ActivityProfile, ActivityProfileType, Profile } from "PFTypes";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import AddActivityProfiles from "../add_activity_profiles";

type AddReviewersModalProps = {
  handleClose: () => void;
};

const AddReviewersModal = ({ handleClose }: AddReviewersModalProps) => {
  const { task, isUnbiasedEnabled } = useActivityPageContext();
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.review" });
  const { getFirstError } = useResponseErrors();
  const taskReviewers = getActiveActivityProfiles(task?.reviewers || []);

  const { mutate: createReviewer } = useReviewerCreate();
  const { mutate: deleteReviewer } = useReviewerDelete(task?.id || 0);

  const [newProfiles, setNewProfiles] = useState<Profile[]>([]);

  if (!task) {
    return null;
  }

  const description = (
    <div>
      <Trans i18nKey="addReviewersDescription" t={t} components={[<strong key="reviewer-strong-tag" />]} />
      {isUnbiasedEnabled && <div style={{ marginTop: 10 }}>{t("addReviewersDescriptionUnbiased")}</div>}
    </div>
  );

  const handleRemove = (activityProfile: ActivityProfile) => {
    const reviewer = taskReviewers.find(({ profile_id }) => activityProfile.profile_id === profile_id);
    if (!reviewer) {
      return null;
    }

    reviewer.id &&
      deleteReviewer(Number(reviewer.id), {
        onSuccess: () =>
          growl({
            message: t("profileRemovedReviewer", {
              profileName: getProfileName(reviewer.profile)
            }),
            kind: "success"
          })
      });
  };

  const handleSubmit = (profiles) =>
    profiles.forEach((profile) =>
      createReviewer(
        {
          profileId: profile.id,
          activityId: task.id,
          unbiased: profile.unbiased || false
        },
        {
          onSuccess: () =>
            growl({
              message: t("requestReviewSuccess", { profileName: getProfileName(profile) }),
              kind: "success"
            }),
          onError: (response) => {
            const error = getFirstError(response, {});
            growl({
              kind: "error",
              message: error || t("requestReviewError", { profileName: getProfileName(profile) })
            });
          }
        }
      )
    );

  return (
    <AddActivityProfiles
      task={task}
      onSubmit={handleSubmit}
      profiles={taskReviewers}
      onClose={handleClose}
      onRemove={handleRemove}
      submitButtonLabel={t("addAndShare")}
      title={t("addReviewer")}
      description={description}
      showUnbiasedToggle
      newProfiles={newProfiles}
      onNewProfilesChange={setNewProfiles}
      activityProfileType={ActivityProfileType.Reviewer}
    />
  );
};

export default AddReviewersModal;
