const ALLOWED_META_KEY = ["activitiesMeta", "profilesMeta", "matchesMeta"];

const mergeMetaFilters = (search, metaKey, attrs) => {
  if (!ALLOWED_META_KEY.includes(metaKey)) {
    throw new Error("invalid metaKey");
  }

  const newMeta = { filters: [], ...search[metaKey] };
  for (var key in attrs) {
    const updatedFilter = newMeta.filters.find((filter) => filter.name === key);
    const index = newMeta.filters.indexOf(updatedFilter);
    newMeta.filters[index] = { ...newMeta.filters[index], value: attrs[key] };
  }
  return { [metaKey]: newMeta };
};

export default mergeMetaFilters;
