// TODO: [PROF-4641] change this file to use camelized properties
/* eslint-disable camelcase */
import { UNDETERMINED_FUTURE } from "PFApp/constants/dates";
import { useGrowl } from "PFApp/use_growl";
import useIsBookingModulePermitted from "PFApp/use_is_booking_module_permitted";
import { Modal } from "PFComponents/modal";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useBookingDelete } from "PFCore/hooks/queries/bookings/use_booking_delete";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import DeleteBookingIcon from "PFIcons/calendar_delete.svg";
import { Booking } from "PFTypes/booking";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRefreshBookingData } from "../../hooks/use_refresh_booking_data";
import { deleteBookingTemplate } from "../../services/api";
import { BookingSelectOption } from "../booking_questions/booking_questions";
import {
  GroupBookingsQuestions,
  GroupBookingsQuestionsMode,
  GroupBookingsQuestionsOption
} from "../booking_questions/group_bookings_questions";
import { SpecificTimeBookingsQuestions } from "../booking_questions/specific_time_bookings_questions";
import { BookingDeleteAllOption } from "./delete_all_bookings_question";
import { DeleteMode } from "./delete_booking.types";
import css from "./delete_booking_modal.module.scss";
import { useDeleteBookingModalContext } from "./delete_booking_modal_context_provider";
import { RoleRelatedBookingsModalContent } from "./role_related_bookings_modal_content";
import { useResetShortlist } from "./use_reset_shortlist";

const DELETE_BOOKING_SUCCESS_ACTION_ID = "bm-delete-booking-success-action";

export type DeleteBookingModalData = {
  deleteMode: DeleteMode;
  booking: Booking;
  profileFullName: string;
  onDeleted?: (booking: Booking, deleteOptionSelected: BookingSelectOption) => Promise<void> | void;
  isSingleDayBookingTemplate?: boolean;
};

type DeleteBookingModalProps = {
  onClose?: () => void;
} & DeleteBookingModalData;

const DeleteBookingModal = ({
  deleteMode,
  booking,
  profileFullName,
  onClose,
  onDeleted,
  isSingleDayBookingTemplate
}: DeleteBookingModalProps): JSX.Element => {
  const {
    modal: { close }
  } = useDeleteBookingModalContext();

  const { isPermittedToResetShortlist, handleResetShortlist } = useResetShortlist({
    deleteMode,
    activityId: booking.activity?.id,
    profileId: booking.profile_id
  });

  const { delete: deleteBooking } = useBookingDelete(booking?.id);

  const { booking_group_id, booking_template_id, profile_id, id, activity } = booking;
  const refreshBookingData = useRefreshBookingData();
  const [deleteOptionSelected, setDeleteOptionSelected] = useState<BookingSelectOption>(
    BookingSelectOption.This
  );
  const [deleteGroupOptionSelected, setDeleteGroupOptionSelected] = useState<GroupBookingsQuestionsOption>(
    GroupBookingsQuestionsOption.GroupThis
  );
  const [deleteAllOption, setDeleteAllOption] = useState<BookingDeleteAllOption>(
    isPermittedToResetShortlist ? BookingDeleteAllOption.ReopenVacancy : BookingDeleteAllOption.KeepVacancy
  );

  useEffect(() => {
    setDeleteAllOption(
      isPermittedToResetShortlist ? BookingDeleteAllOption.ReopenVacancy : BookingDeleteAllOption.KeepVacancy
    );
  }, [isPermittedToResetShortlist]);

  const { formatISODate } = useDateFormatter();

  const isRepeated = Number.isInteger(booking_template_id);
  const isGrouped = Number.isInteger(booking_group_id);

  const growl = useGrowl();
  const { t } = useTranslation(["bookingModule", "translation"]);
  const isBookingPermitted = useIsBookingModulePermitted();
  const { getFirstError } = useResponseErrors();

  const isDeleteGroup = [GroupBookingsQuestionsOption.GroupAll].includes(deleteGroupOptionSelected);

  const handleClick = async () => {
    try {
      const isDeleteFutureOrAll = [BookingSelectOption.Future, BookingSelectOption.All].includes(
        deleteOptionSelected
      );
      const isDeleteFuture = deleteOptionSelected === BookingSelectOption.Future;

      const defaultPayload = isDeleteGroup ? { booking_group_id } : { profile_id };

      const dateRangeFuture = {
        min: formatISODate(booking.start_date),
        max: formatISODate(UNDETERMINED_FUTURE)
      };

      if (booking_template_id) {
        await deleteBookingTemplate(isDeleteGroup ? undefined : booking_template_id, {
          ...defaultPayload,
          date_range:
            deleteOptionSelected === BookingSelectOption.This
              ? { min: booking.start_date, max: booking.end_date }
              : isDeleteFuture
              ? dateRangeFuture
              : undefined
        });
      } else {
        await deleteBooking(
          {
            ...defaultPayload,
            id: isDeleteFutureOrAll || isDeleteGroup ? undefined : id,
            activity_id:
              !booking.booking_group_id && isDeleteFutureOrAll ? (activity?.id as number) : undefined,
            date_range: isDeleteFuture ? dateRangeFuture : undefined
          },
          {
            onSuccess: () => {
              handleResetShortlist(deleteOptionSelected, deleteAllOption);
            }
          }
        );
      }

      refreshBookingData({
        bookingIds: [id],
        bookingGroupId: booking_group_id,
        activityId: activity?.id,
        profileIds: [profile_id],
        refreshGroup: deleteGroupOptionSelected === GroupBookingsQuestionsOption.GroupAll,
        refreshProfile: isRepeated
      });

      growl({
        id: DELETE_BOOKING_SUCCESS_ACTION_ID,
        kind: "success",
        message: t("bookingModule:bookings.delete.success"),
        hidePreviousByTypeAndId: true,
        ttl: 5000
      });
      close();
      onDeleted && (await onDeleted(booking, deleteOptionSelected));
      onClose && onClose();
    } catch (response) {
      const error = getFirstError(response);
      growl({
        message: error || t("translation:somethingWentWrong"),
        kind: "error"
      });
    }
  };

  return (
    <Modal
      title={
        <div className={css.title}>
          <DeleteBookingIcon height={30} width={30} />
          {t("bookingModule:bookings.delete.deleteBooking")}
        </div>
      }
      labelOK={t("translation:continue")}
      onOK={handleClick}
      onClose={close}
      onCancel={close}
      kindOK="danger"
    >
      <div>
        <div className={css.body}>
          {isBookingPermitted && (isGrouped || isRepeated) && !isSingleDayBookingTemplate ? (
            <>
              {isGrouped && (
                <GroupBookingsQuestions
                  mode={GroupBookingsQuestionsMode.Delete}
                  value={deleteGroupOptionSelected}
                  onChange={setDeleteGroupOptionSelected}
                  userName={profileFullName}
                  bookingGroupId={booking_group_id!} // eslint-disable-line camelcase
                />
              )}
              {isRepeated && (
                <SpecificTimeBookingsQuestions
                  label={t("bookingModule:bookings.delete.body.question")}
                  value={deleteOptionSelected}
                  onChange={setDeleteOptionSelected}
                />
              )}
            </>
          ) : (
            <RoleRelatedBookingsModalContent
              booking={booking}
              deleteMode={deleteMode}
              roleName={activity?.name ?? booking.title}
              userName={profileFullName}
              value={deleteOptionSelected}
              onChange={setDeleteOptionSelected}
              deleteAllOption={deleteAllOption}
              onChangeDeleteAllOption={(option) => setDeleteAllOption(option)}
              isPermittedToResetShortlist={isPermittedToResetShortlist}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DeleteBookingModal;
