import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";
import { fetchMatches } from "PFCore/services/matches";
import { Match, PaginatedCollection } from "PFTypes";
import { QueryParams } from "PFTypes/query_params";
import { InfiniteQueryOptions, QueryOptions } from "PFTypes/request_options";

import { matchesKeys } from "./query_keys";

export const useMatches = (
  activityId: number | string,
  params: QueryParams,
  options: QueryOptions<PaginatedCollection<Match[]>> = {}
) => useQuery(matchesKeys.activity(activityId, params), () => fetchMatches(activityId, params), options);

export const useMatchesInfinite = (
  activityId: number | string,
  params: QueryParams,
  options: InfiniteQueryOptions<PaginatedCollection<Match[]>> = {}
) =>
  useInfiniteQuery<PaginatedCollection<Match[]>>({
    queryKey: matchesKeys.activity(activityId, params),
    getNextPageParam,
    queryFn: ({ pageParam = 1 }) => fetchMatches(activityId, { ...params, page: pageParam }),
    ...options
  });
