import { Typography } from "PFComponents/typography";

type LoadAttributeProps = {
  value: number;
};

export const LoadAttribute = ({ value }: LoadAttributeProps) => {
  const percentValue = `${(value * 100).toFixed(0)}%`;
  return (
    <Typography variant="bodyRegular" tag="span">
      {percentValue}
    </Typography>
  );
};
