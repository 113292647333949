import classNames from "classnames";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import css from "../availability_filter.module.scss";
import { hasReachedLimit } from "../helpers";

type AvailabilityLimitWrapperProps = React.PropsWithChildren<{
  scope: number;
}>;

export const AvailabilityLimitWrapper = ({ scope, children }: AvailabilityLimitWrapperProps) => {
  const { t } = useTranslation("availability");
  const hasReachedScopeLimit = hasReachedLimit(scope);

  return (
    <div
      className={classNames({
        [css.scopeLimitReached]: hasReachedScopeLimit
      })}
    >
      {children}
      {hasReachedScopeLimit && (
        <Tooltip content={<div>{t("disabledByLimit.description")}</div>}>
          <div className={css.scopeDisclaimer}>
            <Typography variant="labelRegular">{t("disabledByLimit.message")}</Typography>
            <Icon size="lg" name="info" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
