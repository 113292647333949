import classNames from "classnames";
import { useTranslation } from "react-i18next";

import css from "./user_shortlist_label.module.scss";

interface UserShortlistLabelProps {
  status?:
    | "pending"
    | "shortlisted"
    | "accepted"
    | "declined"
    | "assigned"
    | "filled"
    | "rejected"
    | "booked"
    | "invited"
    | "confirmed";
}

const useStatusLabel = (status?: UserShortlistLabelProps["status"]) => {
  const { t } = useTranslation("core");

  return status ? t(`userShortlistLabel.${status}`) : t("userShortlistLabel.notAdded");
};

export const UserShortlistLabel = ({ status }: UserShortlistLabelProps) => {
  const className = classNames(css.root, {
    [css.red]: status && ["rejected", "declined"].includes(status),
    [css.green]: status && ["filled", "booked", "confirmed"].includes(status),
    [css.blue]: !status || ["shortlisted", "accepted", "assigned", "invited"].includes(status)
  });

  const label = useStatusLabel(status);

  return <div className={className}>{label}</div>;
};
