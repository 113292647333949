import { useSession } from "PFApp/use_session";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useEffect } from "react";

const KEY = "log_in_out_state";
const IN = "in";
const OUT = "out";
const REQUEST = "getSessionStorage";
const FORWARDED = "sessionStorage";
const AUTH_KEY = "auth";

export const broadcastLogIn = () => {
  localStorage.setItem(KEY, IN);
  localStorage.removeItem(KEY);
};

export const broadcastLogOut = () => {
  localStorage.setItem(KEY, OUT);
  localStorage.removeItem(KEY);
};

const sessionContainsAuthToken = () => Object.keys(sessionStorage).find((item) => item.includes(AUTH_KEY));

export const broadcastSessionStorage = () => {
  if (sessionContainsAuthToken()) {
    localStorage.setItem(FORWARDED, JSON.stringify(sessionStorage));
    localStorage.removeItem(FORWARDED);
  }
};

export const broadcastRequestSessionStorage = () => {
  if (!sessionContainsAuthToken()) {
    localStorage.setItem(REQUEST, "true");
    localStorage.removeItem(REQUEST);
  }
};

export const useLogInOutBroadcasts = () => {
  const { isSignedIn } = useCurrentProfile();

  const { expireSession } = useSession();
  // If we have no data in sessionStorage
  // request it in case it is needed
  useEffect(() => {
    broadcastRequestSessionStorage();
  }, []);

  useEffect(() => {
    $(window).on("storage", (e) => {
      const ev = e.originalEvent;

      // If another tab requested data send it if present
      if (ev.key === REQUEST && sessionContainsAuthToken()) {
        broadcastSessionStorage();
      }

      // If another tab sent data we store it
      if (ev.key === FORWARDED) {
        const data = JSON.parse(ev.newValue);
        for (const key in data) {
          sessionStorage.setItem(key, data[key]);
        }
      }

      if (ev.key === KEY) {
        if (ev.newValue === OUT && isSignedIn) {
          expireSession();
        }

        if (ev.newValue === IN && !isSignedIn) {
          window.location.reload();
        }
      }
    });

    return () => {
      $(window).off("storage");
    };
  }, [isSignedIn]);
};
