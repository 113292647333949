import useDebounce from "PFCore/helpers/use_debounce";
import { useEffect, useState } from "react";

const DEBOUNCE_TIME = 500;

export const useDebouncedTerm = (term: string): string => {
  const [queryTerm, setQueryTerm] = useState(term);

  const debouncedChangeQueryTerm = useDebounce((newTerm: string) => {
    setQueryTerm(newTerm);
  }, DEBOUNCE_TIME);

  useEffect(() => {
    debouncedChangeQueryTerm(term);
  }, [term, debouncedChangeQueryTerm]);

  return queryTerm;
};
