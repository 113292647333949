import { LoadingDots } from "PFComponents/loading_dots";
import NoProfilesIcon from "PFIcons/no_profiles.svg";
import { useTranslation } from "react-i18next";

import PinButton from "../../../../parts/pin_button";
import InterestedColumnsHeaders from "./interested_column_headers";
import { useInterestedContext } from "./interested_context_provider";
import InterestedRows from "./interested_rows";
import css from "./interested_table.module.scss";

const InterestedTable = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.interested" });
  const {
    watchersMetadata,
    watchersQuery: { isLoading, isFetchingNextPage },
    togglePinSelected,
    pinSelected
  } = useInterestedContext();

  if ((watchersMetadata?.total === 0 || !watchersMetadata) && !isLoading) {
    return (
      <div className={css.noItems}>
        <NoProfilesIcon />
        <div className={css.msg}>{t("noInterestedUsers")}</div>
      </div>
    );
  }

  return (
    <div className={css.scrollWrapper}>
      <div className={css.pinButtonWrapper}>
        <PinButton
          onChange={togglePinSelected}
          isSelected={pinSelected}
          renderContent={(isSelected) =>
            isSelected ? t("table.tooltips.unpinSelected") : t("table.tooltips.pinSelected")
          }
        />
      </div>
      <div className={css.list}>
        <InterestedColumnsHeaders />
        <InterestedRows />
        {(isLoading || isFetchingNextPage) && (
          <LoadingDots className={css.loading} circleSize="xs" circlesEnabled />
        )}
      </div>
    </div>
  );
};

export default InterestedTable;
