import { useCallback } from "react";

import { useGrowl } from "../../../use_growl";

export const useGrowlFormError = () => {
  const growl = useGrowl();

  return useCallback(
    (message: string) =>
      growl({
        message,
        kind: "error"
      }),
    [growl]
  );
};
