import classNames from "classnames";
import Color from "color";
import BookingPill from "PFApp/components/pills/booking_pill";
import useBookingCategories from "PFCore/hooks/use_booking_categories";

import css from "./category_badge.module.scss";

type CategoryBadgeProps = { categoryId?: number; className?: string; titleClassName?: string };

const CategoryBadge = ({ categoryId, className, titleClassName }: CategoryBadgeProps) => {
  const { getBookingCategory } = useBookingCategories();
  const category = getBookingCategory(categoryId);
  const baseColor = category && Color(category?.color);

  return (
    <BookingPill
      category={category}
      style={{
        color:
          baseColor?.luminosity() === 0
            ? "#fff"
            : baseColor?.isLight()
            ? baseColor.darken(0.85).hex()
            : baseColor?.lighten(0.85).hex()
      }}
      className={classNames(css.categoryBadge, className)}
    >
      <div className={classNames(css.categoryTitle, titleClassName)}>{category?.display_as}</div>
    </BookingPill>
  );
};

export default CategoryBadge;
