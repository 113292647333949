import api from "PFCore/api";
import { BookedProfile, Collection } from "PFTypes";

export type FetchBookedProfilesPayload = {
  bookingGroupId?: number;
  activityId?: number;
  page?: number;
  perPage?: number;
};

export const fetchBookedProfiles = ({
  bookingGroupId,
  activityId,
  page,
  perPage
}: FetchBookedProfilesPayload): Promise<Collection<BookedProfile>> =>
  api({
    url: `booking_module/booked_profiles`,
    params: {
      bookingGroupId,
      activityId,
      page,
      perPage
    }
  });
