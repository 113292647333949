import safePrune from "PFCore/helpers/safe_prune";
import PropTypes from "prop-types";

const Description = ({ description, descriptionLength }) => (
  <div>{safePrune(description, descriptionLength)}</div>
);

Description.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  descriptionLength: PropTypes.number
};

Description.defaultProps = {
  descriptionLength: 140
};

export default Description;
