import { every, find, isArray, isEmpty, isFunction, isString } from "lodash";

import { useCurrentProfile } from "../hooks/queries/profile/use_current_profile";
import { CurrentProfile, PermissionRule } from "../types";

export function useIsCurrentUserPermittedTo(): (rules: any) => boolean;
export function useIsCurrentUserPermittedTo(rule: PermissionRule): boolean;
export function useIsCurrentUserPermittedTo(rule?: PermissionRule) {
  const { data: currentProfile } = useCurrentProfile();

  const isPermittedTo = useIsPermittedTo(currentProfile);

  return rule ? isPermittedTo(rule) : isPermittedTo;
}

// Usage: useIsPermittedTo(currentProfile)("feature")
export default function useIsPermittedTo(currentProfile: CurrentProfile) {
  return (rules) => {
    const isPermittedTo = (rules) => {
      if (rules === true || rules === false) {
        return rules;
      } else if (isString(rules)) {
        const group = currentProfile.permissions_group;
        const permissions = find(group ? group.permissions : null, { name: rules });
        return permissions ? !!permissions.permitted : false;
      } else if (isFunction(rules)) {
        return isPermittedTo(rules());
      } else if (isEmpty(rules)) {
        return true;
      } else if (isArray(rules)) {
        return every(rules, isPermittedTo);
      }
    };

    return isPermittedTo(rules);
  };
}
