import { TableColumn, TableItem } from "PFApp/components/table/table_types";
import { useCallback, useMemo } from "react";

import { getRawValue } from "../helpers";

export function useTermFilteredItems<ItemType>(
  items: (ItemType & TableItem)[],
  searchTerm: string,
  options?: { selectedColumns?: TableColumn[]; formatValues?: object }
) {
  const getFormattedValue = useCallback(
    (item: TableItem, column: TableColumn) => {
      const rawValue = getRawValue(item, column);
      const formatFunction = options?.formatValues?.[column.name];

      if (formatFunction) {
        return formatFunction(rawValue);
      }

      return rawValue ? JSON.stringify(rawValue) : "-";
    },
    [getRawValue, options?.formatValues]
  );

  const stringifyItem = useCallback(
    (item: TableItem): string => {
      if (options?.selectedColumns) {
        return options?.selectedColumns
          .map((column) => getFormattedValue(item, column))
          .join("_")
          .toLowerCase();
      }
      return JSON.stringify(item);
    },
    [getFormattedValue, options?.selectedColumns]
  );

  const stringifiedItems = useMemo(
    () => items.reduce((acc, curr) => ({ ...acc, [curr.id]: stringifyItem(curr) }), {}),
    [items, stringifyItem]
  );

  const filteredItems: typeof items = useMemo(
    () =>
      searchTerm
        ? items.filter((item) => stringifiedItems[item.id]?.includes(searchTerm.toLowerCase()))
        : items,
    [items, stringifiedItems, searchTerm]
  );

  return { filteredItems: filteredItems as ItemType[] };
}
