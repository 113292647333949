import api from "PFCore/api";
import { Subtemplate } from "PFTypes";

export type FetchSubtemplatesParams = {
  templateKey: string;
};

export const fetchSubtemplates = (params: FetchSubtemplatesParams): Promise<Subtemplate[]> =>
  api({
    url: "subtemplates",
    params
  });
