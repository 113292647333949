import classNames from "classnames";

import css from "./tile.module.scss";

type TileProps = {
  className?: string;
  light?: boolean;
  children?: React.ReactNode;
};

export const Tile = ({ className, children, light }: TileProps) => (
  <div
    className={classNames(css.tile, className, {
      [css.light]: light
    })}
  >
    {children}
  </div>
);
