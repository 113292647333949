import { useMemo, useState } from "react";

export const useLazyTooltip = (isEnabled, content, render) => {
  const [mounted, setMounted] = useState(false);

  const lazyPlugin = useMemo(
    () => ({
      fn: () => ({
        onMount: () => setMounted(true),
        onHidden: () => setMounted(false)
      })
    }),
    [setMounted]
  );

  const finalContent = useMemo(() => {
    if (isEnabled) {
      return mounted ? content : "";
    } else {
      return content;
    }
  }, [isEnabled, mounted, content]);

  const finalRender = render
    ? (args) => (!isEnabled || (isEnabled && mounted) ? render(args) : "")
    : undefined;

  return [lazyPlugin, finalContent, finalRender];
};
