import { useProfilePreview } from "PFApp/use_profile_preview";
import { Profile } from "PFTypes";
import { useCallback } from "react";

import { useMatchCardContext } from "../match_card_context";

export const useShowMatchProfileSidePanel = () => {
  const { showPreview } = useProfilePreview();
  const { profile } = useMatchCardContext();

  return useCallback(() => {
    showPreview({ id: profile.id, profile: profile as Profile });
  }, [profile, showPreview]);
};
