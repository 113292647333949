import classNames from "classnames";
import { IMatchScores } from "PFTypes";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import css from "./match_gauge.module.scss";
import { AvailabilityInfo } from "./parts/availability_info";
import { Bar } from "./parts/bar";
import { DonutChart } from "./parts/donut_chart";

type MatchGaugeProps = {
  match: {
    scores?: IMatchScores | null;
  };
  className?: string;
  availabilityIcon?: JSX.Element;
  isMatchLoading?: boolean;
};

type HoveredSegment = "direct" | "indirect" | "related";

export const MatchGauge = ({
  match,
  className,
  availabilityIcon,
  isMatchLoading
}: MatchGaugeProps): JSX.Element | null => {
  const { t } = useTranslation("core", { keyPrefix: "matchGauge" });
  const [hoveredSegment, setHoveredSegment] = useState<HoveredSegment | null>(null);

  if (!match.scores) {
    return null;
  }

  const {
    scores: {
      score,
      normalized_direct: directNormalizedScore,
      normalized_indirect: indirectNormalizedScore,
      normalized_related: relatedNormalizedScore,
      availability_score
    }
  } = match;

  const noScore = score === null;
  const noAvailabilityScore = availability_score === null;

  if (noScore && noAvailabilityScore) {
    return null;
  }

  const title = hoveredSegment && (
    <Trans i18nKey={`${hoveredSegment}Skills`} t={t} components={[<strong key="0" />]} />
  );

  return (
    <div className={classNames(css.container, className)}>
      <div className={css.chartWrapper}>
        <DonutChart
          match={match as { scores: IMatchScores }}
          hoveredSegment={hoveredSegment}
          isLoading={isMatchLoading}
        />
        <div className={css.barChart}>
          <div className={css.title}>{title}</div>
          <Bar
            score={directNormalizedScore}
            name="direct"
            onHover={setHoveredSegment}
            isLoading={isMatchLoading}
          />
          <Bar
            score={indirectNormalizedScore}
            name="indirect"
            onHover={setHoveredSegment}
            isLoading={isMatchLoading}
          />
          <Bar
            score={relatedNormalizedScore}
            name="related"
            onHover={setHoveredSegment}
            isLoading={isMatchLoading}
          />
          <AvailabilityInfo availabilityScore={availability_score} icon={availabilityIcon} />
        </div>
      </div>
    </div>
  );
};
