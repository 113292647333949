import moment, { Moment } from "moment";
import { Booking, Calendar } from "PFTypes";
import { useCallback, useEffect, useState } from "react";

import { CalendarPeriod, CommonCalendarProps } from "./bookings_calendar.types";
import { fromCalendarPeriod, toCalendarPeriod } from "./bookings_calendar.utils";
import { CalendarDisplay } from "./calendar_display";
import { useWorkingDayCheck } from "./use_working_day_check";

export type BookingsCalendarProps = {
  calendars: Record<string, Calendar>;
  onClick?: (booking: Booking) => void;
  displayMonth?: Moment;
  setDisplayMonth?: (date: Moment) => void;
  bookings: Booking[];
} & CommonCalendarProps;

const BookingsCalendar = ({
  bookings,
  calendars,
  bookingCategories,
  onClick,
  jobCodeDisplayAs,
  displayMonth,
  setDisplayMonth,
  ...calendarProps
}: BookingsCalendarProps): JSX.Element => {
  const [displayedMonth, setDisplayedMonth] = useState(displayMonth || moment());

  useEffect(() => {
    if (displayedMonth !== displayMonth) {
      setDisplayedMonth(displayMonth || moment());
    }
  }, [displayMonth]);

  const handleDisplayedMonthChange = useCallback(
    (date: Moment) => {
      setDisplayedMonth(date);
      setDisplayMonth?.(date);
    },
    [setDisplayMonth]
  );

  const { isWorkingDayCheck } = useWorkingDayCheck({
    calendars
  });
  const [calendarPeriods, setCalendarPeriods] = useState<CalendarPeriod[]>(
    bookings.map((booking) => toCalendarPeriod(booking, bookingCategories))
  );

  useEffect(() => {
    setCalendarPeriods(bookings.map((booking) => toCalendarPeriod(booking, bookingCategories)));
  }, [bookings]);

  const handleClick = (period: CalendarPeriod) => {
    const rawBooking = fromCalendarPeriod(period);
    onClick && onClick(rawBooking);
  };

  return (
    <CalendarDisplay
      {...calendarProps}
      onClick={handleClick}
      calendarPeriods={calendarPeriods}
      qaId="ProfileBookings"
      isWorkingCheck={isWorkingDayCheck}
      bookingCategories={bookingCategories}
      jobCodeDisplayAs={jobCodeDisplayAs}
      displayMonth={displayedMonth}
      onDisplayMonthChange={handleDisplayedMonthChange}
    />
  );
};

export default BookingsCalendar;
