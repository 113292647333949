const findCustomType = (item, customTypes) =>
  customTypes.find(
    ({ name, id }) =>
      name === item.type || // type is a string (name)
      (item.type && id === item.type.id) ||
      (item.type && name === item.type.name) ||
      false
  );

export const normalize = (item, customTypes, matchCustomTypes, filterCustomTypes) => {
  const customType = findCustomType(item, customTypes);
  if (customType) {
    return {
      ...item,
      type: { id: customType.id, name: customType.name },
      match: !!findCustomType(item, matchCustomTypes),
      filterKey: findCustomType(item, filterCustomTypes)?.name
    };
  } else {
    // should not happen..., but if it does it's filtered out in normalizeList;
    return null;
  }
};

export const normalizeList = (items, customTypes, matchCustomTypes, filterCustomTypes) =>
  (items || []).map((t) => normalize(t, customTypes, matchCustomTypes, filterCustomTypes)).filter((t) => !!t);

export const isFilter = (item) => !!item.filterKey;
export const isMatch = (item) => item.match;
