import axios, { AxiosInstance } from "axios";

import { PROXIED_ENVS } from "../../utilities/routes";

let instance: AxiosInstance | null = null;

export const getInsightsInstance = () => {
  if (!instance) {
    instance = axios.create({
      baseURL: PF.config.insights_api_endpoint,
      headers: {
        "Accept": null,
        "Ui-Version": PF.config.ui_version,
        ...(PROXIED_ENVS.includes(PF.config.environment ?? "")
          ? null
          : { sessionHost: PF.config.api_endpoint })
      }
    });
  }

  return instance;
};
