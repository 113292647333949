import { decamelizeKeys } from "humps";
import api from "PFCore/api";
import { Filters, Id } from "PFTypes";
import { PageTarget, SavedFilter } from "PFTypes/saved_filters";

// decamelization is needed to preserve proper filter field names (e.g. start_date)
const decamelizeFilterFields = <T extends Filters>(savedFilter: T): T => ({
  ...savedFilter,
  ...(savedFilter.fields ? { fields: decamelizeKeys(savedFilter.fields) } : {}),
  ...(savedFilter.numbers ? { numbers: decamelizeKeys(savedFilter.numbers) } : {}),
  ...(savedFilter.availability ? { availability: decamelizeKeys(savedFilter.availability) } : {}),
  ...(savedFilter.strings ? { strings: decamelizeKeys(savedFilter.strings) } : {}),
  ...(savedFilter.children ? { children: decamelizeFilterFields(savedFilter.children) } : {})
});

export type SavedFiltersResponse = SavedFilter[];

export const fetchSavedFilters = (target: PageTarget) =>
  api<SavedFiltersResponse>({
    url: "saved_filters",
    params: {
      target
    }
  }).then((savedFilters) => savedFilters.map((savedFilter) => decamelizeFilterFields(savedFilter)));

export type SavedFilterCreateData = Omit<SavedFilter, "id" | "profileId">;

export const createSavedFilter = (data: SavedFilterCreateData) =>
  api<SavedFilter>({
    url: "saved_filters",
    method: "POST",
    data
  }).then((res) => decamelizeFilterFields(res));

export const deleteSavedFilter = (id: Id) =>
  api<null>({
    url: `saved_filters/${id}`,
    method: "DELETE"
  });

export const fetchRelevantToMeFilters = (target: PageTarget): Promise<SavedFilter> =>
  api<SavedFilter>({
    url: "relevant_filters",
    params: {
      target
    }
  }).then((res) => decamelizeFilterFields(res));

export type ShareSavedFiltersData = {
  profilesIds: number[];
  name?: string;
};

export const shareSavedFilter = (
  id: Id,
  { name, profilesIds }: ShareSavedFiltersData
): Promise<SavedFilter[]> =>
  api({
    url: `saved_filters/${id}/share`,
    method: "POST",
    data: {
      name,
      profileId: profilesIds
    }
  });
