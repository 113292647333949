import AppContext from "PFApp/app_context";
import ProfindaLogo from "PFComponents/profinda_logo";
import { fullMarkdown } from "PFCore/helpers/markdown";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import FacebookIcon from "PFIcons/facebook.svg";
import InstagramIcon from "PFIcons/instagram.svg";
import LinkedInIcon from "PFIcons/linkedin.svg";
import TwitterIcon from "PFIcons/twitter.svg";
import { MODALS_ACTIONS } from "PFReducers/modals_reducer";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef } from "react";

import css from "./footer.less";

const Footer = ({ style }) => {
  const linksRef = useRef();

  const { dispatch } = useContext(AppContext);

  const { data: currentAccount } = useCurrentAccount();

  const handleLinkClick = (e) => {
    const href = e.target.getAttribute("href");
    if (href === "/terms") {
      e.preventDefault();
      dispatch({ type: MODALS_ACTIONS.TERMS_SHOW });
    }
  };

  useEffect(() => {
    linksRef.current && linksRef.current.addEventListener("click", handleLinkClick);
    return () => {
      linksRef.current && linksRef.current.removeEventListener("click", handleLinkClick);
    };
  }, [linksRef.current]);

  const { customization } = currentAccount;

  if (!customization) {
    return null;
  }

  const showFooterLogo = customization.show_footer_logo;
  const { copyright } = customization;
  const linkedInUrl = customization.linkedin_url;
  const facebookUrl = customization.facebook_url;
  const twitterUrl = customization.twitter_url;
  const instagramUrl = customization.instagram_url;
  const footerLinks = fullMarkdown(customization.footer_links);

  return (
    <div className={css.root} style={style}>
      <div className={css.logo}>
        {showFooterLogo && <ProfindaLogo width={75} colorMain="inherit" colorSecondary="inherit" />}
      </div>

      {copyright && (
        <span className={css.legal}>
          {copyright} {new Date().getFullYear()}
        </span>
      )}

      <div className={css.social}>
        {linkedInUrl && (
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <LinkedInIcon width={15} height={15} />
          </a>
        )}

        {facebookUrl && (
          <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
            <FacebookIcon width={15} height={15} />
          </a>
        )}

        {twitterUrl && (
          <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
            <TwitterIcon width={15} height={15} />
          </a>
        )}

        {instagramUrl && (
          <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
            <InstagramIcon width={15} height={15} />
          </a>
        )}
      </div>

      {footerLinks && (
        <div ref={linksRef} className={css.links} dangerouslySetInnerHTML={{ __html: footerLinks }} />
      )}
    </div>
  );
};

Footer.propTypes = {
  style: PropTypes.object
};

export default Footer;
