import { shouldDisplayProperty } from "PFApp/activities/edit/utils/should_display_property";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Subtemplate, Template } from "PFTypes";

type GetVisiblePermittedPropertiesAttributes = {
  subtemplate?: Subtemplate | null;
  template?: Template | null;
};

export const useVisiblePermittedProperties = () => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();

  const getVisiblePermittedProperties = ({
    subtemplate,
    template
  }: GetVisiblePermittedPropertiesAttributes) =>
    getVisibleProperties(subtemplate || template).filter(
      (property) =>
        property.type !== "custom_field" || shouldDisplayProperty(property, customTypes, currentProfile)
    );

  return {
    getVisiblePermittedProperties
  };
};
