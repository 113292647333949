import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { AccessLevel } from "PFTypes";
import PropTypes from "prop-types";

import { PositionsList } from "./list/positions_list";

const PositionsSection = ({ permissionsGroup, adminPage, profile, handleProfileUpdate }) => {
  const positions = profile?.positions || [];

  const canAddPosition = () =>
    isProfileFieldPermitted(
      permissionsGroup,
      profile?.permissions_group?.id,
      "other_positions/dates",
      AccessLevel.ReadWrite
    );

  return (
    <PositionsList
      createDisabled={!canAddPosition()}
      handleAdd={handleProfileUpdate}
      handleUpdate={handleProfileUpdate}
      handleRemove={handleProfileUpdate}
      positions={positions}
      profile={profile}
      permissionsGroup={permissionsGroup}
      adminPage={adminPage}
    />
  );
};

PositionsSection.propTypes = {
  profile: PropTypes.object.isRequired,
  permissionsGroup: PropTypes.any,
  adminPage: PropTypes.any,
  handleProfileUpdate: PropTypes.func,
  handleRemove: PropTypes.func
};

export default PositionsSection;
