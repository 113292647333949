import classNames from "classnames";
import Checkbox from "PFComponents/checkbox/checkbox";

import css from "./table_bulk_select.module.scss";

type BulkSelectProps = {
  isAllSelected: boolean;
  isPartiallySelected: boolean;
  onChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
};

export const BulkSelect = ({ isAllSelected, isPartiallySelected, onChange }: BulkSelectProps) => (
  <div className={css.mainContainer}>
    <Checkbox
      className={css.tableCheckbox}
      checked={isAllSelected}
      handleChange={(val, ev) => onChange(ev)}
    />
    <div className={css.lineContainer}>
      <div className={classNames(css.line, { [css.hidden]: !isPartiallySelected })} />
    </div>
  </div>
);
