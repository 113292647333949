import { Typography } from "PFComponents/typography/typography";

import css from "./profile_edit_nav_box.module.scss";

type ProfileEditNavBoxProps = {
  title: string;
};

export const ProfileEditNavBox = ({
  children,
  title
}: React.PropsWithChildren<ProfileEditNavBoxProps>): JSX.Element => (
  <div className={css.box}>
    <Typography variant="bodyRegular">{title}</Typography>
    {children}
  </div>
);
