import { Icon } from "PFComponents/icon";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import { useTranslation } from "react-i18next";

import { useIsMatchTrainee } from "../../hooks/use_is_match_trainee";
import css from "./match_card_header.module.scss";

// todo: [PROF-2224] add remaining status icons once api provides necessary data
export const MatchStatusIcons = () => {
  const isTrainee = useIsMatchTrainee();
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card" });

  if (!isTrainee) {
    return null;
  }

  return (
    <div className={css.iconsWrapper}>
      {isTrainee && (
        <TooltipIcon
          content={t("developmentalMatchInfo")}
          IconComponent={() => <Icon name="development" />}
        />
      )}
    </div>
  );
};
