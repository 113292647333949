import useWindowSize from "PFCore/helpers/use_window_size";
import PropTypes from "prop-types";

import css from "./page_layout.less";

const COUNTS = { full: 1, withSidebar: 2, withLeftSidebar: 2 };
import { Children } from "react";

export default function PageLayout({ mode, children }) {
  const count = Children.count(children);
  if (COUNTS[mode] !== count) {
    throw new Error(`PageLayout: invalid number of columns (got ${count}, expected ${COUNTS[mode]})`);
  }

  return (
    <div>
      {Children.map(children, (child, index) => (
        <div className={css[mode]} key={index}>
          {child}
        </div>
      ))}
    </div>
  );
}

PageLayout.WhenOneColumn = ({ children }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { windowWidth } = useWindowSize();

  return windowWidth < 1000 ? <div>{children}</div> : null;
};

PageLayout.WhenOneColumn.propTypes = { children: PropTypes.node.isRequired };
PageLayout.WhenOneColumn.displayName = "PageLayout.WhenOneColumn";

PageLayout.WhenTwoColumns = ({ children }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { windowWidth } = useWindowSize();

  return windowWidth >= 1000 ? <div>{children}</div> : null;
};
PageLayout.WhenTwoColumns.propTypes = { children: PropTypes.node.isRequired };
PageLayout.WhenTwoColumns.displayName = "PageLayout.WhenTwoColumns";

PageLayout.propTypes = {
  mode: PropTypes.oneOf(["full", "withSidebar", "withLeftSidebar"]),
  children: PropTypes.node.isRequired
};

PageLayout.defaultProps = {
  mode: "full"
};
