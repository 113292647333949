import { Id } from "PFTypes";

export enum SearchEntity {
  Role = "role",
  AuditRole = "audit_role",
  Engagement = "engagement",
  AuditEngagement = "audit_engagement",
  Profile = "profile"
}

export type EntityConfig = {
  entity: SearchEntity;
  useAsTerm?: boolean;
  redirect?: boolean;
};

export type SearchConfig = EntityConfig[];

export type SearchEntityData = {
  id: Id;
  type: SearchEntity;
};

export type SearchDropdownItem = {
  text: string;
  entity?: SearchEntityData;
  useAsTerm?: boolean;
  onClick?: (term: string, entity?: SearchEntityData) => void;
};
