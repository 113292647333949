import { useQuery } from "@tanstack/react-query";
import { fetchKeywords, KeywordsParams } from "PFCore/services/keywords";
import { Collection, Keyword } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { keywordsKeys } from "./query_keys";

export const useKeywords = (params: KeywordsParams, options: QueryOptions<Collection<Keyword[]>> = {}) =>
  useQuery<Collection<Keyword[]>>(
    keywordsKeys.all(params),
    () => fetchKeywords(params) as Promise<Collection<Keyword[]>>,
    options
  );
