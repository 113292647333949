import api from "PFCore/api";
import { Collection, Id, PureCustomValue } from "PFTypes";

type FetchLocationsOptionsParams = {
  term: string;
  customTypeId: Id;
  templateId?: Id;
  searchWorldwide?: boolean;
};

export const fetchLocationsOptions = (
  params: FetchLocationsOptionsParams
): Promise<Collection<PureCustomValue[]>> =>
  api({
    url: "options/locations",
    params
  });
