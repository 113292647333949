import { isNumber } from "lodash";
import { ThresholdSlider } from "PFComponents/availability/threshold_slider";
import { AvailabilityRule, Template } from "PFTypes";

type AvailabilityThresholdSliderProps = {
  availability: Partial<AvailabilityRule>;
  template: Template;
  onChange: (updatedAvailability: Partial<AvailabilityRule>) => void;
};

export const AvailabilityThresholdSlider = ({
  availability,
  template,
  onChange
}: AvailabilityThresholdSliderProps) => {
  const defaultAvailabilityThreshold = template?.schema.properties?.availability?.default_threshold;

  const threshold = isNumber(availability.availability_threshold)
    ? availability.availability_threshold
    : defaultAvailabilityThreshold || 70;

  const handleThresholdChange = (value: number) => {
    onChange({ ...availability, availability_threshold: value });
  };

  return <ThresholdSlider threshold={threshold} onChange={handleThresholdChange} />;
};
