import { useState } from "react";

import { SaveAsViewModalInitialData, SaveAsViewModalMode } from "../save_as_view_modal/save_as_view_modal";

type UseSaveAsViewModalReturn = {
  open: (initialData?: SaveAsViewModalInitialData) => void;
  close: () => void;
  isOpen: boolean;
  data: SaveAsViewModalInitialData;
};

const DEFAULT_DATA: SaveAsViewModalInitialData = {
  mode: SaveAsViewModalMode.Create,
  viewName: ""
};

export const useSaveAsViewModal = (): UseSaveAsViewModalReturn => {
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [initialData, setInitialData] = useState<SaveAsViewModalInitialData>(DEFAULT_DATA);

  const handleClose = () => {
    setInitialData(DEFAULT_DATA);
    setDisplayConfirmModal(false);
  };

  const handleOpen = (initialData?: SaveAsViewModalInitialData) => {
    setInitialData(initialData ?? DEFAULT_DATA);
    setDisplayConfirmModal(true);
  };

  return {
    open: handleOpen,
    close: handleClose,
    isOpen: displayConfirmModal,
    data: initialData
  };
};
