import classNames from "classnames";
import { forwardRef } from "react";

import css from "./side_panel_body.module.scss";

type SidePanelBodyProps = React.PropsWithChildren<{
  onScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  maxHeight: string;
  noOverflow?: boolean;
}>;

export const SidePanelBody = forwardRef<HTMLDivElement, SidePanelBodyProps>(
  ({ onScroll, maxHeight, children, noOverflow }, ref) => (
    <div
      ref={ref}
      style={{
        maxHeight
      }}
      className={classNames(css.bodyWithFooter, {
        [css.noOverflow]: noOverflow
      })}
      onScroll={onScroll}
    >
      {children}
    </div>
  )
);

SidePanelBody.displayName = "SidePanelBody";
