import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Experience } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useSkillsProficiencyOptions = () => {
  const { t } = useTranslation("skillsFrameworks", { keyPrefix: "edit" });

  return useMemo<DropdownOption[]>(
    () => [
      {
        id: Experience.Basic,
        item: Experience.Basic,
        displayElement: t("basic")
      },
      {
        id: Experience.Intermediate,
        item: Experience.Intermediate,
        displayElement: t("intermediate")
      },
      {
        id: Experience.Advanced,
        item: Experience.Advanced,
        displayElement: t("advanced")
      }
    ],
    [t]
  );
};
