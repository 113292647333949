import { CustomType, PureCustomValue } from "PFTypes";

import { useActivityEditFormContext } from "../../activity_edit_form_context";
import { useGetCustomFieldsToLink } from "../use_get_custom_fields_to_link";
import { useUpdateActivityCustomFieldValue } from "../use_update_activity_custom_field_value";
import { areValuesEqual } from "./helpers";

const cannotLinkResponse = { canLink: false, isLinked: false };

export const useCustomFieldLink = (customType: CustomType, value: PureCustomValue[]) => {
  const { selectedParentActivity } = useActivityEditFormContext();
  const getLinkedCustomFields = useGetCustomFieldsToLink();
  const updateField = useUpdateActivityCustomFieldValue();

  if (!selectedParentActivity) {
    return cannotLinkResponse;
  }

  const linkedCustomFields = getLinkedCustomFields(selectedParentActivity);

  const linkedCustomField = linkedCustomFields.find(({ type }) => type.name === customType.name);

  if (!linkedCustomField) {
    return cannotLinkResponse;
  }

  const isEqualToEngagementValue = areValuesEqual(linkedCustomField.values, value);

  return {
    isLinked: isEqualToEngagementValue,
    canLink: linkedCustomField.values.length > 0,
    restore: () => {
      updateField(linkedCustomField.type.name, linkedCustomField.values);
    }
  };
};
