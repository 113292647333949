import { useRef } from "react";

export const useFirstLoad = (): boolean => {
  const isFirstLoad = useRef(true);

  if (isFirstLoad.current) {
    isFirstLoad.current = false;

    return true;
  }

  return isFirstLoad.current;
};
