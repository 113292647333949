import classNames from "classnames";
import CloseIcon from "PFIcons/close.svg";
import OkIcon from "PFIcons/ok.svg";
import PropTypes from "prop-types";

import css from "./yes_no.module.scss";

const YesNo = ({ yes, style }) => (
  <div className={classNames(css.root, { [css.yes]: yes })} style={style}>
    {yes ? <OkIcon width={20} height={20} /> : <CloseIcon width={22} height={22} />}
  </div>
);

YesNo.propTypes = {
  yes: PropTypes.bool,
  style: PropTypes.object
};

export default YesNo;
