import activityKeys from "PFCore/hooks/queries/activity/query_keys";
import { Id, QueryParams } from "PFTypes";

export const activityHistoryKeys = {
  all: (activityId: Id) => [...activityKeys.activity(activityId), "history"],
  list: (activityId: Id, params?: QueryParams) => [
    ...activityHistoryKeys.all(activityId),
    activityId,
    { params }
  ]
};
