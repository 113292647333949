import { isEmpty } from "lodash";
import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import css from "./feedback_form.module.scss";
import {
  FeedbackFormContextProps,
  FeedbackFormContextProvider,
  FeedbackFormData,
  useFeedbackFormContext
} from "./feedback_form_context_provider";
import { useFeedbackFormMutations } from "./hooks/use_feedback_form";
import { Questions } from "./parts/questions/questions";
import { SkillsEvaluation } from "./parts/skills/skills_evaluation";

export type FeedbackFormProps = FeedbackFormContextProps & FeedbackFormContentProps;

type FeedbackFormContentProps = {
  readOnly: boolean;
  onSubmit: (formData: FeedbackFormData) => void;
  footerContent?: React.ReactNode;
};

export const FeedbackForm = ({
  readOnly,
  onSubmit,
  defaultData,
  footerContent,
  ...props
}: FeedbackFormProps): JSX.Element => (
  <FeedbackFormContextProvider defaultData={defaultData} {...props}>
    <FeedbackFormContent onSubmit={onSubmit} readOnly={readOnly} footerContent={footerContent} />
  </FeedbackFormContextProvider>
);

const FeedbackFormContent = ({
  readOnly,
  onSubmit,
  footerContent
}: FeedbackFormContentProps): JSX.Element => {
  const { t } = useTranslation("activities", { keyPrefix: "show.feedback" });
  const { formData } = useFeedbackFormContext();
  const { filteredFormData, validateQuestions } = useFeedbackFormMutations();
  const isAnyActiveSkillRanked = !isEmpty(filteredFormData?.skills);

  const handleSubmit = () => {
    const errors = validateQuestions();
    if (Object.values(errors).length === 0 && filteredFormData && isAnyActiveSkillRanked) {
      onSubmit(filteredFormData);
    }
  };

  return (
    <div className={css.formContainer}>
      <div className={css.formContent}>
        <SkillsEvaluation readOnly={readOnly} />
        {formData?.questions && <Questions readOnly={readOnly} />}
      </div>
      <div className={css.footer}>
        <div className={css.additionalFooterContent}>{footerContent}</div>
        {!readOnly && (
          <Button text={t("sendFeedback")} onClick={handleSubmit} disabled={!isAnyActiveSkillRanked} />
        )}
      </div>
    </div>
  );
};
