import { RadioItem, Radios } from "PFComponents/radios";

import css from "./booking_questions.module.scss";
import { GroupBookingsQuestionsOption } from "./group_bookings_questions";

export enum BookingSelectOption {
  This = "This",
  All = "All",
  Future = "Future"
}

export type Messages = Partial<Record<BookingSelectOption, React.ReactNode>>;

type BookingQuestionsProps = {
  className?: string;
  messages?: Messages;
  text: string;
  name: string;
  value: BookingSelectOption | GroupBookingsQuestionsOption;
  items: RadioItem<BookingSelectOption | GroupBookingsQuestionsOption>[];
  onChange: (selectedOption: BookingSelectOption | GroupBookingsQuestionsOption) => void;
};

export const BookingQuestions = ({
  text,
  name,
  className,
  messages,
  value,
  items,
  onChange
}: BookingQuestionsProps): JSX.Element => (
  <>
    <div className={css.radios}>
      <p>{text}</p>
      <div className={className}>
        <Radios<BookingSelectOption | GroupBookingsQuestionsOption>
          name={name}
          items={items}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
    {messages && <p>{messages[value]}</p>}
  </>
);
