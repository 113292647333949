import { Icon } from "PFComponents/icon";
import { Label } from "PFComponents/text/input_field_set/components/label";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./engagement_placeholder.module.scss";

const TAG_ID = "bm-engagement-placeholder";

export const EngagementPlaceholder = () => {
  const { t } = useTranslation(["translation", "bookingModule"]);

  return (
    <div className={css.root}>
      <Label tagId={TAG_ID} label={t("translation:engagement")} />
      <div id={TAG_ID} className={css.placeholder}>
        <Typography variant="bodyBold" tag="span">
          {t("bookingModule:bookings.create.notApplicable")}
        </Typography>
        <Tooltip content={t("bookingModule:bookings.create.repeatedBookingEngagementInfo")}>
          <span className={css.tooltipIcon}>
            <Icon name="info" size="sm" />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
