import { ActionIcon } from "../../../action_icon";
import css from "./password_toggle.module.scss";

type PasswordToggleProps = { showPassword?: boolean; onClick: () => void };

export const PasswordToggle = ({ showPassword, onClick }: PasswordToggleProps) => (
  <ActionIcon
    name={showPassword ? "shown" : "hidden"}
    onClick={onClick}
    classes={{ root: css.passwordToggle }}
  />
);
