import { isEmpty } from "lodash";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import YesNo from "PFComponents/icons/yes_no";
import { Markdown } from "PFComponents/markdown";
import Pill from "PFComponents/pill/pill";
import { MatchAccuracyBadge } from "PFComponents/resource_metrics/match_accuracy";
import { getMainPosition } from "PFCore/helpers/profile";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity, CustomType, Profile, Shortlist, ShortlistProfile } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import AvailabilityWarningIcon from "../../../show/shortlist/availability_warning_icon";
import css from "../compare_details_page.module.scss";

const SHORT_BIO_LENGTH_LIMIT = 150;

export type Row = Pick<CustomType, "name" | "display_as" | "kind"> & { id: number | string };
export type ComparableProfile = Omit<Shortlist, "profile"> & {
  profile: Profile & ShortlistProfile;
  availabilityMatch: number;
  isAvailable: boolean;
  scores?: {
    normalized_score: number | null;
  };
};

type GetCellDataReturn = {
  profile: Profile;
  mobileName: JSX.Element;
  node: JSX.Element;
};

export const useCellData = (task: Activity) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { t } = useTranslation("activities");

  const [recalcOffsetCounter, setRecalcOffsetCounter] = useState(1);

  const updateOffsets = useCallback(
    () => setRecalcOffsetCounter(recalcOffsetCounter + 1),
    [recalcOffsetCounter]
  );

  const getCellData = useCallback(
    (row: Row, comparableProfile: ComparableProfile): GetCellDataReturn => {
      let node;
      const { profile, applicable_custom_values, comparable_custom_values } = comparableProfile;

      const getValues = (name: string) => profile[name] || getMainPosition(profile)[name] || [];

      if (row.id === "bio") {
        const summary = (profile?.summary || "").trim();
        node = !summary ? (
          "-"
        ) : (
          <Markdown
            raw={summary}
            crop={SHORT_BIO_LENGTH_LIMIT}
            emojify={false}
            onToggle={updateOffsets}
            className={css.shortBio}
          />
        );
      } else if (row.id === "availability") {
        const availabilityScore = comparableProfile?.availabilityMatch;
        const normalizedScore = comparableProfile.scores?.normalized_score;
        // if task requires availability, and score is null or undefined
        // it means that the profile is not a match (so it lacks the score)
        // eslint-disable-next-line eqeqeq
        if (task.metadata?.availability && availabilityScore == null && normalizedScore == null) {
          node = <Pill style={{ margin: "20px 0" }}>{t("compare.notAMatch")}</Pill>;
        } else {
          node =
            // null and undefined are both options
            // eslint-disable-next-line eqeqeq
            availabilityScore != null && comparableProfile?.state !== "filled" ? (
              <MatchAccuracyBadge
                style={{ margin: "20px 0" }}
                value={
                  // Conversion to have the range in [0, 1]
                  Math.min(availabilityScore, 1)
                }
                icon={availabilityScore <= 0 ? <AvailabilityWarningIcon /> : undefined}
              />
            ) : (
              <YesNo
                style={{ margin: "20px 0" }}
                yes={comparableProfile.state === "filled" || comparableProfile.isAvailable}
              />
            );
        }
      } else if (row.kind === "multiple") {
        const customType = customTypes.find(({ name }) => row.name === name);
        const fields = task.custom_fields;
        const field = row.name && fields && fields.find(({ type: { name } }) => name === row.name);

        const applicableCustomValues = applicable_custom_values || [];
        const comparableCustomValues = comparable_custom_values || [];

        const filteredComparableCustomValues =
          customType && field && !isEmpty(field.values)
            ? comparableCustomValues.filter(
                ({ id }) =>
                  field.values.some(({ id: valueId }) => valueId === id) && field.type.id === customType.id
              )
            : comparableCustomValues;

        const applicables =
          customType && field && !isEmpty(field.values)
            ? [...applicableCustomValues, ...filteredComparableCustomValues].filter(
                ({ custom_type_id }) => custom_type_id === customType.id
              )
            : getValues(row.name);

        if (applicables.length === 0) {
          node = "-";
        } else {
          node = (
            <CustomValuesList
              type={row}
              currentProfile={currentProfile}
              currentAccount={currentAccount}
              profileId={profile.id}
              customValues={applicables}
              sort={false}
              moreLimit={row.name === "skills" ? 5 : 3}
              showMoreButton
              handleMoreClicked={updateOffsets}
              showTooltip
            />
          );
        }
      } else {
        let val = getValues(row.name);
        val = Array.isArray(val) ? val[0] : val;
        node = val ? <div style={{ padding: "3px 0" }}>{val.text}</div> : "";
      }

      return {
        node,
        mobileName: <small className={css.mobileName}>{row.display_as}</small>,
        profile
      };
    },
    [
      currentAccount,
      currentProfile,
      customTypes,
      task.custom_fields,
      task.metadata.availability,
      updateOffsets
    ]
  );

  return { getCellData, recalcOffsetCounter };
};
