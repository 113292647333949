import PropTypes from "prop-types";

import ICON_SIZES from "./icon_sizes";

const AddCollapseIcon = ({ size, add }) => (
  <svg width={size} height={size} viewBox="0 0 20 20">
    <rect x={0} y={8.5} height={3} width={20} rx={2} ry={2} />
    <rect
      x={8.5}
      y={0}
      height={20}
      width={3}
      rx={2}
      ry={2}
      style={{
        transform: add ? "scale(1, 0)" : "scale(1, 1)",
        transformOrigin: "50% 50%",
        transition: "transform ease 200ms"
      }}
    />
  </svg>
);

AddCollapseIcon.propTypes = {
  /** 't-shirt' size. */
  size: PropTypes.number,
  add: PropTypes.bool
};

AddCollapseIcon.defaultProps = {
  size: ICON_SIZES.sm,
  add: true
};

export default AddCollapseIcon;
