import { activityPreviewReducer } from "PFReducers/activity_preview_reducer";
import { appLayoutReducer } from "PFReducers/app_layout_reducer";
import { chatReducer } from "PFReducers/chat_reducer";
import { growlReducer } from "PFReducers/growl_reducer";
import { modalReducer } from "PFReducers/modals_reducer";
import { notificationSettingsReducer } from "PFReducers/notification_settings_reducer";
import { notificationsReducer } from "PFReducers/notifications_reducer";
import { pageReducer } from "PFReducers/page_reducer";
import { profilePreviewReducer } from "PFReducers/profile_preview_reducer";
import { searchReducer } from "PFReducers/search_reducer/search_reducer";
import { templatesConfigurationReducer } from "PFReducers/templates_configuration_reducer";
import { themeVarsReducer } from "PFReducers/theme_vars_reducer";

const combineReducers = (reducers) => (state, action) =>
  reducers.reduce((mem, reducer) => ({ ...mem, ...(reducer(mem, action) || {}) }), state);

export const mainReducer = (state, action) =>
  combineReducers([
    appLayoutReducer,
    chatReducer,
    growlReducer,
    notificationsReducer,
    notificationSettingsReducer,
    templatesConfigurationReducer,
    pageReducer,
    modalReducer,
    profilePreviewReducer,
    activityPreviewReducer,
    searchReducer,
    themeVarsReducer
  ])(state, action);
