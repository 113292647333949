import { AccessLevel, PermissionsGroup, Profile } from "PFTypes";

export const isProfileFieldPermitted = (
  permissionsGroup: PermissionsGroup | undefined,
  targetGroupId: number | undefined,
  fieldName: string,
  accessLevel = AccessLevel.ReadOnly
) => {
  if (!targetGroupId) {
    return false;
  }

  const profileFields =
    permissionsGroup?.cross_group_permissions?.[targetGroupId]?.profile_fields_access_levels;
  if (!profileFields) {
    return false;
  }

  const perm = profileFields[fieldName];

  if (accessLevel === AccessLevel.ReadWrite) {
    return perm === AccessLevel.ReadWrite;
  } else if (accessLevel === AccessLevel.ReadOnly) {
    return perm === AccessLevel.ReadOnly || perm === AccessLevel.ReadWrite;
  }

  return false;
};

export const getProfileCustomTypesAccessLevel = (
  permissionsGroup: PermissionsGroup | undefined,
  profile: Profile
): Record<string, AccessLevel> => {
  const targetPG = profile.permissions_group;

  if (!targetPG) {
    throw new Error("PermissionsGroup::getProfileCustomTypesAccessLevel - missing permissions_groups");
  }

  return permissionsGroup?.cross_group_permissions?.[targetPG.id]?.custom_types_access_levels || {};
};
