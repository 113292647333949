import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import moment from "moment";
import { Divider } from "PFComponents/divider";
import { LoadingDots } from "PFComponents/loading_dots";
import { RadioItem, Radios } from "PFComponents/radios";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Id } from "PFTypes";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFutureBookings } from "../../hooks/use_future_bookings";
import css from "./bookings_to_reassign_selector.module.scss";

export enum ReassignSelectOption {
  ThisBooking = "ThisBooking",
  AllBookings = "AllBookings",
  ThisAndFuture = "ThisAndFuture"
}

export type CurrentBooking = {
  id: Id;
  startDate: string;
  endDate: string;
};

type BookingsToReassignSelectorProps = {
  profileId: number;
  activityId: Id;
  currentBooking: CurrentBooking;
  reassignOptionSelected: ReassignSelectOption;
  setReassignOptionSelected: Dispatch<SetStateAction<ReassignSelectOption>>;
  dividerEnabled?: boolean;
};

export const BookingsToReassignSelector = ({
  profileId,
  activityId,
  currentBooking,
  reassignOptionSelected,
  setReassignOptionSelected,
  dividerEnabled
}: BookingsToReassignSelectorProps): JSX.Element => {
  const { t } = useTranslation("bookingModule");
  const { dateFormatString, formatDate, utc } = useDateFormatter();

  const { bookings, hasFutureBookings, isFetching } = useFutureBookings({
    thisBooking: { id: currentBooking.id as number, startDate: currentBooking.startDate },
    activityId,
    profileId
  });

  const currentBookingStartDate = moment(utc(currentBooking.startDate)).format(dateFormatString);

  const dateFrom = bookings?.entries
    ? moment.min(bookings?.entries.map(({ start_date }) => utc(start_date))).format(dateFormatString)
    : null;

  const dateTo = bookings?.entries
    ? moment.max(bookings?.entries.map(({ end_date }) => utc(end_date))).format(dateFormatString)
    : null;

  const radioItems = useMemo<RadioItem<ReassignSelectOption>[]>(() => {
    if (isEmpty(bookings)) {
      return [];
    }
    const hasMoreThanOneBooking = !isNil(bookings.meta?.total) && bookings.meta.total > 1;
    return [
      ...(hasMoreThanOneBooking
        ? [
            {
              id: ReassignSelectOption.ThisBooking,
              label: t("bookings.reassign.reassignThisBooking", {
                dateFrom: formatDate(utc(currentBooking.startDate)),
                dateTo: formatDate(utc(currentBooking.endDate))
              })
            }
          ]
        : []),
      ...(hasFutureBookings
        ? [
            {
              id: ReassignSelectOption.ThisAndFuture,
              label: t("bookings.reassign.reassignThisAndFutureBookings", {
                dateFrom: currentBookingStartDate,
                dateTo
              })
            }
          ]
        : []),
      ...(hasMoreThanOneBooking
        ? [
            {
              id: ReassignSelectOption.AllBookings,
              label: t("bookings.reassign.reassignAllBookings", {
                count: bookings.meta.total,
                dateFrom,
                dateTo
              })
            }
          ]
        : [])
    ];
  }, [bookings, hasFutureBookings, t]);

  return (
    <div className={css.root}>
      {isFetching && <LoadingDots />}
      {!!bookings && !isEmpty(radioItems) && (
        <>
          <Radios<ReassignSelectOption>
            name="reassignBookings"
            items={radioItems}
            value={reassignOptionSelected}
            onChange={(id) => setReassignOptionSelected(id)}
          />
          {dividerEnabled && <Divider />}
        </>
      )}
    </div>
  );
};
