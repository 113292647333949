import { MsTeamsAuthSaml, MsTeamsDeadEnd, MsTeamsRootPage, MsTeamsSignIn } from "PFApp/ms_teams";
import NotFoundRoute from "PFApp/routes/not_found_route";
import { WithLayout } from "PFApp/routes/route_helpers";
import { headerOnly } from "PFCore/base/page_layouts";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const MsTeamsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <WithLayout layout={{ sidebar: headerOnly }}>
      <Switch>
        <Route exact path={path}>
          <MsTeamsRootPage />
        </Route>
        <Route exact path={`${path}/signin`}>
          <MsTeamsSignIn />
        </Route>
        <Route exact path={`${path}/authsaml`}>
          <MsTeamsAuthSaml />
        </Route>
        <Route exact path={`${path}/end`}>
          <MsTeamsDeadEnd />
        </Route>
        <Route path="*">
          <NotFoundRoute />
        </Route>
      </Switch>
    </WithLayout>
  );
};

export default MsTeamsRoutes;
