import api from "PFCore/api";
import { ApiRoute } from "PFCore/utilities/routes";
import { InterestQuestionnaire } from "PFTypes";

export type ExportResponse =
  | {
      fileUrl: string;
      message?: never;
    }
  | {
      fileUrl?: never;
      message: "enqueued";
    };

export interface InterestQuestionnairesResponse {
  entries: InterestQuestionnaire[];
  meta: {
    total: number;
  };
}

export const fetchInterestQuestionnaires = (): Promise<InterestQuestionnairesResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute("/api/interest_questionnaires"),
    api_version: 2 // eslint-disable-line camelcase
  });

export type FetchUrlMetadataResponse = {
  title: string | null;
  description: string | null;
  type: string | null;
  image: string | null;
  url: string | null;
};

export const fetchUrlMetadata = (url: string): Promise<FetchUrlMetadataResponse> =>
  api({
    url: "open_graph_parser",
    params: {
      url
    }
  });
