import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { useTranslation } from "react-i18next";

import css from "../description_generator.module.scss";

type ClosedViewProps = {
  title: string;
  onOpen: () => void;
};

export const ClosedView = ({ title, onOpen }: ClosedViewProps) => {
  const { t } = useTranslation("activities");

  return (
    <div className={css.root}>
      <Button kind="secondary" small disabled={!title} onClick={onOpen}>
        <Icon name="ai" className={css.magicIcon} size="md" />
        {t("edit.sections.descriptionGenerate.aiGenerate")}
      </Button>
    </div>
  );
};

ClosedView.displayName = "DescriptionGeneratorClosedView";
