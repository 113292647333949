import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking } from "PFTypes";

import { useNotApplicable } from "./use_not_applicable";

type UseSortRolesReturn = {
  sortRoles: (bookingA: Booking, bookingB: Booking) => number;
};

export const useSortRoles = (): UseSortRolesReturn => {
  const { getCategory } = useBookingCategories();
  const NOT_APPLICABLE = useNotApplicable();

  const sortRoles = (bookingA: Booking, bookingB: Booking) => {
    const categoryA = getCategory(bookingA);
    const categoryB = getCategory(bookingB);

    const roleA = categoryA ? bookingA.activity?.name ?? "" : NOT_APPLICABLE;
    const roleB = categoryB ? bookingB.activity?.name ?? "" : NOT_APPLICABLE;

    return roleA.localeCompare(roleB);
  };

  return {
    sortRoles
  };
};
