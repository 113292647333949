import AppContext from "PFApp/app_context";
import { notificationsNavItemOpenDispatchOptions } from "PFApp/components/navbar/items/notifications_nav_item";
import NotificationsPanel from "PFApp/notifications/notifications_panel";
import NotificationsPanelActions from "PFApp/notifications/notifications_panel_actions";
import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography/typography";
import { ReactNode, SyntheticEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

import { NotificationsPanelFilters } from "../../notifications/notification_panel_filters";
import css from "./left_action_panel.module.scss";

const LeftActionPanel = () => {
  const { t } = useTranslation("translation", { keyPrefix: "leftActionPanel" });
  const {
    dispatch,
    store: {
      appLayout: { leftActionPanel }
    }
  } = useContext(AppContext);

  const close = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(notificationsNavItemOpenDispatchOptions({ collapsed: true, userDriven: true }));
  };

  const [title, content, filters, actions] = getContent(leftActionPanel.id, t("actions"));

  return (
    <div className={leftActionPanel.collapsed ? css.collapsed : undefined} id="left_action_panel">
      <div className={css.spacer}>{/* spacer is so we can simply use this in a display: flex wrap */}</div>
      <div className={css.root}>
        <div className={css.header}>
          <Typography variant="h4" qaId="LeftActionPanel.title" noMargin>
            {title || "..."}
          </Typography>
          <div className={css.actions}>{actions}</div>
          <ActionIcon size="sm" name="chevron-left" onClick={close} disableHover />
        </div>
        <div className={css.filters}>{filters}</div>
        <div className={css.content}>
          <div className={css.inner}>{content}</div>
        </div>
      </div>
    </div>
  );
};

const getContent = (id: string, actionsLabel: string): ReactNode[] => {
  /* eslint-disable react/jsx-key */
  switch (id) {
    case "notifications_panel":
      return [
        actionsLabel,
        <NotificationsPanel />,
        <NotificationsPanelFilters />,
        <NotificationsPanelActions />
      ];
    default:
      return [false, false, false, false];
  }
};

export default LeftActionPanel;
