// Icon sizes uses across the app.
// On the left - "t-shirt sizes", on the right - size in pixels.
const ICON_SIZES = {
  xs: 14,
  sm: 16,
  md: 20,
  lg: 24,
  xlg: 32,
  xxl: 64,
  xxxl: 80 // Used in resourcing cards
};

export default ICON_SIZES;
