import { ApiRoute } from "PFCore/utilities/routes";

type MarkNotificationsPayload = {
  targetTypes: string[];
};

export const markNotificationsAsRead = ({ targetTypes }: MarkNotificationsPayload) =>
  //@ts-ignore
  $.ajax({
    url: ApiRoute("/api/notifications/mark_as_read"),
    type: "PUT",
    data: {
      target_types: targetTypes
    },
    api_version: 2
  });
