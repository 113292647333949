import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Button } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { fetchMatches } from "PFCore/services/matches";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type RefreshActionProps = {
  activityId?: number;
  onUpdate: VoidFunction;
  showLabel?: boolean;
  qaId?: string;
};

const RefreshAction = ({ activityId, onUpdate, showLabel = true, qaId }: RefreshActionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { matchesMeta, matchesReload } = useActivityPageContext();
  const { formatDateTime } = useDateFormatter();
  const { t } = useTranslation("activities");
  const lastRefreshAt =
    matchesMeta.timestamps && "matched_at" in matchesMeta.timestamps
      ? matchesMeta.timestamps.matched_at
      : null;
  const tooltipMessage =
    lastRefreshAt && t("show.iconsTooltips.lastUpdated", { date: formatDateTime(lastRefreshAt) });

  if (!activityId) {
    return null;
  }

  const handleUpdate = () => {
    setIsLoading(true);
    fetchMatches(activityId, { refresh: true })
      .then(() => {
        matchesReload && matchesReload();
        onUpdate && onUpdate();
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Tooltip content={tooltipMessage}>
      <Button
        icon="refresh"
        disabled={isLoading}
        text={showLabel ? t("show.parts.refreshMatches") : undefined}
        onClick={handleUpdate}
        kind="secondary"
        qaId={qaId}
      />
    </Tooltip>
  );
};

export default RefreshAction;
