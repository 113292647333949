import ActivityContextProvider from "PFApp/activities/show/activity_context_provider";
import { DetailsPanelContextProvider } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context_provider";
import { useParams } from "react-router-dom";

import { BookingFormContextProvider } from "../../booking/components/booking_form";
import { DeleteBookingModalContextProvider } from "../../booking/components/delete_booking_modal/delete_booking_modal_context_provider";
import { DetailsPanel } from "../../booking/components/details_panel";
import { ActivityPage } from "./activity_page";

const ActivityShowController = () => {
  const { id } = useParams();

  return (
    <ActivityContextProvider id={id}>
      <DetailsPanelContextProvider>
        <BookingFormContextProvider>
          <DeleteBookingModalContextProvider>
            <ActivityPage />
            <DetailsPanel />
          </DeleteBookingModalContextProvider>
        </BookingFormContextProvider>
      </DetailsPanelContextProvider>
    </ActivityContextProvider>
  );
};

export default ActivityShowController;
