import { ProfileAvatarConnected } from "PFApp/components/profile_avatar_connected";
import { ActionIcon } from "PFComponents/action_icon";
import { Avatar } from "PFComponents/avatar";
import { MatchAccuracyBadge } from "PFComponents/resource_metrics/match_accuracy";
import { shortlistResponseShapePropType } from "PFCore/base/prop_types/shortlist_response_prop_types";
import { profileHash } from "PFCore/helpers/profile_hash";
import { ProfileNameLink } from "PFCore/helpers/profile_name_link";
import PropTypes from "prop-types";

import { RoleProfileActionButtons } from "../../../components/role_profile_action_buttons";
import { useActivityPageContext } from "../../show/activity_page_context";
import cssDetails from "./compare_details_page.module.scss";
import css from "./compare_profile_header.module.scss";
import { GradeAndRateCell } from "./parts/grade_and_rate_cell";

const CompareProfileHeader = ({ task, comparableProfile, removeProfileId, isUnbiased }) => {
  const { profile } = comparableProfile;
  const normalizedScore = comparableProfile.scores?.normalized_score;
  const { vacanciesLoading, existsOpenVacancy } = useActivityPageContext();

  const isShortlisted = !!comparableProfile.state;

  return (
    <th className={cssDetails.th}>
      <div className={css.profileHeader}>
        {isUnbiased ? (
          <Avatar size={30} backgroundColor="#ffc0cb" text={profileHash(profile.id)} />
        ) : (
          <ProfileAvatarConnected profile={profile} size={30} />
        )}

        {isUnbiased ? (
          <div className={css.unbiasedCandidate}>{`Candidate ${profileHash(profile.id)}`}</div>
        ) : (
          <div className={css.name}>
            &nbsp; {/* handle flex empty cell */}
            <span className={css.nameInner}>
              <ProfileNameLink profile={profile} className={css.profileLink} />
            </span>
          </div>
        )}

        {normalizedScore && (
          <div className={css.rating}>
            <MatchAccuracyBadge value={normalizedScore} />
          </div>
        )}

        <ActionIcon name="cross" size="xs" onClick={() => removeProfileId(profile.id)} />
      </div>

      <RoleProfileActionButtons
        role={task}
        profile={profile}
        shortlist={isShortlisted ? { id: comparableProfile.id, state: comparableProfile.state } : null}
        isFillDisabled={vacanciesLoading || !existsOpenVacancy}
        className={css.buttons}
      />

      <GradeAndRateCell profile={profile} />
    </th>
  );
};

CompareProfileHeader.propTypes = {
  task: PropTypes.object.isRequired,
  comparableProfile: shortlistResponseShapePropType,
  updateOne: PropTypes.func.isRequired,
  removeProfileId: PropTypes.func.isRequired,
  isUnbiased: PropTypes.bool,
  isActivityUnbiased: PropTypes.bool,
  isFillButtonDisabled: PropTypes.bool.isRequired
};

export default CompareProfileHeader;
