import Storage, { cacheBucket, cacheSuffix } from "PFCore/base/storage/storage";
import { endsWith, startsWith } from "underscore.string";

export default class SessionStorage extends Storage {
  setItem = (key, value, options = {}) => {
    this.flushExpired();

    value = this.stringify(value);
    try {
      window.sessionStorage.setItem(this.storageKey(key), value);
    } catch (e) {
      if (this.is_out_of_space(e)) {
        console.warn(`Could not add item with key '${key}', perhaps it's too big?`, e);
      } else {
        console.warn(`Could not add item with key '${key}'`, e);
      }
      return false;
    }

    if (options.expiresIn) {
      window.sessionStorage.setItem(this.expirationKey(key), this.expirationTimeFor(options.expiresIn));
    } else {
      if (!options.preserveTime) {
        window.sessionStorage.removeItem(this.expirationKey(key));
      }
    }
  };

  getItem = (key) => {
    this.flushExpiredItem(key);

    const value = window.sessionStorage.getItem(this.storageKey(key));
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  };

  removeItem = (key) => {
    window.sessionStorage.removeItem(this.storageKey(key));
    window.sessionStorage.removeItem(this.expirationKey(key));
  };

  clear = () => {
    window.sessionStorage.clear();
  };

  key = (index) => {
    window.sessionStorage.key(index);
  };

  eachKey = (callback) => {
    Object.keys(window.sessionStorage).forEach((key) => {
      if (key && startsWith(key, cacheBucket) && !endsWith(key, cacheSuffix)) {
        callback(key.replace(cacheBucket, ""));
      }
    });
  };

  expirationTime = (key) => {
    const time = window.sessionStorage.getItem(this.expirationKey(key));
    if (time) {
      return parseInt(time, 10);
    } else {
      return null;
    }
  };
}
