import { TableColumn } from "PFApp/components/table/table_types";

const defaultColumns: TableColumn[] = [
  {
    locked: true,
    orderKey: "profile.name",
    name: "name",
    title: "activities:show.interested.table.headers.name",
    size: 2,
    type: "text",
    isDefault: true,
    attribute: ["profile"]
  },
  {
    locked: true,
    orderKey: "relation.activity_has_interested_profiles.shortlisted",
    name: "shortlist",
    title: "activities:show.interested.table.headers.shortlist",
    size: 2,
    type: "button",
    isDefault: true,
    attribute: ["profile"]
  },
  {
    locked: false,
    name: "profile_id",
    title: "activities:show.interested.table.headers.profileId",
    size: 1,
    type: "text",
    isDefault: false,
    attribute: ["profile", "id"]
  },
  {
    locked: false,
    orderKey: "relation.activity_has_matched_profile.normalized_score",
    name: "normalized_score",
    title: "activities:show.interested.table.headers.match",
    size: 1,
    type: "score",
    isDefault: true,
    attribute: ["scores"]
  },
  {
    locked: false,
    name: "availability_score",
    orderKey: "relation.activity_has_available_profile.availability_score",
    title: "activities:show.interested.table.headers.availability",
    size: 1,
    type: "score",
    isDefault: true,
    attribute: ["scores"]
  },
  {
    locked: false,
    name: "statement",
    title: "activities:show.interested.table.headers.statement",
    size: 3,
    type: "text",
    isDefault: true
  },
  {
    locked: false,
    orderKey: "relation.activity_has_interested_profiles.interested_at",
    name: "created_at",
    title: "activities:show.interested.table.headers.createdAt",
    size: 2,
    type: "date",
    isDefault: true
  },
  {
    locked: false,
    name: "questions",
    title: "activities:show.interested.table.headers.questions",
    size: 2,
    type: "text",
    isDefault: false,
    attribute: ["activity", "questions"]
  }
];

export default defaultColumns;
