import { ColumnHelper } from "@tanstack/react-table";
import { LoadingDots } from "PFComponents/loading_dots";
import Table from "PFComponents/table";
import { useProfilesOptions } from "PFCore/hooks/queries/profiles/use_profiles_options";
import { ProfileBasicInformation } from "PFTypes";
import React from "react";

import { WorkforceMemberColumn } from "../columns/workforce_member_column";
import { RowSelection } from "./reassign_content";

type NamedResourceTableProps = {
  searchValue: string;
} & RowSelection;

export const NamedResourceTable = ({
  searchValue,
  onTargetProfileChange,
  rowSelectionState
}: NamedResourceTableProps) => {
  const { data, isLoading } = useProfilesOptions({
    term: searchValue
  });

  const columnsGenerator = (columnHelper: ColumnHelper<ProfileBasicInformation>) => [
    columnHelper.accessor((row) => row, {
      id: "workforceMember",
      header: "",
      cell: (info) => <WorkforceMemberColumn profile={info.getValue()} />,
      enableSorting: false
    })
  ];

  if (isLoading) {
    return <LoadingDots circlesEnabled circleSize="sm" centered />;
  }

  return (
    <Table<ProfileBasicInformation>
      tableData={data?.entries ?? []}
      generateColumns={columnsGenerator}
      enableRowSelection
      onRowSelectionChange={onTargetProfileChange}
      rowSelection={rowSelectionState}
      disableHeader
      getRowId={(profile) => `${profile.id}`}
      disableAutoResizing
    />
  );
};
