import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";

import css from "./entity_row.module.scss";

type EntityRowProps = {
  title: string;
  subtitle?: string;
  iconName: IconName;
  redirect: boolean;
  entityLabel?: string;
};

export const EntityRow = ({ title, subtitle, iconName, redirect, entityLabel }: EntityRowProps) => (
  <div className={css.row}>
    <Icon name={iconName} />
    <span className={css.info}>
      <Typography variant="bodyRegular" noMargin clipOverflow>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="labelRegular" noMargin clipOverflow>
          {subtitle}
        </Typography>
      )}
    </span>
    <span className={css.rightSideContent}>
      {entityLabel && (
        <Typography variant="labelRegular" className={css.entityLabel} noMargin>
          {entityLabel}
        </Typography>
      )}
      {redirect && <Icon name="open" size="md" color="paletteBlue2" />}
    </span>
  </div>
);
