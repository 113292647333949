import { useCallback, useEffect, useMemo } from "react";

import { useFeedbackContext } from "../feedback_context_provider";
import { FeedbackFormData } from "../form/feedback_form_context_provider";

type SavedFeedbackForms = {
  participantSavedForm: FeedbackFormData | undefined;
  updateSavedForms: () => void;
  resetSavedForm: () => void;
};

export const useSavedFeedbackForms = (): SavedFeedbackForms => {
  const {
    selectedParticipant,
    selectedRole,
    activeFeedbackRound,
    currentFormData,
    savedForms,
    setSavedForms,
    setCurrentFormData
  } = useFeedbackContext();

  const entryKey = useMemo(
    () => `${selectedParticipant?.id}-${selectedRole?.id}`,
    [selectedParticipant?.id, selectedRole?.id]
  );

  const participantSavedForm = useMemo(() => savedForms?.[entryKey], [entryKey, savedForms]);

  const updateSavedForms = useCallback(() => {
    if (!selectedParticipant || !selectedRole || !currentFormData) {
      return;
    }
    setSavedForms((prev) => ({
      ...prev,
      [entryKey]: {
        ...currentFormData,
        round: activeFeedbackRound?.id
      }
    }));
  }, [selectedParticipant, selectedRole, currentFormData, setSavedForms, entryKey, activeFeedbackRound?.id]);

  const resetSavedForm = useCallback(() => {
    if (!selectedParticipant) {
      return;
    }
    setCurrentFormData(null);
    setSavedForms({ ...savedForms, [entryKey]: null });
  }, [entryKey, savedForms, selectedParticipant, setCurrentFormData, setSavedForms]);

  useEffect(() => {
    setCurrentFormData(null);
  }, [selectedParticipant?.id, setCurrentFormData]);

  return {
    participantSavedForm,
    updateSavedForms,
    resetSavedForm
  };
};
