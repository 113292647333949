import { filter } from "lodash";
import { Card } from "PFComponents/card";
import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { EconomicsVacancy, Id } from "PFTypes";
import { useMemo } from "react";

import { AssignmentsForm } from "./assignments_form";
import css from "./role_card.module.scss";
import { VacanciesTable } from "./vacancies_table";

type RoleCardProps = {
  vacancies: EconomicsVacancy[];
  scenarioId: Id;
  isEditable?: boolean;
};

export const RoleCard = ({ vacancies, scenarioId, isEditable = true }: RoleCardProps) => {
  const roleName: string = useMemo(() => vacancies[0].activity.name, [vacancies]);

  const [filledVacancies, emptyVacancies] = useMemo(
    () =>
      isEditable
        ? [
            filter(vacancies, "assignment.profile"),
            filter(vacancies, (vacancy) => !vacancy.assignment?.profile)
          ]
        : [vacancies, []],
    [vacancies, isEditable]
  );

  return (
    <Card className={css.roleCard}>
      <Typography variant="h5" noMargin className={css.roleTitle}>
        {roleName} ({vacancies.length})
      </Typography>
      {!!filledVacancies.length && (
        <VacanciesTable vacancies={filledVacancies} scenarioId={scenarioId} isEditable={isEditable} />
      )}
      {!!filledVacancies.length && !!emptyVacancies.length && <Divider />}
      {!!emptyVacancies.length && <AssignmentsForm emptyVacancies={emptyVacancies} scenarioId={scenarioId} />}
    </Card>
  );
};
