import { Icon } from "PFComponents/icon";
import Toggle from "PFComponents/toggle/toggle";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { useActivityEditFormContext } from "../../../activity_edit_form_context";
import css from "./activity_skills_settings.module.scss";

export const ActivitySkillsSettings = () => {
  const { isDevelopmental, toggleIsDevelopmental } = useActivityEditFormContext();
  const { t } = useTranslation("activities", { keyPrefix: "edit.sections.skillsSettings" });

  return (
    <div className={css.settingsRow}>
      <div className={css.settingsElement}>
        <Toggle onChange={toggleIsDevelopmental} checked={isDevelopmental} compact />
        <Typography variant="labelRegular">{t("developmentalSkillsToggleLabel")}</Typography>
      </div>
      {isDevelopmental && (
        <div className={css.settingsElement}>
          <Icon name="info" />
          <Typography variant="labelRegular">{t("developmentalSkillsInfo")}</Typography>
        </div>
      )}
    </div>
  );
};
