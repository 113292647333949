import TooltipComponent from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export const OverscheduleTimeTooltip = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings" });

  return (
    <TooltipComponent
      content={
        <>
          <Typography variant="bodyBold" noMargin tag="p">
            {t("overscheduleTimeTooltip.header")}
          </Typography>
          <Typography variant="bodyRegular" noMargin tag="p">
            {t("overscheduleTimeTooltip.content")}
          </Typography>
        </>
      }
    >
      {children}
    </TooltipComponent>
  );
};
