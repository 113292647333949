import classNames from "classnames";
import { IMatchScores } from "PFTypes";
import { useTranslation } from "react-i18next";

import Tooltip from "../../tooltip/tooltip";
import css from "../match_gauge.module.scss";
import { DonutSegment } from "./donut_segment";

const sanitizeValue = (value: number) => parseFloat(String(Math.min(value, 1)));

type DonutChartProps = {
  match: {
    scores: IMatchScores;
  };
  hoveredSegment: string | null;
  isLoading?: boolean;
};

export const DonutChart = ({ match, hoveredSegment, isLoading }: DonutChartProps): JSX.Element | null => {
  const { t } = useTranslation("core", { keyPrefix: "matchGauge" });
  const {
    scores: {
      score,
      normalized_score: normalizedScore,
      normalized_direct: normalizedDirectScore,
      normalized_indirect: normalizedIndirectScore,
      normalized_related: normalizedRelatedScore,
      direct: directScore,
      indirect: indirectScore,
      related: relatedScore
    }
  } = match;

  const sanitizedNormalizedScore = sanitizeValue(normalizedScore ?? 0);

  const direct = score ? sanitizeValue((sanitizedNormalizedScore * (directScore ?? 0)) / score) : 0;
  const indirect = score ? sanitizeValue((sanitizedNormalizedScore * (indirectScore ?? 0)) / score) : 0;
  const related = score ? sanitizeValue((sanitizedNormalizedScore * (relatedScore ?? 0)) / score) : 0;

  const segments = {
    direct: normalizedDirectScore,
    indirect: normalizedIndirectScore,
    related: normalizedRelatedScore
  };

  const percent = (
    (sanitizeValue((hoveredSegment ? segments[hoveredSegment] : normalizedScore) ?? 0) || 0) * 100
  ).toFixed(1);

  return (
    <div className={classNames(css.donutContainer, { [css.isLoading]: isLoading })}>
      <svg width="86px" height="86px" viewBox="0 0 86 86" className={css.donut}>
        <circle
          className={css.circle}
          cx="43"
          cy="43"
          r="36"
          fill="transparent"
          strokeWidth="1"
          strokeDasharray="1 4"
          strokeLinecap="round"
        ></circle>
        <DonutSegment score={direct} name="direct" hoveredSegment={hoveredSegment} />
        <DonutSegment startScore={direct} score={indirect} name="indirect" hoveredSegment={hoveredSegment} />
        <DonutSegment
          startScore={direct + indirect}
          score={related}
          name="related"
          hoveredSegment={hoveredSegment}
        />
      </svg>
      <Tooltip content={t("matchChartHeader")}>
        <div className={classNames(css.donutPercent)}>
          {percent}
          <span>%</span>
        </div>
      </Tooltip>
    </div>
  );
};
