import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { ApiRoute } from "PFCore/utilities/routes";

export const ENGAGEMENTS_ASYNC_ACTIONS = {
  FETCH_COLLECTION: "FETCH_ENGAGEMENTS"
};

export const engagementsDispatch = (dispatch, action) => {
  switch (action.type) {
    case ENGAGEMENTS_ASYNC_ACTIONS.FETCH_COLLECTION: {
      return fetchPaginatedCollection(ApiRoute("/api/engagements"), action.payload.params);
    }
  }
};
