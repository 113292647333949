import api from "PFCore/api";

export const fetchCertificate = (id) => api({ url: `certificates/${id}` });

export const fetchCertificates = (profileId, page = 1, perPage = 10, customValueIds: number[] = []) =>
  api({
    url: `certificates?profile_id=${profileId}`,
    params: {
      page,
      perPage,
      ...(customValueIds.length > 0 ? { customValueIds } : {})
    }
  });

export const createCertificate = (payload) =>
  api({
    method: "POST",
    url: "certificates",
    data: payload
  });

export const updateCertificate = (payload) =>
  api({
    method: "PUT",
    url: `certificates/${payload.id}`,
    data: payload
  });

export const deleteCertificate = (id) =>
  api({
    method: "DELETE",
    url: `certificates/${id}`
  });
