import safePrune from "PFCore/helpers/safe_prune";
import PropTypes from "prop-types";

import css from "./cards.module.scss";

const FeedCard = ({ item }) => {
  const title = safePrune(item.title, 95);
  return (
    <a className={css.feed} href={item.link} target="_blank" rel="noopener noreferrer">
      <div className={css.imageWrapper}>
        <img src={item.image_url} className={css.image} alt="" />
      </div>
      <div className={css.description}>{title}</div>
    </a>
  );
};

FeedCard.propTypes = {
  item: PropTypes.shape({
    image_url: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string
  })
};

export default FeedCard;
