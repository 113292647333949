import { isArray, isEmpty, uniq } from "lodash";
import AutoSelect from "PFComponents/select/autoselect";
import { Typography } from "PFComponents/typography";
import { useDeterministicStringify } from "PFCore/helpers/use_deterministic_stringity";
import useForceUpdate from "PFCore/helpers/use_force_update";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { letFilterRestore } from "../../use_filtered_collection";
import css from "./select_many_item.module.scss";

const SelectManyItem = ({ label, labelTooltip, filter, handleChange, error, portalRef, disabled }) => {
  const { t } = useTranslation();
  const [cacheValue, setCurrentValue] = useState([]);
  const forceUpdate = useForceUpdate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [displayValues, setDisplayValues] = useState(null);
  const stringify = useDeterministicStringify();

  const { length } = cacheValue;

  useEffect(() => {
    let isRunning = true;
    if (isEmpty(filter.value)) {
      if (isRunning) {
        setCurrentValue([]);
      }
    } else {
      filter.query({ value: filter.value }).then((resp) => {
        if (isRunning) {
          // for example when calling custom values endpoint, return in flat, not collection
          setCurrentValue(isArray(resp?.entries) ? resp.entries : resp);
        }
      });
    }

    return () => {
      isRunning = false;
    };
  }, [filter.value]);

  useEffect(() => {
    if (length === 1) {
      setDisplayValues(cacheValue[0].text);
    } else if (length > 1) {
      const isOR = filter.operator === "any";
      const label = isOR
        ? t("filters.filtersSelectedOR", { count: length })
        : t("filters.filtersSelected", { count: length });
      setDisplayValues(label);
    } else {
      setDisplayValues(null);
    }
  }, [dropdownOpen, cacheValue]);

  const letRestore = letFilterRestore(filter);

  return (
    <div>
      <AutoSelect
        key={[filter.value]
          .flat()
          .map((value) => `${stringify(value)}`)
          .join(",")}
        name={filter.name}
        disabled={filter.disabled || disabled}
        error={error}
        handleChange={(values) => {
          const newValueIds = values.map((value) => value.id);
          setCurrentValue(values); // make sure the render part would still work
          handleChange(newValueIds.length > 0 ? [...uniq(newValueIds)] : null, values);
          forceUpdate();
        }}
        cache={false}
        letClear={filter.value?.length > 0}
        onRestore={letRestore ? () => handleChange(filter.defaultValue) : undefined}
        closeOnChange={false}
        values={cacheValue}
        label={label}
        labelTooltip={labelTooltip}
        query={(term) => filter.query({ term })}
        multi={true}
        showValues={false}
        // TODO: [PROF-7192] - remove workaround with displaying value (introduced in PROF-7187)
        displayValues={
          <Typography className={css.displayValues} variant="bodyBold" noMargin clipOverflow>
            {displayValues}
          </Typography>
        }
        hideDisplayValuesOnFocus
        handleDropDownToggle={setDropdownOpen}
        preOptions={
          <div className={css.preOptions}>
            {cacheValue?.map(({ id, text }) => (
              <div key={id} className={css.preOption}>
                {/* eslint-disable */}
                <a
                  onClick={() => {
                    const values = cacheValue.filter((val) => val.id !== id);
                    const ids = values.map((val) => val.id);
                    handleChange(ids.length === 0 ? null : ids);
                    setCurrentValue(values); // make sure the render part would still work
                    setDropdownOpen(false);
                    forceUpdate();
                  }}
                >
                  &times;
                </a>
                <span>{` ${text}`}</span>
              </div>
            ))}
          </div>
        }
        portalRef={portalRef}
      />
    </div>
  );
};

SelectManyItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string,
    query: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    operator: PropTypes.oneOf(["any", "all"]),
    disabled: PropTypes.bool,
    letClear: PropTypes.bool
  }).isRequired,
  label: PropTypes.oneOfType(PropTypes.string),
  labelTooltip: PropTypes.shape({
    icon: PropTypes.string,
    content: PropTypes.string
  }),
  error: PropTypes.string,
  portalRef: PropTypes.object,
  disabled: PropTypes.bool
};

export default SelectManyItem;
