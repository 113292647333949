import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { Meta } from "PFTypes";
import { useEffect, useRef, useState } from "react";

type UnknownResponse = {
  meta: Meta<unknown>;
};

type UseRequestAttempts = {
  maxAttempts?: number;
  delayPerAttempt?: number;
  revertOnNotReady?: boolean;
  queryKey?: QueryKey;
};

type OnReadyFunction = (res: UnknownResponse) => void;

const useRequestAttempts = ({
  maxAttempts = 12,
  delayPerAttempt = 500,
  revertOnNotReady = false,
  queryKey
}: UseRequestAttempts = {}) => {
  const [attempt, setAttempt] = useState(0);
  const [previousResponse, setPreviousResponse] = useState<UnknownResponse | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const queryClient = useQueryClient();

  const cancelNextAttempt = (): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(
    () => () => {
      cancelNextAttempt();
    },
    []
  );

  const handleReadyState = (response: UnknownResponse, onReady?: OnReadyFunction): void => {
    setAttempt(0);
    setPreviousResponse(response);
    onReady && onReady(response);
    cancelNextAttempt();
  };

  const handleNotReadyState = (fetchFunction: VoidFunction): void => {
    if (revertOnNotReady && !!queryKey && previousResponse) {
      queryClient.setQueryData(queryKey, previousResponse);
    }
    timeoutRef.current = setTimeout(() => {
      setAttempt((currentAttempt) => currentAttempt + 1);
      fetchFunction();
    }, delayPerAttempt * attempt);
  };

  const handleSuccessfulAttempt = (
    response: UnknownResponse,
    fetchFunction: VoidFunction,
    onReady?: OnReadyFunction
  ): void => {
    if (attempt < maxAttempts) {
      if (response.meta?.status === "ready") {
        handleReadyState(response, onReady);
      } else {
        handleNotReadyState(fetchFunction);
      }
    } else {
      setAttempt(0);
    }
  };

  return {
    handleSuccessfulAttempt
  };
};

export default useRequestAttempts;
