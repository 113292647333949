import { find } from "lodash";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { PermissionRule } from "PFTypes";
import { useMemo } from "react";

export const useIsWorkflowPermitted = () => {
  const { data: currentProfile } = useCurrentProfile();
  const isPermittedTo = useIsPermittedTo(currentProfile);

  const workflowAccess = useMemo(() => {
    const engagementReadPermitted = find(currentProfile.templates, {
      key: "engagement"
    })?.read_permitted;
    const roleReadPermitted = find(currentProfile.templates, { key: "role" })?.read_permitted;
    const isResourcer = isPermittedTo(PermissionRule.Resourcer);
    const isSeniorPartner = isPermittedTo(PermissionRule.SeniorPartner);
    return (engagementReadPermitted || roleReadPermitted) && (isResourcer || isSeniorPartner);
  }, [currentProfile.id, isPermittedTo]);

  return workflowAccess;
};

export default useIsWorkflowPermitted;
