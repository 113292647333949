import { Experience } from "PFTypes";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useExperienceText = () => {
  const { t } = useTranslation(["skillsFrameworks", "core"]);

  const experienceTextMap = useMemo(
    () => ({
      [Experience.Basic]: t("core:skillRatings.basic"),
      [Experience.Intermediate]: t("core:skillRatings.intermediate"),
      [Experience.Advanced]: t("core:skillRatings.advanced")
    }),
    [t]
  );

  return useCallback(
    (experience: Experience) => experienceTextMap[experience] || experienceTextMap[Experience.Intermediate],
    [experienceTextMap]
  );
};
