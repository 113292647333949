import classNames from "classnames";
import { Avatar } from "PFComponents/avatar";
import { Card } from "PFComponents/card";
import Toggle from "PFComponents/toggle/toggle";
import { Typography } from "PFComponents/typography";
import { ChangeRequest } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../change_request_page.module.scss";
import { DisabledItems, SetDisabledItems } from "../change_request_page.types";

type AvatarSectionProps = {
  data: ChangeRequest["data"];
  disabledItems: DisabledItems;
  setDisabledItems: SetDisabledItems;
};

export const AvatarSection = ({ data, disabledItems, setDisabledItems }: AvatarSectionProps) => {
  const { t } = useTranslation("changeRequests");
  if (!data?.avatar?.url) {
    return null;
  }

  const isDisabled = disabledItems.avatar;

  return (
    <Card qaId="ChangeRequest.Avatar">
      <Typography variant="h2" className={css.header}>
        {t("parts.avatar")}
        <Toggle
          checked={!isDisabled}
          onChange={(val) => setDisabledItems((prev) => ({ ...prev, avatar: !val }))}
        />
      </Typography>
      <div className={classNames(css.avatar, { [css.fade]: isDisabled })}>
        <Avatar imagePath={data?.avatar?.url} size={120} />
      </div>
    </Card>
  );
};
