import { LoadingDots } from "PFComponents/loading_dots";
import { Component } from "react";

export default class ChatLoading extends Component {
  render() {
    return (
      <div style={{ textAlign: "center", marginTop: 30 }}>
        <LoadingDots />
      </div>
    );
  }
}
