import { isEqual } from "lodash";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Select } from "PFComponents/select/select";
import { Typography } from "PFComponents/typography";
import { Id } from "PFTypes";
import React from "react";

type WarningsFilterProps = {
  isSingleOption: boolean;
  singleOptionValue?: string;
  label: string;
  options: DropdownOption[];
  selectedOption: Id;
  onChangeSelectedOption: (value: Id) => void;
};

export const WarningsFilter = ({
  isSingleOption,
  singleOptionValue,
  label,
  options,
  selectedOption,
  onChangeSelectedOption
}: WarningsFilterProps) => {
  if (isSingleOption) {
    return (
      <Typography variant="bodyBold" tag="span">
        {singleOptionValue}
      </Typography>
    );
  }
  return (
    <Select
      label={label}
      controlledValue
      options={options}
      value={options.find(({ item }) => isEqual(item, selectedOption))?.displayElement}
      onChange={(value: string) => onChangeSelectedOption(value as Id)}
    />
  );
};
