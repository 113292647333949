import { isArray, mapValues, sortBy, uniqBy } from "lodash";
import PROFILE_SECTIONS from "PFApp/profiles/profile_sections";
import { useEdgeToEdgeLayout } from "PFApp/react_app_layout";
import { useGrowl } from "PFApp/use_growl";
import { hasCustomTypeSection } from "PFCore/helpers/custom_types";
import { customTypesAccessLevelsAgainstProfile } from "PFCore/helpers/custom_types";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import PropTypes from "prop-types";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { SkipToContent } from "../../components/skip_to_content";
import { ExperienceSection } from "./parts/experience_section";
import { AccomplishmentsSection } from "./parts/section_accomplishments";
import AdditionalSection from "./parts/section_additional";
import SummarySection from "./parts/section_summary";
import SidePanel from "./parts/side_panel";
import css from "./profile_show_page.module.scss";

const BASIC_INFO_HARDCODED_FIELDS = ["business_unit", "talent_pool", "grade", "location", "day_rate"];

export const getBasicInfoTypes = (profile, currentProfile, customTypes) => {
  const accessLevels = customTypesAccessLevelsAgainstProfile(profile, currentProfile);
  const types = (customTypes || [])
    .filter(
      (ct) =>
        hasCustomTypeSection(ct, PROFILE_SECTIONS.profile_basic_info) ||
        BASIC_INFO_HARDCODED_FIELDS.indexOf(ct.name) >= 0
    )
    .filter((ct) => accessLevels[ct.id])
    .map((ct) => ct.name);

  return types.sort((name, nameB) => {
    const ordinalA = customTypes.find((ct) => ct.name === name).position;
    const ordinalB = customTypes.find((ct) => ct.name === nameB).position;
    return ordinalA > ordinalB ? 1 : -1;
  });
};

const ProfileShowPage = ({
  profile,
  onProfileUpdate,
  interestsCollection,
  initSection,
  internalExperiences,
  fetchInternalExperience
}) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles");

  const { section } = useParams();
  const growl = useGrowl();
  const { getFormattedErrors } = useResponseErrors();
  const { loadForSections, customTypes } = useCustomTypes();

  const setEditModeReducer = (state, action) => ({ ...state, [action.type]: action.payload });
  const [editMode, dispatchEditModeAction] = useReducer(setEditModeReducer, {});

  const setEditMode = (section, value) => dispatchEditModeAction({ type: section, payload: value });

  const [isPendingUpdate, setIsPendingUpdate] = useState(false);
  const [errors, setErrors] = useState(null);

  useEdgeToEdgeLayout();

  const isAccomplishmentsSectionVisible = currentAccount.profile_view_customization.accomplishment_section;

  const sectionComponents = useMemo(
    () => ({
      summary: SummarySection,
      experience: ExperienceSection,
      ...(isAccomplishmentsSectionVisible ? { accomplishments: AccomplishmentsSection } : {}),
      additional: AdditionalSection
    }),
    [isAccomplishmentsSectionVisible]
  );

  useEffect(() => {
    setErrors(null);
  }, [editMode]);

  const isMe = profile?.id && profile?.id === currentProfile?.id;

  const accessLevels = customTypesAccessLevelsAgainstProfile(profile, currentProfile);
  const accreditationsType = (customTypes || []).find((type) => type.name === "accreditations");
  const accreditationsAccessLevel = accessLevels[accreditationsType?.id];
  const notRankableCustomTypes = customTypes.filter(
    (ct) => !ct.sections.find((section) => section.key === "rankable")
  );
  const basicCustomTypes = getBasicInfoTypes(profile, currentProfile, notRankableCustomTypes);

  const experienceCustomTypes = useMemo(() => {
    const privateCustomTypes = isMe
      ? customTypes.filter((ct) => hasCustomTypeSection(ct, PROFILE_SECTIONS.profile_private_fields))
      : [];

    const rankableCustomTypes = customTypes.filter((ct) => {
      const isRankable = hasCustomTypeSection(ct, "rankable");
      const isPrivate = hasCustomTypeSection(ct, PROFILE_SECTIONS.profile_private_fields);
      return isMe ? isRankable : isRankable && !isPrivate;
    });

    return sortBy(
      uniqBy(
        loadForSections(PROFILE_SECTIONS.profile_expertise)
          .concat(privateCustomTypes)
          .filter((ct) => ["ro", "rw"].includes(accessLevels[ct.id]))
          .filter((ct) => !ct.sections.find((section) => section.key === "rankable"))
          .concat(rankableCustomTypes),
        "id"
      )
        .filter((ct) => ct.name !== "skills")
        .filter((ct) => ct.name !== "accreditations")
        .filter((ct) => ct.name !== "security_clearances")
        .filter((ct) => basicCustomTypes.indexOf(ct.name) < 0)
        .filter((ct) => BASIC_INFO_HARDCODED_FIELDS.indexOf(ct.name) < 0),
      ["position"]
    );
  }, [accessLevels, basicCustomTypes, loadForSections, customTypes, isMe]);

  const sectorsType = (experienceCustomTypes || []).find((type) => type.name === "sectors");
  const sectorsAccessLevel = accessLevels[sectorsType?.id];
  const typeToShowInGrid = accreditationsAccessLevel
    ? accreditationsType
    : sectorsAccessLevel
    ? sectorsType
    : null;

  const handleProfileUpdate = (data = {}, options = { renderGrowl: true }) => {
    setIsPendingUpdate(true);
    setErrors(null);
    return onProfileUpdate(data)
      .always(() => setIsPendingUpdate(false))
      .catch((resp) => {
        let message = t("unknownError");
        const profileErrors = mapValues(getFormattedErrors(resp), (val) =>
          isArray(val) ? val.join(", ") : val
        );
        if (Object.values(profileErrors).length > 0) {
          setErrors(profileErrors);
          message = t("formNotSaved");
        }
        if (options.renderGrowl) {
          growl({
            message,
            kind: "error"
          });
        }

        return Promise.reject(resp);
      });
  };

  const SectionComponent = sectionComponents[section] || SummarySection;

  return (
    <div className={css.outerRoot}>
      <div id="main-content" className={css.root}>
        <SkipToContent
          targetId="profile-navigation"
          text={t("show.parts.skipLinks.profileNavigation")}
          isRoot
        />
        {profile ? (
          <SidePanel
            isMe={isMe}
            profile={profile}
            experienceCustomTypes={experienceCustomTypes}
            interestsCount={interestsCollection.entries.length}
            initSection={initSection}
            onProfileUpdate={handleProfileUpdate}
          />
        ) : (
          "..."
        )}
        <SectionComponent
          className={css.mainPanel}
          profile={profile}
          currentProfile={currentProfile}
          handleProfileUpdate={handleProfileUpdate}
          editMode={editMode}
          me={isMe}
          typeToShowInGrid={typeToShowInGrid}
          isPendingUpdate={isPendingUpdate}
          errors={errors}
          setEditMode={setEditMode}
          basicCustomTypes={basicCustomTypes}
          interestsCollection={interestsCollection}
          initSection={initSection}
          internalExperiences={internalExperiences}
          fetchInternalExperience={fetchInternalExperience}
          customTypes={customTypes}
          experienceCustomTypes={experienceCustomTypes}
        />
      </div>
    </div>
  );
};

ProfileShowPage.propTypes = {
  internalExperiences: PropTypes.arrayOf(PropTypes.object),
  profile: PropTypes.object,
  interestsCollection: PropTypes.shape({
    entries: PropTypes.array,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    setCurrentPage: PropTypes.func,
    pending: PropTypes.bool
  }),
  initSection: PropTypes.string,
  onProfileUpdate: PropTypes.func,
  fetchInternalExperience: PropTypes.func
};

export default ProfileShowPage;
