import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMessage } from "PFCore/services/chat/delete_message";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";

import { chatKeys } from "./query_keys";

export const useDeleteChatMessage = (conversationId: Id, options?: MutationOptions<string>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteMessage(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: chatKeys.messages(conversationId) });
      options?.onSuccess?.(...args);
    },
    ...options
  });
};
