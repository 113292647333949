import { ChangeRequestPage } from "PFApp/change_requests/change_request_page";
import ContactView from "PFApp/contact/contact_view";
import { EmailConfirmationController } from "PFApp/email_confirmation/email_confirmation_controller";
import EditForgottenPasswordPage from "PFApp/forgot_password/edit_forgotten_password_page";
import ActivitiesRoutes from "PFApp/routes/activities_routes";
import MsTeamsRoutes from "PFApp/routes/ms_teams_routes";
import NotFoundRoute from "PFApp/routes/not_found_route";
import ProfileRoutes from "PFApp/routes/profile_routes";
import ProfilesRoutes from "PFApp/routes/profiles_routes";
import { RootRoute } from "PFApp/routes/root_route";
import {
  EnsureLoggedIn,
  EnsureLoggedOut,
  EnsureRegistrationComplete,
  WithLayout
} from "PFApp/routes/route_helpers";
import SearchRoutes from "PFApp/routes/search_routes";
import UsersRoutes, { SignOutRoute, TokenResetRoute, WelcomeRoute } from "PFApp/routes/users_routes";
import SamlSignInPage from "PFApp/signin/saml_sign_in_page";
import RequestAccessPage from "PFApp/signup/request_access_page";
import TermsOfService from "PFApp/terms_of_service/terms_of_service";
import { auto, headerOnly } from "PFCore/base/page_layouts";
import { PermissionRule } from "PFTypes";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";

import { EnsureProfilePermission } from "./routes/route_helpers";

const AdminRoutes = lazy(() => import(/* webpackChunkName: "admin" */ "PFApp/routes/admin_routes"));
const AnalyticsRoutes = lazy(() =>
  import(/* webpackChunkName: "analytics" */ "PFApp/routes/analytics_routes").then(({ AnalyticsRoutes }) => ({
    default: AnalyticsRoutes
  }))
);
const AuditPlannerRoutes = lazy(() =>
  import(/* webpackChunkName: "audit_planner" */ "PFApp/routes/audit_planner_routes")
);
const BookingRoutes = lazy(() =>
  import(/* webpackChunkName: "booking_module" */ "PFApp/routes/booking_routes")
);
const FeedRoutes = lazy(() => import(/* webpackChunkName: "feed" */ "PFApp/routes/feed_routes"));
const MarketplaceRoutes = lazy(() =>
  import(/* webpackChunkName: "marketplace" */ "PFApp/routes/marketplace_routes").then(
    ({ MarketplaceRoutes }) => ({
      default: MarketplaceRoutes
    })
  )
);
const OnboardingRoutes = lazy(() =>
  import(/* webpackChunkName: "onboarding" */ "PFApp/routes/onboarding_routes")
);

const SaasAdminRoutes = lazy(() =>
  import(/* webpackChunkName: "saas_admin" */ "PFApp/routes/saas_admin_routes")
);
const WorkflowRoutes = lazy(() => import(/* webpackChunkName: "workflow" */ "PFApp/routes/workflow_routes"));

const MainRouter = () => (
  <Switch>
    <Route exact path="/authsaml">
      <EnsureLoggedOut>
        <SamlSignInPage />
      </EnsureLoggedOut>
    </Route>
    <Route exact path="/email_confirmation">
      <EmailConfirmationController />
    </Route>
    <Route exact path="/request_access">
      <WithLayout layout={{ sidebar: headerOnly }}>
        <RequestAccessPage />
      </WithLayout>
    </Route>
    <Route exact path={["/contact_us", "/contact-us"]}>
      <EnsureLoggedIn>
        <EnsureProfilePermission permittedTo={PermissionRule.ContactForm}>
          <WithLayout layout={{ sidebar: auto }}>
            <ContactView />
          </WithLayout>
        </EnsureProfilePermission>
      </EnsureLoggedIn>
    </Route>
    <Route exact path="/terms">
      <WithLayout layout={{ sidebar: auto }}>
        <TermsOfService />
      </WithLayout>
    </Route>
    <Route path="/users">
      <UsersRoutes />
    </Route>
    <Route path="/ms_teams">
      <MsTeamsRoutes />
    </Route>
    <Route exact path="/password_edit">
      <WithLayout layout={{ sidebar: headerOnly }}>
        <EditForgottenPasswordPage />
      </WithLayout>
    </Route>
    <Route exact path="/edit_password/from_welcome/:resetToken">
      <TokenResetRoute />
    </Route>
    <Route path="/onboarding">
      <OnboardingRoutes />
    </Route>
    <Route path="/welcome">
      <WelcomeRoute />
    </Route>
    <Route path="/workflow">
      <WorkflowRoutes />
    </Route>
    <Route path="/audit_planner">
      <AuditPlannerRoutes />
    </Route>
    <Route path="/activities">
      <ActivitiesRoutes />
    </Route>
    <Route path="/booking">
      <BookingRoutes />
    </Route>
    {/* <Route path="/insights">
      <InsightsRoutes />
    </Route> */}
    <Route path="/analytics">
      <AnalyticsRoutes />
    </Route>
    <Route path="/feed">
      <FeedRoutes />
    </Route>
    <Route path="/marketplace">
      <MarketplaceRoutes />
    </Route>
    <Route path="/profiles">
      <ProfilesRoutes />
    </Route>
    <Route path="/profile">
      <ProfileRoutes />
    </Route>
    <Route path="/search">
      <SearchRoutes />
    </Route>
    <Route path="/change_requests/:id">
      <ChangeRequestPage />
    </Route>
    <Route path="/admin">
      <AdminRoutes />
    </Route>
    <Route path="/saas_admin">
      <SaasAdminRoutes />
    </Route>
    <Route exact path="/sign_out">
      <EnsureLoggedIn>
        <SignOutRoute />
      </EnsureLoggedIn>
    </Route>
    <Route exact path="/">
      <RootRoute />
    </Route>
    <Route path="*">
      <EnsureRegistrationComplete>
        <NotFoundRoute />
      </EnsureRegistrationComplete>
    </Route>
  </Switch>
);

export default MainRouter;
