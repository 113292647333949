import { useQuery } from "@tanstack/react-query";
import { fetchParticipants, FetchParticipantsPayload } from "PFCore/services/chat/fetch_paricipants";

import { chatKeys } from "./query_keys";

export const useChatParticipants = ({ conversationId, profileId }: FetchParticipantsPayload) => {
  const queryKey = chatKeys.participants(conversationId);

  return useQuery(
    queryKey,
    () => fetchParticipants({ conversationId, profileId }).then(({ entries }) => entries),
    {
      placeholderData: []
    } as any
  );
};
