import { Moment } from "moment";
import { ISO_DATE_FORMAT } from "PFCore/helpers/date";

type ProfilesAvailabilityDateRangeReturn = {
  minDisplayedDate: string;
  maxDisplayedDate: string;
};

export const getProfilesAvailabilityDateRange = (
  displayedMonth: Moment
): ProfilesAvailabilityDateRangeReturn => {
  const currentMonthStartDate = displayedMonth.clone().startOf("month");
  const currentMonthEndDate = displayedMonth.clone().endOf("month");
  const previousMonthDaysCount = currentMonthStartDate.isoWeekday() - 1;
  const nextMonthDaysCount = 7 - currentMonthEndDate.isoWeekday();

  const minDisplayedDate = currentMonthStartDate
    .clone()
    .add(-previousMonthDaysCount, "days")
    .format(ISO_DATE_FORMAT);
  const maxDisplayedDate = currentMonthEndDate
    .clone()
    .add(nextMonthDaysCount, "days")
    .format(ISO_DATE_FORMAT);
  return {
    minDisplayedDate,
    maxDisplayedDate
  };
};
