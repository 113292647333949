import AppContext from "PFApp/app_context";
import ForgotPasswordPage from "PFApp/forgot_password/forgot_password_page";
import NotFoundRoute from "PFApp/routes/not_found_route";
import { EnsureLoggedOut, WithLayout } from "PFApp/routes/route_helpers";
import SignInPage from "PFApp/signin/sign_in_page";
import SignUpPage from "PFApp/signup/sign_up_page";
import { useSession } from "PFApp/use_session";
import { LoadingDots } from "PFComponents/loading_dots";
import api from "PFCore/api";
import { headerOnly } from "PFCore/base/page_layouts";
import { uriDeparam } from "PFCore/helpers/deparam";
import i18n from "PFCore/i18n";
import { GROWL_ACTIONS } from "PFReducers/growl_reducer";
import { useContext, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";

export const TokenResetRoute = () => {
  const { resetToken } = useParams<{ resetToken: string }>();
  window.storage.setItem("reset_password_token", resetToken);
  return <Redirect to="/password_edit" />;
};

export const SignOutRoute = () => {
  const { signOut } = useSession();
  useEffect(() => {
    signOut();
  }, []);
  return <LoadingDots circlesEnabled pageViewEnabled />;
};

export const WelcomeRoute = () => {
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const params = uriDeparam({ cleanup: true });
  const signInType = params.get("sign_in_type");
  const welcomeToken = params.get("welcome_token");

  if (!welcomeToken) {
    return <Redirect to="/" />;
  }

  // When user clicks link in "Welcome mail" and account uses SSO
  if (signInType === "sso") {
    dispatch({
      type: GROWL_ACTIONS.GROWL_RENDER,
      payload: {
        message: i18n.t("welcome.sso"),
        kind: "success"
      }
    });
    return <Redirect to="/" />;
  }

  api({ url: "welcome", headers: { apiVersion: 1 }, params: { welcomeToken } }).then(
    (resp) => {
      const url = `/edit_password/from_welcome/${resp.resetPasswordToken}`;
      history.push(url);
    },
    () => {
      dispatch({
        type: GROWL_ACTIONS.GROWL_RENDER,
        payload: {
          message: i18n.t("welcome.failure"),
          kind: "error",
          onClick: () => history.push("/")
        }
      });
      history.push("/");
    }
  );

  return null;
};

const UsersRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <WithLayout layout={{ sidebar: headerOnly }}>
      <Switch>
        <Route exact path={`${path}/password/edit`}>
          <Redirect to="/password_edit" />
        </Route>
        <Route exact path={`${path}/welcome`}>
          <Redirect to="/welcome" />
        </Route>
        <Route exact path={`${path}/signin`}>
          <EnsureLoggedOut>
            <SignInPage />
          </EnsureLoggedOut>
        </Route>
        <Route exact path={`${path}/signin_admin`}>
          <EnsureLoggedOut>
            <SignInPage adminPath={true} />
          </EnsureLoggedOut>
        </Route>
        <Route exact path={`${path}/reset_password`}>
          <EnsureLoggedOut>
            <ForgotPasswordPage />
          </EnsureLoggedOut>
        </Route>
        <Route exact path={`${path}/signup`}>
          <EnsureLoggedOut>
            <SignUpPage />
          </EnsureLoggedOut>
        </Route>
        <Route path="*">
          <NotFoundRoute />
        </Route>
      </Switch>
    </WithLayout>
  );
};

export default UsersRoutes;
