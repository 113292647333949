import { Typography } from "PFComponents/typography";
import React from "react";

type ScoreColumnProps = {
  value: string | undefined;
};

export const ScoreColumn = ({ value }: ScoreColumnProps) => (
  <Typography variant="bodyRegular">{value ? `${value}%` : "-"}</Typography>
);
