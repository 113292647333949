import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

type DateAttributeProps = {
  value: string;
};

export const DateAttribute = ({ value }: DateAttributeProps) => {
  const { formatDateTime, utc } = useDateFormatter();
  return (
    <Typography variant="bodyRegular" tag="span">
      {formatDateTime(utc(value))}
    </Typography>
  );
};
