import { CustomType, CustomTypesAccessLevels, Profile } from "PFTypes";

export const customTypesAccessLevelsAgainstProfile = (
  profile: Profile,
  currentProfile: Profile
): CustomTypesAccessLevels => {
  const pgId = profile?.permissions_group?.id;

  return currentProfile?.id === profile?.id
    ? ownProfileCustomTypesAccessLevels(currentProfile)
    : currentProfile?.permissions_group?.cross_group_permissions[pgId]?.custom_types_access_levels;
};

export const customTypesForProfile = (
  profile: Profile,
  currentProfile: Profile,
  customTypes: CustomType[]
): CustomType[] => {
  const accessLevels = customTypesAccessLevelsAgainstProfile(profile, currentProfile);
  return (customTypes || []).filter((customType) => accessLevels[customType?.id]);
};

export const customTypesForActivities = (
  currentProfile: Profile,
  customTypes: CustomType[]
): CustomType[] => {
  const accessLevels = activityCustomTypesAccessLevels(currentProfile);
  return (customTypes || []).filter((customType) => accessLevels[customType?.id]);
};

export const ownProfileCustomTypesAccessLevels = (currentProfile: Profile): CustomTypesAccessLevels =>
  currentProfile?.permissions_group?.custom_types_access_levels?.own_profile;

export const activityCustomTypesAccessLevels = (currentProfile: Profile): CustomTypesAccessLevels =>
  currentProfile.permissions_group.custom_types_access_levels.activities;

export const hasCustomTypeSection = (customType: CustomType, sectionKey: string) =>
  !!customType.sections?.find((section) => section.key === sectionKey);
