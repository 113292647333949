import { MatchingRelationType } from "PFApp/constants/matching_relation_type";
import { useGrowl } from "PFApp/use_growl";
import Toggle from "PFComponents/toggle/toggle";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import InfoIcon from "PFTheme/graphics/icons/info.svg";
import { PermissionRule } from "PFTypes";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import css from "./roles_toggles_controls.module.scss";

interface RolesTogglesControlsProps {
  relationType: MatchingRelationType;
  onRelationTypeChange: (newRelationType: MatchingRelationType) => void;
  requirementsOn: boolean;
  setRequirementsOn: (value: boolean) => void;
  availabilityOn: boolean;
  setAvailabilityOn: (value: boolean) => void;
  disabled?: boolean;
}

export const RolesTogglesControls = ({
  relationType,
  onRelationTypeChange,
  disabled,
  requirementsOn,
  setRequirementsOn,
  availabilityOn,
  setAvailabilityOn
}: RolesTogglesControlsProps) => {
  const { t } = useTranslation("marketplace", { keyPrefix: "workOpportunities.toggles" });
  const growl = useGrowl();
  const canToggleRequirements = useIsCurrentUserPermittedTo(PermissionRule.ChangeMarketplaceRequirements);
  const areRequirementsOn = !canToggleRequirements || requirementsOn;

  useEffect(() => {
    let newRelationType: MatchingRelationType;
    if (areRequirementsOn && availabilityOn) {
      newRelationType = MatchingRelationType.PrefilteredAndAvailable;
    } else if (areRequirementsOn) {
      newRelationType = MatchingRelationType.Prefiltered;
    } else if (availabilityOn) {
      newRelationType = MatchingRelationType.Available;
    } else {
      newRelationType = MatchingRelationType.Matched;
    }

    if (newRelationType !== relationType) {
      onRelationTypeChange(newRelationType);
    }
  }, [areRequirementsOn, availabilityOn, relationType, onRelationTypeChange]);

  return (
    <>
      <div className={css.rolesTogglesWrapper}>
        <div className={css.infoMessage}>
          <span>{t("opportunitiesMatching")}</span>
          <Tooltip
            maxWidth={400}
            content={
              <div className={css.togglesTooltipContent}>
                {canToggleRequirements
                  ? t("fullOpportunitiesMatchingTooltip")
                  : t("availabilityOpportunitiesMatchingTooltip")}
              </div>
            }
            arrow
            theme="pf-dark-blue"
          >
            <div className={css.infoIconWrapper}>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
        <div className={css.toggles}>
          {canToggleRequirements && (
            <Toggle
              qaId="marketplaceHomeRequirements"
              description={t("requirements")}
              onChange={(value) => {
                setRequirementsOn(value);
                if (!value) {
                  growl({
                    message: t("useFiltersTip"),
                    ttl: 3000
                  });
                }
              }}
              checked={areRequirementsOn}
              disabled={disabled}
              reversed
            />
          )}
          <Toggle
            qaId="marketplaceHomeAvailability"
            description={t("availability")}
            onChange={(value) => {
              setAvailabilityOn(value);
            }}
            checked={availabilityOn}
            disabled={disabled}
            reversed
          />
        </div>
      </div>
    </>
  );
};
