import { throttle } from "lodash";
import scrollTo from "PFCore/helpers/scroll_to";
import { useFocusableElements } from "PFCore/helpers/use_focusable_elements";
import { useScrollTop } from "PFCore/helpers/use_scroll";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const lockScroll = (scrollLock) => {
  window.clearTimeout(scrollLock.current);
  scrollLock.current = window.setTimeout(() => (scrollLock.current = null), 2000);
};

const scrollY = () => window.pageYOffset || window.scrollY || 0;

export const useTargets = ({ me, profile, initSection }) => {
  const scrollLock = useRef();
  const targets = useRef(); // having this as state destroys performance
  const [section, setSection] = useState(initSection || "summary");

  const recalcTargets = useMemo(
    () =>
      throttle(() => {
        const nodesList = document.querySelectorAll("[data-scroll-key]");
        const items = Array.prototype.map.call(nodesList, (node) => ({
          top: node.getBoundingClientRect().top + scrollY(),
          key: node.dataset.scrollKey,
          node
        }));
        items.sort((itemA, itemB) => itemA.top > itemB.top);
        items.unshift({ top: 0, key: "summary", node: null });

        targets.current = items;
      }, 250),
    []
  );

  const updateHighlight = useMemo(
    () =>
      throttle((recalc = false) => {
        if (recalc || !targets.current || !targets.current.length) {
          recalcTargets();
        }
        if (targets.current && targets.current.length && !scrollLock.current) {
          let foundTarget = targets.current[0];
          const maxScrollY = document.body.scrollHeight - window.innerHeight;
          const normalizedY = scrollY() / maxScrollY; // the lower you are the lower you look
          const lookY = scrollY() + normalizedY * 0.75 * window.innerHeight + 100;

          for (let i = 0; i < targets.current.length; i++) {
            const target = targets.current[i];
            if (target.top > lookY) {
              break;
            }
            foundTarget = target;
          }

          if (foundTarget) {
            setSection(foundTarget.key);
          }
        }
      }, 250),
    [me, profile.id]
  );

  window.recalcTargets = recalcTargets;
  window.updateHighlight = updateHighlight;

  useScrollTop(window, () => {
    if (!targets.current || !targets.current.length) {
      targets.current = recalcTargets();
    }
    updateHighlight();
  });

  const { windowHeight, windowWidth } = useWindowSize();

  useEffect(() => {
    recalcTargets();
    updateHighlight();
  }, [windowHeight, windowWidth, updateHighlight, recalcTargets]);

  const scrollToItem = useCallback(
    (item) => {
      if (!window) {
        return;
      }
      if (item.top || item.top === 0) {
        scrollTo(window, 0, item.top);
      } else {
        const el = document.querySelector(`[data-scroll-key="${item.key}"]`);
        if (el) {
          scrollTo(window, 0, el.getBoundingClientRect().top + scrollY() - 50);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { 0: firstFocusableElement } = useFocusableElements(el)();
          if (firstFocusableElement) {
            setTimeout(() => firstFocusableElement.focus(), 1000);
          }
        }
      }
      lockScroll(scrollLock);
      setSection(item.id);
    },
    [me, profile.id]
  );

  return {
    section,
    scrollToItem,
    recalcTargets,
    updateHighlight
  };
};
