/* eslint-disable camelcase */
import classNames from "classnames";
import { map } from "lodash";
import DownloadLink from "PFApp/components/chat/download_link";
import LocationIcon from "PFIcons/pin.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { dasherize } from "underscore.string";

import css from "./activity_details_common.scss";
import { useMetadata } from "./use_metadata";

const ActivityDetailsMoreInfo = ({ activity, attachments, metadataToOmit, className }) => {
  const { t } = useTranslation("activities");
  const metadata = useMetadata({ activity, metadataToOmit });
  const isSeparatorVisible = metadata?.filter((item) => item.text)?.length > 0;

  // wrapping div is important here because we target last-child with css
  return (
    <div
      data-qa-id="ActivityDetailsMoreInfo"
      className={classNames(css.infoMore, { [className]: isSeparatorVisible })}
    >
      {map(
        metadata,
        (prop) =>
          prop.text && (
            <div
              className={css.property}
              key={prop.title}
              data-qa-id={`activity-details-metadata-prop${dasherize(prop.title)}`}
            >
              <div className={css.infoItem}>
                <strong className={css.textOverflow}>{prop.title}</strong>
                <div className={css.description}>
                  {prop.type === "link" ? (
                    <a href={prop.text} target="_blank" rel="noopener noreferrer">
                      {prop.text}
                    </a>
                  ) : (
                    <div className={prop.type === "availability" ? css.vertical : css.inlineIcon}>
                      {prop.title === "Location" && <LocationIcon height={20} width={20} />}
                      <span style={{ whiteSpace: "pre-line" }}> {prop.text} </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
      )}

      {attachments && attachments.length > 0 && (
        <>
          <div className={css.topLine} />
          <div className={css.infoItem}>
            <strong>{t("show.details.attachments")}</strong>
            <ul className={css.attachments}>
              {attachments.map((attachment) => (
                <li key={attachment.id}>
                  <DownloadLink attachment={attachment} useIcon={false}>
                    {attachment.file_name}
                  </DownloadLink>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

ActivityDetailsMoreInfo.propTypes = {
  activity: PropTypes.object,
  attachments: PropTypes.array,
  metadataToOmit: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
};

export default ActivityDetailsMoreInfo;
