import api from "PFCore/api";
import { Owner } from "PFTypes";

export type OwnerUpdatePayload = {
  principal: boolean;
};

export type OwnerCreatePayload = {
  profileId: number;
  activityId: number;
  principal?: boolean;
};

export type BulkOwnerCreatePayload = {
  profileIds: number[];
  activityIds: number[];
};

export const createOwner = (data: OwnerCreatePayload): Promise<Owner> =>
  api({
    url: "owners",
    method: "POST",
    data
  });

export const updateOwner = (id: number, { principal }: OwnerUpdatePayload): Promise<Owner> =>
  api({
    url: `owners/${id}`,
    method: "PUT",
    data: { principal }
  });

export const deleteOwner = (id: number): Promise<Owner> =>
  api({
    url: `owners/${id}`,
    method: "DELETE"
  });

export const bulkCreateOwner = (data: BulkOwnerCreatePayload) =>
  api({
    url: `owners/bulk_create`,
    method: "POST",
    data
  });
