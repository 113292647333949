import AppContext from "PFApp/app_context";
import { Markdown } from "PFComponents/markdown";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ProfilePreviewHeader } from "./profile_preview_header";

export const ProfilePreviewSummary = () => {
  const {
    profilePreview: { ready, profile }
  } = useContext(AppContext).store;

  const { t } = useTranslation("profiles");

  if (!ready || !profile || !profile.summary) {
    return null;
  }

  return (
    <div data-qa-id="ProfilePreviewSummary">
      <ProfilePreviewHeader>{t("show.parts.bio")}</ProfilePreviewHeader>
      <Markdown raw={profile.summary} emojify={false} />
    </div>
  );
};
