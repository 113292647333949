import Footer from "PFApp/layout/footer/footer";
import { useIsMobile } from "PFCore/hooks/use_is_mobile";

import css from "../landing_layout.module.scss";
import { Logo } from "./logo";

export const Sidebar = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useIsMobile();

  return (
    <div className={css.sidebar}>
      <div className={css.sidebarWrap}>
        <div className={css.sidebarInner}>
          {isMobile && <Logo width={120} height={40} />}
          <div data-qa-id="LandingLayout.sidebar">{children}</div>
          {isMobile && <Footer style={{ transform: "none", paddingTop: 60 }} />}
        </div>
      </div>
    </div>
  );
};
