import AppContext from "PFApp/app_context";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import PropTypes from "prop-types";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import SmartSuggestionsList from "./smart_suggestions_list";

const SuggestedFilters = ({ items }) => {
  const {
    dispatch,
    store: {
      search: { choosenKeywords, pendingKeywordSearch, matchesMeta }
    }
  } = useContext(AppContext);
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("search");

  const selected = (keyword) =>
    choosenKeywords.find((other) => other.id === keyword.id) ||
    Object.values(matchesMeta.filters?.strings || {})
      .flat()
      .find(({ value }) => value === keyword.id);

  const pushChoosenKeyword = useCallback((payload) => {
    const type = SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD;
    dispatch({ type, payload });
  }, []);

  return (
    <div style={{ opacity: pendingKeywordSearch ? 0.5 : 1 }}>
      <SmartSuggestionsList
        style={{ marginTop: 15 }}
        items={items}
        title={t("smartSuggestions.filterSuggestions")}
        onAdd={pushChoosenKeyword}
        selected={selected}
        onRemove={(keyword) =>
          dispatch({
            type: SEARCH_ACTIONS.SEARCH_REMOVE_CHOOSEN_KEYWORD,
            payload: keyword
          })
        }
        usedOn="searchFilters"
        userId={currentProfile.id}
      />
    </div>
  );
};

SuggestedFilters.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }))
};

export default SuggestedFilters;
