import { findIndex } from "lodash";
import { Button } from "PFComponents/button";
import ButtonSelect from "PFComponents/select/button_select";
import { Typography } from "PFComponents/typography";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import { useActivityEditFormSubmit, useEngagementWizard } from "../hooks";
import css from "./engagement_wizard_button_section.module.scss";

const Pagination = ({ items, index, onChange }) => {
  const count = items.length;

  const canNext = index < count - 1;
  const canPrev = 0 < index;

  if (count <= 1) {
    return false;
  }
  return (
    <div className={css.pagination}>
      {canPrev && <Button kind="secondary" icon="arrow-left" onClick={() => onChange(items[index - 1])} />}
      <Typography variant="bodyRegular" noMargin>
        {`Role ${index + 1}`}
      </Typography>
      {canNext && <Button kind="secondary" icon="arrow-right" onClick={() => onChange(items[index + 1])} />}
    </div>
  );
};

Pagination.propTypes = {
  items: PropTypes.array.isRequired,
  index: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const EngagementWizardButtonSection = ({ isOneColumn }) => {
  const history = useHistory();
  const { t } = useTranslation("activities");

  const onSubmit = useActivityEditFormSubmit();

  const { engagementWizardState: wizardState } = useEngagementWizard();
  const { isDraft, isNew } = useActivityEditPageFlags();
  const { activity } = useActivityEditPageContext();
  const labels = useMemo(
    () => ({
      true: {
        done: t("edit.sections.labels.saveDraftAndDone"),
        clone: t("edit.sections.labels.saveDraftAndClone"),
        addNew: t("edit.sections.labels.saveDraftAndAddNew")
      },
      false: {
        done: t("edit.sections.labels.createAndDone"),
        clone: t("edit.sections.labels.createAndClone"),
        addNew: t("edit.sections.labels.createAndAddNew")
      }
    }),
    [t]
  );

  const createOptions = (isDraft) => [
    {
      id: "done",
      displayElement: labels[!!isDraft].done,
      item: "done"
    },
    {
      id: "clone",
      displayElement: labels[!!isDraft].clone,
      item: "clone"
    },
    {
      id: "add_new",
      displayElement: labels[!!isDraft].addNew,
      item: "add_new"
    }
  ];

  const updateDraftOptions = [
    {
      id: "draft",
      displayElement: t("edit.sections.draft"),
      item: "draft"
    },
    {
      id: "publish",
      displayElement: t("edit.sections.publish"),
      item: "publish"
    }
  ];

  const handleRoleChange = (roleId) => {
    if (roleId) {
      history.push(`/activities/${roleId}/edit`);
    } else if (wizardState.newRoleUrl) {
      history.push(wizardState.newRoleUrl);
    } else {
      history.push(`/activities/role/new/parent_activity_id/${wizardState.engagementId}`);
    }
  };

  const roles = [...wizardState.roles, null];

  const index = isNew ? roles.length - 1 : findIndex(roles, (value) => value === activity.id);

  const updateButton = isDraft ? (
    <ButtonSelect
      kind="primary"
      qaId="update-button"
      onChange={(value) => onSubmit({ [value]: true })}
      options={updateDraftOptions}
      title={t("edit.sections.update")}
    />
  ) : (
    <Button
      text={t("edit.sections.update")}
      kind="primary"
      qaId="update-button"
      onClick={() => onSubmit()}
      rootClassName={css.submitButton}
    />
  );

  return (
    <div className={css.container}>
      <Pagination items={roles} index={index} onChange={handleRoleChange} />
      {isNew ? (
        <div className={css.buttons}>
          <ButtonSelect
            kind="secondary"
            qaId="draft-button"
            onChange={(value) => onSubmit({ draft: true, [value]: true })}
            classes={{
              button: css.submitButton
            }}
            options={createOptions(true)}
            title={t("edit.sections.draft")}
            dropDownClassName={css.draftDropDown}
            displayAbove={isOneColumn}
          />
          <ButtonSelect
            kind="primary"
            qaId="creation-button"
            onChange={(value) => onSubmit({ [value]: true })}
            classes={{
              button: css.submitButton
            }}
            options={createOptions()}
            title={t("edit.sections.create")}
            displayAbove={isOneColumn}
          />
        </div>
      ) : (
        updateButton
      )}
    </div>
  );
};

EngagementWizardButtonSection.propTypes = {
  isOneColumn: PropTypes.bool
};

export default EngagementWizardButtonSection;
