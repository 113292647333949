import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";

import { BookingFormValues } from "../booking_form_provider";

type UseInitialActivityId = {
  setValue: UseFormSetValue<BookingFormValues>;
};

export const useInitialActivityId = ({ setValue }: UseInitialActivityId) => {
  const { activity: initialActivity } = useBookingActivityContext(); // the Booking Form was open in context of this activity

  useEffect(() => {
    setValue("activityId", initialActivity?.id, { shouldValidate: true });
  }, [initialActivity]);
};
