import classNames from "classnames";
import { Button } from "PFComponents/button";
import Toggle from "PFComponents/toggle/toggle";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AudienceSelector from "./audience_selector";
import css from "./sections.module.scss";

const MakePrivateSection = ({
  audiences,
  isPrivate,
  isDraft,
  isEdit,
  isForcePrivate,
  errors,
  handlePrivateChange,
  handleAudiencesChange
}) => {
  const { customTypes } = useCustomTypes();
  const [renderAudienceSelector, setRenderAudienceSelector] = useState(false);
  const [broadcastTypes, setBroadcastTypes] = useState([]);
  const { t } = useTranslation("activities");

  useEffect(() => {
    if (customTypes) {
      setBroadcastTypes(
        customTypes.filter((ct) => ct.sections.find((section) => section.key === "broadcast"))
      );
    }
  }, [customTypes]);

  const isAudienceAllowed = broadcastTypes.length > 0;

  /* eslint-disable max-len */
  return (
    <div data-qa-id="make-private-section" id="make-private-section">
      <Toggle
        label={t("edit.sections.makePrivate")}
        tip={t("edit.sections.onlyPeopleYouInvite", {
          ifBroadcast: isAudienceAllowed ? t("edit.sections.broadcastToAudience") : "."
        })}
        inline
        checked={isPrivate}
        onChange={handlePrivateChange}
        disabled={!isDraft && isEdit && isForcePrivate}
      />
      {isPrivate && isAudienceAllowed && (
        <Button
          text={t("edit.sections.selectNewAudience")}
          className={css.privateSectionButton}
          onClick={() => setRenderAudienceSelector(true)}
        />
      )}
      <div className={classNames(css.audienceWrapper, { [css.error]: errors?.audiences })}>
        <div>
          {audiences.map((audience, index) => (
            <Audience
              key={index}
              audience={audience}
              broadcastTypes={broadcastTypes}
              handleRemoveAudience={(selectedAudience) =>
                handleAudiencesChange(audiences.filter((audience) => audience !== selectedAudience))
              }
              isNewActivity={!isEdit}
            />
          ))}
        </div>
        {errors?.audiences && <span>{errors.audiences}</span>}
      </div>
      {renderAudienceSelector && (
        <AudienceSelector
          broadcastTypes={broadcastTypes}
          audiences={audiences}
          handleAudiencesClose={() => setRenderAudienceSelector(false)}
          handleAudiencesChange={(audience) =>
            handleAudiencesChange([...audiences, { ...audience, unconfirmed: true }])
          }
        />
      )}
      <div className={css.line} />
    </div>
  );
};

export default MakePrivateSection;

MakePrivateSection.propTypes = {
  activity: PropTypes.object,
  audiences: PropTypes.array,
  isEdit: PropTypes.bool,
  isDraft: PropTypes.bool,
  isPrivate: PropTypes.bool.isRequired,
  isForcePrivate: PropTypes.bool.isRequired,
  handlePrivateChange: PropTypes.func.isRequired,
  handleAudiencesChange: PropTypes.func,
  errors: PropTypes.object
};

const Audience = ({ audience, broadcastTypes, handleRemoveAudience, isNewActivity }) => {
  const { t } = useTranslation("activities");

  const { unconfirmed, ...types } = audience;

  return (
    <div className={css.wrapper}>
      {Object.keys(types).map((typeName) => {
        const type = broadcastTypes.find((t) => t.name === typeName);

        return (
          <React.Fragment key={typeName}>
            <b>{type?.display_as || typeName}</b>
            <div>{audience[typeName].map(({ text }) => text).join(", ")}</div>
          </React.Fragment>
        );
      })}
      {(unconfirmed || isNewActivity) && (
        <Button
          className={css.removeAudienceButton}
          text={t("remove")}
          kind="danger"
          small
          onClick={() => handleRemoveAudience(audience)}
        />
      )}
    </div>
  );
};

Audience.propTypes = {
  audience: PropTypes.object,
  broadcastTypes: PropTypes.array,
  handleRemoveAudience: PropTypes.func,
  isNewActivity: PropTypes.func
};
