import { useTranslation } from "react-i18next";

import { useTemplate } from "../../../../hooks";
import { useActivityHistoryContext } from "../activity_history_context";

export const useTranslatedActivityType = () => {
  const { activity } = useActivityHistoryContext();
  const template = useTemplate(activity?.template_id);

  const { t } = useTranslation("activities", { keyPrefix: "templates" });

  return template ? t(template.key) : null;
};
