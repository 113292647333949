import { ChangeEvent, useRef } from "react";

import { useSliderContext } from "../context/slider_context";
import { useAriaLabels } from "../hooks/use_aria_labels";
import { useInputWidth } from "../hooks/use_input_width";
import { useSynchronizedState } from "../hooks/use_synchronized_state";
import { SideTools } from "../parts/side_tools";
import { Steps } from "../parts/steps";
import { getLeftOffset } from "../sliders.common";
import slidersCss from "../sliders.module.scss";

export type SingleRangeSliderProps = {
  value: number;
  onChange: (value: number) => void;
};

export const SingleRangeSlider = () => {
  const { value, min, max, step, showSteps, disabled, instantOnChange, sliderId, onChange } =
    useSliderContext<SingleRangeSliderProps>();
  const inputRef = useRef<HTMLInputElement>(null);
  const inputWidth = useInputWidth(inputRef);
  const { singleAriaLabel } = useAriaLabels();
  const [localSliderValue, setLocalSliderValue] = useSynchronizedState<number>(value, !instantOnChange);

  const sliderValue = instantOnChange ? value : localSliderValue;

  const leftOffset = getLeftOffset({ value: sliderValue, min, max, inputWidth });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (instantOnChange) {
      onChange(event.target.valueAsNumber);
    } else {
      setLocalSliderValue(event.target.valueAsNumber);
    }
  };

  return (
    <SideTools<number> localSliderValue={sliderValue} onChange={onChange}>
      <div className={slidersCss.sliderContainer}>
        <input
          id={sliderId}
          ref={inputRef}
          type="range"
          className={slidersCss.sliderInput}
          onChange={handleChange}
          onMouseUp={instantOnChange ? undefined : () => onChange(sliderValue)}
          onKeyUp={instantOnChange ? undefined : () => onChange(sliderValue)}
          value={sliderValue}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          aria-label={singleAriaLabel}
        />
        <div className={slidersCss.slider}>
          <div className={slidersCss.sliderTrack}>
            {showSteps && <Steps value={sliderValue} inputWidth={inputWidth} />}
          </div>
          <div className={slidersCss.sliderRange} style={{ width: `${leftOffset}px` }} />
        </div>
      </div>
    </SideTools>
  );
};
