import { useCallback, useEffect } from "react";
import { useToggle } from "react-use";

import { AccordionProps } from "./accordion";

type UseAccordionProps = Pick<AccordionProps, "defaultState" | "state" | "onStateChange">;

export const useAccordionState = ({ defaultState = "open", state, onStateChange }: UseAccordionProps) => {
  const [isOpen, toggleIsOpen] = useToggle((state || defaultState) === "open");

  const toggle = useCallback(() => {
    onStateChange && onStateChange(isOpen ? "closed" : "open");
    toggleIsOpen();
  }, [isOpen, onStateChange, toggleIsOpen]);

  useEffect(() => {
    if (state) {
      toggleIsOpen(state === "open");
    }
  }, [state, toggleIsOpen]);

  return {
    toggle,
    isOpen
  };
};
