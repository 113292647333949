/* eslint-disable no-unused-vars */
import "regenerator-runtime/runtime.js";
// Profinda
import "PFCore/profinda";

import ReactApp from "PFApp/react_app";
import ReactDOM from "react-dom/client";

import i18n from "./i18n";

PF.renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("react_app"));
  root.render(<ReactApp />);
};

// Initializers
import { customizeConfig } from "PFCore/initializers/customize_config";

PF.Initializers.customizeConfig = customizeConfig;

if (document.readyState !== "loading") {
  PF.renderApp({});
} else {
  document.addEventListener("DOMContentLoaded", () => PF.renderApp({}), false);
}

if (module.hot) {
  module.hot.accept();
}
