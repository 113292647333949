export const cacheBucket = "Profinda-";
export const cacheSuffix = "-cacheexpiration";

const expiryRadix = 10;
const expiryUnits = 1000; // time is stored in seconds
const quotaErrors = ["QUOTA_EXCEEDED_ERR", "NS_ERROR_DOM_QUOTA_REACHED", "QuotaExceededError"];

export default class Storage {
  setItem = () => {
    throw new Error("Implement this method in subclass");
  };

  getItem = () => {
    throw new Error("Implement this method in subclass");
  };

  removeItem = () => {
    throw new Error("Implement this method in subclass");
  };

  clear = () => {
    throw new Error("Implement this method in subclass");
  };

  key = () => {
    throw new Error("Implement this method in subclass");
  };

  eachKey = () => {
    throw new Error("Implement this method in subclass");
  };

  expirationTime = () => {
    throw new Error("Implement this method in subclass");
  };

  length = () => {
    let count = 0;
    this.eachKey(() => count = count + 1);
    return count;
  };

  flushExpired = () => this.eachKey((key) => this.flushExpiredItem(key));

  flushExpiredItem = (key) => {
    if (this.isExpired(key)) {
      this.removeItem(key);
      return true;
    }
  };

  isExpired = (key) => {
    const expTime = this.expirationTime(key);
    if (expTime) {
      return this.currentTime() >= expTime;
    } else {
      return false;
    }
  };

  currentTime = () => Math.floor((new Date()).getTime() / expiryUnits);
  storageKey = (key) => `${ cacheBucket }${ key }`;
  expirationKey = (key) => `${ cacheBucket }${ key }${ cacheSuffix }`;
  expirationTimeFor = (value) => (this.currentTime() + value).toString(expiryRadix);
  is_out_of_space = (e) => !!(e && quotaErrors.indexOf(e.name) >= 0);

  stringify = (value) => {
    if (typeof value === "string") {
      return value;
    } else {
      try {
        return JSON.stringify(value);
      } catch (e) {
        return null;
      }
    }
  };
}
