export const useDeeplink = () => {
  const saveDeeplink = (customPath) => {
    const path = customPath ? customPath : location.pathname + location.search;
    storage.setItem("redirected_from", path);
  };

  const getDeeplink = (options) => {
    const deeplink = storage.getItem("redirected_from");
    if (options.remove) {
      storage.removeItem("redirected_from");
    }

    return deeplink;
  };

  return {
    getDeeplink,
    saveDeeplink
  };
};
