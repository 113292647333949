import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { ActivitiesResponse, fetchActivities } from "PFCore/services/activities";
import { useCallback } from "react";

type UseEngagementQuery = {
  isAudit: boolean;
};

export const useEngagementQuery = ({ isAudit }: UseEngagementQuery) => {
  const { data: currentProfile } = useCurrentProfile();

  const engagementKey = isAudit ? "audit_engagement" : "engagement";
  const engagementTemplate = currentProfile.templates?.find((item) => item.key === engagementKey);

  const engagementSelectQuery = (term: string) =>
    fetchActivities({
      filters: { fields: { term, template_key: engagementTemplate?.key, state: ["draft", "new"] } }
    });

  const engagementSelectParseResponse = useCallback(
    ({ entries }: ActivitiesResponse) =>
      entries
        .filter((item) => item.activity.state !== "complete")
        .map(({ activity }) => ({ ...activity, text: activity.name })),
    []
  );

  return {
    engagementSelectQuery,
    engagementSelectParseResponse
  };
};
