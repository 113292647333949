import { useQueryClient } from "@tanstack/react-query";
import { GIdTargetId, GIdTargetType } from "PFTypes";

import { attachmentsKeys } from "./query_keys";

export const useAttachmentsInvalidate = () => {
  const cache = useQueryClient();
  return {
    invalidate: (targetType: GIdTargetType, targetId: GIdTargetId) => {
      if (targetType && targetId) {
        return cache.invalidateQueries(attachmentsKeys.list(targetType, targetId));
      } else {
        return cache.invalidateQueries(attachmentsKeys.all());
      }
    }
  };
};
