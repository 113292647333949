import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { Activity, AvailabilityRule } from "PFTypes";

import css from "./role_information_header.module.scss";

type RoleDetailsProps = {
  role: Activity;
};

export const RoleDetails = ({ role }: RoleDetailsProps) => {
  const { readableAvailability } = useReadableAvailability({
    availability: role.metadata.availability as AvailabilityRule
  });

  return (
    <div className={css.roleDetails}>
      <Typography variant={"labelRegular"} noMargin>
        {role.name}
      </Typography>
      {readableAvailability && (
        <>
          <Divider />
          <Typography variant={"labelRegular"} noMargin>
            {readableAvailability}
          </Typography>
        </>
      )}
    </div>
  );
};
