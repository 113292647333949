import { useFormContext } from "react-hook-form";

import { NoteForm } from "../note_modal";
import { NoteCounter } from "./note_counter";
import css from "./note_input.module.scss";

const MAX_LENGTH = 500;

export const NoteInput = (): JSX.Element => {
  const { register, watch } = useFormContext<NoteForm>();
  const watchedContent = watch("content");
  return (
    <div className={css.formContainer}>
      <textarea
        className={css.input}
        {...register("content", {
          maxLength: MAX_LENGTH
        })}
      />
      <NoteCounter counterValue={watchedContent.length} maxLength={MAX_LENGTH} />
    </div>
  );
};
