import { PostUntilChangeReason, PostUntilChangeReasonData } from "PFTypes";

export const OTHER_REASON_KEY = "other";

export const getPostUntilChangeReason = (
  postUntilChangeReason: PostUntilChangeReason | null,
  reasonsList: PostUntilChangeReason[]
): PostUntilChangeReason | null => {
  if (postUntilChangeReason && postUntilChangeReason.key === OTHER_REASON_KEY) {
    return postUntilChangeReason;
  }

  return reasonsList.find(({ key }) => key === postUntilChangeReason?.key) ?? null;
};

export const getPostUntilChangeReasonData = (
  postUntilChangeReason: PostUntilChangeReason | null
): PostUntilChangeReasonData | null => {
  if (postUntilChangeReason && postUntilChangeReason.key === OTHER_REASON_KEY) {
    return postUntilChangeReason;
  }

  return postUntilChangeReason ? { key: postUntilChangeReason.key } : null;
};
