import { ApiRoute } from "PFCore/utilities/routes";
import { Profile } from "PFTypes";
import { Id } from "PFTypes/id";

export const editProfile = (profileId: Id, formData: Partial<Profile>) =>
  // @ts-ignore
  $.ajax({
    type: "PUT",
    url: ApiRoute(`/api/admin/profiles/${profileId}`),
    api_version: 2,
    processData: false,
    contentType: "application/json",
    data: JSON.stringify(formData)
  });
