import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";

export type SignUpPayload = {
  accountId: number;
  email: string;
  password: string;
  passwordConfirmation: string;
};

type SignUpResponse = {
  message: string;
};

export const signUp = ({
  accountId,
  email,
  password,
  passwordConfirmation
}: SignUpPayload): jqXHR<SignUpResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute("/api/auth/sign_up"),
    method: "POST",
    data: {
      user: {
        account_id: accountId,
        email,
        password,
        password_confirmation: passwordConfirmation
      }
    }
  });
