import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFTypes";

import { matchesKeys } from "./query_keys";

export const useMatchesInvalidate = () => {
  const cache = useQueryClient();
  return {
    invalidate: (activityIds?: Id[]) => {
      if (!activityIds) {
        return cache.invalidateQueries(matchesKeys.all());
      }

      return Promise.all([
        ...activityIds.map((activityId) =>
          cache.invalidateQueries({
            queryKey: matchesKeys.activity(activityId)
          })
        )
      ]);
    }
  };
};
