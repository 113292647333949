import { useQuery } from "@tanstack/react-query";
import { useGrowl } from "PFApp/use_growl";
import { QueryOptions } from "PFTypes/request_options";
import { useTranslation } from "react-i18next";

import {
  DiscoveredSkillsResponse,
  fetchDiscoveredSkills
} from "../../../services/skills/fetch_discovered_skills";
import { skillsKeys } from "./query_keys";

export const useDiscoveredSkills = (options?: QueryOptions<DiscoveredSkillsResponse>) => {
  const growl = useGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  return useQuery(skillsKeys.discovered(), () => fetchDiscoveredSkills(), {
    ...options,
    onError: (...args) => {
      options?.onError?.(...args);
      growl({
        message: t("sectionSkills.errors.fetchDiscoveredSkills"),
        kind: "error"
      });
    }
  });
};
