import LegacyForm from "PFApp/components/form/legacy_form";
import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import css from "PFApp/profiles/edit/sections/section.less";
import { useGrowl } from "PFApp/use_growl";
import AutoSelect from "PFComponents/select/autoselect";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { isAdmin } from "PFCore/helpers/profile";
import { editProfile } from "PFCore/services/admin/profiles/edit_profile";
import { ApiRoute } from "PFCore/utilities/routes";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { camelize, humanize } from "underscore.string";

const AdminCredentialsSection = ({ profile, handleProfileUpdate }) => {
  const growl = useGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "edit.sections.admin" });

  const profileIsAdmin = isAdmin(profile);

  const [state, setState] = useState({
    forgotState: null,
    emailErrors: {},
    employeeStatusErrors: {},
    adminEmailsError: {}
  });

  const [formData, setFormData] = useState({
    email: profile.email,
    employee_status: profile.employee_status,
    permissions_group: { text: profile.permissions_group.display_as, id: profile.permissions_group.id },
    admin_emails: profile.admin_emails
  });

  const handleEditProfile = () => editProfile(profile.id, formData);

  const handleEmailSuccess = () => {
    handleProfileUpdate();
    setState((prevState) => ({ ...prevState, emailErrors: {} }));
    growl({ message: t("credentials.successes.email") });
  };

  const handleStatusSuccess = () => {
    handleProfileUpdate();
    setState((prevState) => ({ ...prevState, emailErrors: {} }));
    growl({ message: t("credentials.successes.employeeStatus") });
  };

  const handleStatusError = (errors) => {
    setState((prevState) => ({ ...prevState, employeeStatusErrors: errors }));
  };

  const handlePermissionsGroupSuccess = () => {
    handleProfileUpdate();
    growl({ message: t("credentials.successes.permissionsGroup") });
  };

  const handleEmailError = (errors) => {
    setState((prevState) => ({ ...prevState, emailErrors: errors }));
  };

  const handleAdminEmailsSuccess = () => {
    handleProfileUpdate();
    setState((prevState) => ({ ...prevState, adminEmailsErrors: {} }));
    growl({ message: t("credentials.successes.adminEmails") });
  };

  const handleAdminEmailsError = (errors) => {
    setState((prevState) => ({ ...prevState, adminEmailsErrors: errors }));
  };

  return (
    <ProfileEditPanel title={getProfileSectionText("admin_credentials", true)}>
      <h3 className={css.header}>{t("credentials.changeEmail")}</h3>
      <LegacyForm
        qaIdPrefix="profile-edit-credentials-email"
        onSuccess={handleEmailSuccess}
        onError={handleEmailError}
        onSubmit={handleEditProfile}
        isDirty={profile.email !== formData.email}
        blockNavigationOnChange
      >
        <div data-qa-id="profile-edit-credentials-email" className={css.wrap}>
          <InputFieldSet
            label={t("credentials.emailAddress")}
            error={state.emailErrors.attr}
            tip={t("credentials.changeEmailTip")}
            value={formData.email}
            onChange={(value) => setFormData({ ...formData, email: value })}
          />
        </div>
      </LegacyForm>

      <h3 className={css.header}>{t("credentials.changeEmployeeStatus")}</h3>

      <LegacyForm
        qaIdPrefix="profile-edit-credentials-employee-status"
        onSuccess={handleStatusSuccess}
        onError={handleStatusError}
        onSubmit={handleEditProfile}
        isDirty={profile.employee_status !== formData.employee_status}
        blockNavigationOnChange
      >
        <div data-qa-id="profile-edit-credentials-employee-status" className={css.wrap}>
          {/* TODO: [PROF-3790] i18n */}
          <AutoSelect
            query={() =>
              Promise.resolve([
                { text: "active" },
                { text: "leave of absence" },
                { text: "withdrawn" },
                { text: "terminated" }
              ])
            }
            letCreate
            closeOnChange
            label={t("credentials.employeeStatus")}
            error={(state.employeeStatusErrors?.[0] || "").toString()}
            values={[{ text: formData.employee_status }]}
            allowSameValueInOptions
            handleChange={(values) => setFormData({ ...formData, employee_status: values[0].text })}
            formatOption={(item) => ({
              id: item.id || item.text,
              displayElement: t(`credentials.statuses.${camelize(item.text)}`, {
                defaultValue: humanize(item.text)
              }),
              item: item
            })}
          />
        </div>
      </LegacyForm>

      <h3 className={css.header}>{t("credentials.changePermissionGroup")}</h3>
      <LegacyForm
        qaIdPrefix="profile-edit-credentials-permission-group"
        onSubmit={handleEditProfile}
        onSuccess={handlePermissionsGroupSuccess}
        isDirty={profile.permissions_group.id !== formData.permissions_group.id}
        blockNavigationOnChange
      >
        <AutoSelect
          query={() =>
            $.ajax({
              api_version: 2,
              url: ApiRoute("/api/admin/permissions_groups?per_page=1000")
            })
          }
          closeOnChange
          label={t("credentials.permissionGroup")}
          values={[formData.permissions_group]}
          allowSameValueInOptions
          parseResponse={(data) =>
            (data.entries || []).map((entry) => ({ id: entry.id, text: entry.display_as }))
          }
          handleChange={(values) => {
            setFormData({ ...formData, permissions_group: values[0] });
          }}
        />
      </LegacyForm>

      {profileIsAdmin && (
        <React.Fragment>
          <h3 className={css.header}>Change Admin Email settings</h3>
          <LegacyForm
            qaIdPrefix="profile-edit-credentials-admin-emails"
            onSubmit={handleEditProfile}
            onSuccess={handleAdminEmailsSuccess}
            onError={handleAdminEmailsError}
            isDirty={profile.admin_emails !== formData.admin_emails}
            blockNavigationOnChange
          >
            <div data-qa-id="profile-edit-credentials-admin-emails" className={css.wrap}>
              {/* TODO: [PROF-3790] i18n */}
              <AutoSelect
                query={() => Promise.resolve([{ text: "Receive" }, { text: "Don't Receive" }])}
                letCreate
                closeOnChange
                label={t("credentials.adminEmails")}
                values={[{ text: formData.admin_emails ? "Receive" : "Don't Receive" }]}
                allowSameValueInOptions
                handleChange={(values) =>
                  setFormData({ ...formData, admin_emails: values[0].text === "Receive" })
                }
                tip={`If set to 'Receive' the user will get admin emails like Access Requests etc.`}
              />
            </div>
          </LegacyForm>
        </React.Fragment>
      )}
    </ProfileEditPanel>
  );
};

AdminCredentialsSection.propTypes = {
  profile: PropTypes.any.isRequired,
  handleProfileUpdate: PropTypes.func
};

export default AdminCredentialsSection;
