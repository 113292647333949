import classNames from "classnames";
import { ExpandableCard } from "PFComponents/expandable_card";
import { getProfileName } from "PFCore/helpers/profile";
import { forwardRef } from "react";
import { TransitionStatus } from "react-transition-group";

import { useShowMatchProfileSidePanel } from "../../hooks/use_show_match_profile_side_panel";
import { useMatchCardContext } from "../../match_card_context";
import { MatchCardBody } from "../match_card_body";
import { MatchCardFooter } from "../match_card_footer";
import { MatchCardHeader } from "../match_card_header";
import css from "./animated_match_card.module.scss";

type AnimatedMatchCardProps = {
  state: TransitionStatus;
};

export const AnimatedMatchCard = forwardRef<HTMLDivElement, AnimatedMatchCardProps>(({ state }, ref) => {
  const { profile, animationTrigger, isProfileSelectedToCompare } = useMatchCardContext();
  const openProfileSidePanel = useShowMatchProfileSidePanel();

  const profileName = getProfileName(profile);

  return (
    <ExpandableCard
      ref={ref}
      header={<MatchCardHeader />}
      onClick={openProfileSidePanel}
      ariaLabel={profileName}
      className={classNames(css.card, css[state], {
        [css.selectedCard]: isProfileSelectedToCompare
      })}
      animationTrigger={animationTrigger}
      footer={<MatchCardFooter />}
    >
      <MatchCardBody />
    </ExpandableCard>
  );
});

AnimatedMatchCard.displayName = "AnimatedMatchCard";
