import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { Shortlist, ShortlistProfile } from "PFTypes";
import { useEffect, useState } from "react";

import { useShortlistCreate } from "./queries/shortlists/use_shortlist_create";
import { useShortlistDelete } from "./queries/shortlists/use_shortlist_delete";

type ShortlistMutationProps = { onSuccess?: () => void };

const useAddShortlist = (profile, task, shortlists: Shortlist[] | null) => {
  const growlErrors = useErrorsGrowl();
  const [shortlistItem, setShortlistItem] = useState<Partial<Shortlist> | null>(null);
  const { mutate: createShortlist, isLoading: isCreating } = useShortlistCreate();
  const { mutate: deleteShortlist, isLoading: isDeleting } = useShortlistDelete({ activityId: task.id });
  const isShortlistsLoaded = !!shortlists;

  useEffect(() => {
    if (isShortlistsLoaded) {
      setShortlistItem(
        shortlists.filter((shortlist) => shortlist.profile && shortlist.profile.id === profile.id)[0]
      );
    }
  }, [isShortlistsLoaded]);

  const undoShortlist = ({ onSuccess }: ShortlistMutationProps = {}) => {
    const shortlistItemCopy = { ...shortlistItem };
    setShortlistItem(null);

    if (!shortlistItemCopy?.id) {
      return;
    }
    deleteShortlist(shortlistItemCopy.id, {
      onSuccess,
      onError: (response) => {
        setShortlistItem(shortlistItemCopy);
        growlErrors(response);
      }
    });
  };

  const shortlistProfile = ({ onSuccess }: ShortlistMutationProps = {}) => {
    setShortlistItem({
      activity_id: task.id,
      profile: { id: profile.id } as ShortlistProfile,
      state: "pending"
    });

    createShortlist(
      { activityId: task.id, profileId: profile.id },
      {
        onSuccess: (resp) => {
          setShortlistItem((prev) => ({ ...prev, id: resp.id } as Shortlist));
          onSuccess?.();
        },
        onError: (response) => {
          // UNDO optimistic update
          setShortlistItem(null);
          growlErrors(response);
        }
      }
    );
  };

  const isShortlisted = !!shortlistItem;
  const loading = isCreating || isDeleting;

  return {
    isShortlisted,
    shortlistProfile,
    undoShortlist,
    loading,
    isShortlistsLoaded,
    shortlistItem
  };
};

export default useAddShortlist;
