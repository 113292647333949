import { useProfilePreview } from "PFApp/use_profile_preview";
import ProfileCard from "PFComponents/profile_card/profile_card";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useMemo } from "react";

import { useProfileChatIcon } from "./use_profile_chat_icon";

/**
 * @deprecated Use application\components\profile_card
 */
const ProfileCardConnected = (props) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();

  const { showPreview } = useProfilePreview();

  const { profileResponse, withChat } = props;

  const { Icon, handleClick: handleChatClick } = useProfileChatIcon(profileResponse);

  const onClick = () => {
    if (props.onClick) {
      onClick();
    }
    const { profile } = props.profileResponse;
    if (profile) {
      showPreview({ id: profile.id, profile });
    }
  };

  const handleIconClick = useMemo(
    () => props.handleIconClick || (withChat ? handleChatClick : null),
    [handleChatClick, withChat, props.handleIconClick]
  );

  const IconComponent = useMemo(() => props.Icon || (withChat ? Icon : null), [Icon, withChat, props.Icon]);

  return (
    <ProfileCard
      {...props}
      currentAccount={currentAccount}
      currentProfile={currentProfile}
      handleClick={onClick}
      Icon={IconComponent}
      handleIconClick={handleIconClick}
    />
  );
};

ProfileCardConnected.propTypes = { ...ProfileCard.propTypes, currentProfile: undefined };

export default ProfileCardConnected;
