import { gidFor } from "PFCore/helpers/gid";
import { ApiRoute } from "PFCore/utilities/routes";
import { Attachment, GIdTargetId, GIdTargetType } from "PFTypes";

export type FetchAttachmentsPayload = {
  targetId: GIdTargetId;
  targetType: GIdTargetType;
};

export type FetchAttachmentsResponse = {
  entries: Attachment[];
  meta: { total: number };
};

export const fetchAttachments = ({
  targetType,
  targetId
}: FetchAttachmentsPayload): Promise<FetchAttachmentsResponse> => {
  const gid = gidFor(targetType, targetId);
  // @ts-ignore
  return $.ajax({
    url: ApiRoute("/api/attachments"),
    api_version: 2,
    data: { target: gid }
  });
};
