export const noteKeys = {
  all: () => ["bookingNotes"],
  ofBooking: (bookingId: number, perPage?: number) => [
    ...noteKeys.all(),
    bookingId,
    ...(perPage !== undefined ? [perPage] : [])
  ],
  remove: (noteId: number) => [...noteKeys.all(), noteId, "remove"],
  create: () => [...noteKeys.all(), "create"],
  update: (noteId: number) => [...noteKeys.all(), noteId, "update"]
};
