import { useCallback, useState } from "react";

import { DeleteBookingModalData } from "./delete_booking_modal";
import {
  DeleteBookingModalDataStateType,
  DeleteBookingModalType
} from "./delete_booking_modal_context_provider";

export const useDeleteBookingModal = (): DeleteBookingModalType => {
  const [modalData, setModalData] = useState<DeleteBookingModalDataStateType>({ isOpen: false });

  const open = useCallback((data: DeleteBookingModalData) => setModalData({ isOpen: true, data }), []);

  const close = useCallback(() => setModalData({ isOpen: false }), []);

  return { open, close, state: modalData };
};
