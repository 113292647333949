import { includes, isArray, some } from "lodash";

import { AccessLevel, CustomType, CustomTypes } from "../types";

export type PermissionObject = {
  ctAccessLevels: Record<string, AccessLevel>;
  rw?: boolean;
  ro?: boolean;
};

const TYPE_REQUIRES_CURATION = [
  "skills",
  "technical_experience",
  "brands",
  "company",
  "qualifications",
  "clients"
];

export const isInSections = (sectionKeys: string[] | string, customType: CustomType) =>
  some(customType.sections, (section) =>
    includes(isArray(sectionKeys) ? sectionKeys : [sectionKeys], section.key)
  );

export const isRankable = (customType: CustomType) => isInSections("rankable", customType);

export const allowsNewValues = (customType: CustomType) => {
  // This block is because API location works different than any other custom type.
  // Allows new does not mean allows new values, it means allows adding
  // new location to the account from global values
  if (customType.value_type === "location") {
    return false;
  }

  return customType.new_values === "allowed" || customType.new_values === "require_approval";
};

export const requiresApproval = (customType: CustomType) => customType.new_values === "require_approval";

export const requiresCuration = (customType: CustomType) => {
  if (!customType.name) {
    return false;
  }
  return TYPE_REQUIRES_CURATION.includes(customType.name);
};

export const getPermittedTypes = (
  customTypes: CustomTypes,
  { ctAccessLevels, rw = true, ro = true }: PermissionObject
) =>
  customTypes.filter((ct) => {
    const ctAccessLevel = ctAccessLevels?.[ct.id];
    return (ro && ctAccessLevel === "ro") || (rw && ctAccessLevel === "rw");
  });

export const loadForSections = (sections: string[] | string, customTypes: CustomTypes) =>
  (customTypes || []).filter((customType) => isInSections(sections, customType));

export const getPrintableCustomType = (customType: CustomType | undefined) => {
  const { displayAs, display_as, name } = customType || {};
  return display_as || displayAs || name || "";
};
