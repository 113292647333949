import { utc } from "moment";

import { BookingWithProfileId } from "./use_profile_bookings";

export const filterBookings = (
  bookings: BookingWithProfileId[],
  search: string | null,
  currentMonth: string
) => {
  if (!search) {
    return bookings;
  }

  return bookings
    .filter(({ start_date, end_date }) => {
      const isBookingBeforeCurrentMonth = utc(end_date).isBefore(currentMonth, "month");
      const isBookingAfterCurrentMonth = utc(start_date).isAfter(currentMonth, "month");
      return !isBookingBeforeCurrentMonth && !isBookingAfterCurrentMonth;
    })
    .filter(({ title }) => title && title.toLowerCase().includes(search.trim().toLowerCase()));
};
