import { prune, truncate } from "underscore.string";

const safePrune = (text, len) => {
  if (!text || text.length <= len) {
    return text || "";
  }

  const out = prune(text, len);
  if (out === "...") {
    return truncate(text, len);
  } else {
    return out;
  }
};

export default safePrune;
