import classNames from "classnames";
import PropTypes from "prop-types";
import { ReactNode } from "react";

import css from "./legend.module.scss";

interface Props {
  title: string;
  children: ReactNode;
  className?: string;
}

const LegendItem = ({ title, children, className }: Props) => (
  <>
    <div className={classNames(css.legendItemTitle, className)}>{title}</div>
    <div className={classNames(css.legendItemValue, className)}>{children}</div>
  </>
);

LegendItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default LegendItem;
