import ExpandableBadge from "PFComponents/expandable_badge/expandable_badge";
import FreemasonryLayout from "PFComponents/freemasonry/freemasonry_layout";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./expandable_badge_list.module.scss";

const ExpandableBadgesList = ({
  values,
  customButton,
  moreLimit,
  handleMoreClicked,
  openByDefault,
  profileId,
  currentProfile
}) => {
  const { t } = useTranslation("core");
  const getLimit = () => moreLimit;

  const [revealed, setRevealed] = useState(values.length <= getLimit() + 1);

  // TODO: [ENG-2787] Unify this logic with BadgesList
  const renderMoreButton = () => {
    const limit = getLimit();

    if (limit && limit < values.length) {
      const handleToggleClick = () => {
        setRevealed((prev) => !prev);
        handleMoreClicked && handleMoreClicked(!revealed);
      };
      const qaId = revealed ? "BadgesList.show_less" : "BadgesList.show_more";

      return (
        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a
            onClick={handleToggleClick}
            data-qa-id={qaId}
            role="button"
            onKeyDown={handleToggleClick}
            tabIndex={0}
          >
            {revealed ? (
              t("viewLess")
            ) : (
              <>
                <span>{`+${values.length - moreLimit} `}</span> {t("viewMore")}
              </>
            )}
          </a>
        </div>
      );
    } else {
      return false;
    }
  };

  const button = !customButton && renderMoreButton();

  const shownValues = revealed ? values : values.slice(0, getLimit());

  return (
    <div data-qa-id="ExpandableBadgesList" className={css.wrapper}>
      <FreemasonryLayout minColumnWidth={340}>
        {shownValues.map((badgeProps, index) => (
          <ExpandableBadge
            {...badgeProps}
            key={`${badgeProps._key || badgeProps.text}:${index}`}
            openByDefault={openByDefault}
            profileId={profileId}
            currentProfile={currentProfile}
          />
        ))}
      </FreemasonryLayout>
      {button}
      {customButton && <div>{customButton}</div>}
    </div>
  );
};

ExpandableBadgesList.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      icon: PropTypes.any,
      showTooltip: PropTypes.bool,
      kind: PropTypes.string,
      className: PropTypes.string
    })
  ).isRequired,
  moreLimit: PropTypes.number,
  handleMoreClicked: PropTypes.func,
  // /** If provided we will display the "badges left" number
  //  * ie. "+123 more" instead of just "more" */
  moreCount: PropTypes.number,
  customButton: PropTypes.node,
  avatar: PropTypes.object,
  openByDefault: PropTypes.bool,
  profileId: PropTypes.number,
  currentProfile: PropTypes.object
};

ExpandableBadgesList.defaultProps = {
  moreLimit: 1000000,
  handleMoreClicked: null,
  moreCount: null,
  customButton: null,
  avatar: null
};

export default ExpandableBadgesList;
