import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import Toggle from "PFComponents/toggle/toggle";
import Tooltip from "PFComponents/tooltip/tooltip";
import useStorage from "PFCore/helpers/use_storage";
import MinimizeIcon from "PFIcons/minimize.svg";
import InfoIcon from "PFIcons/question_rounded.svg";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./smart_suggestions.less";

const SmartSuggestionsShell = ({ children, empty, style }) => {
  const { t } = useTranslation("search");
  const [isInitiallyVisible, setIsInitiallyVisible] = useStorage("smart_suggest_visible", true);
  const [collapsed, setCollapsed] = useState(!isInitiallyVisible);

  const labelWithtooltip = (
    <div className={css.toggleWrapper}>
      {t("smartSuggestions.on")}
      <Tooltip content={<div>{t("smartSuggestions.toggle")}</div>}>
        <div className={css.label}>
          <InfoIcon height={24} width={24} />
        </div>
      </Tooltip>
    </div>
  );

  return (
    <div className={classNames(css.root, { [css.empty]: empty, [css.collapsed]: collapsed })} style={style}>
      <div className={css.card}>
        <div className={css.content}>
          <div className={css.header}>
            <div className={css.left}>
              <strong className={css.title}>{t("smartSuggestions.title")}</strong>
              <Toggle
                checked={isInitiallyVisible}
                description={labelWithtooltip}
                onChange={() => setIsInitiallyVisible((prev) => !prev)}
                reversed
              />
            </div>
            <Button onClick={() => setCollapsed(true)} kind="blank">
              <MinimizeIcon width={20} height={20} />
            </Button>
          </div>
          <div className={css.children}>{children}</div>
        </div>
      </div>
      <div className={css.collapsedCard}>
        <strong className={css.title}>{t("smartSuggestions.title")}</strong>
        <ActionIcon onClick={() => setCollapsed(false)} name="add" color="paletteNeutral0" size="sm" />
      </div>
    </div>
  );
};

SmartSuggestionsShell.propTypes = {
  children: PropTypes.node.isRequired,
  empty: PropTypes.bool.isRequired,
  style: PropTypes.object
};

export default SmartSuggestionsShell;
