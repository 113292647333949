import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";

export type SaveConversationPayload = {
  target: string;
  ownerId?: number;
  withMessage?: boolean;
};

type SaveConversationResponse = {
  id: number;
};

export const saveConversation = ({
  target,
  ownerId
}: SaveConversationPayload): jqXHR<SaveConversationResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute(`/api/chat/conversations`),
    api_version: 2,
    method: "POST",
    data: {
      target,
      owner_id: ownerId
    }
  });
