import { Button } from "PFComponents/button";
import { SyntheticEvent } from "react";

type TablePropertiesButtonProps = {
  onClick: (e: SyntheticEvent) => void;
  qaId?: string;
};

export const TablePropertiesButton = ({ onClick, qaId }: TablePropertiesButtonProps) => (
  <Button icon="table" kind="secondary" onClick={onClick} id="table_properties_button" qaId={qaId} />
);
