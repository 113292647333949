import classNames from "classnames";
import { AnimatedDiv } from "PFComponents/divs";
import { Activity } from "PFTypes";
import { useToggle } from "react-use";

import { RoleDetails, RoleInformationHeader } from "./parts/role_information_header";
import { RoleSkills } from "./parts/role_skills";
import css from "./role_information.module.scss";

type RoleInformationProps = {
  role: Activity;
  qaId?: string;
};

export const RoleInformation = ({ role, qaId }: RoleInformationProps) => {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <AnimatedDiv className={css.root}>
      <RoleInformationHeader role={role} isOpen={isOpen} onClick={toggleOpen} qaId={qaId} />
      <div
        className={classNames(css.body, {
          [css.closed]: !isOpen
        })}
      >
        <RoleDetails role={role} />
        <RoleSkills role={role} />
      </div>
    </AnimatedDiv>
  );
};
