import { useIsMutating, useMutation } from "@tanstack/react-query";
import { deleteBookingNote } from "PFApp/booking/services/booking_notes_api";

import { noteKeys } from "./query_keys";
import { useBookingNoteInvalidate } from "./use_booking_note_invalidate";

export const useBookingNoteRemove = (bookingId: number, noteId: number) => {
  const mutationKey = noteKeys.remove(noteId);

  const { invalidate } = useBookingNoteInvalidate();

  const removeMutation = useMutation(() => deleteBookingNote({ bookingId, id: noteId }) as any, {
    mutationKey,
    onSuccess: () => invalidate(bookingId)
  });

  const isMutating = useIsMutating({ mutationKey });

  return {
    mutation: removeMutation,
    remove: removeMutation.mutateAsync,
    isMutating
  };
};
