import { Filters, Meta, Value } from "PFTypes";
import { useMemo } from "react";

import { checkFiltersAreClear } from "./format_filters";
import { getValueFilters } from "./helpers";
import { useFilters, UseFiltersReturn } from "./use_filters";

export type UseMetaFiltersReturn = UseFiltersReturn & { areFiltersClear: boolean };

export const useMetaFilters = (
  meta: Meta,
  update: (updatedFilters: Filters<Value>) => void,
  defaultValue?: Filters<Value>
): UseMetaFiltersReturn => {
  const filterValues = useMemo(() => getValueFilters(meta.filters), [meta.filters]);

  const areFiltersClear = useMemo(() => checkFiltersAreClear(meta.filters), [meta.filters]);

  const filtersData = useFilters({
    selectedFilters: filterValues,
    setSelectedFilters: update,
    defaultValue
  });

  return { areFiltersClear, ...filtersData };
};
