import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "PFTypes";

import { uploadFile } from "./api";

type UploadFileData = {
  url: string;
  data: FormData;
};

type UseUploadFileData = {
  options?: MutationOptions<UploadFileData>;
};

export const useUploadFile = <RESPONSE_TYPE extends object>({ options }: UseUploadFileData) =>
  useMutation<RESPONSE_TYPE, unknown, UploadFileData>({
    mutationFn: ({ url, data }) => uploadFile(url, data),
    onSuccess: (...params) => options?.onSuccess && options.onSuccess(...params),
    onError: (...error) => options?.onError && options.onError(...error)
  });
