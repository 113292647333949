import ProfilesMatchingRolesController from "PFApp/profiles/matching_roles/profile_matching_roles_controller";
import ProfileShowController from "PFApp/profiles/show/profile_show_controller";
import NotFoundRoute from "PFApp/routes/not_found_route";
import {
  EnsureLoggedInAndRegistered,
  EnsureWorkflowPermission,
  WithLayout
} from "PFApp/routes/route_helpers";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { ProfilesPageControllerWrapper } from "../profiles/list/profiles_page_controller";

const ProfilesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <EnsureLoggedInAndRegistered>
      <WithLayout>
        <Switch>
          <Route exact path={`${path}/me/suggested_skills`}>
            <Redirect to={`${path}/me/summary/skills`} />
          </Route>
          <Route path={`${path}/:id/matching_roles`}>
            <EnsureWorkflowPermission>
              <ProfilesMatchingRolesController />
            </EnsureWorkflowPermission>
          </Route>
          <Route path={`${path}/:id/:section?/:additionalSection?`}>
            <ProfileShowController />
          </Route>
          <Route exact path={path}>
            <ProfilesPageControllerWrapper />
          </Route>
          <Route path="*">
            <NotFoundRoute />
          </Route>
        </Switch>
      </WithLayout>
    </EnsureLoggedInAndRegistered>
  );
};

export default ProfilesRouter;
