import { GIdTargetId, GIdTargetType } from "PFTypes";

export const attachmentsKeys = {
  all: () => ["attachments"],
  list: (targetType: GIdTargetType, targetId: GIdTargetId) => [
    ...attachmentsKeys.all(),
    "list",
    targetType,
    String(targetId)
  ]
};
