import { Alert } from "PFComponents/alert";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { camelize } from "underscore.string";

const ProfileEditTip = ({ tip, tipName }) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles");

  const [displayTip, setDisplayTip] = useState(true);

  const hideTip = useCallback(() => {
    const tipPresent = (currentProfile.progress_tips || []).find(({ name }) => name === tipName);
    setDisplayTip(tipPresent);
  }, [currentProfile.progress_tips, tipName]);

  useEffect(() => {
    hideTip();
  }, [currentProfile, hideTip]);

  const customType =
    tipName && (currentProfile.custom_types || []).find(({ name }) => name === tipName.replace("pcf_", ""));
  const displayName = customType ? customType.display_as : null;

  const tipPresent = (currentProfile.progress_tips || []).find(({ name }) => name === tipName);
  const tipMessage = tip && t(`edit.profileEditTips.${camelize(tip)}`, { name: displayName });

  return (
    <React.Fragment>
      {tip && tipPresent && displayTip && (
        <Alert title={t("edit.tip")} style={{ marginBottom: 20 }}>
          {tipMessage}
        </Alert>
      )}
    </React.Fragment>
  );
};

ProfileEditTip.propTypes = {
  tip: PropTypes.string,
  tipName: PropTypes.string
};

ProfileEditTip.defaultProps = {
  tip: null,
  tipName: null
};

export default ProfileEditTip;
