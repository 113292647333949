import { Dispatch, SetStateAction, useEffect, useState } from "react";

type UseSynchronizedStateReturn<Value> = [Value, Dispatch<SetStateAction<Value>>];

export const useSynchronizedState = <Value>(
  value: Value,
  enabled: boolean = true
): UseSynchronizedStateReturn<Value> => {
  const [state, setState] = useState<Value>(value);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    setState(value);
  }, [value, enabled]);

  return [state, setState];
};
