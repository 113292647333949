import { useActivityPageContext } from "../../activity_page_context";
import { OwnerFlow } from "./owner_flow";
import { ReviewerFlow } from "./reviewer_flow";

export const ActivityReviewSection = () => {
  const { task, currentProfileIsReviewer } = useActivityPageContext();

  return (
    <div>
      {currentProfileIsReviewer && <ReviewerFlow />}
      {!currentProfileIsReviewer && task.coowned && <OwnerFlow />}
    </div>
  );
};
