import { Icon } from "../icon";
import css from "./toggle.module.scss";

type ToggleReadOnlyIconProps = {
  checked: boolean;
};

export const ToggleReadOnlyIcon = ({ checked }: ToggleReadOnlyIconProps): JSX.Element => (
  <div className={css.toggleReadOnly}>
    {checked ? <Icon name="check" width={20} height={20} /> : <Icon name="cross" width={20} height={20} />}
  </div>
);
