import PropTypes from "prop-types";

import css from "./cards.module.scss";

const CourseCard = ({ item }) => {
  const croppedTitle = item.title.length > 50 ? `${item.title.substring(0, 50)} ...` : item.title;
  const croppedDescription =
    item.description.length > 75 ? `${item.description.substring(0, 75)} ...` : item.description;

  return (
    <div className={css.course}>
      <a href={item.courseURL} target="_blank" rel="noopener noreferrer">
        <>
          <img src={item.imageURL} className={css.courseImage} alt="" />
          <div className={css.imageOverlay} />
        </>
        <div className={css.courseContainer}>
          <div className={css.top}>
            <span>Course</span>
          </div>
          <div className={css.content}>
            <div className={css.title}>{croppedTitle}</div>
            <div className={css.description}>{croppedDescription}</div>
          </div>
        </div>
      </a>
    </div>
  );
};

CourseCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    imageURL: PropTypes.string,
    courseURL: PropTypes.string
  })
};

export default CourseCard;
