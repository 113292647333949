import { isUndefined } from "lodash";
import { ApiRoute } from "PFCore/utilities/routes";
import { Conversation, GId } from "PFTypes";

export type FetchConversationsPayload = {
  target?: GId;
  term?: string;
  perPage?: number;
  page?: number;
  empty?: boolean;
  me?: boolean;
};

export type FetchConversationsResponse = {
  entries: Conversation[];
  meta: { total: number };
};

export const fetchConversations = ({
  target,
  term = "",
  empty,
  me,
  page = 1,
  perPage = 15
}: FetchConversationsPayload = {}): Promise<FetchConversationsResponse> => {
  const searchParams = new URLSearchParams({
    ...(target && { target: target.toString() }),
    ...(!isUndefined(empty) && { empty: empty.toString() }),
    ...(!isUndefined(me) && { me: me.toString() }),
    ...(term && { term }),
    page: page.toString(),
    per_page: perPage.toString()
  });
  // @ts-ignore
  return $.ajax({
    url: ApiRoute(`/api/chat/conversations?${searchParams.toString()}`),
    api_version: 2
  });
};
