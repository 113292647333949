import { ActionIcon } from "PFCore/components/action_icon";
import { Typography } from "PFCore/components/typography";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useModalContext } from "../modal_context";
import css from "./modal_layout.module.scss";

export type ModalLayoutHeaderProps = {
  title: string | ReactNode | null;
  subtitle?: string | ReactNode | null;
};

export const ModalLayoutHeader = ({ title, subtitle }: ModalLayoutHeaderProps) => {
  const { t } = useTranslation();
  const { closeWithAnimation } = useModalContext();

  if (!title) {
    return null;
  }

  return (
    <header className={css.header}>
      <div className={css.titleWithSubtitle}>
        <Typography variant="h4" className={css.title} noMargin>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="bodyRegular" className={css.subtitle} noMargin>
            {subtitle}
          </Typography>
        )}
      </div>
      <div className={css.close}>
        <ActionIcon
          name="cross"
          size="sm"
          onClick={() => closeWithAnimation()}
          title={t("closeModal", { title })}
        />
      </div>
    </header>
  );
};
