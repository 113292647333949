import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { Threshold, ThresholdKind } from "PFTypes";

export const useThresholds = (kinds: ThresholdKind[] = []): Record<string, Threshold[]> => {
  const {
    data: { thresholds }
  } = useCurrentAccount();

  return kinds.reduce(
    (result, current) => ({
      ...result,
      [current]: thresholds?.filter((item) => item.kind === current) || []
    }),
    {}
  );
};
