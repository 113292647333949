import api from "PFCore/api";
import { ActivityEvent, Id, PaginatedCollection, QueryParams } from "PFTypes";

export type FetchActivityHistoryParams = QueryParams;

export type FetchActivityHistoryResponse = PaginatedCollection<ActivityEvent[]>;

export const fetchActivityHistory = (activityId: Id, params: FetchActivityHistoryParams) =>
  api<FetchActivityHistoryResponse>({
    url: `activities/${activityId}/history`,
    params
  });
