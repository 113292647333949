export const ACTIVITY_PREVIEW_ACTIONS = {
  ACTIVITY_PREVIEW_SHOW: "ACTIVITY_PREVIEW_SHOW",
  ACTIVITY_PREVIEW_HIDE: "ACTIVITY_PREVIEW_HIDE",
  ACTIVITY_PREVIEW_DONE: "ACTIVITY_PREVIEW_DONE"
};

export const activityPreviewReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIVITY_PREVIEW_ACTIONS.ACTIVITY_PREVIEW_SHOW:
      return {
        ...state,
        activityPreview: {
          id: payload.id,
          profile: payload.profile,
          ready: false,
          show: true
        }
      };

    case ACTIVITY_PREVIEW_ACTIONS.ACTIVITY_PREVIEW_HIDE:
      // don't remove activity and id so we can have proper closing animation
      return {
        ...state,
        activityPreview: { ...state.activityPreview, show: false }
      };

    case ACTIVITY_PREVIEW_ACTIONS.ACTIVITY_PREVIEW_DONE:
      if (payload.activity.id === state.activityPreview.id) {
        return {
          ...state,
          activityPreview: { ...state.activityPreview, activity: payload.activity, ready: true }
        };
      } else {
        return state; // another activity opened in the meantime - no need to update the state
      }
  }
};
