import EchartsWrapper, { ECOption } from "PFCore/helpers/echarts_wrapper";
import { FeedbackForProfile } from "PFTypes/activity_feedback";
import { useLayoutEffect, useRef, useState } from "react";

import { useFeedbackChartData } from "./use_feedback_chart_data";

const MIN_CHART_WIDTH = 400;
const MOBILE_VIEW_WIDTH = 800;
const CHART_WIDTH_PER_SERIES = 250;

type FeedbackSkillsChartProps = {
  managerFeedback?: FeedbackForProfile;
  selfAssessment?: FeedbackForProfile;
  peerFeedbacks?: FeedbackForProfile[];
};

export const FeedbackSkillsChart = ({
  managerFeedback,
  selfAssessment,
  peerFeedbacks
}: FeedbackSkillsChartProps) => {
  const { config, seriesCount } = useFeedbackChartData(managerFeedback, selfAssessment, peerFeedbacks);

  const containerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number | undefined>();

  useLayoutEffect(() => {
    if (containerRef?.current && containerRef.current?.clientWidth > MOBILE_VIEW_WIDTH && seriesCount > 0) {
      setChartWidth(
        Math.max(
          Math.min(seriesCount * CHART_WIDTH_PER_SERIES, containerRef.current.clientWidth),
          MIN_CHART_WIDTH
        )
      );
    } else {
      setChartWidth(undefined);
    }
  }, [seriesCount, containerRef.current?.clientWidth]);

  return (
    <div ref={containerRef}>
      <EchartsWrapper height={500} width={chartWidth} options={config as ECOption} />
    </div>
  );
};
