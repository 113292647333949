import { useQuery } from "@tanstack/react-query";
import {
  fetchProfileSkillsFrameworks,
  FetchProfileSkillsFrameworksParams,
  FetchProfileSkillsFrameworksResponse
} from "PFCore/services/skills_frameworks/fetch_profile_skills_frameworks";
import { Id } from "PFTypes/id";
import { QueryOptions } from "PFTypes/request_options";

import { skillsFrameworksKeys } from "./query_keys";

type UseProfileSkillsFrameworks<TData = FetchProfileSkillsFrameworksResponse> = {
  profileId: Id;
  params: FetchProfileSkillsFrameworksParams;
  options?: QueryOptions<FetchProfileSkillsFrameworksResponse, unknown, TData>;
};

export const useProfileSkillsFrameworks = <TData = FetchProfileSkillsFrameworksResponse>({
  profileId,
  params,
  options = {}
}: UseProfileSkillsFrameworks<TData>) =>
  useQuery<FetchProfileSkillsFrameworksResponse, unknown, TData>(
    skillsFrameworksKeys.profileSkillsFrameworks(profileId, params),
    () => fetchProfileSkillsFrameworks(profileId, params),
    options
  );
