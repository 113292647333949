import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { PermissionRule } from "PFTypes";

export const useIsRoleRemovalPermitted = () => {
  const { data: currentProfile } = useCurrentProfile();

  const isPermittedTo = useIsPermittedTo(currentProfile);
  return isPermittedTo(PermissionRule.RoleRemoval);
};

export default useIsRoleRemovalPermitted;
