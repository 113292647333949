import { Collection, EconomicsScenario, Id } from "PFTypes";

import api from "../../api";

export type FetchActivityEconomicsResponse = Collection<EconomicsScenario[]>;

export const fetchActivityEconomics = async (activityId: Id) =>
  api<FetchActivityEconomicsResponse>({
    url: `engagements/${activityId}/scenarios`
  });
