import { useToggle } from "react-use";

type UseSkillsVisibility = {
  availableSkillsCount: number;
  missingSkillsCount: number;
  limit: number;
};

type UseSkillsVisibilityReturn = {
  visibleAvailableSkillsCount: number;
  visibleMissingSkillsCount: number;
  showAll: boolean;
  displayShowMoreButton: boolean;
  toggleShowAll: VoidFunction;
};

export const useSkillsVisibility = ({
  availableSkillsCount,
  missingSkillsCount,
  limit
}: UseSkillsVisibility): UseSkillsVisibilityReturn => {
  const [showAll, toggleShowAll] = useToggle(false);

  const limitedAvailableSkillsCount = Math.min(availableSkillsCount, limit);
  const limitedMissingSkillsCount = Math.min(missingSkillsCount, Math.max(limit - availableSkillsCount, 0));
  const displayShowMoreButton = limit < availableSkillsCount + missingSkillsCount;

  return {
    visibleAvailableSkillsCount: showAll ? availableSkillsCount : limitedAvailableSkillsCount,
    visibleMissingSkillsCount: showAll ? missingSkillsCount : limitedMissingSkillsCount,
    showAll,
    displayShowMoreButton,
    toggleShowAll
  };
};
