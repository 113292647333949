import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";

const AnimatedText = ({ value, incrementsPerSecond, className }) => {
  const [displayValue, setDisplayValue] = useState(0);
  const timeoutRef = useRef(null);
  const updateTimeInMs = useMemo(() => 1000 / incrementsPerSecond, [incrementsPerSecond]);

  useEffect(() => {
    animateValue();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    animateValue();
  }, [value]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => animateValue(), updateTimeInMs);
  }, [displayValue]);

  const isAnimating = () => value !== displayValue;

  const animateValue = () => {
    if (isAnimating()) {
      const valueChange = value > displayValue ? 1 : -1;
      setDisplayValue(displayValue + valueChange);
    }
  };

  return <span className={className}>{displayValue}</span>;
};

AnimatedText.defaultProps = {
  incrementsPerSecond: 50
};

AnimatedText.propTypes = {
  value: PropTypes.number.isRequired,
  style: PropTypes.object,
  incrementsPerSecond: PropTypes.number,
  className: PropTypes.string
};

export default AnimatedText;
