import { useIsMutating, useMutation } from "@tanstack/react-query";
import { sendActivityFeedback } from "PFCore/services/activities";
import { ActivityFeedbackCreateData } from "PFTypes/activity_feedback";
import { MutationOptions } from "PFTypes/request_options";

import activityKeys from "./query_keys";
import { useActivityInvalidate } from "./use_activity_invalidate";

export const useActivityFeedbackCreate = (
  activityId?: number,
  options?: MutationOptions<ActivityFeedbackCreateData>
) => {
  const mutationKey = activityKeys.feedbackCreate(activityId);
  const { invalidateFeedbacks, invalidateFeedbackParticipants } = useActivityInvalidate();
  const { onSuccess, ...queryOptions } = options || {};

  const createMutation = useMutation((body: ActivityFeedbackCreateData) => sendActivityFeedback(body), {
    mutationKey,
    onSuccess: (...args) => {
      invalidateFeedbacks();
      invalidateFeedbackParticipants(activityId);
      onSuccess?.(...args);
    },
    ...queryOptions
  });

  const isMutating = useIsMutating({ mutationKey });

  return {
    mutation: createMutation,
    create: createMutation.mutate,
    isMutating
  };
};
