import { useQuery } from "@tanstack/react-query";
import { fetchCustomValue } from "PFCore/services/custom_values";
import { CustomValue } from "PFTypes/custom_value";
import { QueryOptions } from "PFTypes/request_options";
import { CamelCasedPropertiesDeep } from "type-fest";

import { customValueKeys } from "./query_keys";

export const useCustomValue = (
  id: number,
  profileId: number,
  options: QueryOptions<CamelCasedPropertiesDeep<CustomValue>>
) =>
  useQuery<CamelCasedPropertiesDeep<CustomValue>>({
    queryKey: customValueKeys.single(id, profileId),
    queryFn: () => fetchCustomValue(id, profileId),
    retry: false,
    ...options
  });
