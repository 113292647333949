import { ActionIcon } from "PFComponents/action_icon";
import { Alert } from "PFComponents/alert";
import { useTranslation } from "react-i18next";

import css from "../description_generator.module.scss";

type ErrorViewProps = {
  onClose?: () => void;
};

export const ErrorView = ({ onClose }: ErrorViewProps) => {
  const { t } = useTranslation("activities");

  return (
    <div className={css.error}>
      <Alert kind="error" title={t("edit.sections.descriptionGenerate.error")} small />
      {onClose && <ActionIcon name="cross" size="xs" onClick={onClose} />}
    </div>
  );
};

ErrorView.displayName = "DescriptionGeneratorErrorView";
