import { useQuery } from "@tanstack/react-query";
import {
  bookingsForUpdate,
  BookingsForUpdatePayload,
  BookingsForUpdateResponse
} from "PFCore/services/bookings_bulk_operation";
import { QueryOptions } from "PFTypes/request_options";

import { bulkOperationKeys } from "./query_keys";

export type SelectionType =
  | "total_selection"
  | "filtered_selection"
  | "wall_planner"
  | "split_mode"
  | "profile_bookings";

export const useBookingsForUpdate = (
  selectionType: SelectionType,
  payload: BookingsForUpdatePayload,
  options: QueryOptions<BookingsForUpdateResponse>
) =>
  useQuery<BookingsForUpdateResponse>(
    bulkOperationKeys.forUpdate(selectionType, payload),
    async () => await bookingsForUpdate(payload),
    options
  );
