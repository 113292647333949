import { find } from "lodash";
import { useGrowl } from "PFApp/use_growl";
import { getActiveActivityProfiles } from "PFCore/helpers/get_active_activity_profiles";
import { getProfileName } from "PFCore/helpers/profile";
import { useOwnerDelete } from "PFCore/hooks/queries/owners";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity, Profile } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type UseOwnerRemoveReturn = {
  handleRemove: (profile: Profile) => void;
  getRemoveModalContent: (profile: Profile) => string | undefined;
};

export const useOwnerRemove = (task: Activity, onRemoveSelf: () => void = () => {}): UseOwnerRemoveReturn => {
  const { data: currentProfile } = useCurrentProfile();
  const { id: currentProfileId } = currentProfile;

  const growl = useGrowl();
  const { t } = useTranslation("activities");
  const { mutate: deleteOwner } = useOwnerDelete(task.id);

  const taskOwners = getActiveActivityProfiles(task.owners);

  const handleRemove = (profile) => {
    const owner = find(taskOwners, { profile_id: profile.profile_id });
    if (!owner) {
      return null;
    }

    const isMe = owner.profile.id === currentProfileId;

    owner.id &&
      deleteOwner(Number(owner.id), {
        onSuccess: () => {
          isMe && onRemoveSelf();
          growl({
            message: isMe
              ? t("show.parts.successRemoveOwner")
              : t("show.parts.profileRemovedCoOwner", { profileName: getProfileName(owner.profile) }),
            kind: "success"
          });
        },
        onError: () =>
          growl({
            message: isMe ? t("show.parts.issueRemovingOwnership") : t("show.parts.unknownError"),
            kind: "error"
          })
      });
  };

  const getRemoveModalContent = useCallback(
    (profile) => {
      if (profile.active === false) {
        return;
      }
      const activeOwners = taskOwners.filter(({ profile }) => profile.status === "active");

      if (activeOwners.length <= 1 && profile.id === currentProfileId) {
        return t("show.parts.removingYourselfAsOwnerNotAllowed");
      }
      if (activeOwners.length <= 1) {
        return t("show.parts.removingLastOwner");
      }
      if (profile.id === currentProfileId) {
        return t("show.parts.removingYourselfAsOwner");
      }
    },
    [currentProfileId, taskOwners, t]
  );

  return { handleRemove, getRemoveModalContent };
};
