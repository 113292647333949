import css from "./required_text.module.scss";

interface Props {
  label: string;
}

const RequiredText = ({ label }: Props): JSX.Element => {
  const words = label.split(" ");
  const wordsExceptLast = words.slice(0, -1).join(" ");
  const lastWord = words[words.length - 1];

  return (
    <>
      {wordsExceptLast} <span className={css.noWrap}>{lastWord}*</span>
    </>
  );
};

export default RequiredText;
