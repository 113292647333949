import { useEffect, useState } from "react";

let passiveSupported = false;
try {
  const options = {
    get passive() {
      // This function will be called when the browser attempts to access the passive property
      passiveSupported = true;
      return false;
    }
  };

  window.addEventListener("test", null, options);
  window.removeEventListener("test", null, options);
} catch (err) {
  passiveSupported = false;
}

const listenersOptions = passiveSupported ? { passive: true } : false;

export const useScrollLeft = (el) => {
  const [left, setLeft] = useState(0);
  const handleScroll = () => setLeft(el.scrollLeft);

  useEffect(() => {
    el && el.addEventListener("scroll", handleScroll, listenersOptions);
    return () => el && el.removeEventListener("scroll", handleScroll);
  }, [el]);

  return left;
};

export const useScrollTop = (el, onScroll, enabled = true) => {
  const [top, setTop] = useState(0);
  useEffect(() => {
    el && el.addEventListener("scroll", handleScroll, listenersOptions);
    return () => el && el.removeEventListener("scroll", handleScroll);
  }, [el]);
  // avoid touching dom and events:
  if (enabled === false) {
    return;
  }

  const handleScroll = (e) => {
    const newTop = el === window ? el.scrollY : el.scrollTop;
    setTop(newTop);
    onScroll && onScroll(e, newTop);
  };

  return top;
};
