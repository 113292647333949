import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { FeatureFlag, PermissionRule } from "PFTypes";

export const useIsMarketplacePermitted = () => {
  const { data: currentProfile } = useCurrentProfile();

  const isPermittedTo = useIsPermittedTo(currentProfile);
  const isEnabled = useIsFeatureEnabled();
  return (
    currentProfile.active &&
    isEnabled(FeatureFlag.TalentMarketplace) &&
    isPermittedTo(PermissionRule.TalentMarketplace)
  );
};

export default useIsMarketplacePermitted;
