import { useMutation } from "@tanstack/react-query";
import { shareSavedFilter, ShareSavedFiltersData } from "PFCore/services/saved_filters";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";
import { SavedFilter } from "PFTypes/saved_filters";

export type UseShareSavedFiltersData = {
  id: Id;
  data: ShareSavedFiltersData;
};

export const useShareSavedFilters = (
  queryParams: MutationOptions<UseShareSavedFiltersData, SavedFilter[]> = {}
) =>
  useMutation({
    mutationFn: ({ id, data }: UseShareSavedFiltersData) => shareSavedFilter(id, data),
    ...queryParams
  });
