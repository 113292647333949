import { DimensionDefinitionLoose, OptionSourceData } from "echarts/types/src/util/types.js";
import { ECOption } from "PFCore/helpers/echarts_wrapper";

const EXPERIENCE_TO_EFFICIENCY = {
  2: "basic",
  4: "intermediate",
  6: "advanced"
};

export const createFeedbackChartConfig = (
  datasetSource: OptionSourceData,
  dimensions: DimensionDefinitionLoose[]
): ECOption => ({
  legend: {},
  tooltip: {},
  dataset: {
    source: datasetSource,
    dimensions: ["skill", ...dimensions]
  },
  xAxis: {
    type: "category",
    axisLabel: {
      interval: 0,
      rotate: 45
    }
  },
  yAxis: {
    max: 6,
    min: 0,
    interval: 2,
    axisLabel: {
      formatter: (value: number) => EXPERIENCE_TO_EFFICIENCY[value]
    }
  },
  grid: { left: "100px", bottom: "150px" },
  series: dimensions.map(() => ({
    barMaxWidth: 20,
    type: "bar"
  }))
});
