import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFTypes/id";

import { rankedShortlistsKeys, shortlistsKeys } from "./query_keys";

export const useShortlistsInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateShortlists: (id?: Id) => {
      if (id === undefined) {
        queryClient.invalidateQueries(shortlistsKeys.all());
        return;
      }

      queryClient.invalidateQueries(shortlistsKeys.activityShortlists(id));
      queryClient.invalidateQueries(shortlistsKeys.activitySuspendedShortlists(id));
    },
    invalidateRankedShortlists: (id?: Id) => {
      if (id === undefined) {
        queryClient.invalidateQueries(rankedShortlistsKeys.all());
        return;
      }

      queryClient.invalidateQueries(rankedShortlistsKeys.activityRankedShortlists(id));
    }
  };
};
