import classNames from "classnames";
import AppContext from "PFApp/app_context";
import { LoadingDots } from "PFComponents/loading_dots";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import SearchFatIcon from "PFIcons/search_fat.svg";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import css from "./recent_searches.less";

const RecentSearches = ({ searches, isLoading }) => {
  const history = useHistory();
  const {
    dispatch,
    store: {
      search: { choosenKeywords }
    }
  } = useContext(AppContext);
  const { t } = useTranslation("search");

  const resetState = (path, payload) => {
    dispatch({ type: SEARCH_ACTIONS.SEARCH_RESET_STATE, payload });
    history.replace(path);
  };

  return (
    <div className={classNames(css.root, { [css.isSeparated]: choosenKeywords.length > 0 })}>
      <h1>
        <SearchFatIcon width={19} height={19} />
        <span>{t("parts.recentSearches")}</span>
      </h1>

      {isLoading ? (
        <LoadingDots circlesEnabled pageViewEnabled />
      ) : (
        <>
          {searches && searches.length === 0 && <div>{t("parts.noPastSearches")}</div>}

          {searches && searches.length > 0 && (
            <ul className={css.list}>
              {searches.map(({ activity }) => (
                <RecentSearch task={activity} resetState={resetState} key={activity.id} />
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

RecentSearches.propTypes = {
  searches: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, description: PropTypes.string })),
  isLoading: PropTypes.bool
};

const RecentSearch = ({ task, resetState }) => {
  const { t } = useTranslation("search");

  const term = task.description || t("parts.noSearchName");
  const encodedTerm = window.encodeURI(term).replace(/\//g, "%2f");
  const path = `/search/${task.id}/all/${encodedTerm}`;

  const onClick = (e) => {
    e.preventDefault();
    resetState(path, { autoselectEnabled: false });
  };

  return (
    <li>
      <Link to={path} onClick={onClick} className={css.link}>
        <DownArrowIcon width={20} height={20} />
        {term}
      </Link>
    </li>
  );
};

RecentSearch.propTypes = {
  task: PropTypes.shape({ description: PropTypes.string, id: PropTypes.number }).isRequired,
  resetState: PropTypes.func.isRequired
};

export default RecentSearches;
