import { ApiRoute } from "PFCore/utilities/routes";

export type FetchInvitesPayload = {
  targetType: string;
  targetId: number;
  page?: number;
  perPage?: number;
};

export type FetchInvitesResponse = {
  entries: any[];
  meta: { total: number };
};

export const fetchInvites = ({
  targetType,
  targetId,
  page = 1,
  perPage = 1000
}: FetchInvitesPayload): Promise<FetchInvitesResponse> => {
  const searchParams = new URLSearchParams({
    target_type: targetType,
    target_id: targetId.toString(),
    page: page.toString(),
    per_page: perPage.toString()
  });
  // @ts-ignore
  return $.ajax({
    url: ApiRoute(`/api/invites?${searchParams.toString()}`),
    api_version: 2
  });
};
