import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

type DurationAttributeProps = {
  value: number;
};

export const DurationAttribute = ({ value }: DurationAttributeProps) => {
  const { t } = useTranslation("bookingModule");
  const hoursValue = Math.floor(value / 60);
  return (
    <Typography variant="bodyRegular" tag="span">
      {t("details.history.hour", { count: hoursValue })}
    </Typography>
  );
};
