import classNames from "classnames";
import { EngagementSelect } from "PFApp/components/engagement_select";
import { useEngagementQuery } from "PFApp/hooks/use_engagement_query";
import Panel from "PFComponents/panel/panel";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useActivityEditFormContext } from "../activity_edit_form_context";
import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import { useLinkAllCustomFields } from "../hooks";
import { DescriptionGenerator } from "./description_generator";
import css from "./sections.module.scss";

const BasicSection = ({ isEngagementWizard, qaIdPrefix }) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("activities");

  const {
    name,
    setName,
    errors,
    description,
    setDescription: onDescriptionChange,
    customFields,
    selectedParentActivity,
    setIsPrivate,
    setSelectedParentActivity,
    minRequiredCount,
    setMinRequiredCount
  } = useActivityEditFormContext();

  const { isRole, isForceVisibilitySet, isAudit } = useActivityEditPageFlags();
  const { template, parentActivity } = useActivityEditPageContext();

  const parentRequired = template.requires_parent_activity_id;

  //to have the correct engTemplate we need to know if the template is role or audit_role
  const engagementKindKey = isAudit ? "audit_engagement" : "engagement";
  const engagementTemplate = isRole
    ? currentProfile.templates?.find((item) => item.key === engagementKindKey)
    : null;
  const isParentActivityVisible = template.schema?.rules?.hide_parent_activity_id !== true;
  const isEngagementVisible = isParentActivityVisible && engagementTemplate;
  const showActivityAiGenerator = useIsFeatureEnabled()(FeatureFlag.ActivityAiGenerator);
  const setEngagementValues = useLinkAllCustomFields();

  const { engagementSelectQuery, engagementSelectParseResponse } = useEngagementQuery({ isAudit });

  const handleEngagementChange = (engagement) => {
    if (engagement && !isForceVisibilitySet && template.default_visibility === "inherit_from_parent") {
      setIsPrivate(engagement.private);
    }

    setEngagementValues({ engagement });
    setSelectedParentActivity(engagement);
  };

  return (
    <Panel className={css.wrapper}>
      <div className={css.header}>{t("edit.sections.basicInformation")}</div>
      <div className={classNames(css.row, { [css.withEngagement]: isEngagementVisible })}>
        <InputFieldSet
          label={t("edit.sections.title")}
          lockedTip={t("edit.sections.title")}
          value={name}
          maxLength={100}
          required
          qaId={`${qaIdPrefix}-title`}
          error={errors.name}
          onChange={setName}
        />
        {isRole && (
          <InputFieldSet
            label={t("edit.sections.numberOfVacancies")}
            value={minRequiredCount}
            inputType="number"
            min={1}
            required
            id={`${qaIdPrefix}-min-required-count`}
            qaId={`${qaIdPrefix}-min-required-count`}
            error={errors.min_required_count}
            onChange={(minRequired) => setMinRequiredCount(parseInt(minRequired))}
          />
        )}
        {isEngagementVisible && (
          <EngagementSelect
            qaId="activity-selection-engagement"
            id="parent_activity_select"
            label={engagementTemplate.name}
            selectedEngagement={selectedParentActivity ?? parentActivity}
            handleChange={handleEngagementChange}
            query={engagementSelectQuery}
            parseResponse={engagementSelectParseResponse}
            parseEngagementToOption={(activity) => ({
              id: activity.id,
              name: activity.name,
              isPrivate: activity.private
            })}
            required={parentRequired}
            disabled={isEngagementWizard}
            error={errors.parent_activity_id}
          />
        )}
      </div>
      <div className={css.description}>
        <InputFieldSet
          label={t("edit.sections.description")}
          value={description}
          inputType="textarea"
          maxLength={25000}
          minHeight={100}
          minVisibleMaxLengthFactor={0.9}
          required
          qaId={`${qaIdPrefix}-task-description` /* -task- avoids collision with lesson's -description */}
          error={errors.description}
          onChange={onDescriptionChange}
        />
        {isRole && showActivityAiGenerator && (
          <DescriptionGenerator
            title={name}
            description={description}
            customFields={customFields}
            onDescriptionChange={onDescriptionChange}
          />
        )}
      </div>
    </Panel>
  );
};

BasicSection.propTypes = {
  qaIdPrefix: PropTypes.string,
  isEngagementWizard: PropTypes.bool
};

export default BasicSection;
