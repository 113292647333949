import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useTranslation } from "react-i18next";

import DateValue from "./date_value";

export const useGetFormattedPostUntil = (postUntil) => {
  const { formatDate } = useDateFormatter();
  const { t } = useTranslation("core");
  let timeHeader, timeValue;
  try {
    const postFormat = new DateValue(postUntil).postUntilFormat();
    if (!postUntil) {
      timeHeader = null;
      timeValue = null;
    } else if (/^Expired on /.test(postFormat)) {
      timeHeader = t("time.inWords.canceled");
      timeValue = formatDate(postUntil);
    } else if (postFormat === "Expires today") {
      timeHeader = t("time.inWords.expires");
      timeValue = "today";
    } else {
      timeHeader = t("time.inWords.timeLeft");
      timeValue = postFormat.split(": ")[1];
    }
  } catch (event) {
    timeHeader = null;
    timeValue = null;
  }

  return { timeHeader, timeValue };
};
