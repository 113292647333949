import { Markdown } from "PFComponents/markdown";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useIsMobile } from "PFCore/hooks/use_is_mobile";

import css from "./landing_layout.module.scss";
import { Content, Header, Sidebar } from "./parts";

export const LandingLayoutWithLandingDescription = ({ children }: { children: React.ReactNode }) => {
  const { data: currentAccount } = useCurrentAccount();
  const { windowWidth } = useWindowSize();
  const isMobile = useIsMobile();
  const customization = currentAccount.customization || {};

  return (
    <div data-qa-id="LandingLayout">
      {!isMobile && (
        <>
          <Header style={{ right: 400 }} />
          <Content maxWidth={windowWidth > 1100 ? 700 : 460} right={400 - 20 /* -20 for scrollbars */}>
            <div className={css.customizationDesc} data-qa-id="LandingLayout.description">
              <Markdown raw={customization.landing_description} crop={1500} emojify={false} />
            </div>
          </Content>
        </>
      )}
      <Sidebar>{children}</Sidebar>
    </div>
  );
};
