export const EXPIRABLE_CUSTOM_TYPES = [
  "accreditation",
  "accreditations",
  "memberships",
  "licenses",
  "certificates",
  "security_clearance",
  "security_clearances",
  "fsa_tag",
  "technical_overlay",
  "badges"
];
