import classNames from "classnames";
import CloseIcon from "PFIcons/close.svg";
import PropTypes from "prop-types";

import css from "./remove_icon.module.scss";

const RemoveIcon = ({ className }) => (
  <div className={classNames(css.background, className)}>
    <CloseIcon className={css.icon} width={16} height={16} />
  </div>
);

RemoveIcon.propTypes = {
  className: PropTypes.string
};

export default RemoveIcon;
