import { FetchBookedProfilesPayload } from "PFCore/services/booked_profiles/fetch_booked_profiles";

export const bookedProfilesKeys = {
  all: () => ["bookedProfiles"],
  byBookingGroupId: (bookingGroupId?: number) => [...bookedProfilesKeys.all(), bookingGroupId],
  byPage: (data: FetchBookedProfilesPayload = {}) => [
    ...bookedProfilesKeys.byBookingGroupId(data.bookingGroupId),
    data.activityId,
    data.perPage,
    data.page
  ]
};
