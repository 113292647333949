import { CalendarView } from "PFApp/booking/types";

export const projectsKeys = {
  all: () => ["projects"],
  withParameters: (view: CalendarView, filters: any, order: any, page: number, perPage: number) => [
    ...projectsKeys.all(),
    view,
    { filters, order },
    page,
    perPage
  ],
  pinned: (view: CalendarView, filters: any, order: any, page: number, perPage: number) => [
    ...projectsKeys.withParameters(view, filters, order, page, perPage),
    "pinned"
  ]
};
