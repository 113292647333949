import SidePanel from "PFComponents/side_panel/side_panel";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import React from "react";

import { DetailsPanelComponentProps, DetailsSidePanelProps } from "../../details_panel";
import { ProfileDetail } from "./profile_detail";
import { ProfileDetailHeader } from "./profile_detail_header";

type ProfileDetailsSidePanelProps = DetailsSidePanelProps & Pick<DetailsPanelComponentProps, "data">;

export const ProfileDetailsSidePanel = React.memo(
  ({ isOnTop, show, data, onClose, zIndex }: ProfileDetailsSidePanelProps) => {
    const { data: profile } = useProfile(data?.id, { enabled: show && !!data?.id });
    return (
      <SidePanel
        show={show}
        zIndex={zIndex}
        onClose={onClose}
        isSidePanelClosing={data.isClosing}
        title={profile && <ProfileDetailHeader profile={profile} />}
        isOnTop={isOnTop}
      >
        {<ProfileDetail data={data} />}
      </SidePanel>
    );
  }
);

ProfileDetailsSidePanel.displayName = "ProfileDetailsSidePanel";
