const base = "#00338d";

export const variables = {
  "--Palette-base": base,
  "--Palette-base-main": "#0043b8",
  "--Palette-base-light": "#275ddd",

  // Onboarding
  "--Onboarding-bg": "rgba(0,0,0,0.5)",

  // Legacy:
  "--Color-primary": base,
  "--Color-secondary": "#E1EAEF"
};
