import { ActivityCustomValue, CustomType, Experience, Importance } from "PFTypes";

import { IsoDate } from "./date_time";
import { Id } from "./id";

export enum SkillsFrameworkRank {
  Primary = "primary",
  Secondary = "secondary"
}

export const SkillsFrameworkRanks = Object.values(SkillsFrameworkRank);

export type SkillsFrameworkCustomValue = Pick<ActivityCustomValue, "id" | "value"> &
  Partial<Pick<ActivityCustomValue, "requiredExperience">>;
export type SkillsFrameworkCustomType = Pick<CustomType, "id" | "name" | "display_as" | "displayAs">;

export type SkillsFramework<TValue = SkillsFrameworkCustomValue> = {
  id: Id;
  name: string;
  description?: string;
  customFields: {
    id: Id;
    customType: SkillsFrameworkCustomType;
    customValue: TValue;
  }[];
  skills: {
    id: Id;
    importance: Importance;
    requiredExperience: Experience;
    customValue: TValue;
    proposed: boolean;
  }[];
  activeProfilesCount?: number;
  skillsCount?: number;
  rank: SkillsFrameworkRank;
  coverage: number;
  updatedAt: IsoDate;
};

export type SkillsFrameworkCreateUpdateData = {
  name: string;
  description?: string;
  customFields: {
    customTypeId: Id;
    customValueId: Id;
  }[];
  skills: {
    skillId: Id;
    importance: Importance;
    requiredExperience: Experience;
  }[];
  rank: SkillsFrameworkRank;
};
